import toast from "react-hot-toast";
import httpClient from "../services/http/client";
import ROUTES from "../services/http/routes";
import { notifyError } from "../services/requests";
import store from "../services/store";
import { GeocodedLocation, UUID } from "./common";
import { JobOffer, Status } from "./jobOffers";
import { UserResponse } from "./users/users";

export interface Customer extends NewCustomer {
  id: UUID;
  created_at: string;
  updated_at: string;
}

export interface CustomerResponse extends Customer {
  owner_first_name: string;
  owner_last_name: string;
  contracts_count: number;
  projects_count: number;
  bills_count: number;
  contacts_count: number;
}

export interface NewCustomer {
  name: string;
  description: string;
  file_id?: UUID;
  owner_id: UUID;
  tax_number: string;
  siret: string;
  location: GeocodedLocation;
  legal_name: string;
  bank_account_id: UUID;
  billing_address: string;
}

export interface ReportSummaryStatus extends Status {
  candidates_count: number;
}

export interface ReportSummary {
  statuses: ReportSummaryStatus[];
  candidates_count: number;
}

export interface ReportFullJobOffer extends JobOffer {
  summary: ReportSummary;
}

export async function getCustomers(): Promise<CustomerResponse[]> {
  return store.cached("customers", 30 * 60, () =>
    httpClient.req(ROUTES.FETCH_CUSTOMERS(), (err) =>
      notifyError(err || "Erreur lors de la récupération des entreprises")
    )
  );
}

export async function getCustomer(id: UUID): Promise<CustomerResponse> {
  return store.cached(`customers-${id}`, 30 * 60, () =>
    httpClient.req(ROUTES.FETCH_CUSTOMER(id), (err) =>
      notifyError(err || "Erreur lors de la récupération de l'entreprise")
    )
  );
}

export async function createCustomer(new_company: NewCustomer): Promise<Customer> {
  store.invalidateCache(["customers"]);
  return httpClient
    .req<Customer>(ROUTES.CREATE_CUSTOMER(new_company), (err) =>
      notifyError(err || "Erreur lors de la création de l'entreprise")
    )
    .then((e) => {
      toast.success("Entreprise créée avec succès");
      return e;
    });
}

export async function updateCustomer(data: Customer): Promise<Customer> {
  store.invalidateCache(["customers", `customers-${data.id}`]);
  return httpClient.req(ROUTES.UPDATE_CUSTOMER(data), (err) =>
    notifyError(err || "Erreur lors de la modification de l'entreprise")
  );
}

export async function deleteCustomer(id: UUID): Promise<void> {
  store.invalidateCache(["customers", `customers-${id}`]);
  return httpClient.req(ROUTES.DELETE_CUSTOMER(id), (err) =>
    notifyError(
      err ||
        "Erreur lors de la suppression de l'entreprise, merci de vérifier qu'elle n'est pas référencée par une offre"
    )
  );
}

export async function reportSummary(payload: {
  job_offers: UUID[];
  from: string;
}): Promise<{ full_jos: ReportFullJobOffer[]; full_jos_from: ReportFullJobOffer[] }> {
  return httpClient.req(ROUTES.REPORT_SUMMARY(payload), (err) =>
    notifyError(err || "Erreur lors de la récupération des synthéses")
  );
}

export async function generateReport(
  id: UUID,
  payload: {
    opportunity_type: "rpo" | "cabinet";
    users: UserResponse[];
    full_jos: ReportFullJobOffer[];
    full_jos_from: ReportFullJobOffer[];
    accomplishments: string[];
    observations: string[];
    goals: string[];
    synthese_only: boolean;
  }
): Promise<Blob> {
  return httpClient.req(ROUTES.GENERATE_REPORT(id, payload), (err) =>
    notifyError(err || "Erreur lors de la génération du rapport")
  );
}
