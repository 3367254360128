import React, { FunctionComponent, useState, useEffect } from "react";
import dayjs from "dayjs";
import { UUID } from "../../models/common";
import { UserResponse } from "../../models/users/users";
import { CondensedJobApplication } from "../../models/jobApplications";
import store from "../../services/store";
import { CommentResponse, NewComment, createComment } from "../../models/comments";
import { Avatar } from "../userStatus";
import { getUsers } from "../../models/users/users";
import { getCandidateComments } from "../../models/candidate";
import { Tooltip, Button, Select, Textarea } from "@getprorecrutement/getpro-design";

interface Props {
  candidateId: UUID;
  jobApps: CondensedJobApplication[];
}

const Comments: FunctionComponent<Props> = (props) => {
  const [users, setUsers] = useState<UserResponse[]>([]);
  const [comments, setComments] = useState<CommentResponse[]>([]);
  const [loading, setLoading] = useState(false);
  const [content, setContent] = useState<string>("");
  const [jobAppId, setJobAppId] = useState<UUID | undefined>(undefined);
  const [isCommenting, setIsCommenting] = useState<boolean>(false);

  const submit = async () => {
    if (!content) return;
    setLoading(true);
    const data: NewComment = {
      content,
      job_application_id: jobAppId,
      candidate_id: props.candidateId,
      user_id: store.state.User?.id as UUID,
    };

    await createComment(data).finally(() => setLoading(false));
    setContent("");
    setJobAppId(undefined);
    setIsCommenting(false);
    fetchData();
  };

  const fetchData = async () => {
    getUsers().then(setUsers);
    getCandidateComments(props.candidateId).then(setComments);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <div className="comments">
        {comments
          .sort(
            (a, b) =>
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              (new Date(b.created_at) as any) - (new Date(a.created_at) as any)
          )
          .map((c) => {
            const user = users.find((u) => u.id === c.user_id);
            return <Comment key={c.id} user={user} comment={c} />;
          })}
      </div>
      {isCommenting ? (
        <div className="flex flex-col gap-4 bg-white mt-4">
          <Select
            options={props.jobApps}
            light
            getKey={(ja) => ja.id}
            optionRender={(ja) => ja.job_offer_name}
            onChange={(e) => e && setJobAppId(e.id)}
            value={props.jobApps.find((ja) => ja.id === jobAppId)}
            type="single"
            bordered
            rounded
            placeholder="Sélectionner la candidature à commenter (optionnel)"
            label="Candidature à commenter (optionnel)"
          />
          <Textarea
            label="Commentaire"
            placeholder="Saisir votre commentaire"
            rows={3}
            value={content}
            onChange={setContent}
          />
          <div className="flex justify-between">
            <Button onClick={() => setIsCommenting(false)} kind="light" light title="Annuler" />
            <Button
              onClick={submit}
              disabled={!content}
              loading={loading}
              kind="light"
              light
              title="Ajouter un commentaire"
            />
          </div>
        </div>
      ) : (
        <Button onClick={() => setIsCommenting(true)} kind="light" light title="Ajouter un commentaire" />
      )}
    </div>
  );
};

export default Comments;

interface CommentProps {
  user?: UserResponse;
  comment: CommentResponse;
}

const Comment: FunctionComponent<CommentProps> = ({ user, comment }) => {
  const date = dayjs.utc(comment.created_at).local().format("LLL");

  return (
    <div className="mb-2">
      <div className="flex items-center gap-2">
        {user && <Avatar user={user} />}
        <div className="font-bold">{user?.full_name || "Inconnu"}</div>
        <Tooltip light title={date}>
          <span className="text-content-light">{date}</span>
        </Tooltip>
      </div>
      <div className="ml-8">
        <div className="CandidateLink">{comment.job_offer_name}</div>
        <div>{comment.content}</div>
      </div>
    </div>
  );
};
