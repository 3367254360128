import httpClient from "../services/http/client";
import ROUTES from "../services/http/routes";
import { notifyError } from "../services/requests";
import { UUID } from "./common";

export interface EmailTemplate extends NewEmailTemplate {
  id: UUID;
  created_at: string;
}

export interface NewEmailTemplate {
  user_id: UUID;
  name: string;
  subject: string;
  body: string;
  color?: string;
}

export interface EmailTemplateData extends Partial<NewEmailTemplate> {
  id: UUID;
}

export interface BillingVars {
  first_name?: string;
  last_name?: string;
  last_bill_amount?: string;
}

export async function getMyEmailTemplates(): Promise<EmailTemplate[]> {
  return httpClient.req(ROUTES.FETCH_MY_EMAIL_TEMPLATES(), (err) =>
    notifyError(err || "Erreur lors de la suppression du template")
  );
}

export async function createEmailTemplate(
  new_template: NewEmailTemplate
): Promise<EmailTemplate> {
  return httpClient.req(ROUTES.CREATE_EMAIL_TEMPLATE(new_template), (err) =>
    notifyError(err || "Erreur lors de la suppression du template")
  );
}

export async function updateEmailTemplate(
  data: EmailTemplateData
): Promise<EmailTemplate> {
  return httpClient.req(ROUTES.UPDATE_EMAIL_TEMPLATE(data), (err) =>
    notifyError(err || "Erreur lors de la suppression du template")
  );
}

export async function deleteEmailTemplate(id: UUID): Promise<void> {
  return httpClient.req(ROUTES.DELETE_EMAIL_TEMPLATE(id), (err) =>
    notifyError(err || "Erreur lors de la suppression du template")
  );
}

export async function generateTemplate(
  id: UUID,
  vars: BillingVars
): Promise<{ body?: string; subject?: string }> {
  return httpClient.req(ROUTES.GENERATE_TEMPLATE(id, vars), (err) =>
    notifyError(err || "Erreur lors de la génération du template")
  );
}
