import dayjs from "dayjs";

export const euroDisplay = (value?: string | number): string => `${value} €`.replace(/\B(?=(\d{3})+(?!\d))/g, " ");

export const duration = (start: string, end?: string): string => {
  const months = dayjs(end || Date.now()).diff(start, "month") + 1;
  const years = Math.floor(months / 12);
  const diffMonths = months % 12;

  const textualYears = years > 1 ? "ans" : "an";

  if (years) {
    return diffMonths > 0 ? `${years} ${textualYears} et ${diffMonths} mois` : `${years} ${textualYears}`;
  }
  return `${months} mois`;
};

export const capitalize = (data: string): string => {
  return data[0].toUpperCase() + data.slice(1);
};

export const sanitize = (data: string): string => {
  return data.trim();
};

export const expFromFirstJobAt = (firstJobAt: string): string => {
  const firstJobDate = dayjs(firstJobAt);
  const expYears = dayjs().diff(firstJobDate, "year");

  if (expYears === 0) return "< 1 an";
  else if (expYears === 1) return "1 an";
  return `${expYears} ans`;
};

export const phoneFormatter = (str: string): string => {
  const cleanStr = str.replaceAll(".", " ").replaceAll("-", " ");
  const re =
    /((?:\+[0-9]{2}\s[0-9])|(?:\+[0-9]{0,2})|[0-9]{1,2}){1}?\s?([0-9]{2})?\s?([0-9]{2})?\s?([0-9]{2})?\s?([0-9]{2})?\s?([0-9]{2})?\s?([0-9]{2})?\s?([0-9]{2})?\s?([0-9]{2})?\s?([0-9]{1,2})?/gm;
  let matches = re.exec(cleanStr) as string[] | null;

  if (!matches) return "";

  matches = matches.slice(1, 15).filter((e) => !!e);
  const res = matches.join(" ");

  return res;
};

export const slugify = (str: string) =>
  str
    .toLowerCase()
    .replace(/[^a-z0-9]/g, "-")
    .replace(/-+/g, "-");
