import { Tooltip, Image } from "@getprorecrutement/getpro-design";
import dayjs from "dayjs";
import React from "react";
import { FunctionComponent } from "react";
import classNames from "classnames";
import { ShieldExclamationIcon, ClockIcon, ShieldCheckIcon, XCircleIcon } from "@heroicons/react/24/solid";
import { Candidate, CandidateRGPDState, RGPG_STATES, SearchCandidateResponse } from "../../models/candidate";
import { DEFAULT_PROFILE_PIC } from "../../assets/profilePicFallback";

interface CandidatePictureProps {
  candidate: Candidate | SearchCandidateResponse;
  badge?: boolean;
  imageClasses?: string;
}

export const CandidatePicture: FunctionComponent<CandidatePictureProps> = ({
  candidate,
  badge = false,
  imageClasses = "",
}) => {
  const tooltipClasses = classNames("flex items-center gap-2 font-bold", {
    "text-content-regular": candidate.consentment_state === CandidateRGPDState.Pending,
    "text-green-400": candidate.consentment_state === CandidateRGPDState.Accepted,
    "text-red-400": candidate.consentment_state === CandidateRGPDState.Denied,
    "text-orange-400": !candidate.consentment_state,
  });

  const imageWrapperClasses = classNames("border-2 border-solid p-0.5 rounded-full min-w-fit relative", {
    "border-content-regular": candidate.consentment_state === CandidateRGPDState.Pending,
    "border-green-400": candidate.consentment_state === CandidateRGPDState.Accepted,
    "border-red-400": candidate.consentment_state === CandidateRGPDState.Denied,
    "border-orange-400": !candidate.consentment_state,
  });

  const badgeClasses = classNames(
    "absolute -bottom-3 left-1/2 -translate-x-1/2 p-1 bg-white border-2 border-solid rounded-full",
    {
      "text-content-regular border-content-regular": candidate.consentment_state === CandidateRGPDState.Pending,
      "text-green-400 border-green-400": candidate.consentment_state === CandidateRGPDState.Accepted,
      "text-red-400 border-red-400": candidate.consentment_state === CandidateRGPDState.Denied,
      "text-orange-400 border-orange-400": !candidate.consentment_state,
    }
  );

  const ICONS: { [key in CandidateRGPDState]: JSX.Element } = {
    pending: <ClockIcon width={16} height={16} />,
    accepted: <ShieldCheckIcon width={16} height={16} />,
    denied: <XCircleIcon width={16} height={16} />,
  };

  const icon = candidate.consentment_state ? (
    ICONS[candidate.consentment_state]
  ) : (
    <ShieldExclamationIcon width={16} height={16} />
  );

  return (
    <Tooltip
      dark
      customRenderer={() => (
        <div className={tooltipClasses}>
          {icon}
          <div>
            RGPD {candidate.consentment_state ? RGPG_STATES[candidate.consentment_state].toLowerCase() : "non envoyée"}
            {candidate.consentment_state && candidate.consentment_updated_at && (
              <span className="text-white font-normal">
                {" "}
                le {dayjs(candidate.consentment_updated_at).format("DD MMMM YYYY")}
              </span>
            )}
          </div>
        </div>
      )}
    >
      <div className={imageWrapperClasses}>
        <Image
          src={candidate.picture_url || DEFAULT_PROFILE_PIC}
          fallback={DEFAULT_PROFILE_PIC}
          className={`rounded-full ${imageClasses}`}
        />
        {badge && <div className={badgeClasses}>{icon}</div>}
      </div>
    </Tooltip>
  );
};
