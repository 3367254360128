import classNames from "classnames";
import React, { FunctionComponent } from "react";

interface BreadcrumbElem {
  title: string;
  icon?: JSX.Element;
  to?: string;
}

export interface Props {
  breadcrumb: BreadcrumbElem[];
  navigate?: (to: string) => void;
  renderSeparator?: () => JSX.Element;
  dark?: boolean;
  light?: boolean;
}

export const Breadcrumb: FunctionComponent<Props> = ({ breadcrumb, navigate, renderSeparator, dark, light }) => {
  const separator = renderSeparator ? renderSeparator() : <span className="px-2">/</span>;

  const wrapperClasses = classNames("text-xs flex", {
    dark: dark,
  });

  const classes = classNames(
    "flex items-center transition last:font-bold text-content-light last:text-primary-darker",
    {
      "dark:text-content-medium dark:last:text-primary-lighter": !light,
    }
  );

  return (
    <div className={wrapperClasses}>
      {breadcrumb.map((bc, i) => {
        const titleClasses = classNames("flex items-center", {
          "cursor-pointer": !!bc.to,
          "hover:text-primary-darker": !!bc.to,
          "dark:hover:text-primary-lighter": !light && !!bc.to,
        });

        return (
          <div key={bc.title} className={classes} onClick={() => bc.to && navigate && navigate(bc.to)}>
            <div className={titleClasses}>
              {bc.icon && <div className="mr-2 w-3">{bc.icon}</div>}
              <div>{bc.title}</div>
            </div>
            {i < breadcrumb.length - 1 && separator}
          </div>
        );
      })}
    </div>
  );
};
