import httpClient from "../services/http/client";
import ROUTES from "../services/http/routes";
import { UUID } from "./common";
import { notifyError } from "../services/requests";
import store from "../services/store";

export interface BankAccount {
  id: UUID;
  name: string;
  iban: string;
  bic: string;
}

export async function getBankAccounts(): Promise<BankAccount[]> {
  return store.cached("bankAccounts", 60 * 30, () =>
    httpClient.req(ROUTES.FETCH_BANK_ACCOUNTS(), (err) =>
      notifyError(err || "Erreur lors de la récupération des départements")
    )
  );
}
