import React, { useState } from "react";
import { FunctionComponent } from "react";
import { UUID } from "../../../../models/common";
import { GlobalFilters, getStatsStatuses, DashboardModule } from "../../../../models/dashboards";
import { Status } from "../../../../models/jobOffers";
import { getComparePeriod } from "../../../../utils/dates";
import StatusesCandidates from "./candidates";
import ModuleTitle from "../../elements/title";
import { useDeepCompareEffect } from "react-use";
import StatusesJos from "./jos";
import { Divider, Checkbox, Radio } from "@getprorecrutement/getpro-design";
import DashboardTimeline, { PerUserOrBuData, TimelineData } from "../../elements/timeline";
import { PALETTE } from "../..";
import DashboardStats from "../../elements/stats";

export interface StatusesData {
  summary: { [name: string]: number };
  timeline?: TimelineData[];
  timeline_per_user?: PerUserOrBuData[];
  timeline_per_bu?: PerUserOrBuData[];
  comparison?: { [name: string]: number };
  candidates?: {
    id: UUID;
    full_name: string;
    job_offer_name: string;
    job_offer_id: UUID;
    linkedin_url?: string;
    status_id: UUID;
    created_at: string;
  }[];
  jos?: {
    id: UUID;
    name: string;
    summary: { name: string; count: number }[];
  }[];
}

interface Props {
  filters: GlobalFilters;
  locked: boolean;
  dashboardSlug: string;
  mod: DashboardModule<{
    statuses_ids?: UUID[];
    opportunity_type?: "cabinet" | "rpo" | "interne";
    granularity?: "day" | "week" | "month";
    compare?: boolean;
    chart_type?: "timeline" | "users" | "bus" | "candidates" | "jos";
  }>;
  statuses: Status[];
  updateModule: (data: {
    dashboard_slug: string;
    module_key: string;
    filters: {
      statuses_ids?: UUID[];
      opportunity_type?: "cabinet" | "rpo" | "interne";
      granularity?: "day" | "week" | "month";
      compare?: boolean;
      chart_type?: "timeline" | "users" | "bus" | "candidates" | "jos";
    };
    title?: string;
  }) => Promise<void>;
}

export const StatusesChart: FunctionComponent<Props> = (props) => {
  const { filters, locked, dashboardSlug, mod, statuses, updateModule } = props;
  const [sortFilter, setSortFilter] = useState<{ field: string; order?: "ascend" | "descend" }>();
  const [chartTypeSelected, _setTimelineSelected] = useState<"timeline" | "users" | "bus" | "candidates" | "jos">(
    mod.filters.chart_type || "timeline"
  );
  const [opportunityType, _setOpportunityType] = useState<"cabinet" | "rpo" | "interne" | undefined>(
    mod.filters.opportunity_type
  );

  const [statusesData, setStatusesData] = useState<StatusesData>();

  const setShowComparison = async (show: boolean) => {
    await updateModule({
      dashboard_slug: dashboardSlug,
      module_key: mod.key,
      filters: {
        compare: show,
      },
    });
  };

  const setGranularity = async (value: "day" | "week" | "month") => {
    await updateModule({
      dashboard_slug: dashboardSlug,
      module_key: mod.key,
      filters: {
        granularity: value,
      },
    });
  };

  const setTimelineSelect = async (value: "timeline" | "users" | "bus" | "candidates" | "jos") => {
    await updateModule({
      dashboard_slug: dashboardSlug,
      module_key: mod.key,
      filters: {
        chart_type: value,
      },
    });
  };

  const setTimelineSelected = (value: "timeline" | "users" | "bus" | "candidates" | "jos") => {
    setTimelineSelect(value);
    _setTimelineSelected(value);
  };

  const setOpportunityType = async (value: "all" | "cabinet" | "rpo" | "interne") => {
    const save = value === "all" ? undefined : value;
    await updateModule({
      dashboard_slug: dashboardSlug,
      module_key: mod.key,
      filters: {
        opportunity_type: save,
      },
    });
    _setOpportunityType(save);
  };

  const getParsedData = (
    data: {
      name: string;
      status_id: UUID;
      count?: number;
    }[]
  ): PerUserOrBuData[] => {
    const res = data.reduce(
      (
        res: {
          [date: string]: { [statusName: string]: number; total: number };
        },
        data
      ) => {
        const status = statuses.find((s) => s.id === data.status_id);
        if (status) {
          res[data.name] ||= { total: 0 };
          res[data.name][status.name] = data.count || 0;
          res[data.name].total += data.count || 0;
        }
        return res;
      },
      {}
    );

    return Object.keys(res).map((d) => ({ name: d, ...res[d] } as PerUserOrBuData));
  };

  const getData = (
    timeline: { updated_at: string; count: number; status_id: UUID }[],
    statuses: Status[]
  ): TimelineData[] => {
    const keyedStatuses: { [id: string]: string } = statuses.reduce((res, s) => ({ [s.id]: s.name, ...res }), {});

    const res = timeline.reduce(
      (
        res: {
          [date: string]: { [name: string]: number; total: number };
        },
        data
      ) => {
        res[data.updated_at] ||= { total: 0 };
        res[data.updated_at][keyedStatuses[data.status_id]] = data.count;
        res[data.updated_at].total += data.count;
        return res;
      },
      {}
    );

    return Object.keys(res).map((d) => ({ date: d, ...res[d] } as TimelineData));
  };

  const fetchStatsPipe = async () => {
    const dateRangeCompare = mod.filters.compare ? filters.period && getComparePeriod(filters.period) : undefined;

    if (statuses.length > 0) {
      const response = await getStatsStatuses({
        ...filters.dateRange,
        ...dateRangeCompare,
        granularity: mod.filters.granularity,
        users: filters.users,
        business_units: filters.businessUnits,
        job_offers: filters.jobOffers,
        customers: filters.customers,
        statuses_ids:
          (mod.filters.statuses_ids || []).length > 0 ? mod.filters.statuses_ids || [] : statuses.map((s) => s.id),
        chart_type: chartTypeSelected,
        opportunity_type: opportunityType,
        ...sortFilter,
      });
      const res = {
        ...response,
        summary: response.summary.reduce((o, data) => ({ ...o, [data[0]]: data[1] }), {}),
        comparison: response.comparison
          ? response.comparison.reduce((o, data) => ({ ...o, [data[0]]: data[1] }), {})
          : undefined,
      };
      setStatusesData({
        ...res,
        timeline: response.timeline && getData(response.timeline, statuses),
        timeline_per_user:
          response.timeline_per_user && getParsedData(response.timeline_per_user).sort((a, b) => b.total - a.total),
        timeline_per_bu:
          response.timeline_per_bu && getParsedData(response.timeline_per_bu).sort((a, b) => b.total - a.total),
      });
    }
  };

  const setSelectedStatusesIds = async (filter: string) => {
    const statuses_ids = !(mod.filters.statuses_ids || []).includes(filter)
      ? [...(mod.filters.statuses_ids || []), filter] // Add
      : (mod.filters.statuses_ids || []).filter((f) => f !== filter); // Remove

    await updateModule({
      dashboard_slug: dashboardSlug,
      module_key: mod.key,
      filters: {
        statuses_ids,
      },
    });
  };

  useDeepCompareEffect(() => {
    fetchStatsPipe();
  }, [filters, statuses, mod.filters, sortFilter]);

  return (
    <div className="bg-white p-6 shadow-md mb-6">
      {!locked && (
        <div>
          <div>
            <div className="flex gap-2">
              <div className="w-[65%]">
                <div className="flex gap-x-4 gap-y-2 items-center flex-wrap text-sm">
                  {statuses.map((status) => (
                    <Checkbox
                      key={status.id}
                      light
                      label={status.name}
                      value={(mod.filters.statuses_ids || []).includes(status.id)}
                      onChange={() => setSelectedStatusesIds(status.id)}
                    />
                  ))}
                </div>
              </div>
              <Divider type="vertical" style={{ height: 20 }} light />
              <div className="w-[35%]">
                <Checkbox
                  light
                  label="Comparer avec la période précédente"
                  value={mod.filters.compare || false}
                  onChange={setShowComparison}
                />
              </div>
            </div>
            <div className="flex gap-4 items-center mt-2">
              <Radio
                light
                optionType="button"
                value={chartTypeSelected}
                onChange={(e) => setTimelineSelected(e as "timeline" | "users" | "bus" | "candidates" | "jos")}
                options={[
                  { label: "Timeline", value: "timeline" },
                  { label: "Utilisateurs", value: "users" },
                  { label: "Départements", value: "bus" },
                  { label: "Candidats", value: "candidates" },
                  { label: "Offres", value: "jos" },
                ]}
              />
              <Radio
                light
                optionType="button"
                value={opportunityType || "all"}
                onChange={(e) => setOpportunityType(e as "all" | "cabinet" | "rpo" | "interne")}
                options={[
                  { label: "Toutes", value: "all" },
                  { label: "Cabinet", value: "cabinet" },
                  { label: "RPO", value: "rpo" },
                  { label: "Interne", value: "interne" },
                ]}
              />
            </div>
          </div>
          <Divider light />
        </div>
      )}
      <ModuleTitle
        title={mod.title}
        slug={dashboardSlug}
        modKey={mod.key}
        updateModule={updateModule}
        locked={locked}
      />
      <div className="flex items-center justify-between gap-2">
        <div className="w-[65%]">
          {chartTypeSelected === "candidates" ? (
            <StatusesCandidates
              candidates={statusesData?.candidates}
              statuses={statuses}
              setSortFilter={setSortFilter}
            />
          ) : chartTypeSelected === "jos" ? (
            <StatusesJos jos={statusesData?.jos || []} statuses_ids={mod.filters.statuses_ids || []} />
          ) : chartTypeSelected === "timeline" ? (
            <DashboardTimeline
              timeline={statusesData?.timeline}
              setGranularity={setGranularity}
              granularity={mod.filters.granularity || "week"}
              statuses={statuses}
              selectedIds={mod.filters.statuses_ids || []}
              colors={PALETTE.PURPLE}
            />
          ) : chartTypeSelected === "users" ? (
            <DashboardTimeline
              timeline={statusesData?.timeline_per_user}
              statuses={statuses}
              selectedIds={mod.filters.statuses_ids || []}
              colors={PALETTE.PURPLE}
              perUserOrBu
            />
          ) : (
            <DashboardTimeline
              timeline={statusesData?.timeline_per_bu}
              statuses={statuses}
              selectedIds={mod.filters.statuses_ids || []}
              colors={PALETTE.PURPLE}
              perUserOrBu
            />
          )}
        </div>
        <Divider type="vertical" style={{ height: 200 }} light />
        <div className="w-[35%]">
          <DashboardStats
            summary={statusesData?.summary || {}}
            comparison={mod.filters.compare ? statusesData?.comparison : undefined}
            statuses={statuses}
            selectedIds={mod.filters.statuses_ids || []}
            colors={PALETTE.PURPLE}
          />
        </div>
      </div>
    </div>
  );
};

export default StatusesChart;
