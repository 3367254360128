import React, { FunctionComponent } from "react";
import { JobTitle } from "../../../models/jobTitle";
import { JobTitleSelect } from "../inputs/JobTitleSelect";

export const OtherJobTitle: FunctionComponent<{
  label: string;
  selected: boolean;
  collapsed: boolean;
  onChange: (jobTitle?: JobTitle, validated?: boolean) => void;
}> = ({ label, selected, collapsed, onChange }) => {
  return (
    <div
      className={`p-4 border border-solid flex gap-3 rounded-lg cursor-pointer ${
        selected ? "border-primary-medium" : "border-border-bright"
      }`}
    >
      <div className={`w-4 h-4 rounded-full mt-0.5 ${selected ? "border-[5px] border-primary-medium" : "border"}`} />
      <div className="flex flex-col w-full">
        <div className="text-sm text-content-light font-medium">{label}</div>
        {(selected || !collapsed) && (
          <div className="mt-2">
            <JobTitleSelect onChange={(title, _, validated) => onChange(title, validated)} />
          </div>
        )}
      </div>
    </div>
  );
};
