import React, { FunctionComponent, useState, useEffect } from "react";
import { UUID } from "../../../../models/common";
import { Customer, getCustomers } from "../../../../models/customers";
import { Select } from "@getprorecrutement/getpro-design";
import classNames from "classnames";
import { useLoading } from "../../../../utils/loading";
import { MultipleValues, SingleValue } from "@getprorecrutement/getpro-design/dist/components/Form/Inputs/Select";

// const { Option } = Select;

interface Props {
  customers?: Customer[];
  onSelect: (s: UUID) => void;
  placeholder?: string;
  style?: React.CSSProperties;
  showSearch?: boolean;
  label?: string;
  size?: "small" | "medium" | "large";
  error?: string;
  showArrowIcon?: boolean;
  dropdownClassName?: string;
}

interface MultipleProps {
  multiple: true;
  value?: UUID[];
  customValueRender?: (value: Customer[]) => JSX.Element;
}

interface SingleProps {
  multiple?: false;
  value?: UUID;
  customValueRender?: (value: Customer) => JSX.Element;
}

export const CustomerSelect: FunctionComponent<Props & (SingleProps | MultipleProps)> = ({
  onSelect,
  value,
  placeholder,
  style,
  showSearch,
  label,
  size = "medium",
  error,
  showArrowIcon,
  ...props
}) => {
  const [customers, setCustomers] = useState<Customer[]>(props.customers || []);
  const [search, setSearch] = useState<string>("");
  const [options, setOptions] = useState<Customer[]>([]);
  const [loading, setLoading] = useState(false);
  const load = useLoading(setLoading);

  useEffect(() => {
    const [selected, nonSelected] = customers
      .filter((c) => search.length === 0 || c.name?.toLowerCase()?.includes(search.toLowerCase()))
      .reduce(
        (acc, next) => {
          if (props.multiple) {
            acc[value?.indexOf(next.id) !== -1 ? 0 : 1].push(next);
          } else {
            acc[next.id === value ? 0 : 1].push(next);
          }
          return acc;
        },
        [[], []] as Customer[][]
      );
    setOptions(selected.concat(nonSelected));
  }, [customers, search]);

  const fetchCustomers = async (): Promise<void> => {
    load(getCustomers().then(setCustomers));
  };

  useEffect(() => {
    if (props.customers) setCustomers(props.customers);
    else fetchCustomers();
  }, [props.customers]);

  const iconClasses = classNames("rounded-full", {
    "max-w-[16px] max-h-[16px]": size === "small",
    "max-w-[24px] max-h-[24px]": size === "medium",
    "max-w-[32px] max-h-[32px]": size === "large",
  });

  const selectProps: SingleValue<Customer> | MultipleValues<Customer> = props.multiple
    ? {
        type: "multiple",
        value: customers.filter((c) => value?.includes(c.id)),
        customValueRender: props.customValueRender,
      }
    : {
        type: "single",
        value: customers.find((c) => c.id === value),
        customValueRender: props.customValueRender,
      };

  return (
    <span className="customerSelect bg-inherit" style={style}>
      {/* ADD ALLOW CLEAR on SELECT */}
      <Select
        light
        bordered
        rounded
        size={size}
        label={label}
        error={error}
        options={options}
        showArrowIcon={showArrowIcon}
        optionRender={(c) => (
          <div className="flex gap-2 items-center">
            {c.file_id && (
              <img
                className={iconClasses}
                src={`https://storage.googleapis.com/getpro-public/${
                  c.file_id || "e1ca4dfa-7173-40a1-8f9a-917dc3f3556f"
                }`}
              />
            )}
            <div>{c.name}</div>
          </div>
        )}
        getKey={(c) => c.id}
        onSearch={showSearch ? setSearch : undefined}
        loading={loading}
        onChange={(v) => v && onSelect(v.id)}
        placeholder={placeholder || "Sélectionner un client"}
        dropdownClassName={props.dropdownClassName}
        {...selectProps}
      />
    </span>
  );
};

export default CustomerSelect;
