import React, { useState } from "react";
import { FunctionComponent } from "react";
import { UUID } from "../../../../models/common";
import { Status } from "../../../../models/jobOffers";
import dayjs from "dayjs";
import { Tooltip, Tag, Table, ColumnProps } from "@getprorecrutement/getpro-design";
import LinkedinIcon from "../../../../utils/icons";
import { useNavigate } from "react-router";
import qs from "qs";

interface Props {
  callStatuses: Status[];
  candidates?: {
    id: UUID;
    full_name: string;
    job_offer_name: string;
    job_offer_id: UUID;
    linkedin_url?: string;
    call_status_id: UUID;
    created_at: string;
  }[];
  setSortFilter: (val?: { field: string; order?: "ascend" | "descend" }) => void;
}

const PER_PAGE = 5;

export const CallStatusesCandidates: FunctionComponent<Props> = (props) => {
  const { callStatuses, candidates, setSortFilter } = props;
  const [page, setPage] = useState<number>(1);
  const navigate = useNavigate();
  const [sortBy, setSortBy] = useState<{
    key: keyof {
      id: UUID;
      full_name: string;
      job_offer_name: string;
      job_offer_id: UUID;
      linkedin_url?: string;
      call_status_id: UUID;
      created_at: string;
    };
    sortDirection: undefined | "asc" | "desc";
  }>();

  const columns: ColumnProps<{
    id: UUID;
    full_name: string;
    job_offer_name: string;
    job_offer_id: UUID;
    linkedin_url?: string;
    call_status_id: UUID;
    created_at: string;
  }>[] = [
    {
      title: "Candidat",
      dataIndex: "full_name",
      key: "full_name",
    },
    {
      title: "Poste",
      dataIndex: "job_offer_name",
      key: "job_offer_name",
    },
    {
      title: "Linkedin",
      width: 70,
      dataIndex: "linkedin_url",
      key: "linkedin_url",
      render: (value) => (
        <div className="flex justify-center items-center">
          {value && (
            <Tooltip light title="Voir le profil">
              <a
                href={value}
                style={{
                  height: 20,
                  cursor: value ? undefined : "not-allowed",
                }}
              >
                <LinkedinIcon />
              </a>
            </Tooltip>
          )}
        </div>
      ),
    },
    {
      title: "Statut",
      width: 160,
      // TODO FILTER BY CALL_STATUSES
      // sorter: true as any,
      dataIndex: "call_status_id",
      key: "call_status_id",
      render: (value) => {
        const callStatus = callStatuses.find((s) => s.id === value);
        return <div>{callStatus && <Tag value={callStatus.name} light bordered rounded />}</div>;
      },
    },
    {
      title: "Date",
      dataIndex: "created_at",
      key: "created_at",
      sortable: true,
      render: (value) => <div>{`le ${dayjs(value).format("LLL")}`}</div>,
    },
  ];

  const filterCandidates = (
    candidates: {
      id: UUID;
      full_name: string;
      job_offer_name: string;
      job_offer_id: UUID;
      linkedin_url?: string;
      call_status_id: UUID;
      created_at: string;
    }[]
  ) => {
    return (
      (sortBy && sortBy.key === "created_at"
        ? candidates.sort((a, b) =>
            sortBy.sortDirection === "asc"
              ? dayjs(a.created_at).diff(dayjs(b.created_at))
              : dayjs(b.created_at).diff(dayjs(a.created_at))
          )
        : candidates) || []
    ).filter((_, i) => i >= (page - 1) * PER_PAGE && i < page * PER_PAGE);
  };

  const _setSortBy = (
    key: keyof {
      id: UUID;
      full_name: string;
      job_offer_name: string;
      job_offer_id: UUID;
      linkedin_url?: string;
      call_status_id: UUID;
      created_at: string;
    },
    direction: "desc" | "asc" | undefined
  ) => {
    setSortBy({ key: key, sortDirection: direction });
    setSortFilter({
      field: key,
      order: direction === "asc" ? "ascend" : direction === "desc" ? "descend" : undefined,
    });
    setPage(1);
  };

  return (
    <Table
      size="small"
      dataSource={{ data: filterCandidates(candidates || []), total: candidates?.length || 0 }}
      pagination={{ per_page: PER_PAGE, page, onChange: setPage }}
      columns={columns}
      rowClassName={() => "cursor-pointer"}
      onClick={(v) =>
        navigate({
          pathname: `/jobs/${v.job_offer_id}`,
          search: qs.stringify({ candidateId: v.id }),
        })
      }
      bordered
      light
      sortBy={sortBy}
      onSortDirectionChange={_setSortBy}
    />
  );
};

export default CallStatusesCandidates;
