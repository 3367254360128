import httpClient from "../services/http/client";
import ROUTES from "../services/http/routes";
import { NominatimLocationResponse, UUID } from "./common";

export interface CandidateLocation extends NominatimLocationResponse {
  id: UUID;
  max_radius?: number;
  candidate_id: UUID;
  location_id: number;
}

export interface NewCandidateLocation {
  max_radius?: number;
  candidate_id: UUID;
  location_id: number;
}

export interface SimpleCandidateLocation {
  location: string;
  max_radius: number;
}

export const getCandidateLocations = (candidateId: UUID): Promise<CandidateLocation[]> =>
  httpClient.req(ROUTES.CANDIDATE_LOCATIONS(candidateId));
