import React from "react";
import { FunctionComponent } from "react";
import { UUID } from "../../../models/common";
import dayjs from "dayjs";
import { Bar, BarChart, ResponsiveContainer, XAxis, YAxis, Tooltip, TooltipProps, LabelList } from "recharts";
import { Radio, Spinner } from "@getprorecrutement/getpro-design";
import { ArrowLongRightIcon } from "@heroicons/react/24/outline";

export const formattedAmountLabel = (nb: number) => {
  return Math.abs(nb) < 1000
    ? nb
    : Math.abs(nb) > 100000
    ? `${(nb / 1000).toFixed(0)}K`
    : `${+(nb / 1000).toFixed(1)}K`;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const renderCustomizedLabel = (props: any) => {
  const { x, y, width, value } = props;
  const radius = 10;

  return (
    value && (
      <text
        className="text-xs"
        x={x + width / 2}
        y={y - radius}
        fill="#000"
        textAnchor="middle"
        dominantBaseline="middle"
      >
        {formattedAmountLabel(value)}
      </text>
    )
  );
};

export const getCustomTooltipDate = (date: string, granularity: "day" | "week" | "month") => {
  return (
    <div className="DashboardCustomTooltipDate">
      {granularity === "month" ? (
        dayjs(date).format("MMMM YYYY")
      ) : granularity === "week" ? (
        <div className="flex justify-between items-center gap-2">
          <div>{dayjs(date).format("D MMM YY")}</div>
          <ArrowLongRightIcon className="w-4 h-4" />
          <div>{dayjs(date).endOf("week").format("D MMM YY")}</div>
        </div>
      ) : (
        dayjs(date).format("LL")
      )}
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const CustomizedAxisTick = (props: any) => {
  const { x, y, payload } = props;

  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={4} textAnchor="end" fill="#666" transform="rotate(-90)">
        {payload.value.split(" ")[0]}
      </text>
    </g>
  );
};

export type PerUserOrBuData = { [name: string]: number } & {
  name: string;
  total: number;
};

export type TimelineData = { [name: string]: number } & {
  date: string;
  total: number;
};

interface Props {
  timeline?: TimelineData[] | PerUserOrBuData[];
  setGranularity?: (value: "day" | "week" | "month") => void;
  granularity?: "day" | "week" | "month";
  selectedIds: UUID[];
  statuses: { id: UUID; name: string }[];
  colors: string[];
  perUserOrBu?: boolean;
}

export const DashboardTimeline: FunctionComponent<Props> = ({
  timeline,
  setGranularity,
  granularity,
  selectedIds,
  statuses,
  colors,
  perUserOrBu = false,
}) => {
  const tl = timeline || [];
  const barSize = tl.length <= 2 ? 100 : undefined;
  const padding = tl.length > 0 && tl.length < 3 ? tl.length * 100 : undefined;
  const paddingProp = padding ? { left: padding, right: padding } : "gap";

  const CustomTooltip = ({ active, payload, label }: TooltipProps<number, string>) => {
    if (active && payload && payload.length) {
      return (
        <div className="DashboardCustomTooltip">
          {perUserOrBu ? (
            <div style={{ marginBottom: 12, fontWeight: "bold", textAlign: "center" }}>{label}</div>
          ) : (
            getCustomTooltipDate(label, granularity || "week")
          )}
          {displayedStatuses.map((s, i) => {
            const save = payload.find((p) => p.name === s.name);
            if (save) {
              return (
                <div key={save.dataKey} className="DashboardCustomTooltipData" style={{ backgroundColor: colors[i] }}>
                  <span className="DashboardCustomTooltipLabel">{save.name}</span>
                  <span className="DashboardCustomTooltipValue">{`${save.value}`}</span>
                </div>
              );
            }
          })}
        </div>
      );
    }

    return null;
  };

  const displayedStatuses = selectedIds.length === 0 ? statuses : statuses.filter((s) => selectedIds.includes(s.id));

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: 284,
      }}
    >
      {timeline ? (
        <div style={{ width: "100%" }}>
          {!perUserOrBu && setGranularity && granularity && (
            <div className="mb-6 flex justify-between items-center">
              <Radio
                light
                optionType="button"
                options={[
                  { label: "Jours", value: "day" },
                  { label: "Semaine", value: "week" },
                  { label: "Mois", value: "month" },
                ]}
                value={granularity}
                onChange={(v) => setGranularity(v as "day" | "week" | "month")}
              />
            </div>
          )}
          <ResponsiveContainer width="100%" height={perUserOrBu ? 300 : 200}>
            <BarChart
              margin={{ left: 0, right: 0 }}
              data={
                perUserOrBu
                  ? (timeline as PerUserOrBuData[])
                  : (timeline as TimelineData[]).map((t: TimelineData) => ({
                      ...t,
                      date: dayjs(t.date).valueOf(),
                    }))
              }
            >
              <XAxis
                dataKey={perUserOrBu ? "name" : "date"}
                tick={perUserOrBu ? <CustomizedAxisTick /> : undefined}
                scale={perUserOrBu ? undefined : "time"}
                type={perUserOrBu ? undefined : "number"}
                axisLine={false}
                tickLine={false}
                interval={perUserOrBu ? 0 : undefined}
                height={perUserOrBu ? 100 : undefined}
                padding={perUserOrBu ? undefined : paddingProp}
                domain={["auto", "auto"]}
                tickFormatter={perUserOrBu ? undefined : (tick) => dayjs(tick).format("DD/MM/YY")}
              />
              <YAxis padding={{ top: 20, bottom: 0 }} axisLine={false} tickLine={false} />
              <Tooltip cursor={false} content={CustomTooltip} />
              {displayedStatuses.sort().map((s, i) => {
                return (
                  <Bar barSize={barSize} key={s.id} dataKey={s.name} stackId="a" fill={colors[i]}>
                    {i === displayedStatuses.length - 1 && (
                      <LabelList position="top" dataKey="total" content={renderCustomizedLabel} />
                    )}
                  </Bar>
                );
              })}
            </BarChart>
          </ResponsiveContainer>
        </div>
      ) : (
        <Spinner light />
      )}
    </div>
  );
};

export default DashboardTimeline;
