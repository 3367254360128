import React, { FunctionComponent } from "react";
import { JobTitle } from "../../../models/jobTitle";
import { Category } from "../../../models/jobTitleCategory";
import { Hierarchy } from "../../../models/jobTitleHierarchy";
import { Tag, TagColor } from "@getprorecrutement/getpro-design";
import { JobTitleValidation } from "../inputs/JobTitleSelect/jobTitleValidation";

export enum JobTitleSource {
  Sweeft = "sweeft",
  Candidate = "candidate",
  Title = "title",
  Other = "other",
  SweeftAndCandidate = "sweeft_and_candidate",
}

interface DisplayedSelectionJobTitle {
  kind: "selection";
  title: JobTitle;
}

interface DisplayedRawJobTitle {
  kind: "raw_input";
  title: string;
}

interface DisplayedBase {
  source: JobTitleSource;
  categories: string[];
}

export type DisplayedJobTitle = DisplayedBase & (DisplayedSelectionJobTitle | DisplayedRawJobTitle);

interface JobTitleElemProps {
  selected: boolean;
  jobTitle: DisplayedJobTitle;
  categories: Category[];
  collapsed: boolean;
  hierarchies: Hierarchy[];
  onChange: (title?: JobTitle) => void;
}

const TITLE_SOURCE = {
  [JobTitleSource.Sweeft]: "Suggestion Sweeft",
  [JobTitleSource.Candidate]: "Suggestion candidat",
  [JobTitleSource.SweeftAndCandidate]: "Suggestion Sweeft & candidat",
  [JobTitleSource.Title]: "Titre actuel Sweeft",
  [JobTitleSource.Other]: "Proposez un autre titre de poste",
};

export const JobTitleElement: FunctionComponent<JobTitleElemProps> = ({
  selected,
  jobTitle,
  categories,
  hierarchies,
  collapsed,
  onChange,
}) => {
  const current_hierarchies =
    jobTitle.kind === "selection" ? hierarchies.find((v) => v.id === jobTitle.title.hierarchy_id) : undefined;

  return (
    <div
      className={`p-4 border border-solid rounded-lg flex gap-3 cursor-pointer ${
        selected ? "border-primary-medium" : "border-border-bright"
      }`}
    >
      <div
        className={`w-4 h-4 min-w-[16px] rounded-full border-solid mt-3 ${
          selected ? "border-[5px] border-primary-medium" : "border border-border-bright"
        }`}
      />
      <div className="flex flex-col w-full">
        <div className="text-2xs-g text-content-light font-medium">{TITLE_SOURCE[jobTitle.source]} :</div>
        <div className="text-sm-g font-bold">
          {jobTitle.kind === "selection" ? jobTitle.title.title : jobTitle.title}
        </div>
        {jobTitle.kind === "selection" && jobTitle.categories.length > 0 && (
          <div className="flex gap-2 items-center mt-2 flex-wrap">
            {jobTitle.categories.map((c, i) => (
              <Tag key={i} value={c} type={TagColor.Primary} bold className="border-none" />
            ))}
            {current_hierarchies && (
              <Tag value={current_hierarchies.title} className="bg-teal-100 text-teal-400 border-none" bold />
            )}
          </div>
        )}
        {jobTitle.kind === "raw_input" && (selected || !collapsed) && (
          <JobTitleValidation
            onChange={onChange}
            rawTitle={jobTitle.title}
            categories={categories}
            hierarchies={hierarchies}
          />
        )}
      </div>
    </div>
  );
};
