import httpClient from "../../services/http/client";
import ROUTES from "../../services/http/routes";
import { notifyError } from "../../services/requests";

export interface DateFilters {
  from: string;
  to: string;
  granularity: "day" | "week" | "month";
}

export interface ChartBillData {
  due_date: string;
  pending: number;
  billed: number;
  paid: number;
}

export async function getBillsData(filters: DateFilters): Promise<ChartBillData[]> {
  return httpClient.req(ROUTES.BILLS_AMOUNT_BY_STATUS_AND_DAY(filters), (err) =>
    notifyError(err || "Erreur lors de la récupération des données du graphique")
  );
}
