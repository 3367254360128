import React, { FunctionComponent } from "react";

export interface Props {
  elem: JSX.Element;
}

export const Skeleton: FunctionComponent<Props> = ({ elem }) => {
  return (
    <div>
      <div className="grayscale !text-white/0 animate-pulse">{elem}</div>
    </div>
  );
};
