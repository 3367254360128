import React, { useEffect, useState } from "react";
import { FunctionComponent } from "react";
import { UUID } from "../../../../models/common";
import { GlobalFilters, getStatsCallStatuses, DashboardModule } from "../../../../models/dashboards";
import { Status } from "../../../../models/jobOffers";
import { getComparePeriod } from "../../../../utils/dates";
import ModuleTitle from "../../elements/title";
import { useDeepCompareEffect } from "react-use";
import { getCallStatuses } from "../../../../services/requests";
import CallStatusesCandidates from "./candidates";
import { Divider, Checkbox } from "@getprorecrutement/getpro-design";
import DashboardTimeline, { TimelineData } from "../../elements/timeline";
import { PALETTE } from "../..";
import DashboardStats from "../../elements/stats";

interface Props {
  filters: GlobalFilters;
  locked: boolean;
  dashboardSlug: string;
  mod: DashboardModule<{
    cold_call_ids?: UUID[];
    granularity?: "day" | "week" | "month";
    compare?: boolean;
    show_candidates?: boolean;
  }>;
  statuses: Status[];
  updateModule: (data: {
    dashboard_slug: string;
    module_key: string;
    filters: {
      cold_call_ids?: UUID[];
      granularity?: "day" | "week" | "month";
      compare?: boolean;
      show_candidates?: boolean;
    };
    title?: string;
  }) => Promise<void>;
}

export const ColdCallChart: FunctionComponent<Props> = (props) => {
  const { filters, locked, dashboardSlug, mod, updateModule } = props;
  const [sortFilter, setSortFilter] = useState<{ field: string; order?: "ascend" | "descend" }>();
  const [callStatuses, setCallStatuses] = useState<Status[]>();
  const [callStatusData, setCallStatusData] = useState<{
    summary: { [name: string]: number };
    timeline: TimelineData[];
    comparison?: { [name: string]: number };
    candidates?: {
      id: UUID;
      full_name: string;
      job_offer_name: string;
      job_offer_id: UUID;
      linkedin_url?: string;
      call_status_id: UUID;
      created_at: string;
    }[];
  }>();

  useEffect(() => {
    getCallStatuses().then(setCallStatuses);
  }, []);

  const setShowCandidates = async () => {
    await updateModule({
      dashboard_slug: dashboardSlug,
      module_key: mod.key,
      filters: {
        show_candidates: !mod.filters.show_candidates,
      },
    });
  };

  const setShowComparison = async (show: boolean) => {
    await updateModule({
      dashboard_slug: dashboardSlug,
      module_key: mod.key,
      filters: {
        compare: show,
      },
    });
  };

  const setGranularity = async (value: "day" | "week" | "month") => {
    await updateModule({
      dashboard_slug: dashboardSlug,
      module_key: mod.key,
      filters: {
        granularity: value,
      },
    });
  };

  const getData = (
    timeline: { created_at: string; count: number; call_status_id: UUID }[],
    statuses: Status[]
  ): TimelineData[] => {
    const keyedStatuses: { [id: string]: string } = statuses.reduce((res, s) => ({ [s.id]: s.name, ...res }), {});

    const res = timeline.reduce(
      (
        res: {
          [date: string]: { [name: string]: number; total: number };
        },
        data
      ) => {
        res[data.created_at] ||= { total: 0 };
        res[data.created_at][keyedStatuses[data.call_status_id]] = data.count;
        res[data.created_at].total += data.count;
        return res;
      },
      {}
    );

    return Object.keys(res).map((d) => ({ date: d, ...res[d] } as TimelineData));
  };

  const fetchStatsPipe = async () => {
    const dateRangeCompare = mod.filters.compare ? filters.period && getComparePeriod(filters.period) : undefined;

    if ((callStatuses || []).length > 0) {
      const response = await getStatsCallStatuses({
        ...filters.dateRange,
        ...dateRangeCompare,
        granularity: mod.filters.granularity,
        users: filters.users,
        business_units: filters.businessUnits,
        job_offers: filters.jobOffers,
        customers: filters.customers,
        call_status_ids:
          (mod.filters.cold_call_ids || []).length > 0
            ? mod.filters.cold_call_ids || []
            : (callStatuses || []).map((s) => s.id),
        candidates: mod.filters.show_candidates,
        ...sortFilter,
      });
      const res = {
        ...response,
        summary: response.summary.reduce((o, data) => ({ ...o, [data[0]]: data[1] }), {}),
        comparison: response.comparison
          ? response.comparison.reduce((o, data) => ({ ...o, [data[0]]: data[1] }), {})
          : undefined,
      };
      setCallStatusData({
        ...res,
        timeline: getData(response.timeline, callStatuses || []),
      });
    }
  };

  const setSelectedStatusesIds = async (filter: string) => {
    const cold_call_ids = !(mod.filters.cold_call_ids || []).includes(filter)
      ? [...(mod.filters.cold_call_ids || []), filter] // Add
      : (mod.filters.cold_call_ids || []).filter((f) => f !== filter); // Remove

    await updateModule({
      dashboard_slug: dashboardSlug,
      module_key: mod.key,
      filters: {
        cold_call_ids,
      },
    });
  };

  useDeepCompareEffect(() => {
    if (callStatuses) fetchStatsPipe();
  }, [filters, mod.filters, sortFilter, callStatuses]);

  return (
    <div className="bg-white p-6 shadow-md mb-6">
      {!locked && (
        <div>
          <div className="flex items-center gap-2">
            <div className="flex-1 flex gap-2 items-center flex-wrap text-sm">
              {(callStatuses || []).map((status) => (
                <Checkbox
                  label={status.name}
                  key={status.id}
                  value={(mod.filters.cold_call_ids || []).includes(status.id)}
                  onChange={() => setSelectedStatusesIds(status.id)}
                  light
                />
              ))}
            </div>
            <Divider type="vertical" style={{ height: 20 }} light />
            <div className="w-[35%]">
              <Checkbox
                label="Comparer avec la période précédente"
                light
                value={mod.filters.compare || false}
                onChange={(val) => setShowComparison(val)}
              />
            </div>
          </div>
          <div className="mt-2">
            <Checkbox
              label="Afficher les candidats"
              light
              value={mod.filters.show_candidates || false}
              onChange={() => setShowCandidates()}
            />
          </div>
          <Divider light />
        </div>
      )}
      <ModuleTitle
        title={mod.title}
        slug={dashboardSlug}
        modKey={mod.key}
        updateModule={updateModule}
        locked={locked}
      />
      <div className="flex items-center justify-between gap-2">
        <div className="w-[65%]">
          {mod.filters.show_candidates ? (
            <CallStatusesCandidates
              candidates={callStatusData?.candidates}
              callStatuses={callStatuses || []}
              setSortFilter={setSortFilter}
            />
          ) : (
            <DashboardTimeline
              timeline={callStatusData?.timeline}
              setGranularity={setGranularity}
              granularity={mod.filters.granularity || "week"}
              statuses={callStatuses || []}
              selectedIds={mod.filters.cold_call_ids || []}
              colors={PALETTE.GREEN}
            />
          )}
        </div>
        <div className="h-[200px]">
          <Divider type="vertical" light />
        </div>
        <div className="w-[35%]">
          <DashboardStats
            summary={callStatusData?.summary || {}}
            comparison={mod.filters.compare ? callStatusData?.comparison : undefined}
            statuses={callStatuses || []}
            selectedIds={mod.filters.cold_call_ids || []}
            colors={PALETTE.GREEN}
          />
        </div>
      </div>
    </div>
  );
};

export default ColdCallChart;
