import React, { FunctionComponent, useState, useEffect } from "react";
import { UUID } from "../../../../models/common";
import { Select } from "@getprorecrutement/getpro-design";
import { useLoading } from "../../../../utils/loading";
import { MultipleValues, SingleValue } from "@getprorecrutement/getpro-design/dist/components/Form/Inputs/Select";
import { Skill } from "../../../../models/skill";
import { fetchSkills as fetchSkillsReq } from "../../../../models/skill";
import { useDebounce } from "../../../../utils";

interface Props {
  onSelect: (s: UUID) => void;
  placeholder?: string;
  label?: string;
  showSearch?: boolean;
  size?: "small" | "medium" | "large";
  error?: string;
  showArrowIcon?: boolean;
  dropdownClassName?: string;
}

interface MultipleProps {
  multiple: true;
  value?: UUID[];
  customValueRender?: (value: Skill[]) => JSX.Element;
}

interface SingleProps {
  multiple?: false;
  value?: UUID;
  customValueRender?: (value: Skill) => JSX.Element;
}

export const SkillSelect: FunctionComponent<Props & (MultipleProps | SingleProps)> = ({
  onSelect,
  placeholder,
  label,
  showSearch,
  size,
  value,
  error,
  showArrowIcon,
  ...props
}) => {
  const [skills, _setSkills] = useState<Skill[]>([]);
  const [search, setSearch] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [selectedValues, setSelectedValues] = useState<Skill[]>([]);
  const load = useLoading(setLoading);
  const debounceSearch = useDebounce(search, 300);

  const setSkills = (skills: Skill[]) => {
    const [selected, nonSelected] = skills.reduce(
      (acc, next) => {
        if (props.multiple) {
          acc[value?.indexOf(next.id) !== -1 ? 0 : 1].push(next);
        } else {
          acc[value === next.id ? 0 : 1].push(next);
        }
        return acc;
      },
      [[], []] as Skill[][]
    );
    _setSkills(selected.concat(nonSelected));
  };

  const fetchSkills = async () => {
    load(fetchSkillsReq({ name: debounceSearch }).then(setSkills));
  };

  useEffect(() => {
    fetchSkills();
  }, [debounceSearch]);

  const selectProps: SingleValue<Skill> | MultipleValues<Skill> = props.multiple
    ? {
        type: "multiple",
        value: selectedValues,
        customValueRender: props.customValueRender,
      }
    : {
        type: "single",
        value: selectedValues[0],
        customValueRender: props.customValueRender,
      };

  useEffect(() => {
    if (value && value.length) {
      if (props.multiple && value.length !== selectedValues.length)
        fetchSkillsReq({ ids: value as UUID[] }).then(setSelectedValues);
      else if (!props.multiple && value !== selectedValues[0].id)
        fetchSkillsReq({ ids: [value as UUID] }).then(setSelectedValues);
    } else {
      setSelectedValues([]);
    }
  }, [value]);

  const selected = (val: Skill) => {
    const newValues = props.multiple
      ? selectedValues.map((skill) => skill.id).includes(val.id)
        ? selectedValues.filter((skill) => skill.id !== val.id)
        : [...selectedValues, val]
      : [val];
    setSelectedValues(newValues);
    onSelect(val.id);
  };

  return (
    <span className="bg-inherit">
      <Select
        light
        bordered
        rounded
        loading={loading}
        showArrowIcon={showArrowIcon}
        size={size}
        label={label}
        error={error}
        options={skills}
        optionRender={(c) => c.name}
        getKey={(c) => c.id}
        onSearch={showSearch ? setSearch : undefined}
        onChange={(v) => v && selected(v)}
        placeholder={placeholder || "Sélectionner une compétence"}
        dropdownClassName={props.dropdownClassName}
        {...selectProps}
      />
    </span>
  );
};

export default SkillSelect;
