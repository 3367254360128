import React, { FunctionComponent } from "react";
import { UUID } from "../../models/common";
import { useEffect } from "react";
import {
  Candidate,
  CandidateData,
  getCandidate,
  getCandidateFiles,
  updateCandidate,
  candidateUnblacklist,
  getCandidateJas,
  clearCandidate,
  ClearCandidateData,
  getCandidateConsentToken,
} from "../../models/candidate";
import {
  File as FileModel,
  FileData,
  updateFile as updateFileReq,
  deleteFile as deleteFileReq,
  uploadFile as uploadFileReq,
} from "../../models/file";
import { useState } from "react";
import Profile from "./profile";
import CandidateForm from "./form";
import JobApplications from "./jobApplications";
import { CondensedJobApplication } from "../../models/jobApplications";
import { Modal, Empty } from "@getprorecrutement/getpro-design";
import { toast } from "react-hot-toast";
import { copyToClipboard } from "../../utils";

interface Props {
  id: UUID;
  onUpdate?: () => void;
}

export const CandidateProfile: FunctionComponent<Props> = (props) => {
  const [candidate, setCandidate] = useState<Candidate | undefined>(undefined);
  const [files, setFiles] = useState<FileModel[]>([]);
  const [loading, setLoading] = useState(true);
  const [beingEdited, setEdit] = useState<boolean>(false);
  const [jobApplications, setJobApplications] = useState<CondensedJobApplication[]>([]);

  const edit = () => {
    setEdit(true);
  };

  const unblacklist = async () => {
    if (!candidate) return;
    const resp = await candidateUnblacklist(candidate.id);
    setCandidate(resp);
    toast.success("Candidat mis à jour avec succès");
    props.onUpdate && props.onUpdate();
  };

  const update = async (payload: CandidateData) => {
    if (!candidate) return;
    const resp = await updateCandidate({ id: candidate.id, ...payload });

    toast.success("Candidat mis à jour avec succès");
    setEdit(false);
    setCandidate(resp);
    props.onUpdate && props.onUpdate();
  };

  const delContact = async (payload: ClearCandidateData) => {
    if (!candidate) return;
    const resp = await clearCandidate(candidate.id, payload);

    setCandidate(resp);
    toast.success("Candidat mis à jour avec succès");
    props.onUpdate && props.onUpdate();
  };

  const uploadFile = (file: File) => {
    uploadFileReq({ file }).then(({ id }) => {
      const currentFiles = candidate?.file_ids || [];
      update({ file_ids: [...currentFiles, id] }).then(fetchFiles);
    });
    return false;
  };

  const deleteFile = (id: UUID) => {
    deleteFileReq(id).then(() => {
      let currentFiles = candidate?.file_ids || [];
      currentFiles = currentFiles.filter((e) => e !== id);
      update({ file_ids: [...currentFiles] }).then(fetchFiles);
    });
  };

  const updateFile = (data: FileData) => {
    return updateFileReq(data).then(() => {
      fetchFiles();
      toast.success("Fichier mis à jour avec succès");
    });
  };

  useEffect(() => {
    fetchCandidate();
    fetchFiles();
    fetchJas();
  }, [props.id]);

  const fetchJas = async () => {
    getCandidateJas(props.id).then(setJobApplications);
  };

  async function getConsentToken(id: UUID) {
    const url = process.env.STAGING === "true" ? process.env.STAGING_RGPD_URL : process.env.RGPD_URL;
    const { token } = await getCandidateConsentToken(id);
    copyToClipboard(`${url}/consent/${token}`, "Le lien RGPD a été copié dans votre presse-papier");
    fetchCandidate();
  }

  const fetchCandidate = async () => {
    setLoading(true);
    getCandidate(props.id)
      .then(setCandidate)
      .finally(() => setLoading(false));
  };

  const fetchFiles = async () => {
    setLoading(true);
    getCandidateFiles(props.id)
      .then(setFiles)
      .finally(() => setLoading(false));
  };

  if (!loading && candidate)
    return (
      <div>
        {jobApplications.length > 0 && (
          <JobApplications
            candidate={candidate}
            key={`ja-${candidate.id}`}
            jobApps={jobApplications}
            fetchJas={fetchJas}
          />
        )}
        <Profile
          delContact={delContact}
          uploadFile={uploadFile}
          deleteFile={deleteFile}
          updateFile={updateFile}
          unblacklist={unblacklist}
          getConsentToken={getConsentToken}
          loading={loading}
          candidate={candidate}
          files={files}
          edit={edit}
          update={update}
          jobApps={jobApplications}
          key={`profile-${candidate.id}`}
        />
        <Modal light show={beingEdited} className="w-modal-md" onClose={() => setEdit(false)}>
          <CandidateForm candidate={candidate} onSubmit={update} key={`cf-${candidate.id}`} />
        </Modal>
      </div>
    );

  return <Empty light />;
};

export default CandidateProfile;
