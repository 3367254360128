import { ChevronLeftIcon, ChevronRightIcon, DotsVerticalIcon } from "@heroicons/react/outline";
import classNames from "classnames";
import React, { FunctionComponent, useEffect, useRef, useState } from "react";
import { twMerge } from "tailwind-merge";
import { dropDownClasses } from "../../utils/common";
import { PopConfirm } from "../PopConfirm";

interface SimpleAction {
  action: () => void;
  popConfirm?: { show: boolean; title?: string };
  disabled?: boolean;
  subElems?: undefined;
}

interface SubAction {
  action?: undefined;
  disabled?: undefined;
  popConfirm?: undefined;
  subElems: {
    title: string;
    icon?: JSX.Element;
    action: () => void;
    disabled?: boolean;
  }[];
}

export interface ActionDefault {
  title: string;
  icon?: JSX.Element;
}

export type Action = ActionDefault & (SimpleAction | SubAction);

export interface Props {
  actions: Action[];
  position?: "bottom-left" | "bottom-right" | "top-left" | "top-right";
  dark?: boolean;
  light?: boolean;
}

interface PropsWithChildren {
  icon?: never;
  children?: JSX.Element;
}
interface PropsWithIcon {
  icon?: JSX.Element;
  children?: never;
}

export type ChildProps = PropsWithChildren | PropsWithIcon;

export const MoreActions: FunctionComponent<Props & ChildProps & React.HTMLAttributes<HTMLDivElement>> = ({
  position = "bottom-left",
  actions,
  children,
  icon,
  dark,
  light,
  ...props
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [subOpen, setSubOpen] = useState<number>();
  const domElem = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const onClickOut = (e: MouseEvent) => {
      if (domElem.current && !domElem.current.contains(e.target as Node) && domElem.current !== e.target) {
        setOpen(false);
        setSubOpen(undefined);
      }
    };

    document.addEventListener("click", onClickOut);

    return function cleanup() {
      removeEventListener("click", onClickOut);
    };
  }, []);

  const tooltipClasses = twMerge(
    classNames(`${dropDownClasses({ position, light })} flex flex-col gap-1 p-2`, {
      "top-[calc(100%)]": position === "bottom-left" || position === "bottom-right",
      "bottom-[calc(100%)]": position === "top-left" || position === "top-right",
      "rounded-tr-none": position === "bottom-left",
      "rounded-tl-none": position === "bottom-right",
      "rounded-br-none": position === "top-left",
      "rounded-bl-none": position === "top-right",
    })
  );

  const subTooltipClasses = twMerge(
    classNames(
      "absolute flex top-0 flex-col gap-1 bg-background-lightest p-2 z-30 rounded-2xl cursor-default shadow-lg shadow-background-light",
      {
        "dark:bg-background-dark dark:shadow-background-darker": !light,
        "right-full -translate-x-2.5 ": position === "bottom-left" || position === "top-left",
        "left-full translate-x-2.5 ": position === "bottom-right" || position === "top-right",
      }
    )
  );

  const wrapperClasses = classNames("w-fit relative", {
    dark: dark,
  });

  const classes = twMerge(
    classNames("flex items-center justify-center text-content-medium cursor-pointer", {
      "w-8 h-8": !children,
      "dark:text-content-bright": !light,
      "rounded-full hover:bg-background-lighter": !children && !open,
      "dark:hover:bg-background-medium": !children && !open && !light,
      "dark:bg-background-dark": !children && open && !light,
      "shadow-base bg-background-lightest": !children && open,
      "rounded-t-full": !children && open && (position === "bottom-left" || position === "bottom-right"),
      "rounded-b-full": !children && open && (position === "top-left" || position === "top-right"),

      [`${props.className}`]: !!props.className,
    })
  );

  const actionClasses = (action: Action) =>
    classNames("px-2 py-1 rounded-xl flex gap-2 whitespace-nowrap items-center w-full text-sm transition", {
      "text-content-medium cursor-pointer hover:bg-background-lighter hover:text-content-dark": !action.disabled,
      "text-content-light": action.disabled,
      "dark:text-content-dark": action.disabled && !light,
      "dark:hover:bg-background-medium dark:text-content-lighter dark:hover:text-content-bright":
        !action.disabled && !light,
    });

  const executeAction = (action?: () => void) => {
    if (action) {
      setOpen(false);
      setSubOpen(undefined);
      action();
    }
  };

  return (
    <div className={wrapperClasses}>
      <div
        {...props}
        tabIndex={0}
        onClick={(e) => {
          e.stopPropagation();
          setOpen(!open);
        }}
        ref={domElem}
        className={classes}
      >
        {icon ? <div className="h-5 w-5">{icon}</div> : !children && <DotsVerticalIcon className="h-5 w-5" />}
        {children && children}
      </div>
      {open && (
        <div className={tooltipClasses} onClick={(e) => e.stopPropagation()}>
          {actions.map((a, index) =>
            a.popConfirm && a.popConfirm.show && !a.disabled && a.action ? (
              <div key={`popConfirm-${a.title}-${index}`}>
                <PopConfirm
                  light={light}
                  position={position}
                  className="w-full"
                  title={a.popConfirm.title || "Êtes-vous sûr de vouloir supprimer cet élément ?"}
                  onValidate={() => executeAction(a.action)}
                  onClick={() => setSubOpen(undefined)}
                  onRefuse={() => setOpen(false)}
                >
                  <div className={actionClasses(a)}>
                    {a.icon && <div className="h-4 w-4">{a.icon}</div>}
                    <div>{a.title}</div>
                  </div>
                </PopConfirm>
              </div>
            ) : a.action ? (
              <div
                key={`action-${a.title}-${index}`}
                className={actionClasses(a)}
                onClick={() => {
                  if (!a.disabled) executeAction(a.action);
                }}
              >
                {a.icon && <div className="h-4 w-4">{a.icon}</div>}
                <div>{a.title}</div>
              </div>
            ) : (
              <div className="relative" key={`sub-${a.title}-${index}`}>
                <div
                  className={actionClasses(a)}
                  onClick={() => (subOpen === index ? setSubOpen(undefined) : setSubOpen(index))}
                >
                  {a.icon && <div className="h-4 w-4">{a.icon}</div>}
                  <div>{a.title}</div>
                  <div className="flex-1 min-h-[16px] min-w-[16px] flex items-center justify-end">
                    <ChevronRightIcon width={16} height={16} />
                  </div>
                </div>
                {subOpen === index && a.subElems && (
                  <div className={subTooltipClasses} onClick={(e) => e.stopPropagation()}>
                    {a.subElems.map((sub, subIndex) => (
                      <div
                        key={`subElem-${sub.title}-${subIndex}`}
                        className={actionClasses(sub)}
                        onClick={() => {
                          if (!sub.disabled) executeAction(sub.action);
                        }}
                      >
                        {sub.icon && <div className="h-4 w-4">{sub.icon}</div>}
                        <div>{sub.title}</div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            )
          )}
        </div>
      )}
    </div>
  );
};
