import { useEffect, useState } from "react";
import { UUID } from "../models/common";
import { toast } from "react-hot-toast";

export const useDebounce = (value: string | undefined, delay: number) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(value), delay);

    return () => {
      clearTimeout(timer);
    };
  }, [delay, value]);

  return debouncedValue;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const debounce = (fn: (...args: any) => void, timeout = 300) => {
  let timeoutId: NodeJS.Timeout;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (...args: any[]) => {
    clearTimeout(timeoutId);

    timeoutId = setTimeout(() => fn.apply(this, args), timeout);
  };
};

export function cleanObj<T>(obj: T): T {
  for (const propName in obj) {
    if (obj[propName] === null || obj[propName] === undefined) {
      delete obj[propName];
    }
  }
  return obj;
}

export const copyToClipboard = (str: string, notif?: string) => {
  const el = document.createElement("textarea");
  el.value = str;
  el.setAttribute("readonly", "");
  el.style.position = "absolute";
  el.style.left = "-9999px";
  document.body.appendChild(el);
  el.select();
  document.execCommand("copy");
  document.body.removeChild(el);
  if (notif) toast(notif);
};

export const isGetProId = (id?: UUID) => id === "2975a765-b1e2-4f04-898a-fc1a37228c7b";

export const sleep = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const randomString = (length = 8) => {
  const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789_-.";

  let str = "";
  for (let i = 0; i < length; i++) {
    str += chars.charAt(Math.floor(Math.random() * chars.length));
  }

  return str;
};

export const isEmailValid = (email: string): boolean => {
  const regEmail = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

  return regEmail.test(email);
};
