import React, { useEffect, useState } from "react";
import { Paginated, UUID } from "../../../models/common";
import dayjs from "dayjs";
import { euroDisplay } from "../../../utils/formatters";
import { Booking, BookingsFilters, deleteBooking } from "../../../models/billing/booking";
import { Table, ColumnProps, MoreActions, TagColor, Tag } from "@getprorecrutement/getpro-design";
import { PencilIcon, TrashIcon } from "@heroicons/react/24/outline";

const PER_PAGE = 10;

interface Props {
  bookings?: Paginated<Booking>;
  fetchBookings: (filters: BookingsFilters) => void;
  selectedBooking: (booking: Booking) => void;
  setForm: (value: { booking: boolean; billing: boolean }) => void;
  filters: BookingsFilters;
  setFilters: (value: BookingsFilters) => void;
}

export const BookingList = (props: Props) => {
  const { bookings, fetchBookings, selectedBooking, setForm, filters, setFilters } = props;
  const [booking, setBooking] = useState<Booking>();

  const columns: ColumnProps<Booking>[] = [
    {
      title: "Montant",
      dataIndex: "amount",
      key: "amount",
      render: (value) => (
        <Tag
          value={value ? euroDisplay(value) : "À définir"}
          light
          bordered
          type={value ? (parseInt(value) > 0 ? TagColor.Success : TagColor.Error) : TagColor.Warning}
        />
      ),
    },
    {
      title: "Date de création",
      dataIndex: "created_at",
      key: "created_at",
      // width: 150,
      render: (value) => <div>{dayjs(value).format("ll")}</div>,
    },
    {
      title: "Actions",
      width: 120,
      dataIndex: "id",
      key: "id",
      render: (_, record) => {
        return (
          <MoreActions
            light
            actions={[
              {
                title: "Editer",
                icon: <PencilIcon />,
                action: () => {
                  selectedBooking(record);
                  setForm({ billing: false, booking: true });
                },
              },
              {
                title: "Supprimer",
                action: () => onDelete(record.id),
                popConfirm: { show: true, title: "Supprimer le booking ?" },
                icon: <TrashIcon />,
              },
            ]}
          />
        );
      },
    },
  ];

  const onDelete = async (id: UUID) => {
    await deleteBooking(id);
    fetchBookings(filters);
  };

  useEffect(() => {
    if (booking && bookings) {
      setBooking((p) => bookings.data.find((e) => e.id === p?.id));
    }
  }, [bookings]);

  useEffect(() => {
    fetchBookings(filters);
  }, [filters]);

  return (
    <div className="px-6">
      {bookings && (
        <Table
          dataSource={bookings}
          columns={columns}
          light
          bordered
          pagination={{
            per_page: PER_PAGE,
            page: filters.page,
            onChange: (p) => setFilters({ ...filters, page: p }),
          }}
        />
      )}
    </div>
  );
};

export default BookingList;
