import { SearchIcon } from "@heroicons/react/outline";
import classNames from "classnames";
import React, { createRef, useEffect, useRef, useState } from "react";
import { ColumnProps } from ".";
import { Light } from "../../stories/Tab.stories";
import { TextInput } from "../Form/Inputs";
import { PopOver } from "../PopOver";
import { SortableBtn } from "./SortableBtn";

export interface TextInputDroopDownInterface<T> {
  col: ColumnProps<T>;
  currentValue: string;
  sortBy?: { key: keyof T; sortDirection: "asc" | "desc" | undefined };
  filter?: {
    filters: { [key in keyof T]?: any[] | string };
    onFilterChange: (key: keyof T, value: any) => void;
  };
  onSortDirectionChange?: (key: keyof T, direction?: "asc" | "desc") => void;
  light?: boolean;
}

export const TextInputDropDown = <T extends unknown>({
  col,
  currentValue,
  filter,
  sortBy,
  light,
  onSortDirectionChange,
}: TextInputDroopDownInterface<T>) => {
  const [showSearch, setShowSearch] = useState<boolean>(false);
  const colLabelClasses = classNames(
    "py-1 px-2 w-fit text-2xs font-bold text-content-darker flex h-full justify-left gap-2 items-center",
    {
      "dark:text-content-medium": !light,
    }
  );

  const wrapperLabelClasses = classNames("bg-background-lightest rounded-full py-1.5", {
    "dark:bg-background-dark": !light,
  });

  return (
    <div
      className={colLabelClasses}
      onClick={() => setShowSearch(!showSearch)}
      onBlur={(ev) => {
        if (!ev.currentTarget.contains(ev.relatedTarget)) setShowSearch(false);
      }}
      tabIndex={0}
    >
      {showSearch ? (
        <div className={wrapperLabelClasses}>
          <TextInput
            autoFocus={true}
            bordered={false}
            type="text"
            size="small"
            light={light}
            inputClassName="py-0"
            placeholder={`Chercher un ${col.title}`}
            value={currentValue}
            onClick={(ev) => ev.stopPropagation()}
            onChange={({ target }) => {
              filter?.onFilterChange(col.dataIndex, target.value);
            }}
          />
        </div>
      ) : (
        <div className="flex justify-left items-center gap-1 cursor-pointer">
          <div>{col.title}</div>
          <SearchIcon height={16} width={16} />
          {col.sortable && (
            <SortableBtn
              currentSort={sortBy?.key === col.dataIndex ? sortBy.sortDirection : undefined}
              sortable={col.sortable}
              onDirectionChange={(newDirection) => {
                onSortDirectionChange?.(col.dataIndex, newDirection);
              }}
            />
          )}
        </div>
      )}
    </div>
  );
};
