import { Modal, TextInput } from "@getprorecrutement/getpro-design";
import { BeakerIcon, MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import React, { useEffect, useState } from "react";
import { MeiliSearch, SearchResponse } from "meilisearch";
import { UUID } from "../../../models/common";
import { OmnisearchResults } from "./results";
import { OmnisearchHistory } from "./history";
import classNames from "classnames";
import ROUTES from "../../../services/http/routes";
import httpClient from "../../../services/http/client";
import { toast } from "react-hot-toast";
import store from "../../../services/store";

const searchClient = new MeiliSearch({
  host: process.env.MEILISEARCH_API_URL as string,
  apiKey: process.env.MEILISEARCH_PUBLIC_API_KEY,
});

export type SearchedCompany = {
  id: UUID;
  name: string;
  logo_url: string | null;
};

export type SearchedCandidate = {
  id: UUID;
  full_name: string;
  linkedin_url: string | null;
  picture_url: string | null;
};

export type SearchedJobOffer = {
  id: UUID;
  name: string;
  company_name: string;
  company_logo_id: string | null;
  business_unit_name: string;
  owner_name: string;
  archived_at: string | null;
};

export type SearchResults = Array<JobOffersResult | CompaniesResult | CandidatesResult>;

export type JobOffersResult = { indexUid: "job_offers" } & SearchResponse<SearchedJobOffer>;
export type CompaniesResult = { indexUid: "companies" } & SearchResponse<SearchedCompany>;
export type CandidatesResult = { indexUid: "candidates" } & SearchResponse<SearchedCandidate>;

type Props = {
  collapsed: boolean;
};

async function reloadMeilisearchData() {
  await httpClient.req(ROUTES.CLEAR_MEILISEARCH_DATA());
  toast.success("Cleared indices");
  await httpClient.req(ROUTES.POPULATE_MEILISEARCH_DATA());
  toast.success("Populated indices");
}

const Omnisearch = ({ collapsed }: Props) => {
  const [shown, setShown] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [results, setResults] = useState<SearchResults>([]);

  const onSearch = async (q: string) => {
    const result = await searchClient.multiSearch({
      queries: [
        {
          indexUid: "job_offers",
          q,
          limit: 5,
          //   filter: ["archived_at IS NULL"],
        },
        { indexUid: "candidates", q, limit: 5 },
        { indexUid: "companies", q, limit: 5 },
      ],
    });

    console.log(result);

    setResults(result.results as unknown as SearchResults);
  };

  useEffect(() => {
    if (searchQuery.length > 1) onSearch(searchQuery);
  }, [searchQuery]);

  const shortcutOmnisearch = (e: KeyboardEvent) => {
    if (e.key.toLowerCase() === "f" && e.ctrlKey && e.shiftKey) {
      setShown(true);
    } else if (e.key === "Escape") setShown(false);
  };

  useEffect(() => {
    document.addEventListener("keydown", shortcutOmnisearch);

    return () => {
      document.removeEventListener("keydown", shortcutOmnisearch);
    };
  }, [shortcutOmnisearch]);

  const menuClasses = classNames("flex gap-2 items-center bg-background-medium rounded-full cursor-text", {
    "p-2": collapsed,
    "px-4 py-1 flex-grow": !collapsed,
  });

  const testClasses = classNames(
    "text-content-light overflow-hidden p-1 w-full transition-all flex-grow text-sm select-none",
    {
      hidden: collapsed,
    }
  );

  return (
    <div style={{ width: collapsed ? 96 : 208 }} className="transition-all p-2 flex justify-center">
      <div className={menuClasses} onClick={() => setShown(true)}>
        <MagnifyingGlassIcon className="h-5 w-5 text-content-light stroke-2" />
        <div className={testClasses}>Recherche</div>
      </div>

      <Modal show={shown} onClose={() => setShown(false)}>
        <div className="flex flex-col w-[400px]">
          <div className="flex gap-2 items-center relative">
            <MagnifyingGlassIcon className="h-6 w-6 text-content-light stroke-2" />
            <div className="flex-grow">
              <TextInput
                onChange={({ target }) => setSearchQuery(target.value)}
                value={searchQuery}
                type="text"
                placeholder="Recherche globale (CTRL+Shift+F)"
                autoFocus
                bordered={false}
              />
            </div>
            {store.state.JWT?.superadmin && (
              <BeakerIcon className="absolute right-0 cursor-pointer h-6 w-6" onClick={() => reloadMeilisearchData()} />
            )}
          </div>
          {searchQuery.length > 1 ? (
            <OmnisearchResults results={results} closeModal={() => setShown(false)} />
          ) : (
            <OmnisearchHistory closeModal={() => setShown(false)} />
          )}
        </div>
      </Modal>
    </div>
  );
};

export default Omnisearch;
