import React, { useState } from "react";
import { UUID } from "../../../models/common";
import { Contact, createContact, NewContact, updateContact } from "../../../models/contacts";
import { TextInput, Button } from "@getprorecrutement/getpro-design";
import { useFormManager } from "../../../services/form";

interface Props {
  customerId: UUID;
  contact?: Partial<Contact>;
  onFinish: (contact: Contact) => void;
  onCancel: () => void;
}

export const ContactForm = (props: Props) => {
  const { customerId, contact, onCancel } = props;
  const [loading, setLoading] = useState(false);
  const [editContact, setEditContact] = useState<Partial<Contact>>(contact || {});

  const submit = async (data: Partial<Contact>) => {
    setLoading(true);
    try {
      const contact = await (data.id
        ? updateContact(data as Contact)
        : createContact({ ...data, customer_id: customerId } as NewContact));
      setEditContact({});
      props.onFinish(contact);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="contactForm bg-background-bright my-2">
      <ContactInputs contact={editContact} loading={loading} onSubmit={submit} onCancel={onCancel} />
    </div>
  );
};

export default ContactForm;

interface InputProps {
  contact: Partial<Contact>;
  loading: boolean;
  onSubmit: (data: Partial<Contact>) => void;
  onCancel: () => void;
}

export const ContactInputs = (props: InputProps) => {
  const { contact, loading, onSubmit, onCancel } = props;
  // const [editContact, setEditContact] = useState<Partial<Contact>>(contact);
  const form = useFormManager<Contact>({
    defaultState: contact,
    validations: {
      first_name: {
        required: true,
        message: "Merci de préciser le prénom du contact",
      },
      last_name: {
        required: true,
        message: "Merci de préciser le nom du contact",
      },
      email: {
        required: true,
        message: "Merci de préciser l'adresse e-mail du contact",
      },
      phone: {
        required: true,
        message: "Merci de préciser le numéro de téléphone du contact",
      },
    },
  });

  return (
    <div className="bg-inherit flex flex-col gap-4">
      <TextInput
        label="Prénom"
        placeholder="Prénom"
        type="text"
        value={form.state.first_name}
        error={form.errors.first_name}
        onChange={(e) => form.onChange({ first_name: e.target.value })}
        light
      />
      <TextInput
        label="Nom"
        placeholder="Nom"
        type="text"
        value={form.state.last_name}
        error={form.errors.last_name}
        onChange={(e) => form.onChange({ last_name: e.target.value })}
        light
      />
      <TextInput
        label="Adresse e-mail"
        placeholder="Adresse e-mail"
        type="email"
        value={form.state.email}
        error={form.errors.email}
        onChange={(e) => form.onChange({ email: e.target.value })}
        light
      />
      <TextInput
        label="Numéro de téléphone"
        placeholder="Numéro de téléphone"
        type="tel"
        // TODO ERROR
        // error={
        //   editContact.phone && phoneValidate(editContact.phone) === ""
        //     ? "Merci de préciser un numéro de téléphone valide"
        //     : undefined
        // }
        value={form.state.phone}
        error={form.errors.phone}
        onChange={(e) => form.onChange({ phone: e.target.value })}
        light
      />
      <TextInput
        label="Titre de poste"
        placeholder="Titre de poste"
        type="text"
        value={form.state.title}
        onChange={(e) => form.onChange({ title: e.target.value })}
        light
      />
      <div className="flex justify-between gap-2">
        <Button
          light
          title="Annuler"
          onClick={() => {
            // setEditContact({});
            // form.onChange
            onCancel();
          }}
          size="small"
        />
        <Button
          light
          disabled={form.disabled}
          onClick={() => form.validate() && onSubmit(form.state)}
          title={contact?.id ? "Editer" : "Ajouter"}
          loading={loading}
          size="small"
        />
      </div>
    </div>
  );
};
