import { UUID } from "../common";
import ROUTES from "../../services/http/routes";
import { AuthResponse, LoginPayload, OauthPayload } from "../auth";
import httpClient from "../../services/http/client";
import { notifyError } from "../../services/requests";
import store from "../../services/store";
import { UserSettings } from "./settings";

export interface UserResponse {
  id: UUID;
  first_name: string;
  last_name: string;
  title: string;
  linkedin_url: string;
  email: string;
  role_id: UUID;
  created_at: string;
  updated_at: string;
  email_signature?: string;
  superadmin: boolean;
  locked: boolean;
  accept_template_subject?: string;
  accept_template_body?: string;
  reject_template_subject?: string;
  reject_template_body?: string;
  business_unit_id: UUID;
  full_name: string;
}

export interface NewUser {
  first_name: string;
  last_name: string;
  email: string;
  role_id: UUID;
  password: string;
  title: string;
  linkedin_url?: string;
  business_unit_id: UUID;
}

export interface UserData {
  id: UUID;
  first_name?: string;
  last_name?: string;
  email?: string;
  role_id?: UUID;
  title?: string;
  password?: string;
  email_signature?: string;
  linkedin_url?: string;
  accept_template_body?: string;
  accept_template_subject?: string;
  reject_template_body?: string;
  reject_template_subject?: string;
  settings?: UserSettings;
  business_unit_id?: UUID;
}

export async function login(data: LoginPayload): Promise<AuthResponse> {
  return httpClient.req(ROUTES.LOGIN(data), notifyError);
}

export async function googleAuth(redirect_url: string): Promise<{ url: string }> {
  return httpClient.req(ROUTES.GOOGLE_OAUTH({ redirect_url }), (err) =>
    notifyError(err || "Erreur lors de l'authentification avec Google")
  );
}

export async function googleAuthCb(params: OauthPayload): Promise<AuthResponse> {
  return httpClient.req(ROUTES.GOOGLE_OAUTH_CB(params), notifyError);
}

export async function getMe(): Promise<UserResponse> {
  return store.cached("users/me", 60 * 30, () => httpClient.req(ROUTES.FETCH_ME(), notifyError));
}

export async function getUser(id: UUID, error?: string): Promise<UserResponse> {
  return store.cached(`user-${id}`, 60 * 30, () =>
    httpClient.req(ROUTES.FETCH_USER(id), error ? () => notifyError(error) : notifyError)
  );
}

export async function getUsers(): Promise<UserResponse[]> {
  return store.cached("users", 60 * 30, () =>
    httpClient.req(ROUTES.FETCH_USERS(), (err) => notifyError(err || "Erreur lors de la récupération des utilisateurs"))
  );
}

export async function setLockUnlockUser(id: UUID): Promise<UserResponse> {
  return httpClient.req(ROUTES.LOCK_UNLOCK_USER(id), notifyError);
}

export async function deleteUser(id: UUID): Promise<UserResponse> {
  store.invalidateCache(["users", `user-${id}`]);
  return httpClient.req(ROUTES.DELETE_USER(id), notifyError);
}

export async function updateUser(data: UserData): Promise<UserResponse> {
  store.invalidateCache(["users", `user-${data.id}`, "users/me"]);
  return httpClient.req(ROUTES.UPDATE_USER(data), notifyError);
}

export async function createUser(new_user: NewUser): Promise<UserResponse> {
  store.invalidateCache(["users"]);
  return httpClient.req(ROUTES.CREATE_USER(new_user), notifyError);
}
