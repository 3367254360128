import { UUID } from "crypto";
import httpClient from "../services/http/client";
import ROUTES from "../services/http/routes";

export interface Hierarchy {
  id: UUID;
  title: string;
}

export const fetchHierarchies = (): Promise<Hierarchy[]> => httpClient.req(ROUTES.FETCH_HIERARCHIES());
