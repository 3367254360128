import React, { useEffect, useState } from "react";
import { Paginated, UUID } from "../../../../models/common";
import {
  Bill,
  BillsFilters,
  BillStatus,
  BillData,
  updateBill,
  deleteBill,
  BillResponse,
  BillLine,
} from "../../../../models/billing/bill";
import dayjs from "dayjs";
import { euroDisplay } from "../../../../utils/formatters";
import { Link } from "react-router-dom";
import PdfBtn from "./pdfBtn";
import { ProjectType } from "../../../../models/billing/project";
import {
  Tooltip,
  Table,
  ColumnProps,
  Modal,
  MoreActions,
  Button,
  Tag,
  TagColor,
  DatePicker,
} from "@getprorecrutement/getpro-design";
import {
  ArrowRightIcon,
  CheckCircleIcon,
  ClockIcon,
  PencilIcon,
  QuestionMarkCircleIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";

const PER_PAGE = 10;
interface Props {
  bills?: Paginated<BillResponse>;
  fetchBills: (filters: BillsFilters, project_types: ProjectType[]) => void;
  selectedBill: (bill: Bill) => void;
  setForm: (value: { booking: boolean; billing: boolean }) => void;
  filters: BillsFilters;
  projectTypes: ProjectType[];
  setFilters: (value: BillsFilters) => void;
}

export const BillList = (props: Props) => {
  const { bills, fetchBills, selectedBill, setForm, filters, projectTypes, setFilters } = props;
  const [bill, setBill] = useState<Bill>();
  const [toPaid, setToPaid] = useState<boolean>();
  const [loading, setLoading] = useState(false);
  const [paidAt, setPaidAt] = useState<string | undefined>(
    (bill?.paid_at ? dayjs(bill?.paid_at) : dayjs()).format("YYYY-MM-DD")
  );

  const columns: ColumnProps<BillResponse>[] = [
    {
      title: "Montant HT",
      width: 110,
      dataIndex: "amount",
      key: "amount-HT",
      render: (value) => (
        <Tag
          value={value ? euroDisplay(value) : "À définir"}
          light
          bordered
          type={value ? (parseInt(value) > 0 ? TagColor.Success : TagColor.Error) : TagColor.Warning}
        />
      ),
    },
    {
      title: "Montant TTC",
      width: 110,
      dataIndex: "amount",
      key: "amount-TTC",
      render: (value, bill) => (
        <Tag
          value={
            value
              ? euroDisplay(Math.round((value + (value * bill.contract_vat_percent) / 100) * 100) / 100)
              : "À définir"
          }
          light
          bordered
          type={value ? (parseInt(value) > 0 ? TagColor.Success : TagColor.Error) : TagColor.Warning}
        />
      ),
    },
    {
      title: "Client",
      dataIndex: "customer_name",
      key: "customer_name",
      render: (value: string, record: Bill) => (
        <Tooltip light title={value}>
          <Link to={`/billing/customers/${record.customer_id}`}>
            <div className="cursor-pointer truncate">{value}</div>
          </Link>
        </Tooltip>
      ),
    },
    {
      title: "Projet",
      dataIndex: "project_name",
      key: "project_name",
      render: (value: string, record: Bill) => (
        <Tooltip light title={value}>
          <Link to={`/billing/customers/${record.customer_id}/projects/${record.project_id}`}>
            <div className="cursor-pointer truncate">{value}</div>
          </Link>
        </Tooltip>
      ),
    },
    {
      title: "Contrat",
      dataIndex: "contract_name",
      key: "contract_name",
      render: (value: string, record: Bill) => (
        <Tooltip light title={value}>
          <Link to={`/billing/contracts/${record.contract_id}`}>
            <div className="cursor-pointer truncate">{value}</div>
          </Link>
        </Tooltip>
      ),
    },
    {
      title: "Échéance",
      dataIndex: "due_date",
      key: "due_date",
      width: 120,
      render: (value, record: Bill) => (
        <span className={dayjs(value) < dayjs() && record.status !== BillStatus.Paid ? "text-red-600" : ""}>
          {dayjs(value).format("ll")}
        </span>
      ),
    },
    {
      title: "Facturation",
      dataIndex: "billed_at",
      key: "billed_at",
      width: 120,
      sortable: true,
      render: (value) => <div>{dayjs(value).format("ll")}</div>,
    },
    {
      title: "Statut",
      dataIndex: "status",
      key: "status",
      width: 120,
      render: (value: string, record: Bill) => {
        const status = value as BillStatus;

        return (
          <div className="flex group/statuses">
            <div>
              {status === BillStatus.Pending && (
                <Tooltip light title="En attente">
                  <ClockIcon className="text-orange-600 w-5 h-5" />
                </Tooltip>
              )}
              {status === BillStatus.Billed && (
                <Tooltip light title={"Facturé"}>
                  <QuestionMarkCircleIcon className="text-blue-600 w-5 h-5" />
                </Tooltip>
              )}
              {status === BillStatus.Paid && (
                <Tooltip light title={"Payé"}>
                  <CheckCircleIcon className="text-green-600 w-5 h-5" />
                </Tooltip>
              )}
            </div>
            <div className="hidden group-hover/statuses:flex items-center gap-2 ml-2">
              <ArrowRightIcon width={20} height={20} />
              {status !== BillStatus.Pending && (
                <Tooltip light title="En attente">
                  <ClockIcon
                    onClick={(e) =>
                      _updateBill(
                        {
                          id: record.id,
                          status: BillStatus.Pending,
                        },
                        e
                      )
                    }
                    className="text-orange-600 w-5 h-5"
                  />
                </Tooltip>
              )}
              {status !== BillStatus.Billed && (
                <Tooltip light title="Facturé">
                  <QuestionMarkCircleIcon
                    onClick={(e) =>
                      _updateBill(
                        {
                          id: record.id,
                          status: BillStatus.Billed,
                        },
                        e
                      )
                    }
                    className="text-blue-600 w-5 h-5"
                  />
                </Tooltip>
              )}
              {status !== BillStatus.Paid && (
                <Tooltip light title="Payé">
                  <CheckCircleIcon
                    onClick={(e) => {
                      e.stopPropagation();
                      setBill(record);
                      setToPaid(true);
                    }}
                    className="text-green-600 w-5 h-5"
                  />
                </Tooltip>
              )}
            </div>
          </div>
        );
      },
    },
    {
      title: "Actions",
      width: 200,
      dataIndex: "id",
      key: "id",
      render: (_, record) => {
        return (
          <div className="flex justify-end items-center gap-4">
            <PdfBtn id={record.id} fileId={record.file_id} billRef={record.bill_ref} />
            <MoreActions
              light
              actions={[
                {
                  title: "Editer",
                  icon: <PencilIcon />,
                  action: () => {
                    selectedBill(record);
                    setForm({ booking: false, billing: true });
                  },
                },
                {
                  title: "Supprimer",
                  action: () => onDelete(record.id),
                  popConfirm: { show: true, title: "Supprimer la facture ?" },
                  icon: <TrashIcon />,
                },
              ]}
            />
          </div>
        );
      },
    },
  ];

  const onDelete = async (id: UUID) => {
    await deleteBill(id);
    fetchBills(filters, projectTypes);
  };

  useEffect(() => {
    if (bill && bills) {
      setBill((p) => bills.data.find((e) => e.id === p?.id));
    }
  }, [bills]);

  useEffect(() => {
    fetchBills(filters, projectTypes);
  }, [filters, projectTypes]);

  const onFinishPaid = async (data: BillData) => {
    if (toPaid && bill) {
      _updateBill({
        id: bill.id,
        status: BillStatus.Paid,
        paid_at: data.paid_at,
      });
      setToPaid(false);
      setPaidAt(undefined);
    }
  };

  const _updateBill = async (data: BillData & { id: UUID }, e?: React.MouseEvent) => {
    setLoading(true);
    if (e) e.stopPropagation();
    try {
      await updateBill(data);
      fetchBills(filters, projectTypes);
    } finally {
      setLoading(false);
    }
  };

  const expandedBillList = (lines: (BillLine & { contract_vat_percent: number })[]) => {
    const columns: ColumnProps<BillLine & { contract_vat_percent: number }>[] = [
      { title: "Description", dataIndex: "description", key: "description" },
      {
        title: "Montant TTC",
        dataIndex: "amount",
        key: "amount-TCC-expanded",
        width: 140,
        render: (amount: number | undefined, record: BillLine & { contract_vat_percent: number }) => (
          <div>
            {amount && euroDisplay(Math.round((amount + (amount * record.contract_vat_percent) / 100) * 100) / 100)}
          </div>
        ),
      },
      {
        title: "Montant",
        dataIndex: "amount",
        width: 140,
        key: "amount-HT-expanded",
        render: (amount: number | undefined) => <div>{amount && euroDisplay(amount)}</div>,
      },
      {
        title: "Quantité",
        dataIndex: "quantity",
        key: "quantity",
        width: 120,
        render: (e: number | undefined) => <div>{typeof e === "number" && e}</div>,
      },
      {
        title: "Montant total",
        dataIndex: "amount",
        key: "amount-expanded",
        width: 140,
        render: (_: unknown, bill: BillLine & { contract_vat_percent: number }) => (
          <div>{euroDisplay((bill.amount || 0) * (typeof bill.quantity === "number" ? bill.quantity : 0))}</div>
        ),
      },
    ];

    return (
      <div className="p-2">
        <Table
          size="small"
          rowClassName={() => "hover:bg-white rounded-none"}
          // bordered={false}
          extend={{
            render: (data) => (data.note ? <div className="px-4 py-2">{data.note}</div> : undefined),
            extendKey: "note",
          }}
          columns={columns}
          dataSource={{ data: lines, total: lines.length }}
          light
        />
      </div>
    );
  };

  const handleTableChange = (sorter: { key: keyof BillResponse; direction: "desc" | "asc" | undefined }) => {
    if (sorter.direction) {
      const sortFilters = { field: sorter.key, order: sorter.direction === "asc" ? "ascend" : "descend" };
      setFilters({ ...filters, ...sortFilters });
    } else {
      setFilters({ ...filters, field: undefined, order: undefined });
    }
  };

  return (
    <div>
      <div className="px-6">
        <Table
          dataSource={bills || { data: [], total: 0 }}
          columns={columns}
          light
          bordered
          pagination={{
            per_page: PER_PAGE,
            page: filters.page,
            onChange: (p) => setFilters({ ...filters, page: p }),
          }}
          extend={{
            render: (data) =>
              expandedBillList(
                data.lines.map((l) => {
                  return { ...l, contract_vat_percent: data.contract_vat_percent };
                })
              ),
            extendKey: "lines",
          }}
          sortBy={
            filters.field && filters.order
              ? { key: filters.field as keyof BillResponse, sortDirection: filters.order === "ascend" ? "asc" : "desc" }
              : undefined
          }
          onSortDirectionChange={(key, direction) => handleTableChange({ key, direction })}
        />
      </div>
      <Modal onClose={() => setToPaid(false)} show={!!toPaid} className="w-modal-sm" light>
        <div className="mb-2 text-2xl">Informations de la facture</div>
        <div className="bg-inherit py-2 pr-4 overflow-auto">
          <DatePicker label="Date du paiement" light value={paidAt} onChange={setPaidAt} />
          <div className="flex justify-end mt-8">
            <Button
              loading={loading}
              size="small"
              light
              title="Enregister"
              disabled={!paidAt}
              onClick={() => onFinishPaid({ paid_at: paidAt })}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default BillList;
