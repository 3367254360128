import React, { FunctionComponent } from "react";
import { SignalTargetResponse } from "../../models/signals";
import { Spinner } from "@getprorecrutement/getpro-design";
import { ChevronRightIcon } from "@heroicons/react/24/outline";
import { UUID } from "../../models/common";
import { Customer } from "../../models/customers";

interface CompaniesListProps {
  companies?: {
    company: Customer;
    targets: SignalTargetResponse[];
  }[];
  selectedCompanyId?: UUID;
  setSelectedCompany: (cs: { company: Customer; targets: SignalTargetResponse[] }) => void;
  search?: string;
}

export const CompaniesList: FunctionComponent<CompaniesListProps> = ({
  companies,
  selectedCompanyId,
  setSelectedCompany,
  search,
}) => {
  return companies ? (
    <div className="flex-1 flex flex-col gap-2 px-2 overflow-auto">
      {companies
        .filter((cs) =>
          search && search.length > 0 ? cs.company.name.toLowerCase().includes(search.toLowerCase()) : true
        )
        .map((cs) => (
          <div
            key={cs.company.id}
            className={`flex gap-2 items-center rounded-2xl px-4 py-2 cursor-pointer ${
              selectedCompanyId === cs.company.id ? "bg-background-bright" : "hover:bg-background-bright"
            }`}
            onClick={() => setSelectedCompany(cs)}
          >
            <div className="min-w-[40px] min-h-[40px] w-10 h-10 flex items-center justify-center bg-white rounded-full">
              <img
                className="max-w-full max-h-full rounded-full"
                src={`https://storage.googleapis.com/getpro-public/${
                  cs.company.file_id || "e1ca4dfa-7173-40a1-8f9a-917dc3f3556f"
                }`}
              />
            </div>
            <div className="flex-1 max-w-[calc(100%-72px)]">
              <div className="font-bold truncate">{cs.company.name}</div>
              <div className="text-content-light">{cs.targets.length} collaborateurs</div>
            </div>
            <ChevronRightIcon width={14} height={14} className="text-content-light min-w-fit min-h-fit" />
          </div>
        ))}
    </div>
  ) : (
    <Spinner light />
  );
};
