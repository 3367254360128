import React, { useState } from "react";
import { FunctionComponent } from "react";
import { Table, ColumnProps, Tooltip, Progress } from "@getprorecrutement/getpro-design";

const PER_PAGE = 5;

interface Props {
  countByJobOffer?: [string, number][];
}

export const ContactedJobOffers: FunctionComponent<Props> = (props) => {
  const { countByJobOffer } = props;
  const [page, setPage] = useState<number>(1);

  const getPercentage = (value: number): number => {
    let totalContacted = 0;
    countByJobOffer?.map((e) => (totalContacted += e[1]));
    return Math.round((value / totalContacted) * 100);
  };

  const columns: ColumnProps<[string, number]>[] = [
    {
      title: "Poste",
      dataIndex: "0",
      key: "0",
      render: (_, record) => (
        <Tooltip light title={record[0]}>
          {record[0]}
        </Tooltip>
      ),
    },
    {
      title: "Nombre de candidats",
      width: 200,
      dataIndex: "1",
      key: "1",
      render: (_, record) => (
        <div className="w-4/5">
          <Tooltip light title={`${record[1]} (${getPercentage(record[1])} %)`}>
            <Progress light size={100} value={getPercentage(record[1])} inline labelMode="percentage" />
          </Tooltip>
        </div>
      ),
    },
  ];

  const josDisplayed = (countByJobOffer || [])
    .sort((a, b) => b[1] - a[1])
    .filter((_, i) => i >= (page - 1) * PER_PAGE && i < page * PER_PAGE);

  return (
    <Table
      size="small"
      dataSource={{ data: josDisplayed, total: countByJobOffer?.length || 0 }}
      columns={columns}
      light
      bordered
      pagination={{ per_page: PER_PAGE, page, onChange: setPage }}
    />
  );
};

export default ContactedJobOffers;
