import React, { FunctionComponent, useEffect, useState } from "react";
import {
  Category,
  CategoryType,
  NewCategory,
  deleteCategory as deleteCategoryRoute,
  updateCategory,
  createCategory,
  getCategories,
  getCategoryTypes,
} from "../../../models/categories";
import { UUID } from "../../../models/common";
import CategoryForm from "./form";
import { Table, Modal, Button, ColumnProps, PopConfirm } from "@getprorecrutement/getpro-design";
import { toast } from "react-hot-toast";
import { CheckIcon, TrashIcon, XMarkIcon } from "@heroicons/react/24/outline";

const PER_PAGE = 10;

export const SettingsCategories: FunctionComponent = () => {
  const [search, setSearch] = useState<string>("");
  const [categories, setCategories] = useState<Category[]>([]);
  const [categoryTypes, setCategoryTypes] = useState<CategoryType[]>([]);
  const [category, setCategory] = useState<Category | undefined>();
  const [edit, setEdit] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);

  const columns: ColumnProps<Category>[] = [
    {
      title: "Catégorie",
      dataIndex: "name",
      key: "name",
      filterBy: "text",
    },
    {
      title: "Type",
      dataIndex: "category_type_id",
      key: "category_type_id",
      render: (category_type_id) => (
        <div>{categoryTypes.find((ct) => ct.id === category_type_id)?.name || <b>Non défini</b>}</div>
      ),
      // TODO SORT
      // defaultSortOrder: "ascend",
      // sorter: (a: Category, b: Category) => {
      //   const aType = categoryTypes.find((ct) => ct.id === a.category_type_id)?.name || "";
      //   const bType = categoryTypes.find((ct) => ct.id === b.category_type_id)?.name || "";

      //   return aType.localeCompare(bType);
      // },
    },
    {
      title: "Filtrable (job board)",
      dataIndex: "index",
      key: "index",
      render: (index) =>
        (index as unknown as number) === -1 ? (
          <XMarkIcon width={20} height={20} />
        ) : (
          <div className="flex items-center gap-1">
            <CheckIcon width={20} height={20} />
            <div>({index})</div>
          </div>
        ),
    },
    {
      title: "",
      dataIndex: "id",
      key: "id",
      render: (_, { id }) => (
        <PopConfirm
          light
          title={"Êtes-vous sûr de vouloir supprimer cette catégorie ? Cela l'enlevera de toutes les offres"}
          onValidate={() => deleteCategory(id)}
        >
          <TrashIcon width={20} height={20} className="hover:text-red-400" />
        </PopConfirm>
      ),
    },
  ];

  useEffect(() => {
    if (page !== 1) setPage(1);
  }, [search]);

  useEffect(() => {
    fetchCategories();
    fetchCategoryTypes();
  }, []);

  const submitCategory = async (rawData: Category): Promise<void> => {
    const data = {
      ...rawData,
      index: rawData.index || 0,
    };

    await (category?.id ? updateCategory({ ...data, id: category.id }) : createCategory(data as NewCategory));

    toast.success(`Catégorie ${data.id ? "mise à jour" : "créée"} avec succès`);
    setEdit(false);
    fetchCategories();
  };

  const deleteCategory = async (id: UUID) => {
    await deleteCategoryRoute(id);

    toast.success(`Catégorie supprimée avec succès`);
    setCategory(undefined);
    fetchCategories();
  };

  const fetchCategories = async () => {
    getCategories().then(setCategories);
  };

  const fetchCategoryTypes = async () => {
    getCategoryTypes().then(setCategoryTypes);
  };

  const displayedCategories = (filterPage: boolean) => {
    const save = search ? categories.filter((c) => c.name.toLowerCase().includes(search.toLowerCase())) : categories;

    return filterPage ? save.filter((_, i) => i >= (page - 1) * PER_PAGE && i < page * PER_PAGE) : save;
  };

  return (
    <div className="w-full">
      <div className="bg-white p-6 shadow-md mb-6">
        <div className="flex justify-between items-center">
          <div className="text-3xl font-bold">Catégories</div>
          <Button
            onClick={() => {
              setCategory(undefined);
              setEdit(true);
            }}
            title="Nouvelle catégorie"
            light
            size="small"
          />
        </div>
      </div>
      <div className="px-6">
        <Table
          dataSource={{
            data: displayedCategories(true).map((e) => ({ key: e.id, ...e })),
            total: displayedCategories(false).length || 0,
          }}
          columns={columns}
          pagination={{ per_page: PER_PAGE, page, onChange: setPage }}
          light
          bordered
          filter={{
            filters: { name: search },
            onFilterChange: (key, value) => {
              if (key === "name") {
                setSearch(value);
              }
            },
          }}
        />
      </div>
      <Modal className="w-modal-md" light onClose={() => setEdit(false)} show={edit}>
        <CategoryForm
          categories={categories}
          categoryTypes={categoryTypes}
          key={category?.id || "new_category"}
          onSubmit={submitCategory}
          category={category}
        />
      </Modal>
    </div>
  );
};

export default SettingsCategories;
