import React, { FunctionComponent, useEffect, useState } from "react";
import {
  BusinessUnit,
  NewBusinessUnit,
  BusinessUnitData,
  createBusinessUnit,
  updateBusinessUnit,
  deleteBusinessUnit,
  getBusinessUnits,
} from "../../../models/businessUnits";
import BusinessUnitForm from "./form";
import { UUID } from "../../../models/common";
import { getUsers, UserResponse } from "../../../models/users/users";
import { Avatar } from "../../../components/userStatus";
import { Button, Modal, TextInput, MoreActions } from "@getprorecrutement/getpro-design";
import { PencilIcon, TrashIcon } from "@heroicons/react/24/outline";
import { toast } from "react-hot-toast";

export const SettingsBusinessUnits: FunctionComponent = () => {
  const [search, setSearch] = useState<string>("");
  const [businessUnits, setBusinessUnits] = useState<BusinessUnit[]>([]);
  const [businessUnit, setBusinessUnit] = useState<BusinessUnit | undefined>();
  const [users, setUsers] = useState<UserResponse[]>([]);
  const [edit, setEdit] = useState<boolean>(false);

  useEffect(() => {
    fetchBusinessUnits();
    fetchUsers();
  }, []);

  const fetchBusinessUnits = async () => {
    getBusinessUnits().then(setBusinessUnits);
  };

  const fetchUsers = async () => {
    getUsers().then(setUsers);
  };

  const submitBusinessUnit = async (data: BusinessUnitData): Promise<void> => {
    const res = await (data.id ? updateBusinessUnit(data) : createBusinessUnit(data as NewBusinessUnit));

    setBusinessUnit(res);
    toast.success(`Département ${data.id ? "mis à jour" : "créé"} avec succès`);
    setEdit(false);
    fetchBusinessUnits();
  };

  const deleteBu = async () => {
    await deleteBusinessUnit(businessUnit?.id as UUID);

    toast.success(`Département supprimé avec succès`);
    setBusinessUnit(undefined);
    fetchBusinessUnits();
  };

  const displayedBu = businessUnits.filter((c) => c.name.toLowerCase().includes(search.toLowerCase()));

  return (
    <div className="w-full">
      <div className="bg-white p-6 shadow-md mb-6">
        <div className="flex justify-between items-center gap-2 bg-inherit mb-4">
          <div className="text-3xl font-bold">Départements</div>
          <Button
            onClick={() => {
              setBusinessUnit(undefined);
              setEdit(true);
            }}
            title="Nouveau département"
            light
            size="small"
          />
        </div>
        <TextInput
          style={{ width: 300 }}
          type="search"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          light
          label="Recherche"
          placeholder="Recherche"
        />
      </div>
      <div className="flex justify-between gap-6 px-6">
        <div className="flex-1 flex h-fit gap-4 flex-wrap">
          {displayedBu.map((e) => (
            <div
              className="font-bold cursor-pointer p-6 bg-slate-200 shadow-md"
              onClick={() => setBusinessUnit(e)}
              key={e.id}
            >
              {e.name}
            </div>
          ))}
        </div>
        {businessUnit && (
          <div className="w-1/3">
            <div className="bg-white shadow-md p-6">
              <div className="flex justify-between mb-4 items-center">
                <div className="text-3xl font-bold">{businessUnit.name}</div>
                <MoreActions
                  light
                  actions={[
                    { title: "Editer", action: () => setEdit(true), icon: <PencilIcon /> },
                    {
                      title: "Supprimer",
                      action: deleteBu,
                      popConfirm: { show: true, title: "Supprimer le département ?" },
                      icon: <TrashIcon />,
                    },
                  ]}
                />
              </div>
              <div className="flex flex-col gap-2">
                {users
                  .filter((user) => user.business_unit_id === businessUnit.id && !user.locked)
                  .map((user) => (
                    <div className="flex items-center gap-2" key={user.id}>
                      <Avatar size="large" user={user} />
                      <div>
                        {user.first_name} {user.last_name}
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        )}
      </div>
      <Modal className="w-modal-sm" onClose={() => setEdit(false)} show={edit} light>
        <BusinessUnitForm
          key={businessUnit?.id || "NEW_BUSINESS_UNIT"}
          onSubmit={submitBusinessUnit}
          businessUnit={businessUnit}
        />
      </Modal>
    </div>
  );
};

export default SettingsBusinessUnits;
