import classNames from "classnames";
import React, { FunctionComponent, useEffect, useRef, useState } from "react";
import { twMerge } from "tailwind-merge";
import { ColorType } from "../../utils/common";
import { dropDownClasses } from "../../utils/common";
import { Button } from "../Button";

export interface Props {
  position?: "top" | "bottom" | "left" | "right" | "bottom-left" | "bottom-right" | "top-left" | "top-right";
  children: React.ReactNode;
  onValidate: React.MouseEventHandler<HTMLButtonElement>;
  onChange?: (show: boolean) => void;
  onRefuse?: React.MouseEventHandler<HTMLButtonElement>;
  colorType?: ColorType;
  dark?: boolean;
  light?: boolean;
}

export interface WithTitleProps extends Props {
  title: string;
  customRenderer?: undefined;
}

export interface WithCustomRendererProps extends Props {
  customRenderer: () => JSX.Element;
  title?: undefined;
}

export const PopConfirm: FunctionComponent<Omit<React.HTMLAttributes<HTMLDivElement>, "onChange"> &
  (WithTitleProps | WithCustomRendererProps)> = ({
  position = "top",
  children,
  customRenderer,
  title,
  onValidate,
  onChange,
  onRefuse,
  dark,
  light,
  colorType = ColorType.Primary,
  ...props
}) => {
  const [show, setShow] = useState<boolean>(false);
  const domElem = useRef<HTMLDivElement>(null);

  useEffect(() => {
    onChange && onChange(show);
  }, [show]);

  useEffect(() => {
    const onClickOut = (e: MouseEvent) => {
      if (domElem.current && !domElem.current.contains(e.target as Node) && domElem.current !== e.target) {
        setShow(false);
      }
    };

    document.addEventListener("click", onClickOut);

    return function cleanup() {
      removeEventListener("click", onClickOut);
    };
  }, []);

  const popConfirmClasses = twMerge(
    classNames("w-max relative", {
      [`${props.className}`]: !!props.className,
      dark: dark,
    })
  );

  const popConfirmTextClasses = classNames(`w-max text-sm text-center ${dropDownClasses({ position, light })}`, {
    "max-w-[240px]": !customRenderer,
    block: show,
    hidden: !show,
  });

  return (
    <div {...props} className={popConfirmClasses} ref={domElem}>
      <div className={popConfirmTextClasses}>
        {customRenderer ? customRenderer() : title}
        <div className="flex justify-center items-center gap-4 mt-3">
          {/* TODO: use dark or light buttons */}
          <Button
            title="Non"
            size="small"
            kind="outline"
            light={light}
            colorType={colorType}
            onClick={(ev) => {
              onRefuse && onRefuse(ev);
              setShow(false);
            }}
          />
          <Button
            title="Oui"
            size="small"
            colorType={colorType}
            light={light}
            onClick={(ev) => {
              onValidate(ev);
              setShow(false);
            }}
          />
        </div>
      </div>
      <div onClick={() => setShow(!show)} className="cursor-pointer">
        {children}
      </div>
    </div>
  );
};
