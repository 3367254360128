import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/outline";
import classNames from "classnames";
import React, { FunctionComponent, useState } from "react";

export interface Props<T> {
  values: T[];
  renderSubElem?: (val: T) => JSX.Element;
  renderAction?: () => JSX.Element;
  getKey: (val: T) => string;
  dark?: boolean;
  light?: boolean;
}

export interface customrender<T> {
  getLabel?: undefined;
  render: (val: T) => JSX.Element;
}

export interface labelRenderer<T> {
  render?: undefined;
  getLabel: (val: T) => String;
}

export const Tree = <T extends unknown>({
  values,
  renderAction,
  renderSubElem,
  render,
  getLabel,
  getKey,
  dark,
  light,
  ...props
}: Props<T> & (customrender<T> | labelRenderer<T>) & React.HTMLAttributes<HTMLDivElement>) => {
  const [subElemOpened, setSubElemOpened] = useState<string[]>([]);

  const wrapperClasses = classNames("ml-4 h-max relative mt-2.5", {
    dark: dark,
  });
  const leftLineClasses = classNames("absolute border-l-2 border-solid border-border-bright top-0 -translate-y-2.5", {
    "dark:border-border-dark": !light,
  });
  const elemClasses = "flex items-center h-10";
  const elemIndicatorClasses = classNames("h-[2px] w-4 bg-border-bright", {
    "dark:bg-border-dark": !light,
  });
  const elemRenderClasses = classNames("", {
    "text-content-darker text-sm px-2": !render,
    "dark:text-content-bright": !render && !light,
    "cursor-pointer": !!renderSubElem,
  });

  const openCloseSub = (value: T) => {
    if (subElemOpened.includes(getKey(value))) setSubElemOpened((elems) => elems.filter((e) => e !== getKey(value)));
    else setSubElemOpened(subElemOpened.concat(getKey(value)));
  };

  const treeElem = (val: T) => {
    return (
      <div {...props}>
        <div className={elemClasses}>
          <div className={elemIndicatorClasses} />
          {render ? (
            <div onClick={() => renderSubElem && openCloseSub(val)} className={elemRenderClasses}>
              {render(val)}
            </div>
          ) : (
            <div onClick={() => renderSubElem && openCloseSub(val)} className={elemRenderClasses}>
              {getLabel && getLabel(val)}
            </div>
          )}
          {renderSubElem && (
            <div onClick={() => openCloseSub(val)} className="cursor-pointer text-content-light">
              {subElemOpened.includes(getKey(val)) ? <ChevronUpIcon width={15} /> : <ChevronDownIcon width={15} />}
            </div>
          )}
        </div>
        {subElemOpened.includes(getKey(val)) && renderSubElem && <div className="ml-4">{renderSubElem(val)}</div>}
      </div>
    );
  };

  const treeAction = () => {
    return (
      renderAction && (
        <div className={elemClasses}>
          <div className={elemIndicatorClasses} />
          {renderAction()}
        </div>
      )
    );
  };

  return (
    <div className={wrapperClasses}>
      <div style={{ height: "calc(100% - 10px)" }} className={leftLineClasses} />
      {values.map((value, i) => (
        <div key={i}>{treeElem(value)}</div>
      ))}
      {treeAction()}
    </div>
  );
};
