import { UUID } from "./common";
import { Candidate } from "./candidate";
import httpClient from "../services/http/client";
import ROUTES from "../services/http/routes";
import { notifyError } from "../services/requests";
import { EmailData } from "./messenger";
import { EventModel } from "./event";
import { PublicQuestion } from "./jobOffers";
import toast from "react-hot-toast";

export enum JobApplicationTerminationSource {
  GetPro = "getpro",
  Client = "client",
  Candidate = "candidate",
}
export const JA_TERMINATION_SOURCES = {
  [JobApplicationTerminationSource.GetPro]: "GetPro",
  [JobApplicationTerminationSource.Client]: "Client",
  [JobApplicationTerminationSource.Candidate]: "Candidat",
};

export interface Answer {
  value: boolean | number;
  target: PublicQuestion;
}

export interface JobApplicationBase extends NewJobApplication {
  id: UUID;
  updated_at: string;
  created_at: string;
  status_id: UUID; // Not an option
  user_id: UUID;
  customized_email?: string;
  customized_email_subject?: string;
  status_updated_at: string;
  contacted: boolean;
  contacted_by_email: boolean;
  contacted_by_inmail: boolean;
  contacted_by_phone: boolean;
  starts_job_at?: string;
  terminated?: boolean;
  termination_source?: JobApplicationTerminationSource;
  termination_comment?: string;
  pinned: boolean;
}

export interface JobApplicationResponse extends JobApplicationBase {
  candidate: Candidate;
}

export interface CondensedJobApplication extends JobApplicationBase {
  candidate_id: UUID;
  job_offer_id: UUID;
  job_offer_name: string;
  job_offer_company_name: string;
  job_offer_archived: boolean;
  source_id?: UUID;
  description?: string;
  call_status_id?: UUID;
  status_updated_at: string;
  answers?: Answer[];
}

export interface JobApplicationData {
  job_offer_id?: UUID;
  candidate_id?: UUID;
  description?: string;
  source_id?: UUID;
  status_id?: UUID;
  call_status_id?: UUID;
  customized_email?: string;
  customized_email_subject?: string;
  status_updated_at?: string;
  contacted_by_inmail?: boolean;
  contacted?: boolean;
  contacted_by_phone?: boolean;
  contacted_by_email?: boolean;
  starts_job_at?: string;
  answers?: Answer[];
  pinned?: boolean;
}

export interface NewJobApplication {
  job_offer_id: UUID;
  candidate_id: UUID;
  source_id?: UUID;
  status_id?: UUID;
  call_status_id?: UUID;
  description?: string;
  answers?: Answer[];
}

export interface JobapplicationTerminationRequest {
  termination_source: JobApplicationTerminationSource;
  termination_comment?: string;
}

export async function getJa(id: UUID): Promise<JobApplicationResponse> {
  return httpClient.req(ROUTES.FETCH_JOB_APPLICATION(id), (err) =>
    notifyError(err || "Erreur lors de la récupération de la candidature")
  );
}

export async function getEvents(id: UUID): Promise<EventModel[]> {
  return httpClient.req(ROUTES.FETCH_EVENTS(id), (err) =>
    notifyError(err || "Erreur lors de la récupération de la timeline de la candidature")
  );
}

export async function getEmailTemplate(
  id: UUID,
  kind: "accept" | "reject" | UUID
): Promise<{ body?: string; subject?: string }> {
  return httpClient.req(ROUTES.FETCH_TEMPLATE(id, kind), (err) =>
    notifyError(err || "Erreur lors de la récupération du template de mail")
  );
}

export async function sendJaEmail(id: UUID, data: EmailData): Promise<void> {
  return httpClient.req(ROUTES.SEND_EMAIL_JOB_APP(id, data), (err) => notifyError(err || "Erreur lors de l'envoi"));
}

export async function createJa(new_ja: NewJobApplication): Promise<JobApplicationResponse> {
  return httpClient
    .req<JobApplicationResponse>(ROUTES.CREATE_JOB_APPLICATION(new_ja), (err) =>
      notifyError(err || "Erreur lors de la création de la candidature")
    )
    .then((e) => {
      toast.success("Candidature créée avec succès");
      return e;
    });
}

export async function updateJa(data: JobApplicationData & { id: UUID }): Promise<JobApplicationResponse> {
  return httpClient.req(ROUTES.UPDATE_JOB_APPLICATION(data), (err) =>
    notifyError(err || "Échec de la mise à jour de la candidature")
  );
}

export async function deleteJa(id: UUID): Promise<void> {
  return httpClient.req(ROUTES.DELETE_JOB_APPLICATION(id), notifyError);
}

export async function terminateJa(
  id: UUID,
  terminationRequest: JobapplicationTerminationRequest
): Promise<JobApplicationResponse> {
  return httpClient.req(ROUTES.TERMINATE_JOB_APPLICATION(id, terminationRequest), notifyError);
}

export async function restartJa(id: UUID): Promise<JobApplicationResponse> {
  return httpClient.req(ROUTES.RESTART_JOB_APPLICATION(id), notifyError);
}
