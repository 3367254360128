import React, { FunctionComponent, useEffect, useState } from "react";
import { MessengerData, EmailData } from "../../models/messenger";
import store, { Message } from "../../services/store";
import HtmlEditor from "../forms/inputs/htmlEditor";
import { EmailTemplate, generateTemplate, getMyEmailTemplates } from "../../models/emailTemplates";
import { UUID } from "../../models/common";
import { getEmailTemplate, sendJaEmail } from "../../models/jobApplications";
import { Button, Checkbox, Select, TextInput } from "@getprorecrutement/getpro-design";
import { AtSymbolIcon } from "@heroicons/react/24/outline";
import { XMarkIcon, MinusIcon } from "@heroicons/react/24/solid";

import { toast } from "react-hot-toast";

export const Messenger: FunctionComponent = () => {
  const [messengerData, setMessengerData] = useState<MessengerData | undefined>(undefined);
  const [body, setBody] = useState<string | undefined>();
  const [subject, setSubject] = useState<string | undefined>();
  const [to, setTo] = useState<string | undefined>();
  const [showSignature, setShowSignature] = useState<boolean>(true);
  const [hidden, setHidden] = useState<boolean>(true);
  const [emailTemplates, setEmailTemplates] = useState<EmailTemplate[]>([]);
  const [loading, setLoading] = useState(false);
  const [selectedEt, setSelectedEt] = useState<EmailTemplate>();

  const _setMessengerData = (data: MessengerData | undefined) => {
    setMessengerData(data);
    setBody(data?.body);
    setSubject(data?.subject);
    setTo(data?.to);
  };

  store.listen(Message.Messenger, _setMessengerData);

  useEffect(() => {
    if (selectedEt) fetchTemplateContent(selectedEt.id as UUID, messengerData?.jaId as UUID);
  }, [selectedEt]);

  const fetchTemplateContent = async (templateId: UUID, jaId?: UUID): Promise<void> => {
    const content = await (jaId
      ? getEmailTemplate(jaId, templateId)
      : generateTemplate(templateId, { ...messengerData?.templateData }));

    setBody(content.body);
    setSubject(content.subject);
    setMessengerData((e) => ({
      ...e,
      label: e?.label || "Nouveau message",
      body: content.body,
      subject: content.subject,
    }));
  };

  useEffect(() => {
    getMyEmailTemplates().then(setEmailTemplates);
  }, []);

  const cancel = () => store.notify(Message.Messenger);

  const send = () => {
    if (messengerData?.jaId) {
      setLoading(true);
      sendJaEmail(messengerData.jaId, {
        append_signature: showSignature,
        email: { body, subject, recipient: to },
      } as EmailData)
        .then(() => {
          toast.success("Message envoyé avec succès");
          messengerData?.afterSend && messengerData?.afterSend();
        })
        .finally(() => {
          setLoading(false);
          messengerData.closeAfterSend && cancel();
        });
    }
  };

  if (messengerData)
    return (
      <div className="overflow-auto z-[1071] fixed w-[800px] bottom-8 right-8 max-h-[80%] bg-white p-6 rounded-2xl">
        <div
          onClick={() => setHidden(true)}
          className={`mb-6 text-lg font-bold cursor-pointer ${hidden ? "hidden" : ""}`}
        >
          {messengerData.label}
        </div>
        <div className="mb-4 flex justify-between gap-4 bg-white">
          <TextInput
            className="min-w-[400px]"
            placeholder="Adresse e-mail"
            label="Adresse e-mail"
            light
            icon={<AtSymbolIcon />}
            type="text"
            value={to}
            onChange={(e) => setTo(e.target.value)}
          />
          <div className="flex gap-2 items-start">
            <MinusIcon className="w-4 h-4 cursor-pointer mt-1" onClick={() => setHidden(false)} />
            <XMarkIcon className="w-4 h-4 cursor-pointer" onClick={cancel} />
          </div>
        </div>
        {emailTemplates.length > 0 && (
          <div className="mb-4 bg-white">
            <Select
              options={emailTemplates}
              onChange={(e) => e && setSelectedEt(e)}
              value={selectedEt}
              light
              getKey={(item) => item.id}
              optionRender={(item) => (
                <div className="flex items-center gap-2">
                  <div style={{ backgroundColor: item.color }} className="w-2 h-2 rounded-full" />
                  <div>{item.name}</div>
                </div>
              )}
              type="single"
              bordered
              rounded
              label="Modèle :"
            />
          </div>
        )}
        <div className="mb-4 bg-white">
          <TextInput
            type="text"
            placeholder="Sujet"
            label="Sujet"
            light
            value={subject}
            onChange={(v) => setSubject(v.target.value)}
          />
        </div>
        <HtmlEditor content={messengerData?.body} withVars={false} onChange={setBody} />
        <div className="flex justify-between mb-4">
          <Checkbox label="Ajouter ma signature" light onChange={(e) => setShowSignature(e)} value={showSignature} />
          <Button light title="Envoyer" loading={loading} onClick={send} size="small" />
        </div>
      </div>
    );
  return null;
};

export default Messenger;
