import React, { useState } from "react";
import { FunctionComponent } from "react";
import { UUID } from "../../../../models/common";
import { PipeCandidate } from "../../../../models/dashboards";
import { Status } from "../../../../models/jobOffers";
import JobAppContacts from "../../../jobAppContacts";
import { Table, ColumnProps, Tooltip, Tag } from "@getprorecrutement/getpro-design";
import { useNavigate } from "react-router";
import qs from "qs";

const PER_PAGE = 5;

interface Props {
  candidates?: PipeCandidate[];
  statuses: Status[];
  setSortFilter: (val?: { field: string; order?: "ascend" | "descend" }) => void;
}

export const CandidatePipeList: FunctionComponent<Props> = (props) => {
  const { candidates, statuses, setSortFilter } = props;
  const [page, setPage] = useState<number>(1);
  const navigate = useNavigate();
  const [sortBy, setSortBy] = useState<{
    key: keyof PipeCandidate;
    sortDirection: undefined | "asc" | "desc";
  }>();

  const _setSortBy = (key: keyof PipeCandidate, direction: "desc" | "asc" | undefined) => {
    setSortBy({ key: key, sortDirection: direction });
    setSortFilter({
      field: key,
      order: direction === "asc" ? "ascend" : direction === "desc" ? "descend" : undefined,
    });
    setPage(1);
  };

  const columns: ColumnProps<PipeCandidate>[] = [
    {
      title: "Candidat",

      dataIndex: "candidate_name",
      key: "candidate_name",
    },
    {
      title: "Poste",
      dataIndex: "job_offer_name",
      key: "job_offer_name",
      sortable: true,
    },
    {
      title: "Contact",
      dataIndex: "phone",
      key: "phone",
      width: 100,
      render: (value: string, record) => (
        <JobAppContacts phone={value} email={record.email} linkedinUrl={record.linkedin_url} />
      ),
    },
    {
      title: "Recruteur",
      dataIndex: "owner_first_name",
      key: "owner_first_name",
      sortable: true,
      render: (value: string, record) => (
        <Tooltip light title={`${value} ${record.owner_last_name}`}>
          <Tag light bordered value={`${value} ${record.owner_last_name[0].toUpperCase()}`} />
        </Tooltip>
      ),
    },
    {
      title: "Statut",
      dataIndex: "status_id",
      key: "status_id",
      width: 100,
      sortable: true,
      render: (value: UUID) => {
        const status = statuses.find((s) => s.id === value);
        return (
          <div>
            {status && (
              <Tag
                bordered
                value={status.name.split(" ")[0]}
                light
                icon={<div className="w-2 h-2 rounded-full" style={{ background: `#${status.color}` }} />}
              />
            )}
          </div>
        );
      },
    },
  ];

  const displayedCandidates = (candidates || []).filter((_, i) => i >= (page - 1) * PER_PAGE && i < page * PER_PAGE);

  return (
    <div style={{ width: "100%", minHeight: 284 }}>
      <Table
        size="small"
        dataSource={{ data: displayedCandidates || [], total: candidates?.length || 0 }}
        columns={columns}
        bordered
        light
        rowClassName={() => "cursor-pointer"}
        onClick={(v) =>
          navigate({
            pathname: `/jobs/${v.job_offer_id}`,
            search: qs.stringify({ candidateId: v.candidate_id }),
          })
        }
        pagination={{ per_page: PER_PAGE, page, onChange: setPage }}
        sortBy={sortBy}
        onSortDirectionChange={_setSortBy}
      />
    </div>
  );
};

export default CandidatePipeList;
