import React, { useCallback, useEffect, useRef } from "react";
import { FunctionComponent } from "react";
import { embedDashboard } from "@superset-ui/embedded-sdk";
import httpClient from "../../services/http/client";
import ROUTES from "../../services/http/routes";

export const SupersetDashboardPage: FunctionComponent = () => {
  const ref = useRef(null);

  const init = useCallback(async () => {
    const fetchGuestToken = async () => httpClient.req<string>(ROUTES.SUPERSET_TOKEN());
    if (ref.current) {
      embedDashboard({
        id: "9ebda260-f758-4587-b443-4310b6c6aca8",
        supersetDomain: "https://superset.getpro.co",
        mountPoint: ref.current,
        fetchGuestToken,
        dashboardUiConfig: { hideTitle: true, filters: { expanded: false } },
      });
    }
  }, [ref]);

  useEffect(() => {
    init();
  }, [init]);

  return <div ref={ref} className="supersetDashboard" style={{ width: "100%", height: "100%", minHeight: 500 }}></div>;
};

export default SupersetDashboardPage;
