import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { DOMRectWrapper } from "./base";

export interface SelectDropdownProps {
  renderDropdown: () => JSX.Element;
  wrapperPosition: DOMRectWrapper;
  onClose: () => void;
  parentRef: React.RefObject<HTMLDivElement>;
  parentId: string;
}

export interface Position {
  width: number;
  top: number;
  left?: number;
  bottom?: number;
}
const DROPDOWN_SPACING = 6;

export const SelectDropdown = ({
  renderDropdown,
  wrapperPosition,
  onClose,
  parentId,
  parentRef,
}: SelectDropdownProps) => {
  const [position, setPosition] = useState<Position>();
  const ref = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState<number>();
  const [direction, setDirection] = useState<"top" | "bottom">("bottom");

  const parent = useMemo(() => {
    return document.getElementById(parentId);
  }, [parentId]);

  useLayoutEffect(() => {
    if (ref.current && position) {
      let bounds = ref.current.getBoundingClientRect();
      const newHeight = bounds.height;
      setHeight(newHeight);

      // swap direction if position is out of bouds
      if ((bounds.top < 0 && direction == "top") || (bounds.bottom > window.innerHeight && direction == "bottom")) {
        updatePos();
      }
    }
  }, [renderDropdown, ref.current]);

  useEffect(() => {
    if (ref.current) {
      let current = ref.current;
      if (!(document.activeElement && current.contains(document.activeElement))) {
        current.focus({ preventScroll: true });
      }
    }
  }, [ref.current]);

  //<div style="background-color: red;position: absolute;left: 327px;top: 179px;width: 20px;height: 20px;border-radius: 300px;"></div>

  const updatePos = useCallback(() => {
    if (ref.current) {
      let computedHeight = height || ref.current.getBoundingClientRect().height;

      let scroll = document.documentElement.scrollTop || document.body.scrollTop || 0;

      const itersection = document.elementFromPoint(
        wrapperPosition.left + wrapperPosition.width / 2,
        (wrapperPosition.top + wrapperPosition.bottom) / 2
        // wrapperPosition.top
      );
      if (itersection?.id !== parentId && !parent?.contains(itersection)) {
        onClose();
      }
      if (computedHeight + wrapperPosition.bottom + DROPDOWN_SPACING > window.innerHeight) {
        setPosition({
          top: wrapperPosition.top - DROPDOWN_SPACING + scroll,
          width: wrapperPosition.width,
          left: wrapperPosition.left,
        });
        setDirection("top");
      } else {
        setPosition({
          width: wrapperPosition?.width,
          top: wrapperPosition?.bottom + DROPDOWN_SPACING + scroll,
          left: wrapperPosition?.left,
        });
        setDirection("bottom");
      }
    }
  }, [wrapperPosition]);

  useLayoutEffect(() => {
    updatePos();
  }, [wrapperPosition]);

  return (
    <div
      ref={ref}
      tabIndex={0}
      onBlur={(ev) => {
        if (ev.relatedTarget?.contains(parentRef.current)) {
          return;
        }

        if (!(ev.relatedTarget && ref.current && (ref.current as Node).contains(ev.relatedTarget))) {
          onClose();
        }
      }}
      className="absolute z-50"
      style={
        position && {
          ...position,
          top: position.top - ((direction === "top" && height) || 0),
        }
      }
    >
      {renderDropdown()}
    </div>
  );
};
