import classNames from "classnames";
import React, { FunctionComponent, useEffect, useState } from "react";
import { twMerge } from "tailwind-merge";
import { Tooltip } from "../Tooltip";

export interface RowInfos {
  label: string;
  color: string;
}

export interface BarChartProps {
  columns: RowInfos[];
  rows: Row[];
  tickNb: number;
  title: string;
}

export interface Row {
  data: number[];
  label: string;
}

export const BarChart: FunctionComponent<BarChartProps & React.HTMLAttributes<HTMLDivElement>> = ({
  title,
  columns,
  rows,
  tickNb,
  ...props
}) => {
  const [xTicks, setXTicks] = useState<number[]>([]);

  useEffect(() => {
    let maxHeight = Math.ceil(Math.max(...rows.map((r) => r.data).flat()));

    let size = maxHeight.toString().length - 1;
    let ceil = 10 ** size;

    const step = Math.ceil(maxHeight / tickNb / ceil) * ceil;

    setXTicks(Array.from(Array(tickNb).keys()).map((elem) => (tickNb - elem) * step));
  }, [rows, tickNb]);

  const classes = twMerge(
    classNames("py-10 bg-background-darker rounded-3xl text-white px-20 flex flex-col", {
      [`${props.className}`]: !!props.className,
    })
  );

  return (
    <div {...props} className={classes}>
      <div className="flex justify-between items-center mb-10">
        <div className="font-bold text-xl">{title}</div>
        <div className="flex gap-4 justify-center items-center">
          {columns &&
            columns.map((info, index) => (
              <div key={index} className="flex justify-center items-center gap-2">
                <div style={{ background: info.color }} className="h-2 w-2 rounded-full"></div>
                <div>{info.label}</div>
              </div>
            ))}
        </div>
      </div>
      {rows.length > 0 && xTicks[0] > 0 ? (
        <div className="flex justify-between items-center h-full">
          <div className="flex flex-col h-full gap-4 self-start w-[10%]">
            <div className="h-[90%] flex justify-start w-full">
              <div className="h-full flex flex-col justify-between items-end w-fit">
                {xTicks.map((e, index) => (
                  <div key={index}>{e}</div>
                ))}
                <div>0</div>
              </div>
            </div>

            <div className="h-[10%]"></div>
          </div>
          <div className="flex justify-between items-center w-[90%] h-full">
            {rows.map((row, index) => (
              <div key={index} className="flex flex-col justify-end items-center h-full gap-4">
                <div className="flex gap-1 justify-center items-end h-[90%] w-full">
                  {row.data.map((v, index) => {
                    const col = columns[index];
                    return (
                      <Tooltip
                        key={index}
                        title={v.toFixed()}
                        style={{ height: `${Math.round((v / xTicks[0]) * 100)}%` }}
                      >
                        <div
                          style={{
                            background: col.color,
                          }}
                          className="rounded-md w-2 h-full"
                        ></div>
                      </Tooltip>
                    );
                  })}
                </div>
                <div className="h-[10%]">{row.label}</div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className="flex justify-center items-center h-full flex-col">
          <svg width="64" height="41" viewBox="0 0 64 41" xmlns="http://www.w3.org/2000/svg">
            <g transform="translate(0 1)" fill="none" fillRule="evenodd">
              <ellipse fill="#f5f5f5" cx="32" cy="33" rx="32" ry="7"></ellipse>
              <g stroke="#d9d9d9" fillRule="nonzero">
                <path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"></path>
                <path
                  d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z"
                  fill="#fafafa"
                ></path>
              </g>
            </g>
          </svg>
          <div className="mt-10"> Aucune statistique disponible pour le moment</div>
        </div>
      )}
    </div>
  );
};

// export const BarChar = <T extends unknown>({ rows, getValue, getColLabel }: BarChartProps<T>) => {
//   const [maxH, setMaxH] = useState<number>(0);
//   const [loading, setLoading] = useState<boolean>(true);
//   const [datas, setDatas] = useState<Column[]>();

//   useEffect(() => {
//     let maxH = 0;
//     const grouped = rows.reduce((acc, next) => {
//       Object.entries(next.datas).map(([column, value]: [Column<T>, T]) => {
//         const nbValue = getValue(value);
//         if (maxH > nbValue) {
//           maxH = nbValue;
//         }
//         (acc[column] = acc[column] || []).push({
//           nb: nbValue,
//           infos: next.infos,
//         });
//       });
//       return acc;
//     }, {} as { [key: Column<T>]: { nb: number; infos: RowInfos }[] });
//     setDatas(
//       Object.entries(grouped).map(([label, datas]) => {
//         return {
//           label,
//           datas,
//         };
//       })
//     );
//     setLoading(false);
//   }, [rows]);
// };
