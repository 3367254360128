import httpClient from "../services/http/client";
import ROUTES from "../services/http/routes";
import { notifyError } from "../services/requests";
import { UUID } from "./common";

export interface CommentResponse extends NewComment {
  id: UUID;
  job_offer_name?: string;
  created_at: string;
  updated_at: string;
}

export interface NewComment {
  job_application_id?: UUID;
  candidate_id: UUID;
  mentions?: UUID[];
  user_id: UUID;
  content: string;
}

export async function createComment(
  data: NewComment
): Promise<CommentResponse> {
  return httpClient.req(ROUTES.CREATE_COMMENT(data), (err) =>
    notifyError(err || "Erreur lors de création du commentaire")
  );
}
