import React from "react";
import { FunctionComponent } from "react";

interface Props {
  count?: number;
  title: string;
  onClick?: () => void;
  size?: "small" | "normal" | "large";
}

export const ButtonCard: FunctionComponent<Props> = (props) => {
  const { count, title, onClick, size } = props;

  return (
    <div className="overflow-hidden p-6 bg-white shadow-md hover:shadow-lg relative cursor-pointer" onClick={onClick}>
      <div className={`text-center ${size === "small" ? "text-base" : size === "large" ? "text-3xl" : "text-2xl"}`}>
        {title}
      </div>
      {typeof count != "undefined" && (
        <div
          className={`${
            count === 0 ? "after:bg-[#e68985]" : "after:bg-[#53d1a3]"
          } absolute right-0 top-0 after:content-[''] after:block after:w-16 after:h-16 after:rounded-[50%] after:absolute after:-right-8 after:-top-8`}
        >
          <div className="relative right-2 top-[3px] text-white font-bold z-10">{count}</div>
        </div>
      )}
    </div>
  );
};

export default ButtonCard;
