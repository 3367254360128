import React, { useCallback, useEffect, useRef, useState } from "react";
import { PortalHandler } from "../../../Portal";
import { randomString } from "../../../../utils/common";
import { createPortal } from "react-dom";
import { SelectDropdown } from "./selectDropdown";

export interface Props {
  render: (id: string) => JSX.Element;
  open: boolean;
  setOpen: (value: boolean) => void;
  disabled?: boolean;
  renderDropdown: () => JSX.Element;
}

export const selectHandler: PortalHandler = new PortalHandler();

/// wrap DOMRect without methods
export interface DOMRectWrapper {
  width: number;
  top: number;
  left: number;
  bottom: number;
}

export const SelectBase = ({ render, renderDropdown, disabled, open, setOpen }: Props) => {
  const [portal, setPortal] = useState<HTMLElement>();
  const [id] = useState<string>(randomString(12));
  const [wrapperPosition, setWrapperPosition] = useState<DOMRectWrapper>();
  const ref = useRef(null);

  useEffect(() => {
    if (open && !selectHandler.items.includes(id)) {
      selectHandler.addModal(id);
    } else if (!open) {
      selectHandler.removeModal(id);
    }
  }, [open]);

  useEffect(() => {
    let elem = document.getElementById("select-portal");
    if (elem) {
      setPortal(elem);
    } else {
      throw new Error("Modal Portal not defined");
    }
    return () => {
      selectHandler.removeModal(id);
    };
  }, []);

  useEffect(() => {
    if (ref.current) {
      const interval = setInterval(() => {
        updatePos(ref.current);
      }, 0);

      return () => clearInterval(interval);
    }
  }, [ref]);

  const updatePos = useCallback((value: HTMLDivElement | null) => {
    let bounds = value?.getBoundingClientRect();
    setWrapperPosition((position) => {
      if (bounds) {
        let rect: DOMRectWrapper = {
          width: bounds.width,
          top: bounds.top + (value?.offsetTop || 0),
          left: bounds.left,
          bottom: bounds.bottom,
        };
        // je kiffe je JS
        if (JSON.stringify(rect) !== JSON.stringify(position)) return rect;
      }
      return position;
    });
  }, []);

  let content = wrapperPosition && (
    <SelectDropdown
      parentRef={ref}
      parentId={id}
      onClose={() => setOpen(false)}
      wrapperPosition={wrapperPosition}
      renderDropdown={renderDropdown}
    />
  );

  return (
    <div className="w-full">
      <div tabIndex={0} ref={ref} className="w-full" onClick={() => !disabled && setOpen(!open)}>
        {render(id)}
      </div>
      {open && portal && createPortal(content, portal)}
    </div>
  );
};
