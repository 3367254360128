import React, { FunctionComponent, useEffect, useState } from "react";
import { Blacklist, Candidate, ClearCandidateData, Education, Experience } from "../../models/candidate";
import { euroDisplay, expFromFirstJobAt, phoneFormatter } from "../../utils/formatters";
import ExpEdu from "./expEdu";
import md5 from "md5";
import dayjs from "dayjs";
import Comments from "./comments";
import { copyToClipboard, isEmailValid } from "../../utils";
import { File as FileModel, FileData } from "../../models/file";
import { UUID } from "../../models/common";
import FileAttachment from "../forms/inputs/fileAttachment";
import store from "../../services/store";
import { getUsers, UserResponse } from "../../models/users/users";
import { CondensedJobApplication } from "../../models/jobApplications";
import {
  Button,
  Modal,
  Tooltip,
  Divider,
  PopConfirm,
  TextInput,
  Textarea,
  Empty,
  FileInput,
  MoreActions,
} from "@getprorecrutement/getpro-design";
import { toast } from "react-hot-toast";
import {
  CheckIcon,
  ClockIcon,
  CurrencyEuroIcon,
  EnvelopeIcon,
  IdentificationIcon,
  InformationCircleIcon,
  MapPinIcon,
  NewspaperIcon,
  PencilIcon,
  PhoneIcon,
  PlusCircleIcon,
  ShieldCheckIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { CandidatePicture } from "./picture";
import JobAppForm from "../forms/jobAppForm";
import LinkedinIcon from "../../utils/icons";
import { JobTitle, fetchJobTitle } from "../../models/jobTitle";

interface Props {
  candidate: Candidate;
  files: FileModel[];
  edit: () => void;
  update: (c: Partial<Candidate>) => void;
  delContact: (data: ClearCandidateData) => void;
  uploadFile: (file: File) => boolean;
  deleteFile: (id: UUID) => void;
  updateFile: (data: FileData) => Promise<void>;
  unblacklist: () => void;
  getConsentToken: (id: UUID) => Promise<void>;
  jobApps: CondensedJobApplication[];
  loading: boolean;
}

const genExpKey = (elem: Experience): string => {
  const base = `${elem.title}${elem.company}${elem.start}${elem.summary}`.substring(0, 500);
  return md5(base);
};

const genEduKey = (elem: Education): string => {
  const base = `${elem.degree}${elem.domain}${elem.start}${elem.school}`.substring(0, 500);
  return md5(base);
};

const isLinkedinValid = (email: string): boolean => {
  const regEmail = /^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/(pub|in|profile)\/([-a-zA-Z0-9]+)\/*/gm;

  return regEmail.test(email);
};

export const Profile: FunctionComponent<Props> = (props) => {
  const { candidate, files, uploadFile, deleteFile, updateFile, unblacklist } = props;
  const [blacklistValues, setBlacklistValues] = useState<Partial<Blacklist>>();
  const [addToJoModalOpen, setAddToJoModalOpen] = useState<Candidate>();
  const [showBlacklist, setShowBlacklist] = useState<{
    blacklist: boolean;
    comment: boolean;
  }>({ blacklist: false, comment: false });
  const [users, setUsers] = useState<UserResponse[]>();
  const [jobTitle, setJobTitle] = useState<JobTitle>();

  useEffect(() => {
    getUsers().then(setUsers);
  }, []);

  useEffect(() => {
    if (candidate.job_title_id) fetchJobTitle(candidate.job_title_id).then(setJobTitle);
  }, [candidate]);

  const expsRaw = candidate.details.experiences.map((e) => ({
    ...e,
    key: genExpKey(e),
  }));
  const exps: {
    [key: string]: Experience & { key: string };
  } = expsRaw.reduce(
    (obj, exp) => ({
      ...obj,
      [exp.key]: exp,
    }),
    {}
  );
  const edusRaw = candidate.details.educations.map((e) => ({
    ...e,
    key: genEduKey(e),
  }));
  const edus: {
    [key: string]: Education & { key: string };
  } = edusRaw.reverse().reduce(
    (obj, exp) => ({
      ...obj,
      [exp.key]: exp,
    }),
    {}
  );

  const onSubmitBlacklist = () => {
    if (store.state.JWT?.sub && blacklistValues?.comment) {
      props.update({
        blacklisted: {
          user_id: store.state.JWT?.sub,
          comment: blacklistValues.comment,
          blacklisted_at: dayjs().format("YYYY-MM-DD"),
        },
      });
      setBlacklistValues(undefined);
    } else toast.error("Erreur lors de la récupération de l'indentifiant de l'utilisateur");
    setShowBlacklist({ blacklist: false, comment: false });
  };

  return (
    <div className="relative">
      {candidate.blacklisted && (
        <div
          className="ribbon ribbon-top-left"
          onClick={() =>
            setShowBlacklist({
              blacklist: false,
              comment: true,
            })
          }
        >
          <span>Blacklisted</span>
        </div>
      )}
      <div className="bg-white p-6 shadow-md border border-solid border-border-bright">
        <div className="flex bg-inherit">
          <div className="w-1/6">
            <div className="flex flex-col gap-2 justify-center items-center">
              <CandidatePicture badge candidate={candidate} imageClasses="w-24 h-24" />
              <div>{candidate.full_name || "Candidat anonyme"}</div>
            </div>
            <div className="mt-4">
              {contact(props.update, props.delContact, ContactKind.Linkedin, candidate.linkedin_url)}
              {contact(props.update, props.delContact, ContactKind.Email, candidate.email)}
              {contact(props.update, props.delContact, ContactKind.Phone, candidate.phone)}
            </div>
            <div>
              <FileInput
                title="Ajouter un document"
                className="text-center"
                id="logo"
                light
                onChange={(e) => e && uploadFile(e[0])}
              />
              <div>
                {files.map((e) => (
                  <FileAttachment file={e} update={updateFile} remove={deleteFile} key={e.id} />
                ))}
              </div>
            </div>
          </div>
          <div className="flex-1 pl-8 bg-inherit">
            <div className="flex justify-between">
              <div className="text-3xl font-bold">Fiche candidat</div>
              <div className="flex items-center gap-2">
                {candidate.blacklisted ? (
                  <>
                    <Button
                      light
                      kind="outline"
                      size="small"
                      icon={<InformationCircleIcon />}
                      onClick={() =>
                        setShowBlacklist({
                          blacklist: false,
                          comment: true,
                        })
                      }
                    />
                    <PopConfirm
                      title={`Voulez vous enlever ${candidate.full_name} de la blacklist ?`}
                      onValidate={unblacklist}
                      light
                    >
                      <Button title="Deblacklister" light size="small" />
                    </PopConfirm>
                  </>
                ) : (
                  <Button
                    light
                    title="Blacklister"
                    size="small"
                    onClick={() => setShowBlacklist({ blacklist: true, comment: false })}
                  />
                )}
                <Button onClick={props.edit} light size="small" title="Editer" />
                <MoreActions
                  light
                  actions={[
                    {
                      icon: <PlusCircleIcon />,
                      title: "Ajouter à une offre",
                      action: () => setAddToJoModalOpen(candidate),
                    },
                    {
                      icon: <ShieldCheckIcon />,
                      title: "Lien RGPD",
                      action: async () => await props.getConsentToken(candidate.id),
                    },
                  ]}
                />
              </div>
            </div>
            <div className="flex gap-2 my-8">
              <div className="flex-1 flex flex-col items-center gap-4">
                <Tooltip light title="Salaire">
                  <CurrencyEuroIcon className="w-6 h-6 text-blue-500" />
                </Tooltip>
                <div className="text-center">{candidate.salary ? euroDisplay(candidate.salary) : "Non défini"}</div>
              </div>
              <div className="flex-1 flex flex-col items-center gap-4">
                <Tooltip light title="Localisation">
                  <MapPinIcon className="w-6 h-6 text-blue-500" />
                </Tooltip>
                <div className="text-center">{candidate.location?.formatted_address || "Non défini"}</div>
              </div>
              <div className="flex-1 flex flex-col items-center gap-4">
                <Tooltip light title="Titre de poste">
                  <IdentificationIcon className="w-6 h-6 text-blue-500" />
                </Tooltip>
                <div className="text-center">{jobTitle?.title || "Non défini"}</div>
              </div>
              <div className="flex-1 flex flex-col items-center gap-4">
                <Tooltip light title={"Disponibilité"}>
                  <ClockIcon className="w-6 h-6 text-blue-500" />
                </Tooltip>
                <div className="text-center">
                  {(candidate.available_at && dayjs(candidate.available_at).format("LL")) || "Non défini"}
                </div>
              </div>
              <div className="flex-1 flex flex-col items-center gap-4">
                <Tooltip light title={"Expérience"}>
                  <NewspaperIcon className="w-6 h-6 text-blue-500" />
                </Tooltip>
                <div className="text-center">
                  {(candidate.first_job_at && expFromFirstJobAt(candidate.first_job_at)) || "Non défini"}
                </div>
              </div>
            </div>
            <Divider title="Commentaires" light />

            <Comments key={candidate.id} candidateId={candidate.id} jobApps={props.jobApps} />
            <Divider title="Expérience" light />

            {candidate.details.experiences.length === 0 ? (
              <Empty size="small" light title="Rien à déclarer" />
            ) : (
              Object.values(exps).map((exp) => <ExpEdu key={exp.key} elem={exp} />)
            )}
            <Divider title="Cursus" light />
            {candidate.details.educations.length === 0 ? (
              <Empty size="small" light title="Rien à déclarer" />
            ) : (
              Object.values(edus).map((edu) => <ExpEdu key={edu.key} isEdu elem={edu} />)
            )}
          </div>
        </div>
      </div>
      <Modal
        className="w-modal-md"
        show={showBlacklist.blacklist}
        onClose={() => setShowBlacklist({ blacklist: false, comment: false })}
        light
      >
        <div className="text-2xl mb-2">Raison du blacklist</div>
        <div className="bg-inherit py-2 pr-4 overflow-auto">
          <Textarea
            light
            rows={3}
            placeholder="Commentaire"
            label="Raison"
            value={blacklistValues?.comment}
            onChange={(e) => setBlacklistValues({ ...blacklistValues, comment: e })}
          />
          <div className="flex justify-end mt-4">
            <Button
              title="Valider"
              size="small"
              disabled={!blacklistValues?.comment}
              onClick={onSubmitBlacklist}
              light
            />
          </div>
        </div>
      </Modal>
      <Modal
        className="w-modal-xs"
        show={showBlacklist.comment}
        onClose={() => setShowBlacklist({ blacklist: false, comment: false })}
        light
      >
        {candidate.blacklisted && (
          <div className="flex flex-col gap-4">
            <div className="text-2xl font-bold">Blacklisté</div>
            <div>
              Par <b>{users?.find((u) => candidate.blacklisted?.user_id === u.id)?.first_name}</b> le{" "}
              <b>{dayjs(candidate.blacklisted.blacklisted_at).format("DD.MM.YYYY")}</b>
            </div>
            <div className="text-lg font-bold">Raison :</div>
            <div>{candidate.blacklisted.comment}</div>
          </div>
        )}
      </Modal>
      <Modal className="w-modal-md" light show={!!addToJoModalOpen} onClose={() => setAddToJoModalOpen(undefined)}>
        {addToJoModalOpen && (
          <JobAppForm candidate={addToJoModalOpen} onFinish={() => setAddToJoModalOpen(undefined)} />
        )}
      </Modal>
    </div>
  );
};

enum ContactKind {
  Email,
  Phone,
  Linkedin,
}

const contact = (
  update: (c: Partial<Candidate>) => void,
  delContact: (data: ClearCandidateData) => void,
  kind: ContactKind,
  value?: string
) => {
  const [newContact, setNewContact] = useState<string>(value || "");
  const [editing, setEditing] = useState<boolean>(false);
  const [showDelete, setShowDelete] = useState<boolean>(false);

  useEffect(() => {
    setNewContact(value || "");
    setEditing(false);
  }, [value]);

  const isContactValid = () =>
    (kind === ContactKind.Email && isEmailValid(newContact)) ||
    (kind === ContactKind.Phone && newContact.length > 13) ||
    (kind === ContactKind.Linkedin && isLinkedinValid(newContact));

  return (
    <div className="mb-2">
      {editing ? (
        <div className={`border border-solid h-fit border-${isContactValid() ? "green" : "red"}-400`}>
          <TextInput
            light
            bordered={false}
            size="small"
            type="text"
            placeholder=""
            value={kind === ContactKind.Phone ? phoneFormatter(newContact) : newContact}
            onChange={(e) => setNewContact(e.target.value)}
            autoFocus
            prefix={
              <div className="flex items-center px-2">
                {kind === ContactKind.Phone ? (
                  <PhoneIcon className="w-4 h-4 CandidateLink" />
                ) : kind === ContactKind.Email ? (
                  <EnvelopeIcon className="w-4 h-4 CandidateLink" />
                ) : (
                  <LinkedinIcon className="w-4 h-4" />
                )}
              </div>
            }
            suffix={
              <div className="flex items-center px-2">
                <CheckIcon
                  className="w-4 h-4 cursor-pointer CandidateLink"
                  onClick={() => {
                    if (isContactValid()) {
                      update({
                        [kind === ContactKind.Phone ? "phone" : kind === ContactKind.Email ? "email" : "linkedin_url"]:
                          newContact,
                      });
                      setEditing(false);
                    }
                  }}
                />
              </div>
            }
          />
        </div>
      ) : (
        <div className="flex items-center group gap-2">
          {kind === ContactKind.Phone ? (
            <PhoneIcon className={`w-4 h-4 ${showDelete ? "hidden" : "group-hover:hidden"} CandidateLink`} />
          ) : kind === ContactKind.Email ? (
            <EnvelopeIcon className={`w-4 h-4 ${showDelete ? "hidden" : "group-hover:hidden"} CandidateLink`} />
          ) : (
            <LinkedinIcon className={`w-4 h-4 ${showDelete ? "hidden" : "group-hover:hidden"} CandidateLink`} />
          )}
          <div className={showDelete ? "block" : "hidden group-hover:block"}>
            {value ? (
              <div className="flex justify-center items-center gap-2">
                {(kind === ContactKind.Email || kind === ContactKind.Phone) && (
                  <PopConfirm
                    onChange={setShowDelete}
                    title={`Supprimer ${kind === ContactKind.Phone ? "le numéro de téléphone" : "l'adresse e-mail"} ?`}
                    onValidate={() => delContact({ [kind === ContactKind.Phone ? "phone" : "email"]: true })}
                    light
                  >
                    <TrashIcon className="w-4 h-4 CandidateLink" />
                  </PopConfirm>
                )}
                <PencilIcon className="w-4 h-4 cursor-pointer CandidateLink" onClick={() => setEditing(true)} />
              </div>
            ) : (
              <PlusCircleIcon className="w-4 h-4 CandidateLink cursor-pointer" onClick={() => setEditing(true)} />
            )}
          </div>
          <div className="flex-1 max-w-[calc(100%-24px)] truncate">
            {value && kind === ContactKind.Linkedin ? (
              <a target="_blank" className="truncate flex-1" href={value} rel="noreferrer">
                {value}
              </a>
            ) : (
              <span
                onClick={() => value && copyToClipboard(value, "Le contact a été copié dans votre presse-papier")}
                className={!value ? "text-content-light" : "cursor-pointer"}
              >
                {value || "Non défini"}
              </span>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Profile;
