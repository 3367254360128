import React, { FunctionComponent } from "react";

interface Props {
  label: string;
  value: string | JSX.Element | undefined;
  icon?: JSX.Element;
  bold?: boolean;
  labelWidth?: number;
  direction?: "horizontal" | "vertical";
  dark?: boolean;
}

export const InlineLabelValue: FunctionComponent<Props> = (props) => {
  const { label, value, icon, bold, labelWidth } = props;

  const direction = props.direction || "horizontal";

  // Todo rework with tailwind dark
  const valueClasses = `${props.dark ? "text-white" : "text-content-darker"} flex-1 flex items-start gap-0.5 font-bold`;

  return (
    <div className={`flex flex-wrap gap-1 mb-1 ${direction === "vertical" ? "flex-col" : ""}`}>
      <div
        style={{ minWidth: direction === "horizontal" ? labelWidth || 140 : labelWidth }}
        className={`text-content-regular text-xs ${bold ? "" : ""}`}
      >
        {label}
      </div>
      {value ? (
        <div className={valueClasses}>
          {icon && <span className="mr-2">{icon}</span>}
          {value}
        </div>
      ) : (
        <div className="italic text-content-light">Non défini</div>
      )}
    </div>
  );
};

export default InlineLabelValue;
