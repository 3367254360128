import React, { FunctionComponent, useState, useEffect } from "react";
import { UUID } from "../../../../models/common";
import { BusinessUnit, getBusinessUnits } from "../../../../models/businessUnits";
import { Select } from "@getprorecrutement/getpro-design";
import { MultipleValues, SingleValue } from "@getprorecrutement/getpro-design/dist/components/Form/Inputs/Select";

interface Props {
  businessUnits?: BusinessUnit[];
  onSelect: (s: UUID) => void;
  value?: UUID | UUID[];
  multiple?: boolean;
  error?: string;
  placeholder?: string;
  style?: React.CSSProperties;
  size?: "small" | "medium" | "large";
  showSearch?: boolean;
  label?: string;
}

export const BusinessUnitSelect: FunctionComponent<Props> = (props) => {
  const [businessUnits, setBusinessUnits] = useState<BusinessUnit[]>(props.businessUnits || []);
  const [search, setSearch] = useState<string>("");

  const fetchBusinessUnits = async () => {
    getBusinessUnits().then(setBusinessUnits);
  };

  useEffect(() => {
    if (!props.businessUnits) fetchBusinessUnits();
  }, []);

  useEffect(() => {
    if (props.businessUnits) setBusinessUnits(props.businessUnits);
  }, [props.businessUnits]);

  const selectProps: SingleValue<BusinessUnit> | MultipleValues<BusinessUnit> = props.multiple
    ? { type: "multiple", value: businessUnits.filter((c) => props.value?.includes(c.id)) }
    : { type: "single", value: businessUnits.find((c) => c.id === props.value) };

  return (
    <span style={props.style} className="businessUnitSelect bg-inherit">
      <Select
        light
        bordered
        error={props.error}
        rounded
        label={props.label}
        size={props.size}
        options={businessUnits.filter(
          (c) => !props.showSearch || c.name?.toLowerCase()?.includes(search.toLowerCase())
        )}
        optionRender={(c) => c.name}
        getKey={(c) => c.id}
        onSearch={props.showSearch ? (val) => setSearch(val) : undefined}
        // loading={loading}
        onChange={(v) => v && props.onSelect(v.id)}
        placeholder={props.placeholder}
        {...selectProps}
      />
    </span>
  );
};

export default BusinessUnitSelect;
