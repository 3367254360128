import { toast } from "react-hot-toast";
import httpClient from "../../services/http/client";
import ROUTES, { PaginationParams } from "../../services/http/routes";
import { notifyError } from "../../services/requests";
import { Paginated, UUID } from "../common";

export interface Booking extends NewBooking {
  id: UUID;
  user_id: UUID;
  business_unit_id: UUID;
  customer_id: UUID;
  contract_id: UUID;
  bill_id: UUID;
  created_at: string;
}

export interface NewBooking {
  amount: number;
  project_id: UUID;
}

export interface BookingData {
  amount: number;
  created_at: string;
}

export type BookingsFilters = {
  project_id?: UUID;
  customer_id?: UUID;
  contract_id?: UUID;
} & PaginationParams;

export async function getBookings(filters?: BookingsFilters): Promise<Paginated<Booking>> {
  return httpClient.req(ROUTES.FETCH_BOOKINGS(filters), (err) =>
    notifyError(err || "Erreur lors de la récupération des bookings")
  );
}

export async function createBooking(new_booking: NewBooking): Promise<Booking> {
  return httpClient
    .req<Booking>(ROUTES.CREATE_BOOKING(new_booking), (err) =>
      notifyError(err || "Erreur lors de la création du booking")
    )
    .then((e) => {
      toast.success("Booking créé avec succès");
      return e;
    });
}

export async function updateBooking(data: BookingData & { id: UUID }): Promise<Booking> {
  return httpClient
    .req<Booking>(ROUTES.UPDATE_BOOKING(data), (err) => notifyError(err || "Erreur lors de la mise à jour du booking"))
    .then((e) => {
      toast.success("Booking mis à jour avec succès");
      return e;
    });
}

export async function deleteBooking(id: UUID): Promise<void> {
  return httpClient
    .req(ROUTES.DELETE_BOOKING(id), (err) => notifyError(err || "Erreur lors de la suppression du booking"))
    .then(() => {
      toast.success("Booking supprimé avec succès");
    });
}
