import React, { forwardRef, useEffect, useImperativeHandle } from "react";
import { UUID } from "../../../models/common";
import { Customer } from "../../../models/customers";
import { FileInput, TextInput } from "@getprorecrutement/getpro-design";
import UserSelect from "../inputs/userSelect";
import GeocodingSelect from "../inputs/geocodingSelect";
import { useFormManager } from "../../../services/form";
import { StepHandle } from ".";

interface Props {
  values: Partial<Customer>;
  setDisabled: (value: boolean) => void;
  uploadLogo: (file: File) => void;
  logoFile?: File;
}

export const BasicStepForm = forwardRef<StepHandle, Props>(function BasicStepForm(props, ref) {
  const { values, uploadLogo, logoFile } = props;

  const form = useFormManager<Customer>({
    defaultState: values,
    validations: {
      name: {
        required: true,
        message: "Merci de préciser le nom de l'entreprise",
      },
      legal_name: {
        required: true,
        message: "Merci de préciser le nom légal de l'entreprise",
      },
      description: {
        required: true,
        message: "Merci de préciser la description de l'entreprise",
      },
      owner_id: {
        required: true,
        message: "Merci de préciser le propriétaire de l'entreprise",
      },
      location: {
        required: true,
        message: "Merci de préciser l'addresse de l'entreprise",
      },
      billing_address: {
        required: true,
        message: "Merci de préciser l'addresse de facturation de l'entreprise",
      },
    },
  });

  useEffect(() => {
    props.setDisabled(form.disabled);
  }, [form.disabled]);

  useImperativeHandle(
    ref,
    () => {
      return {
        isValid() {
          return form.validate();
        },

        getData() {
          return form.state;
        },
      };
    },
    [form.state]
  );

  return (
    <div className="bg-inherit flex flex-col gap-4">
      <div className="flex items-start bg-inherit">
        <div className="bg-inherit flex-1">
          <TextInput
            label="Nom"
            placeholder="GetPro"
            type="text"
            error={form.errors.name}
            value={form.state.name}
            onChange={(e) => form.onChange({ name: e.target.value })}
            light
            inputClassName="rounded-r-none"
          />
        </div>
        <div className="bg-inherit flex-1">
          <TextInput
            label="Nom légal"
            placeholder="GetPro"
            type="text"
            error={form.errors.legal_name}
            value={form.state.legal_name}
            onChange={(e) => form.onChange({ legal_name: e.target.value })}
            light
            inputClassName="rounded-l-none"
          />
        </div>
      </div>
      <TextInput
        label="Description"
        placeholder="Description affichée sur la job board"
        type="text"
        error={form.errors.description}
        value={form.state.description}
        onChange={(e) => form.onChange({ description: e.target.value })}
        light
      />
      <UserSelect
        label={"Propriétaire"}
        placeholder="Sélectionner le propriétaire de l'entreprise"
        error={form.errors.owner_id}
        value={form.state.owner_id}
        showSearch
        onSelect={(id: UUID) =>
          form.onChange({
            owner_id: id,
          })
        }
      />

      <GeocodingSelect
        label="Adresse"
        error={form.errors.location}
        value={form.state.location}
        onSelect={(v) => form.onChange({ location: v })}
      />
      <TextInput
        label="Adresse de facturation"
        placeholder="Adresse de facturation"
        type="text"
        error={form.errors.billing_address}
        value={form.state.billing_address}
        onChange={(e) => form.onChange({ billing_address: e.target.value })}
        light
      />
      <div className="flex items-center justify-center">
        <FileInput
          title="Ajouter un logo"
          id="logo"
          light
          className={"p-4"}
          accept={"image/png, image/jpeg"}
          customRenderer={
            form.state.file_id || logoFile ? (
              <img
                className="max-w-[100px] max-h-[60px]"
                src={
                  logoFile
                    ? URL.createObjectURL(logoFile)
                    : `https://storage.googleapis.com/getpro-public/${
                        form.state.file_id || "e1ca4dfa-7173-40a1-8f9a-917dc3f3556f"
                      }`
                }
              />
            ) : undefined
          }
          onChange={(e) => e && uploadLogo(e[0])}
        />
      </div>
    </div>
  );
});

export default BasicStepForm;
