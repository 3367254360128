import React, { useState } from "react";
import { Paginated, UUID } from "../../models/common";
import { Bill, BillsFilters, BillStatus, BillResponse, generateZip, getCsvResults } from "../../models/billing/bill";
import BillStatusMultiSelect from "./bills/billStatusMultiSelect";
import BillForm from "./bills/billForm";
import BillList from "./bills/billList";
import { Booking, BookingsFilters } from "../../models/billing/booking";
import BookingForm from "./booking/bookingForm";
import BookingList from "./booking/bookingList";
import { ProjectType } from "../../models/billing/project";
import store from "../../services/store";
import { downloadFileFromUrl } from "../../models/misc";
import { Checkbox, Modal, Button, PopOver, Tab, DateRange } from "@getprorecrutement/getpro-design";
import { ArrowLeftIcon, PlusIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";

const PER_PAGE = 10;

const MENU_LIST = [
  { label: "Factures", value: "billing" },
  { label: "Booking", value: "booking" },
];

interface Props {
  bills?: Paginated<BillResponse>;
  fetchBills: (filters: BillsFilters, project_types: ProjectType[]) => void;
  bookings?: Paginated<Booking>;
  fetchBookings?: (filters: BookingsFilters) => void;
  returnPath?: string;
  edition?: boolean; // Allow create / update
  customerId?: UUID;
  contractId?: UUID;
  projectId?: UUID;
  defaultStatuses?: BillStatus[];
}

export const BillAndBookingList = (props: Props) => {
  const { bills, fetchBills, bookings, fetchBookings, edition, customerId, contractId, projectId, returnPath } = props;
  const [billFilters, setBillFilters] = useState<BillsFilters>({
    page: 1,
    per_page: PER_PAGE,
    status: props.defaultStatuses,
  });
  const [projectTypes, setProjectTypes] = useState<ProjectType[]>([]);
  const [bookingFilters, setBookingFilters] = useState<BookingsFilters>({
    page: 1,
    per_page: PER_PAGE,
    project_id: projectId,
  });
  const [bill, setBill] = useState<Bill>();
  const [booking, setBooking] = useState<Booking>();
  const [form, setForm] = useState<{ billing: boolean; booking: boolean }>({
    billing: false,
    booking: false,
  });
  const [selectedMenu, setSelectedMenu] = useState<{ label: string; value: string }>(MENU_LIST[0]);
  const [billPeriod, setBillPeriod] = useState<{ from: string; to: string }>();

  const isBilling = selectedMenu.value === "billing" || !fetchBookings;

  const onFinish = async (billing: boolean) => {
    if (billing) {
      setForm({ ...form, billing: false });
      fetchBills(billFilters, projectTypes);
      setBill(undefined);
    } else {
      setForm({ ...form, booking: false });
      fetchBookings && fetchBookings(bookingFilters);
      setBooking(undefined);
    }
  };

  const toggleProjectType = (types: ProjectType[]) => {
    types.some((e) => projectTypes.includes(e))
      ? setProjectTypes(projectTypes.filter((e) => !types.includes(e)))
      : setProjectTypes(projectTypes.concat(types));
  };

  const selectBill = (bill: Bill) => {
    setForm({ ...form, billing: true });
    setBill(bill);
  };

  const selectBooking = (booking: Booking) => {
    setForm({ ...form, booking: true });
    setBooking(booking);
  };

  const generateCsvResults = async () => {
    if (billPeriod && store.state.JWT?.sub) {
      const url = await getCsvResults(store.state.JWT?.sub, billPeriod);
      await downloadFileFromUrl(url.url);
    }
  };

  const periodictBillsOverlay = () => {
    return (
      <div className="w-[360px] py-2">
        <DateRange
          light
          allowClear
          value={billPeriod}
          onChange={(val) => setBillPeriod(val.from && val.to ? (val as { from: string; to: string }) : undefined)}
        />
        <div className="flex justify-center gap-4 mt-4">
          <Button
            kind="outline"
            title="CSV"
            size="small"
            light
            onClick={async () => await generateCsvResults()}
            disabled={!billPeriod}
          />
          <Button
            light
            disabled={!billPeriod}
            onClick={async () => {
              if (billPeriod) {
                await generateZip(billPeriod);
                setBillPeriod(undefined);
              }
            }}
            size="small"
            title="Télécharger"
          />
        </div>
      </div>
    );
  };

  const billHeader = () => {
    return (
      <div className="flex shadow-md mb-6">
        {returnPath && (
          <div className="flex items-center justify-center w-20">
            <Link to={returnPath}>
              <ArrowLeftIcon className="w-5 h-5 cursor-pointer" />
            </Link>
          </div>
        )}
        {fetchBookings && (
          <div>
            <Tab
              values={MENU_LIST}
              getKey={(v) => v.value}
              getLabel={(v) => v.label}
              onChange={(v) => setSelectedMenu(v)}
              selected={selectedMenu}
              direction="vertical"
              light
            />
          </div>
        )}
        <div className="bg-white p-6 flex-1 flex items-center">
          {!fetchBookings && <div className="text-3xl font-bold mr-6">Factures</div>}
          <div className={`flex ${isBilling ? "justify-start" : "justify-end"} items-center flex-1`}>
            {isBilling && (
              <div className="flex items-center flex-1 flex-wrap gap-x-10 gap-y-1">
                <div>
                  <BillStatusMultiSelect
                    defaultValue={billFilters.status}
                    onSelect={(e) => setBillFilters({ ...billFilters, page: 1, status: e })}
                  />
                </div>
                <div className="flex gap-2">
                  <Checkbox
                    value={[ProjectType.Commission, ProjectType.CommissionRate, ProjectType.CommissionThird].some((e) =>
                      projectTypes.includes(e)
                    )}
                    onChange={() => {
                      toggleProjectType([
                        ProjectType.Commission,
                        ProjectType.CommissionRate,
                        ProjectType.CommissionThird,
                      ]);
                    }}
                    label="Offres"
                    light
                  />
                  <Checkbox
                    value={projectTypes.includes(ProjectType.Product)}
                    onChange={() => toggleProjectType([ProjectType.Product])}
                    label="Produits"
                    light
                  />
                  <Checkbox
                    value={projectTypes.includes(ProjectType.Rpo)}
                    onChange={() => toggleProjectType([ProjectType.Rpo])}
                    label="RPO"
                    light
                  />
                  <Checkbox
                    value={projectTypes.includes(ProjectType.Subscription)}
                    onChange={() => toggleProjectType([ProjectType.Subscription])}
                    label="Abonnements"
                    light
                  />
                </div>
              </div>
            )}
            {edition && (
              <div className="flex items-center gap-4">
                <PopOver content={periodictBillsOverlay()} position="bottom" light>
                  <Button title="Exports" light size="small" kind="outline" />
                </PopOver>
                <Button
                  light
                  size="small"
                  icon={<PlusIcon />}
                  onClick={() => {
                    setForm(isBilling ? { ...form, billing: true } : { ...form, booking: true });
                    setBill(undefined);
                  }}
                  title={isBilling ? "Nouvelle facture" : "Nouveau booking"}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      {billHeader()}
      {isBilling ? (
        <BillList
          fetchBills={fetchBills}
          bills={bills}
          selectedBill={selectBill}
          setForm={setForm}
          filters={billFilters}
          projectTypes={projectTypes}
          setFilters={setBillFilters}
        />
      ) : (
        <BookingList
          fetchBookings={fetchBookings}
          bookings={bookings}
          selectedBooking={selectBooking}
          setForm={setForm}
          filters={bookingFilters}
          setFilters={setBookingFilters}
        />
      )}
      <Modal onClose={() => onFinish(true)} show={form.billing} className="w-modal-xl" light>
        <BillForm
          onFinish={() => onFinish(true)}
          bill={bill}
          customerId={customerId}
          contractId={contractId}
          projectId={projectId}
          key={bill?.id || "newBill"}
        />
      </Modal>
      <Modal onClose={() => onFinish(false)} show={form.booking} className="w-modal-xl" light>
        <BookingForm
          onFinish={() => onFinish(false)}
          booking={booking}
          customerId={customerId}
          contractId={contractId}
          projectId={projectId}
          key={booking?.id || "newBooking"}
        />
      </Modal>
    </div>
  );
};

export default BillAndBookingList;
