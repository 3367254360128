import classNames from "classnames";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { twMerge } from "tailwind-merge";

export interface Props<T> {
  values: T[];
  getKey: (value: T) => string;
  onChange?: (value: T) => void;
  dark?: boolean;
  light?: boolean;
  activeColor?: string;
  direction?: "horizontal" | "vertical";
  selected?: T;
}

export interface WithCustomRenderer<T> {
  customRenderer: (value: T, onCLick: () => void, setRef: (elem: HTMLDivElement) => void) => JSX.Element;
  getLabel?: undefined;
}

export interface WithoutCustomRenderer<T> {
  customRenderer?: undefined;
  getLabel: (value: T) => string;
}

export const Tab = <T extends unknown>({
  values,
  getLabel,
  getKey,
  customRenderer,
  onChange,
  activeColor,
  direction = "horizontal",
  selected,
  dark,
  light,
  ...props
}: Props<T> &
  (WithCustomRenderer<T> | WithoutCustomRenderer<T>) &
  Omit<React.HTMLAttributes<HTMLDivElement>, "onChange">) => {
  const itemsRef = useRef<HTMLDivElement[]>([]);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [selectedBarPos, setSelectedBarPos] = useState<{
    width?: number;
    height?: number;
    left?: number;
    top?: number;
  }>();

  useEffect(() => {
    itemsRef.current = itemsRef.current.slice(0, values.length);
  }, [values]);

  useEffect(() => {
    const index = values.findIndex((v) => v == selected);

    if (wrapperRef.current && itemsRef.current[index]) {
      if (direction === "horizontal") {
        const rect = itemsRef.current[index].getBoundingClientRect();
        const width = (rect?.right || 0) - (rect?.left || 0);
        const left =
          (rect?.left || 0) - (wrapperRef.current?.getBoundingClientRect().left || 0) + wrapperRef.current.scrollLeft;
        const max_scroll = wrapperRef.current.scrollWidth - wrapperRef.current.clientWidth;
        const scroll = left - width / 2 > max_scroll ? max_scroll : left / 2;
        wrapperRef.current.scrollTo({
          left: scroll > 0 ? scroll : 0,
          behavior: "smooth",
        });
        setSelectedBarPos({ width, left });
      } else {
        const rect = itemsRef.current[index].getBoundingClientRect();
        const height = (rect?.bottom || 0) - (rect?.top || 0);
        const top =
          (rect?.top || 0) - (wrapperRef.current?.getBoundingClientRect().top || 0) + wrapperRef.current.scrollTop;
        const max_scroll = wrapperRef.current.scrollWidth - wrapperRef.current.clientWidth;
        const scroll = top - height / 2 > max_scroll ? max_scroll : top / 2;
        wrapperRef.current.scrollTo({
          top: scroll > 0 ? scroll : 0,
          behavior: "smooth",
        });
        setSelectedBarPos({ height, top });
      }
    }
  }, [selected, values]);

  const wrapperClasses = twMerge(
    classNames("tab-wrapper relative overflow-x-auto w-full", {
      flex: direction === "horizontal",
      [`${props.className}`]: !!props.className,
      dark: dark,
    })
  );

  const barClasses = classNames("transition-all absolute bg-background-medium", {
    "h-[2px] bottom-0": direction === "horizontal",
    "w-[2px] left-0": direction === "vertical",
    "dark:bg-background-light": !activeColor && !light,
    [`bg-[${activeColor}]`]: !!activeColor,
  });

  const activeItemCLasses = classNames("px-5 py-3 cursor-pointer min-w-max", {
    "text-content-darker": !activeColor,
    "dark:text-content-bright": !activeColor && !light,
  });

  const itemCLasses = classNames("px-5 py-3 cursor-pointer min-w-max text-content-light", {
    "dark:text-content-medium": !light,
  });

  const renderSelectedBar = () => {
    const index = values.findIndex((v) => v == selected);
    if (index == -1) return 0;

    return (
      <div
        style={{
          width: selectedBarPos?.width,
          left: selectedBarPos?.left,
          height: selectedBarPos?.height,
          top: selectedBarPos?.top,
          background: activeColor,
        }}
        className={barClasses}
      />
    );
  };

  return (
    <div {...props} ref={wrapperRef} className={wrapperClasses}>
      {values.map((value, index) => {
        return customRenderer ? (
          customRenderer(
            value,
            () => onChange?.(value),
            (el) => el && (itemsRef.current[index] = el)
          )
        ) : (
          <div
            ref={(el) => el && (itemsRef.current[index] = el)}
            key={getKey(value)}
            className={value === selected ? activeItemCLasses : itemCLasses}
            style={(activeColor && value === selected && { color: activeColor }) || undefined}
            onClick={() => onChange?.(value)}
          >
            {getLabel?.(value as T)}
          </div>
        );
      })}
      {selected && renderSelectedBar()}
    </div>
  );
};
