import { InboxIcon } from "@heroicons/react/outline";
import classNames from "classnames";
import React, { FunctionComponent } from "react";

export interface Props {
  title?: string;
  icon?: JSX.Element;
  size?: "small" | "medium" | "large";
  dark?: boolean;
  light?: boolean;
}

export const Empty: FunctionComponent<Props & React.HTMLAttributes<HTMLDivElement>> = ({
  title,
  icon,
  size = "medium",
  dark,
  light,
  ...props
}) => {
  const wrapperClasses = classNames("w-full flex flex-col items-center justify-center", {
    dark: dark,
    "my-8 gap-2": size === "small",
    "my-12 gap-3": size === "medium",
    "my-16 gap-4": size === "large",
  });

  const iconClasses = classNames({
    "h-12 w-12": size === "small",
    "h-16 w-16": size === "medium",
    "h-20 w-20": size === "large",
  });

  const titleClasses = classNames("text-content-darker", {
    "dark:text-content-bright": !light,
    "text-base": size === "small",
    "text-lg": size === "medium",
    "text-2xl": size === "large",
  });

  return (
    <div className={wrapperClasses}>
      <div className={iconClasses}>{icon || <InboxIcon />}</div>
      <div className={titleClasses}>{title || "Aucune données disponible"}</div>
    </div>
  );
};
