import React, { useState } from "react";
import { FunctionComponent } from "react";
import { TextInput, Button } from "@getprorecrutement/getpro-design";
import { CheckIcon, PencilIcon } from "@heroicons/react/24/outline";

interface Props {
  title?: string;
  modKey: string;
  slug: string;
  locked: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  updateModule: (data: { dashboard_slug: string; module_key: string; filters: any; title?: string }) => Promise<void>;
}

const ModuleTitle: FunctionComponent<Props> = (props) => {
  const { title, modKey, slug, locked, updateModule } = props;
  const [titleContentEdit, setTitleContentEdit] = useState<string | undefined>(title);
  const [edit, setEdit] = useState<boolean>(false);

  const updateModuleTitle = async (title?: string) => {
    if (title) {
      await updateModule({
        dashboard_slug: slug,
        module_key: modKey,
        filters: {},
        title: title,
      });
      setTitleContentEdit(undefined);
      setEdit(false);
    }
  };

  return (
    <div>
      {edit && !locked ? (
        <div className="mb-6 flex items-center bg-white gap-2">
          <TextInput
            light
            className="min-w-[300px]"
            label="Titre"
            placeholder="Titre"
            type="text"
            value={titleContentEdit}
            onChange={(e) => setTitleContentEdit(e.target.value)}
          />
          <Button
            className="p-0"
            light
            kind="light"
            icon={<CheckIcon />}
            onClick={() => {
              updateModuleTitle(titleContentEdit);
            }}
          />
        </div>
      ) : (
        <div className="mb-6 flex items-center gap-4">
          {title ? (
            <div className="text-3xl font-bold">{title}</div>
          ) : !locked ? (
            <div className="text-3xl font-bold">Aucun titre</div>
          ) : undefined}
          {!locked && (
            <Button
              icon={<PencilIcon />}
              className="p-0"
              light
              kind="light"
              onClick={() => {
                setTitleContentEdit(title);
                setEdit(true);
              }}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default ModuleTitle;
