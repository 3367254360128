import React, { FunctionComponent, useState, useEffect } from "react";
import { UUID } from "../../../../models/common";
import { Select } from "@getprorecrutement/getpro-design";
import { useLoading } from "../../../../utils/loading";
import { useDebounce } from "../../../../utils";
import { JobTitle, fetchJobTitles as fetchJobTitlesReq } from "../../../../models/jobTitle";

interface Props {
  onSelect: (s: UUID) => void;
  value?: UUID[];
  customValueRender?: (value: JobTitle[]) => JSX.Element;
  placeholder?: string;
  label?: string;
  showSearch?: boolean;
  size?: "small" | "medium" | "large";
  error?: string;
  showArrowIcon?: boolean;
  dropdownClassName?: string;
}

export const JobTitleSelectMultiple: FunctionComponent<Props> = ({
  onSelect,
  placeholder,
  label,
  showSearch,
  size,
  value,
  error,
  showArrowIcon,
  ...props
}) => {
  const [jobTitles, _setJobTitles] = useState<JobTitle[]>([]);
  const [selectedValues, setSelectedValues] = useState<JobTitle[]>([]);
  const [search, setSearch] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const load = useLoading(setLoading);
  const debounceSearch = useDebounce(search, 300);

  const setJobTitles = (jobTitles: JobTitle[]) => {
    const [selected, nonSelected] = jobTitles.reduce(
      (acc, next) => {
        acc[value?.indexOf(next.id) !== -1 ? 0 : 1].push(next);

        return acc;
      },
      [[], []] as JobTitle[][]
    );
    _setJobTitles(selected.concat(nonSelected));
  };

  const fetchJobTitles = async () => {
    load(fetchJobTitlesReq({ search: debounceSearch }).then(setJobTitles));
  };

  useEffect(() => {
    fetchJobTitles();
  }, [debounceSearch]);

  useEffect(() => {
    if (value && value.length) {
      if (value.length !== selectedValues.length) fetchJobTitlesReq({ ids: value }).then(setSelectedValues);
    } else {
      setSelectedValues([]);
    }
  }, [value]);

  const selected = (val: JobTitle) => {
    const newValues = selectedValues.map((jobTitle) => jobTitle.id).includes(val.id)
      ? selectedValues.filter((jobTitle) => jobTitle.id !== val.id)
      : [...selectedValues, val];
    setSelectedValues(newValues);
    onSelect(val.id);
  };

  return (
    <span className="bg-inherit">
      <Select
        light
        type="multiple"
        value={selectedValues}
        customValueRender={props.customValueRender}
        bordered
        rounded
        loading={loading}
        showArrowIcon={showArrowIcon}
        size={size}
        label={label}
        error={error}
        options={jobTitles}
        optionRender={(c) => c.title}
        getKey={(c) => c.id}
        onSearch={showSearch ? setSearch : undefined}
        onChange={(v) => v && selected(v)}
        placeholder={placeholder || "Sélectionner des titres de poste"}
        dropdownClassName={props.dropdownClassName}
      />
    </span>
  );
};

export default JobTitleSelectMultiple;
