import React, { FunctionComponent, ButtonHTMLAttributes } from "react";
import classNames from "classnames";
import Loading from "./Loading";
import "./Button.css";
import { twMerge } from "tailwind-merge";
import { ColorType, inputPaddingClasses, textSizeClasses } from "../../utils/common";

export interface Props {
  title?: string;
  size?: "small" | "medium" | "large";
  icon?: JSX.Element;
  loading?: boolean;
  disabled?: boolean;
  kind?: "outline" | "solid" | "light";
  dark?: boolean;
  light?: boolean;
  type?: React.ButtonHTMLAttributes<HTMLButtonElement>["type"];
  colorType?: ColorType;
}

export const Button: FunctionComponent<ButtonHTMLAttributes<HTMLButtonElement> & Props> = ({
  title,
  icon,
  loading,
  disabled,
  kind = "solid",
  size = "medium",
  dark,
  light,
  type = "button",
  onClick,
  className,
  colorType = ColorType.Primary,
  ...props
}) => {
  const c = "primary";

  // SOLID
  const lightSolid = `bg-primary-medium text-white hover:bg-primary-light border border-solid border-primary-medium hover:border-primary-light`;
  const darkSolid = `dark:bg-primary-light dark:text-primary-darker dark:hover:bg-primary-medium dark:border dark:border-solid dark:border-primary-light dark:hover:border-primary-medium`;
  const lightSolidContent =
    "bg-content-darker text-white hover:bg-content-dark border border-solid border-content-darker hover:border-content-dark";
  const darkSolidContent =
    "dark:bg-content-bright dark:text-content-darker dark:hover:bg-content-lighter dark:border dark:border-solid dark:border-content-bright dark:hover:border-content-medium";
  // OUTLINE
  const lightOutline = `bg-transparent border border-solid border-primary-medium text-primary-medium hover:border-primary-lighter`;
  const darkOutline = `dark:bg-transparent dark:border dark:border-solid dark:border-primary-light dark:text-primary-bright dark:hover:border-primary-medium`;
  const lightOutlineContent =
    "bg-transparent border border-solid border-content-darker text-content-darker hover:border-content-medium";
  const darkOutlineContent =
    "dark:bg-transparent dark:border dark:border-solid dark:border-content-bright dark:text-content-bright dark:hover:border-content-medium";
  // LIGHT
  const lightLight = `text-primary-medium hover:text-primary-light`;
  const darkLight = `dark:text-primary-light dark:hover:text-primary-medium`;
  const lightLightContent = "text-content-darker hover:text-content-medium";
  const darkLightContent = "dark:text-content-bright dark:hover:text-content-light";
  // DISABLED LIGHT
  const disabledSolid = `bg-primary-bright border text-primary-lighter cursor-not-allowed`;
  const disabledOutline = `border border-solid border-primary-bright text-primary-lighter cursor-not-allowed`;
  const disabledLight = `text-primary-lighter cursor-not-allowed`;
  const disabledSolidContent = "bg-content-bright border text-content-lighter cursor-not-allowed";
  const disabledOutlineContent = "border border-solid border-content-lighter text-content-lighter cursor-not-allowed";
  const disabledLightContent = "text-content-lighter cursor-not-allowed";
  // DISABLED DARK
  const disabledSolidDark = `dark:bg-primary-darker dark:border dark:text-primary-dark dark:cursor-not-allowed`;
  const disabledOutlineDark = `dark:border dark:border-solid dark:border-primary-darker dark:text-primary-darker dark:cursor-not-allowed`;
  const disabledLightDark = `dark:text-primary-darker dark:cursor-not-allowed`;
  const disabledSolidDarkContent = "dark:bg-content-dark dark:border dark:text-content-darker dark:cursor-not-allowed";
  const disabledOutlineDarkContent =
    "dark:border dark:border-solid dark:border-content-dark dark:text-content-dark dark:cursor-not-allowed";
  const disabledLightDarkContent = "dark:text-content-dark dark:cursor-not-allowed";

  const classes = twMerge(
    classNames(
      `flex relative items-center font-medium rounded-full h-max transition-colors ease-in-out ${inputPaddingClasses({
        size,
        bordered: kind !== "light",
      })} ${textSizeClasses({ size })}`, //
      {
        // "bg-primary-dark": loading,
        "gap-2": size === "small" && title && icon,
        "gap-3": size === "medium" && title && icon,
        "gap-4": size === "large" && title && icon,
        // SOLID
        [darkSolid]: kind === "solid" && colorType !== ColorType.Content && !light && !disabled,
        [lightSolid]: kind === "solid" && colorType !== ColorType.Content && !disabled,
        [darkSolidContent]: kind === "solid" && colorType === ColorType.Content && !light && !disabled,
        [lightSolidContent]: kind === "solid" && colorType === ColorType.Content && !disabled,
        // OUTLINE
        [darkOutline]: kind === "outline" && colorType !== ColorType.Content && !light && !disabled,
        [lightOutline]: kind === "outline" && colorType !== ColorType.Content && !disabled,
        [darkOutlineContent]: kind === "outline" && colorType === ColorType.Content && !light && !disabled,
        [lightOutlineContent]: kind === "outline" && colorType === ColorType.Content && !disabled,
        // LIGHT
        [lightLight]: kind === "light" && colorType !== ColorType.Content && !disabled,
        [darkLight]: kind === "light" && colorType !== ColorType.Content && !light && !disabled,
        [lightLightContent]: kind === "light" && colorType === ColorType.Content && !disabled,
        [darkLightContent]: kind === "light" && colorType === ColorType.Content && !light && !disabled,
        // DISABLED LIGHT
        [disabledLight]: kind === "light" && colorType !== ColorType.Content && disabled,
        [disabledSolid]: kind === "solid" && colorType !== ColorType.Content && disabled,
        [disabledOutline]: kind === "outline" && colorType !== ColorType.Content && disabled,
        [disabledLightContent]: kind === "light" && colorType === ColorType.Content && disabled,
        [disabledSolidContent]: kind === "solid" && colorType === ColorType.Content && disabled,
        [disabledOutlineContent]: kind === "outline" && colorType === ColorType.Content && disabled,
        // DISABLED DARK
        [disabledLightDark]: kind === "light" && colorType !== ColorType.Content && !light && disabled,
        [disabledSolidDark]: kind === "solid" && colorType !== ColorType.Content && !light && disabled,
        [disabledOutlineDark]: kind === "outline" && colorType !== ColorType.Content && !light && disabled,
        [disabledLightDarkContent]: kind === "light" && colorType === ColorType.Content && !light && disabled,
        [disabledSolidDarkContent]: kind === "solid" && colorType === ColorType.Content && !light && disabled,
        [disabledOutlineDarkContent]: kind === "outline" && colorType === ColorType.Content && !light && disabled,
        [`${className}`]: !!className,
      }
    )
  );

  const iconClasses = classNames({
    invisible: loading,
    "h-4 w-4": size === "small",
    "h-5 w-5": size === "medium",
    "h-6 w-6": size === "large",
  });

  const textClasses = classNames("whitespace-normal", {
    invisible: loading,
  });

  const wrapperClasses = classNames("", {
    dark: dark,
  });

  return (
    <div className={wrapperClasses}>
      <button {...props} className={classes} onClick={onClick} type={type} disabled={disabled}>
        {loading && (
          <div className="left-0 absolute w-full h-full flex justify-center items-center">
            <Loading />
          </div>
        )}
        {icon && <div className={iconClasses}>{icon}</div>}
        {title && <div className={textClasses}>{title}</div>}
      </button>
    </div>
  );
};
