import React, { FunctionComponent, useEffect, useState, useMemo } from "react";
import { getEvents, JobApplicationBase } from "../../models/jobApplications";
import { FullJobOffer, getJo } from "../../models/jobOffers";
import dayjs, { Dayjs } from "dayjs";
import { UUID } from "../../models/common";
import { Status } from "../../models/jobOffers";
import { EventModel } from "../../models/event";
import { Tag, Divider, Spinner, Timeline } from "@getprorecrutement/getpro-design";
import {
  ArrowPathIcon,
  CheckCircleIcon,
  ChevronRightIcon,
  ClockIcon,
  PauseCircleIcon,
  PhoneIcon,
  PlayCircleIcon,
} from "@heroicons/react/24/outline";
import classNames from "classnames";
import JobAppInfosAnswers from "./answers";

interface Props {
  jobApp: JobApplicationBase;
  sources: {
    id: UUID;
    name: string;
  }[];
  statuses: Status[];
  callStatuses: Status[];
  // jobOffer: FullJobOffer;
}

const JobAppInfos: FunctionComponent<Props> = (props) => {
  const { jobApp, sources, statuses, callStatuses } = props;
  const [events, setEvents] = useState<EventModel[]>([]);
  const [timeline, setTimeline] = useState<
    {
      date: Dayjs;
      content: string | JSX.Element;
      icon?: JSX.Element;
      key: string;
      kind: string;
    }[]
  >([]);
  const source = useMemo(() => sources.find((s) => s.id === jobApp?.source_id), [sources, jobApp]);
  const [openedDescription, setOpenedDescription] = useState<boolean>(false);
  const [jobOffer, setJobOffer] = useState<FullJobOffer>();

  useEffect(() => {
    if (!jobOffer) getJo(jobApp.job_offer_id).then(setJobOffer);
    else setJobOffer(jobOffer);
  }, [jobApp]);

  const sourceClasses = classNames("text-white", {
    "bg-[#cbba82]": jobApp.source_id === "cbd94a29-c083-45ef-9107-36893aefb39c",
    "bg-[#8de879]": jobApp.source_id === "6803df04-733e-412c-a39e-6bb3af2aec0b",
    "bg-[#1890ff]": jobApp.source_id === "e5a29f8c-6ba7-4b83-92d8-5be93d2bca13",
    "bg-[#115290]": jobApp.source_id === "1c309c6b-f1c3-4286-bc2f-5f607a16fa9c",
    "bg-[#000000]": jobApp.source_id === "ca48f6fa-0d78-4863-8fcf-3714ece2d387",
  });

  useEffect(() => {
    if (jobApp.id) fetchEvents(jobApp.id);
  }, [jobApp]);

  useEffect(() => {
    if (!jobApp) return;
    const res = [];
    res.push({
      key: "ja_creation",
      date: dayjs(jobApp.created_at),
      content: "Candidature créée",
      kind: "ja_creation",
      icon: <PlayCircleIcon className="text-blue-500" />,
    });
    for (let index = 0; index < events.length; index++) {
      const event = events[index];
      if (event.kind === "status_changed_event") {
        res.push({
          key: event.id,
          date: dayjs(event.created_at),
          content: `Statut mis à jour à ${statuses.find((e) => e.id === event.to_id)?.name || "Non défini"}`,
          kind: "status_changed_event",
        });
      } else if (event.kind === "call_status_changed_event") {
        res.push({
          key: event.id,
          date: dayjs(event.created_at),
          content: `Call status mis à jour à ${callStatuses.find((e) => e.id === event.to_id)?.name || "Non défini"}`,
          icon: <PhoneIcon />,
          kind: "call_status_changed_event",
        });
      } else if (event.kind === "job_application_terminated") {
        res.push({
          key: event.id,
          date: dayjs(event.created_at),
          content: `Candidature clôturée`,
          icon: <PauseCircleIcon className="text-red-500" />,
          kind: "job_application_terminated",
        });
      } else if (event.kind === "job_application_restarted") {
        res.push({
          key: event.id,
          date: dayjs(event.created_at),
          content: `Candidature relancée`,
          icon: <ArrowPathIcon className="text-green-500" />,
          kind: "job_application_restarted",
        });
      }
    }
    if (jobApp.starts_job_at)
      res.push({
        key: "ja_start_job_at",
        date: dayjs(jobApp.starts_job_at),
        content: "Date d'entrée en poste",
        icon: <CheckCircleIcon className="text-green-500" />,
        kind: "ja_start_job_at",
      });
    setTimeline(res);
  }, [events]);

  const jaLoading = () => {
    return <Spinner light size="large" title={"Chargement des détails de la candidature ..."} />;
  };

  if (!jobApp) return jaLoading();

  const fetchEvents = async (id: UUID) => {
    getEvents(id).then(setEvents);
  };

  return (
    <>
      <div className="text-2xl mb-2">Informations de la candidature</div>
      <Divider />
      {timeline.length > 0 && (
        <div className="pt-20 pb-24">
          <Timeline
            elems={timeline
              .sort((a, b) => dayjs(a.date).diff(dayjs(b.date)))
              .map((elem) => ({ value: elem, icon: elem.icon }))}
            renderer={(elem) => (
              // Rework renderer with specific design depending on event kind
              <div className="bg-background-lighter px-2 py-1 rounded-lg">
                <div>{elem.content}</div>
                <div className="text-xs flex justify-end items-center gap-1 mt-2">
                  <div>{elem.date.format("D MMM YYYY à HH:mm")}</div>
                  <ClockIcon className="w-4 h-4 min-w-fit" />
                </div>
              </div>
            )}
            size={4}
          />
        </div>
      )}
      <div className="overflow-auto flex-1 flex flex-col gap-2">
        {jobApp.source_id && (
          <div className="flex gap-4 items-center w-full">
            <div style={{ width: 240 }}>Source :</div>
            <div>
              <Tag light value={source?.name || ""} className={sourceClasses} />
            </div>
          </div>
        )}
        {jobApp.status_id === "ce7d87f3-f446-499d-bc49-12112e78f131" && (
          <div className="flex items-center gap-4">
            <div style={{ width: 240 }}>Date d'entrée en poste :</div>
            <div>
              <Tag value={dayjs(jobApp.starts_job_at).format("LL")} light />
            </div>
          </div>
        )}
        {jobApp.description && (
          <div className="border-l-2 border-solid border-border-lighter px-4">
            <div className="flex items-center gap-4 py-2" onClick={() => setOpenedDescription(!openedDescription)}>
              <ChevronRightIcon
                className={`w-4 h-4 min-w-fit stroke-2 transition-all ${openedDescription ? "rotate-90" : ""}`}
              />
              <div>Description</div>
            </div>
            {openedDescription && <div>{jobApp.description}</div>}
          </div>
        )}
        {jobApp.answers && jobOffer && jobApp.answers.length > 0 && (
          <JobAppInfosAnswers jobOffer={jobOffer} answers={jobApp.answers} />
        )}
      </div>
    </>
  );
};

export default JobAppInfos;
