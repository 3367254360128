import React, { FunctionComponent, useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import { CustomerResponse, getCustomers } from "../../../models/customers";
import { ColumnProps, Table, Tooltip } from "@getprorecrutement/getpro-design";

const PER_PAGE = 10;

export const BillingCustomersPage: FunctionComponent = () => {
  const [clients, setClients] = useState<CustomerResponse[]>([]);
  const [search, setSearch] = useState<string>("");
  const navigate = useNavigate();
  const [page, setPage] = useState<number>(1);

  useEffect(() => {
    getCustomers().then(setClients);
  }, []);

  const columns: ColumnProps<CustomerResponse>[] = [
    {
      title: "Nom",
      dataIndex: "name",
      key: "name",
      filterBy: "text",
    },
    {
      title: "Propriétaire",
      dataIndex: "owner_id",
      key: "owner_id",
      render: (_, customer) => (
        <div>
          {customer.owner_first_name} {customer.owner_last_name}
        </div>
      ),
    },
    {
      title: "Adresse de facturation",
      dataIndex: "location",
      key: "location",
      width: 200,
      render: (_, customer) => (
        <Tooltip position="top" title={customer.location.formatted_address}>
          <div className="truncate w-[200px]">{customer.location.formatted_address}</div>
        </Tooltip>
      ),
    },
    {
      title: "Nombre de contrats",
      width: 160,
      dataIndex: "contracts_count",
      key: "contracts_count",
      render: (val) => <div className="flex justify-center">{val}</div>,
    },
    {
      title: "Nombre de projets",
      width: 150,
      dataIndex: "projects_count",
      key: "projects_count",
      render: (val) => <div className="flex justify-center">{val}</div>,
    },
  ];

  useEffect(() => {
    if (page !== 1) setPage(1);
  }, [search]);

  const displayedCustomers = clients
    .filter((c) => c.name.toLowerCase().includes(search.toLowerCase()))
    .filter((_, i) => i >= (page - 1) * 10 && i < page * 10);

  return (
    <div>
      <div className="bg-white p-6 shadow-md mb-6">
        <div className="text-3xl font-bold">Clients</div>
      </div>
      <div className="px-6">
        <Table
          dataSource={{
            data: displayedCustomers.map((e) => ({ key: e.id, ...e })),
            total: clients.filter((c) => c.name.toLowerCase().includes(search.toLowerCase())).length,
          }}
          columns={columns}
          light
          bordered
          onClick={(record) => {
            navigate(`/billing/customers/${record.id}`);
          }}
          pagination={{ per_page: PER_PAGE, page, onChange: setPage }}
          filter={{
            filters: { name: search },
            onFilterChange: (key, value) => {
              if (key === "name") {
                setSearch(value);
              }
            },
          }}
        />
      </div>
    </div>
  );
};

export default BillingCustomersPage;
