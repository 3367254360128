import React, { FunctionComponent, useState } from "react";
import classNames from "classnames";
import { twMerge } from "tailwind-merge";

export interface Props {
  placeholder: string;
  label?: string;
  size?: "small" | "medium" | "large";
  disabled?: boolean;
  error?: string;
  rows: number;
  value?: string;
  onChange?: (value: string) => void;
  dark?: boolean;
  light?: boolean;
}

export const Textarea: FunctionComponent<Props & Omit<React.HTMLAttributes<HTMLDivElement>, "onChange">> = ({
  placeholder,
  label,
  disabled,
  error,
  rows,
  value,
  onChange,
  dark,
  light,
  size = "medium",
  ...props
}) => {
  const [focused, setFocused] = useState<boolean>(false);

  const wrapperClasses = classNames("bg-inherit", {
    dark: dark,
  });

  const groupClasses = twMerge(
    classNames("GetPro__TextInput group relative mt-1 bg-inherit text-content-light", {
      "text-content-darker": !error,
      "text-error-normal": error,
      "text-error-light": disabled && error,
      [`${props.className}`]: !!props.className,
    })
  );

  const textareaClasses = twMerge(
    classNames(
      "flex relative w-full items-center w-full  \
       bg-transparent font-bold rounded-xl \
       placeholder:font-light placeholder:italic placeholder:text-content-light \
       transition-colors focus:outline-none text-content-darker",
      {
        "dark:text-content-bright dark:placeholder:text-primary-dark": !light,
        "px-4 placeholder:text-xs": size === "small",
        "px-7 placeholder:text-sm": size === "medium",
        "px-10 placeholder:text-base": size === "large",
        "placeholder:text-error-light": error,
        "cursor-not-allowed": disabled,
      }
    )
  );

  const textareaWrapperClasses = twMerge(
    classNames("border border-solid border-border-lighter rounded-xl", {
      "dark:text-content-bright dark:border-border-medium": !light,
      "group-hover:border-border-darker focus:border-border-darker": !disabled,
      "dark:group-hover:border-border-bright dark:focus:border-border-bright": !disabled && !light,
      "border-error-normal": error,
      "group-hover:border-error-dark focus:border-error-normal": !disabled && error,
      "py-3 text-sm": size === "small",
      "py-3 text-base": size === "medium",
      "py-5 text-lg": size === "large",
      "cursor-not-allowed": disabled,
    })
  );

  const labelClasses = twMerge(
    classNames("absolute z-10 -top-2 text-content-light font-medium bg-inherit transition-colors", {
      "text-xs left-3 px-1": size === "small",
      "text-xs left-5 px-2": size === "medium",
      "text-sm left-8 px-2": size === "large",
      "dark:text-primary-dark": !light,
      "group-hover:text-content-darker": !disabled && !error,
      "dark:group-hover:text-content-bright": !disabled && !error && !light,
      "text-content-darker": focused,
      "dark:text-content-bright": focused && !light,
      "text-error-normal": !disabled && error,
    })
  );

  const errorClasses = classNames("mt-1 italic", {
    "pl-3 text-xs": size === "small",
    "pl-5 text-sm": size === "medium",
    "pl-8": size === "large",
  });

  return (
    <div className={wrapperClasses}>
      <div {...props} className={groupClasses}>
        {label && <label className={labelClasses}>{label}</label>}
        <div className={textareaWrapperClasses}>
          <textarea
            className={textareaClasses}
            value={value || ""}
            onChange={({ target }) => onChange?.(target.value)}
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
            placeholder={placeholder}
            disabled={disabled}
            rows={rows}
          />
        </div>
        {error && <div className={errorClasses}>{error}</div>}
      </div>
    </div>
  );
};
