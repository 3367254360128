import { DateRange, Radio } from "@getprorecrutement/getpro-design";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { ChartBillData, DateFilters, getBillsData } from "../../../models/billing/chart";
import ReactFrappeChart from "react-frappe-charts";

export const BillingCharts = () => {
  const [bills, setBills] = useState<ChartBillData[]>([]);
  const [dateFilters, setDateFilters] = useState<DateFilters>({
    from: dayjs().subtract(1, "month").format("YYYY-MM-DD"),
    to: dayjs().add(1, "month").format("YYYY-MM-DD"),
    granularity: "week",
  });

  const fetchBillsData = async (date: DateFilters) => {
    const b = await getBillsData(date);
    setBills(b);
  };

  const getDatasets = () => [
    {
      name: "En attente",
      values: bills.map((e) => e.pending),
    },
    {
      name: "Facturé",
      values: bills.map((e) => e.billed),
    },
    {
      name: "Payé",
      values: bills.map((e) => e.paid),
    },
  ];

  useEffect(() => {
    fetchBillsData(dateFilters);
  }, [dateFilters]);

  return (
    <div className="bg-white p-6 shadow-md mb-6">
      <div className="flex items-center gap-4">
        <div className="w-fit">
          <DateRange
            size="small"
            light
            value={dateFilters}
            onChange={(val) =>
              setDateFilters({
                ...dateFilters,
                ...(val.from && val.to ? (val as { from: string; to: string }) : {}),
              })
            }
          />
        </div>
        <Radio
          onChange={(e) => setDateFilters((f) => ({ ...f, granularity: e as "day" | "week" | "month" }))}
          light
          value={dateFilters.granularity}
          optionType="button"
          options={[
            { label: "Jours", value: "day" },
            { label: "Semaine", value: "week" },
            { label: "Mois", value: "month" },
          ]}
        />
      </div>
      <ReactFrappeChart
        type="bar"
        colors={["#21ba45"]}
        axisOptions={{ xAxisMode: "tick", yAxisMode: "tick", xIsSeries: 1 }}
        height={250}
        barOptions={{ stacked: 1 }}
        data={{
          labels: bills.map((e) => dayjs(e.due_date).format(dateFilters.granularity === "month" ? "MMM YYYY" : "ll")),
          datasets: getDatasets(),
        }}
      />
    </div>
  );
};

export default BillingCharts;
