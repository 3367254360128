import React, { FunctionComponent } from "react";
import { Contact } from "../../../models/contacts";
import { copyToClipboard } from "../../../utils";
import { Tag, MoreActions, Tooltip } from "@getprorecrutement/getpro-design";
import { EnvelopeIcon, PhoneIcon } from "@heroicons/react/24/outline";

interface ContactProps {
  contact: Contact;
  openMessenger: (contact: Contact) => void;
}

export const ContactElem = (props: ContactProps) => {
  const { contact, openMessenger } = props;

  return (
    <div className="px-4 py-2 flex justify-between items-center bg-content-bright rounded-2xl gap-6">
      <div>
        <div className="font-bold">
          {contact.first_name} {contact.last_name}
        </div>
        {contact.title && <div className="text-content-light">{contact.title}</div>}
      </div>
      <div className="flex flex-col justify-center gap-1">
        <div className="px-2 py-1 rounded-3xl border border-border-bright">
          <Tooltip title={contact.email}>
            <EnvelopeIcon onClick={() => openMessenger(contact)} className="h-4 w-4 cursor-pointer" />
          </Tooltip>
        </div>

        <div className="px-2 py-1 rounded-3xl border border-border-bright">
          <Tooltip title={contact.phone}>
            <PhoneIcon
              className="h-4 w-4 cursor-pointer"
              onClick={() =>
                copyToClipboard(contact.phone, "Le numéro de téléphone a été copié dans votre presse-papier")
              }
            />
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

interface Props {
  contacts: Contact[];
  openMessenger: (contact: Contact) => void;
  direction?: "horizontal" | "vertical";
}

export const ContactsList: FunctionComponent<Props> = (props) => {
  const { openMessenger } = props;
  const contacts = props.contacts || [];

  const direction = props.direction || "horizontal";

  return (
    <div>
      {direction === "vertical" && <div className="text-lg font-bold mb-2">Contacts</div>}
      <div className="flex items-center gap-2 flex-wrap">
        {direction === "horizontal" && <div className="text-lg font-bold">Contacts</div>}
        {contacts.length > 0 ? (
          contacts.map((c) => <ContactElem contact={c} key={c.id} openMessenger={openMessenger} />)
        ) : (
          <div className="leading-6">Aucun contact</div>
        )}
      </div>
    </div>
  );
};

export default ContactsList;
