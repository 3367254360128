/* istanbul ignore next */
function download(filename: string, data: string): void {
  const element = document.createElement("a");

  element.setAttribute(
    "href",
    `data:text/plain;charset=utf-8,${encodeURIComponent(data)}`
  );
  element.setAttribute("download", filename);

  element.style.display = "none";
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}

export default download;
