import React from "react";
import { Tooltip } from "@getprorecrutement/getpro-design";
import { QuestionMarkCircleIcon } from "@heroicons/react/24/outline";

interface Props {
  title: string;
  position?: "left" | "right" | "top" | "bottom";
}

const HelpTooltip = (props: Props) => {
  return (
    <Tooltip {...props} light>
      <QuestionMarkCircleIcon width={20} height={20} className="text-content-light" />
    </Tooltip>
  );
};

export default HelpTooltip;
