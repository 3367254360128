import { Tooltip } from "@getprorecrutement/getpro-design";
import React from "react";
import { FunctionComponent } from "react";
import { copyToClipboard } from "../utils";
import { PhoneIcon, EnvelopeIcon } from "@heroicons/react/24/solid";
import LinkedinIcon from "../utils/icons";

interface Props {
  email?: string;
  linkedinUrl?: string;
  phone?: string;
}

export const JobAppContacts: FunctionComponent<Props> = (props) => {
  const { email, linkedinUrl, phone } = props;

  return (
    <div className="bg-[#ededed] rounded-xl h-6">
      <div className="flex justify-around items-center h-6">
        <Tooltip light title={phone || ""}>
          <span
            onClick={() =>
              phone && copyToClipboard(phone, "Le numéro de téléphone a été copié dans votre presse-papier")
            }
            className={phone ? "cursor-pointer" : ""}
          >
            <PhoneIcon width={20} className={phone ? "text-primary-medium" : "text-primary-lightest"} />
          </span>
        </Tooltip>
        <Tooltip light title={linkedinUrl ? "Voir le profil" : ""}>
          <a href={linkedinUrl} className={!linkedinUrl ? "cursor-not-allowed" : ""}>
            <LinkedinIcon fill={linkedinUrl ? "#7942ed" : "#cec2fc"} />
          </a>
        </Tooltip>
        <Tooltip light title={email || ""}>
          <span
            onClick={() => {
              email && copyToClipboard(email, "L'adresse e-mail a été copié dans votre presse-papier");
            }}
            className={email ? "cursor-pointer" : ""}
          >
            <EnvelopeIcon width={20} className={email ? "text-primary-medium" : "text-primary-lightest"} />
          </span>
        </Tooltip>
      </div>
    </div>
  );
};

export default JobAppContacts;
