import toast from "react-hot-toast";
import httpClient from "../services/http/client";
import ROUTES from "../services/http/routes";
import { notifyError } from "../services/requests";
import { UUID } from "./common";

export interface Contact extends NewContact {
  id: UUID;
  created_at: string;
  updated_at: string;
  archived: boolean;
}

export interface NewContact {
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  customer_id: UUID;
  title?: string;
}

export interface ContractContacts {
  billing: Contact;
  legal: Contact;
}

export async function getCustomerContacts(id: UUID): Promise<Contact[]> {
  return httpClient.req(ROUTES.FETCH_CONTACTS_BY_CUSTOMER(id), (err) =>
    notifyError(err || "Erreur lors de la récupération des contacts")
  );
}

export async function getContractContacts(id: UUID): Promise<ContractContacts> {
  return httpClient.req(ROUTES.FETCH_CONTACTS_BY_CONTRACT(id), (err) =>
    notifyError(err || "Erreur lors de la récupération des contacts du contrat")
  );
}

export async function getProjectContacts(id: UUID): Promise<Contact[]> {
  return httpClient.req(ROUTES.FETCH_CONTACTS_BY_PROJECT(id), (err) =>
    notifyError(err || "Erreur lors de la récupération des contacts du projet")
  );
}

export async function createContact(contact: NewContact): Promise<Contact> {
  return httpClient
    .req<Contact>(ROUTES.CREATE_CONTACT(contact), (err) => notifyError(err || "Erreur lors de la création du contact"))
    .then((e) => {
      toast.success("Contact ajouté avec succès");
      return e;
    });
}

export async function updateContact(contact: Contact): Promise<Contact> {
  return httpClient
    .req<Contact>(ROUTES.UPDATE_CONTACT(contact), (err) =>
      notifyError(err || "Erreur lors de la mise à jour du contact")
    )
    .then((e) => {
      toast.success("Contact édité avec succès");
      return e;
    });
}

export async function archiveContact(id: UUID): Promise<void> {
  return httpClient
    .req<void>(ROUTES.UPDATE_CONTACT({ id, archived: true }), (err) =>
      notifyError(err || "Erreur lors de la suppression du contact")
    )
    .then((e) => {
      toast.success("Contact supprimé avec succès");
      return e;
    });
}
