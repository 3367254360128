import React, { FunctionComponent, useState } from "react";
import Confetti from "react-confetti";
import store, { Message } from "../services/store";
import { useWindowSize } from "react-use";

const Confettis: FunctionComponent = () => {
  const [confetti, _setConfetti] = useState<boolean>(false);
  const [confettiFinished, setConfettiFinished] = useState<boolean>(true);
  const { width, height } = useWindowSize();

  const setConfetti = () => {
    _setConfetti(true);
    setConfettiFinished(false);

    setTimeout(() => {
      setConfettiFinished(true);
    }, 15000);
    setTimeout(() => {
      _setConfetti(false);
    }, 25000);
  };

  store.listen(Message.Confetti, setConfetti);

  return (
    <Confetti
      numberOfPieces={100}
      run={confetti}
      recycle={!confettiFinished}
      style={{ zIndex: 2000, position: "fixed" }}
      width={width}
      height={height}
    />
  );
};

export default Confettis;
