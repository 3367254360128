import React, { FunctionComponent, useEffect, useRef } from "react";
import { JobOffer, JobOfferData, NewJobOffer, FullJobOffer, NewJobOfferDetails } from "../../../models/jobOffers";
import { toast } from "react-hot-toast";
import { useState } from "react";
import BasicStepForm from "./basicStep";
import BillingStepForm from "./billingStep";
import RedactionStepForm from "./redactionForm";
import { UUID } from "../../../models/common";
import { createJo, updateJo } from "../../../models/jobOffers";
import { getUser } from "../../../models/users/users";
import {
  getProject,
  NewProject,
  Project,
  ProjectData,
  ProjectType,
  updateProject,
} from "../../../models/billing/project";
import { isGetProId } from "../../../utils";
import { Button, Steps } from "@getprorecrutement/getpro-design";

interface Props {
  jobOffer?: JobOffer;
  onFinish: (jobOffer: JobOffer) => void;
  onSubmit?: (jobOffer: FullJobOffer) => void;
}

export interface StepHandle {
  getData: () =>
    | NewJobOffer
    | {
        project?: ProjectData;
        job_offer?: JobOfferData;
      };
  isValid: () => boolean;
}

const getSteps = (billing: boolean) => {
  const steps: { title: string; description: string }[] = [];
  steps.push({
    title: "Détails",
    description: "Informations basiques",
  });
  if (billing)
    steps.push({
      title: "Facturation",
      description: "Infos commerciales",
    });
  steps.push({
    title: "Rédaction",
    description: "Templates de l'offre",
  });
  return steps;
};

const INC_RE =
  /(^|\s)[-'a-zA-ZÀ-ÖØ-öø-ÿ]{3,}((\.|·)(([-'aeiouÀ-ÖØ-öø-ÿ]{1,2})|([-'a-zA-ZÀ-ÖØ-öø-ÿ][-'aeiouÀ-ÖØ-öø-ÿ]))){1,2}($|\s|,|.|;)/;

function checkContentQuality(data?: NewJobOfferDetails | JobOfferData): boolean {
  let err;
  if (data?.description && data?.description.match(INC_RE))
    err = "Merci d'adopter une syntaxe correcte dans votre description";

  if (err) {
    toast.error(err);
    return false;
  }
  return true;
}

export const JobOfferForm: FunctionComponent<Props> = (props) => {
  const [jobOffer, setJobOffer] = useState<Partial<JobOffer>>(props.jobOffer || {});
  const [values, setValues] = useState<
    | NewJobOffer
    | {
        project?: ProjectData;
        job_offer?: JobOfferData;
      }
  >({ job_offer: props.jobOffer });
  const [project, setProject] = useState<Partial<Project>>({});
  const [current, setCurrent] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState<boolean>();
  const childRef = useRef<StepHandle>(null);

  const steps = getSteps((!props.jobOffer || !!jobOffer.project_id) && jobOffer.opportunity_type !== "interne");
  const saveable = !!jobOffer.id || current >= 1;

  useEffect(() => {
    if (jobOffer) setValues({ ...values, job_offer: jobOffer });
  }, [jobOffer]);

  useEffect(() => {
    if (project) setValues({ ...values, project: project });
  }, [project]);

  const onSubmitCreate = async (
    data:
      | NewJobOffer
      | {
          project?: ProjectData;
          job_offer?: JobOfferData;
        }
  ) => {
    const jo = data.job_offer;
    if (!jo) return;

    jo.validation_period_months = jo.validation_period_months || 0;
    if (jo.owner_id) {
      const user = await getUser(jo.owner_id);
      jo.business_unit_id = user.business_unit_id;
    }

    const details = {
      project_type: data.project?.project_type,
      ...(data.project?.details
        ? {
            amount: data.project.project_type === ProjectType.CommissionRate ? jo.expected_salary : undefined,
            ...data.project?.details,
          }
        : {}),
    };

    const payload: NewJobOffer = {
      job_offer: jo as NewJobOfferDetails,
      project:
        jo.opportunity_type !== "interne"
          ? ({
              owner_id: jo.owner_id,
              name: jo.name,
              customer_id: jo.company_id,
              ...data.project,
              details,
            } as NewProject)
          : undefined,
    };
    const resp = await createJo(payload);
    return resp;
  };

  const onSubmit = async (
    data:
      | NewJobOffer
      | {
          project?: ProjectData;
          job_offer?: JobOfferData;
        }
  ) => {
    if (saveable) {
      if (!checkContentQuality(data.job_offer)) return;
      setLoading(true);
      const jo = { ...jobOffer, ...data.job_offer };
      jo.validation_period_months = jo.validation_period_months || 0;

      try {
        const resp = await (jo.id
          ? updateJo(jo as JobOfferData & { id: UUID })
          : onSubmitCreate({ ...data, job_offer: jo }));
        if (jo.project_id && current === 1) {
          const details = {
            project_type: data.project?.project_type,
            ...(data.project?.details
              ? {
                  amount: data.project.project_type === ProjectType.CommissionRate ? jo.expected_salary : undefined,
                  ...data.project?.details,
                }
              : {}),
          };
          const project = await updateProject({
            id: jo.project_id,
            owner_id: jo.owner_id,
            name: jo.name,
            ...data.project,
            details,
          });
          setProject(project);
        }

        if (resp) {
          setJobOffer(resp);
          if (current < steps.length - 1) setCurrent((current) => current + 1);
          else props.onFinish(resp);
          props.onSubmit && props.onSubmit(resp);
        }
        toast.success(props.jobOffer ? "Offre mise à jour avec succès" : "Offre créée avec succès", {
          className: "z-[500]",
        });
      } finally {
        setLoading(false);
      }
    } else {
      setJobOffer((jo) => ({
        ...jo,
        ...data.job_offer,
        opportunity_type: isGetProId(data.job_offer?.company_id) ? "interne" : undefined,
      }));
      if (current < steps.length - 1) setCurrent((current) => current + 1);
    }
  };

  async function fetchProject(id: UUID) {
    const p = await getProject(id);
    setProject(p);
  }

  useEffect(() => {
    const jo = props.jobOffer;
    if (jo?.project_id) fetchProject(jo.project_id);

    setJobOffer(jo || {});
  }, [props.jobOffer]);

  const CurrentForm = (
    (!props.jobOffer || !!jobOffer.project_id) && jobOffer.opportunity_type !== "interne"
      ? [BasicStepForm, BillingStepForm, RedactionStepForm]
      : [BasicStepForm, RedactionStepForm]
  )[current];

  const goToStep = (step: number) => {
    if (childRef.current) {
      setValues(childRef.current.getData());
      if (childRef.current.isValid() && (current + 1 == step || props.jobOffer?.id)) {
        setCurrent(step);
      } else if (current > step) {
        setCurrent(step);
      }
    }
  };

  const validateAndSubmit = () => {
    if (childRef.current?.isValid()) {
      const data = childRef.current.getData();
      setValues(data);
      onSubmit(data);
    }
  };

  return (
    <>
      <div className="mb-2">
        <Steps light steps={steps} currentStep={current} onClick={(step) => goToStep(step)} />
      </div>
      <div className="jobOfferForm bg-inherit py-2 pr-4 overflow-auto">
        <div className="bg-inherit">
          <div className="mt-4 bg-inherit">
            <CurrentForm ref={childRef} data={values} setDisabled={setDisabled} jobOffer={jobOffer} project={project} />
          </div>
          <div className="flex justify-end mt-8">
            {current !== steps.length - 1 ? ( // Is not last step
              <Button
                light
                size="small"
                loading={loading}
                title={saveable ? "Enregister & Suivant" : "Suivant"}
                disabled={disabled}
                onClick={validateAndSubmit}
              />
            ) : // Last step
            saveable ? (
              <Button
                light
                size="small"
                loading={loading}
                title="Enregister"
                disabled={disabled}
                onClick={validateAndSubmit}
              />
            ) : (
              <span>Merci de remplir les champs obligatoires</span>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default JobOfferForm;
