import React, { useEffect, useState } from "react";
import store, { OmniSearchHistory, OmniSearchResultType, Stored } from "../../../services/store";
import { SearchResults } from ".";
import { OmnisearchResults } from "./results";

const constructSearchResultsFromHistory = (history: OmniSearchHistory) => {
  const results: SearchResults = [];
  const candidates = history[OmniSearchResultType.Candidate];
  const jobOffers = history[OmniSearchResultType.JobOffer];
  const companies = history[OmniSearchResultType.Company];
  if (candidates?.length) {
    results.push({
      indexUid: "candidates",
      hits: candidates,
      processingTimeMs: 0,
      query: "",
    });
  }
  if (jobOffers?.length) {
    results.push({
      indexUid: "job_offers",
      hits: jobOffers,
      processingTimeMs: 0,
      query: "",
    });
  }
  if (companies?.length) {
    results.push({
      indexUid: "companies",
      hits: companies,
      processingTimeMs: 0,
      query: "",
    });
  }
  return results;
};

export const OmnisearchHistory = ({ closeModal }: { closeModal: () => void }) => {
  const [results, setResults] = useState<SearchResults>();

  useEffect(() => {
    if (store.state.OmniSearchHistory) {
      setResults(constructSearchResultsFromHistory(store.state.OmniSearchHistory));
    }
  }, []);

  store.listen(Stored.OmniSearchHistory, (history) => {
    if (history) setResults(constructSearchResultsFromHistory(history));
  });

  return results?.length ? <OmnisearchResults results={results || []} closeModal={closeModal} /> : <></>;
};
