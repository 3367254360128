import React, { FunctionComponent } from "react";
import { useState } from "react";
import { UUID } from "../../../models/common";
import { phoneFormatter } from "../../../utils/formatters";
import { JobApplicationResponse, createJa } from "../../../models/jobApplications";
import { NewCandidate, createCandidate } from "../../../models/candidate";
import { Button, TextInput } from "@getprorecrutement/getpro-design";
import { useFormManager } from "../../../services/form";
import { useLoading } from "../../../utils/loading";
import { isEmailValid } from "../../../utils";

interface Props {
  jobOfferId: UUID;
  onFinish: (jobApp: JobApplicationResponse) => void;
}

export const NewCandidateForm: FunctionComponent<Props> = (props) => {
  const [loading, setLoading] = useState(false);
  const load = useLoading(setLoading);
  const form = useFormManager<NewCandidate>({
    defaultState: {},
    validations: {
      first_name: {
        required: true,
        message: "Merci de préciser le prénom",
      },
      last_name: {
        required: true,
        message: "Merci de préciser le nom",
      },
      phone: {
        required: (val?: string) => !!val && val.length > 13,
        message: "Merci de préciser un numéro de téléphone valide",
      },
      email: {
        required: (val?: string) => !!val && isEmailValid(val),
        message: "Merci de préciser une adresse e-mail valide",
      },
    },
  });

  const onSubmit = async (data: NewCandidate) => {
    const candidate = {
      ...data,
      full_name: `${data.first_name} ${data.last_name}`,
    };

    load(
      createCandidate(candidate).then(
        async (c) =>
          await createJa({
            job_offer_id: props.jobOfferId,
            candidate_id: c.id,
            // source_id is defined with Sweeft source id
            source_id: "6803df04-733e-412c-a39e-6bb3af2aec0b",
          }).then((ja) => props.onFinish(ja))
      )
    );
  };

  return (
    <>
      <div className="mb-2 text-2xl">Nouveau candidat</div>
      <div className="bg-inherit py-2 pr-4 overflow-auto">
        <TextInput
          className="mt-2"
          label="Prénom"
          type="text"
          light
          placeholder="Prénom"
          value={form.state.first_name}
          error={form.errors.first_name}
          onChange={(e) =>
            form.onChange({
              first_name: e.currentTarget.value,
            })
          }
        />
        <TextInput
          className="mt-6"
          label="Nom"
          type="text"
          light
          placeholder="Nom"
          value={form.state.last_name}
          error={form.errors.last_name}
          onChange={(e) =>
            form.onChange({
              last_name: e.currentTarget.value,
            })
          }
        />
        <TextInput
          className="mt-6"
          label="Numéro de téléphone"
          type="text"
          light
          placeholder="Numéro de téléphone"
          value={form.state.phone}
          error={form.errors.phone}
          onChange={(e) =>
            form.onChange({
              phone: phoneFormatter(e.currentTarget.value),
            })
          }
        />
        <TextInput
          className="mt-6"
          label="Adresse e-mail"
          type="email"
          light
          placeholder="Adresse e-mail"
          value={form.state.email}
          error={form.errors.email}
          onChange={(e) =>
            form.onChange({
              email: e.currentTarget.value,
            })
          }
        />
        <div className="flex justify-end mt-8">
          <Button
            size="small"
            light
            loading={loading}
            title="Enregistrer"
            onClick={() => form.validate() && onSubmit(form.state as NewCandidate)}
            disabled={form.disabled}
          />
        </div>
      </div>
    </>
  );
};

export default NewCandidateForm;
