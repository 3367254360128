import React, { useState } from "react";
import { FunctionComponent } from "react";
import { UUID } from "../../../../models/common";
import { Status } from "../../../../models/jobOffers";
import dayjs from "dayjs";
import { Table, ColumnProps, Tooltip, Tag } from "@getprorecrutement/getpro-design";
import LinkedinIcon from "../../../../utils/icons";
import { useNavigate } from "react-router";
import qs from "qs";

const PER_PAGE = 5;

interface CandidateInfos {
  id: UUID;
  full_name: string;
  job_offer_name: string;
  job_offer_id: UUID;
  linkedin_url?: string;
  status_id: UUID;
  created_at: string;
}

interface Props {
  statuses: Status[];
  candidates?: CandidateInfos[];
  setSortFilter: (val?: { field: string; order?: "ascend" | "descend" }) => void;
}

export const StatusesCandidates: FunctionComponent<Props> = (props) => {
  const { statuses, candidates, setSortFilter } = props;
  const [page, setPage] = useState<number>(1);
  const navigate = useNavigate();
  const [sortBy, setSortBy] = useState<{
    key: keyof CandidateInfos;
    sortDirection: undefined | "asc" | "desc";
  }>();

  const _setSortBy = (key: keyof CandidateInfos, direction: "desc" | "asc" | undefined) => {
    setSortBy({ key: key, sortDirection: direction });
    setSortFilter({
      field: key,
      order: direction === "asc" ? "ascend" : direction === "desc" ? "descend" : undefined,
    });
    setPage(1);
  };

  const columns: ColumnProps<{
    id: UUID;
    full_name: string;
    job_offer_name: string;
    job_offer_id: UUID;
    linkedin_url?: string;
    status_id: UUID;
    created_at: string;
  }>[] = [
    {
      title: "Candidat",
      dataIndex: "full_name",
      key: "full_name",
    },
    {
      title: "Poste",
      dataIndex: "job_offer_name",
      key: "job_offer_name",
    },
    {
      title: "Linkedin",
      width: 70,
      dataIndex: "linkedin_url",
      key: "linkedin_url",
      render: (value) => (
        <div className="flex justify-center items-center">
          {value && (
            <Tooltip light title="Voir le profil">
              <a
                href={value}
                style={{
                  height: 20,
                  cursor: value ? undefined : "not-allowed",
                }}
              >
                <LinkedinIcon />
                {/* <LinkedinOutlined style={{ fontSize: 20 }} /> */}
              </a>
            </Tooltip>
          )}
        </div>
      ),
    },
    {
      title: "Statut",
      width: 160,
      sortable: true,
      dataIndex: "status_id",
      key: "status_id",
      render: (value) => {
        const status = statuses.find((s) => s.id === value);
        return (
          <div>
            {status && (
              <Tag
                rounded
                bordered
                value={status.name}
                light
                icon={<div className="w-2 h-2 rounded-full" style={{ background: `#${status.color}` }} />}
              />
            )}
          </div>
        );
      },
    },
    {
      title: "Date",
      dataIndex: "created_at",
      key: "created_at",
      width: 130,
      sortable: true,
      render: (value) => <div>{dayjs(value).format("D MMM YYYY")}</div>,
    },
  ];

  const displayedCandidates = (
    (sortBy && sortBy.key === "created_at"
      ? candidates?.sort((a, b) =>
          sortBy.sortDirection === "asc"
            ? dayjs(a.created_at).diff(dayjs(b.created_at))
            : dayjs(b.created_at).diff(dayjs(a.created_at))
        )
      : candidates) || []
  ).filter((_, i) => i >= (page - 1) * PER_PAGE && i < page * PER_PAGE);

  return (
    <Table
      size="small"
      dataSource={{ data: displayedCandidates, total: candidates?.length || 0 }}
      columns={columns}
      rowClassName={() => "cursor-pointer"}
      onClick={(v) =>
        navigate({
          pathname: `/jobs/${v.job_offer_id}`,
          search: qs.stringify({ candidateId: v.id }),
        })
      }
      bordered
      light
      pagination={{ per_page: PER_PAGE, page, onChange: setPage }}
      sortBy={sortBy}
      onSortDirectionChange={_setSortBy}
    />
  );
};

export default StatusesCandidates;
