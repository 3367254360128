import { useCallback } from "react";

export const useLoading = (setLoading: React.Dispatch<React.SetStateAction<boolean>>) => {
  return useCallback(
    <T>(promise: Promise<T>) => {
      setLoading(true);
      return promise.finally(() => setLoading(false));
    },
    [setLoading]
  );
};
