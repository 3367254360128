import classNames from "classnames";
import React, { FunctionComponent, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { twMerge } from "tailwind-merge";

export interface Props {
  children: JSX.Element;
  optionalRenderer?: () => JSX.Element;
  onDragStart?: () => void;
  onDragEnd?: () => void;
}

interface MousePos {
  x: number;
  y: number;
}

interface DraggedItemProps {
  children: JSX.Element;
  initialMousePos: MousePos;
}

const DraggedItem: FunctionComponent<DraggedItemProps> = ({ children, initialMousePos }) => {
  const [mousePos, setMousePos] = useState<MousePos>(initialMousePos);

  const trackMousePos = (event: any) => {
    setMousePos({ x: event.clientX, y: event.clientY });
  };

  useEffect(() => {
    document.addEventListener("dragover", trackMousePos);
    return () => {
      document.removeEventListener("dragover", trackMousePos);
    };
  }, []);

  return (
    <div
      className="z-50 fixed w-fit h-fit"
      style={{ top: mousePos?.y, left: mousePos?.x, transform: "translate(-50%, -50%)" }}
    >
      {children}
    </div>
  );
};

export const Draggable: FunctionComponent<Props & React.HTMLAttributes<HTMLDivElement>> = ({
  children,
  optionalRenderer,
  onDragStart,
  onDragEnd,
  ...props
}) => {
  const [dragging, setDragging] = useState<boolean>(false);
  const [portal, setPortal] = useState<HTMLElement>();
  const [initialMousePos, setInitialMousePos] = useState<MousePos>();

  useEffect(() => {
    let elem = document.getElementById("draggable-portal");
    if (elem) {
      setPortal(elem);
    } else {
      throw new Error("Draggable Portal not defined");
    }
  }, []);

  const classes = twMerge(
    classNames("cursor-pointer w-full", {
      invisible: dragging,
      [`${props.className}`]: props.className,
    })
  );

  const allowDrag = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  return (
    <div className="w-full">
      {dragging &&
        portal &&
        createPortal(
          <DraggedItem initialMousePos={initialMousePos as MousePos}>
            {(optionalRenderer && optionalRenderer()) || children}
          </DraggedItem>,
          portal
        )}
      <div
        {...props}
        onDragOver={allowDrag}
        className={classes}
        draggable
        onDragStart={(ev) => {
          onDragStart?.();
          const ghostImage = document.createElement("img");
          ev.dataTransfer.setDragImage(ghostImage, 0, 0);
          window.setTimeout(() => {
            setInitialMousePos({
              x: ev.clientX,
              y: ev.clientY,
            });
            setDragging(true);
          }, 0);
        }}
        onDragEnd={() => {
          setDragging(false);
          onDragEnd?.();
        }}
      >
        {children}
      </div>
    </div>
  );
};
