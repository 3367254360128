import React from "react";
import { SearchedCandidate } from ".";
import { DEFAULT_PROFILE_PIC } from "../../../assets/profilePicFallback";
import { Image } from "@getprorecrutement/getpro-design";

export const CandidateResult = (props: SearchedCandidate) => (
  <div className="flex p-2 items-center gap-4 bg-white rounded-full hover:bg-background-lightest transition">
    <div className="min-w-[32px] min-h-[32px] w-8 h-8 flex items-center justify-center bg-white rounded-full">
      <Image
        src={props.picture_url || DEFAULT_PROFILE_PIC}
        fallback={DEFAULT_PROFILE_PIC}
        className="max-w-full max-h-full rounded-full"
      />
    </div>
    <div>{props.full_name}</div>
  </div>
);
