import { useCallback, useEffect, useState } from "react";

interface FormManagerProps<T> {
  defaultState: Partial<T>;
  validations?: { [key in keyof T]?: { required: boolean | ((val?: T[key]) => boolean); message: string } };
}

export interface FormManager<T> {
  state: Partial<T>;
  onChange: (value: Partial<T>) => void;
  errors: { [key in keyof T]?: string };
  disabled: boolean;
  validate: () => boolean;
}

export const useFormManager = <T>({ defaultState, validations }: FormManagerProps<T>): FormManager<T> => {
  const [state, setState] = useState<Partial<T>>(defaultState);
  const [errors, setErrors] = useState<{ [key in keyof T]?: string }>({});
  const [firstValidate, setFirstValidate] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false);

  useEffect(() => {
    if (firstValidate) validate();
  }, [state]);

  useEffect(() => {
    setDisabled(Object.keys(errors).length !== 0);
  }, [errors]);

  const isNotSet = (value: T[keyof T] | undefined) => {
    if (value === undefined || value === null) return true;
    if ((typeof value === "string" || Array.isArray(value)) && value.length === 0) return true;
    if (typeof value === "object" && Object.values(value).length === 0) return true;
    return false;
  };

  const validate = useCallback(() => {
    if (!firstValidate) setFirstValidate(true);
    let valid = true;
    if (validations) {
      Object.keys(validations).map((key) => {
        const validation = validations[key as keyof T];
        const value = state[key as keyof T] as T[keyof T] | undefined;
        if (validation) {
          if (
            (typeof validation.required === "boolean" && validation.required && isNotSet(value)) ||
            (typeof validation.required === "function" && !validation.required(value))
          ) {
            valid = false;
            setErrors((v) => ({ ...v, [key]: validation.message }));
          } else setErrors((v) => JSON.parse(JSON.stringify({ ...v, [key]: undefined })));
        }
      });
    }
    return valid;
  }, [validations]);

  const onChange = (newData: Partial<T>) => {
    setState((prevData) => ({ ...prevData, ...newData }));
  };

  return { state, onChange, errors, disabled, validate };
};
