import React, { FunctionComponent, useState } from "react";
import { File as FileModel, FileData } from "../../../models/file";
import { UUID } from "../../../models/common";
import { downloadFile } from "../../../models/misc";
import { PopConfirm, TextInput, Tooltip } from "@getprorecrutement/getpro-design";
import { CheckIcon, DocumentIcon, TrashIcon } from "@heroicons/react/24/outline";

interface Props {
  file: FileModel;
  remove: (id: UUID) => void;
  update: (data: FileData) => Promise<void>;
}

const FileAttachment: FunctionComponent<Props> = (props) => {
  const { file, remove, update } = props;
  const [edit, setEdit] = useState<boolean>(false);
  const [name, setName] = useState<string>(file.name);
  const [showDelete, setShowDelete] = useState<boolean>(false);

  return (
    <div
      key={file.id}
      className="flex items-center gap-2 mb-2 first:mt-4 group px-4 py-2 bg-background-lighter rounded-full"
    >
      <DocumentIcon
        width={16}
        height={16}
        onClick={() => downloadFile(file.id, "getpro-data")}
        className={edit ? "-mr-4" : "cursor-pointer"}
      />
      {!edit ? (
        <div
          onClick={() => setEdit(true)}
          className={showDelete ? "w-[calc(100%-48px)]" : "w-[calc(100%-32px)] group-hover:w-[calc(100%-48px)]"}
        >
          <Tooltip light title={file.name}>
            <div className="truncate w-full">{file.name}</div>
          </Tooltip>
        </div>
      ) : (
        <div className="flex-1">
          <TextInput
            light
            type="text"
            placeholder="Nom du fichier"
            value={name}
            onChange={(e) => setName(e.currentTarget.value)}
            handleKeyDown={(event) =>
              event.key === "Enter" && update({ id: file.id, name: name }).then(() => setEdit(false))
            }
            bordered={false}
            size="small"
            inputClassName="py-1"
          />
        </div>
      )}
      {!edit ? (
        <PopConfirm
          className={showDelete ? "block" : "hidden group-hover:block"}
          title={`Supprimer le fichier ${file.name} ?`}
          onValidate={() => remove(file.id)}
          light
          onChange={setShowDelete}
        >
          <TrashIcon className="w-4 h-4 text-red-400 cursor-pointer" />
        </PopConfirm>
      ) : (
        <div
          onClick={() => {
            update({ id: file.id, name: name }).then(() => setEdit(false));
          }}
          className={`z-10 w-fit flex items-center justify-center cursor-pointer ${edit ? "-ml-4" : ""}`}
        >
          <CheckIcon className="w-4 h-4" />
        </div>
      )}
    </div>
  );
};

export default FileAttachment;
