import React, { FunctionComponent, useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import { ContractResponse, getContracts } from "../../../models/billing/contract";
import { Paginated } from "../../../models/common";
import ContractForm from "../../../components/billing/contracts/contractForm";
import { Customer } from "../../../models/customers";
import { euroDisplay } from "../../../utils/formatters";
import { Button, Table, ColumnProps, Modal, Tag } from "@getprorecrutement/getpro-design";
import dayjs from "dayjs";
import { ArrowLeftIcon, InformationCircleIcon, PlusIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";

const PER_PAGE = 10;

interface Props {
  customer?: Customer;
  onUpdate?: () => void;
}

export const ContractList: FunctionComponent<Props> = (props) => {
  const { customer, onUpdate } = props;
  const [contracts, setContracts] = useState<Paginated<ContractResponse>>();
  const [page, setPage] = useState<number>(1);
  const [create, setCreate] = useState<boolean>(false);
  const navigate = useNavigate();

  const fetchContracts = async (page: number) => {
    setPage(page);
    const c = await getContracts({
      customer_id: customer?.id,
      page,
      per_page: PER_PAGE,
    });
    setContracts(c);
  };

  const onFinish = () => {
    setCreate(false);
    if (customer?.id) {
      fetchContracts(page);
      if (onUpdate) onUpdate();
    }
  };

  useEffect(() => {
    fetchContracts(page);
  }, [page]);

  const columns: ColumnProps<ContractResponse>[] = [
    {
      title: "Nom",
      dataIndex: "name",
      key: "name",
    },
    // {
    //   title: "Description",
    //   dataIndex: "description",
    //   key: "description",
    // },
    {
      title: "Propriétaire",
      dataIndex: "owner_id",
      key: "owner_id",
      render: (_, contract) => (
        <div>
          {contract.owner_first_name} {contract.owner_last_name}
        </div>
      ),
    },
    {
      title: "Projets",
      dataIndex: "projects_count",
      key: "projects_count",
      width: 80,
      render: (val) => <div className="flex justify-center">{val}</div>,
    },
    {
      title: "Factures",
      dataIndex: "bills_count",
      key: "bills_count",
      width: 80,
      render: (val) => <div className="flex justify-center">{val}</div>,
    },
    {
      title: "Total facturé",
      dataIndex: "amount_billed",
      key: "amount_billed",
      width: 120,
      render: (val) => <Tag value={euroDisplay(val)} light bordered />,
    },
    {
      title: "Date de création",
      dataIndex: "created_at",
      key: "created_at",
      width: 140,
      render: (value) => <div>{dayjs(value).format("ll")}</div>,
    },
  ];

  return (
    <div>
      <div className="bg-white p-6 shadow-md mb-6">
        <div className="flex justify-between items-center">
          <div className="flex items-center">
            {customer && (
              <Link to={`/billing/customers/${customer.id}`}>
                <ArrowLeftIcon className="w-5 h-5 mr-4 cursor-pointer" />
              </Link>
            )}
            <div className="text-3xl font-bold">Contrats</div>
          </div>
          <Button icon={<PlusIcon />} onClick={() => setCreate(true)} className="p-0" kind="light" />
        </div>
      </div>
      <div className="px-6">
        {contracts && (
          <Table
            dataSource={contracts}
            columns={columns}
            onClick={(record) => navigate(`/billing/contracts/${record.id}`)}
            light
            bordered
            pagination={{ per_page: PER_PAGE, page, onChange: setPage }}
            extend={{
              render: (data) =>
                data.description ? (
                  <div className="flex items-center gap-2 p-4">
                    <InformationCircleIcon width={20} height={20} />
                    {data.description}
                  </div>
                ) : undefined,
              extendKey: "id",
            }}
          />
        )}
      </div>
      <Modal onClose={() => onFinish()} show={create} className="w-modal-md" light>
        <ContractForm customerId={customer?.id} onFinish={onFinish} />
      </Modal>
    </div>
  );
};

export default ContractList;
