import classNames from "classnames";
import React, { FunctionComponent, useEffect, useState } from "react";
import { UploadIcon, XCircleIcon } from "@heroicons/react/outline";
import { twMerge } from "tailwind-merge";

export interface Props {
  onChange?: (value: FileList | undefined) => void;
  title: string;
  subTitle?: string;
  customRenderer?: JSX.Element;
  accept?: string;
  id: string;
  value?: FileList;
  size?: "small" | "medium" | "large";
  dark?: boolean;
  light?: boolean;
}

export const FileInput: FunctionComponent<Props & Omit<React.HTMLAttributes<HTMLLabelElement>, "onChange">> = ({
  title,
  onChange,
  id,
  value,
  subTitle,
  customRenderer,
  accept,
  size = "medium",
  dark,
  light,
  ...props
}) => {
  const [fileHover, setFileHover] = useState<boolean>(false);

  const dragOverHandler = (ev: React.DragEvent) => {
    ev.preventDefault();
  };

  const onDrop = (ev: React.DragEvent) => {
    ev.preventDefault();
    onChange?.(ev.dataTransfer.files);
    setFileHover(false);
  };

  const onDragEnter = (ev: React.DragEvent) => {
    setFileHover(true);
  };

  const onDragLeave = (ev: React.DragEvent) => {
    setFileHover(false);
  };

  const wrapperClasses = classNames("", {
    dark: dark,
  });

  const classes = twMerge(
    classNames(
      "cursor-pointer block text-content-darker rounded-3xl border rounded-2xl border-border-lighter border-dashed flex justify-center items-center flex-col",
      {
        "text-xs p-4": size === "small",
        "text-base p-6": size === "medium",
        "text-lg p-8": size === "large",
        "dark:border-border-medium dark:text-content-bright": !light,
        "bg-primary-bright": fileHover,
        "dark:bg-primary-darker": fileHover && !light,
        [`${props.className}`]: !!props.className,
      }
    )
  );

  const valueClasses = classNames(
    "px-6 py-2 border border-solid rounded-full flex text-primary-medium border-primary-medium bg-primary-bright justify-between font-normal",
    {
      "dark:bg-primary-darker dark:border-primary-dark dark:text-primary-lighter": !light,
    }
  );

  const iconClasses = classNames("", {
    "w-6 h-6": size === "small",
    "w-8 h-8": size === "medium",
    "w-10 h-10": size === "large",
  });

  return (
    <div className={wrapperClasses}>
      {value ? (
        <div className={valueClasses}>
          <div className="mr-4">{value[0].name}</div>
          <XCircleIcon className="cursor-pointer" width={24} strokeWidth={1} onClick={() => onChange?.(undefined)} />
        </div>
      ) : (
        <label
          {...props}
          className={classes}
          onDragOver={dragOverHandler}
          onDrop={onDrop}
          onDragEnter={onDragEnter}
          onDragLeave={onDragLeave}
          htmlFor={id}
        >
          {customRenderer ? (
            customRenderer
          ) : (
            <>
              <UploadIcon className={iconClasses} strokeWidth={1} />
              <div className="font-bold mt-3">{title}</div>
              {subTitle && <div className="text-content-medium">{subTitle}</div>}
            </>
          )}
          <input
            className="hidden"
            id={id}
            type="file"
            accept={accept}
            onChange={(ev) => onChange?.(ev.target.files || undefined)}
          />
        </label>
      )}
    </div>
  );
};
