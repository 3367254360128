import { useEffect } from "react";
import { JWT, AuthResponse } from "../models/auth";
import JwtDecode from "jwt-decode";
import { MessengerData } from "../models/messenger";
import { UserSettings } from "../models/users/settings";
import { UserResponse } from "../models/users/users";
import { JobOffersFilters } from "../models/jobOffers";
import { UUID } from "../models/common";

import { SearchedCompany, SearchedCandidate, SearchedJobOffer } from "../components/layout/omnisearch";

type Listener<T extends Message | Stored> = (value?: StoreTypes[T]) => void;

const log = (a: unknown, ...s: unknown[]): void => {
  if (process.env.NODE_ENV === "development") console.log(a, s);
};

// Please stringify every element
export enum Message {
  NeedAuth = "NeedAuth",
  Notification = "Notification",
  Error = "Error",
  Messenger = "Messenger",
  Confetti = "Confetti",
  RefreshSettings = "RefreshSettings",
}

export enum Stored {
  JWT = "JWT",
  RawJWT = "RawJWT",
  RefreshToken = "RefreshToken",
  Theme = "Theme",
  User = "User",
  JobOffersFilters = "JobOffersFilters",
  SideMenuClosed = "SideMenuClosed",
  OmniSearchHistory = "OmniSearchHistory",
  Settings = "Settings",
}

export enum DraggedType {
  Question,
  QuestionGroup,
}

export enum Theme {
  Light = "theme",
  Dark = "darkTheme",
}

export enum OmniSearchResultType {
  JobOffer = "job_offer",
  Company = "company",
  Candidate = "candidate",
}

export type OmniSearchHistory = {
  [OmniSearchResultType.Company]?: SearchedCompany[];
  [OmniSearchResultType.Candidate]?: SearchedCandidate[];
  [OmniSearchResultType.JobOffer]?: SearchedJobOffer[];
};

interface StoreTypes {
  readonly [Message.NeedAuth]: string;
  readonly [Message.Notification]: string;
  readonly [Message.Error]: string;
  readonly [Message.Messenger]: MessengerData;
  readonly [Message.Confetti]: boolean;
  readonly [Message.RefreshSettings]: boolean;
  readonly [Stored.Theme]: Theme;
  readonly [Stored.JWT]: JWT;
  readonly [Stored.RawJWT]: string;
  readonly [Stored.RefreshToken]: string;
  readonly [Stored.User]: UserResponse;
  readonly [Stored.JobOffersFilters]: JobOffersFilters;
  readonly [Stored.SideMenuClosed]: boolean;
  readonly [Stored.OmniSearchHistory]: OmniSearchHistory;
  readonly [Stored.Settings]: UserSettings;
}

const DEFAULT_PERSISTENT_KEYS: Stored[] = [
  Stored.Theme,
  Stored.JobOffersFilters,
  Stored.SideMenuClosed,
  Stored.OmniSearchHistory,
  // Stored.Settings,
];

class Store {
  private persistentKeys: Stored[];
  private listeners: {
    [key in Stored | Message]?: Array<Listener<Message | Stored>>;
  };
  public state: { [key in Stored]?: StoreTypes[key] };
  public cache: { [key: string]: { fetched_at: Date; data: unknown } };

  constructor() {
    this.cache = {};
    this.listeners = {};
    this.persistentKeys = DEFAULT_PERSISTENT_KEYS;
    this.state = this.readState() || {}; // Order dependent
  }

  public async cached<T>(key: string, timeoutSeconds: number, operation: () => Promise<T>): Promise<T> {
    const stored = this.cache[key];
    if (stored) {
      const now = new Date();
      const diff = (now.getTime() - stored.fetched_at.getTime()) / 1000;
      if (diff <= timeoutSeconds) return Promise.resolve(stored.data) as Promise<T>;
    }

    return operation().then((data) => {
      this.cache[key] = { data, fetched_at: new Date() };
      return data;
    });
  }

  public invalidateCache(keys: string[]) {
    for (const key of keys) delete this.cache[key];
  }

  public listen<T extends Message | Stored>(type: T, callback: Listener<T>): void {
    useEffect(() => {
      this.getListeners(type).push(callback);
      log("[Store -> listen -> useEffect]", type);

      return function cleanup(): void {
        store.forget(type, callback);
      };
    }, []);
  }

  public forget<T extends Message | Stored>(type: T, callback: Listener<T>): void {
    this.listeners[type] = this.getListeners<Message | Stored>(type).filter((cb) => cb !== callback);
  }

  public update<K extends Stored, V extends StoreTypes[K] | undefined>(key: K, value: V): V {
    log("[Store -> update]", key, value);
    if (value === undefined || value === null) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { [key]: _omitted, ...stored } = this.state;
      this.state = stored;
    } else {
      this.state[key] = value;
    }
    if (this.persistentKeys.includes(key)) this.saveState();
    this.getListeners(key).forEach((listener: Listener<K>) => listener(value));
    return value;
  }

  /// Push value in top position of history
  public pushOmnisearchHistory<
    Type extends keyof OmniSearchHistory,
    V extends NonNullable<OmniSearchHistory[Type]>[number]
  >(value: V, type: Type): void {
    const history: OmniSearchHistory[Type] = this.state.OmniSearchHistory?.[type] || [];
    const ids: UUID[] = [];

    // dedup history
    const res = (history as { id: UUID }[]).filter(({ id }) => {
      if (!ids.includes(id)) {
        ids.push(id);
        return true;
      }
      return false;
    });

    res.push(value);

    const updatedHistory: OmniSearchHistory = {
      ...(this.state.OmniSearchHistory || {}),
      [type]: res.slice(-10),
    } as OmniSearchHistory;

    this.update(Stored.OmniSearchHistory, updatedHistory);
  }

  public notify<M extends Message>(key: M, data?: StoreTypes[M]): void {
    log("[Store -> notify]", key, data);
    this.getListeners(key).forEach((listener: Listener<M>) => listener(data));
  }

  private getListeners<T extends Message | Stored>(type: T): Listener<T>[] {
    this.listeners[type] = this.listeners[type] || [];
    return this.listeners[type] as Listener<T>[];
  }

  public setCredentials(data: AuthResponse): void {
    this.update(Stored.JWT, JwtDecode(data.jwt) as JWT);
    this.update(Stored.RawJWT, data.jwt);
    this.update(Stored.RefreshToken, data.token);
  }

  public setPersistable(key: Stored, persist: boolean): void {
    if (persist) this.persistentKeys.push(key);
    else {
      const index = this.persistentKeys.indexOf(key);
      if (index > -1) {
        this.persistentKeys.splice(index, 1);
      }
    }
  }

  private readState(): StoreTypes | null {
    try {
      const state = JSON.parse(localStorage.getItem("state") || "{}") || {};
      if (state[Stored.RefreshToken]) this.setPersistable(Stored.RefreshToken, true); // If user already wanted to stay logged in, do it again
      return state;
    } catch (err) {
      console.warn("Cannot read the localStorage state");
      console.error(err);
      return null;
    }
  }

  private saveState(): void {
    try {
      const filtered = (Object.keys(this.state) as Stored[])
        .filter((key) => this.persistentKeys.includes(key))
        .reduce((obj: { [key in Stored]?: StoreTypes[key] }, key) => {
          return { ...obj, [key]: this.state[key] };
        }, {});
      localStorage.setItem("state", JSON.stringify(filtered));
    } catch (err) {
      console.warn("Cannot read the localStorage state");
    }
  }
}

const store = new Store();

export default store;
