import React, { FunctionComponent } from "react";
import { XMarkIcon } from "@heroicons/react/24/solid";

const colors = [
  "#C3EE4E",
  "#92E877",
  "#46DA98",
  "#06635A",
  "#129FAC",
  "#66D5EC",
  "#43A8FF",
  "#619CFF",
  "#3052E9",
  "#4A45DD",
  "#90ABD8",
  "#C6D5EE",
  "#94A2FD",
  "#7B7AFD",
  "#9076FA",
  "#7E37BD",
  "#B479FF",
  "#D76EFF",
  "#F06C9A",
  "#FF64CE",
  "#FF646B",
  "#FC7753",
  "#FAA35B",
  "#F6C946",
  "#EBED51",
];

interface Props {
  toggle: () => void;
  onChange: (color: string) => void;
  onHover?: (color?: string) => void;
  keepOpen?: boolean;
}

export const ColorPalette: FunctionComponent<Props> = (props) => {
  const submit = (color: string) => {
    props.onChange(color);
    !props.keepOpen && props.toggle();
  };

  return (
    <div className="shadow-lg absolute z-[100] bottom-0 left-0 translate-y-full p-6 bg-white w-[200px] rounded-sm">
      <XMarkIcon className="absolute top-2 right-2 w-4 h-4 cursor-pointer" onClick={props.toggle} />
      <div className="text-xs text-center mb-2">Sélectionner une couleur</div>
      <div className="flex flex-wrap gap-1 justify-center items-center">
        {colors.map((color) => (
          <div key={color} className="w-6 h-6 flex justify-center items-center">
            <div
              className="h-5 w-5 rounded-full cursor-pointer hover:w-6 hover:h-6 transition-all"
              style={{ backgroundColor: color }}
              onClick={() => submit(color)}
              onMouseEnter={() => props.onHover && props.onHover(color)}
              onMouseLeave={() => props.onHover && props.onHover(undefined)}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ColorPalette;
