/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosResponse } from "axios";
import ROUTES, { AxiosResponseType, Method, Route, URLS } from "./routes";
import { AuthResponse } from "../../models/auth";
import store, { Message, Stored } from "../store";
import { toast } from "react-hot-toast";
import * as Sentry from "@sentry/react";

interface Request {
  resolve: any;
  reject: any;
  route: Route;
}

type ErrorHandler = (err: string | undefined) => void;

class HttpClient {
  public pendingRequests: Request[];
  public isRefreshing?: boolean;

  constructor() {
    this.pendingRequests = [];
  }

  public req<T>(route: Route, errorHandler?: ErrorHandler): Promise<T> {
    const { params, method, auth, file, responseType = AxiosResponseType.Json } = route;
    let { data } = route;
    const headers = route.headers || {};

    const state = store.state;
    const [jwt, rawJwt, refreshToken] = [state[Stored.JWT], state[Stored.RawJWT], state[Stored.RefreshToken]];

    if (auth) {
      if (this.isRefreshing) {
        return this.setPendingRequest(route);
      }
      if (!refreshToken) {
        store.notify(Message.NeedAuth);
        return Promise.reject("Please log in to continue"); // TODO I18n
      }
      if (!jwt || jwt.exp <= new Date().getTime() / 1000 + 60) {
        const pendingRequest = this.setPendingRequest(route);

        this.isRefreshing = true;
        this.refreshJwt();
        return pendingRequest;
      }
      headers.Authorization = `Bearer ${rawJwt}`;
    }
    if (file) {
      data = new FormData();

      data.append("file", file);
    }

    Sentry.configureScope((scope) => {
      const path = route.path.replace(/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/, ":id");
      if (auth) {
        scope.setUser({ email: store.state.JWT?.email });
      }

      scope.setTransactionName(`${method.toUpperCase()} ${path}`);
    });

    const opts = {
      responseType,
      // paramsSerializer: function (params: any) {
      //   return qs.stringify(params, { allowDots: true });
      // },
    };
    return [Method.Get, Method.Delete].includes(method)
      ? (axios[method] as any)(this.genUrl(route), { params, headers, ...opts })
          .then((e: any) => e.data)
          .catch((err: { response?: AxiosResponse }) => this.handleError(err, errorHandler))
      : (axios[method] as any)(this.genUrl(route), data, {
          params,
          headers,
          ...opts,
        })
          .then((e: any) => e.data)
          .catch((err: { response?: AxiosResponse }) => this.handleError(err, errorHandler));
  }

  private handleError(error: { response?: AxiosResponse }, handler?: ErrorHandler): void {
    const err = (error.response && error.response.data && error.response.data.error) || undefined;

    if (handler) {
      handler(err);
    } else {
      toast.error(err || "Erreur de communication avec le serveur");
    }
    if (error.response?.status === 401) {
      store.update(Stored.RefreshToken, undefined);
    }
    throw error;
  }

  private refreshJwt(): void {
    const token = store.state[Stored.RefreshToken];
    if (!token) {
      return store.notify(Message.NeedAuth);
    }

    this.req<AuthResponse>(ROUTES.REFRESH_JWT(token))
      .then((res) => {
        store.setCredentials(res);
        this.isRefreshing = false;
        this.pendingRequests.forEach((r) => {
          this.req(r.route).then(r.resolve).catch(r.reject);
        });
      })
      .catch(() => {
        this.pendingRequests.forEach((r) => {
          r.reject();
        });
        this.pendingRequests = [];
        store.notify(Message.NeedAuth);
      });
  }

  public genUrl(route: Route, webSocket?: boolean): string {
    const urlReg = /^(?:https?:\/\/)?([0-9a-z-.:]+)\/?/i;
    const protocol = webSocket ? "ws" : "http";
    const baseUrl = URLS[route.api]?.replace(
      urlReg,
      protocol + (process.env.NODE_ENV === "development" ? "://$1/" : "s://$1/")
    );
    return baseUrl + route.path;
  }

  public genUrlWithParams(route: Route): string {
    let url = this.genUrl(route);
    if (route.params) {
      const params = new URLSearchParams(route.params);
      url = `${url}?${params}`;
    }
    return url;
  }

  private setPendingRequest(route: Route): Promise<any> {
    const promise = new Promise((resolve: any, reject: any) => {
      this.pendingRequests.push({ resolve, reject, route });
    });

    return promise;
  }
}

const httpClient = new HttpClient();

export default httpClient;
