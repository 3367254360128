import React, { FunctionComponent, useEffect } from "react";
import { CheckIcon } from "@heroicons/react/outline";
import classNames from "classnames";
import { twMerge } from "tailwind-merge";
import { ColorType } from "../../../../utils/common";

export interface Props {
  value: boolean;
  onChange: (value: boolean) => void;
  colorType?: ColorType;
  label?: JSX.Element | string;
  dark?: boolean;
  light?: boolean;
}

export const Checkbox: FunctionComponent<Props & Omit<React.HTMLAttributes<HTMLDivElement>, "onChange">> = ({
  value,
  onChange,
  label,
  dark,
  light,
  colorType = ColorType.Primary,
  ...props
}) => {
  const wrapperClasses = classNames("", {
    dark: dark,
  });

  const classes = twMerge(
    classNames("flex items-center w-fit gap-2 relative group/checkbox", {
      [`${props.className}`]: props.className,
    })
  );

  const labelClasses = classNames("w-fit cursor-pointer", {
    "dark:text-content-bright": !light && value,
    "dark:text-content-medium dark:group-hover/checkbox:text-content-bright": !light && !value,
    "text-content-darker": value,
    "text-content-light group-hover/checkbox:text-content-darker": !value,
  });

  const checkboxClasses = classNames(
    "flex items-center justify-center border-2 border-solid left-0 h-4 w-4 rounded cursor-pointer",
    {
      "border-border-lighter group-hover/checkbox:border-border-darker": !value,
      "bg-primary-medium border-primary-medium": value && colorType === ColorType.Primary,
      "bg-secondary-medium border-secondary-medium": value && colorType === ColorType.Secondary,
      "bg-content-medium border-content-medium": value && colorType === ColorType.Content,
      "dark:border-border-medium dark:group-hover/checkbox:border-border-bright": !light && !value,
      "dark:bg-primary-medium dark:border-primary-medium": !light && value && colorType === ColorType.Primary,
      "dark:bg-secondary-medium dark:border-secondary-medium": !light && value && colorType === ColorType.Secondary,
      "dark:bg-content-medium dark:border-content-medium": !light && value && colorType === ColorType.Content,
    }
  );

  const checkIconClasses = classNames("text-white", {
    "dark:text-content-darker": !light && value,
  });

  return (
    <div className={wrapperClasses}>
      <div {...props} className={classes} onClick={() => onChange(!value)}>
        <div className={checkboxClasses}>{value && <CheckIcon className={checkIconClasses} />}</div>
        {label && <div className={labelClasses}>{label}</div>}
      </div>
    </div>
  );
};
