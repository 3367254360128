import React, { useEffect, useState } from "react";
import { FunctionComponent } from "react";
import dayjs from "dayjs";
import { Bar, BarChart, ResponsiveContainer, XAxis, YAxis, Tooltip, TooltipProps, LabelList } from "recharts";
import { BookingTimelinePerBu, BookingTimelinePerType, getCustomTooltipDate } from ".";
import { PALETTE } from "../..";
import { renderCustomizedLabel } from "./bookingTimelinePerType";
import { BusinessUnit, getBusinessUnits } from "../../../../models/businessUnits";
import { GlobalFilters } from "../../../../models/dashboards";
import { euroDisplay } from "../../../../utils/formatters";
import { useDeepCompareEffect } from "react-use";
import { Radio, Spinner } from "@getprorecrutement/getpro-design";

interface Props {
  bookings?: BookingTimelinePerBu[];
  setGranularity: (value: "month" | "quarter" | "year") => void;
  granularity: "month" | "quarter" | "year";
  filters: GlobalFilters;
}

export const BookingsTimelinePerBu: FunctionComponent<Props> = (props) => {
  const { bookings, setGranularity, granularity, filters } = props;
  const [bus, setBus] = useState<BusinessUnit[]>([]);
  const [busAvailable, setBusAvailable] = useState<string[]>([]);

  const availableBus = () => {
    const save: string[] = [];

    bookings?.map((line) => {
      bus.map((bu) => {
        if (line[bu.name] !== undefined && !save.includes(bu.name)) {
          save.push(bu.name);
        }
      });
    });

    return save;
  };

  const fetchBus = async () => {
    const res = await getBusinessUnits();
    setBus(res);
  };

  useDeepCompareEffect(() => {
    fetchBus();
  }, [filters]);

  useEffect(() => {
    if (bookings) {
      const res = availableBus();
      setBusAvailable(res);
    }
  }, [bookings]);

  const tl = bookings || [];
  const barSize = tl.length <= 2 ? 100 : undefined;
  const padding = tl.length > 0 && tl.length < 3 ? tl.length * 100 : undefined;
  const paddingProp = padding ? { left: padding, right: padding } : "gap";

  const CustomTooltip = ({ active, payload, label }: TooltipProps<number, string>) => {
    if (active && payload && payload.length) {
      return (
        <div className="DashboardCustomTooltip">
          {getCustomTooltipDate(label, granularity)}
          {bus
            .filter((bu) => busAvailable.includes(bu.name))
            .map((s, i) => {
              const save = payload.find((p) => p.name === s.name);
              if (save) {
                return (
                  <div
                    key={save.dataKey}
                    className="DashboardCustomTooltipData"
                    style={{
                      backgroundColor: PALETTE.BLUE[0],
                      filter: `hue-rotate(${(i / busAvailable.length) * 90}deg)`,
                    }}
                  >
                    <span className="DashboardCustomTooltipLabel">{save.name}</span>
                    <span className="DashboardCustomTooltipValue">{`${euroDisplay(save.value)}`}</span>
                  </div>
                );
              }
            })}
        </div>
      );
    }

    return null;
  };

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: 284,
      }}
    >
      {bookings ? (
        <div style={{ width: "100%" }}>
          <div className="mb-6 flex justify-between items-center">
            <Radio
              light
              optionType="button"
              options={[
                { label: "Mois", value: "month" },
                { label: "Trimestre", value: "quarter" },
                { label: "Année", value: "year" },
              ]}
              value={granularity}
              onChange={(v) => setGranularity(v as "month" | "quarter" | "year")}
            />
          </div>
          <ResponsiveContainer width="100%" height={200}>
            <BarChart
              margin={{ left: 0, right: 0 }}
              data={bookings.map((t: BookingTimelinePerType) => ({
                ...t,
                created_at: dayjs(t.created_at).valueOf(),
              }))}
            >
              <XAxis
                dataKey="created_at"
                scale="time"
                type="number"
                axisLine={false}
                tickLine={false}
                padding={paddingProp}
                domain={["auto", "auto"]}
                tickFormatter={(tick) => dayjs(tick).format("DD/MM/YY")}
              />
              <YAxis padding={{ top: 20, bottom: 0 }} axisLine={false} tickLine={false} />
              <Tooltip cursor={false} content={CustomTooltip} />
              {bus
                .filter((bu) => busAvailable.includes(bu.name))
                .sort()
                .map((s, i) => {
                  return (
                    <Bar
                      barSize={barSize}
                      key={s.name}
                      dataKey={s.name}
                      stackId={busAvailable.length > 5 ? "a" : i}
                      fill={PALETTE.BLUE[0]}
                      style={{ filter: `hue-rotate(${(i / busAvailable.length) * 90}deg)` }}
                    >
                      {((busAvailable.length > 5 && busAvailable.length - 1 === i) || busAvailable.length <= 5) && (
                        <LabelList
                          position="top"
                          dataKey={busAvailable.length > 5 ? "total" : s.name}
                          content={renderCustomizedLabel}
                        />
                      )}
                    </Bar>
                  );
                })}
            </BarChart>
          </ResponsiveContainer>
        </div>
      ) : (
        <Spinner light />
      )}
    </div>
  );
};

export default BookingsTimelinePerBu;
