import React, { FunctionComponent } from "react";
import { JobOffer, SummaryStatus, FullJobOffer, OpportunityTypes } from "../../models/jobOffers";
import { useState } from "react";
import { useNavigate } from "react-router";
import JobOfferActions, { JobOfferAction } from "../jobOfferActions";
import dayjs from "dayjs";
import { ClockIcon, ExclamationTriangleIcon, EyeSlashIcon, FolderIcon } from "@heroicons/react/24/outline";
import { Tag, Tooltip } from "@getprorecrutement/getpro-design";
import qs from "qs";

const statusesCategories = [
  {
    name: "Nouveaux",
    statusIds: [
      "97df2814-b7b7-41ce-82a4-a6583ad64303", // Entrante
      "94850f81-c820-47ab-b0d7-404912f1587c", // Sélectionné
      "67ba838b-3fc2-4f56-a1d7-7e82bda13816", // Contacté
    ],
    colorClass: "statusesNew",
  },
  {
    name: "En cours",
    statusIds: [
      "c596e2bc-d302-4b5e-bc25-ff6afe66ec4b", // Postulé
      "188508f1-9000-49fe-80a2-93162a002d52", // Présenté au client
      "5eaf0c5a-1cbf-44ae-9f7e-35bc31741a14", // Reçu par le client
    ],
    colorClass: "statusesInProcess",
  },
  {
    name: "Closing",
    statusIds: [
      "967e60fd-7063-49cb-a50a-9058883354b8", // Closing
      "ce7d87f3-f446-499d-bc49-12112e78f131", // Embauché
      "cedcfdaf-1ee2-458e-b89c-8e19939af2d8", // Validé
    ],
    colorClass: "statusesClosing",
  },
];

interface Props {
  jobOffer: FullJobOffer;
  onUpdate?: () => void;
  onEdit?: (jo: JobOffer) => void;
}

export const JobOfferRow: FunctionComponent<Props> = (props) => {
  const { jobOffer } = props;
  const statuses = jobOffer.summary.statuses.sort((a, b) => a.index - b.index);
  const [selectedCat, setSelectedCat] = useState<number>(0);
  const navigate = useNavigate();

  const daysSinceCreated = (jobOffer.archived_at ? dayjs(jobOffer.archived_at).set("hour", 0) : dayjs()).diff(
    dayjs(jobOffer.created_at).set("hour", 0),
    "day"
  );
  const opportunityType = (
    OpportunityTypes.find((ot) => ot.value === jobOffer?.opportunity_type) || {
      label: "Indéfini",
    }
  ).label;

  return (
    <div
      className="bg-background-bright p-4 rounded-3xl flex gap-4 cursor-pointer"
      onClick={() => navigate(`/jobs/${jobOffer.id}`)}
    >
      <div className="min-w-[64px] min-h-[64px] w-16 h-16 flex items-center justify-center bg-white rounded-full">
        <img
          className="max-w-full max-h-full rounded-full"
          src={`https://storage.googleapis.com/getpro-public/${
            jobOffer.company.file_id || "e1ca4dfa-7173-40a1-8f9a-917dc3f3556f"
          }`}
        />
      </div>
      <div className="flex-1 flex justify-between max-w-[calc(100%-128px)]">
        <div className="text-content-darker flex-1 max-w-[calc(100%-400px)]">
          <div className="flex items-center gap-2">
            <div className="truncate">{jobOffer.company.name}</div>
            {jobOffer.anonymous_company && <span className="text-content-regular text-sm">Entreprise anonyme</span>}
          </div>
          <div className="flex items-center gap-2">
            <div className="text-lg font-bold truncate">{jobOffer.name}</div>
            <Tag bold light className="bg-teal-200 border-0" size="small" value={opportunityType} />
            {jobOffer.published && <Tag bold light className="bg-pink-300 border-0" size="small" value="Publiée" />}
          </div>
          <div className="flex items-center gap-x-3 gap-y-1 mt-4 flex-wrap">
            <b>
              {jobOffer.owner.first_name} {jobOffer.owner.last_name[0]}.
            </b>
            <div>le {dayjs(jobOffer.created_at).format("ll")}</div>
            <Tag
              className={`border-0 ${
                jobOffer.archived_at
                  ? "bg-purple-100 text-purple-400"
                  : daysSinceCreated < 60
                  ? "bg-emerald-100 text-emerald-400"
                  : daysSinceCreated < 120
                  ? "bg-orange-100 text-orange-400"
                  : "bg-red-100 text-red-400"
              }`}
              light
              value={
                <div className="flex items-center gap-2">
                  <div>
                    {jobOffer.archived_at ? (
                      <FolderIcon className="w-4 h-4" />
                    ) : daysSinceCreated < 120 ? (
                      <ClockIcon className="w-4 h-4" />
                    ) : (
                      <ExclamationTriangleIcon className="w-4 h-4" />
                    )}
                  </div>
                  <div>
                    {jobOffer.archived_at ? "Pendant" : "Il y a"} {daysSinceCreated} jours
                  </div>
                </div>
              }
            />
          </div>
        </div>
        <div className="max-w-[400px] w-full py-1.5" onClick={(e) => e.stopPropagation()}>
          <div className="flex items-center justify-center gap-4">
            {statusesCategories.map((sc, i) => {
              const stList = sc.statusIds
                .map((sId) => statuses.find((s) => s.id === sId))
                .filter((s) => s) as SummaryStatus[];
              const nbStatuses = stList.reduce((nb, s) => nb + s.active_count, 0);

              return (
                <div
                  key={`sc-${i}`}
                  onClick={() => setSelectedCat(i)}
                  className={`flex items-center gap-1 group ${
                    selectedCat === i ? "text-content-darker" : "text-content-light hover:text-content-darker"
                  }`}
                >
                  <div
                    className={`font-bold px-2 text-[10px] rounded ${
                      selectedCat === i ? "bg-content-lightest" : "group-hover:bg-content-lightest"
                    }`}
                  >
                    {nbStatuses}
                  </div>
                  <div className="text-xs">{sc.name}</div>
                </div>
              );
            })}
          </div>
          <div className="flex items-center justify-center gap-4 mt-2">
            {statusesCategories[selectedCat].statusIds.map((s) => {
              const status = statuses.find((e) => e.id === s);

              return (
                <div
                  key={s}
                  className="bg-background-lightest px-4 py-2 rounded-2xl min-w-[100px]"
                  onClick={() =>
                    navigate({
                      pathname: `/jobs/${jobOffer.id}`,
                      search: qs.stringify({ statusIds: [status?.id] }),
                    })
                  }
                >
                  <div className="font-bold text-center">{status?.active_count}</div>
                  <div className="flex gap-3 items-center justify-center">
                    <div
                      className="min-w-[8px] min-h-[8px] rounded-full"
                      style={{ backgroundColor: `#${status?.color}` }}
                    />
                    <div className="text-xs">{status?.name.split(" ")[0]}</div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div onClick={(e) => e.stopPropagation()}>
        <JobOfferActions
          ignoredActions={[JobOfferAction.Edit, JobOfferAction.GoToProject, JobOfferAction.NewCandidate]}
          onEdit={props.onEdit ? () => props.onEdit && props.onEdit(jobOffer) : undefined}
          onUpdate={props.onUpdate}
          jobOffer={jobOffer}
        />
      </div>
    </div>
  );
};

export default JobOfferRow;
