import React, { FunctionComponent, useState } from "react";
import {
  BoolValidation,
  MinimumValidation,
  Question,
  QuestionType,
  RangeValidation,
  ValidationType,
} from "../../../models/jobOffers";
import { InputNumber, Select, TextInput } from "@getprorecrutement/getpro-design";
import { XMarkIcon } from "@heroicons/react/24/outline";

interface QuestionProps {
  index: number;
  question: Question;
  updateQuestion: (question: Question) => void;
  removeQuestion: () => void;
}

const getQuestionValue = (question: Question): string | undefined => {
  if (question.question.length > 0) {
    const remove = getQuestionPreview(question.kind);
    let value = question.question.replace(remove, "");
    value = value.replaceAll("[:value:]", "");
    value = value.replace("?", "");
    value = value.trim();
    return value;
  }
  return undefined;
};

const getQuestionPreview = (kind: QuestionType): string => {
  if (kind === QuestionType.Lang) return "Quel est votre niveau ";
  else if (kind === QuestionType.Experience) return "Combien d'années d'expérience avez-vous en tant que ";
  else if (kind === QuestionType.Salary) return "Quel est votre salaire actuel en brut annuel";
  else if (kind === QuestionType.Skill) return "Quel est votre niveau de compétence ";
  else if (kind === QuestionType.Size) return "Quelle taille d'équipe avez-vous été amené à manager ";
  else return "";
};

export const QuestionRow: FunctionComponent<QuestionProps> = (props) => {
  const { question, updateQuestion, removeQuestion } = props;
  const [value, _setValue] = useState<string>(getQuestionValue(question) || "");
  const [validation, setValidation] = useState<BoolValidation | MinimumValidation | RangeValidation>(
    question.validation
  );

  const setMin = (min: number) => {
    const validation =
      question.validation.type === ValidationType.Range
        ? { ...question.validation, min }
        : { ...question.validation, value: min };
    setValidation(validation);
    updateQuestion({ ...question, validation });
  };

  const setMax = (max: number) => {
    const validation = { ...question.validation, max };
    setValidation(validation);
    updateQuestion({ ...question, validation });
  };

  const setValue = (value: string) => {
    _setValue(value);
    updateQuestion({
      ...question,
      question:
        question.kind !== QuestionType.Bool
          ? `${getQuestionPreview(question.kind)}[:value:]${value}[:value:] ?`
          : `${value} ?`,
    });
  };

  const getTitle = (kind: QuestionType): string => {
    if (kind === QuestionType.Experience) return "Expérience";
    else if (kind === QuestionType.Lang) return "Langue";
    else if (kind === QuestionType.Salary) return "Salaire";
    else if (kind === QuestionType.Skill) return "Compétence";
    else if (kind === QuestionType.Size) return "Management";
    else return "";
  };

  const getInputs = (kind: QuestionType): JSX.Element => {
    const options: string[] =
      kind === QuestionType.Lang
        ? ["Notions", "Intermédiaire", "Courant", "Bilingue"]
        : kind === QuestionType.Skill
        ? ["Notions", "Intermédiaire", "Maîtrise", "Expert"]
        : kind === QuestionType.Size
        ? ["1-3", "3-5", "5-10", "10 et plus"]
        : [];

    if (kind === QuestionType.Lang || kind === QuestionType.Skill || kind === QuestionType.Size) {
      return (
        <div className="flex justify-between mt-6 gap-6 bg-inherit">
          <TextInput
            className="w-[200px]"
            value={value}
            onChange={(e) => setValue(e.target.value)}
            type="text"
            light
            placeholder=""
            label={
              kind === QuestionType.Lang ? "Langue :" : kind === QuestionType.Skill ? "Compétence :" : "Management :"
            }
          />
          <Select
            label={kind === QuestionType.Lang || kind === QuestionType.Skill ? "Niveau minimum :" : "Taille :"}
            className="w-[200px]"
            light
            bordered
            rounded
            value={(options || [])
              .map((v, i) => ({ label: v, value: i + 1 }))
              .find(
                (e) =>
                  e.value ===
                  ((validation as MinimumValidation).value !== undefined ? (validation as MinimumValidation).value : 1)
              )}
            getKey={(item) => item.value.toString()}
            optionRender={(item) => item.label}
            options={(options || []).map((v, i) => ({ label: v, value: i + 1 }))}
            onChange={(item) => item && setMin(item.value)}
            type="single"
          />
        </div>
      );
    } else if (kind === QuestionType.Experience || kind === QuestionType.Salary) {
      return (
        <div className="flex justify-between gap-6 mt-6 bg-inherit">
          {kind === QuestionType.Experience && (
            <TextInput
              className="w-[200px]"
              value={value}
              onChange={(e) => setValue(e.target.value)}
              type="text"
              light
              placeholder=""
              label={"Rôle :"}
            />
          )}
          {kind === QuestionType.Experience ? (
            <InputNumber
              light
              label="Exp minimum :"
              className="w-[200px]"
              placeholder="5 ans"
              min={0}
              value={(validation as MinimumValidation).value || 0}
              onChange={(e) => typeof e != "undefined" && setMin(e)}
              suffix="ans"
            />
          ) : (
            <InputNumber
              light
              label="Salaire max :"
              className="w-[200px]"
              placeholder="50 000 €"
              value={(validation as RangeValidation).max || 0}
              onChange={(e) => typeof e != "undefined" && setMax(e)}
              suffix="€"
              display={{
                formatter: (value) => value && value.replace(/\B(?=(\d{3})+(?!\d))/g, " "),
                parser: (value) => (value ? value.replace(/[\s€]*/g, "") : ""),
              }}
              precision={2}
              min={0}
              controls={false}
            />
          )}
        </div>
      );
    } else
      return (
        <TextInput
          type="text"
          light
          placeholder="Question libre"
          value={value}
          onChange={(e) => setValue(e.currentTarget.value)}
        />
      );
  };

  return (
    <div
      className="bg-inherit"
      style={{ padding: 24, border: "medium dashed #F4F4F4", borderRadius: 24, marginBottom: 12 }}
    >
      {/* Informations & actions */}
      <div className="relative">
        <span className="-ml-10 w-8 h-8 rounded-full absolute text-white bg-black flex items-center justify-center font-bold">
          {props.index + 1}
        </span>
        <div className="text-xl font-bold">{getTitle(question.kind)}</div>
        <span
          className="w-6 h-6 rounded-full absolute -right-8 -top-5 text-white bg-slate-300 flex items-center justify-center font-bold cursor-pointer"
          onClick={removeQuestion}
        >
          <XMarkIcon className="w-4 h-4" />
        </span>
      </div>
      {/* Inputs */}
      {getInputs(props.question.kind)}
      {/* Preview of question */}
      <div className="bg-[#F4F4F4] p-3 rounded-xl mt-6">
        <div className="text-[#b1b1b1] font-bold">Rendu sur le formulaire</div>

        <div>
          {getQuestionPreview(question.kind)}
          <span
            className={
              question.kind !== QuestionType.Bool ? "font-bold text-[#33B172] underline decoration-[#33B172]" : ""
            }
          >
            {value}
          </span>{" "}
          ?
        </div>
      </div>
    </div>
  );
};

export default QuestionRow;
