import React, { useState } from "react";
import { FunctionComponent } from "react";
import { GlobalFilters, DashboardModule, getStatsBooking } from "../../../../models/dashboards";
import BookingsTimeline from "./timeline";
import ModuleTitle from "../../elements/title";
import BookingPerUser from "./bookingPerUser";
import BookingsTimelinePerType from "./bookingTimelinePerType";
import BookingsTimelinePerBu from "./bookingTimelinePerBu";
import { useDeepCompareEffect } from "react-use";
import dayjs from "dayjs";
import DashboardBookingsList from "./bookings";
import { UUID } from "../../../../models/common";
import { Radio, Checkbox, Tooltip, Divider } from "@getprorecrutement/getpro-design";
import { ArrowLongRightIcon, QuestionMarkCircleIcon } from "@heroicons/react/24/outline";

export interface BookingData {
  timeline?: BookingTimelineData[];
  per_user?: BookingPerUserData[];
  timeline_per_bu?: BookingTimelinePerBu[];
  timeline_per_type?: BookingTimelinePerType[];
  bookings: FullBookingData[];
}

export interface BookingResponse {
  timeline?: BookingTimelineData[];
  per_user?: BookingPerUserData[];
  timeline_per_bu?: BookingTimelinePerBuData[];
  timeline_per_type?: BookingTimelinePerTypeData[];
  bookings: FullBookingData[];
}

export interface FullBookingData {
  id: UUID;
  amount: number;
  owner_name: string;
  job_offer_id?: UUID;
  job_offer_name?: string;
  customer_id: UUID;
  customer_name: string;
  created_at: string;
}

export interface BookingTimelineData {
  created_at: string;
  amount?: number;
}

export interface BookingPerUserData {
  name: string;
  amount?: number;
}

export interface BookingTimelinePerBuData {
  created_at: string;
  amount?: number;
  name?: string;
}

export type BookingTimelinePerBu = { [bu: string]: number } & {
  created_at: string;
  total: number;
};

export interface BookingTimelinePerTypeData {
  created_at: string;
  amount?: number;
  opportunity_type?: string;
}

export type BookingTimelinePerType = { [opportunityType: string]: number } & {
  created_at: string;
  total: number;
};

type ModuleFilters = {
  granularity?: "month" | "quarter" | "year";
  compare?: boolean;
  timeline?: "timeline" | "users" | "types" | "bus";
  bookings?: boolean;
};

interface Props {
  filters: GlobalFilters;
  locked: boolean;
  dashboardSlug: string;
  mod: DashboardModule<ModuleFilters>;
  updateModule: (data: {
    dashboard_slug: string;
    module_key: string;
    filters: ModuleFilters;
    title?: string;
  }) => Promise<void>;
}

export const getCustomTooltipDate = (date: string, granularity: "month" | "quarter" | "year") => {
  return (
    <div className="DashboardCustomTooltipDate">
      {granularity === "month" ? (
        dayjs(date).format("MMMM YYYY")
      ) : granularity === "quarter" ? (
        <div className="flex items-center justify-between gap-2">
          <div>{dayjs(date).format("MMM YY")}</div>
          <ArrowLongRightIcon className="w-4 h-4" />
          <div>{dayjs(date).endOf("quarter").format("MMM YY")}</div>
        </div>
      ) : (
        dayjs(date).format("YYYY")
      )}
    </div>
  );
};

export const BookingChart: FunctionComponent<Props> = (props) => {
  const { filters, locked, dashboardSlug, mod, updateModule } = props;
  const [bookingData, setBookingData] = useState<BookingData>();
  const [chartTypeSelected, _setTimelineSelected] = useState<"timeline" | "users" | "types" | "bus">(
    mod.filters.timeline || "timeline"
  );

  const setShowBookings = async (show: boolean) => {
    await updateModule({
      dashboard_slug: dashboardSlug,
      module_key: mod.key,
      filters: {
        bookings: show,
      },
    });
  };

  const setGranularity = async (value: "month" | "quarter" | "year") => {
    await updateModule({
      dashboard_slug: dashboardSlug,
      module_key: mod.key,
      filters: {
        granularity: value,
      },
    });
  };

  const setTimelineSelect = async (value: "timeline" | "users" | "types" | "bus") => {
    await updateModule({
      dashboard_slug: dashboardSlug,
      module_key: mod.key,
      filters: {
        timeline: value,
      },
    });
  };

  const setTimelineSelected = (value: "timeline" | "users" | "types" | "bus") => {
    _setTimelineSelected(value);
    setTimelineSelect(value);
  };

  const getDataTypes = (timeline: BookingTimelinePerTypeData[]): BookingTimelinePerType[] => {
    const res = timeline.reduce(
      (
        res: {
          [date: string]: { [opportunityType: string]: number; total: number };
        },
        data
      ) => {
        res[data.created_at] ||= { total: 0 };
        if (data.opportunity_type && data.amount) {
          res[data.created_at][data.opportunity_type] = data.amount;
          res[data.created_at].total += data.amount;
        }
        return res;
      },
      {}
    );

    return Object.keys(res).map((d) => ({ created_at: d, ...res[d] } as BookingTimelinePerType));
  };

  const getDataBus = (timeline: BookingTimelinePerBuData[]): BookingTimelinePerBu[] => {
    const res = timeline.reduce(
      (
        res: {
          [date: string]: { [buName: string]: number; total: number };
        },
        data
      ) => {
        res[data.created_at] ||= { total: 0 };
        if (data.name && data.amount) {
          res[data.created_at][data.name] = data.amount;
          res[data.created_at].total += data.amount;
        }
        return res;
      },
      {}
    );

    return Object.keys(res).map((d) => ({ created_at: d, ...res[d] } as BookingTimelinePerBu));
  };

  const fetchBookingData = async () => {
    const response = await getStatsBooking({
      ...filters.dateRange,
      granularity: mod.filters.granularity,
      users: filters.users,
      business_units: filters.businessUnits,
      job_offers: filters.jobOffers,
      customers: filters.customers,
      chart_type: chartTypeSelected,
      bookings: mod.filters.bookings,
    });

    setBookingData({
      ...response,
      timeline_per_type: response.timeline_per_type && getDataTypes(response.timeline_per_type),
      timeline_per_bu: response.timeline_per_bu && getDataBus(response.timeline_per_bu),
    });
  };

  useDeepCompareEffect(() => {
    fetchBookingData();
  }, [filters, mod.filters]);

  return (
    <div className="bg-white p-6 shadow-md mb-6">
      {!locked && (
        <div>
          <div className="flex items-center gap-2">
            <Radio
              light
              optionType="button"
              options={[
                { label: "Timeline", value: "timeline" },
                { label: "Par utilisateurs", value: "users" },
                { label: "Par types d'opportunités", value: "types" },
                { label: "Par départements", value: "bus" },
              ]}
              value={chartTypeSelected}
              onChange={(e) => setTimelineSelected(e as "timeline" | "users" | "types" | "bus")}
            />
            {(chartTypeSelected === "bus" || chartTypeSelected === "types") && (
              <Tooltip
                title={
                  chartTypeSelected === "bus"
                    ? "La visualisation par département ne dépend pas des filtres globaux utilisateurs et offres"
                    : "La visualisation par type d'opportunité ne prend en compte que les bookings des projets liée à une offre"
                }
                light
              >
                <QuestionMarkCircleIcon className="w-5 h-5" style={{ color: "#C4C4C4" }} />
              </Tooltip>
            )}
          </div>
          <div className="mt-2">
            <Checkbox
              label="Afficher les bookings"
              light
              value={mod.filters.bookings || false}
              onChange={(val) => setShowBookings(val)}
            />
          </div>
          <Divider light />
        </div>
      )}
      <ModuleTitle
        title={mod.title}
        slug={dashboardSlug}
        modKey={mod.key}
        updateModule={updateModule}
        locked={locked}
      />
      <div className="w-full">
        {mod.filters.bookings ? (
          <DashboardBookingsList bookings={bookingData?.bookings || []} />
        ) : chartTypeSelected === "timeline" ? (
          <BookingsTimeline
            timeline={bookingData?.timeline}
            setGranularity={setGranularity}
            granularity={mod.filters.granularity || "month"}
          />
        ) : chartTypeSelected === "users" ? (
          <BookingPerUser bookings={bookingData?.per_user} />
        ) : chartTypeSelected === "types" ? (
          <BookingsTimelinePerType
            bookings={bookingData?.timeline_per_type}
            setGranularity={setGranularity}
            granularity={mod.filters.granularity || "month"}
          />
        ) : (
          chartTypeSelected === "bus" && (
            <BookingsTimelinePerBu
              bookings={bookingData?.timeline_per_bu}
              setGranularity={setGranularity}
              granularity={mod.filters.granularity || "month"}
              filters={filters}
            />
          )
        )}
      </div>
    </div>
  );
};

export default BookingChart;
