import React, { FunctionComponent, useState } from "react";
import { useParams } from "react-router";
import ProjectList from "../../../components/billing/projects/projectList";
import { getProjects, ProjectResponse, ProjectsFilters } from "../../../models/billing/project";
import { Paginated, UUID } from "../../../models/common";

export const BillingProjectsPage: FunctionComponent = () => {
  const [projects, setProjects] = useState<Paginated<ProjectResponse>>();
  const { id } = useParams<{ id?: UUID }>();

  const fetchProjects = async (filters: ProjectsFilters) => {
    const projects = await getProjects(filters);
    setProjects(projects);
  };

  return (
    <div className="billingProjects">
      <ProjectList fetchProjects={fetchProjects} projects={projects} edition id={id} />
    </div>
  );
};

export default BillingProjectsPage;
