import React, { FunctionComponent, useEffect, useState } from "react";
import { JobTitle, createUnvalidatedJobTItle, deleteUnvalidatedJobTitle } from "../../../../models/jobTitle";
import { Category, fetchCategoryPath } from "../../../../models/jobTitleCategory";
import { Button, Select, Tag, TagColor } from "@getprorecrutement/getpro-design";
import { CategorySelect } from "../jobTitleCategorySelect/categorySelect";
import { Hierarchy } from "../../../../models/jobTitleHierarchy";
import { useFormManager } from "../../../../services/form";
import { PencilSquareIcon } from "@heroicons/react/24/outline";

interface JobTitleValidationProps {
  rawTitle: string;
  onChange: (v?: JobTitle) => void;
  categories: Category[];
  hierarchies: Hierarchy[];
}

export const JobTitleValidation: FunctionComponent<JobTitleValidationProps> = ({
  rawTitle,
  onChange,
  categories,
  hierarchies,
}) => {
  const [jobTitle, setJobTitle] = useState<JobTitle>();
  const [categoryPath, setCategoryPath] = useState<Category[]>();

  const form = useFormManager({
    defaultState: {
      hierarchy: undefined,
      category: undefined,
    } as Partial<{
      category: Category;
      hierarchy: Hierarchy;
    }>,
    validations: {
      hierarchy: {
        required: true,
        message: "Merci de préciser une hiérarchie",
      },
      category: {
        required: true,
        message: "Merci de préciser une catégorie",
      },
    },
  });

  useEffect(() => {
    if (jobTitle) fetchCategoryPath(jobTitle.category_id).then((cp) => setCategoryPath(cp.reverse()));
    else setCategoryPath(undefined);
  }, [jobTitle]);

  const onConfirm = async () => {
    if (form.validate()) {
      const payload = {
        title: rawTitle,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        category_id: form.state.category!.id,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        hierarchy_id: form.state.hierarchy!.id,
      };
      const jobTitle = await createUnvalidatedJobTItle(payload);
      setJobTitle(jobTitle);
      onChange(jobTitle);
    }
  };

  const deleteTitle = async () => {
    if (!jobTitle) return;
    await deleteUnvalidatedJobTitle(jobTitle.id);
    setJobTitle(undefined);
    onChange(undefined);
  };

  return jobTitle ? (
    <div className="mt-2">
      <div className="flex items-center gap-2 justify-between">
        <div className="flex items-center gap-2 flex-wrap">
          {categoryPath &&
            categoryPath.map((c, i) => (
              <Tag key={i} value={c.title} type={TagColor.Primary} className="border-none" bold />
            ))}
          <Tag
            key={jobTitle.hierarchy_id}
            value={hierarchies.find((c) => c.id === jobTitle.hierarchy_id)?.title || "Hiérarchie"}
            type={TagColor.Primary}
            bold
            className="bg-teal-100 text-teal-400 border-none"
          />
        </div>
        <PencilSquareIcon className="w-6 h-6 text-primary-medium cursor-pointer" onClick={deleteTitle} />
      </div>
    </div>
  ) : (
    <div className="flex justify-center w-full items-start gap-3 mt-2">
      <CategorySelect
        error={form.errors.category}
        categories={categories || []}
        value={form.state.category}
        onSelect={(cat) => form.onChange({ category: cat })}
      />
      <Select
        valueClassName="whitespace-nowrap"
        dropdownClassName="min-w-[250px]"
        bordered
        options={hierarchies}
        error={form.errors.hierarchy}
        placeholder="Hiérarchie"
        onChange={(h) => form.onChange({ hierarchy: h })}
        type="single"
        value={form.state.hierarchy}
        getKey={(h) => h.id}
        optionRender={(h) => h.title}
      />
      <Button disabled={form.disabled} title="Créer" onClick={onConfirm} kind="outline" />
    </div>
  );
};
