import React, { useEffect, useState } from "react";
import { FunctionComponent } from "react";
import { GlobalDashboard, UserDashboard, viewGlobalDashboards } from "../../models/dashboards";
import { getMySettings, updateMySettings, UserSettings } from "../../models/users/settings";
import store, { Message } from "../../services/store";
import { useNavigate, useParams } from "react-router";
import Dashboard from "../../components/dashboard/dashboard";
import { useDeepCompareEffect } from "react-use";
import { toast } from "react-hot-toast";
import { Spinner } from "@getprorecrutement/getpro-design";

export const DashboardPage: FunctionComponent = () => {
  const { slug } = useParams<{ slug: string }>();
  const [userSettings, setUserSettings] = useState<UserSettings | undefined>(store.state.Settings);
  const [globalDashboards, setGlobalDashboards] = useState<GlobalDashboard[]>([]);
  const [currentDashboard, setCurrentDashboard] = useState<UserDashboard>();
  const navigate = useNavigate();

  const fetchSettings = async () => {
    const settings = await getMySettings();
    setUserSettings(settings);
    return settings;
  };

  const fetchDashboards = async () => {
    const settings = await getMySettings();
    const globalDashboards = await viewGlobalDashboards();
    setUserSettings(settings);
    setGlobalDashboards(globalDashboards);
  };

  useEffect(() => {
    fetchDashboards();
  }, []);

  store.listen(Message.RefreshSettings, fetchSettings);

  const removeDashboard = async (slug: string) => {
    if (userSettings) {
      if (!userSettings.dashboards.find((d) => d.slug === slug)) {
        toast.error("Pour supprimer un dashboard global, merci de vous rendre dans Paramètres -> Dashboards");
        return;
      }
      const data = {
        ...userSettings,
        dashboards: (userSettings?.dashboards || []).filter((d) => d.slug !== slug),
      } as UserSettings;
      await updateMySettings(data);
      toast.success("Dashboard supprimé avec succès");
      store.notify(Message.RefreshSettings);
      navigate(data.dashboards.length > 0 ? `/dashboard/${data.dashboards[0].slug}` : "/");
    }
  };

  useDeepCompareEffect(() => {
    const dashboard = [...globalDashboards, ...(userSettings?.dashboards || [])].find(
      (dashboard) => dashboard.slug === slug
    );
    if (dashboard) setCurrentDashboard(dashboard);
  }, [slug, globalDashboards, userSettings]);

  const updateDashboard = async (data: UserDashboard | GlobalDashboard | Partial<GlobalDashboard>) => {
    const dashboard = data as UserDashboard;

    if (userSettings && !(data as GlobalDashboard).id) {
      const newSettings: UserSettings = {
        ...userSettings,
        dashboards: [...userSettings.dashboards.filter((d) => d.slug !== data.slug), dashboard],
      };

      await updateMySettings(newSettings);
      store.notify(Message.RefreshSettings);
    } else if ((data as GlobalDashboard).id) {
      const payload = data as GlobalDashboard;
      setGlobalDashboards((ds) => ds.map((d) => (d.id === payload.id ? payload : d)));
    }
  };

  return (
    <div style={{ width: "100%", height: "100%" }}>
      {currentDashboard ? (
        <Dashboard
          remove={removeDashboard}
          update={updateDashboard}
          dashboard={currentDashboard}
          backLink={globalDashboards.length > 0 ? `/dashboard/${globalDashboards[0].slug}` : "/"}
        />
      ) : (
        <div style={{ width: "100%", padding: "100px 0", textAlign: "center" }}>
          <Spinner light />
        </div>
      )}
    </div>
  );
};

export default DashboardPage;
