import React, { FunctionComponent, useState, useEffect } from "react";
import { Select, TextInput } from "@getprorecrutement/getpro-design";
import { PlusCircleIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { JobTitle, fetchJobTitles } from "../../../../models/jobTitle";
import { UUID } from "../../../../models/common";
import { Hierarchy, fetchHierarchies } from "../../../../models/jobTitleHierarchy";
import { useDebounce } from "../../../../utils";
import { Category as JobTitleCategory, fetchJobTitleCategories } from "../../../../models/jobTitleCategory";
import { JobTitleValidation } from "./jobTitleValidation";

export interface jobTitleSelectProps {
  onChange: (jobTitleId?: JobTitle, categoryId?: UUID, validated?: boolean) => void;
  title?: JobTitle;
  currentJobTitle?: JobTitle;
  error?: string;
  label?: string;
}

export const JobTitleSelect: FunctionComponent<jobTitleSelectProps> = ({
  onChange,
  currentJobTitle,
  error,
  label,
  ...props
}) => {
  const [jobTitleCategories, setJobTitleCategories] = useState<JobTitleCategory[]>([]);
  const [hierarchies, setHierarchies] = useState<Hierarchy[]>([]);
  const [jobTitles, setJobTitles] = useState<JobTitle[]>();
  const [selectedTitle, setSelectedTitle] = useState<JobTitle | undefined>(props.title);
  const [newUnvalidatedJobTitle, setNewUnvalidatedJobTitle] = useState<Partial<JobTitle>>();
  const [jobTitleInput, setJobTitleInput] = useState<string>("");
  const jobTitleSearch = useDebounce(jobTitleInput, 500);

  useEffect(() => {
    fetchJobTitleCategories().then(setJobTitleCategories);
    fetchHierarchies().then(setHierarchies);
  }, []);

  useEffect(() => {
    setSelectedTitle(currentJobTitle);
  }, [currentJobTitle]);

  useEffect(() => {
    fetchJobTitles({ search: jobTitleSearch }).then(setJobTitles);
  }, [jobTitleSearch]);

  if (newUnvalidatedJobTitle?.title)
    return (
      <div className="bg-inherit">
        {selectedTitle ? (
          <div>
            {label && <div className="text-sm text-content-light font-medium">{label} :</div>}
            <div className="text-sm font-bold">{newUnvalidatedJobTitle?.title}</div>
          </div>
        ) : (
          <TextInput
            label={label}
            icon={
              <XMarkIcon
                className="w-5 h-5 cursor-pointer"
                onClick={() => {
                  setJobTitleInput("");
                  setNewUnvalidatedJobTitle(undefined);
                }}
              />
            }
            placeholder="Nom du titre"
            type="text"
            value={newUnvalidatedJobTitle?.title}
            onChange={(e) => {
              setJobTitleInput(e.target.value);
              setNewUnvalidatedJobTitle({ title: e.target.value });
            }}
          />
        )}
        <JobTitleValidation
          categories={jobTitleCategories}
          hierarchies={hierarchies}
          rawTitle={newUnvalidatedJobTitle.title}
          onChange={(jt) => {
            onChange(jt, jt?.category_id, false);
            setSelectedTitle(jt);
          }}
        />
      </div>
    );

  const options = currentJobTitle
    ? [currentJobTitle, ...(jobTitles?.filter((title) => title.id !== currentJobTitle.id) || [])]
    : jobTitles || [];

  const JobTitleSelectOptions = (options: JSX.Element) => {
    return (
      <div>
        {jobTitleInput &&
          !(jobTitles || []).map((jt) => jt.title.toLowerCase()).includes(jobTitleInput.toLowerCase()) && (
            <div
              className="flex py-2 justify-start items-center cursor-pointer gap-2 px-6 hover:bg-background-lightest text-content-darker"
              onClick={() => {
                setNewUnvalidatedJobTitle({ title: jobTitleInput });
                onChange(undefined);
                setSelectedTitle(undefined);
              }}
            >
              <PlusCircleIcon height={16} width={16} />
              <div>
                Créer le titre <span className="text-primary-dark font-semibold">{jobTitleInput}</span>
              </div>
            </div>
          )}
        {options}
      </div>
    );
  };

  return (
    <div className="bg-white">
      <Select
        error={error}
        label={label}
        placeholder="Sélectionnez un titre de poste"
        rounded
        bordered
        type="single"
        value={selectedTitle}
        onChange={(title) => {
          setSelectedTitle(title);
          if (title) {
            onChange(title, title.category_id, true);
          }
        }}
        onSearch={setJobTitleInput}
        options={options}
        getKey={(title) => title.id}
        optionRender={(title) => title.title}
        dropdownRender={(menu) => JobTitleSelectOptions(menu)}
      />
    </div>
  );
};
