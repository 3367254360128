import React, { useEffect, useState } from "react";
import {
  CommissionDetails,
  CommissionRateDetails,
  CommissionThirdDetails,
  getProject,
  ProductDetails,
  ProjectResponse,
  ProjectsFilters,
  ProjectStatus,
  ProjectStatuses,
  ProjectType,
  RpoDetails,
  SubscriptionDetails,
  updateProject,
} from "../../../models/billing/project";
import { Paginated, UUID } from "../../../models/common";
import ProjectForm from "./form/index";
import { useDebounce } from "../../../utils";
import ProjectCard from "./projectCard";
import { euroDisplay } from "../../../utils/formatters";
import ProjectTypeTag from "./projectTypeTag";
import dayjs from "dayjs";
import { Modal, Radio, Table, Button, ColumnProps, Tooltip } from "@getprorecrutement/getpro-design";
import { ArrowLeftIcon, InformationCircleIcon, PencilIcon, PlusIcon } from "@heroicons/react/24/outline";
import { Link, useNavigate } from "react-router-dom";

const PER_PAGE = 10;

interface Props {
  projects?: Paginated<ProjectResponse>;
  fetchProjects: (filters: ProjectsFilters) => void;
  returnPath?: string;
  contractId?: UUID; // Form constraint
  customerId?: UUID; // Form constraint
  edition?: boolean; // Allow create / update
  id?: UUID; // Directly display a project
}

export const ProjectList = (props: Props) => {
  const { projects, fetchProjects, returnPath, edition, customerId, contractId, id } = props;
  const [project, setProject] = useState<ProjectResponse>();
  const [filters, setFilters] = useState<ProjectsFilters>({
    page: 1,
    per_page: PER_PAGE,
  });
  const [search, setSearch] = useState<string>();
  const [form, setForm] = useState<boolean>(false);
  const debouncedSearch = useDebounce(search, 300);
  const navigate = useNavigate();

  const getAmount = (project: ProjectResponse) => {
    let amount = 0;
    if (project.project_type === ProjectType.Rpo) {
      (project.details as RpoDetails).cost_lines.map((line) => (amount += line.amount * line.days));
    } else if (project.project_type === ProjectType.Product) {
      (project.details as ProductDetails).products.map((line) => (amount += line.amount * line.quantity));
    } else if (project.project_type === ProjectType.Commission) {
      amount = (project.details as CommissionDetails).amount;
    } else if (project.project_type === ProjectType.CommissionRate) {
      const details = project.details as CommissionRateDetails;
      amount = (details.amount * details.rate) / 100;
    } else if (project.project_type === ProjectType.CommissionThird) {
      amount = (project.details as CommissionThirdDetails).amount * 3;
    } else if (project.project_type === ProjectType.Subscription) {
      amount = (project.details as SubscriptionDetails).amount;
    }
    return amount;
  };

  const updateProjectStatus = async (id: UUID, status: ProjectStatus) => {
    await updateProject({ id, status });
    fetchProject(id);
  };

  const columns: ColumnProps<ProjectResponse>[] = [
    {
      title: "Nom",
      dataIndex: "name",
      key: "name",
      filterBy: "text",
    },
    {
      title: "Entreprise",
      dataIndex: "customer_name",
      key: "customer_name",
      render: (value: string, record: ProjectResponse) => (
        <Tooltip light title={value}>
          <div
            className="truncate cursor-pointer"
            onClick={(e) => {
              e.stopPropagation();
              navigate(`/billing/customers/${record.customer_id}`);
            }}
          >
            {value}
          </div>
        </Tooltip>
      ),
    },
    {
      title: "Département",
      dataIndex: "business_unit_name",
      key: "business_unit_name",
    },
    {
      title: "Type de projet",
      dataIndex: "project_type",
      key: "project_type",
      width: 150,
      render: (_, record) => <ProjectTypeTag type={record.project_type} />,
    },
    {
      title: "Montant",
      dataIndex: "details",
      key: "details",
      width: 120,
      render: (_, record) => <div>{euroDisplay(getAmount(record))}</div>,
    },
    {
      title: "Date de création",
      dataIndex: "created_at",
      key: "created_at",
      width: 150,
      render: (value) => <div>{dayjs(value).format("ll")}</div>,
    },
  ];

  const onFinish = async () => {
    setForm(false);
    if (project) fetchProject(project.id);
  };

  const fetchProject = async (id: UUID) => {
    const p = await getProject(id);
    setProject(p);
  };

  useEffect(() => {
    if (!id) {
      fetchProjects(filters);
      setProject(undefined);
    } else fetchProject(id);
  }, [filters, id]);

  useEffect(() => {
    setFilters((e) => ({ ...e, name: debouncedSearch, page: 1 }));
  }, [debouncedSearch]);

  return (
    <div>
      <div className="bg-white p-6 shadow-md mb-6">
        <div className="flex justify-between items-center">
          <div className="flex items-center">
            {(returnPath || project) && (
              <Link
                to={returnPath && !project ? returnPath : returnPath ? `${returnPath}/projects` : "/billing/projects"}
              >
                <ArrowLeftIcon className="w-5 h-5 mr-4 cursor-pointer" />
              </Link>
            )}
            <div className="text-3xl font-bold">{project?.name || "Projets"}</div>
          </div>
          {edition && (
            <div className="flex gap-2">
              {project && (
                <Radio
                  light
                  optionType="button"
                  options={ProjectStatuses}
                  value={project.status}
                  onChange={(v) => updateProjectStatus(project.id, v as ProjectStatus)}
                />
              )}
              <Button light icon={project ? <PencilIcon /> : <PlusIcon />} onClick={() => setForm(true)} kind="light" />
            </div>
          )}
        </div>
      </div>
      {project && <ProjectCard project={project} />}
      {!project && projects && (
        <div className="px-6">
          <Table
            dataSource={projects}
            columns={columns}
            light
            bordered
            pagination={{
              per_page: PER_PAGE,
              page: filters.page,
              onChange: (p) => setFilters((e) => ({ ...e, page: p })),
            }}
            filter={{
              filters: { name: search },
              onFilterChange: (key, value) => {
                if (key === "name") {
                  setSearch(value);
                }
              },
            }}
            onClick={(record) =>
              navigate(returnPath ? `${returnPath}/projects/${record.id}` : `/billing/projects/${record.id}`)
            }
            extend={{
              render: (data) =>
                data.description ? (
                  <div className="flex items-center gap-2 p-4">
                    <InformationCircleIcon width={20} height={20} />
                    {data.description}
                  </div>
                ) : undefined,
              extendKey: "id",
            }}
            // TODO SELECT TYPE OF PROJECT / SELECT BUSINESS UNIT
          />
        </div>
      )}
      <Modal onClose={onFinish} show={form} className="w-modal-lg" light>
        <ProjectForm
          project={project}
          contractId={contractId}
          customerId={customerId}
          key={project?.id || "newProject"}
          onFinish={onFinish}
        />
      </Modal>
    </div>
  );
};

export default ProjectList;
