import classNames from "classnames";

export enum ColorType {
  Content = "content",
  Primary = "primary",
  Secondary = "secondary",
}

interface DropdownProps {
  position?: "top" | "bottom" | "left" | "right" | "bottom-left" | "bottom-right" | "top-left" | "top-right";
  light?: boolean;
}

export const dropDownClasses = ({ position, light }: DropdownProps) => {
  return classNames(
    "p-4 rounded-2xl bg-background-lightest text-content-darker absolute z-30 shadow-lg shadow-background-light cursor-default",
    {
      "dark:bg-background-dark dark:text-content-bright dark:shadow-background-darker": !light,
      "bottom-[calc(100%+6px)] left-1/2 -translate-x-1/2": position === "top",
      "top-0 right-[calc(100%+6px)]": position === "left",
      "top-0 left-[calc(100%+6px)]": position === "right",
      "top-[calc(100%+6px)] left-1/2 -translate-x-1/2": position === "bottom",
      "top-[calc(100%+6px)] right-0": position === "bottom-left",
      "top-[calc(100%+6px)] left-0": position === "bottom-right",
      "bottom-[calc(100%+6px)] right-0": position === "top-left",
      "bottom-[calc(100%+6px)] left-0": position === "top-right",
    }
  );
};

interface InputProps {
  size?: "small" | "medium" | "large";
  disabled?: boolean;
  light?: boolean;
  bordered?: boolean;
  focused?: boolean;
  error?: string;
}

export const inputPaddingClasses = ({ size, bordered }: InputProps) =>
  classNames("", {
    // Bordered padding
    "py-[7px] px-4": size === "small" && bordered,
    "py-[9px] px-7": size === "medium" && bordered,
    "py-[13px] px-10": size === "large" && bordered,
    // Not bordered padding
    "py-2 px-4": size === "small" && !bordered,
    "py-2.5 px-7": size === "medium" && !bordered,
    "py-3.5 px-11": size === "large" && !bordered,
  });

export const wrapperInputClasses = ({ size = "medium", disabled, light, bordered, focused, error }: InputProps) =>
  classNames(`font-medium w-full transition-colors ease-in-out ${inputPaddingClasses({ size, bordered })}`, {
    // Bordered
    "border-solid border rounded-full": bordered,
    // Bordered not focused
    "hover:border-border-darker focus:border-border-darker group-hover:border-border-darker border-border-lighter":
      bordered && !disabled && !error && !focused,
    "dark:hover:border-border-bright dark:group-hover:border-border-bright dark:focus:border-border-lighter dark:border-border-medium":
      !light && bordered && !disabled && !error && !focused,
    // Bordered focused
    "border-border-darker": bordered && !disabled && focused && !error,
    "dark:border-border-lighter": bordered && !disabled && focused && !error && !light,
    // Bordered disabled
    "border-background-lightest cursor-not-allowed": disabled && bordered,
    // Bordered error
    "border-error-light group-hover:border-error-dark hover:border-error-dark":
      error && !disabled && bordered && !focused,
    "dark:border-error-darker dark:group-hover:border-error-dark dark:hover:border-error-dark":
      error && !disabled && bordered && !focused && !light,
    "border-error-dark": error && !disabled && bordered && focused,
  });

export const textSizeClasses = ({ size }: InputProps) =>
  classNames("", {
    // Text Size
    "text-xs placeholder:text-xs": size === "small",
    "text-sm placeholder:text-sm": size === "medium",
    "text-base placeholder:text-base": size === "large",
  });

export const randomString = (length = 8) => {
  const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789_-.";

  let str = "";
  for (let i = 0; i < length; i++) {
    str += chars.charAt(Math.floor(Math.random() * chars.length));
  }

  return str;
};

export const textInputClasses = ({ size, disabled, light, error }: InputProps) =>
  classNames(`placeholder:font-normal placeholder:italic focus:outline-none ${textSizeClasses({ size })}`, {
    // Text Color
    "dark:text-content-bright dark:placeholder:text-content-regular": !light && !disabled && !error,
    "text-content-darker placeholder:text-content-light": !disabled && !error,
    "text-content-darker placeholder:text-error-light": error && !disabled,
    "dark:text-content-bright dark:placeholder:text-error-darker": error && !disabled && !light,
    "text-content-light placeholder:text-content-light": disabled,
    "dark:text-content-dark dark:placeholder:text-content-dark": disabled && !light,
  });

export const labelClasses = ({ size, disabled, light, focused, error }: InputProps) =>
  classNames("absolute z-10 -top-3 font-normal bg-inherit transition-colors flex items-center rounded-full", {
    "text-xs left-4 px-1": size === "small",
    "text-sm left-5 px-2": size === "medium",
    "text-sm left-9 px-2": size === "large",
    // Normal
    "group-hover:text-content-darker text-content-light": !disabled && !error && !focused,
    "dark:group-hover:text-content-bright dark:text-content-regular": !disabled && !error && !focused && !light,
    // Disabled
    "text-content-light": disabled,
    // Error
    "text-error-normal": !disabled && error,
    "dark:text-error-normal": !disabled && error && !light,
    // Focused
    "text-content-darker": focused && !disabled,
    "dark:text-content-bright": focused && !disabled && !light,
  });
