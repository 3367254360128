import httpClient from "../services/http/client";
import ROUTES from "../services/http/routes";
import { notifyError } from "../services/requests";
import { UUID } from "./common";
import { FilePayload } from "./misc";

export interface File extends NewFile {
  created_at: string;
}

export interface NewFile {
  id: UUID;
  name: string;
}

export interface FileData {
  id: UUID;
  name?: string;
}

export async function uploadFile(file: FilePayload): Promise<{ id: UUID }> {
  return httpClient.req(ROUTES.UPLOAD_FILE(file), (err) =>
    notifyError(err || "Erreur lors de l'ajout du fichier")
  );
}

export async function uploadPublicFile(
  file: FilePayload
): Promise<{ id: UUID }> {
  return httpClient.req(ROUTES.UPLOAD_PUBLIC_FILE(file), (err) =>
    notifyError(err || "Erreur lors de l'ajout du logo")
  ); // LOGO OU FICHIER ?
}

export async function getFileUrl(
  id: UUID,
  bucket_name: string
): Promise<{ url: string }> {
  const signature = await httpClient.req<string>(ROUTES.SIGN_FILE(id), (err) =>
    notifyError(err || "Erreur lors la signature du fichier")
  );
  return {
    url: httpClient.genUrlWithParams(
      ROUTES.DOWNLOAD_FILE(id, signature, bucket_name)
    ),
  };
}

export async function deleteFile(id: UUID): Promise<void> {
  return httpClient.req(ROUTES.DELETE_FILE(id), notifyError);
}

export async function updateFile(data: FileData): Promise<File> {
  return httpClient.req(ROUTES.UPDATE_FILE(data), (err) =>
    notifyError(err || "Erreur lors de la modification du fichier")
  );
}
