import React, { FunctionComponent, useEffect, useState } from "react";
import {
  ShareIcon,
  UserGroupIcon,
  ArrowTrendingUpIcon,
  DocumentTextIcon,
  Cog8ToothIcon,
  ChartBarIcon,
  BanknotesIcon,
  CircleStackIcon,
  UsersIcon,
  CubeTransparentIcon,
  BuildingOffice2Icon,
  FolderOpenIcon,
  RssIcon,
  PlusIcon,
  CheckIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { updateMySettings, UserSettings } from "../../models/users/settings";
import store, { Message } from "../../services/store";
import { slugify } from "../../utils/formatters";
import { GlobalDashboard, viewGlobalDashboards } from "../../models/dashboards";
import { UserResponse } from "../../models/users/users";
import UserMenu from "./userMenu";
import { MenuItem, MenuItemGroup, SubMenuItem } from "./menuItems";
import { Button, TextInput } from "@getprorecrutement/getpro-design";

interface Props {
  settings?: UserSettings;
  user?: UserResponse;
  collapsed: boolean;
  setCollapsed: (collapsed: boolean) => void;
}

export const Menu: FunctionComponent<Props> = (props) => {
  const [globalDashboards, setGlobalDashboards] = useState<GlobalDashboard[]>([]);

  const saveDashboard = async (name: string, slug?: string) => {
    if (props.settings) {
      const dashboards = slug
        ? props.settings.dashboards.map((d) => {
            return d.slug === slug ? { ...d, name, slug: slugify(name) } : d;
          })
        : [...props.settings.dashboards, { name, slug: slugify(name), modules: [], filters: {} }];

      const settings = {
        ...props.settings,
        dashboards,
      } as UserSettings;

      await updateMySettings(settings);
      store.notify(Message.RefreshSettings);
    }
  };

  useEffect(() => {
    viewGlobalDashboards().then(setGlobalDashboards);
  }, []);

  return (
    <div className="flex flex-col justify-between h-full pt-4">
      <div className="flex flex-col gap-8">
        <MenuItem
          key="/jobs"
          collapsed={props.collapsed}
          icon={<DocumentTextIcon width={20} height={20} />}
          to="/jobs"
          title="Offres"
        />
        <MenuItem
          collapsed={props.collapsed}
          key="/candidates"
          icon={<UserGroupIcon width={20} height={20} />}
          to="/candidates"
          title="Candidats"
        />
        {/* BILLING */}
        <MenuItemGroup collapsed={props.collapsed} icon={<BanknotesIcon width={20} height={20} />} title="Facturation">
          {[
            (setSubMenuActive: (key: string, active: boolean) => void) => (
              <SubMenuItem setSubMenuActive={setSubMenuActive} to="/billing/dashboard" title="Accueil" />
            ),
            (setSubMenuActive: (key: string, active: boolean) => void) => (
              <SubMenuItem setSubMenuActive={setSubMenuActive} to="/billing/customers" title="Clients" />
            ),
            (setSubMenuActive: (key: string, active: boolean) => void) => (
              <SubMenuItem setSubMenuActive={setSubMenuActive} to="/billing/bills" title="Factures" />
            ),
            (setSubMenuActive: (key: string, active: boolean) => void) => (
              <SubMenuItem setSubMenuActive={setSubMenuActive} to="/billing/contracts" title="Contrats" />
            ),
            (setSubMenuActive: (key: string, active: boolean) => void) => (
              <SubMenuItem setSubMenuActive={setSubMenuActive} to="/billing/projects" title="Projets" />
            ),
          ]}
        </MenuItemGroup>
        {/* DASHBOARDS */}
        <MenuItemGroup
          collapsed={props.collapsed}
          icon={<ArrowTrendingUpIcon width={20} height={20} />}
          title="Dashboard"
        >
          {globalDashboards
            // eslint-disable-next-line react/display-name
            .map((dashboard) => (setSubMenuActive: (key: string, active: boolean) => void) => (
              <SubMenuItem
                setSubMenuActive={setSubMenuActive}
                to={`/dashboard/${dashboard.slug}`}
                title={dashboard.name}
              />
            ))
            .concat(
              (props.settings?.dashboards || []).map(
                // eslint-disable-next-line react/display-name
                (dashboard) => (setSubMenuActive: (key: string, active: boolean) => void) =>
                  (
                    <SubMenuItem
                      setSubMenuActive={setSubMenuActive}
                      to={`/dashboard/${dashboard.slug}`}
                      title={dashboard.name}
                    />
                  )
              )
            )
            .concat([() => <CreateDashboardElem saveDashboard={saveDashboard} />])}
        </MenuItemGroup>
        {/* SETTINGS */}
        <MenuItemGroup collapsed={props.collapsed} icon={<Cog8ToothIcon width={20} height={20} />} title="Paramètres">
          {[
            (setSubMenuActive: (key: string, active: boolean) => void) => (
              <SubMenuItem
                setSubMenuActive={setSubMenuActive}
                to="/settings/users"
                title="Utilisateurs"
                icon={<UsersIcon width={20} />}
              />
            ),
            (setSubMenuActive: (key: string, active: boolean) => void) => (
              <SubMenuItem
                setSubMenuActive={setSubMenuActive}
                to="/settings/businessUnits"
                title="Départements"
                icon={<CubeTransparentIcon width={20} />}
              />
            ),
            (setSubMenuActive: (key: string, active: boolean) => void) => (
              <SubMenuItem
                setSubMenuActive={setSubMenuActive}
                to="/settings/customers"
                title="Entreprises"
                icon={<BuildingOffice2Icon width={20} />}
              />
            ),
            (setSubMenuActive: (key: string, active: boolean) => void) => (
              <SubMenuItem
                setSubMenuActive={setSubMenuActive}
                to="/settings/dashboards"
                title="Dashboards"
                icon={<ChartBarIcon width={20} />}
              />
            ),
            (setSubMenuActive: (key: string, active: boolean) => void) => (
              <SubMenuItem
                setSubMenuActive={setSubMenuActive}
                to="/settings/roles"
                title="Rôles"
                icon={<CircleStackIcon width={20} />}
              />
            ),
            (setSubMenuActive: (key: string, active: boolean) => void) => (
              <SubMenuItem
                setSubMenuActive={setSubMenuActive}
                to="/settings/categories"
                title="Catégories"
                icon={<FolderOpenIcon width={20} />}
              />
            ),
          ]}
        </MenuItemGroup>
        <MenuItem
          key="/signals"
          collapsed={props.collapsed}
          icon={<RssIcon width={20} height={20} />}
          to="/signals"
          title="Signal"
        />
        <MenuItem
          collapsed={props.collapsed}
          key="jobs"
          icon={<ShareIcon width={20} height={20} />}
          title="Job board"
          to="https://jobs.getpro.co"
          link={false}
        />
      </div>
      {props.user && <UserMenu {...props} user={props.user} />}
    </div>
  );
};

interface CreateDashboardProps {
  saveDashboard: (name: string) => void;
}

const CreateDashboardElem: FunctionComponent<CreateDashboardProps> = (props) => {
  const [editDashboard, setEditDashboard] = useState<string>();
  const [dashboardName, setDashboardName] = useState<string>();

  const saveDashboard = async (name: string) => {
    props.saveDashboard(name);
    setEditDashboard(undefined);
    setDashboardName(undefined);
  };

  return (
    <div className="w-48 relative flex justify-start" onClick={(e) => e.stopPropagation()}>
      {editDashboard === "" ? (
        <div className="flex items-center gap-2 bg-background-medium py-0.5">
          <TextInput
            dark
            value={dashboardName}
            onChange={(e) => setDashboardName(e.currentTarget.value)}
            type="text"
            placeholder="Nom"
            label="Nom"
            size="small"
          />
          <CheckIcon
            width={20}
            height={20}
            className={dashboardName ? "text-content-bright cursor-pointer" : "text-content-medium"}
            onClick={() => dashboardName && saveDashboard(dashboardName)}
          />
          <XMarkIcon
            width={20}
            height={20}
            className={"text-red-400 cursor-pointer"}
            onClick={() => {
              setDashboardName(undefined);
              setEditDashboard(undefined);
            }}
          />
          {/* <div className="ml-1 flex gap-1 absolute left-full"> */}
          {/* </div> */}
        </div>
      ) : (
        <Button
          size="small"
          title="Nouvelle dashboard"
          icon={<PlusIcon />}
          light
          onClick={() => setEditDashboard("")}
        />
      )}
    </div>
  );
};

export default Menu;
