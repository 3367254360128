import classNames from "classnames";
import React, { FunctionComponent, useState } from "react";
import { twMerge } from "tailwind-merge";

export interface Props {
  value?: string;
  options: { label: string; value?: string; disabled?: boolean }[];
  onChange: (value?: string) => void;
  optionType?: "default" | "button";
  dark?: boolean;
  light?: boolean;
}

export const Radio: FunctionComponent<Props & Omit<React.HTMLAttributes<HTMLDivElement>, "onChange">> = ({
  value,
  options,
  onChange,
  optionType = "default",
  dark,
  light,
  ...props
}) => {
  const wrapperClasses = twMerge(
    classNames("w-max flex items-center", {
      [`${props.className}`]: props.className,
      dark: dark,
    })
  );

  const optionClasses = classNames("flex items-center cursor-pointer text-sm text-primary-darker", {
    "px-8 before:content-[''] before:w-2.5 before:h-2.5 before:rounded-full before:border before:border-solid before:border-border-lighter before:mr-[1em] before:bg-transparent":
      optionType === "default",
    "px-4 py-2 border-t border-b border-solid border-border-bright hover:bg-background-bright": optionType === "button",
    "dark:border-border-medium dark:text-content-bright hover:bg-background-dark": optionType === "button" && !light,
    "dark:text-primary-bright dark:before:border-border-medium": optionType === "default" && !light,
  });

  const valueClasses = classNames(" font-normal hover:bg-unset", {
    " before:bg-primary-light": optionType === "default",
    " rounded-full z-10 border-primary-darker bg-background-darker text-white": optionType === "button",
    " dark:border-background-bright dark:bg-background-bright dark:text-content-darker":
      optionType === "button" && !light,
  });

  const roundedLeftClasses = classNames("", {
    " pr-8 -mr-4": optionType === "button",
  });

  const roundedRightClasses = classNames("", {
    " pl-8 -ml-4": optionType === "button",
  });

  const firstElemClasses = classNames("", {
    " rounded-l-full border-l": optionType === "button",
  });

  const lastElemClasses = classNames("", {
    " rounded-r-full border-r": optionType === "button",
  });

  const getCurrentIndex = (): number => {
    let option = options.find((option) => option.value === value);

    if (option) {
      return options.indexOf(option);
    }
    return 0;
  };

  const optionFullClasses = (index: number, optionValue?: string, disabled?: boolean) => {
    let classes = optionClasses;

    if (optionValue === value) {
      classes = classes.concat(valueClasses);
    }

    if (disabled) {
      classes = classes.concat(" text-primary-light cursor-not-allowed");
    }

    if (value && (index === getCurrentIndex() - 1 || index === getCurrentIndex() + 1)) {
      classes = classes.concat(index === getCurrentIndex() - 1 ? roundedLeftClasses : roundedRightClasses);
    }

    if (index === 0 || index === options.length - 1) {
      classes = classes.concat(index === 0 ? firstElemClasses : lastElemClasses);
    }

    return twMerge(classes);
  };

  return (
    <div {...props} className={wrapperClasses}>
      {options.map((option, i) => {
        return (
          <div
            key={option.value}
            className={optionFullClasses(i, option.value, option.disabled)}
            onClick={() => !option.disabled && onChange(option.value)}
          >
            {option.label}
          </div>
        );
      })}
    </div>
  );
};
