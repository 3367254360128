import classNames from "classnames";
import React, { FunctionComponent } from "react";
import { twMerge } from "tailwind-merge";

export interface Props {
  title?: string;
  size?: "small" | "medium" | "large";
  dark?: boolean;
  light?: boolean;
}

export const Spinner: FunctionComponent<Props & React.HTMLAttributes<HTMLDivElement>> = ({
  title,
  size = "medium",
  dark,
  light,
  ...props
}) => {
  const wrapperClasses = classNames("flex flex-col items-center", {
    dark: dark,
    "gap-2": size === "small",
    "gap-4": size === "medium",
    "gap-8": size === "large",
  });

  const circleClasses = classNames(
    "border-b-transparent border-solid border-primary-lighter rounded-[50%] animate-spin",
    {
      "dark:border-border-medium dark:border-b-transparent": !light,
      "w-5 h-5 border-2": size === "small",
      "w-10 h-10 border-4": size === "medium",
      "w-20 h-20 border-8": size === "large",
    }
  );

  const titleClasses = classNames("text-content-darker", {
    "dark:text-content-bright": !light,
    "text-xs": size === "small",
    "text-base": size === "medium",
    "text-2xl": size === "large",
  });

  return (
    <div className={wrapperClasses}>
      <div className={circleClasses}></div>
      {title && <div className={titleClasses}>{title}</div>}
    </div>
  );
};
