import React, { FunctionComponent, useEffect, useState } from "react";
import { NewUser, UserResponse, UserData } from "../../models/users/users";
import UserForm from "../settings/users/userForm";
import TemplatesForm from "./templatesForm";
import { Avatar } from "../../components/userStatus";
import SignatureForm from "./signatureForm";
import { BusinessUnit, getBusinessUnits } from "../../models/businessUnits";
import { createUser, updateUser } from "../../models/users/users";
import { getMe } from "../../models/users/users";
import InlineLabelValue from "../../components/inlineLabelValue";
import { getRoles, Role } from "../../models/roles";
import { Button, Modal, Spinner, Tab } from "@getprorecrutement/getpro-design";
import { toast } from "react-hot-toast";

const MENU_LIST = [
  { label: "Templates", value: "templates" },
  { label: "Signature", value: "signatures" },
];

export const UserAccount: FunctionComponent = () => {
  const [selectedMenu, setSelectedMenu] = useState<{ label: string; value: string }>(MENU_LIST[0]);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<UserResponse | undefined>(undefined);
  const [edit, setEdit] = useState<boolean>(false);
  const [businessUnits, setBusinessUnits] = useState<BusinessUnit[]>([]);
  const [roles, setRoles] = useState<Role[]>([]);

  const fetchRoles = async () => {
    getRoles().then(setRoles);
  };

  const userDatas = (user: UserResponse) => {
    return [
      { label: "Titre du poste", value: user.title, bold: true, section: 1 },
      { label: "Adresse e-mail", value: user.email, bold: true, section: 1 },
      {
        label: "Linkedin",
        value: <a href={user.linkedin_url}>{user.linkedin_url}</a>,
        bold: true,
        section: 1,
      },
      {
        label: "Rôle",
        value: roles.find((r) => r.id === user.role_id)?.name || "",
        bold: true,
        section: 2,
      },
      {
        label: "Département",
        value: businessUnits.find((bu) => bu.id === user.business_unit_id)?.name,
        bold: true,
        section: 2,
      },
    ];
  };

  const fetchMe = async () => {
    const me = await getMe();

    setUser(me);
  };

  const fetchBusinessUnits = async () => {
    try {
      const res = await getBusinessUnits();

      setBusinessUnits(res);
    } finally {
      setLoading(false);
    }
  };

  const submitUser = async (rawData: UserData): Promise<void> => {
    const data = { ...rawData };

    if (!user?.id) data.password = Math.random().toString(36);

    await (user?.id ? updateUser({ ...data, id: user?.id }) : createUser(data as NewUser));
    toast.success(`Utilisateur ${user?.id ? "mis à jour" : "créé"} avec succès`);
    setEdit(false);
    fetchMe();
  };

  useEffect(() => {
    fetchMe();
    fetchRoles();
    fetchBusinessUnits();
  }, []);

  const userInfos = () => {
    if (loading) return <Spinner light />;
    return (
      <div className="bg-white p-6 shadow-md mb-6">
        <div className="flex justify-between mb-4">
          <div className="flex gap-4">
            {user && <Avatar size="large" user={user as UserResponse} />}
            <div className="text-3xl font-bold">
              {user?.first_name} {user?.last_name}
            </div>
          </div>
          <Button light size="small" title="Editer" onClick={() => setEdit(true)} />
        </div>
        {user && (
          <div className="flex gap-4">
            <div className="flex-1">
              {userDatas(user)
                .filter((o) => o.section === 1)
                .map((e) => (
                  <InlineLabelValue {...e} key={e.label} />
                ))}
            </div>
            <div className="flex-1">
              {userDatas(user)
                .filter((o) => o.section === 2)
                .map((e) => (
                  <InlineLabelValue {...e} key={e.label} />
                ))}
            </div>
          </div>
        )}

        <Modal light className="w-modal-md" onClose={() => setEdit(false)} show={edit}>
          <UserForm businessUnits={businessUnits} key={user?.id || "new_user"} onSubmit={submitUser} user={user} />
        </Modal>
      </div>
    );
  };

  return (
    <div className="w-full">
      {userInfos()}
      <div className="flex gap-4">
        <div className="w-fit">
          <Tab
            values={MENU_LIST}
            getKey={(v) => v.value}
            getLabel={(v) => v.label}
            onChange={(v) => setSelectedMenu(v)}
            selected={selectedMenu}
            direction="vertical"
            light
          />
        </div>
        <div className="flex-1 border-l border-solid border-border-bright h-full px-8">
          {selectedMenu.value === "templates" ? (
            <div className="templates">
              <div className="text-3xl font-bold">Templates</div>
              <div className="mt-8">
                <TemplatesForm onSubmit={submitUser} user={user} />
              </div>
            </div>
          ) : (
            <div className="signature">
              <div className="text-3xl font-bold">Signature</div>
              <div className="mt-8">
                <SignatureForm onSubmit={submitUser} user={user} />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserAccount;
