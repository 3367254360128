import React from "react";
import logo from "../assets/logo.svg";

interface Props {
  size?: number;
  bgClass: string;
  borderSize?: number;
}

const Logo = (props: Props) => {
  return (
    <div
      className="relative overflow-hidden rounded-[33%]"
      style={{
        width: props.size || 64,
        height: props.size || 64,
        minWidth: props.size || 64,
        minHeight: props.size || 64,
      }}
    >
      <div
        className="w-[200%] h-[200%] animate-spin absolute pointer-events-none select-none"
        style={{
          boxSizing: "border-box",
          left: "-50%",
          top: "-50%",
          backgroundSize: "400% 400%",
          background: "linear-gradient(#2662FA -50%, #B045F1 38%, #2662FA 100%)",
          animationDuration: "15s",
        }}
      ></div>
      <div
        className="w-full h-full absolute rounded-[33%] overflow-hidden"
        style={{ borderColor: "transparent", borderWidth: props.borderSize || 2 }}
      >
        <div
          className={`flex pointer-events-none select-none items-center justify-center p-[16%] w-full h-full ${props.bgClass}`}
        >
          <img className="h-full" src={logo}></img>
        </div>
      </div>
    </div>
  );
};

export default Logo;
