import React, { FunctionComponent, useCallback, useEffect, useState } from "react";
import { UUID } from "../../../../models/common";
import { Skill, createSkill, deleteUnvalidatedSkill, fetchSkills, getSuggestedSkills } from "../../../../models/skill";
import { useDebounce } from "../../../../utils";
import { Select } from "@getprorecrutement/getpro-design";
import classNames from "classnames";
import { CheckIcon, PlusCircleIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { Candidate } from "../../../../models/candidate";

export const getDisplayedSkillsFromCandidate = async (candidate: Partial<Candidate>) => {
  let skills_ids = candidate.skills || [];
  const confirmed_ids =
    candidate.job_title_inputs?.skills.reduce((acc, next) => {
      if (next.kind === "selection" && !skills_ids.includes(next.id)) {
        acc.push(next.id);
      }
      return acc;
    }, [] as UUID[]) || [];
  skills_ids = skills_ids.concat(confirmed_ids);
  const skills = skills_ids.length > 0 ? await fetchSkills({ ids: skills_ids }) : [];

  const skills_names = skills.map((s) => s.name);
  const raw_selection =
    candidate.job_title_inputs?.skills.reduce((acc, next) => {
      if (next.kind === "raw_input" && !skills_names.includes(next.input)) {
        acc.push({
          kind: "raw_input",
          skill: next.input,
        });
      }
      return acc;
    }, [] as DisplayedSkills[]) || [];

  const res = [
    ...(skills.map((skill) => ({
      kind: "selection",
      skill,
    })) as DisplayedSkills[]),
    ...raw_selection,
  ];

  return res;
};

export const displayedSkillsEq = (a: DisplayedSkills, b: DisplayedSkills) => {
  if (a.kind === b.kind) {
    if (a.kind === "raw_input") return a.skill === b.skill;
    return a.skill.id === (b.skill as Skill).id;
  }
  return false;
};

export const onCreateSkill = (skill: Skill, skills: DisplayedSkills[]) => {
  const newSkill: DisplayedSkills = { kind: "selection", skill: skill };
  const newSelectedSkills = [...(skills || [])];

  const skillPos = skills.findIndex((s) => s.kind === "raw_input" && s.skill === skill.name);
  if (skillPos !== -1) newSelectedSkills[skillPos] = newSkill;
  else newSelectedSkills.push(newSkill);

  return newSelectedSkills;
};

export interface DisplayedConfirmedSkill {
  kind: "selection";
  skill: Skill;
}

interface DisplayedRawSkill {
  kind: "raw_input";
  skill: string;
}

export type DisplayedSkills = DisplayedConfirmedSkill | DisplayedRawSkill;

export interface SkillsSelectionProps {
  onChange: (value: DisplayedSkills) => void;
  selectedTitleCategory?: UUID;
  label?: string;
  error?: string;
  onCreate?: (value: Skill) => void;
  selectedSkills: DisplayedSkills[];
}

export const SkillsSelection: FunctionComponent<SkillsSelectionProps> = ({
  onChange,
  onCreate,
  selectedTitleCategory,
  label,
  error,
  selectedSkills,
}) => {
  const [skills, setSkills] = useState<DisplayedSkills[]>([]);
  const [input, setInput] = useState<string>("");
  const search = useDebounce(input, 300);
  const [suggestedSkills, setSuggestedSkills] = useState<DisplayedSkills[]>([]);
  const [pendingSkillsIds, setPendingSkillsIds] = useState<UUID[]>([]);

  const getSkills = useCallback(async () => {
    if (search?.length) {
      const skills = await fetchSkills({ name: search });
      setSkills(
        skills.map((skill) => ({
          kind: "selection",
          skill,
        }))
      );
    }
  }, [search]);

  useEffect(() => {
    getSkills();
  }, [search]);

  const removeSkill = async (skillId: UUID) => {
    await deleteUnvalidatedSkill(skillId);
    setPendingSkillsIds((v) => v.filter((s) => s !== skillId));
    setSkills((s) => s.filter((v) => !(v.kind === "selection" && v.skill.id === skillId)));
  };

  const addSkill = async (skill: DisplayedRawSkill) => {
    const newSkill = await createSkill({
      name: skill.skill,
      validated: false,
    });

    setPendingSkillsIds((s) => [...s, newSkill.id]);
    onCreate?.(newSkill);
    setSkills((s) => [...s, { kind: "selection", skill: newSkill }]);
  };

  const suggestSkills = useCallback(async () => {
    if (selectedSkills.length > 0 || selectedTitleCategory) {
      const skillsIds = selectedSkills.reduce((acc, next) => {
        if (next.kind === "selection") {
          acc.push(next.skill.id);
        }
        return acc;
      }, [] as UUID[]);
      const suggested = await getSuggestedSkills(selectedTitleCategory, skillsIds);
      setSuggestedSkills(
        suggested.map((skill) => ({
          kind: "selection",
          skill,
        }))
      );
    }
  }, [selectedSkills, selectedTitleCategory]);

  useEffect(() => {
    suggestSkills();
  }, [selectedSkills, selectedTitleCategory]);

  const SkillsSelectOptions = (options: JSX.Element) => {
    return (
      <div>
        {onCreate &&
          input &&
          !skills
            .map((s) => (s as DisplayedConfirmedSkill).skill.name.toLocaleLowerCase())
            .includes(input.toLowerCase()) && (
            <div
              className="flex py-2 justify-start items-center cursor-pointer gap-2 px-6 hover:bg-background-lightest text-content-darker"
              onClick={async () => {
                await addSkill({ kind: "raw_input", skill: input });
              }}
            >
              <PlusCircleIcon height={16} width={16} />
              <div>
                Créer la compétence <span className="text-primary-dark font-semibold">{input}</span>
              </div>
            </div>
          )}
        {options}
      </div>
    );
  };

  const renderInput = (values?: DisplayedSkills[]) => {
    return (
      <div className="flex flex-wrap gap-2 items-center">
        {(values || []).map((skill, idx) => {
          const classes = classNames("px-2 py-0.5 text-xs border rounded-full flex gap-2", {
            "border-dashed text-content-light border-border-lightest": skill.kind === "raw_input" && !error,
            "border-dashed text-error-normal border-error-light font-bold": skill.kind === "raw_input" && error,
          });
          return (
            <div key={idx} className={classes}>
              <div>{skill.kind === "raw_input" ? skill.skill : skill.skill.name}</div>
              {skill.kind === "raw_input" && (
                <CheckIcon
                  className="w-4 h-4"
                  onClick={async (ev) => {
                    ev.stopPropagation();
                    await addSkill(skill);
                  }}
                />
              )}
              <XMarkIcon
                className="w-4 h-4"
                onClick={async (ev) => {
                  ev.stopPropagation();
                  if (skill.kind === "selection" && pendingSkillsIds.includes(skill.skill.id))
                    await removeSkill(skill.skill.id);
                  onChange(skill);
                }}
              />
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div className="bg-inherit">
      <Select
        bordered
        options={skills}
        label={label}
        error={error}
        onSearch={setInput}
        value={selectedSkills}
        valueClassName={selectedSkills.length ? "px-3 py-2 rounded-3xl" : undefined}
        placeholder="Sélectionner les compétences"
        type="multiple"
        onChange={async (v) => {
          if (v) {
            if (v.kind === "selection" && pendingSkillsIds.includes(v.skill.id)) {
              await removeSkill(v.skill.id);
            }
            onChange(v);
          }
        }}
        getKey={(skill) => (skill.kind === "raw_input" ? skill.skill : skill.skill.id)}
        optionRender={(skill) => (skill.kind === "raw_input" ? skill.skill : skill.skill.name)}
        customValueRender={renderInput}
        dropdownRender={(menu) => SkillsSelectOptions(menu)}
      />
      <div className="flex gap-2 items-center flex-wrap mt-4">
        <div className="text-sm text-content-light font-medium">Suggestions:</div>
        {(suggestedSkills as DisplayedConfirmedSkill[]).map((skill) => (
          <div
            key={skill.skill.id}
            className="px-4 py-1 text-xs font-bold bg-primary-bright text-primary-medium rounded-full cursor-pointer"
            onClick={() => onChange(skill)}
          >
            {skill.skill.name}
          </div>
        ))}
        {!suggestedSkills.length && <div className="text-primary-medium">Aucune suggestion disponible</div>}
      </div>
    </div>
  );
};
