import React, { FunctionComponent, useRef, useState } from "react";
import { Customer, NewCustomer, createCustomer, updateCustomer } from "../../../models/customers";
import BasicStepForm from "./basicStep";
import BillingStepForm from "./billingStep";
import ContactStepForm from "./contactStep";
import { Button, Steps } from "@getprorecrutement/getpro-design";
import { uploadPublicFile } from "../../../models/file";
import toast from "react-hot-toast";

interface Props {
  customer?: Customer;
  onFinish: () => Promise<void>;
}

export const customerFormSteps = [
  {
    title: "Détails",
    description: "Informations basiques",
  },
  {
    title: "Facturation",
    description: "Infos commerciales",
  },
  {
    title: "Contacts",
    description: "Infos de contacts",
  },
];

export interface StepHandle {
  getData: () => Partial<Customer>;
  isValid: () => boolean;
}

export const CustomerForm: FunctionComponent<Props> = (props) => {
  const [values, setValues] = useState<Partial<Customer>>(props.customer || {});
  const [loading, setLoading] = useState(false);
  const [logoFile, setLogoFile] = useState<File>();
  const [current, setCurrent] = useState<number>(0);
  const [disabled, setDisabled] = useState<boolean>(false);
  const stepRef = useRef<StepHandle>(null);

  const uploadLogo = async (file: File) => {
    const { id } = await uploadPublicFile({ file });
    return id;
  };

  const onSubmit = async (payload: Partial<Customer>) => {
    if (current === 2 && !props.customer?.id) props.onFinish();
    else {
      setLoading(true);
      try {
        if (props.customer?.id || current === 1) {
          const file_id = logoFile ? await uploadLogo(logoFile) : undefined;
          const c = {
            ...payload,
            file_id,
          };
          const resp = await (payload.id ? updateCustomer(c as Customer) : createCustomer(c as NewCustomer));
          setValues(resp);
        }
        if (current === 2) props.onFinish();
        else setCurrent(current + 1);
      } finally {
        setLoading(false);
      }
    }
  };

  const validateAndSubmit = () => {
    if (stepRef.current?.isValid()) {
      const payload = stepRef.current.getData();
      setValues(payload);
      onSubmit(payload);
    }
  };

  const goToStep = (step: number) => {
    if (stepRef.current) {
      setValues(stepRef.current.getData());
      if (stepRef.current.isValid() && ((step === current + 1 && step < 2) || props.customer?.id)) {
        setCurrent(step);
      } else if (step < current) {
        setCurrent(step);
      } else if (step === current + 1 && step === 2) {
        toast.error("Merci de valider le formulaire pour passer à l'étape suivante");
      }
    }
  };

  const CurrentForm = [BasicStepForm, BillingStepForm, ContactStepForm][current];

  const getButtonTitle = (step: number) => {
    if (step === 0) return "Suivant";
    else if (step === 1) return "Enregistrer & Suivant";
    else if (step === 2) return "Terminé";
  };

  return (
    <>
      <Steps light steps={customerFormSteps} currentStep={current} onClick={goToStep} />
      <div className="bg-inherit mt-2 py-2 pr-4 overflow-auto">
        <div className="bg-inherit my-2 pt-2">
          <CurrentForm
            setDisabled={setDisabled}
            ref={stepRef}
            values={values}
            uploadLogo={setLogoFile}
            logoFile={logoFile}
          />
        </div>
        <div className="flex justify-end mt-6">
          <Button
            loading={loading}
            disabled={disabled}
            title={getButtonTitle(current)}
            light
            size="small"
            onClick={() => validateAndSubmit()}
          />
        </div>
      </div>
    </>
  );
};

export default CustomerForm;
