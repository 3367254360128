import React, { FunctionComponent, useEffect, useRef } from "react";
import store from "../../services/store";
import { JWT } from "../../models/auth";
import { useState } from "react";
import { Notification, MentionedPayload, getNotificationWs } from "../../models/notifications";
import { Link } from "react-router-dom";
import md5 from "md5";
import { BellIcon, CheckIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { Button, Image } from "@getprorecrutement/getpro-design";
import { UUID } from "../../models/common";
import classNames from "classnames";
import { DEFAULT_PROFILE_PIC } from "../../assets/profilePicFallback";

const notif = (notif: Notification) => {
  switch (notif.kind) {
    case "job_offer_assignation":
      return (
        <div className="assignation notif">
          L'offre <Link to={`/jobs/${notif.target_id}`}>{notif.value}</Link> vous a été assigné
        </div>
      );
    case "incoming_candidates":
      return (
        <div className="incomingCandidates notif">
          {notif.value === "1" ? (
            <>
              <span>{notif.value}</span> candidat a postulé sur vos offres
            </>
          ) : (
            <>
              <span>{notif.value}</span> candidats ont postulé sur vos offres
            </>
          )}
        </div>
      );
    case "mentioned": {
      const value = JSON.parse(notif.value as string) as MentionedPayload;
      if (value.jo_id && value.jo_name)
        return (
          <div className="mentioned">
            {value.mentioner} vous a mentionné dans un commentaire sur la candidature de{" "}
            <Link to={`/jobs/${value.jo_id}?candidateId=${value.candidate_id}`}>{value.candidate}</Link> au poste de{" "}
            <Link to={`/jobs/${value.jo_id}`}>{value.jo_name}</Link>
          </div>
        );
      const params = new URLSearchParams();
      params.set("query", JSON.stringify({ id: value.candidate_id }));
      return (
        <div className="mentioned">
          {value.mentioner} vous a mentionné dans un commentaire sur le profil de{" "}
          <Link to={`/candidates?${params}`}>{value.candidate}</Link>
        </div>
      );
    }
    case "text":
      return <div className="text">{notif.value}</div>;
    case "candidate_milestone_closed": {
      const value = JSON.parse(notif.value as string);
      const params = new URLSearchParams();
      params.set("query", JSON.stringify({ id: value.candidate_id }));
      return (
        <div className="milestone">
          Félicitations pour le closing de <Link to={`/candidates?${params}`}>{value.candidate_name}</Link> au poste de{" "}
          <Link to={`/jobs/${value.job_offer_id}`}>{value.job_offer_name}</Link>
        </div>
      );
    }
    case "candidate_milestone_first_day": {
      const value = JSON.parse(notif.value as string);
      const params = new URLSearchParams();
      params.set("query", JSON.stringify({ id: value.candidate_id }));
      return (
        <div className="milestone">
          <Link to={`/candidates?${params}`}>{value.candidate_name}</Link> commence son poste de{" "}
          <Link to={`/jobs/${value.job_offer_id}`}>{value.job_offer_name}</Link> demain, un message d'encouragement ?
        </div>
      );
    }
    case "candidate_milestone_ten_days": {
      const value = JSON.parse(notif.value as string);
      const params = new URLSearchParams();
      params.set("query", JSON.stringify({ id: value.candidate_id }));
      return (
        <div className="milestone">
          <Link to={`/candidates?${params}`}>{value.candidate_name}</Link> a commencé son poste de{" "}
          <Link to={`/jobs/${value.job_offer_id}`}>{value.job_offer_name}</Link> depuis 10 jours, pense à prendre de ses
          nouvelles ;)
        </div>
      );
    }
    case "candidate_milestone_forty_days": {
      const value = JSON.parse(notif.value as string);
      const params = new URLSearchParams();
      params.set("query", JSON.stringify({ id: value.candidate_id }));
      return (
        <div className="milestone">
          <Link to={`/candidates?${params}`}>{value.candidate_name}</Link> a commencé son poste de{" "}
          <Link to={`/jobs/${value.job_offer_id}`}>{value.job_offer_name}</Link> depuis plus d'1 mois, pense à prendre
          de ses nouvelles ;)
        </div>
      );
    }
    case "candidate_milestone_three_months": {
      const value = JSON.parse(notif.value as string);
      const params = new URLSearchParams();
      params.set("query", JSON.stringify({ id: value.candidate_id }));
      return (
        <div className="milestone">
          <Link to={`/candidates?${params}`}>{value.candidate_name}</Link> ne va pas tarder à valider sa période d'essai
          sur son poste de <Link to={`/jobs/${value.job_offer_id}`}>{value.job_offer_name}</Link>, pense à le contacter
          :)
        </div>
      );
    }
    case "candidate_milestone_six_months": {
      const value = JSON.parse(notif.value as string);
      const params = new URLSearchParams();
      params.set("query", JSON.stringify({ id: value.candidate_id }));
      return (
        <div className="milestone">
          <Link to={`/candidates?${params}`}>{value.candidate_name}</Link> a rejoint le poste de{" "}
          <Link to={`/jobs/${value.job_offer_id}`}>{value.job_offer_name}</Link> depuis 6 mois, tout se passe bien ?
        </div>
      );
    }

    case "candidate_milestone_validated": {
      const value = JSON.parse(notif.value as string);
      const params = new URLSearchParams();
      params.set("query", JSON.stringify({ id: value.candidate_id }));
      return (
        <div className="milestone">
          Votre candidat <Link to={`/candidates?${params}`}>{value.candidate_name}</Link> a dépassé la période de
          garantie pour le poste de <Link to={`/jobs/${value.job_offer_id}`}>{value.job_offer_name}</Link> !
        </div>
      );
    }
    case "candidate_milestone_lost": {
      const value = JSON.parse(notif.value as string);
      const params = new URLSearchParams();
      params.set("query", JSON.stringify({ id: value.candidate_id }));
      return (
        <div className="milestone">
          Votre candidat <Link to={`/candidates?${params}`}>{value.candidate_name}</Link> a quitté le poste de{" "}
          <Link to={`/jobs/${value.job_offer_id}`}>{value.job_offer_name}</Link> avant la fin de la période de garantie
        </div>
      );
    }

    default:
      return <div className="text">{notif.value}</div>;
  }
};

export const Avatar: FunctionComponent<{
  user: Partial<JWT>;
  size?: "large" | "small" | "medium";
  onClick?: () => void;
  withName?: boolean;
}> = (props) => {
  const size = props.size || "medium";

  const iconClasses = classNames("rounded-full", {
    "max-w-[16px] max-h-[16px]": size === "small",
    "max-w-[24px] max-h-[24px]": size === "medium",
    "max-w-[32px] max-h-[32px]": size === "large",
  });

  return (
    <div className="avatar" onClick={props.onClick}>
      <Image
        src={props.user ? `https://www.gravatar.com/avatar/${md5(props.user.email || "")}?d=mp` : DEFAULT_PROFILE_PIC}
        fallback={DEFAULT_PROFILE_PIC}
        className={iconClasses}
      />
      {props.withName && (
        <div className="userName">
          {props.user.first_name} {props.user.last_name}
        </div>
      )}
    </div>
  );
};

export const UserStatus: FunctionComponent = () => {
  const [notifs, setNotifs] = useState<Notification[]>([]);
  const [open, setOpen] = useState(false);
  const [unreadCount, setUnreadCount] = useState(0);

  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (store.state.RawJWT) {
      const notfiWs = getNotificationWs(store.state.RawJWT);

      notfiWs.addEventListener("message", function ({ data }: { data: string }) {
        const notifications: Notification[] = JSON.parse(data);
        setNotifs((n) => [...n, ...notifications]);
      });

      return () => notfiWs.close();
    }
  }, []);

  useEffect(() => {
    setUnreadCount(notifs.filter((n) => !n.read_at).length);
  }, [notifs]);

  useEffect(() => {
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  const handleClickOutside = (event: MouseEvent) => {
    if (wrapperRef.current && !wrapperRef?.current?.contains(event.target as Node)) {
      setOpen(false);
      setNotifs((notifs) => notifs.map((n) => ({ ...n, read_at: new Date().toString() })));
    }
  };

  const toggleNotifs = () => {
    const willOpen = !open;
    setOpen(willOpen);

    if (willOpen) {
      // readNotifications();
      // setUnread(0);
    }
  };

  const readNotification = (id: UUID) => {
    setNotifs((val) => val.map((e) => (e.id === id ? { ...e, read_at: "READ" } : e)));
  };

  const sorted = (notifsArr: Notification[]) => {
    return notifsArr.sort(
      (a, b) =>
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (new Date(b.created_at) as any) - (new Date(a.created_at) as any)
    );
  };

  const notifClasses = (read_at?: string) =>
    classNames("flex items-center p-4 min-h-[50px] first:border-t border-b border-solid border-border-medium", {
      "bg-background-medium": !read_at,
    });

  const badgeClasses = (read_at?: string) =>
    classNames("mx-4 w-2 h-2 rounded-full border border-solid border-border-light", {
      "bg-background-dark": read_at,
      "bg-background-bright": !read_at,
    });

  return (
    <div ref={wrapperRef} className="h-full float-right justify-center flex-1 flex items-center userStatus">
      <div className="relative w-fit h-fit cursor-pointer" onClick={toggleNotifs}>
        {unreadCount > 0 && (
          <div className="absolute -top-2.5 -right-2.5 min-w-[20px] h-5 px-1 w-fit flex items-center justify-center bg-blue-400 text-white rounded-full text-sm">
            {unreadCount > 99 ? "99+" : unreadCount}
          </div>
        )}
        <BellIcon className={"text-content-medium"} width={20} />
      </div>
      {open && (
        <div className="bg-background-dark rounded-3xl absolute w-[600px] left-[228px] bottom-5 z-[100] overflow-hidden">
          <div className="max-h-[300px]">
            <div className="flex justify-between items-center px-6 py-2 h-20">
              <div className="text-xl text-content-bright">Notifications</div>
              <div>
                <Button kind="light" title="Tout marquer comme lu" />
              </div>
            </div>
            <div className="overflow-auto max-h-[220px] h-fit">
              {sorted(notifs).map((n) => (
                <div key={n.id} className={notifClasses(n.read_at)}>
                  <div className="w-3/4 flex items-center">
                    <div className={badgeClasses(n.read_at)} />
                    {notif(n)}
                  </div>
                  {!n.read_at && (
                    <div className="w-1/4">
                      <NotificationActions id={n.id} readNotification={readNotification} />
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

interface NotificationActionsProps {
  id: UUID;
  readNotification: (id: UUID) => void;
}

export const NotificationActions: FunctionComponent<NotificationActionsProps> = ({ id, readNotification }) => {
  return (
    <div className="w-full flex gap-4 justify-end">
      <div
        onClick={() => readNotification(id)}
        className="w-6 h-6 cursor-pointer rounded-full bg-background-bright flex justify-center items-center"
      >
        <CheckIcon width={16} className="text-content-dark" />
      </div>
      <div className="w-6 h-6 cursor-pointer rounded-full border border-solid border-border-light flex justify-center items-center">
        <XMarkIcon width={16} className="text-content-bright" />
      </div>
    </div>
  );
};

export default UserStatus;
