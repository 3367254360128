import httpClient from "./http/client";
import ROUTES from "./http/routes";
import { Status } from "../models/jobOffers";
import { UUID } from "../models/common";
import toast from "react-hot-toast";
import store from "./store";

export function notifyError(error: string | undefined) {
  toast.error(error || "Erreur inconnue");
}

export async function getStatuses(): Promise<Status[]> {
  return store.cached("statuses", 60 * 30, () =>
    httpClient.req(ROUTES.FETCH_STATUSES(), (err) => notifyError(err || "Erreur lors de la récupération des status"))
  );
}

export async function getCallStatuses(): Promise<Status[]> {
  return store.cached("callStatuses", 60 * 30, () => httpClient.req(ROUTES.FETCH_CALL_STATUSES()));
}

export async function getSources(): Promise<{ id: UUID; name: string }[]> {
  return store.cached("sources", 60 * 30, () => httpClient.req(ROUTES.FETCH_SOURCES()));
}

// GMAIL

export async function getGmailEmailSignature(email: string, token: string): Promise<{ signature: string }> {
  return httpClient.req(ROUTES.GET_GMAIL_EMAIL_SIGNATURE(email, token), notifyError);
}

// EMAILS

export async function getJaEmails(jaId: UUID): Promise<{ subject: string; content: string; thread_id: string }[]> {
  return httpClient.req(ROUTES.GET_JA_EMAILS(jaId), (err) =>
    notifyError(err || "Erreur lors de la récupération des mails de la candidature")
  );
}

export async function getThread(threadId: UUID): Promise<{ messages: { payload: unknown }[] }> {
  return httpClient.req(ROUTES.GET_THREAD(threadId), notifyError);
}
