import React, { FunctionComponent } from "react";
import { FullJobOffer, MinimumValidation, QuestionType, RangeValidation } from "../../models/jobOffers";
import { Answer } from "../../models/jobApplications";
import { CheckIcon, XMarkIcon } from "@heroicons/react/24/outline";

interface Props {
  jobOffer: FullJobOffer;
  answers: Answer[];
}

const JobAppInfosAnswers: FunctionComponent<Props> = ({ jobOffer, answers }) => {
  const getAnswerValue = (answer: Answer) => {
    if (answer.target.kind === QuestionType.Bool) {
      return (
        <div>
          {answer.value ? (
            <CheckIcon className="w-5 h-5 min-w-fit text-green-500" />
          ) : (
            <XMarkIcon className="w-5 h-5 min-w-fit text-red-500" />
          )}
        </div>
      );
    } else if (answer.target.kind === QuestionType.Lang) {
      return <div>{["Aucun", "Notions", "Intermédiaire", "Courant", "Bilingue"][answer.value as number]}</div>;
    } else if (answer.target.kind === QuestionType.Skill) {
      return <div>{["Aucun", "Notions", "Intermédiaire", "Maîtrise", "Expert"][answer.value as number]}</div>;
    } else if (answer.target.kind === QuestionType.Size) {
      return <div>{["0", "1-3", "3-5", "5-10", "10 et plus"][answer.value as number]}</div>;
    } else if (answer.target.kind === QuestionType.Salary) {
      return <div>{answer.value as number} €</div>;
    } else if (answer.target.kind === QuestionType.Experience) {
      return <div>{answer.value as number} année(s)</div>;
    }
  };

  const getRequiredValue = (answer: Answer) => {
    const question = jobOffer.questions.find((q) => q.question === answer.target.question);

    if (question) {
      if (question.kind === QuestionType.Lang)
        return ["Aucun", "Notions", "Intermédiaire", "Courant", "Bilingue"][
          (question.validation as MinimumValidation).value as number
        ];
      else if (question.kind === QuestionType.Skill)
        return ["Aucun", "Notions", "Intermédiaire", "Maîtrise", "Expert"][
          (question.validation as MinimumValidation).value as number
        ];
      else if (question.kind === QuestionType.Size)
        return ["0", "1-3", "3-5", "5-10", "10 et plus"][(question.validation as MinimumValidation).value as number];
      else if (question.kind === QuestionType.Salary) return `${(question.validation as RangeValidation).max} €`;
      else if (question.kind === QuestionType.Experience)
        return `${(question.validation as MinimumValidation).value} année(s)`;
    }
  };

  return (
    <div className="flex flex-col gap-2">
      <div className="font-bold inline-block">Réponses au questionnaire</div>
      {answers.map((elem, index) => {
        const answerValue = getAnswerValue(elem);
        const requiredValue = elem.target.kind !== QuestionType.Bool ? getRequiredValue(elem) : undefined;

        return (
          <div key={`ja-${index}`} className="mb-2">
            <div className="pb-1">
              <div>{elem.target.question.replaceAll("[:value:]", "")}</div>
            </div>
            <div className="flex items-center gap-2">
              <div>Réponse :</div>
              <div>{answerValue}</div>
              {requiredValue && <div>(Requis : {requiredValue})</div>}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default JobAppInfosAnswers;
