import React, { useEffect, useState } from "react";
import { generateBill, getBill } from "../../../../models/billing/bill";
import { UUID } from "../../../../models/common";
import { downloadFile } from "../../../../models/misc";
import { sleep } from "../../../../utils";
import store from "../../../../services/store";
import httpClient from "../../../../services/http/client";
import ROUTES from "../../../../services/http/routes";
import { Tooltip, Button, Modal } from "@getprorecrutement/getpro-design";
import { ArrowDownOnSquareIcon, DocumentIcon } from "@heroicons/react/24/outline";

interface Props {
  id: UUID;
  fileId?: UUID;
  billRef?: string;
}

export const PdfBtn = (props: Props) => {
  const [loading, setLoading] = useState(false);
  const [fileId, setFileId] = useState<UUID | undefined>(props.fileId);
  const [billRef, setBillRef] = useState<string | undefined>(props.billRef);
  const [preview, setPreview] = useState<string>();

  useEffect(() => {
    setFileId(props.fileId);
    setBillRef(props.billRef);
  }, [props.fileId, props.billRef]);

  const { id } = props;

  const _generateBill = async (billId: UUID) => {
    try {
      setLoading(true);
      await generateBill(billId);
      // Wait at most 20 seconds
      for (let i = 0; i < 21; i++) {
        await sleep(1000);
        const bill = await getBill(id);
        if (bill.file_id) {
          setFileId(bill.file_id);
          setBillRef(bill.bill_ref);
          await downloadFile(bill.file_id, "getpro-bills");
          setPreview(undefined);
          return;
        }
      }
    } finally {
      setLoading(false);
    }
  };

  const showPreview = async () => {
    if (store.state.RawJWT) {
      const preview = httpClient.genUrlWithParams(ROUTES.PREVIEW_BILL(id, store.state.RawJWT));
      setPreview(preview);
    }
  };

  return (
    <div className="PdfBtn">
      {fileId ? (
        <Tooltip light title={"Télécharger la facture en PDF"}>
          <Button
            kind="outline"
            size="small"
            title={billRef || ""}
            light
            loading={loading}
            icon={<DocumentIcon />}
            onClick={() => {
              setLoading(true);
              downloadFile(fileId, "getpro-bills").finally(() => setLoading(false));
            }}
          />
        </Tooltip>
      ) : (
        <Tooltip light title={"Générer la facture en PDF"}>
          <Button kind="light" className="p-0" light icon={<ArrowDownOnSquareIcon />} onClick={showPreview} />
        </Tooltip>
      )}
      <Modal className="w-[928px]" show={!!preview} onClose={() => setPreview(undefined)} light>
        <iframe style={{ marginTop: 32 }} frameBorder={0} width={831} height={1154} src={preview}></iframe>
        <div className="flex justify-end mt-6">
          <Button loading={loading} size="small" title="Générer la facture" onClick={() => _generateBill(id)} />
        </div>
      </Modal>
    </div>
  );
};

export default PdfBtn;
