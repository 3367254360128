import React from "react";
import { FunctionComponent } from "react";
import { UUID } from "../../../models/common";
import CountValue from "./countValue";

interface Props {
  summary: { [name: string]: number };
  comparison?: { [name: string]: number };
  statuses: { id: UUID; name: string }[];
  selectedIds: UUID[];
  colors: string[];
}

export const DashboardStats: FunctionComponent<Props> = (props) => {
  const { summary, comparison, statuses, selectedIds, colors } = props;

  return (
    <div
      style={{
        height: 285,
        fontSize: 20,
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <div className="flex justify-around flex-wrap">
        {(selectedIds.length === 0 ? statuses : statuses.filter((s) => selectedIds.includes(s.id))).map((e, i) => (
          <CountValue
            color={colors[i]}
            compare_value={comparison && comparison[e.id]}
            value={summary[e.id] || 0}
            key={i}
            title={e.name}
          />
        ))}
      </div>
    </div>
  );
};

export default DashboardStats;
