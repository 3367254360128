import React, { FunctionComponent, useEffect } from "react";
import { toast } from "react-hot-toast";
import { useState } from "react";
import ExportJas from "./exportJas";
import { UUID } from "../models/common";
import { updateJo, createJo, JobOffer, JobOfferData } from "../models/jobOffers";
import { getProject, NewProject } from "../models/billing/project";
import { Button, Modal, MoreActions, Select } from "@getprorecrutement/getpro-design";
import { useNavigate } from "react-router-dom";
import {
  ArchiveBoxIcon,
  ArchiveBoxXMarkIcon,
  DocumentDuplicateIcon,
  EyeIcon,
  EyeSlashIcon,
  FolderArrowDownIcon,
  FolderOpenIcon,
  PencilIcon,
  PlusIcon,
  ShareIcon,
} from "@heroicons/react/24/outline";
import { GFile, GFileKind } from "../models/gfiles";
import { generateGdriveUrl } from "../pages/jobOffer";
import { Action } from "@getprorecrutement/getpro-design/dist/components/MoreActions";
import NewCandidateForm from "./forms/newCandidateForm";
import { JobApplicationResponse } from "../models/jobApplications";
import qs from "qs";

export enum JobOfferAction {
  NewCandidate = "NewCandidate",
  Archive = "Archive",
  Publish = "Publish",
  Edit = "Edit",
  Export = "Export",
  Duplicate = "Duplicate",
  GoToProject = "GoToProject",
  GoToJobBoard = "GoToJobBoard",
}
interface Props {
  jobOffer: JobOffer;
  onUpdate?: () => void;
  onEdit?: () => void;
  ignoredActions?: JobOfferAction[];
  gfilesByKind?: { [key in GFileKind]?: GFile };
  createGFile?: (joId: UUID, kind: GFileKind) => void;
}

const ARCHIVED_REASONS = [
  { label: "Candidat embauché", value: "won" },
  { label: "Poste fermé par Getpro", value: "cancelled_by_us" },
  { label: "Poste fermé par le client", value: "cancelled_by_customer" },
  { label: "Poste fermé pourvu par le client", value: "customer_won" },
  { label: "Poste fermé pourvu par d'autres cabinets", value: "competitor_won" },
];

const regExpDuplicate = (name: string) => {
  const regExp = / [#]\d+/i;
  const regMatch = name.match(regExp);
  const index = regMatch ? parseInt(regMatch[0].replace("#", "").trim()) + 1 : 1;
  const newName = name.replace(/\s+/g, " ").trim();
  return regMatch ? newName.replace(regMatch[0], " #" + index) : newName + " #1";
};

export const JobOfferActions: FunctionComponent<Props> = (props) => {
  const [exportJas, setExportJas] = useState<boolean>(false);
  const [duplicate, setDuplicate] = useState<boolean>(false);
  const [isArchiving, setIsArchiving] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [archivedReason, setArchivedReason] = useState<string>();
  const [addNewCandidate, setAddNewCandidate] = useState<boolean>(false);
  const [actions, setActions] = useState<Action[]>();

  const navigate = useNavigate();

  useEffect(() => {
    setActions(getActions(props.jobOffer));
  }, [props.jobOffer, props.gfilesByKind]);

  const archive = async () => {
    setIsArchiving(false);

    try {
      setLoading(true);
      await updateJo({
        archived_reason: archivedReason,
        id: props.jobOffer.id,
        archived: true,
      });
      props.onUpdate && props.onUpdate();
      toast.success("Offre archivée avec succès");
    } finally {
      setLoading(false);
    }
  };

  const update = async (data: JobOfferData & { id: UUID }) => {
    updateJo(data).then(() => props.onUpdate && props.onUpdate());
  };

  const duplicateJo = async () => {
    const error = "Erreur lors de la duplication de l'offre";
    const jo = {
      ...props.jobOffer,
      name: regExpDuplicate(props.jobOffer.name),
      business_unit_id: props.jobOffer.owner.business_unit_id,
    };
    const saveProject = props.jobOffer.project_id ? await getProject(props.jobOffer.project_id) : undefined;
    const res = await createJo(
      {
        job_offer: jo,
        project: saveProject
          ? {
              ...(saveProject as NewProject),
              name: regExpDuplicate(saveProject.name),
            }
          : undefined,
      },
      error
    );
    const joPayload = {
      id: res.id,
      questions: jo.questions,
      email_template_body: jo.email_template_body,
      email_template_subject: jo.email_template_subject,
    };

    await updateJo(joPayload, error);
    props.onUpdate && props.onUpdate();
    toast.success("Offre dupliquée avec succès");
  };

  const gdriveAction = (gfilesByKind: { [key in GFileKind]?: GFile }, kind: GFileKind, title: string) => {
    return {
      title: gfilesByKind[kind] ? title.charAt(0).toUpperCase() + title.slice(1) : "Créer " + title,
      action: () => {
        const file = gfilesByKind[kind];
        if (file) window.open(generateGdriveUrl(file), "_blank");
        else props.createGFile && props.createGFile(props.jobOffer.id, kind);
      },
    };
  };

  const getActions = (jo: JobOffer) => {
    const actions: Action[] = [];
    const ignored = props.ignoredActions;

    const gfileActions = props.gfilesByKind && {
      title: "Gdrive",
      icon: <FolderOpenIcon />,
      subElems: [
        gdriveAction(props.gfilesByKind, GFileKind.Folder, "dossier gdrive"),
        gdriveAction(props.gfilesByKind, GFileKind.Kickoff, "support de réunion de lancement"),
        gdriveAction(props.gfilesByKind, GFileKind.ScoreCard, "grille de recherche"),
        gdriveAction(props.gfilesByKind, GFileKind.Teaser, "fiche de poste & teaser"),
        gdriveAction(props.gfilesByKind, GFileKind.Report, "bilan de fin de mandat"),
      ],
    };

    !ignored?.includes(JobOfferAction.NewCandidate) &&
      actions.push({
        icon: <PlusIcon />,
        title: "Nouveau candidat",
        action: () => setAddNewCandidate(true),
      });

    !ignored?.includes(JobOfferAction.Archive) &&
      actions.push({
        icon: jo.archived ? <ArchiveBoxXMarkIcon /> : <ArchiveBoxIcon />,
        title: jo.archived ? "Désarchiver" : "Archiver",
        action: () => (jo.archived ? update({ id: jo.id, archived: false }) : setIsArchiving(true)),
      });

    !ignored?.includes(JobOfferAction.Publish) &&
      actions.push({
        icon: jo.published ? <EyeSlashIcon /> : <EyeIcon />,
        title: jo.published ? "Dépublier" : "Publier",
        action: () => update({ id: jo.id, published: !jo.published }),
      });

    !ignored?.includes(JobOfferAction.Edit) &&
      props.onEdit &&
      actions.push({
        title: "Éditer",
        icon: <PencilIcon />,
        action: props.onEdit,
      });

    !ignored?.includes(JobOfferAction.Export) &&
      actions.push({
        title: "Exporter les candidats",
        icon: <FolderArrowDownIcon />,
        action: () => setExportJas(true),
      });

    !ignored?.includes(JobOfferAction.Duplicate) &&
      actions.push({
        title: "Dupliquer",
        icon: <DocumentDuplicateIcon />,
        action: () => setDuplicate(true),
      });

    !ignored?.includes(JobOfferAction.GoToProject) &&
      actions.push({
        title: "Voir le projet",
        icon: <EyeIcon />,
        disabled: !jo.project_id,
        action: () => navigate(`/billing/projects/${jo.project_id}`),
      });

    !ignored?.includes(JobOfferAction.GoToJobBoard) &&
      jo.published &&
      actions.push({
        icon: <ShareIcon />,
        title: "Voir sur la job board",
        action: () => {
          window.open(`https://jobs.getpro.co/jobs/${jo.slug}`, "_blank");
        },
      });

    gfileActions && actions.push(gfileActions);
    return actions;
  };

  return (
    <div className="jobOfferActions">
      {actions && <MoreActions actions={actions} light />}
      <Modal light show={addNewCandidate} onClose={() => setAddNewCandidate(false)}>
        <NewCandidateForm
          onFinish={(jobApp: JobApplicationResponse) => {
            setAddNewCandidate(false);
            navigate({
              pathname: `/jobs/${props.jobOffer.id}`,
              search: qs.stringify({ statusIds: [jobApp.status_id] }),
            });
          }}
          jobOfferId={props.jobOffer.id as UUID}
        />
      </Modal>
      <Modal light show={duplicate} onClose={() => setDuplicate(false)}>
        <div className="text-center px-10">
          <div className="text-2xl">Voulez-vous dupliquer l'offre ?</div>
          <div className="text-lg mt-4">
            Vérifier qu'un projet est existant sur l'offre si cela est nécessaire dans votre cas !
          </div>
        </div>
        <div className="mt-8 flex justify-center gap-6">
          <Button light kind="outline" size="small" title="Annuler" onClick={() => setDuplicate(false)} />
          <Button light size="small" title="Dupliquer" onClick={duplicateJo} />
        </div>
      </Modal>
      <Modal className="w-modal-xl" light show={exportJas} onClose={() => setExportJas(false)}>
        <ExportJas onFinish={() => setExportJas(false)} jobOffer={props.jobOffer} />
      </Modal>
      <Modal className="w-modal-md" light show={isArchiving} onClose={() => setIsArchiving(false)}>
        <div className="mb-2 text-2xl">Informations complémentaires</div>
        <div className="py-2 pr-4 bg-inherit overflow-auto">
          <div className="bg-inherit">
            <Select
              bordered
              rounded
              light
              type="single"
              label="Raison d'archivage"
              placeholder="Sélectionner la raison"
              options={ARCHIVED_REASONS}
              value={ARCHIVED_REASONS.find((e) => e.value === archivedReason)}
              optionRender={(item) => item.label}
              getKey={(item) => item.value}
              onChange={(item) => item && setArchivedReason(item.value)}
              onSearch={undefined}
            />
          </div>
          <div className="flex justify-end mt-8">
            <Button
              light
              size="small"
              loading={loading}
              title="Enregistrer"
              onClick={archive}
              disabled={!archivedReason}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default JobOfferActions;
