import dayjs from "dayjs";
import React, { useState } from "react";
import { FunctionComponent } from "react";
import { UUID } from "../../models/common";
import { DatePeriod, GlobalFilters } from "../../models/dashboards";
import BusinessUnitSelect from "../forms/inputs/businessUnitSelect";
import CustomerSelect from "../forms/inputs/customerSelect";
import JobOfferSelect from "../forms/inputs/jobOfferSelect";
import UserSelect from "../forms/inputs/userSelect";
import { useDeepCompareEffect } from "react-use";
import { DateRange, Radio, Select } from "@getprorecrutement/getpro-design";
import { capitalize } from "../../utils/formatters";

interface Props {
  users?: UUID[];
  businessUnits?: UUID[];
  jobOffers?: UUID[];
  period?: DatePeriod;
  customers?: UUID[];
  dateRange?: { from?: string; to?: string };
  onFilter: (data: GlobalFilters, forceRange?: boolean) => void;
}

const DATE_RANGE_OPTS: {
  label: string;
  value: DatePeriod;
  getPeriod: () => {
    from: string;
    to?: string;
  };
}[] = [
  {
    label: "Hier",
    value: DatePeriod.Yesterday,
    getPeriod: () => ({
      from: dayjs().subtract(1, "day").format("DD/MM"),
    }),
  },
  {
    label: "Aujourd'hui",
    value: DatePeriod.Today,
    getPeriod: () => ({
      from: dayjs().format("DD/MM"),
    }),
  },
  {
    label: "Cette semaine",
    value: DatePeriod.Week,
    getPeriod: () => ({
      from: dayjs().weekday(0).format("DD/MM"),
      to: dayjs().weekday(6).format("DD/MM"),
    }),
  },
  {
    label: "Semaine dernière",
    value: DatePeriod.LastWeek,
    getPeriod: () => ({
      from: dayjs().subtract(1, "week").weekday(0).format("DD/MM"),
      to: dayjs().subtract(1, "week").weekday(6).format("DD/MM"),
    }),
  },
  {
    label: "Ce mois",
    value: DatePeriod.Month,
    getPeriod: () => ({
      from: capitalize(dayjs().startOf("month").format("MMM")),
    }),
  },
  {
    label: "Mois dernier",
    value: DatePeriod.LastMonth,
    getPeriod: () => ({
      from: capitalize(dayjs().subtract(1, "month").date(1).format("MMM")),
    }),
  },
  {
    label: "Ce trimestre",
    value: DatePeriod.Quarter,
    getPeriod: () => ({
      from: capitalize(dayjs().startOf("quarter").format("MMM")),
      to: capitalize(dayjs().endOf("quarter").format("MMM")),
    }),
  },
  {
    label: "Trimestre dernier",
    value: DatePeriod.LastQuarter,
    getPeriod: () => ({
      from: capitalize(dayjs().subtract(1, "quarter").startOf("quarter").format("MMM")),
      to: capitalize(dayjs().subtract(1, "quarter").endOf("quarter").format("MMM")),
    }),
  },
  {
    label: "Cette année",
    value: DatePeriod.Year,
    getPeriod: () => ({
      from: dayjs().startOf("year").format("YYYY"),
    }),
  },
  {
    label: "Année dernière",
    value: DatePeriod.LastYear,
    getPeriod: () => ({
      from: dayjs().subtract(1, "year").startOf("year").format("YYYY"),
    }),
  },
  {
    label: "12 derniers mois",
    value: DatePeriod.RollingYear,
    getPeriod: () => ({
      from: `Depuis le ${dayjs().subtract(1, "year").format("DD/MM/YY")}`,
    }),
  },
];

const DashboardFilters: FunctionComponent<Props> = (props) => {
  const { onFilter, ...propsFilters } = props;
  const [filters, _setFilters] = useState<GlobalFilters>(props);
  const [dateDisplay, setDateDisplay] = useState<"date" | "period">("period");

  const addFilters = (data: Partial<GlobalFilters>) => {
    const newFilters = { ...filters, ...data };
    onFilter(data.dateRange ? newFilters : { ...newFilters, dateRange: undefined });
  };

  useDeepCompareEffect(() => {
    _setFilters(propsFilters);
  }, [propsFilters]);

  return (
    <div className="bg-inherit ">
      <div className="flex gap-x-2 gap-y-4 w-full mb-3 bg-inherit flex-wrap">
        <div className="bg-inherit flex-1 min-w-[200px]">
          <UserSelect
            multiple
            placeholder={"Utilisateur(s)"}
            label="Utilisateur(s)"
            value={filters?.users}
            showSearch
            onSelect={(id) => {
              filters.users?.includes(id)
                ? addFilters({
                    users: (filters.users || []).filter((userId) => userId !== id),
                  })
                : addFilters({
                    users: [...(filters?.users || []), id],
                  });
            }}
          />
        </div>
        <div className="bg-inherit flex-1 min-w-[200px]">
          <BusinessUnitSelect
            multiple
            placeholder={"Département(s)"}
            label="Département(s)"
            value={filters.businessUnits}
            onSelect={(buId) => {
              filters.businessUnits?.includes(buId)
                ? addFilters({
                    businessUnits: (filters.businessUnits || []).filter((id) => id !== buId),
                  })
                : addFilters({
                    businessUnits: [...(filters?.businessUnits || []), buId],
                  });
            }}
          />
        </div>
        <div className="bg-inherit flex-1 min-w-[200px]">
          <JobOfferSelect
            multiple
            includeArchived
            label="Offres"
            placeholder="Offre(s)"
            value={filters.jobOffers}
            customerIds={filters.customers}
            onSelect={(joId) => {
              filters.jobOffers?.includes(joId)
                ? addFilters({ jobOffers: (filters.jobOffers || []).filter((id) => id !== joId) })
                : addFilters({ jobOffers: [...(filters.jobOffers || []), joId] });
            }}
          />
        </div>
        <div className="bg-inherit flex-1 min-w-[200px]">
          <CustomerSelect
            multiple
            showSearch
            placeholder="Client(s)"
            label="Client(s)"
            value={filters.customers}
            onSelect={(cId) =>
              filters.customers?.includes(cId)
                ? addFilters({ customers: (filters.customers || []).filter((id) => id !== cId) })
                : addFilters({ customers: [...(filters.customers || []), cId] })
            }
          />
        </div>
      </div>
      <div className="flex gap-2 min-w-fit bg-inherit">
        <Radio
          options={[
            { label: "Période", value: "period" },
            { label: "Date", value: "date" },
          ]}
          light
          optionType="button"
          value={dateDisplay}
          onChange={(v) => setDateDisplay(v as "date" | "period")}
        />
        {dateDisplay === "date" && (
          <div>
            <DateRange
              light
              value={filters.dateRange}
              onChange={(val) =>
                addFilters({
                  dateRange: val.from && val.to ? (val as { from: string; to: string }) : undefined,
                })
              }
            />
          </div>
        )}
        {dateDisplay === "period" && (
          <div className=" bg-inherit">
            <Select
              options={DATE_RANGE_OPTS}
              light
              bordered
              rounded
              label="Période"
              type="single"
              optionRender={(item) => {
                const period = item.getPeriod();

                return (
                  <div className="w-full min-w-[300px] flex justify-between items-center">
                    <div>{item.label}</div>
                    <div>{period.from.concat(period.to ? " - ".concat(period.to) : "")}</div>
                  </div>
                );
              }}
              getKey={(item) => item.label}
              onChange={(val) => val && addFilters({ period: val.value })}
              value={DATE_RANGE_OPTS.find((o) => o.value === filters.period)}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default DashboardFilters;
