import React, { FunctionComponent, useEffect, useState } from "react";
import { MetaFormStepProps } from "..";
import { useFormManager } from "../../../../services/form";
import { getCandidateFeedbackTemplate } from "../../../../models/jobOffers";
import { Button, Radio, TextInput } from "@getprorecrutement/getpro-design";
import HtmlEditor from "../../inputs/htmlEditor";
import { sendJaEmail } from "../../../../models/jobApplications";

const FEEDBACK_TEMPLATE_OPTIONS = [
  { label: "Ne pas envoyer", value: "none" },
  { label: "Tutoiement", value: "informal" },
  { label: "Vouvoiement", value: "formal" },
];

export const FeedbackStep: FunctionComponent<MetaFormStepProps> = ({ candidate, jobApp, onSubmit, onCancel }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedTemplate, setSelectedTemplate] = useState<"none" | "informal" | "formal">("none");
  const form = useFormManager<{
    body?: string;
    subject?: string;
    recipient?: string;
  }>({
    defaultState: {
      recipient: candidate.email,
    },
    validations: {
      body: { required: true, message: "Merci de préciser le contenu de l'email" },
      subject: { required: true, message: "Merci de préciser l'objet de l'email" },
      recipient: { required: true, message: "Merci de préciser l'adresse e-mail" },
    },
  });

  const fetchTemplate = async (kind: "none" | "informal" | "formal") => {
    if (!!kind && kind !== "none") {
      const template = await getCandidateFeedbackTemplate(jobApp.id, kind);
      form.onChange({ body: template.body });
      form.onChange({ subject: template.subject });
    } else {
      form.onChange({ body: undefined });
      form.onChange({ subject: undefined });
    }
  };

  const submit = async () => {
    if (selectedTemplate === "none") return onSubmit(candidate, jobApp);
    if (form.validate()) {
      try {
        setLoading(true);
        await sendJaEmail(jobApp.id, {
          email: form.state as { body: string; subject: string; recipient: string },
          ignore_availability: true,
          append_signature: false,
        });
        onSubmit(candidate, jobApp);
      } catch {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchTemplate(selectedTemplate);
  }, [selectedTemplate]);

  return (
    <div className="bg-inherit">
      <div className="flex gap-4 items-center mt-4">
        <div>Demande de feedback :</div>
        <Radio
          light
          optionType="button"
          options={FEEDBACK_TEMPLATE_OPTIONS}
          onChange={(e) => setSelectedTemplate(e as "none" | "informal" | "formal")}
          value={selectedTemplate}
        />
      </div>

      {form.state.subject && form.state.body && (
        <div className="bg-inherit">
          <TextInput
            light
            className="mt-4"
            type="text"
            label="Adresse e-mail"
            placeholder="Adresse e-mail"
            error={form.errors.recipient}
            value={form.state.recipient}
            onChange={(e) => form.onChange({ recipient: e.currentTarget.value })}
          />
          <TextInput
            light
            className="mt-4"
            type="text"
            label="Objet"
            placeholder="Objet"
            error={form.errors.subject}
            value={form.state.subject}
            onChange={(e) => form.onChange({ subject: e.currentTarget.value })}
          />
          <div className="mt-4">
            <HtmlEditor cleanInput content={form.state.body} onChange={(body) => form.onChange({ body })} />
          </div>
        </div>
      )}

      <div className="flex justify-between mt-8">
        <Button size="small" kind="outline" light title="Retour" onClick={onCancel} />
        <Button size="small" light loading={loading} disabled={form.disabled} title="Enregistrer" onClick={submit} />
      </div>
    </div>
  );
};
