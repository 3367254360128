import React, { FunctionComponent, useEffect, useState } from "react";
import { UserResponse, UserData, NewUser } from "../../../models/users/users";
import { BusinessUnit } from "../../../models/businessUnits";
import { getRoles, Role } from "../../../models/roles";
import { TextInput, Button, Select } from "@getprorecrutement/getpro-design";
import BusinessUnitSelect from "../../../components/forms/inputs/businessUnitSelect";
import { UUID } from "../../../models/common";
import { useFormManager } from "../../../services/form";
import { isEmailValid } from "../../../utils";

interface Props {
  user?: UserResponse;
  businessUnits: BusinessUnit[];
  onSubmit: (data: UserData) => Promise<void>;
}

export const UserForm: FunctionComponent<Props> = (props) => {
  const [loading, setLoading] = useState(false);
  const [roles, setRoles] = useState<Role[]>([]);
  // const [data, setData] = useState<Partial<NewUser & { id: UUID }>>(props.user || {});
  const form = useFormManager<NewUser & { id: UUID }>({
    defaultState: props.user || {},
    validations: {
      first_name: {
        required: true,
        message: "Merci de préciser le prénom",
      },
      last_name: {
        required: true,
        message: "Merci de préciser le nom",
      },
      email: {
        required: (val?: string) => !!val && isEmailValid(val),
        message: "Merci de préciser une adresse e-mail correcte",
      },
      role_id: {
        required: true,
        message: "Merci de préciser le rôle",
      },
      title: {
        required: true,
        message: "Merci de préciser le titre",
      },
      business_unit_id: {
        required: true,
        message: "Merci de préciser le département",
      },
    },
  });

  const fetchRoles = async () => {
    getRoles().then(setRoles);
  };

  useEffect(() => {
    fetchRoles();
  }, []);

  const onSubmit = (data: UserData) => {
    setLoading(true);
    props.onSubmit(data).finally(() => setLoading(false));
  };

  return (
    <>
      <div className="mb-2 text-2xl">
        {props.user ? `Éditer ${props.user.first_name} ${props.user.last_name}` : "Nouvel utilisateur"}
      </div>
      <div className="userForm bg-inherit flex flex-col gap-4 py-2 pr-4 overflow-auto">
        <div className="flex items-center bg-inherit">
          <div className="bg-inherit flex-1">
            <TextInput
              type="text"
              value={form.state.first_name}
              error={form.errors.first_name}
              onChange={(e) => form.onChange({ first_name: e.target.value })}
              light
              placeholder="Prénom"
              label="Prénom"
              suffix={<></>}
            />
          </div>
          <div className="bg-inherit flex-1">
            <TextInput
              type="text"
              value={form.state.last_name}
              error={form.errors.last_name}
              onChange={(e) => form.onChange({ last_name: e.target.value })}
              light
              placeholder="Nom"
              label="Nom"
              prefix={<></>}
            />
          </div>
        </div>
        <TextInput
          type="email"
          value={form.state.email}
          error={form.errors.email}
          onChange={(e) => form.onChange({ email: e.target.value })}
          light
          placeholder="Adresse e-mail"
          label="Adresse e-mail"
        />
        <TextInput
          type="text"
          value={form.state.title}
          error={form.errors.title}
          onChange={(e) => form.onChange({ title: e.target.value })}
          light
          placeholder="Titre de poste"
          label="Titre de poste"
        />
        <div className="flex items-center gap-2 bg-inherit">
          <div className="bg-inherit flex-1">
            <BusinessUnitSelect
              businessUnits={props.businessUnits}
              label="Département"
              value={form.state.business_unit_id}
              onSelect={(v) => form.onChange({ business_unit_id: v })}
            />
          </div>
          <div className="bg-inherit flex-1">
            <Select
              options={roles}
              onChange={(v) => v && form.onChange({ role_id: v.id })}
              value={roles.find((v) => v.id === form.state.role_id)}
              getKey={(item) => item.id}
              optionRender={(item) => item.name}
              light
              bordered
              rounded
              label="Rôle"
              type="single"
            />
          </div>
        </div>
        <TextInput
          type="text"
          value={form.state.linkedin_url}
          onChange={(e) => form.onChange({ linkedin_url: e.target.value })}
          light
          placeholder="Linkedin"
          label="Linkedin"
        />
        <TextInput
          type="password"
          value={form.state.password}
          onChange={(e) => form.onChange({ password: e.target.value })}
          light
          placeholder="Mot de passe"
          label="Mot de passe"
        />
        <div className="flex justify-end mt-4">
          <Button
            loading={loading}
            light
            size="small"
            title="Enregister"
            onClick={() => form.validate() && onSubmit(form.state as UserData)}
            disabled={form.disabled}
          />
        </div>
      </div>
    </>
  );
};

export default UserForm;
