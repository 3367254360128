import classNames from "classnames";
import React, { FunctionComponent } from "react";

export interface Props {
  step: number;
  size: number;
  direction?: "vertical" | "horizontal";
}

export const Carousel: FunctionComponent<Props> = ({ step, size, direction = "vertical" }) => {
  return (
    <div className="w-full h-full flex justify-center items-center">
      <div className="relative text-center h-full w-full">
        {Array.from(Array(size)).map((_, i) => {
          const curClasses = classNames("absolute w-full font-bold", {
            "top-[0%] text-[0.7em] carousel-first text-primary-medium": i === step - 3,
            "top-[10%] text-[1em] carousel-before-main text-primary-medium": i === step - 2,
            "top-1/2 text-[1.5em] carousel-main text-primary-bright": i === step - 1,
            "top-[90%] text-[1em] carousel-after-main text-primary-medium": i === step,
            "top-[100%] text-[0.7em] carousel-last text-primary-medium": i === step + 1,
            "top-[0%] text-[0px] carousel-hidden-bottom": i < step - 3,
            "top-[100%] text-[0px] carousel-hidden-top": i > step + 1,
          });

          // const curClasses = classNames("absolute w-full", {
          //   "top-[0%] text-[0.325em] carousel-first": i === step - 3,
          //   "top-[10%] text-[1em] carousel-before-main": i === step - 2,
          //   "top-1/2 text-[1.5em] carousel-main": i === step - 1,
          //   "top-[90%] text-[1em] carousel-after-main": i === step,
          //   "top-[100%] text-[0.325em] carousel-last": i === step + 1,
          //   "top-[0%] text-[0px] carousel-hidden-bottom": i < step - 3,
          //   "top-[100%] text-[0px] carousel-hidden-top": i > step + 1,
          // });

          const curHorizontalClasses = classNames("absolute h-full w-full", {
            "left-[-40%] text-[0.7em] carousel-h-first text-primary-medium flex items-center justify-center":
              i === step - 3,
            "left-[-25%] text-[1em] carousel-h-before-main text-primary-medium flex items-center justify-center":
              i === step - 2,
            "left-0 text-[1.5em] carousel-h-main text-primary-bright flex items-center justify-center": i === step - 1,
            "left-[25%] text-[1em] carousel-h-after-main text-primary-medium flex items-center justify-center":
              i === step,
            "left-[40%] text-[0.7em] carousel-h-last flex text-primary-medium items-center justify-center":
              i === step + 1,
            "left-[-40%] text-[0px] carousel-h-hidden-bottom flex items-center justify-center": i < step - 3,
            "left-[40%] text-[0px] carousel-h-hidden-top flex items-center justify-center": i > step + 1,
          });

          return (
            <div key={i} className={direction === "vertical" ? curClasses : curHorizontalClasses}>
              {i + 1 < 10 && "0"}
              {i + 1}.
            </div>
          );
        })}
      </div>
    </div>
  );
};
