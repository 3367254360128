import React, { FunctionComponent, useEffect, useState } from "react";
import store from "../../../services/store";
import { NewUser, UserResponse, UserData } from "../../../models/users/users";
import UserForm from "./userForm";
import { UUID } from "../../../models/common";
import { Avatar } from "../../../components/userStatus";
import { BusinessUnit, getBusinessUnits } from "../../../models/businessUnits";
import { createUser, updateUser, deleteUser as deleteUserReq, setLockUnlockUser } from "../../../models/users/users";
import { toast } from "react-hot-toast";
import { Button, ColumnProps, Table, MoreActions, Modal, Spinner } from "@getprorecrutement/getpro-design";
import { getUsers } from "../../../models/users/users";
import { getMe } from "../../../models/users/users";
import InlineLabelValue from "../../../components/inlineLabelValue";
import { getRoles, Role } from "../../../models/roles";
import { LockOpenIcon, LockClosedIcon, PencilIcon, TrashIcon } from "@heroicons/react/24/outline";
import LinkedinIcon from "../../../utils/icons";

const columns: ColumnProps<UserResponse>[] = [
  {
    title: "",
    width: 48,
    dataIndex: "id",
    key: "id",
    render: (_, record) => (
      <div className="flex justify-center">
        <Avatar size="large" user={record} />
      </div>
    ),
  },
  {
    title: "Nom",
    dataIndex: "full_name",
    key: "full_name",
    filterBy: "text",
  },
  {
    title: "Titre",
    dataIndex: "title",
    key: "title",
  },
  {
    title: "Adresse e-mail",
    dataIndex: "email",
    key: "email",
    filterBy: "text",
  },
  {
    title: "LinkedIn",
    dataIndex: "linkedin_url",
    key: "linkedin_url",
    width: 80,
    render: (linkedIn) => (
      <div className="flex justify-center">
        {linkedIn && (
          <a onClick={(e) => e.stopPropagation()} href={linkedIn} target="_blank" rel="noreferrer">
            <LinkedinIcon />
          </a>
        )}
      </div>
    ),
  },
];

const PER_PAGE = 10;

export const SettingsUsers: FunctionComponent = () => {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<UserResponse | undefined>(undefined);
  const [users, setUsers] = useState<UserResponse[] | undefined>(undefined);
  const [edit, setEdit] = useState<boolean>(false);
  const [search, setSearch] = useState<{ full_name: string; email: string }>({ full_name: "", email: "" });
  const [businessUnits, setBusinessUnits] = useState<BusinessUnit[]>([]);
  const [roles, setRoles] = useState<Role[]>([]);
  const [page, setPage] = useState<number>(1);

  const fetchUsers = async () => {
    try {
      setLoading(true);
      const me = await getMe();
      setUser(me);
      const users = await getUsers();
      setUsers(users);
    } finally {
      setLoading(false);
    }
  };

  const fetchRoles = async () => {
    getRoles().then(setRoles);
  };

  const fetchBusinessUnits = async () => {
    getBusinessUnits()
      .then(setBusinessUnits)
      .finally(() => setLoading(false));
  };

  const submitUser = async (rawData: UserData): Promise<void> => {
    const data = { ...rawData };

    if (!user?.id) data.password = Math.random().toString(36);

    await (user?.id ? updateUser({ ...data, id: user?.id }) : createUser(data as NewUser));
    toast.success(`Utilisateur ${data.id ? "mis à jour" : "créé"} avec succès`);
    setEdit(false);
    await fetchUsers();
  };

  const lockUnlockUser = async () => {
    const resp = await setLockUnlockUser(user?.id as UUID);

    toast.success(`L'utilisateur à été ${resp?.locked ? "verrouillé" : "déverrouillé"}`);
    await fetchUsers();
  };

  const deleteUser = async () => {
    await deleteUserReq(user?.id as UUID);

    toast.success(`Utilisateur supprimé avec succès`);
    await fetchUsers();
  };

  useEffect(() => {
    fetchUsers();
    fetchRoles();
    fetchBusinessUnits();
  }, []);

  useEffect(() => {
    if (page !== 1) setPage(1);
  }, [search]);

  const filterUsers = (users: UserResponse[], filterPage: boolean) => {
    const save = search
      ? users
          .filter((u) => u.full_name.toLowerCase().includes(search.full_name.toLowerCase()))
          .filter((u) => u.email.toLowerCase().includes(search.email.toLowerCase()))
      : users;

    return filterPage ? save.filter((_, i) => i >= (page - 1) * 10 && i < page * 10) : save;
  };

  const userDatas = (user: UserResponse) => {
    return [
      {
        label: "Nom",
        value: `${user.first_name} ${user.last_name}`,
        bold: true,
      },
      { label: "Titre du poste", value: user.title, bold: true },
      { label: "Adresse e-mail", value: user.email, bold: true },
      {
        label: "Linkedin",
        value: user.linkedin_url && <a href={user.linkedin_url}>{user.linkedin_url}</a>,
        bold: true,
      },
      {
        label: "Rôle",
        value: roles.find((r) => r.id === user.role_id)?.name || "",
        bold: true,
      },
      {
        label: "Département",
        value: businessUnits.find((bu) => bu.id === user.business_unit_id)?.name,
        bold: true,
      },
    ];
  };

  const userInfos = (user: UserResponse) => {
    if (loading) return <Spinner light />;
    return (
      <div className="bg-white shadow-md p-6">
        <div className="flex justify-between mb-4 items-center">
          <div className="flex gap-4 items-center flex-wrap">
            <Avatar size="large" user={user} />
            <div className="text-3xl font-bold">{user.first_name}</div>
          </div>
          {store.state.JWT?.sub !== user.id && (
            <MoreActions
              light
              actions={[
                {
                  title: user.locked ? "Déverouiller" : "Verouiller",
                  action: lockUnlockUser,
                  icon: user.locked ? <LockOpenIcon /> : <LockClosedIcon />,
                },
                { title: "Editer", action: () => setEdit(true), icon: <PencilIcon /> },
                {
                  title: "Supprimer",
                  action: deleteUser,
                  popConfirm: { show: true, title: "Supprimer l'utilisateur ?" },
                  icon: <TrashIcon />,
                },
              ]}
            />
          )}
        </div>
        {userDatas(user).map((e) => (
          <InlineLabelValue {...e} key={e.label} direction="vertical" />
        ))}
      </div>
    );
  };

  return (
    <div className="w-full">
      <div className="p-6 bg-white shadow-md mb-6 flex justify-between items-center gap-2 bg-inherit">
        <div className="text-3xl font-bold">Utilisateurs</div>
        <Button
          onClick={() => {
            setUser(undefined);
            setEdit(true);
          }}
          title="Nouvel utilisateur"
          light
          size="small"
        />
      </div>
      <div className="flex gap-6 px-6">
        <div className="flex-1 max-w-[calc(66%-24px)]">
          <Table
            columns={columns}
            dataSource={{
              data: filterUsers((users || []) as UserResponse[], true),
              total: filterUsers((users || []) as UserResponse[], false)?.length || 0,
            }}
            light
            bordered
            pagination={{ per_page: PER_PAGE, page, onChange: setPage }}
            onClick={(record) => {
              setUser(record);
              window.scrollTo(0, 0);
            }}
            rowClassName={(data) => `${data.locked ? "locked" : ""}`}
            filter={{
              filters: search,
              onFilterChange: (key, value) => {
                if (key === "full_name") {
                  setSearch({ ...search, full_name: value });
                } else if (key === "email") {
                  setSearch({ ...search, email: value });
                }
              },
            }}
          />
        </div>
        {user && <div className="w-1/3">{userInfos(user)}</div>}
      </div>
      <Modal className="w-modal-md" onClose={() => setEdit(false)} show={edit} light>
        <UserForm businessUnits={businessUnits} key={user?.id || "new_user"} onSubmit={submitUser} user={user} />
      </Modal>
    </div>
  );
};

export default SettingsUsers;
