import React, { FunctionComponent, useState } from "react";
import { ContractResponse } from "../../../models/billing/contract";
import { Paginated } from "../../../models/common";
import { BillResponse, BillsFilters, getBills } from "../../../models/billing/bill";
import BillAndBookingList from "../../../components/billing/billsAndBookingList";
import { Booking, BookingsFilters, getBookings } from "../../../models/billing/booking";
import { ProjectType } from "../../../models/billing/project";

const PER_PAGE = 10;

interface Props {
  contract: ContractResponse;
}

export const ContractBillList: FunctionComponent<Props> = (props) => {
  const { contract } = props;
  const [bills, setBills] = useState<Paginated<BillResponse>>();
  const [bookings, setBookings] = useState<Paginated<Booking>>();

  const fetchBills = async (filters: BillsFilters, project_types: ProjectType[]) => {
    const b = await getBills({
      ...filters,
      per_page: PER_PAGE,
      contract_id: contract.id,
      project_types,
    });
    setBills(b);
  };

  const fetchBookings = async (filters: BookingsFilters) => {
    const b = await getBookings({
      ...filters,
      per_page: PER_PAGE,
      contract_id: contract.id,
    });
    setBookings(b);
  };

  return (
    <BillAndBookingList
      contractId={contract.id}
      customerId={contract.customer_id}
      bills={bills}
      fetchBills={fetchBills}
      bookings={bookings}
      fetchBookings={fetchBookings}
      returnPath={`/billing/contracts/${contract.id}`}
      edition
    />
  );
};

export default ContractBillList;
