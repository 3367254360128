import React, { useEffect, useState, forwardRef, useImperativeHandle } from "react";
import { Customer } from "../../../models/customers";
import { BankAccount, getBankAccounts } from "../../../models/bankAccounts";
import { TextInput, Select } from "@getprorecrutement/getpro-design";
import { useFormManager } from "../../../services/form";
import { StepHandle } from ".";

interface Props {
  values: Partial<Customer>;
  setDisabled: (value: boolean) => void;
}

export const BillingStepForm = forwardRef<StepHandle, Props>(function BasicStepForm({ values, setDisabled }, ref) {
  const [bankAccounts, setBankAccounts] = useState<BankAccount[]>([]);

  const form = useFormManager<Customer>({
    defaultState: values,
    validations: {
      tax_number: {
        required: true,
        message: "Merci de préciser le numéro de TVA de l'entreprise",
      },
      siret: {
        required: true,
        message: "Merci de préciser le SIRET de l'entreprise",
      },
      bank_account_id: {
        required: true,
        message: "Merci de préciser un compte bancaire",
      },
    },
  });

  useEffect(() => {
    setDisabled(form.disabled);
  }, [form.disabled]);

  useImperativeHandle(
    ref,
    () => {
      return {
        isValid() {
          return form.validate();
        },

        getData() {
          return form.state;
        },
      };
    },
    [form.state]
  );

  const fetchBankAccounts = async () => {
    getBankAccounts().then(setBankAccounts);
  };

  useEffect(() => {
    fetchBankAccounts();
  }, []);

  return (
    <div className="bg-inherit flex flex-col gap-4">
      <TextInput
        type="text"
        placeholder="FR40123456824"
        label="N° TVA"
        light
        value={form.state.tax_number}
        error={form.errors.tax_number}
        onChange={(e) => form.onChange({ tax_number: e.target.value })}
      />
      <TextInput
        type="text"
        placeholder="362521879"
        label="N° SIRET"
        light
        error={form.errors.siret}
        value={form.state.siret}
        onChange={(e) => form.onChange({ siret: e.target.value })}
      />
      <Select
        light
        bordered
        rounded
        label="Compte bancaire"
        value={bankAccounts.find((bank) => bank.id === form.state.bank_account_id)}
        error={form.errors.bank_account_id}
        options={bankAccounts}
        optionRender={(item) => item.name}
        getKey={(item) => item.id}
        onChange={(val) => val && form.onChange({ bank_account_id: val.id })}
        type="single"
      />
    </div>
  );
});

export default BillingStepForm;
