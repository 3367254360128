import React, { forwardRef, useImperativeHandle, useState } from "react";
import { Customer } from "../../../models/customers";
import { Contact, NewContact } from "../../../models/contacts";
import { useEffect } from "react";
import {
  createContact,
  getCustomerContacts,
  updateContact,
  archiveContact as archiveContactReq,
} from "../../../models/contacts";
import { UUID } from "../../../models/common";
import ContactCard from "./contactCard";
import { ContactInputs } from "../../billing/contacts/contactForm";
import { Button } from "@getprorecrutement/getpro-design";
import { PlusIcon } from "@heroicons/react/24/outline";
import { StepHandle } from ".";

interface Props {
  values: Partial<Customer>;
}

export const ContactStepForm = forwardRef<StepHandle, Props>(function BasicStepForm(props, ref) {
  const { values } = props;
  const [contacts, setContacts] = useState<Contact[]>([]);
  const [editContact, setEditContact] = useState<Partial<Contact>>();
  const [loading, setLoading] = useState(false);

  const getContacts = async (id: UUID) => {
    const c = await getCustomerContacts(id);
    setContacts(c);
  };

  useImperativeHandle(
    ref,
    () => {
      return {
        isValid() {
          return true;
        },

        getData() {
          return props.values;
        },
      };
    },
    [props.values]
  );

  const archiveContact = async (id: UUID) => {
    await archiveContactReq(id);
    setContacts((contact) => contact.filter((c) => c.id !== id));
  };

  useEffect(() => {
    if (values.id) {
      getContacts(values.id);
    }
  }, []);

  const submitContact = async (data: Partial<Contact>) => {
    setLoading(true);
    try {
      setEditContact(undefined);
      if (data.id) {
        await updateContact(data as Contact);
      } else if (values.id) {
        await createContact({
          ...data,
          customer_id: values.id,
        } as NewContact);
      }
      if (values.id) getContacts(values.id);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="bg-inherit">
      <div className="mb-6 flex justify-center bg-inherit">
        {!editContact && (
          <Button
            size="small"
            title="Ajouter un contact"
            kind="outline"
            light
            onClick={() => {
              setEditContact({});
            }}
            icon={<PlusIcon />}
          />
        )}
        {editContact && values.id && (
          <div key={editContact?.id || "NewContact"} className="bg-inherit">
            <ContactInputs
              contact={editContact}
              loading={loading}
              onSubmit={submitContact}
              onCancel={() => setEditContact(undefined)}
            />
          </div>
        )}
      </div>
      {contacts.length === 0 && !editContact ? (
        <div className="flex justify-center">
          <b>Aucun contact disponible</b>
        </div>
      ) : (
        <div className="flex gap-4 flex-wrap">
          {contacts?.map((c) => (
            <ContactCard key={c.id} contact={c} onDelete={archiveContact} onEdit={setEditContact} />
          ))}
        </div>
      )}
    </div>
  );
});

export default ContactStepForm;
