import React, { FunctionComponent, useEffect, useState } from "react";
import { NewSignalTarget, Signal, SignalTarget } from "../../models/signals";
import { Button, TextInput } from "@getprorecrutement/getpro-design";
import { Customer } from "../../models/customers";
import CustomerSelect from "../../components/forms/inputs/customerSelect";
import { UUID } from "../../models/common";
import { SignalFormInputs } from "./signalForm";

interface Props {
  signalTarget?: SignalTarget;
  companies: Customer[];
  onSubmit: (targetData: Partial<SignalTarget>, signalData?: Partial<Signal>) => Promise<void>;
}

export const SignalClientForm: FunctionComponent<Props> = ({ signalTarget, companies, onSubmit }) => {
  const [data, setData] = useState<Partial<NewSignalTarget>>(signalTarget || {});
  const [selectedCompany, setSelectedCompany] = useState<UUID>();
  const [signalValues, setSignalValues] = useState<Partial<Signal>>({});

  const targetDisabled = !data.first_name || !data.last_name || !data.email || !selectedCompany;
  const signalDisabled = signalTarget ? false : !signalValues.send_on || !signalValues.filters;
  const disabled = targetDisabled || signalDisabled;

  useEffect(() => {
    if (signalTarget && companies) setSelectedCompany(companies.find((c) => c.id === signalTarget.customer_id)?.id);
  }, [signalTarget, companies]);

  return (
    <div className="px-6 py-8 flex-1 flex flex-col items-center gap-4 overflow-hidden">
      <div className="text-2xl mb-2 font-bold">{`${
        signalTarget ? "Edition d'un destinataire signal" : "Création d'un nouveau destinataire signal"
      }`}</div>
      <div className="bg-inherit py-2 pr-4 overflow-auto">
        <div
          className={`flex flex-col gap-4 p-6 border border-solid border-border-bright ${
            signalTarget ? "rounded-2xl" : "rounded-t-2xl"
          }`}
        >
          <div className="text-content-darker font-bold text-center mb-2">Destinataire</div>
          <CustomerSelect
            onSelect={(id) => setSelectedCompany(id)}
            value={selectedCompany}
            showSearch
            customers={companies}
            placeholder="Entreprise"
          />
          <div className="flex items-start">
            <TextInput
              type="text"
              light
              inputClassName="rounded-r-none"
              placeholder="Nom"
              value={data.last_name}
              onChange={(e) => setData({ ...data, last_name: e.currentTarget.value })}
            />
            <TextInput
              type="text"
              light
              inputClassName="rounded-l-none"
              placeholder="Prénom"
              value={data.first_name}
              onChange={(e) => setData({ ...data, first_name: e.currentTarget.value })}
            />
          </div>
          <TextInput
            type="email"
            light
            placeholder="Adresse e-mail"
            value={data.email}
            onChange={(e) => setData({ ...data, email: e.currentTarget.value })}
          />
        </div>
        {!signalTarget && (
          <div className="flex flex-col gap-4 p-6 border-x border-b border-solid border-border-bright rounded-b-2xl">
            <div className="text-content-darker font-bold text-center mb-2">Signal</div>
            <SignalFormInputs values={signalValues} setValues={setSignalValues} />
          </div>
        )}
        <div className="flex justify-center mt-4">
          <Button
            light
            title={signalTarget ? "Enregister" : "Créer"}
            onClick={() =>
              selectedCompany &&
              onSubmit(
                { ...data, customer_id: selectedCompany, id: signalTarget?.id },
                signalTarget ? undefined : signalValues
              )
            }
            disabled={disabled}
          />
        </div>
      </div>
    </div>
  );
};
