import { Tag } from "@getprorecrutement/getpro-design";
import React from "react";
import { ProjectType, ProjectTypes } from "../../../models/billing/project";

interface Props {
  type: ProjectType;
}

export const ProjectTypeTag = (props: Props) => {
  const { type } = props;
  const projectType = ProjectTypes.other.concat(ProjectTypes.recruitment).find((e) => e.value === type)?.label;

  const tagColor = () => {
    switch (type) {
      case ProjectType.Commission:
        return "border-[#b7eb8f] bg-[#f6ffed] text-[#389e0d]";
      case ProjectType.CommissionRate:
        return "border-[#91d5ff] bg-[#e6f7ff] text-[#096dd9]";
      case ProjectType.CommissionThird:
        return "border-[#ffd591] bg-[#fff7e6] text-[#d46b08]";
      case ProjectType.Rpo:
        return "border-[#ffe58f] bg-[#fffbe6] text-[#d48806]";
      case ProjectType.Subscription:
        return "border-[#d3adf7] bg-[#f9f0ff] text-[#531dab]";
      case ProjectType.Product:
        return "border-[#ffbb96] bg-[#fff2e8] text-[#d4380d]";
      default:
        return "default";
    }
  };

  return <div>{projectType && <Tag size="small" value={projectType} className={tagColor()} light bordered />}</div>;
};

export default ProjectTypeTag;
