import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/outline";
import classNames from "classnames";
import React, { FunctionComponent, useState } from "react";
import { twMerge } from "tailwind-merge";

interface TimelineElem<T> {
  value: T;
  icon?: JSX.Element;
}

export interface Props<T> {
  elems: TimelineElem<T>[];
  renderer: (value: T) => JSX.Element;
  size?: number;
}

export const Timeline = <T extends unknown>({ elems, renderer, size = 6 }: Props<T>) => {
  const displayedElems = elems.length > 1 ? (elems.length > size ? size - 1 : elems.length - 1) : 1;
  const elemWidth = 100 / displayedElems;
  const [current, setCurrent] = useState<number>(0);

  const calcWidth = (current: number, index: number) => {
    if (index < current || index > current + displayedElems) return 0;
    else return `calc(${100 / displayedElems}% - ${16 / displayedElems}px)`;
  };

  const calcLeft = (current: number, index: number) => {
    if (index < current) return -16;
    if (index > current + displayedElems) return "100%";

    const pos = index - current;

    if (index === current) return `${pos * elemWidth}%`;
    else return `calc(${pos * elemWidth}% - ${(16 / displayedElems) * pos}px)`;
  };

  const contentClasses = (index: number) =>
    twMerge(
      classNames("absolute left-0 transition-all -translate-x-[calc(50%-6px)] opacity-100 w-[140%]", {
        "-translate-x-[32px]": index === current,
        "-translate-x-[calc(100%-48px)]": index === current + displayedElems,
        "top-4": index % 2 === 0,
        "bottom-8": index % 2 === 1,
        "opacity-0": index < current || index > current + displayedElems,
        "w-full": index === current || index === current + displayedElems,
      })
    );

  return (
    <div className="relative">
      <div className="flex gap-4">
        <ChevronLeftIcon
          className={`w-4 h-4 min-h-fit min-w-fit z-10 ${current === 0 ? "text-content-light" : "cursor-pointer"}`}
          onClick={() => current > 0 && setCurrent(current - 1)}
        />
        <div className="flex-1">
          <div className="relative bg-inherit h-4 overflow-hidden">
            {elems.map((elem, i) => (
              <div
                key={`timeline-elem-${i}`}
                className={"absolute transition-all"}
                style={{
                  width: calcWidth(current, i),
                  left: calcLeft(current, i),
                }}
              >
                <div className="flex items-center gap-2 pr-2">
                  <div className="w-4 h-4 flex justify-center items-center">
                    {elem.icon ? (
                      <div className="w-4 h-4">{elem.icon}</div>
                    ) : (
                      <div className="w-3 h-3 min-h-fit min-w-fit rounded-full border-2 border-solid border-border-light" />
                    )}
                  </div>
                  {i >= current && i < current + displayedElems && <div className="flex-1 h-1 bg-background-lighter" />}
                </div>
              </div>
            ))}
          </div>
          <div className="flex relative">
            {elems.map((elem, i) => (
              <div
                key={`timeline-content-${i}`}
                className={`absolute transition-all ${i < current || i > current + displayedElems ? "invisible" : ""}`}
                style={{
                  width: `calc(${100 / displayedElems}% - ${12 / displayedElems}px)`,
                  left: calcLeft(current, i),
                }}
              >
                <div className={contentClasses(i)}>
                  <div
                    className={`flex ${
                      i === current
                        ? "justify-start"
                        : i === current + displayedElems
                        ? "justify-end"
                        : "justify-center"
                    }`}
                  >
                    {renderer(elem.value)}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <ChevronRightIcon
          className={`w-4 h-4 min-h-fit min-w-fit z-10 ${
            current >= elems.length - displayedElems - 1 ? "text-content-light" : "cursor-pointer"
          }`}
          onClick={() => current < elems.length - displayedElems - 1 && setCurrent(current + 1)}
        />
      </div>
    </div>
  );
};
