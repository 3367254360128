import classNames from "classnames";
import React, { FunctionComponent, useEffect, useState } from "react";

export class PortalHandler {
  items: string[] = [];
  _listeners: ((active: boolean) => void)[] = [];

  onUpdate() {
    this._listeners.forEach((callback) => callback(this.items.length > 0));
  }

  public addModal(id: string) {
    if (this.items.includes(id)) return;
    this.items.push(id);
    this.onUpdate();
  }

  public listen(callback: (active: boolean) => void) {
    this._listeners.push(callback);
    return () => {
      this._listeners = this._listeners.filter((cb) => cb !== callback);
    };
  }

  public removeModal(id: string) {
    this.items = this.items.filter((m) => m !== id);
    this.onUpdate();
  }
}

export interface Props {
  onChange?: (show: boolean) => void;
  handler: PortalHandler;
  className?: string;
  id: string;
}

export const Portal: FunctionComponent<Props> = ({ onChange, handler, id, className }) => {
  const [modalShown, setModalShown] = useState<boolean>(false);

  useEffect(() => handler.listen(setModalShown), []);

  useEffect(() => {
    onChange?.(modalShown);
  }, [modalShown]);

  return <div id={id} className={className} />;
};
