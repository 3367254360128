import httpClient from "../services/http/client";
import ROUTES from "../services/http/routes";
import { notifyError } from "../services/requests";
import { UUID } from "./common";

export interface AuthResponse {
  token: string;
  jwt: string;
}

export interface JWT {
  sub: UUID;
  email: string;
  first_name: string;
  last_name: string;
  role_id: UUID;
  superadmin: boolean;
  exp: number;
}

export interface LoginPayload {
  email: string;
  password: string;
}

export interface OauthPayload {
  state: string;
  code: string;
  redirect_url: string;
  scopes: string;
}

export async function getAccessToken(): Promise<{ access_token: string }> {
  return httpClient.req(ROUTES.GET_OAUTH_ACCESS_TOKEN(), notifyError);
}
