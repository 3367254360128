import { FunctionComponent, useEffect, useState } from "react";
import React from "react";
import { Select } from "@getprorecrutement/getpro-design";
import { Category } from "../../../../models/jobTitleCategory";
import { CheckIcon, ChevronDownIcon, ChevronUpIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { MultipleValues, SingleValue } from "@getprorecrutement/getpro-design/dist/components/Form/Inputs/Select";

export interface CategorySelectProps {
  categories: Category[];
  onSelect: (category?: Category) => void;
  showXIcon?: boolean;
  error?: string;
  placeholder?: string;
  bordered?: boolean;
  dropdownClassName?: string;
  size?: "small" | "medium" | "large";
  showArrowIcon?: boolean;
}

interface MultipleProps {
  multiple: true;
  value?: Category[];
  customValueRender?: (value: Category[]) => JSX.Element;
}

interface SingleProps {
  multiple?: false;
  value?: Category;
  customValueRender?: (value: Category) => JSX.Element;
}

export interface CategoryTree {
  category: Category;
  children: CategoryTree[];
}

export const constructCategoryTree = (categories: Category[]) => {
  const constructFromParent = (parent: Category): CategoryTree => {
    const children = categories.filter((category) => category.parent_id === parent.id);
    return {
      category: parent,
      children: children.map(constructFromParent),
    };
  };

  const topLevelCats = categories.filter((elem) => !elem.parent_id);

  return topLevelCats.map(constructFromParent);
};

const filterCategories = (search: string, categoryTree: CategoryTree[]): CategoryTree[] => {
  const filtered = categoryTree.reduce((acc, next) => {
    const filterdCategories = { ...next, children: filterCategories(search, next.children) };
    if (
      filterdCategories.category.title.toLowerCase().includes(search.toLowerCase() || "") ||
      filterdCategories.children.length !== 0
    )
      acc.push(filterdCategories);
    return acc;
  }, [] as CategoryTree[]);
  return filtered;
};

const CategoryTreeComponent = ({
  elem,
  onClick,
  multiple,
  selected,
}: {
  elem: CategoryTree;
  onClick: (category: Category) => void;
  multiple?: boolean;
  selected: Category[];
}) => {
  const [openedKeys, setOpenedKeys] = useState<string[]>([]);

  const toggleOpen = (category: CategoryTree) => {
    if (openedKeys.includes(category.category.id)) {
      setOpenedKeys(openedKeys.filter((id) => id !== category.category.id));
    } else {
      setOpenedKeys([...openedKeys, category.category.id]);
    }
  };

  return (
    <div
      className="px-4 py-0.5 w-full"
      onClick={(ev) => {
        ev.stopPropagation();
        onClick(elem.category);
      }}
    >
      <div className="flex items-center gap-3 w-fit">
        {multiple && (
          <div
            className={`w-4 h-4 min-w-[16px] border border-solid rounded-sm ${
              selected.map((s) => s.id).includes(elem.category.id) ? "bg-primary-medium" : ""
            }`}
          >
            {selected.map((s) => s.id).includes(elem.category.id) && <CheckIcon className="w-full h-full text-white" />}
          </div>
        )}
        <div className="cursor-pointer px-2 rounded-full hover:text-primary-medium font-semibold">
          {elem.category.title}
        </div>
        {elem.children.length > 0 && (
          <div
            className="cursor-pointer hover:text-primary-medium font-semibold"
            onClick={(ev) => {
              ev.stopPropagation();
              ev.preventDefault();
              elem.children.length > 0 && toggleOpen(elem);
            }}
          >
            {openedKeys.includes(elem.category.id) ? (
              <ChevronUpIcon height={12} width={12} />
            ) : (
              <ChevronDownIcon height={12} width={12} />
            )}
          </div>
        )}
      </div>
      {openedKeys.includes(elem.category.id) && (
        <div className="flex flex-col gap-0.5">
          {elem.children.map((e) => (
            <CategoryTreeComponent
              key={e.category.id}
              elem={e}
              onClick={onClick}
              multiple={multiple}
              selected={selected}
            />
          ))}
        </div>
      )}
    </div>
  );
};
export const CategorySelect: FunctionComponent<CategorySelectProps & (MultipleProps | SingleProps)> = ({
  categories,
  error,
  onSelect,
  bordered = true,
  ...props
}) => {
  const [categoryTree, setCategoryTree] = useState<CategoryTree[]>(constructCategoryTree(categories));
  const [search, setSearch] = useState<string>("");
  const [filteredTree, setFilteredTree] = useState<CategoryTree[]>(categoryTree);
  const [selected, setSelected] = useState<Category[]>([]);

  useEffect(() => {
    setCategoryTree(constructCategoryTree(categories));
  }, [categories]);

  useEffect(() => {
    if (search) {
      setFilteredTree(filterCategories(search, categoryTree));
    } else {
      setFilteredTree(categoryTree);
    }
  }, [search, categoryTree]);

  useEffect(() => {
    if (props.value) setSelected(props.multiple ? props.value : [props.value]);
  }, [props.value]);

  const renderInputSingle = () => {
    return props.customValueRender ? (
      (props.customValueRender as (value: Category) => JSX.Element)(selected[0])
    ) : (
      <div className="flex gap-2 justify-between items-center w-full">
        <div>{selected[0].title}</div>
        <XMarkIcon
          className="w-4 h-4 stroke-2 text-content-light cursor-pointer hover:text-content-darker"
          onClick={() => {
            setSelected([]);
            onSelect(undefined);
          }}
        />
      </div>
    );
  };

  const renderInputMultiple = () => {
    return props.customValueRender ? (
      (props.customValueRender as (value: Category[]) => JSX.Element)(selected)
    ) : (
      <div>{selected.length} Sélectionnés</div>
    );
  };

  const selectProps: SingleValue<CategoryTree> | MultipleValues<CategoryTree> = props.multiple
    ? {
        type: "multiple",
        value: selected.length ? categoryTree : undefined,
        hideCheckbox: true,
      }
    : {
        type: "single",
        value: selected.length ? categoryTree[0] : undefined,
      };

  return (
    <div className="w-full">
      <Select
        className="w-full"
        placeholder={props.placeholder || "Catégorisez le titre"}
        customValueRender={props.multiple ? renderInputMultiple : renderInputSingle}
        error={error}
        size={props.size}
        bordered={bordered}
        rounded
        showArrowIcon={props.showArrowIcon}
        getKey={(t) => t.category.id}
        options={filteredTree}
        onChange={() => null}
        onSearch={(e) => setSearch(e)}
        dropdownClassName={props.dropdownClassName}
        optionClassName="p-0 hover:bg-transparent"
        optionRender={(t) => (
          <CategoryTreeComponent
            key={t.category.id}
            elem={t}
            onClick={onSelect}
            multiple={props.multiple}
            selected={selected}
          />
        )}
        {...selectProps}
      />
    </div>
  );
};
