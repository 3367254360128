const _temp0 = require("./ac.svg");
const _temp1 = require("./ad.svg");
const _temp2 = require("./ae.svg");
const _temp3 = require("./af.svg");
const _temp4 = require("./ag.svg");
const _temp5 = require("./ai.svg");
const _temp6 = require("./al.svg");
const _temp7 = require("./am.svg");
const _temp8 = require("./ao.svg");
const _temp9 = require("./aq.svg");
const _temp10 = require("./ar.svg");
const _temp11 = require("./as.svg");
const _temp12 = require("./at.svg");
const _temp13 = require("./au.svg");
const _temp14 = require("./aw.svg");
const _temp15 = require("./ax.svg");
const _temp16 = require("./az.svg");
const _temp17 = require("./ba.svg");
const _temp18 = require("./bb.svg");
const _temp19 = require("./bd.svg");
const _temp20 = require("./be.svg");
const _temp21 = require("./bf.svg");
const _temp22 = require("./bg.svg");
const _temp23 = require("./bh.svg");
const _temp24 = require("./bi.svg");
const _temp25 = require("./bj.svg");
const _temp26 = require("./bl.svg");
const _temp27 = require("./bm.svg");
const _temp28 = require("./bn.svg");
const _temp29 = require("./bo.svg");
const _temp30 = require("./bq.svg");
const _temp31 = require("./br.svg");
const _temp32 = require("./bs.svg");
const _temp33 = require("./bt.svg");
const _temp34 = require("./bv.svg");
const _temp35 = require("./bw.svg");
const _temp36 = require("./by.svg");
const _temp37 = require("./bz.svg");
const _temp38 = require("./ca.svg");
const _temp39 = require("./cc.svg");
const _temp40 = require("./cd.svg");
const _temp41 = require("./cefta.svg");
const _temp42 = require("./cf.svg");
const _temp43 = require("./cg.svg");
const _temp44 = require("./ch.svg");
const _temp45 = require("./ci.svg");
const _temp46 = require("./ck.svg");
const _temp47 = require("./cl.svg");
const _temp48 = require("./cm.svg");
const _temp49 = require("./cn.svg");
const _temp50 = require("./co.svg");
const _temp51 = require("./cp.svg");
const _temp52 = require("./cr.svg");
const _temp53 = require("./cu.svg");
const _temp54 = require("./cv.svg");
const _temp55 = require("./cw.svg");
const _temp56 = require("./cx.svg");
const _temp57 = require("./cy.svg");
const _temp58 = require("./cz.svg");
const _temp59 = require("./de.svg");
const _temp60 = require("./dg.svg");
const _temp61 = require("./dj.svg");
const _temp62 = require("./dk.svg");
const _temp63 = require("./dm.svg");
const _temp64 = require("./do.svg");
const _temp65 = require("./dz.svg");
const _temp66 = require("./ea.svg");
const _temp67 = require("./ec.svg");
const _temp68 = require("./ee.svg");
const _temp69 = require("./eg.svg");
const _temp70 = require("./eh.svg");
const _temp71 = require("./er.svg");
const _temp72 = require("./es-ct.svg");
const _temp73 = require("./es-ga.svg");
const _temp74 = require("./es-pv.svg");
const _temp75 = require("./es.svg");
const _temp76 = require("./et.svg");
const _temp77 = require("./eu.svg");
const _temp78 = require("./fi.svg");
const _temp79 = require("./fj.svg");
const _temp80 = require("./fk.svg");
const _temp81 = require("./fm.svg");
const _temp82 = require("./fo.svg");
const _temp83 = require("./fr.svg");
const _temp84 = require("./ga.svg");
const _temp85 = require("./gb-eng.svg");
const _temp86 = require("./gb-nir.svg");
const _temp87 = require("./gb-sct.svg");
const _temp88 = require("./gb-wls.svg");
const _temp89 = require("./gb.svg");
const _temp90 = require("./gd.svg");
const _temp91 = require("./ge.svg");
const _temp92 = require("./gf.svg");
const _temp93 = require("./gg.svg");
const _temp94 = require("./gh.svg");
const _temp95 = require("./gi.svg");
const _temp96 = require("./gl.svg");
const _temp97 = require("./gm.svg");
const _temp98 = require("./gn.svg");
const _temp99 = require("./gp.svg");
const _temp100 = require("./gq.svg");
const _temp101 = require("./gr.svg");
const _temp102 = require("./gs.svg");
const _temp103 = require("./gt.svg");
const _temp104 = require("./gu.svg");
const _temp105 = require("./gw.svg");
const _temp106 = require("./gy.svg");
const _temp107 = require("./hk.svg");
const _temp108 = require("./hm.svg");
const _temp109 = require("./hn.svg");
const _temp110 = require("./hr.svg");
const _temp111 = require("./ht.svg");
const _temp112 = require("./hu.svg");
const _temp113 = require("./ic.svg");
const _temp114 = require("./id.svg");
const _temp115 = require("./ie.svg");
const _temp116 = require("./il.svg");
const _temp117 = require("./im.svg");
const _temp118 = require("./in.svg");
const _temp119 = require("./io.svg");
const _temp120 = require("./iq.svg");
const _temp121 = require("./ir.svg");
const _temp122 = require("./is.svg");
const _temp123 = require("./it.svg");
const _temp124 = require("./je.svg");
const _temp125 = require("./jm.svg");
const _temp126 = require("./jo.svg");
const _temp127 = require("./jp.svg");
const _temp128 = require("./ke.svg");
const _temp129 = require("./kg.svg");
const _temp130 = require("./kh.svg");
const _temp131 = require("./ki.svg");
const _temp132 = require("./km.svg");
const _temp133 = require("./kn.svg");
const _temp134 = require("./kp.svg");
const _temp135 = require("./kr.svg");
const _temp136 = require("./kw.svg");
const _temp137 = require("./ky.svg");
const _temp138 = require("./kz.svg");
const _temp139 = require("./la.svg");
const _temp140 = require("./lb.svg");
const _temp141 = require("./lc.svg");
const _temp142 = require("./li.svg");
const _temp143 = require("./lk.svg");
const _temp144 = require("./lr.svg");
const _temp145 = require("./ls.svg");
const _temp146 = require("./lt.svg");
const _temp147 = require("./lu.svg");
const _temp148 = require("./lv.svg");
const _temp149 = require("./ly.svg");
const _temp150 = require("./ma.svg");
const _temp151 = require("./mc.svg");
const _temp152 = require("./md.svg");
const _temp153 = require("./me.svg");
const _temp154 = require("./mf.svg");
const _temp155 = require("./mg.svg");
const _temp156 = require("./mh.svg");
const _temp157 = require("./mk.svg");
const _temp158 = require("./ml.svg");
const _temp159 = require("./mm.svg");
const _temp160 = require("./mn.svg");
const _temp161 = require("./mo.svg");
const _temp162 = require("./mp.svg");
const _temp163 = require("./mq.svg");
const _temp164 = require("./mr.svg");
const _temp165 = require("./ms.svg");
const _temp166 = require("./mt.svg");
const _temp167 = require("./mu.svg");
const _temp168 = require("./mv.svg");
const _temp169 = require("./mw.svg");
const _temp170 = require("./mx.svg");
const _temp171 = require("./my.svg");
const _temp172 = require("./mz.svg");
const _temp173 = require("./na.svg");
const _temp174 = require("./nc.svg");
const _temp175 = require("./ne.svg");
const _temp176 = require("./nf.svg");
const _temp177 = require("./ng.svg");
const _temp178 = require("./ni.svg");
const _temp179 = require("./nl.svg");
const _temp180 = require("./no.svg");
const _temp181 = require("./np.svg");
const _temp182 = require("./nr.svg");
const _temp183 = require("./nu.svg");
const _temp184 = require("./nz.svg");
const _temp185 = require("./om.svg");
const _temp186 = require("./pa.svg");
const _temp187 = require("./pe.svg");
const _temp188 = require("./pf.svg");
const _temp189 = require("./pg.svg");
const _temp190 = require("./ph.svg");
const _temp191 = require("./pk.svg");
const _temp192 = require("./pl.svg");
const _temp193 = require("./pm.svg");
const _temp194 = require("./pn.svg");
const _temp195 = require("./pr.svg");
const _temp196 = require("./ps.svg");
const _temp197 = require("./pt.svg");
const _temp198 = require("./pw.svg");
const _temp199 = require("./py.svg");
const _temp200 = require("./qa.svg");
const _temp201 = require("./re.svg");
const _temp202 = require("./ro.svg");
const _temp203 = require("./rs.svg");
const _temp204 = require("./ru.svg");
const _temp205 = require("./rw.svg");
const _temp206 = require("./sa.svg");
const _temp207 = require("./sb.svg");
const _temp208 = require("./sc.svg");
const _temp209 = require("./sd.svg");
const _temp210 = require("./se.svg");
const _temp211 = require("./sg.svg");
const _temp212 = require("./sh.svg");
const _temp213 = require("./si.svg");
const _temp214 = require("./sj.svg");
const _temp215 = require("./sk.svg");
const _temp216 = require("./sl.svg");
const _temp217 = require("./sm.svg");
const _temp218 = require("./sn.svg");
const _temp219 = require("./so.svg");
const _temp220 = require("./sr.svg");
const _temp221 = require("./ss.svg");
const _temp222 = require("./st.svg");
const _temp223 = require("./sv.svg");
const _temp224 = require("./sx.svg");
const _temp225 = require("./sy.svg");
const _temp226 = require("./sz.svg");
const _temp227 = require("./ta.svg");
const _temp228 = require("./tc.svg");
const _temp229 = require("./td.svg");
const _temp230 = require("./tf.svg");
const _temp231 = require("./tg.svg");
const _temp232 = require("./th.svg");
const _temp233 = require("./tj.svg");
const _temp234 = require("./tk.svg");
const _temp235 = require("./tl.svg");
const _temp236 = require("./tm.svg");
const _temp237 = require("./tn.svg");
const _temp238 = require("./to.svg");
const _temp239 = require("./tr.svg");
const _temp240 = require("./tt.svg");
const _temp241 = require("./tv.svg");
const _temp242 = require("./tw.svg");
const _temp243 = require("./tz.svg");
const _temp244 = require("./ua.svg");
const _temp245 = require("./ug.svg");
const _temp246 = require("./um.svg");
const _temp247 = require("./un.svg");
const _temp248 = require("./us.svg");
const _temp249 = require("./uy.svg");
const _temp250 = require("./uz.svg");
const _temp251 = require("./va.svg");
const _temp252 = require("./vc.svg");
const _temp253 = require("./ve.svg");
const _temp254 = require("./vg.svg");
const _temp255 = require("./vi.svg");
const _temp256 = require("./vn.svg");
const _temp257 = require("./vu.svg");
const _temp258 = require("./wf.svg");
const _temp259 = require("./ws.svg");
const _temp260 = require("./xk.svg");
const _temp261 = require("./xx.svg");
const _temp262 = require("./ye.svg");
const _temp263 = require("./yt.svg");
const _temp264 = require("./za.svg");
const _temp265 = require("./zm.svg");
const _temp266 = require("./zw.svg");
module.exports = {
  "ac": _temp0,
  "ad": _temp1,
  "ae": _temp2,
  "af": _temp3,
  "ag": _temp4,
  "ai": _temp5,
  "al": _temp6,
  "am": _temp7,
  "ao": _temp8,
  "aq": _temp9,
  "ar": _temp10,
  "as": _temp11,
  "at": _temp12,
  "au": _temp13,
  "aw": _temp14,
  "ax": _temp15,
  "az": _temp16,
  "ba": _temp17,
  "bb": _temp18,
  "bd": _temp19,
  "be": _temp20,
  "bf": _temp21,
  "bg": _temp22,
  "bh": _temp23,
  "bi": _temp24,
  "bj": _temp25,
  "bl": _temp26,
  "bm": _temp27,
  "bn": _temp28,
  "bo": _temp29,
  "bq": _temp30,
  "br": _temp31,
  "bs": _temp32,
  "bt": _temp33,
  "bv": _temp34,
  "bw": _temp35,
  "by": _temp36,
  "bz": _temp37,
  "ca": _temp38,
  "cc": _temp39,
  "cd": _temp40,
  "cefta": _temp41,
  "cf": _temp42,
  "cg": _temp43,
  "ch": _temp44,
  "ci": _temp45,
  "ck": _temp46,
  "cl": _temp47,
  "cm": _temp48,
  "cn": _temp49,
  "co": _temp50,
  "cp": _temp51,
  "cr": _temp52,
  "cu": _temp53,
  "cv": _temp54,
  "cw": _temp55,
  "cx": _temp56,
  "cy": _temp57,
  "cz": _temp58,
  "de": _temp59,
  "dg": _temp60,
  "dj": _temp61,
  "dk": _temp62,
  "dm": _temp63,
  "do": _temp64,
  "dz": _temp65,
  "ea": _temp66,
  "ec": _temp67,
  "ee": _temp68,
  "eg": _temp69,
  "eh": _temp70,
  "er": _temp71,
  "es-ct": _temp72,
  "es-ga": _temp73,
  "es-pv": _temp74,
  "es": _temp75,
  "et": _temp76,
  "eu": _temp77,
  "fi": _temp78,
  "fj": _temp79,
  "fk": _temp80,
  "fm": _temp81,
  "fo": _temp82,
  "fr": _temp83,
  "ga": _temp84,
  "gb-eng": _temp85,
  "gb-nir": _temp86,
  "gb-sct": _temp87,
  "gb-wls": _temp88,
  "gb": _temp89,
  "gd": _temp90,
  "ge": _temp91,
  "gf": _temp92,
  "gg": _temp93,
  "gh": _temp94,
  "gi": _temp95,
  "gl": _temp96,
  "gm": _temp97,
  "gn": _temp98,
  "gp": _temp99,
  "gq": _temp100,
  "gr": _temp101,
  "gs": _temp102,
  "gt": _temp103,
  "gu": _temp104,
  "gw": _temp105,
  "gy": _temp106,
  "hk": _temp107,
  "hm": _temp108,
  "hn": _temp109,
  "hr": _temp110,
  "ht": _temp111,
  "hu": _temp112,
  "ic": _temp113,
  "id": _temp114,
  "ie": _temp115,
  "il": _temp116,
  "im": _temp117,
  "in": _temp118,
  "io": _temp119,
  "iq": _temp120,
  "ir": _temp121,
  "is": _temp122,
  "it": _temp123,
  "je": _temp124,
  "jm": _temp125,
  "jo": _temp126,
  "jp": _temp127,
  "ke": _temp128,
  "kg": _temp129,
  "kh": _temp130,
  "ki": _temp131,
  "km": _temp132,
  "kn": _temp133,
  "kp": _temp134,
  "kr": _temp135,
  "kw": _temp136,
  "ky": _temp137,
  "kz": _temp138,
  "la": _temp139,
  "lb": _temp140,
  "lc": _temp141,
  "li": _temp142,
  "lk": _temp143,
  "lr": _temp144,
  "ls": _temp145,
  "lt": _temp146,
  "lu": _temp147,
  "lv": _temp148,
  "ly": _temp149,
  "ma": _temp150,
  "mc": _temp151,
  "md": _temp152,
  "me": _temp153,
  "mf": _temp154,
  "mg": _temp155,
  "mh": _temp156,
  "mk": _temp157,
  "ml": _temp158,
  "mm": _temp159,
  "mn": _temp160,
  "mo": _temp161,
  "mp": _temp162,
  "mq": _temp163,
  "mr": _temp164,
  "ms": _temp165,
  "mt": _temp166,
  "mu": _temp167,
  "mv": _temp168,
  "mw": _temp169,
  "mx": _temp170,
  "my": _temp171,
  "mz": _temp172,
  "na": _temp173,
  "nc": _temp174,
  "ne": _temp175,
  "nf": _temp176,
  "ng": _temp177,
  "ni": _temp178,
  "nl": _temp179,
  "no": _temp180,
  "np": _temp181,
  "nr": _temp182,
  "nu": _temp183,
  "nz": _temp184,
  "om": _temp185,
  "pa": _temp186,
  "pe": _temp187,
  "pf": _temp188,
  "pg": _temp189,
  "ph": _temp190,
  "pk": _temp191,
  "pl": _temp192,
  "pm": _temp193,
  "pn": _temp194,
  "pr": _temp195,
  "ps": _temp196,
  "pt": _temp197,
  "pw": _temp198,
  "py": _temp199,
  "qa": _temp200,
  "re": _temp201,
  "ro": _temp202,
  "rs": _temp203,
  "ru": _temp204,
  "rw": _temp205,
  "sa": _temp206,
  "sb": _temp207,
  "sc": _temp208,
  "sd": _temp209,
  "se": _temp210,
  "sg": _temp211,
  "sh": _temp212,
  "si": _temp213,
  "sj": _temp214,
  "sk": _temp215,
  "sl": _temp216,
  "sm": _temp217,
  "sn": _temp218,
  "so": _temp219,
  "sr": _temp220,
  "ss": _temp221,
  "st": _temp222,
  "sv": _temp223,
  "sx": _temp224,
  "sy": _temp225,
  "sz": _temp226,
  "ta": _temp227,
  "tc": _temp228,
  "td": _temp229,
  "tf": _temp230,
  "tg": _temp231,
  "th": _temp232,
  "tj": _temp233,
  "tk": _temp234,
  "tl": _temp235,
  "tm": _temp236,
  "tn": _temp237,
  "to": _temp238,
  "tr": _temp239,
  "tt": _temp240,
  "tv": _temp241,
  "tw": _temp242,
  "tz": _temp243,
  "ua": _temp244,
  "ug": _temp245,
  "um": _temp246,
  "un": _temp247,
  "us": _temp248,
  "uy": _temp249,
  "uz": _temp250,
  "va": _temp251,
  "vc": _temp252,
  "ve": _temp253,
  "vg": _temp254,
  "vi": _temp255,
  "vn": _temp256,
  "vu": _temp257,
  "wf": _temp258,
  "ws": _temp259,
  "xk": _temp260,
  "xx": _temp261,
  "ye": _temp262,
  "yt": _temp263,
  "za": _temp264,
  "zm": _temp265,
  "zw": _temp266
}