/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { UUID } from "../../models/common";
import { LoginPayload, OauthPayload } from "../../models/auth";
import { JobOfferData, NewJobOffer } from "../../models/jobOffers";
import { NewCategory, Category } from "../../models/categories";
import { Customer, NewCustomer, ReportFullJobOffer } from "../../models/customers";
import {
  JobApplicationData,
  JobApplicationResponse,
  JobapplicationTerminationRequest,
  NewJobApplication,
} from "../../models/jobApplications";
import { FilePayload } from "../../models/misc";
import { AxiosRequestConfig } from "axios";
import {
  CandidateSearchQuery,
  CandidateQueryAction,
  CandidateData,
  NewCandidate,
  ClearCandidateData,
} from "../../models/candidate";
import { EmailData } from "../../models/messenger";
import { NewUser, UserData, UserResponse } from "../../models/users/users";
import { SavedCandidateQuery, UserSettings } from "../../models/users/settings";
import { NewComment } from "../../models/comments";
import { NewEmailTemplate, EmailTemplateData, BillingVars } from "../../models/emailTemplates";
import { BusinessUnitData, NewBusinessUnit } from "../../models/businessUnits";
import { FileData } from "../../models/file";
import { Contact, NewContact } from "../../models/contacts";
import { NewProject, ProjectData, ProjectsFilters, ProjectType } from "../../models/billing/project";
import { ContractData, ContractsFilters, NewContract } from "../../models/billing/contract";
import { BillData, BillsFilters, NewBill } from "../../models/billing/bill";
import { DateFilters } from "../../models/billing/chart";
import { BookingData, BookingsFilters, NewBooking } from "../../models/billing/booking";
import { GFileKind } from "../../models/gfiles";
import { NewRole, RoleData } from "../../models/roles";
import { GlobalDashboard } from "../../models/dashboards";
import { NewSignal, NewSignalTarget, UpdateSignal, UpdateSignalTarget } from "../../models/signals";
import { AnyJobTitleFilters, JobTitleQuery, NewUnvalidatedJobTitle } from "../../models/jobTitle";
import { NewSkill } from "../../models/skill";

export enum Method {
  Get = "get",
  Post = "post",
  Patch = "patch",
  Delete = "delete",
}

export enum API {
  App,
  Google,
}

export const URLS = {
  [API.App]: process.env.STAGING === "true" ? process.env.STAGING_API_URL : (process.env.API_URL as string),
  [API.Google]: process.env.GOOGLE_API_URL as string,
};

export enum AxiosResponseType {
  ArrayBuffer = "arraybuffer",
  Blob = "blob",
  Document = "document",
  Json = "json",
  Text = "text",
  Stream = "stream",
}

export interface Route {
  api: API;
  path: string;
  method: Method;
  data?: AxiosRequestConfig["data"];
  params?: AxiosRequestConfig["params"];
  auth?: boolean;
  file?: File;
  responseType?: AxiosResponseType;
  headers?: { Authorization?: string };
}

export interface PaginationParams {
  page: number;
  per_page: number;
}

const ROUTES = {
  // === BASICS ===

  FETCH_STATUSES: () => ({
    api: API.App,
    path: "/statuses/",
    method: Method.Get,
    auth: true,
  }),
  FETCH_SOURCES: () => ({
    api: API.App,
    path: "/sources/",
    method: Method.Get,
    auth: true,
  }),
  FETCH_CALL_STATUSES: () => ({
    api: API.App,
    path: "/call_statuses/",
    method: Method.Get,
    auth: true,
  }),
  FETCH_CATEGORY_TYPES: () => ({
    api: API.App,
    path: "/category_types/",
    method: Method.Get,
    auth: false,
  }),
  FETCH_BANK_ACCOUNTS: () => ({
    api: API.App,
    path: "/bank_accounts/",
    method: Method.Get,
    auth: true,
  }),
  GEOCODE: (params: { query: string }) => ({
    api: API.App,
    path: "/geocoding/geocode/",
    method: Method.Get,
    params,
    auth: true,
  }),
  GEOCODE_CITY: (params: { search: string }) => ({
    api: API.App,
    path: "/geocoding/city/",
    method: Method.Get,
    params,
    auth: true,
  }),
  LOOKUP_LOCATION: (id: number) => ({
    api: API.App,
    path: `/geocoding/lookup/${id}/`,
    method: Method.Get,
    auth: true,
  }),
  CANDIDATE_LOCATIONS: (candidateId: UUID) => ({
    api: API.App,
    path: `/candidates/${candidateId}/locations/`,
    method: Method.Get,
    auth: true,
  }),
  GET_GMAIL_EMAIL_SIGNATURE: (email: string, token: string) => ({
    api: API.Google,
    path: `/gmail/v1/users/me/settings/sendAs/${email}/`,
    method: Method.Get,
    headers: { Authorization: `Bearer ${token}` },
  }),

  // === USERS ===

  LOGIN: (data: LoginPayload) => ({
    api: API.App,
    path: "/users/login/",
    method: Method.Post,
    auth: false,
    data,
  }),
  REFRESH_JWT: (token: string) => ({
    api: API.App,
    path: `/users/refresh/${token}/`,
    method: Method.Get,
    auth: false,
  }),
  FETCH_USERS: () => ({
    api: API.App,
    path: "/users/",
    method: Method.Get,
    auth: true,
  }),
  FETCH_USER: (id: UUID) => ({
    api: API.App,
    path: `/users/${id}/`,
    method: Method.Get,
    auth: true,
  }),
  CREATE_USER: (params: NewUser) => ({
    api: API.App,
    path: "/users/",
    method: Method.Post,
    data: params,
    auth: true,
  }),
  UPDATE_USER: (params: UserData) => ({
    api: API.App,
    path: `/users/${params.id}/`,
    method: Method.Patch,
    data: params,
    auth: true,
  }),
  DELETE_USER: (id: UUID) => ({
    api: API.App,
    path: `/users/${id}/`,
    method: Method.Delete,
    auth: true,
  }),
  LOCK_UNLOCK_USER: (id: UUID) => ({
    api: API.App,
    path: `/users/${id}/lock/`,
    method: Method.Patch,
    auth: true,
  }),
  FETCH_ME: () => ({
    api: API.App,
    path: `/users/me/`,
    method: Method.Get,
    auth: true,
  }),
  FETCH_MY_SETTINGS: () => ({
    api: API.App,
    path: `/users/me/settings/`,
    method: Method.Get,
    auth: true,
  }),
  UPDATE_MY_SETTINGS: (data: UserSettings) => ({
    api: API.App,
    path: `/users/me/settings/`,
    method: Method.Patch,
    data,
    auth: true,
  }),
  UPDATE_MODULE_IN_SETTINGS: (data: {
    dashboard_slug: string;
    module_key: string;
    filters: unknown;
    title?: string;
  }) => ({
    api: API.App,
    path: `/users/me/settings/module/`,
    method: Method.Patch,
    data,
    auth: true,
  }),
  SAVE_QUERY: (data: Omit<SavedCandidateQuery, "id">) => ({
    api: API.App,
    path: `/users/me/settings/query/`,
    method: Method.Post,
    data,
    auth: true,
  }),
  DELETE_SAVED_QUERY: (id: UUID) => ({
    api: API.App,
    path: `/users/me/settings/query/${id}/`,
    method: Method.Delete,
    auth: true,
  }),

  // === OAUTH ===

  GOOGLE_OAUTH: (params: { redirect_url: string }) => ({
    api: API.App,
    path: "/oauth/google/auth/",
    method: Method.Get,
    auth: false,
    params,
  }),
  GOOGLE_OAUTH_CB: (params: OauthPayload) => ({
    api: API.App,
    path: "/oauth/google/callback/",
    method: Method.Get,
    auth: false,
    params,
  }),
  GET_OAUTH_ACCESS_TOKEN: () => ({
    api: API.App,
    path: `/oauth/token/`,
    method: Method.Get,
    auth: true,
  }),

  // === JOB APPLICATIONS ===

  FETCH_TEMPLATE: (id: UUID, kind: "accept" | "reject" | UUID) => ({
    api: API.App,
    path: `/job_applications/${id}/template/${kind}/`,
    method: Method.Get,
    auth: true,
  }),
  FETCH_FEEDBACK_TEMPLATE: (id: UUID, kind: "formal" | "informal" | UUID) => ({
    api: API.App,
    path: `/job_applications/${id}/feedback_template/${kind}/`,
    method: Method.Get,
    auth: true,
  }),
  SEND_EMAIL_JOB_APP: (id: UUID, data: EmailData) => ({
    api: API.App,
    path: `/job_applications/${id}/send_mail/`,
    method: Method.Post,
    data,
    auth: true,
  }),

  FETCH_JOB_APPLICATION: (id: UUID) => ({
    api: API.App,
    path: `/job_applications/${id}/`,
    method: Method.Get,
    auth: true,
  }),
  CREATE_JOB_APPLICATION: (params: NewJobApplication) => ({
    api: API.App,
    path: "/job_applications/",
    method: Method.Post,
    data: params,
    auth: true,
  }),
  UPDATE_JOB_APPLICATION: (data: JobApplicationData & { id: UUID }) => ({
    api: API.App,
    path: `/job_applications/${data.id}/`,
    method: Method.Patch,
    data,
    auth: true,
  }),
  DELETE_JOB_APPLICATION: (id: UUID) => ({
    api: API.App,
    path: `/job_applications/${id}/`,
    method: Method.Delete,
    auth: true,
  }),
  FETCH_EVENTS: (id: UUID) => ({
    api: API.App,
    path: `/job_applications/${id}/events/`,
    method: Method.Get,
    auth: true,
  }),

  // === JOB OFFERS ===

  FETCH_JOB_OFFERS: (
    params: PaginationParams & {
      archived?: boolean;
      owned_by_me?: boolean;
      search?: string;
      bu_id?: UUID;
      owner_ids?: UUID[];
      customer_ids?: UUID[];
    }
  ) => ({
    api: API.App,
    path: "/job_offers/",
    method: Method.Get,
    auth: true,
    params,
  }),
  FETCH_JOB_OFFER_JOB_APPLICATIONS: (
    id: UUID,
    params: PaginationParams & {
      status_ids?: UUID[];
      call_status_ids?: UUID[];
      search?: string;
      terminated?: boolean;
      pinned?: boolean;
      sort_by?: keyof JobApplicationResponse;
      sort_direction?: "asc" | "desc";
    }
  ) => ({
    api: API.App,
    path: `/job_offers/${id}/job_applications/`,
    method: Method.Get,
    params,
    auth: true,
  }),
  FETCH_JOB_OFFER: (id: UUID) => ({
    api: API.App,
    path: `/job_offers/${id}/internal/`,
    method: Method.Get,
    auth: true,
  }),
  CREATE_JOB_OFFER: (params: NewJobOffer) => ({
    api: API.App,
    path: "/job_offers/",
    method: Method.Post,
    data: params,
    auth: true,
  }),
  UPDATE_JOB_OFFER: (params: JobOfferData & { id: UUID }) => ({
    api: API.App,
    path: `/job_offers/${params.id}/`,
    method: Method.Patch,
    data: params,
    auth: true,
  }),
  FETCH_JOB_OFFER_GFILES: (joId: UUID) => ({
    api: API.App,
    path: `/job_offers/${joId}/gfiles/`,
    method: Method.Get,
    auth: true,
  }),
  CREATE_JOB_OFFER_GFILES: (joId: UUID, kind: GFileKind) => ({
    api: API.App,
    path: `/job_offers/${joId}/gfiles/`,
    method: Method.Post,
    data: { kind: kind },
    auth: true,
  }),
  REPORT_SUMMARY: (payload: { job_offers: UUID[]; from: string }) => ({
    api: API.App,
    path: `/job_offers/report_summary/`,
    method: Method.Post,
    data: payload,
    auth: true,
  }),

  // === CANDIDATES ===

  FETCH_CANDIDATE_JOB_APPLICATIONS: (id: UUID) => ({
    api: API.App,
    path: `/candidates/${id}/job_applications/`,
    method: Method.Get,
    auth: true,
  }),
  FETCH_COMMENTS: (id: UUID) => ({
    api: API.App,
    path: `/candidates/${id}/comments/`,
    method: Method.Get,
    auth: true,
  }),
  FETCH_CANDIDATE: (id: UUID) => ({
    api: API.App,
    path: `/candidates/${id}/`,
    method: Method.Get,
    auth: true,
  }),
  FETCH_CANDIDATE_FILES: (id: UUID) => ({
    api: API.App,
    path: `/candidates/${id}/files/`,
    method: Method.Get,
    auth: true,
  }),
  UPDATE_CANDIDATE: (data: CandidateData & { id: UUID }) => ({
    api: API.App,
    path: `/candidates/${data.id}/`,
    method: Method.Patch,
    data,
    auth: true,
  }),
  CREATE_CANDIDATE: (data: NewCandidate) => ({
    api: API.App,
    path: "/candidates/",
    method: Method.Post,
    data,
    auth: true,
  }),
  CANDIDATE_ACTION: (data: CandidateQueryAction) => ({
    api: API.App,
    path: "/candidates/action/",
    method: Method.Post,
    data,
    auth: true,
  }),
  ADD_CONTACT: (data: { email?: string; phone?: string; id: UUID }) => ({
    api: API.App,
    path: `/candidates/${data.id}/add_contact/`,
    method: Method.Patch,
    data,
    auth: true,
  }),
  CLEAR_CANDIDATE: (id: UUID, data: ClearCandidateData) => ({
    api: API.App,
    path: `/candidates/${id}/clear/`,
    method: Method.Patch,
    data,
    auth: true,
  }),
  DELETE_CONTACT_CANDIDATE: (data: { email?: string; phone?: string; id: UUID }) => ({
    api: API.App,
    path: `/candidates/${data.id}/delete_contact/`,
    method: Method.Patch,
    data,
    auth: true,
  }),
  SEARCH_CANDIDATES: (params: PaginationParams, query: CandidateSearchQuery) => ({
    api: API.App,
    path: "/candidates/search/",
    method: Method.Post,
    params,
    data: query,
    auth: true,
  }),
  CANDIDATE_UNBLACKLIST: (id: UUID) => ({
    api: API.App,
    path: `/candidates/${id}/unblacklist/`,
    method: Method.Patch,
    auth: true,
  }),
  FETCH_CANDIDATE_CONSENT_TOKEN: (id: UUID) => ({
    api: API.App,
    path: `/candidates/gdpr/${id}/consent_token/`,
    method: Method.Get,
    auth: true,
  }),

  // === CUSTOMERS ===

  FETCH_CUSTOMERS: () => ({
    api: API.App,
    path: "/customers/",
    method: Method.Get,
    auth: true,
  }),
  FETCH_CUSTOMER: (id: UUID) => ({
    api: API.App,
    path: `/customers/${id}/`,
    method: Method.Get,
    auth: true,
  }),
  UPDATE_CUSTOMER: (params: Customer) => ({
    api: API.App,
    path: `/customers/${params.id}/`,
    method: Method.Patch,
    data: params,
    auth: true,
  }),
  CREATE_CUSTOMER: (params: NewCustomer) => ({
    api: API.App,
    path: "/customers/",
    method: Method.Post,
    data: params,
    auth: true,
  }),
  FETCH_CONTACTS_BY_CUSTOMER: (id: UUID) => ({
    api: API.App,
    path: `/customers/${id}/contacts/`,
    method: Method.Get,
    auth: true,
  }),
  DELETE_CUSTOMER: (id: UUID) => ({
    api: API.App,
    path: `/customers/${id}/`,
    method: Method.Delete,
    auth: true,
  }),
  GENERATE_REPORT: (
    id: UUID,
    payload: {
      opportunity_type: "rpo" | "cabinet";
      users: UserResponse[];
      full_jos: ReportFullJobOffer[];
      full_jos_from: ReportFullJobOffer[];
      accomplishments: string[];
      observations: string[];
      goals: string[];
      synthese_only: boolean;
    }
  ) => ({
    api: API.App,
    path: `/customers/${id}/generate_report/`,
    method: Method.Post,
    data: payload,
    auth: true,
    responseType: AxiosResponseType.Blob,
  }),
  // === JOB TITLES ===
  FETCH_JOB_TITLES: (filters: JobTitleQuery) => ({
    api: API.App,
    path: `/job_titles/job_titles/`,
    params: filters,
    method: Method.Get,
    auth: true,
  }),
  FETCH_JOB_TITLE: (id: UUID) => ({
    api: API.App,
    path: `/job_titles/job_titles/${id}`,
    method: Method.Get,
    auth: true,
  }),
  FETCH_ANY_JOB_TILTES: (filters: AnyJobTitleFilters) => ({
    api: API.App,
    path: `/job_titles/job_titles/any`,
    params: filters,
    method: Method.Get,
    auth: true,
  }),
  FETCH_JOB_TITLE_CATEGORIES: (params?: { ids?: UUID[] }) => ({
    api: API.App,
    path: `/job_titles/categories/`,
    params,
    method: Method.Get,
    auth: true,
  }),
  FETCH_CATEGORY_PATH: (id: UUID) => ({
    api: API.App,
    path: `/job_titles/categories/${id}/path`,
    method: Method.Get,
    auth: true,
  }),
  FETCH_HIERARCHIES: () => ({
    api: API.App,
    path: `/job_titles/hierarchies/`,
    method: Method.Get,
    auth: true,
  }),
  CREATE_UNVALIDATED_JOBTITLE: (data: NewUnvalidatedJobTitle) => ({
    api: API.App,
    path: `/job_titles/job_titles/unvalidated/`,
    method: Method.Post,
    data,
    auth: true,
  }),
  DELETE_UNVALIDATED_JOB_TITLE: (id: UUID) => ({
    api: API.App,
    path: `/job_titles/job_titles/unvalidated/${id}`,
    method: Method.Delete,
    auth: true,
  }),
  SUGGEST_SKILLS: (categoryId?: UUID, skills?: UUID[]) => ({
    api: API.App,
    path: `/job_titles/skills/suggest/`,
    method: Method.Get,
    params: {
      category_id: categoryId,
      skills: skills,
    },
    auth: true,
  }),
  FETCH_SKILLS: (params: { name?: string; ids?: UUID[] }) => ({
    api: API.App,
    path: `/job_titles/skills/`,
    method: Method.Get,
    params,
    auth: true,
  }),
  CREATE_SKILL: (payload: NewSkill) => ({
    api: API.App,
    path: `/job_titles/skills/`,
    method: Method.Post,
    data: payload,
    auth: true,
  }),
  DELETE_UNVALIDATED_SKILL: (id: UUID) => ({
    api: API.App,
    path: `/job_titles/skills/unvalidated/${id}/`,
    method: Method.Delete,
    auth: true,
  }),
  // === FILES ===

  UPLOAD_FILE: (params: FilePayload) => ({
    api: API.App,
    path: "/files/",
    method: Method.Post,
    data: params,
    auth: false,
    file: params.file,
  }),
  UPLOAD_PUBLIC_FILE: (params: FilePayload) => ({
    api: API.App,
    path: "/files/public/",
    method: Method.Post,
    data: params,
    auth: true,
    file: params.file,
  }),
  SIGN_FILE: (id: UUID) => ({
    api: API.App,
    path: `/files/sign/${id}/`,
    method: Method.Get,
    auth: true,
  }),
  DOWNLOAD_FILE: (id: UUID, signature: string, bucket_name: string) => ({
    api: API.App,
    path: `/files/download/${id}/${signature}/`,
    method: Method.Get,
    params: { bucket_name },
    auth: true,
  }),
  DELETE_FILE: (id: UUID) => ({
    api: API.App,
    path: `/files/${id}/`,
    method: Method.Delete,
    auth: true,
  }),
  UPDATE_FILE: (data: FileData) => ({
    api: API.App,
    path: `/files/${data.id}/`,
    method: Method.Patch,
    data,
    auth: true,
  }),

  // === CATEGORIES ===

  FETCH_CATEGORIES: () => ({
    api: API.App,
    path: "/categories/",
    method: Method.Get,
    auth: false,
  }),
  TERMINATE_JOB_APPLICATION: (id: UUID, data: JobapplicationTerminationRequest) => ({
    api: API.App,
    path: `/job_applications/${id}/terminate/`,
    method: Method.Post,
    data,
    auth: true,
  }),
  RESTART_JOB_APPLICATION: (id: UUID) => ({
    api: API.App,
    path: `/job_applications/${id}/restart/`,
    method: Method.Post,
    auth: true,
  }),
  CREATE_CATEGORY: (params: NewCategory) => ({
    api: API.App,
    path: "/categories/",
    method: Method.Post,
    data: params,
    auth: true,
  }),
  UPDATE_CATEGORY: (params: Category) => ({
    api: API.App,
    path: `/categories/${params.id}/`,
    method: Method.Patch,
    data: params,
    auth: true,
  }),
  DELETE_CATEGORY: (id: UUID) => ({
    api: API.App,
    path: `/categories/${id}/`,
    method: Method.Delete,
    auth: true,
  }),

  // === COMMENTS ===

  CREATE_COMMENT: (data: NewComment) => ({
    api: API.App,
    path: `/comments/`,
    method: Method.Post,
    data,
    auth: true,
  }),

  // === CONTACTS ===

  CREATE_CONTACT: (params: NewContact) => ({
    api: API.App,
    path: "/contacts/",
    method: Method.Post,
    data: params,
    auth: true,
  }),
  FETCH_CONTACTS: () => ({
    api: API.App,
    path: "/contacts/",
    method: Method.Get,
    auth: true,
  }),
  UPDATE_CONTACT: (params: Partial<Contact> & { id: UUID }) => ({
    api: API.App,
    path: `/contacts/${params.id}/`,
    method: Method.Patch,
    data: params,
    auth: true,
  }),

  // === NOTIFICATIONS ===

  GET_NOTIFICATION_WS: (token: string) => ({
    api: API.App,
    path: `/notifications/ws/${token}/`,
    method: Method.Get,
  }),
  READ_NOTIFICATIONS: () => ({
    api: API.App,
    path: "/notifications/read/",
    method: Method.Patch,
    auth: true,
  }),

  // === META ===

  FETCH_PERMISSIONS: () => ({
    api: API.App,
    path: `/meta/permissions/`,
    method: Method.Get,
    auth: true,
  }),
  CLEAR_MEILISEARCH_DATA: () => ({
    api: API.App,
    path: `/meta/indices/clear/`,
    method: Method.Get,
    auth: true,
  }),
  POPULATE_MEILISEARCH_DATA: () => ({
    api: API.App,
    path: `/meta/indices/populate/`,
    method: Method.Get,
    auth: true,
  }),

  // === BUSINESS UNITS ===

  FETCH_BUSINESS_UNITS: () => ({
    api: API.App,
    path: "/business_units/",
    method: Method.Get,
    auth: true,
  }),
  CREATE_BUSINESS_UNIT: (params: NewBusinessUnit) => ({
    api: API.App,
    path: "/business_units/",
    method: Method.Post,
    data: params,
    auth: true,
  }),
  UPDATE_BUSINESS_UNIT: (params: BusinessUnitData) => ({
    api: API.App,
    path: `/business_units/${params.id}/`,
    method: Method.Patch,
    data: params,
    auth: true,
  }),
  DELETE_BUSINESS_UNIT: (id: UUID) => ({
    api: API.App,
    path: `/business_units/${id}/`,
    method: Method.Delete,
    auth: true,
  }),

  // === EMAIL TEMPLATES ===

  GENERATE_TEMPLATE: (id: UUID, vars: BillingVars) => ({
    api: API.App,
    path: `/email_templates/template/${id}/generate/`,
    method: Method.Post,
    data: vars,
    auth: true,
  }),
  FETCH_MY_EMAIL_TEMPLATES: () => ({
    api: API.App,
    path: `/email_templates/mine/`,
    method: Method.Get,
    auth: true,
  }),
  CREATE_EMAIL_TEMPLATE: (params: NewEmailTemplate) => ({
    api: API.App,
    path: "/email_templates/",
    method: Method.Post,
    data: params,
    auth: true,
  }),
  UPDATE_EMAIL_TEMPLATE: (params: EmailTemplateData) => ({
    api: API.App,
    path: `/email_templates/${params.id}/`,
    method: Method.Patch,
    data: params,
    auth: true,
  }),
  DELETE_EMAIL_TEMPLATE: (id: UUID) => ({
    api: API.App,
    path: `/email_templates/${id}/`,
    method: Method.Delete,
    auth: true,
  }),

  // === EMAILS ===

  GET_JA_EMAILS: (jaId: UUID) => ({
    api: API.App,
    path: `/emails/job_application/${jaId}/`,
    method: Method.Get,
    auth: true,
  }),
  GET_THREAD: (threadId: UUID) => ({
    api: API.App,
    path: `/emails/thread/${threadId}/`,
    method: Method.Get,
    auth: true,
  }),

  // === ROLES ===

  CREATE_ROLE: (params: NewRole) => ({
    api: API.App,
    path: "/roles/",
    method: Method.Post,
    data: params,
    auth: true,
  }),
  UPDATE_ROLE: (data: RoleData & { id: UUID }) => ({
    api: API.App,
    path: `/roles/${data.id}/`,
    method: Method.Patch,
    data,
    auth: true,
  }),
  DELETE_ROLE: (id: UUID, target_id: UUID) => ({
    api: API.App,
    path: `/roles/${id}/`,
    method: Method.Delete,
    params: { target_id },
    auth: true,
  }),
  FETCH_ROLES: () => ({
    api: API.App,
    path: "/roles/",
    method: Method.Get,
    auth: true,
  }),

  // === CONTRACTS (BILLING) ===

  FETCH_CONTRACT: (id: UUID) => ({
    api: API.App,
    path: `/billing/contracts/${id}/`,
    method: Method.Get,
    auth: true,
  }),
  FETCH_CONTRACTS: (params: ContractsFilters) => ({
    api: API.App,
    path: "/billing/contracts/",
    method: Method.Get,
    auth: true,
    params,
  }),
  UPDATE_CONTRACT: (data: ContractData & { id: UUID }) => ({
    api: API.App,
    path: `/billing/contracts/${data.id}/`,
    method: Method.Patch,
    data,
    auth: true,
  }),
  CREATE_CONTRACT: (params: NewContract) => ({
    api: API.App,
    path: "/billing/contracts/",
    method: Method.Post,
    data: params,
    auth: true,
  }),
  FETCH_CONTACTS_BY_CONTRACT: (id: UUID) => ({
    api: API.App,
    path: `/billing/contracts/${id}/contacts/`,
    method: Method.Get,
    auth: true,
  }),

  // === PROJECTS (BILLING) ===

  FETCH_PROJECT: (id: UUID) => ({
    api: API.App,
    path: `/billing/projects/${id}/`,
    method: Method.Get,
    auth: true,
  }),
  FETCH_PROJECTS: (params: ProjectsFilters & { customer_id?: UUID }) => ({
    api: API.App,
    path: "/billing/projects/",
    method: Method.Get,
    auth: true,
    params,
  }),
  CREATE_PROJECT: (params: NewProject) => ({
    api: API.App,
    path: "/billing/projects/",
    method: Method.Post,
    data: params,
    auth: true,
  }),
  UPDATE_PROJECT: (data: ProjectData & { id: UUID }) => ({
    api: API.App,
    path: `/billing/projects/${data.id}/`,
    method: Method.Patch,
    data,
    auth: true,
  }),
  SET_PROJECT_CONTACTS: (id: UUID, contacts: UUID[]) => ({
    api: API.App,
    path: `/billing/projects/${id}/contacts/`,
    method: Method.Patch,
    data: { contacts_ids: contacts },
    auth: true,
  }),
  FETCH_AMOUNT_PAID: (id: UUID) => ({
    api: API.App,
    path: `/billing/projects/${id}/amount_paid/`,
    method: Method.Get,
    auth: true,
  }),
  FETCH_CONTACTS_BY_PROJECT: (id: UUID) => ({
    api: API.App,
    path: `/billing/projects/${id}/contacts/`,
    method: Method.Get,
    auth: true,
  }),
  FETCH_PROJECT_BILLS_DETAILS: (params: { project_id: UUID }) => ({
    api: API.App,
    path: "/billing/projects/bills_details/",
    method: Method.Get,
    auth: true,
    params,
  }),

  // === BILLS (BILLING0) ===

  FETCH_BILL: (id: UUID) => ({
    api: API.App,
    path: `/billing/bills/${id}/`,
    method: Method.Get,
    auth: true,
  }),
  FETCH_BILLS: (
    params: BillsFilters & {
      project_types?: ProjectType[];
    }
  ) => ({
    api: API.App,
    path: "/billing/bills/",
    method: Method.Get,
    auth: true,
    params,
  }),
  CREATE_BILL: (params: NewBill) => ({
    api: API.App,
    path: "/billing/bills/",
    method: Method.Post,
    data: params,
    auth: true,
  }),
  UPDATE_BILL: (data: BillData & { id: UUID }) => ({
    api: API.App,
    path: `/billing/bills/${data.id}/`,
    method: Method.Patch,
    data,
    auth: true,
  }),
  DELETE_BILL: (id: UUID) => ({
    api: API.App,
    path: `/billing/bills/${id}/`,
    method: Method.Delete,
    auth: true,
  }),
  GENERATE_BILL: (bill_id: UUID) => ({
    api: API.App,
    path: `/billing/bills/${bill_id}/generate_bill_pdf/`,
    method: Method.Patch,
    auth: true,
  }),
  GENERATE_BILL_ZIP: (period: { from: string; to: string }) => ({
    api: API.App,
    path: `/billing/bills/generate_zip/`,
    method: Method.Get,
    auth: true,
    params: period,
  }),
  PREVIEW_BILL: (bill_id: UUID, jwt: string) => ({
    api: API.App,
    path: `/billing/bills/${bill_id}/preview_pdf/`,
    method: Method.Get,
    auth: true,
    params: { jwt },
  }),
  CSV_RESULTS: (id: UUID, signature: string, period: { from: string; to: string }) => ({
    api: API.App,
    path: `/billing/bills/results/${id}/${signature}/`,
    method: Method.Get,
    params: period,
    auth: true,
  }),

  // === BOOKINGS (BILLING) ===

  FETCH_BOOKINGS: (params?: BookingsFilters) => ({
    api: API.App,
    path: "/billing/bookings/",
    method: Method.Get,
    auth: true,
    params,
  }),
  CREATE_BOOKING: (params: NewBooking) => ({
    api: API.App,
    path: "/billing/bookings/",
    method: Method.Post,
    data: params,
    auth: true,
  }),
  UPDATE_BOOKING: (data: BookingData & { id: UUID }) => ({
    api: API.App,
    path: `/billing/bookings/${data.id}/`,
    method: Method.Patch,
    data,
    auth: true,
  }),
  DELETE_BOOKING: (id: UUID) => ({
    api: API.App,
    path: `/billing/bookings/${id}/`,
    method: Method.Delete,
    auth: true,
  }),

  // === CHARTS (BILLING) ===

  BILLS_AMOUNT_BY_STATUS_AND_DAY: (params: DateFilters) => ({
    api: API.App,
    path: `/billing/charts/bills/`,
    method: Method.Get,
    params,
    auth: true,
  }),
  GET_STATS_CONTACTED: (params: {
    from?: string;
    to?: string;
    granularity?: string;
    users?: UUID[];
    business_units?: UUID[];
    job_offers?: UUID[];
    customers?: UUID[];
    contact_types: string[];
  }) => ({
    api: API.App,
    path: `/dashboards/charts/contacted/`,
    method: Method.Get,
    params,
    auth: true,
  }),
  GET_STATS_STATUSES: (params: {
    from?: string;
    to?: string;
    compare_from?: string;
    compare_to?: string;
    granularity?: string;
    users?: UUID[];
    business_units?: UUID[];
    job_offers?: UUID[];
    customers?: UUID[];
    candidates?: boolean;
    jos?: boolean;
    statuses_ids: UUID[];
    opportunity_type?: "cabinet" | "rpo" | "interne";
    field?: string;
    order?: string;
    chart_type: string;
  }) => ({
    api: API.App,
    path: `/dashboards/charts/statuses/`,
    method: Method.Get,
    params,
    auth: true,
  }),
  GET_STATS_CALL_STATUSES: (params: {
    from?: string;
    to?: string;
    compare_from?: string;
    compare_to?: string;
    granularity?: string;
    users?: UUID[];
    business_units?: UUID[];
    job_offers?: UUID[];
    customers?: UUID[];
    call_status_ids: UUID[];
    field?: string;
    order?: string;
  }) => ({
    api: API.App,
    path: `/dashboards/charts/call_statuses/`,
    method: Method.Get,
    params,
    auth: true,
  }),
  GET_CANDIDATES_PIPE: (params: {
    users?: UUID[];
    business_units?: UUID[];
    job_offers?: UUID[];
    customers?: UUID[];
    statuses_ids?: UUID[];
    archived?: boolean;
    candidates?: boolean;
    field?: string;
    order?: string;
  }) => ({
    api: API.App,
    path: `/dashboards/charts/pipe/`,
    method: Method.Get,
    params,
    auth: true,
  }),
  GET_STATS_SOURCING: (params: {
    from?: string;
    to?: string;
    compare_from?: string;
    compare_to?: string;
    granularity?: string;
    users?: UUID[];
    business_units?: UUID[];
    job_offers?: UUID[];
    customers?: UUID[];
    sources_ids: UUID[];
  }) => ({
    api: API.App,
    path: `/dashboards/charts/sourcing/`,
    method: Method.Get,
    params,
    auth: true,
  }),
  GET_STATS_TERMINATED: (params: {
    from?: string;
    to?: string;
    compare_from?: string;
    compare_to?: string;
    granularity?: string;
    users?: UUID[];
    business_units?: UUID[];
    job_offers?: UUID[];
    customers?: UUID[];
    termination_sources?: UUID[];
  }) => ({
    api: API.App,
    path: `/dashboards/charts/terminated/`,
    method: Method.Get,
    params,
    auth: true,
  }),
  GET_STATS_BOOKING: (params: {
    from?: string;
    to?: string;
    // compare_from?: string;
    // compare_to?: string;
    granularity?: string;
    users?: UUID[];
    business_units?: UUID[];
    job_offers?: UUID[];
    customers?: UUID[];
    chart_type: string;
    bookings?: boolean;
  }) => ({
    api: API.App,
    path: `/dashboards/charts/booking/`,
    method: Method.Get,
    params,
    auth: true,
  }),
  GET_STATS_RETAINERS: (params: {
    from?: string;
    to?: string;
    granularity?: string;
    users?: UUID[];
    business_units?: UUID[];
    job_offers?: UUID[];
    customers?: UUID[];
    chart_type: string;
    retainers?: boolean;
  }) => ({
    api: API.App,
    path: `/dashboards/charts/retainers/`,
    method: Method.Get,
    params,
    auth: true,
  }),
  GET_GLOBAL_DASHBOARDS: () => ({
    api: API.App,
    path: `/dashboards/global/`,
    method: Method.Get,
    auth: true,
  }),
  VIEW_GLOBAL_DASHBOARDS: () => ({
    api: API.App,
    path: `/dashboards/global/view/`,
    method: Method.Get,
    auth: true,
  }),
  CREATE_GLOBAL_DASHBOARD: (params: Partial<GlobalDashboard>) => ({
    api: API.App,
    path: "/dashboards/global/",
    method: Method.Post,
    data: params,
    auth: true,
  }),
  UPDATE_GLOBAL_DASHBOARD: (params: Partial<GlobalDashboard>) => ({
    api: API.App,
    path: `/dashboards/global/${params.id}/`,
    method: Method.Patch,
    data: params,
    auth: true,
  }),
  DELETE_GLOBAL_DASHBOARD: (id: UUID) => ({
    api: API.App,
    path: `/dashboards/global/${id}/`,
    method: Method.Delete,
    auth: true,
  }),

  // === SIGNALS ===

  GET_SIGNAL_TARGETS: () => ({
    api: API.App,
    path: `/signals/`,
    method: Method.Get,
    auth: true,
  }),
  // When using this route, please add a QS serialized NewSignal in the path (c.f backend)
  GET_SIGNAL_PREVIEW: (nth: number) => ({
    api: API.App,
    path: `/signals/preview/${nth}/`,
    method: Method.Get,
    auth: true,
  }),
  CREATE_SIGNAL_TARGET: (data: NewSignalTarget) => ({
    api: API.App,
    path: `/signals/targets/`,
    method: Method.Post,
    data,
    auth: true,
  }),
  UPDATE_SIGNAL_TARGET: (data: UpdateSignalTarget & { id: UUID }) => ({
    api: API.App,
    path: `/signals/targets/${data.id}/`,
    method: Method.Patch,
    data,
    auth: true,
  }),
  CREATE_SIGNAL: (data: NewSignal) => ({
    api: API.App,
    path: `/signals/`,
    method: Method.Post,
    data,
    auth: true,
  }),
  UPDATE_SIGNAL: (data: UpdateSignal & { id: UUID }) => ({
    api: API.App,
    path: `/signals/${data.id}/`,
    method: Method.Patch,
    data,
    auth: true,
  }),
  DELETE_SIGNAL: (id: UUID) => ({
    api: API.App,
    path: `/signals/${id}/`,
    method: Method.Delete,
    auth: true,
  }),
  DELETE_SIGNAL_TARGET: (id: UUID) => ({
    api: API.App,
    path: `/signals/targets/${id}/`,
    method: Method.Delete,
    auth: true,
  }),
  SUPERSET_TOKEN: () => ({
    api: API.App,
    path: "/dashboards/global/token/",
    method: Method.Post,
    auth: true,
  }),
};

export default ROUTES;
