import React, { FunctionComponent } from "react";
import { SearchResults, JobOffersResult, CandidatesResult, CompaniesResult } from "./index";
import { CandidateResult } from "./candidateResult";
import { CompanyResult } from "./companyResult";
import { JobOfferResult } from "./jobOfferResult";
import { useNavigate } from "react-router";
import store, { OmniSearchResultType } from "../../../services/store";

export type OmnisearchResultsProps = {
  results: SearchResults;
  closeModal: () => void;
};

export const OmnisearchResults: FunctionComponent<OmnisearchResultsProps> = ({ results, closeModal }) => {
  const jobOffers = results.find((r) => r.indexUid === "job_offers") as JobOffersResult | undefined;
  const candidates = results.find((r) => r.indexUid === "candidates") as CandidatesResult | undefined;
  const companies = results.find((r) => r.indexUid === "companies") as CompaniesResult | undefined;
  const noResults = !(jobOffers?.hits.length || candidates?.hits.length || companies?.hits.length);

  const navigate = useNavigate();

  if (noResults) return <div className="text-lg my-8 text-center">Aucun résultat</div>;

  return (
    <div className="overflow-auto">
      {!!jobOffers?.hits.length && (
        <div>
          <div className="text-xs font-bold text-content-light my-2">Offres</div>
          <div>
            {jobOffers?.hits.map((e) => (
              <div
                className="cursor-pointer"
                key={e.id}
                onClick={() => {
                  store.pushOmnisearchHistory(e, OmniSearchResultType.JobOffer);

                  navigate(`/jobs/${e.id}`);
                  closeModal();
                }}
              >
                <JobOfferResult {...e} />
              </div>
            ))}
          </div>
        </div>
      )}
      {!!candidates?.hits.length && (
        <div>
          <div className="text-xs font-bold text-content-light my-2">Candidats</div>

          <div>
            {candidates?.hits.map((e) => (
              <div
                className="cursor-pointer"
                key={e.id}
                onClick={() => {
                  store.pushOmnisearchHistory(e, OmniSearchResultType.Candidate);
                  navigate(`/candidates?id=${e.id}`);
                  closeModal();
                }}
              >
                <CandidateResult {...e} key={e.id} />
              </div>
            ))}
          </div>
        </div>
      )}
      {!!companies?.hits.length && (
        <div>
          <div className="text-xs font-bold text-content-light my-2">Entreprises</div>

          <div>
            {companies?.hits.map((e) => (
              <div
                className="cursor-pointer"
                key={e.id}
                onClick={() => {
                  store.pushOmnisearchHistory(e, OmniSearchResultType.Company);

                  navigate(`/billing/customers/${e.id}`);
                  closeModal();
                }}
              >
                <CompanyResult {...e} />
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
