import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from "react";
import { Candidate } from "../../../models/candidate";
import { UUID } from "../../../models/common";
import { JobTitle, deleteUnvalidatedJobTitle, fetchAnyJobTitle } from "../../../models/jobTitle";
import { Category, fetchCategoryPath, fetchJobTitleCategories } from "../../../models/jobTitleCategory";
import { Hierarchy, fetchHierarchies } from "../../../models/jobTitleHierarchy";
import { DisplayedJobTitle, JobTitleElement, JobTitleSource } from "./jobTitleElement";
import { OtherJobTitle } from "./otherJobTitle";
import LinkedinIcon from "../../../utils/icons";

const getDefaultSource = (candidate: Partial<Candidate>): JobTitleSource | undefined => {
  if (candidate.job_title_id) return JobTitleSource.Title;
  if (
    !candidate.job_title_inputs?.suggested_title_id &&
    !candidate.job_title_id &&
    !candidate.job_title_inputs?.candidate_selection
  )
    return JobTitleSource.Other;
  return undefined;
};

const getDisplayedTitleById = async (
  jobTitleId: UUID,
  source: JobTitleSource,
  titles: JobTitle[]
): Promise<DisplayedJobTitle> => {
  const jobTitle = titles.find((t) => t.id === jobTitleId);
  if (!jobTitle) {
    throw "Titles does not contains requested title_id";
  }
  const current_categories: Category[] = await fetchCategoryPath(jobTitle.category_id);
  return {
    source,
    title: jobTitle,
    categories: current_categories.map((c) => c.title).reverse(),
    kind: "selection",
  };
};

const getDisplayedTitles = async (candidate: Partial<Candidate>) => {
  const displayed: DisplayedJobTitle[] = [];

  const suggested = candidate.job_title_inputs?.suggested_title_id;
  const candidateSelection = candidate.job_title_inputs?.candidate_selection;
  const currentTitle = candidate.job_title_id;

  const ids = [];
  if (currentTitle) {
    ids.push(currentTitle);
  }

  if (candidateSelection && candidateSelection.kind === "selection") {
    ids.push(candidateSelection.id);
  }

  if (suggested) {
    ids.push(suggested);
  }

  const titles = await fetchAnyJobTitle({ ids });

  if (
    suggested &&
    !currentTitle &&
    !(candidateSelection?.kind === "selection" && candidateSelection.id === suggested)
  ) {
    const title = await getDisplayedTitleById(suggested, JobTitleSource.Sweeft, titles);
    if (title) displayed.push(title);
  }

  if (candidateSelection) {
    const source = JobTitleSource.Candidate;
    const title =
      candidateSelection.kind === "selection"
        ? await getDisplayedTitleById(
            candidateSelection.id,
            candidateSelection.id === suggested ? JobTitleSource.SweeftAndCandidate : source,
            titles
          )
        : {
            source,
            title: candidateSelection.input,
            categories: [],
            kind: candidateSelection.kind,
          };
    if (title) displayed.push(title);
  }

  if (currentTitle) {
    const title = await getDisplayedTitleById(currentTitle, JobTitleSource.Title, titles);
    if (title) displayed.push(title);
  }

  return displayed;
};

interface PendingJobTitle {
  source: JobTitleSource;
  jobTitle: JobTitle;
  validated: boolean;
}

interface Props {
  candidate: Candidate;
  error?: string;
  loading?: boolean;
  onChange: (title?: JobTitle) => void;
}

export const JobTitleRgpdForm: FunctionComponent<Props> = ({ candidate, onChange, error, ...props }) => {
  const [displayedTitles, setDisplayedTitles] = useState<DisplayedJobTitle[]>([]);
  const [selectedSource, setSelectedSource] = useState<JobTitleSource | undefined>(getDefaultSource(candidate));
  const [selectedJobTitle, setSelectedJobTitle] = useState<JobTitle>();
  const [categories, setCategories] = useState<Category[]>([]);
  const [hierarchies, setHierarchies] = useState<Hierarchy[]>([]);
  const [pendingJobTitles, setPendingJobTitles] = useState<PendingJobTitle[]>([]);
  const pendingRef = useRef<PendingJobTitle[]>();
  const selectedRef = useRef<JobTitle>();

  useEffect(() => {
    pendingRef.current = pendingJobTitles;
  }, [pendingJobTitles]);

  useEffect(() => {
    selectedRef.current = selectedJobTitle;
    onChange(selectedJobTitle);
  }, [selectedJobTitle]);

  useEffect(() => {
    const jobTitle = pendingJobTitles.find((jt) => selectedSource === jt.source);
    setSelectedJobTitle(jobTitle?.jobTitle);
  }, [selectedSource, pendingJobTitles]);

  useEffect(() => {
    return () => {
      // Delete unused unvalidated job titles
      const pendingJobTitles = pendingRef.current || [];
      const selectedJobTitle = selectedRef.current;
      pendingJobTitles
        .filter((jt) => jt.jobTitle.id !== selectedJobTitle?.id && jt.validated === false)
        .forEach((jt) => {
          deleteUnvalidatedJobTitle(jt.jobTitle.id);
        });
    };
  }, []);

  const initJobTitles = useCallback(async () => {
    const displayed = await getDisplayedTitles(candidate);
    const categories = await fetchJobTitleCategories();
    const hierarchies = await fetchHierarchies();

    setCategories(categories);
    setHierarchies(hierarchies);
    setDisplayedTitles(displayed);
    setSelectedJobTitle(displayed.find((v) => v.source === JobTitleSource.Title)?.title as JobTitle | undefined);
  }, [candidate]);

  useEffect(() => {
    if (!props.loading) initJobTitles();
  }, [candidate, props.loading]);

  const onPendingJobTitleChange = (title: JobTitle | undefined, source: JobTitleSource, validated?: boolean) => {
    const newPendingJobTitles = pendingJobTitles.filter((p) => p.source !== source);
    if (title) {
      newPendingJobTitles.push({ jobTitle: title, source: source, validated: !!validated });
    }
    setPendingJobTitles(newPendingJobTitles);
    setSelectedJobTitle(title);
  };

  return (
    <div className="flex flex-col gap-4">
      <div className="font-bold flex items-center">
        <div className="font-bold text-2xs-g">
          Choisir le titre de poste correspondant le mieux au profil de {candidate.full_name}
        </div>
        {candidate.linkedin_url && (
          <a
            onClick={(e) => e.stopPropagation()}
            href={candidate.linkedin_url}
            target="_blank"
            rel="noreferrer"
            className="ml-1 p-1"
          >
            <LinkedinIcon className="w-4 h-4" />
          </a>
        )}
      </div>
      {displayedTitles.map((jt) => (
        <div
          key={jt.source}
          onClick={() => {
            if (jt.kind === "selection") {
              onPendingJobTitleChange(jt.title, jt.source, true);
              setSelectedSource(jt.source);
            } else {
              setSelectedSource(jt.source);
            }
          }}
        >
          <JobTitleElement
            categories={categories}
            hierarchies={hierarchies}
            collapsed={!pendingJobTitles.find((title) => title.source === jt.source)}
            selected={jt.source === selectedSource}
            onChange={(t) => onPendingJobTitleChange(t, jt.source)}
            jobTitle={jt}
          />
        </div>
      ))}
      <div onClick={() => setSelectedSource(JobTitleSource.Other)}>
        <OtherJobTitle
          label={displayedTitles.length ? "Proposez un autre titre de poste" : "Proposez un titre de poste"}
          selected={selectedSource === JobTitleSource.Other}
          collapsed={!pendingJobTitles.find((t) => t.source === JobTitleSource.Other)}
          onChange={(t?: JobTitle, v?: boolean) => onPendingJobTitleChange(t, JobTitleSource.Other, v)}
        />
      </div>
      {error && <div className="text-error-normal italic">{error}</div>}
    </div>
  );
};
