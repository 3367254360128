import React, { FunctionComponent, useEffect, useState } from "react";
import { UserResponse, UserData } from "../../models/users/users";
import HtmlEditor from "../../components/forms/inputs/htmlEditor";
import {
  EmailTemplate,
  EmailTemplateData,
  NewEmailTemplate,
  createEmailTemplate,
  updateEmailTemplate,
  deleteEmailTemplate,
  getMyEmailTemplates,
} from "../../models/emailTemplates";
import ColorPalette from "../../components/forms/inputs/colorPalette";
import store from "../../services/store";
import { UUID } from "../../models/common";
import { Divider, Modal, Button, TextInput, PopConfirm } from "@getprorecrutement/getpro-design";
import { toast } from "react-hot-toast";
import { TrashIcon } from "@heroicons/react/24/outline";
interface Props {
  user?: UserResponse;
  onSubmit: (data: UserData) => Promise<void>;
}

export const TemplatesForm: FunctionComponent<Props> = (props) => {
  const [customTemplates, setCustomTemplates] = useState<EmailTemplate[]>([]);
  const [selectedEt, setSelectedEt] = useState<Partial<EmailTemplate>>();
  const [colorPalette, setColorPalette] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState<Partial<UserResponse>>(props.user || {});

  useEffect(() => {
    setValues(props.user || {});
  }, [props.user]);

  useEffect(() => {
    getMyEmailTemplates().then(setCustomTemplates);
  }, []);

  const onSubmit = (data: UserData) => {
    setLoading(true);
    props.onSubmit(data).then(() => setLoading(false));
  };

  const submitCustomEt = async (data: EmailTemplateData) => {
    setLoading(true);

    await (selectedEt?.id
      ? updateEmailTemplate({ ...data, id: selectedEt.id })
      : createEmailTemplate(data as NewEmailTemplate)
    ).finally(() => setLoading(false));

    toast.success("Modèle enregistré avec succès");
    setSelectedEt(undefined);
    getMyEmailTemplates().then(setCustomTemplates);
  };

  const deleteEt = async (id: UUID) => {
    await deleteEmailTemplate(id).finally(() => setLoading(false));

    toast.success("Modèle supprimé avec succès");
    getMyEmailTemplates().then(setCustomTemplates);
  };

  return (
    <div className="userTemplateForm bg-white">
      <Divider title="Modèle d'email d'acceptation" light />
      <TextInput
        light
        className="mb-2"
        type="text"
        placeholder="Offre : [:job_offer_name:]"
        label="Sujet d'email"
        onChange={(e) => setValues((old) => ({ ...old, accept_template_subject: e.target.value }))}
        value={values.accept_template_subject}
      />
      <HtmlEditor
        onChange={(val) => setValues((old) => ({ ...old, accept_template_body: val }))}
        cleanInput
        content={props.user?.accept_template_body}
        withVars
      />
      <Divider title="Modèle d'email de refus" light />
      <TextInput
        light
        className="mb-2"
        type="text"
        placeholder="Refus : [:job_offer_name:]"
        label="Sujet d'email"
        onChange={(e) => setValues((old) => ({ ...old, reject_template_subject: e.target.value }))}
        value={values.reject_template_subject}
      />
      <HtmlEditor
        onChange={(val) => setValues((old) => ({ ...old, reject_template_body: val }))}
        cleanInput
        content={props.user?.reject_template_body}
        withVars
      />
      <div className="flex justify-end">
        <Button
          size="small"
          disabled={
            !(
              (values.accept_template_subject && values.accept_template_body) ||
              (values.reject_template_subject && values.reject_template_body)
            )
          }
          onClick={() => onSubmit(values as UserData)}
          loading={loading}
          light
          title="Enregistrer"
        />
      </div>
      <Divider title="Modèles personnalisés" light />
      {customTemplates.map((et) => (
        <div className="flex gap-2 items-center my-2 w-fit" key={et.id}>
          <div
            style={{
              backgroundColor: `${et.color}`,
            }}
            className="w-3 h-3 min-w-fit min-h-fit rounded-md"
          />
          <div className="cursor-pointer" onClick={() => setSelectedEt(et)}>
            {et.name}
          </div>
          <div className="hover:text-red-400">
            <PopConfirm title={"Supprimer le modèle ?"} onValidate={() => deleteEt(et.id)} light>
              <TrashIcon width={20} height={20} />
            </PopConfirm>
          </div>
        </div>
      ))}
      <Button
        onClick={() =>
          setSelectedEt({
            color: "#CCCCCC",
            user_id: store.state.JWT?.sub,
            body: "",
            subject: "",
            name: "",
          })
        }
        title="+ Nouveau template"
        kind="light"
      />
      <Modal light className="w-modal-lg" onClose={() => setSelectedEt(undefined)} show={!!selectedEt}>
        <div className="text-2xl mb-2">
          {selectedEt?.id ? `Éditer ${selectedEt.name || "le modèle"}` : "Nouveau modèle"}
        </div>
        <div className="bg-inherit py-2 pr-4 overflow-auto">
          <TextInput
            light
            className="mb-4"
            type="text"
            label="Nom du modèle"
            placeholder="e.g Notif premier entretien"
            value={selectedEt?.name}
            onChange={(e) => setSelectedEt({ ...selectedEt, name: e.target.value })}
            prefix={
              <div className="color flex items-center justify-center w-10 border-l border-y border-solid">
                <div
                  onClick={() => setColorPalette(true)}
                  className="w-full h-full flex justify-center items-center cursor-pointer"
                >
                  <div
                    className="w-3 h-3 min-w-fit min-h-fit rounded-md"
                    style={{
                      backgroundColor: selectedEt?.color || "#CCCCCC",
                    }}
                  />
                </div>
                {colorPalette && (
                  <ColorPalette
                    toggle={() => setColorPalette((e) => !e)}
                    onChange={(color) => {
                      setSelectedEt({ ...selectedEt, color });
                    }}
                  />
                )}
              </div>
            }
          />
          <TextInput
            light
            className="mb-4"
            label="Sujet d'email"
            type="text"
            value={selectedEt?.subject}
            onChange={(e) => setSelectedEt({ ...selectedEt, subject: e.target.value })}
            placeholder="Sujet (variables autorisées)"
          />
          <HtmlEditor
            onChange={(val) => setSelectedEt({ ...selectedEt, body: val })}
            content={selectedEt?.body}
            withVars
          />
          <div className="flex justify-end mt-4">
            <Button
              size="small"
              loading={loading}
              disabled={!selectedEt?.name || !selectedEt?.subject || !selectedEt.body}
              onClick={() => submitCustomEt(selectedEt as EmailTemplateData)}
              title="Enregistrer"
              light
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default TemplatesForm;
