import classNames from "classnames";
import React, { FunctionComponent } from "react";
import { twMerge } from "tailwind-merge";

export enum TagColor {
  Default = "default",
  Primary = "primary",
  Secondary = "secondary",
  Success = "success",
  Warning = "warning",
  Error = "error",
}

export interface Props {
  value: string | JSX.Element;
  icon?: JSX.Element;
  rounded?: boolean;
  bordered?: boolean;
  bold?: boolean;
  type?: TagColor;
  size?: "small" | "medium" | "large";
  dark?: boolean;
  light?: boolean;
  textClassName?: string;
}

export const Tag: FunctionComponent<Props & React.HTMLAttributes<HTMLDivElement>> = ({
  value,
  icon,
  rounded,
  bordered,
  bold,
  size = "medium",
  dark,
  light,
  textClassName,
  ...props
}) => {
  const type = props.type || TagColor.Default;
  const wrapperClasses = classNames("max-w-full w-fit", {
    dark: dark,
  });

  const classes = twMerge(
    classNames("flex gap-2 items-center w-fit max-w-full font-medium border-solid border", {
      "border-border-lighter text-content-darker": type === TagColor.Default,
      "bg-background-lightest": type === TagColor.Default && !bordered,
      "dark:border-background-medium dark:bg-background-medium dark:text-background-bright":
        type === TagColor.Default && !light,
      "border-primary-lighter bg-primary-bright text-primary-medium": type === TagColor.Primary,
      "dark:border-primary-medium dark:bg-primary-darker dark:text-primary-regular":
        type === TagColor.Primary && !light,
      "dark:border-primary-medium dark:bg-primary-medium dark:text-content-darker":
        type === TagColor.Primary && !light && !bordered,
      "border-secondary-lighter bg-secondary-bright text-secondary-dark": type === TagColor.Secondary,
      "border-success-light bg-success-lighter text-success-dark": type === TagColor.Success,
      "border-warning-light bg-warning-lighter text-warning-dark": type === TagColor.Warning,
      "border-error-light bg-error-lighter text-error-dark": type === TagColor.Error,
      "rounded-full": rounded,
      "rounded-md": !rounded,
      "border-solid border": bordered,
      "px-2 py-1": size === "small",
      "px-1.5 py-1": size === "medium",
      "px-4 py-2": size === "large",
      [`${props.className}`]: !!props.className,
    })
  );

  const textClasses = classNames("text-xs", {
    "text-sm": size === "medium",
    "text-base": size === "large",
    "font-bold": bold,
    [`${textClassName}`]: !!textClassName,
  });

  return (
    <div className={wrapperClasses}>
      <div {...props} className={classes}>
        {icon && icon}
        <div className={textClasses}>{value}</div>
      </div>
    </div>
  );
};
