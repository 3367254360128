import React, { FunctionComponent, useEffect, useRef, useState } from "react";
import {
  ChevronRightIcon,
  ChevronLeftIcon,
  UserCircleIcon,
  ArrowRightOnRectangleIcon,
} from "@heroicons/react/24/outline";
import store, { Stored } from "../../services/store";
import { Tooltip } from "@getprorecrutement/getpro-design";
import UserStatus, { Avatar } from "../userStatus";
import { UserResponse } from "../../models/users/users";
import p from "../../../package.json";
import { SubMenuItem } from "./menuItems";

interface MenuProfilProps {
  collapsed: boolean;
  setCollapsed: (collapsed: boolean) => void;
  user: UserResponse;
}

const UserMenu: FunctionComponent<MenuProfilProps> = ({ collapsed, setCollapsed, user }) => {
  const [open, setOpen] = useState<boolean>(false);
  const domElem = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const onClickOut = (e: MouseEvent) => {
      if (domElem.current && !domElem.current.contains(e.target as Node) && domElem.current !== e.target) {
        setOpen(false);
      }
    };

    document.addEventListener("click", onClickOut);

    return function cleanup() {
      removeEventListener("click", onClickOut);
    };
  }, []);

  return (
    <div className="relative mt-3">
      <div className="m-2 p-4 bg-background-medium rounded-xl">
        <div
          className={`flex text-content-medium items-center  ${
            collapsed ? "justify-center flex-col gap-4" : "justify-between"
          }`}
        >
          <UserStatus />
          <div ref={domElem} className="flex-1 flex justify-center">
            <Tooltip title="Mon compte">
              <div onClick={() => setOpen(!open)} className="cursor-pointer">
                <Avatar size="large" user={user} />
              </div>
            </Tooltip>
            <div
              style={{ zIndex: 9999 }}
              className={`${
                open ? "block" : "hidden"
              } justify-start absolute left-[calc(100%+4px)] bottom-2 bg-background-medium rounded-3xl`}
            >
              <div className="py-4 pl-5 pr-14">
                <div className="flex gap-2 items-center">
                  <Avatar size="large" user={user} />
                  <div className="flex flex-col justify-between">
                    <div className="text-content-bright">{user.full_name}</div>
                    <div className="text-xs text-content-regular">{user.email}</div>
                  </div>
                </div>
                <div className="flex flex-col gap-4 mt-9 mb-6">
                  <SubMenuItem
                    key="/account"
                    icon={<UserCircleIcon width={20} height={20} />}
                    to="/account"
                    title="Mon profil"
                  />
                  <div
                    onClick={() => store.update(Stored.RefreshToken, undefined)}
                    className="flex justify-start items-center relative transition-colors hover:text-content-bright w-fit text-content-medium cursor-pointer"
                  >
                    <div className="pr-2">{<ArrowRightOnRectangleIcon width={20} height={20} />}</div>
                    <div className="whitespace-nowrap">Logout</div>
                  </div>
                </div>
              </div>
              <div className="flex justify-center items-center mx-5 py-1 text-[10px] italic border-t border-solid border-border-medium">
                <div>
                  <em>v{p.version}</em> - <em>C'est le futur</em>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          onClick={() => setCollapsed(!collapsed)}
          className="py-2 mt-4 rounded flex justify-center text-content-medium hover:bg-background-medium cursor-pointer"
        >
          {collapsed ? <ChevronRightIcon width={24} /> : <ChevronLeftIcon width={24} />}
        </div>
      </div>
    </div>
  );
};

export default UserMenu;
