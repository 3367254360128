import classNames from "classnames";
import React, { FunctionComponent, useEffect, useState } from "react";

export interface Props {
  defaultValue?: number;
  labels?: string[];
  size?: number;
  onChange?: (value: number) => void;
  dark?: boolean;
  light?: boolean;
  showSteps?: boolean;
}

export const Slider: FunctionComponent<Props & Omit<React.HTMLAttributes<HTMLDivElement>, "onChange">> = ({
  defaultValue,
  labels,
  onChange,
  dark,
  light,
  showSteps,
  ...props
}) => {
  const [value, setValue] = useState<number>(defaultValue || 0);
  const size = labels?.length || props.size || 10;
  const max = size - 1;

  useEffect(() => {
    if (onChange) onChange(value);
  }, [value]);

  const classes = classNames("slider relative", {
    [`${props.className}`]: !!props.className,
    dark: dark,
  });

  const sliderClasses = classNames("h-[5px] bg-background-bright w-full cursor-pointer", {
    "dark:bg-background-dark": !light,
  });
  const sliderThumbClasses = classNames(
    "absolute w-4 h-4 bg-primary-lighter top-2 rounded-full z-10 pointer-events-none",
    {
      "dark:bg-primary-medium": !light,
    }
  );
  const progressClasses = classNames("h-[5px] bg-primary-lighter rounded absolute top-[13.5px] pointer-events-none", {
    "dark:bg-primary-medium": !light,
  });

  const stepClasses = (index: number) =>
    classNames("w-[14px] h-[14px] absolute -top-[15px] z-10 cursor-pointer rounded-lg", {
      "bg-background-bright": value < index,
      "bg-primary-bright dark:bg-background-dark": value < index && !light,
      "bg-primary-lighter": value >= index,
      "dark:bg-primary-medium": value >= index && !light,
      hidden: value === index,
      block: value !== index,
    });

  const labelClasses = (index: number) =>
    classNames("top-2 py-1 px-3 absolute text-xs w-max rounded-lg", {
      "bg-primary-medium text-content-bright tooltiptext after:content-[' '] after:absolute after:-top-[33%] after:left-[50%] after:-ml-[5px] after:border-4 after:border-solid  after:border-transparent after:border-b-primary-medium":
        value === index,
      "bg-transparent text-content-light": value !== index,
      "dark:text-content-medium": value !== index && !light,
      "dark:bg-primary-medium dark:text-content-bright tooltiptext after:content-[' '] after:absolute after:-top-[33%] after:left-[50%] after:-ml-[5px] after:border-4 after:border-solid  after:border-transparent dark:after:border-b-primary-medium":
        value === index && !light,
      "cursor-pointer": labels && labels[index],
    });

  return (
    <div {...props} className={classes}>
      {/* Slider */}
      <input
        className={sliderClasses}
        type="range"
        min={0}
        max={max}
        value={value > max ? max : value}
        onChange={(e) => setValue(parseInt(e.currentTarget.value))}
      />
      {/* Slider Thumb */}
      <span
        className={sliderThumbClasses}
        style={{ left: `calc(${(value / max) * 100}% - ${16 * (value / max)}px)` }}
      ></span>
      {/* Progress Bar */}
      <div
        className={progressClasses}
        style={{
          width: `calc(${(value / max) * 100}% + 16px - ${16 * (value / max)}px)`,
        }}
      />
      {/* Labels for slider */}
      <div style={{ position: "relative" }}>
        {Array.from(Array(size).keys()).map((i) => (
          <div
            key={i}
            className="absolute"
            style={{
              left: `calc(${(i / max) * 100}% - ${14 * (i / max)}px)`, // 14 * (i / max) => SIZE OF STEP WIDTH
            }}
          >
            {/* Step on slider */}
            {showSteps && <span className={stepClasses(i)} onClick={() => setValue(i)}></span>}
            {/* Label */}
            <span
              className={labelClasses(i)}
              style={{
                transform: `translateX(calc(-50% + 8px - ${2 * (i / max)}px))`, // 8px => HALF OF THUMB WIDTH /// 2 * (i / max) => DIFF BETWEEN STEP WIDTH AND THUMB WIDTH
              }}
              onClick={() => setValue(i)}
            >
              {(labels && labels[i]) || (value === i && i)}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};
