import classNames from "classnames";
import React, { FunctionComponent, useEffect, useState } from "react";
import { twMerge } from "tailwind-merge";
import DateRangePicker from "@wojtekmaj/react-daterange-picker/dist/entry.nostyle";
import { ArrowRightIcon, CalendarIcon, XIcon } from "@heroicons/react/outline";
import dayjs from "dayjs";
import { labelClasses, textInputClasses, wrapperInputClasses } from "../../../../utils/common";

export interface Props {
  // value format is YYYY-MM-DD
  value?: { from?: string; to?: string };
  onChange: (val: { from?: string; to?: string }) => void;
  size?: "small" | "medium" | "large";
  disabled?: boolean;
  width?: number;
  label?: string;
  dark?: boolean;
  light?: boolean;
  error?: string;
  bordered?: boolean;
  defaultOpen?: boolean;
  allowClear?: boolean;
  inputClassName?: string;
  showIcon?: boolean;
}

export const DateRange: FunctionComponent<Props & Omit<React.HTMLAttributes<HTMLDivElement>, "onChange">> = ({
  value,
  onChange,
  size = "medium",
  disabled,
  width,
  label,
  dark,
  light,
  error,
  bordered = true,
  defaultOpen = false,
  allowClear = false,
  inputClassName,
  showIcon = false,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(defaultOpen);

  const wrapperClasses = twMerge(
    classNames("bg-inherit", {
      dark: dark,
    })
  );

  const groupClasses = twMerge(
    classNames("dateRange w-full relative bg-inherit group", {
      "cursor-pointer": !disabled,
      [`w-[${width}px] min-w-fit`]: !!width,
      "w-full": !width,
    })
  );

  const valueClasses = twMerge(
    classNames(
      [
        wrapperInputClasses({ size, disabled, light, bordered, focused: isOpen, error }),
        textInputClasses({ size, disabled, light, error }),
      ].join(" "),
      {
        [`${inputClassName}`]: !!inputClassName,
      }
    )
  );

  const iconClasses = classNames("stroke-2", {
    "w-4 h-4": size === "small",
    "w-4.5 h-4.5": size === "medium",
    "w-5 h-5": size === "large",
  });

  const errorClasses = classNames("mt-1 italic text-error-normal", {
    "pl-3 text-xs": size === "small",
    "pl-5 text-sm": size === "medium",
    "pl-8": size === "large",
  });

  const calendarClasses = classNames("overflow-hidden my-2 rounded-3xl", {
    "dark:bg-background-dark": !light,
  });

  return (
    <div className={wrapperClasses}>
      <div
        {...props}
        onClick={() => !isOpen && !disabled && setIsOpen(true)}
        onFocus={() => !isOpen && !disabled && setIsOpen(true)}
        className={groupClasses}
      >
        {label && <label className={labelClasses({ size, disabled, light, focused: isOpen, error })}>{label}</label>}
        <DateRangePicker
          dayPlaceholder={"dd"}
          monthPlaceholder={"mm"}
          yearPlaceholder={"yyyy"}
          format={"dd-MM-y"}
          calendarClassName={calendarClasses}
          className={valueClasses}
          locale="fr-FR"
          rangeDivider={<ArrowRightIcon className={iconClasses.concat(" mx-1")} />}
          // TODO CHECK ON INPUT
          onChange={(e: any) => {
            if (e && !!e[0] && !!e[1] && dayjs(e[0].toString()).isValid() && dayjs(e[1].toString()).isValid()) {
              if (
                dayjs(e[0].toString()).format("YYYY").length === 4 &&
                dayjs(e[1].toString()).format("YYYY").length === 4
              ) {
                onChange({
                  from: dayjs(e[0].toString()).format("YYYY-MM-DD"),
                  to: dayjs(e[1].toString()).format("YYYY-MM-DD"),
                });
              }
            } else if (e && !![0] && dayjs(e[0].toString()).isValid()) {
              dayjs(e[0].toString()).format("YYYY").length === 4 &&
                onChange({ ...value, from: dayjs(e[0].toString()).format("YYYY-MM-DD") });
            } else if (e && !![1] && dayjs(e[1].toString()).isValid()) {
              dayjs(e[1].toString()).format("YYYY").length === 4 &&
                onChange({ ...value, to: dayjs(e[1].toString()).format("YYYY-MM-DD") });
            } else {
              onChange({ from: undefined, to: undefined });
            }
          }}
          // TODO CHECK
          value={[value && value.from ? new Date(value.from) : "", value && value.to ? new Date(value.to) : ""]}
          isOpen={isOpen}
          disabled={disabled}
          onCalendarClose={() => setIsOpen(false)}
          calendarIcon={showIcon ? <CalendarIcon className={iconClasses} /> : null}
          clearIcon={allowClear ? <XIcon className={iconClasses} /> : null}
          required={false}
        />
        {error && <div className={errorClasses}>{error}</div>}
      </div>
    </div>
  );
};
