import React, { FunctionComponent } from "react";
import { toast } from "react-hot-toast";
import { useState } from "react";
import {
  JA_TERMINATION_SOURCES,
  JobApplicationBase,
  JobApplicationResponse,
  JobapplicationTerminationRequest,
  JobApplicationTerminationSource,
  terminateJa,
} from "../../../models/jobApplications";
import { Button, Select, Textarea } from "@getprorecrutement/getpro-design";

interface Props {
  jobApplication: JobApplicationBase;
  onFinish: (jobApplication: JobApplicationResponse) => void;
}

export const TerminateForm: FunctionComponent<Props> = (props) => {
  const jobApplication = props.jobApplication;
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<Partial<JobapplicationTerminationRequest>>({});

  const submit = async (data: JobapplicationTerminationRequest) => {
    setLoading(true);
    try {
      const updatedJa = await terminateJa(jobApplication.id, data);
      props.onFinish(updatedJa);
      toast.success("Candidature clôturée");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="text-2xl mb-2">Clôturer la candidature</div>
      <div className="terminateJobApplicationForm bg-inherit py-2 pr-4 overflow-auto">
        <Select
          className="mt-6"
          light
          bordered
          rounded
          label="Source"
          options={[
            JobApplicationTerminationSource.GetPro,
            JobApplicationTerminationSource.Client,
            JobApplicationTerminationSource.Candidate,
          ]}
          optionRender={(v) => JA_TERMINATION_SOURCES[v]}
          getKey={(v) => v}
          onChange={(v) => setData({ ...data, termination_source: v })}
          type="single"
          value={data.termination_source}
          onSearch={undefined}
        />
        <Textarea
          light
          value={data.termination_comment || ""}
          className="mt-6"
          onChange={(v) => setData({ ...data, termination_comment: v })}
          placeholder="Pas de fit avec le N+1"
          rows={4}
          label="Raison"
        />
        <div className="flex justify-end mt-8">
          <Button
            size="small"
            light
            loading={loading}
            title="Enregistrer"
            onClick={() => submit(data as JobapplicationTerminationRequest)}
            disabled={!data.termination_comment || !data.termination_source}
          />
        </div>
      </div>
    </>
  );
};

export default TerminateForm;
