import classNames from "classnames";
import React, { FunctionComponent, useEffect, useState } from "react";
import { twMerge } from "tailwind-merge";

export interface Props {
  value: number;
  size: number;
  inline?: boolean;
  showTooltip?: boolean;
  labelMode?: "percentage" | "fractional" | "none";
  type?: "line" | "circle";
  icon?: JSX.Element;
  dark?: boolean;
  light?: boolean;
}

export const Progress: FunctionComponent<Props & React.HTMLAttributes<HTMLDivElement>> = ({
  value,
  size,
  inline = false,
  labelMode = "fractional",
  showTooltip = false,
  type = "line",
  icon,
  dark,
  light,
  ...props
}) => {
  const percent = Math.round(((value > size ? size : value) / size) * 100);

  const getPercent = () => {
    if (percent > 100) return 100;
    else if (percent < 0) return 0;
    else return percent || 0;
  };

  const classes = twMerge(
    classNames("w-full relative", {
      [`${props.className}`]: !!props.className,
      dark: dark,
    })
  );

  const barClasses = classNames("", {
    "dark:bg-background-dark": !light && type === "line",
    "h-1 relative w-full bg-background-bright rounded-full": type === "line",
    "dark:text-background-dark": !light && type === "circle",
    "text-background-bright": type === "circle",
  });

  const progressClasses = classNames("transition-all", {
    "dark:bg-primary-light": !light && type === "line",
    "h-1 bg-primary-dark rounded-full": type === "line",
    "text-primary-dark": type === "circle",
    "dark:text-primary-light": !light && type === "circle",
  });

  const labelClasses = classNames(
    "text-primary-darker absolute top-4 text-base bg-primary-lighter px-2 py-1 -translate-x-1/2 rounded transition-all after:content-[' '] after:absolute after:-top-[25%] after:left-[50%] after:-ml-[5px] after:border-4 after:border-solid  after:border-transparent after:border-b-primary-lighter",
    {
      "dark:text-content-dark dark:bg-primary-light dark:after:border-b-primary-light": !light,
    }
  );
  const percentClasses = classNames("font-bold text-base w-max text-content-dark", {
    "dark:text-content-lighter": !light,
  });

  const valueClasses = classNames("text-content-light whitespace-nowrap", {
    "dark:text-content-medium": !light,
  });

  const sizeClasses = classNames("text-content-dark", {
    "dark:text-content-light": !light,
  });

  const renderLabels = () => (
    <>
      {labelMode !== "none" &&
        (labelMode === "fractional" ? (
          <div>
            <div className="font-bold text-base">
              <span className={valueClasses}>{value > size ? size : value} / </span>
              <span className={sizeClasses}>{size}</span>
            </div>
            {icon && (
              <div className="w-full flex items-center justify-center mt-2">
                <div className="w-4 h-4">{icon}</div>
              </div>
            )}
          </div>
        ) : (
          <div className={percentClasses}>
            <div>{getPercent()}%</div>
            {icon && (
              <div className="w-full flex items-center justify-center mt-2">
                <div className="w-4 h-4">{icon}</div>
              </div>
            )}
          </div>
        ))}
    </>
  );

  return (
    <div {...props} className={classes}>
      {type === "line" ? (
        <>
          {!inline && <div className="flex justify-end mb-3">{renderLabels()}</div>}
          <div className="flex items-center gap-5">
            <div className={barClasses}>
              {showTooltip && (
                <div className={labelClasses} style={{ left: `${getPercent()}%` }}>
                  <span className="font-bold">{getPercent()}</span>%
                </div>
              )}
              <div className={progressClasses} style={{ width: `${getPercent()}%` }} />
            </div>
            {inline && renderLabels()}
          </div>
        </>
      ) : (
        <div className="w-fit overflow-hidden bg-white rounded-full relative">
          <svg className="w-32 h-32 flex justify-center items-center -rotate-90 transform">
            <circle
              className={barClasses}
              strokeWidth={10}
              stroke="currentColor"
              fill="transparent"
              r="50"
              cx="60"
              cy="60"
            />
            <circle
              className={progressClasses}
              strokeWidth={10}
              strokeDasharray={50 * 2 * Math.PI}
              strokeDashoffset={50 * 2 * Math.PI - (percent / 100) * (50 * 2 * Math.PI)}
              strokeLinecap="round"
              stroke="currentColor"
              fill="transparent"
              r="50"
              cx="60"
              cy="60"
            />
          </svg>
          <div className="absolute w-full h-full top-0 left-0 flex items-center justify-center mt-1">
            {renderLabels()}
          </div>
        </div>
      )}
    </div>
  );
};
