import { DotsVerticalIcon } from "@heroicons/react/outline";
import { ColumnProps } from ".";
import { Checkbox, Select } from "../Form/Inputs";
import { SortableBtn } from "./SortableBtn";
import React from "react";
import classNames from "classnames";
import { ColorType } from "../../utils/common";
import { PopOver } from "../PopOver";

interface renderSelectFilterProps<T> {
  col: ColumnProps<T>;
  filter?: {
    filters: { [key in keyof T]?: string | any[] | undefined };
    onFilterChange: (key: keyof T, value: any) => void;
  };
  onSortDirectionChange?: (key: keyof T, direction?: "asc" | "desc") => void;
  sortBy?: { key: keyof T; sortDirection: "asc" | "desc" | undefined };
  colLabelClasses: string;
  colorType?: ColorType;
  light?: boolean;
}

export const SelectFilter = <T extends unknown>({
  col,
  filter,
  onSortDirectionChange,
  sortBy,
  colLabelClasses,
  colorType = ColorType.Primary,
  light,
}: renderSelectFilterProps<T>) => {
  return (
    <PopOver
      light={light}
      tooltipProps={{ className: "px-4 py-2" }}
      position="bottom-right"
      content={
        <div>
          {(col.optionFilters || []).map((c) => (
            <Checkbox
              key={c.key}
              className="whitespace-nowrap w-full cursor-pointer"
              light={light}
              colorType={colorType}
              value={!!filter?.filters[col.dataIndex]?.includes(c.value)}
              onChange={() => {
                filter?.onFilterChange(col.dataIndex, c.value);
              }}
              label={c.label}
            />
          ))}
        </div>
      }
    >
      <div className={`${colLabelClasses} cursor-pointer`}>
        {col.title}
        {col.sortable && (
          <SortableBtn
            currentSort={sortBy?.key === col.dataIndex ? sortBy.sortDirection : undefined}
            sortable={col.sortable}
            onDirectionChange={(newDirection) => {
              onSortDirectionChange?.(col.dataIndex, newDirection);
            }}
          />
        )}
        <DotsVerticalIcon height={15} width={15} />
      </div>
    </PopOver>
  );

  // return (
  //   <Select
  //     type="multiple"
  //     value={col.optionFilters?.filter((elem) => filter?.filters[col.dataIndex]?.includes(elem.value))}
  //     className="bg-inherit"
  //     valueRenderer={() => (
  //       <div className={`${colLabelClasses} cursor-pointer h-full `}>
  //         {col.title}
  //         {col.sortable && (
  //           <SortableBtn
  //             currentSort={sortBy?.key === col.dataIndex ? sortBy.sortDirection : undefined}
  //             sortable={col.sortable}
  //             onDirectionChange={(newDirection) => {
  //               onSortDirectionChange?.(col.dataIndex, newDirection);
  //             }}
  //           />
  //         )}
  //         <DotsVerticalIcon height={15} width={15} />
  //       </div>
  //     )}
  //     dropdownClassName={`border-border-bright border-b border-x ${light ? "" : "dark:border-border-medium"}`}
  //     bordered
  //     rounded
  //     light={light}
  //     options={col.optionFilters || []}
  //     onChange={(item) => {
  //       item && filter?.onFilterChange(col.dataIndex, item.value);
  //     }}
  //     getKey={(item) => item.key}
  //     getLabel={(item) => (
  //       <Checkbox
  //         light={light}
  //         colorType={colorType}
  //         value={!!filter?.filters[col.dataIndex]?.includes(item.value)}
  //         onChange={() => {}}
  //         label={item.label}
  //         onClick={(ev) => {
  //           ev.stopPropagation();
  //         }}
  //       />
  //     )}
  //   />
  // );
};
