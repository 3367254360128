import React, { FunctionComponent, useCallback, useEffect, useState } from "react";
import { FullJobOffer, getJos } from "../../../../models/jobOffers";
import { UUID } from "../../../../models/common";
import { useDeepCompareEffect } from "react-use";
import { Select } from "@getprorecrutement/getpro-design";
import { useLoading } from "../../../../utils/loading";
import { MultipleValues, SingleValue } from "@getprorecrutement/getpro-design/dist/components/Form/Inputs/Select";
import classNames from "classnames";

interface Props {
  onSelect: (s: UUID) => void;
  onClear?: () => void;
  onFetchJos?: (jos: UUID[]) => void;
  placeholder?: string;
  includeArchived?: boolean;
  style?: React.CSSProperties;
  ownerIds?: UUID[];
  customerIds?: UUID[];
  label?: string;
  size?: "small" | "medium" | "large";
  error?: string;
  showArrowIcon?: boolean;
  dropdownClassName?: string;
}

interface MultipleProps {
  multiple: true;
  value?: UUID[];
  customValueRender?: (value: FullJobOffer[]) => JSX.Element;
}

interface SingleProps {
  multiple?: false;
  value?: UUID;
  customValueRender?: (value: FullJobOffer) => JSX.Element;
}

const PER_PAGE = 40;
const SELECTED_PER_PAGE = 100;

export const JobOfferSelect: FunctionComponent<Props & (SingleProps | MultipleProps)> = ({
  size = "medium",
  ...props
}) => {
  const [jobOffers, setJobOffers] = useState<FullJobOffer[]>([]);
  const [search, setSearch] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const load = useLoading(setLoading);
  const [selectedValues, setSelectedValues] = useState<FullJobOffer[]>([]);

  const fetchJobOffers = async (): Promise<void> => {
    let saveJobOffers: FullJobOffer[] = [];

    const currentIds = props.value ? (props.multiple ? (props.value as UUID[]) : [props.value as UUID]) : undefined;

    if (currentIds && currentIds.length > 0) {
      const selectedJos = await getJos({ page: 1, per_page: SELECTED_PER_PAGE, search: search, id: currentIds });
      saveJobOffers = selectedJos.data;
    }

    const jos = await getJos({
      page: 1,
      per_page: PER_PAGE,
      search: search,
      archived: !props.includeArchived ? false : undefined,
      owner_ids: props.ownerIds,
      customer_ids: props.customerIds,
    });
    saveJobOffers = saveJobOffers.concat(jos.data.filter((jo) => !(currentIds || []).includes(jo.id)));

    setJobOffers(saveJobOffers);
    props.onFetchJos && props.onFetchJos(saveJobOffers.map((jo) => jo.id));
  };

  const initBaseSelected = useCallback(async (value?: UUID | UUID[]) => {
    const currentIds = value ? (props.multiple ? (value as UUID[]) : [value as UUID]) : undefined;

    if (currentIds?.length) {
      if (selectedValues.length !== currentIds.length)
        getJos({ page: 1, per_page: SELECTED_PER_PAGE, search: search, id: currentIds }).then((jos) =>
          setSelectedValues(jos.data)
        );
    } else {
      setSelectedValues([]);
    }
  }, []);

  useEffect(() => {
    initBaseSelected(props.value);
  }, [props.value]);

  const selected = (val: FullJobOffer) => {
    const newValues = props.multiple
      ? selectedValues.map((jo) => jo.id).includes(val.id)
        ? selectedValues.filter((v) => v.id !== val.id)
        : [...selectedValues, val]
      : [val];
    setSelectedValues(newValues);
    props.onSelect(val.id);
  };

  useDeepCompareEffect(() => {
    load(fetchJobOffers());
  }, [search, props.ownerIds, props.customerIds]);

  const selectProps: SingleValue<FullJobOffer> | MultipleValues<FullJobOffer> = props.multiple
    ? { type: "multiple", value: selectedValues, customValueRender: props.customValueRender }
    : { type: "single", value: selectedValues[0], customValueRender: props.customValueRender };

  const logoClasses = classNames("rounded-full object-contain bg-white", {
    "w-4 h-4": size === "small",
    "w-5 h-5": size === "medium",
    "w-6 h-6": size === "large",
  });

  return (
    <span className="jobOfferSelect bg-inherit" style={props.style}>
      <Select
        light
        bordered
        rounded
        onSearch={(val) => setSearch(val)}
        options={jobOffers}
        loading={loading}
        label={props.label}
        size={size}
        error={props.error}
        showArrowIcon={props.showArrowIcon}
        onChange={(v) => v && selected(v)}
        optionRender={(item) => (
          <div className="flex justify-between w-full items-center gap-2">
            <div className="flex gap-2 w-full items-center">
              <img
                className={logoClasses}
                src={`https://storage.googleapis.com/getpro-public/${
                  item.company.file_id || "e1ca4dfa-7173-40a1-8f9a-917dc3f3556f"
                }`}
              />
              <div title={item.name} className="line-clamp break-all" style={{ WebkitLineClamp: 1 }}>
                {item.name}
              </div>
            </div>
            <div>{item.summary.candidates_count}</div>
          </div>
        )}
        getKey={(item) => item.id}
        placeholder={props.placeholder}
        dropdownClassName={props.dropdownClassName}
        {...selectProps}
      />
    </span>
  );
};

export default JobOfferSelect;
