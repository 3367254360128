import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/outline";
import classNames from "classnames";
import React, { FunctionComponent, useEffect, useState } from "react";
import { twMerge } from "tailwind-merge";
import { Light } from "../../stories/Tab.stories";
import { MoreActions } from "../MoreActions";

export interface Props {
  page: number;
  total: number;
  pageSize?: number;
  onChange: (page: number) => void;
  pageParamsActions?: { action: () => void; title: string }[];
  dark?: boolean;
  light?: boolean;
}

export const Pagination: FunctionComponent<Props & Omit<React.HTMLAttributes<HTMLDivElement>, "onChange">> = ({
  page,
  total,
  pageSize = 10,
  onChange,
  pageParamsActions,
  dark,
  light,
  ...props
}) => {
  const nbPages = Math.ceil(total / pageSize);
  const [pageInput, setPageInput] = useState<number>(page);

  useEffect(() => {
    setPageInput(page);
  }, [page]);

  const renderSelection = () => {
    const prevTotal = (page - 1) * pageSize;

    return `${prevTotal + 1} - ${page < nbPages ? page * pageSize : total - prevTotal + prevTotal} sur ${total}`;
  };

  const classes = twMerge(
    classNames("flex gap-5 items-center", {
      [`${props.className}`]: !!props.className,
      dark: dark,
    })
  );

  const onKeyPress: React.KeyboardEventHandler<HTMLDivElement> = (event) => {
    const page = parseInt(event.currentTarget.innerText, 10) || 0;
    if (event.key === "Enter") {
      if (pageInput !== page) {
        onChange(page);
        setPageInput(pageInput);
      }
    }
  };

  const selectionClasses = classNames("w-max text-content-darker font-medium text-[10px]", {
    "dark:text-content-light": !light,
    "py-1.5": pageParamsActions,
  });

  const pageWrapperClasses = classNames(
    "border-[1.5px] rounded border-solid flex border-border-bright items-baseline px-2 py-0.5 whitespace-nowrap",
    {
      "dark:border-border-dark": !light,
    }
  );

  const pageClasses = classNames("resize-none text-center focus:outline-none bg-transparent pr-1 text-sm", {
    "dark:text-content-lighter": !light,
  });

  const chevronLeftClasses = classNames("", {
    "cursor-pointer": page > 1,
    "dark:text-content-light": page > 1 && !light,
    "text-content-lighter": page <= 1,
    "dark:text-content-dark": page <= 1 && !light,
  });

  const chevronRightClasses = classNames("", {
    "cursor-pointer": page < nbPages,
    "dark:text-content-light": page < nbPages && !light,
    "text-content-lighter": page >= nbPages,
    "dark:text-content-dark": page >= nbPages && !light,
  });

  const nbPageClasses = classNames("text-content-light text-xs font-medium", {
    "dark:text-content-medium": !light,
  });

  return (
    <div {...props} className={classes}>
      {pageParamsActions ? (
        <MoreActions actions={pageParamsActions} position="top-left">
          <div className={selectionClasses}>{renderSelection()}</div>
        </MoreActions>
      ) : (
        <div className={selectionClasses}>{renderSelection()}</div>
      )}
      <div>
        <div className="flex items-center">
          <ChevronLeftIcon
            onClick={() => page > 1 && onChange(page - 1)}
            height={12}
            width={24}
            className={chevronLeftClasses}
          />
          <div className={pageWrapperClasses}>
            <div
              contentEditable
              className={pageClasses}
              onInput={({ currentTarget }) => {
                const page = Math.max(1, Math.min(nbPages, parseInt(currentTarget.innerText, 10) || 0));
                if (page.toString() !== currentTarget.innerText) currentTarget.innerText = page.toString();
              }}
              onBlur={(e) => onChange(parseInt(e.currentTarget.innerText, 10) || 0)}
              onKeyPress={onKeyPress}
              dangerouslySetInnerHTML={{ __html: page }}
            />
            <div className={nbPageClasses}> / {nbPages}</div>
          </div>
          <ChevronRightIcon
            onClick={() => page < nbPages && onChange(page + 1)}
            height={12}
            width={24}
            className={chevronRightClasses}
          />
        </div>
      </div>
    </div>
  );
};
