/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FunctionComponent, useMemo, useState } from "react";
import { UUID } from "../../../models/common";
import { JobApplicationBase } from "../../../models/jobApplications";
import { Candidate } from "../../../models/candidate";
import { Status } from "../../../models/jobOffers";
import { HiredBasicStep } from "./hiredForm/basicStep";
import { FeedbackStep } from "./hiredForm/feedbackStep";
import { PresentedBasicStep } from "./presentedForm/basicStep";
import { JobTitleStep } from "./presentedForm/jobTitleStep";
import { toast } from "react-hot-toast";

export type StepComponent = FunctionComponent<MetaFormStepProps>;

interface Props {
  jobApp: JobApplicationBase;
  candidate: Candidate;
  steps: StepComponent[];
  onFinish: () => void;
  onCancel: () => void;
}

export interface MetaFormStepProps {
  onSubmit: (candidate: Candidate, jobApp: JobApplicationBase) => void;
  candidate: Candidate;
  jobApp: JobApplicationBase;
  onCancel: () => void;
}

export const STEPS: {
  [key: UUID]: StepComponent[];
} = {
  "ce7d87f3-f446-499d-bc49-12112e78f131": [HiredBasicStep, FeedbackStep],
  "188508f1-9000-49fe-80a2-93162a002d52": [PresentedBasicStep, JobTitleStep],
};

export const getSteps = (fromStatusId: UUID, toStatusId: UUID, statuses: Status[]) => {
  const sortedStatuses = statuses.sort((a, b) => a.index - b.index);
  const fromIndex = sortedStatuses.find((s) => s.id === fromStatusId)?.index || 0;
  const toIndex = sortedStatuses.find((s) => s.id === toStatusId)?.index || sortedStatuses.length;

  return sortedStatuses
    .filter((s) => s.index > fromIndex && s.index <= toIndex)
    .map((s) => STEPS[s.id] || [])
    .flat();
};

export const MetaForm: FunctionComponent<Props> = ({ steps, onCancel, ...props }) => {
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [candidate, setCandidate] = useState<Candidate>(props.candidate);
  const [jobApp, setJobApp] = useState<JobApplicationBase>(props.jobApp);

  const CurrentForm = useMemo(() => {
    return steps[currentStep];
  }, [steps, currentStep]);

  const submit = (candidate: Candidate, jobApp: JobApplicationBase) => {
    if (currentStep == steps.length - 1) {
      toast.success("Informations mises à jour avec succès");
      return props.onFinish();
    }
    setCandidate(candidate);
    setJobApp(jobApp);
    setCurrentStep((step) => step + 1);
  };

  const cancel = () => {
    if (currentStep > 0) setCurrentStep((step) => step - 1);
    else onCancel();
  };

  return (
    <>
      <div className="text-2xl mb-2">Informations complémentaires</div>
      <div className="metaForm bg-inherit py-2 pr-4 overflow-auto">
        <CurrentForm onCancel={cancel} onSubmit={submit} candidate={candidate} jobApp={jobApp} />
      </div>
    </>
  );
};

export default MetaForm;
