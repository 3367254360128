import { getFileUrl } from "../models/file";

export interface FilePayload {
  file: File;
}

export interface Column<T> {
  title: string | JSX.Element;
  dataIndex: string | string[];
  className?: string;
  width?: number;
  ellipsis?: {
    showTitle: false;
  };
  render?: (val: string, record: T) => JSX.Element | undefined | string;
  defaultSortOrder?: "ascend" | "descend";
  sorter?: (a: T, b: T) => number;
}

export const downloadFile = async (fileId: string, bucket_name: string): Promise<void> => {
  const url = await getFileUrl(fileId, bucket_name);
  const a = document.createElement("a");

  a.href = url.url;
  a.target = "_blank";
  a.click();
};

export const downloadFileFromUrl = async (url: string): Promise<void> => {
  const a = document.createElement("a");

  a.href = url;
  a.target = "_blank";
  a.click();
};
