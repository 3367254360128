import React, { FunctionComponent, useState } from "react";
import { Button, TextInput } from "@getprorecrutement/getpro-design";
import { BusinessUnit, BusinessUnitData } from "../../../models/businessUnits";
import { useFormManager } from "../../../services/form";

interface Props {
  businessUnit?: BusinessUnit;
  onSubmit: (data: BusinessUnitData) => Promise<void>;
}

export const BusinessUnitForm: FunctionComponent<Props> = ({ businessUnit, onSubmit }) => {
  const [loading, setLoading] = useState(false);
  // const [data, setData] = useState<Partial<BusinessUnitData>>(businessUnit || {});
  const form = useFormManager<BusinessUnitData>({
    defaultState: businessUnit || {},
    validations: { name: { required: true, message: "Merci de préciser le nom du département" } },
  });

  const submit = (data: BusinessUnitData) => {
    setLoading(true);
    const value = businessUnit?.id ? { ...data, id: businessUnit.id } : data;
    onSubmit(value).then(() => setLoading(false));
  };

  return (
    <>
      <div className="text-2xl mb-2">{businessUnit ? `Éditer ${businessUnit.name}` : "Nouveau département"}</div>
      <div className="userForm bg-inherit py-2 pr-4 overflow-auto">
        <TextInput
          type="text"
          light
          label="Département"
          placeholder="Département"
          error={form.errors.name}
          value={form.state.name}
          onChange={(e) => form.onChange({ name: e.target.value })}
        />
        <div className="flex justify-end mt-6">
          <Button
            size="small"
            loading={loading}
            light
            title="Enregistrer"
            disabled={form.disabled}
            onClick={() => form.validate() && submit(form.state as BusinessUnitData)}
          />
        </div>
      </div>
    </>
  );
};

export default BusinessUnitForm;
