import httpClient from "../services/http/client";
import ROUTES from "../services/http/routes";
import { notifyError } from "../services/requests";
import { GeocodedLocation, UUID } from "./common";

export enum Granularity {
  Day = "day",
  Week = "week",
  Month = "month",
}

export enum Weekday {
  Monday = "Mon",
  Tuesday = "Tue",
  Wednesday = "Wed",
  Thursday = "Thu",
  Friday = "Fri",
  Saturday = "Sat",
  Sunday = "Sun",
}

export interface SignalFilterMetadata {
  location?: {
    location_id: number;
    max_radius?: number;
  };
}

export interface CategorySignalFilter {
  category: UUID;
  skills: UUID[];
}

export interface SignalFilters {
  categories: CategorySignalFilter[];
  metadata: SignalFilterMetadata;
}

export interface Temporality {
  every: { [key in Granularity]?: Weekday } | Granularity.Day;
  at: [number, number];
}

export const convertTemporalityToUtc = (temporality: Temporality): Temporality => {
  const newHour = temporality.at[0] + new Date().getTimezoneOffset() / 60;
  return { ...temporality, at: [newHour, temporality.at[1]] };
};

export const convertTemporalityToLocal = (temporality: Temporality): Temporality => {
  const newHour = temporality.at[0] - new Date().getTimezoneOffset() / 60;
  return { ...temporality, at: [newHour, temporality.at[1]] };
};

export const getGranularity = (t: Temporality) =>
  typeof t.every === "string"
    ? t.every
    : (Object.keys(t.every as { [key in Granularity]?: Weekday })[0] as Granularity);

export const getWeekday = (t: Temporality) =>
  typeof t.every === "string" ? undefined : Object.values<Weekday>(t.every as { [key in Granularity]?: Weekday })[0];

export const createTemporality = (granularity: Granularity, weekDay: Weekday | undefined, at: [number, number]) => ({
  every: granularity === Granularity.Day ? granularity : { [granularity]: weekDay },
  at,
});

export interface Signal extends NewSignal {
  id: UUID;
  next_run: string;
  created_at: string;
}

export interface NewSignal {
  target_id: UUID;
  send_on: Temporality;
  filters: SignalFilters;
}

export interface UpdateSignal {
  send_on?: Temporality;
  filters?: SignalFilters;
}

export interface SignalTarget {
  id: UUID;
  email: string;
  first_name: string;
  last_name: string;
  customer_name: string;
  customer_id: UUID;
  created_at: string;
}

export interface SignalTargetResponse extends SignalTarget {
  signals: Signal[];
}

export interface NewSignalTarget {
  email: string;
  first_name: string;
  last_name: string;
  customer_id: UUID;
}

export interface UpdateSignalTarget {
  email?: string;
  first_name?: string;
  last_name?: string;
  customer_id?: UUID;
}

export async function getSignalTargets(): Promise<SignalTargetResponse[]> {
  return httpClient.req(ROUTES.GET_SIGNAL_TARGETS(), notifyError);
}

export async function createSignalTarget(data: NewSignalTarget): Promise<SignalTarget> {
  return httpClient.req(ROUTES.CREATE_SIGNAL_TARGET(data), notifyError);
}

export async function updateSignalTarget(data: UpdateSignalTarget & { id: UUID }): Promise<SignalTarget> {
  return httpClient.req(ROUTES.UPDATE_SIGNAL_TARGET(data), notifyError);
}

export async function createSignal(data: NewSignal): Promise<Signal> {
  return httpClient.req(ROUTES.CREATE_SIGNAL(data), notifyError);
}

export async function updateSignal(data: UpdateSignal & { id: UUID }): Promise<Signal> {
  return httpClient.req(ROUTES.UPDATE_SIGNAL(data), notifyError);
}

export async function deleteSignal(id: UUID): Promise<void> {
  return httpClient.req(ROUTES.DELETE_SIGNAL(id), (err) =>
    notifyError(err || "Erreur lors de la suppression du signal")
  );
}

export async function deleteSignalTarget(id: UUID): Promise<void> {
  return httpClient.req(ROUTES.DELETE_SIGNAL_TARGET(id), (err) =>
    notifyError(err || "Erreur lors de la suppression du contact signal")
  );
}
