import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from "react";

import { ChevronRightIcon, ChevronLeftIcon } from "@heroicons/react/24/outline";
import { NavLink } from "react-router-dom";
import { useMatch } from "react-router-dom";
import { Tooltip } from "@getprorecrutement/getpro-design";

interface MenuItemGroupProps {
  icon: JSX.Element;
  title: string;
  children: ((setSubMenuActive: (key: string, active: boolean) => void) => JSX.Element)[];
  collapsed: boolean;
}

export const MenuItemGroup: FunctionComponent<MenuItemGroupProps> = ({ icon, title, children, collapsed }) => {
  const [active, setActive] = useState<boolean>(false);
  const [, setSubMenusState] = useState<{ [key: string]: boolean }>({});
  const [opened, setOpened] = useState<boolean>(false);
  const domElem = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const onClickOut = (e: MouseEvent) => {
      if (domElem.current && !domElem.current.contains(e.target as Node) && domElem.current !== e.target) {
        setOpened(false);
      }
    };

    document.addEventListener("click", onClickOut);

    return function cleanup() {
      removeEventListener("click", onClickOut);
    };
  }, []);

  const setSubMenuActive = useCallback((key: string, active: boolean) => {
    setSubMenusState((state) => {
      const newState = { ...state, [key]: active };
      const accordionActive = !!Object.values(newState).find((e: boolean) => e);
      setActive(accordionActive);
      return newState;
    });
  }, []);

  const content = (
    <div
      onClick={() => setOpened(!opened)}
      className={`flex justify-start items-center cursor-pointer transition-colors hover:text-content-bright ${
        active ? "text-content-bright" : "text-content-regular"
      } ${collapsed ? "justify-center" : "justify-start"}`}
    >
      {active && <div className="bg-primary-medium h-[22px] w-[4px] left-0 absolute rounded-r-xl"></div>}
      {collapsed && icon ? (
        <Tooltip title={title}>
          <div className="px-4">{icon}</div>
        </Tooltip>
      ) : (
        <div className="px-4">{icon && icon}</div>
      )}
      {!collapsed && <div>{title}</div>}
      {!collapsed && (
        <div className="flex justify-end mr-4 flex-grow">
          {opened ? <ChevronLeftIcon width={16} /> : <ChevronRightIcon width={16} />}
        </div>
      )}
    </div>
  );

  return (
    <div className="relative" ref={domElem}>
      {content}
      {
        <div
          style={{ zIndex: 9999 }}
          className={`${
            opened ? "flex" : "hidden"
          } justify-start absolute left-[calc(100%+4px)] -top-4 bg-background-medium px-7 py-6 rounded-3xl max-h-[300px] overflow-hidden`}
        >
          <div className="flex flex-col items-start gap-4 overflow-auto w-fit pr-7">
            {children.map((c, i) => (
              <div key={i}>{c(setSubMenuActive)}</div>
            ))}
          </div>
        </div>
      }
    </div>
  );
};

interface MenuItemProps {
  icon?: JSX.Element;
  to: string;
  title: string;
  collapsed: boolean;
  link?: boolean;
}

export const MenuItem: FunctionComponent<MenuItemProps> = ({ icon, to, title, collapsed, link = true }) => {
  const content = (active?: boolean) => (
    <div className={`flex items-center ${collapsed ? "justify-center" : "justify-start"}`}>
      {active && <div className="bg-primary-medium h-[22px] w-[4px] left-0 absolute rounded-r-xl"></div>}
      {collapsed && icon ? (
        <Tooltip title={title}>
          <div className="px-4">{icon}</div>
        </Tooltip>
      ) : (
        <div className="px-4">{icon && icon}</div>
      )}
      {!collapsed && <div>{title}</div>}
    </div>
  );

  return (
    <div>
      {link ? (
        <NavLink
          className={({ isActive }) =>
            `transition-colors hover:text-content-bright ${isActive ? "text-content-bright" : "text-content-regular"}`
          }
          to={to}
        >
          {({ isActive }) => content(isActive)}
        </NavLink>
      ) : (
        <a
          className="text-content-regular hover:text-content-bright transition-colors"
          onClick={(e) => e.stopPropagation()}
          target="_blank"
          href={to}
          rel="noreferrer"
        >
          {content()}
        </a>
      )}
    </div>
  );
};

interface SubMenuItemProps {
  icon?: JSX.Element;
  to: string;
  title: string;
  link?: boolean;
  setSubMenuActive?: (key: string, active: boolean) => void;
}

export const SubMenuItem: FunctionComponent<SubMenuItemProps> = ({ icon, to, title, setSubMenuActive }) => {
  const content = (active: boolean) => (
    <div
      className={`flex justify-start items-center relative transition-colors hover:text-content-bright max-w-[300px] w-fit ${
        active ? "text-content-bright" : "text-content-regular"
      }`}
    >
      {/* {active && <div className="bg-primary-medium h-[22px] w-[4px] -left-[16px] absolute rounded-r-xl"></div>} */}
      {icon && <div className="pr-2">{icon}</div>}
      <div className="whitespace-nowrap max-w-[280px] truncate">{title}</div>
    </div>
  );

  const match = useMatch(to);

  useEffect(() => {
    setSubMenuActive && setSubMenuActive(to, !!match);
  }, [match]);

  return <NavLink to={to}>{({ isActive }) => content(isActive)}</NavLink>;
};
