import classNames from "classnames";
import React, { FunctionComponent } from "react";
import { twMerge } from "tailwind-merge";

export interface Props {
  position?: "top" | "bottom" | "left" | "right" | "bottom-left" | "bottom-right" | "top-left" | "top-right";
  children: React.ReactNode;
  arrowMarker?: boolean;
  dark?: boolean;
  light?: boolean;
  size?: "small" | "medium" | "large";
}

export interface WithTitleProps extends Props {
  title: string;
  customRenderer?: undefined;
}

export interface WithCustomRendererProps extends Props {
  customRenderer: () => JSX.Element;
  title?: undefined;
}

export const Tooltip: FunctionComponent<React.HTMLAttributes<HTMLDivElement> &
  (WithTitleProps | WithCustomRendererProps)> = ({
  position = "top",
  children,
  arrowMarker = true,
  customRenderer,
  title,
  dark,
  light,
  size = "medium",
  ...props
}) => {
  const tooltipClasses = twMerge(
    classNames("w-full group/tooltip h-full relative", {
      [`${props.className}`]: !!props.className,
    })
  );

  const tooltipTextClasses = classNames(
    "hidden w-max bg-background-bright text-content-dark rounded-lg leading-4 absolute z-30 border-solid border border-border-lighter",
    {
      "dark:bg-background-medium dark:border-border-regular dark:text-content-medium": !light,
      "max-w-[300px]": !customRenderer,
      "bottom-[calc(100%+12px)] left-1/2 -translate-x-1/2": position === "top",
      "top-1/2 -translate-y-1/2 right-[calc(100%+12px)]": position === "left",
      "top-1/2 -translate-y-1/2 left-[calc(100%+12px)]": position === "right",
      "-bottom-3 translate-y-full left-1/2 -translate-x-1/2": position === "bottom",
      "top-[calc(100%+12px)] right-0": position === "bottom-left",
      "top-[calc(100%+12px)] left-0": position === "bottom-right",
      "bottom-[calc(100%+12px)] right-0": position === "top-left",
      "bottom-[calc(100%+12px)] left-0": position === "top-right",
      "after:content-[' '] after:absolute after:border-[5px] after:border-transparent after:border-solid after:bg-transparent":
        arrowMarker && !customRenderer,
      "after:top-full after:left-1/2 after:-ml-[5px] after:border-t-border-lighter":
        position == "top" && arrowMarker && !customRenderer,
      "after:top-1/2 after:left-full after:-mt-[5px] after:border-l-border-lighter":
        position == "left" && arrowMarker && !customRenderer,
      "after:top-1/2 after:-left-[10px] after:-mt-[5px] after:border-r-border-lighter":
        position == "right" && arrowMarker && !customRenderer,
      "after:top-[-10px] after:left-1/2 after:-ml-[5px] after:border-b-border-lighter":
        position == "bottom" && arrowMarker && !customRenderer,
      "dark:after:border-t-border-regular": position == "top" && arrowMarker && !customRenderer && !light,
      "dark:after:border-l-border-regular": position == "left" && arrowMarker && !customRenderer && !light,
      "dark:after:border-r-border-regular": position == "right" && arrowMarker && !customRenderer && !light,
      "dark:after:border-b-border-regular": position == "bottom" && arrowMarker && !customRenderer && !light,
      "py-1 px-2 text-xs": size === "small",
      "py-2 px-4 text-sm": size === "medium",
      "py-3 px-6 text-base": size === "large",
      "group-hover/tooltip:block": (!!title && title.length > 0) || !!customRenderer,
    }
  );

  return (
    <div {...props} className={dark ? "dark" : ""}>
      <div className={tooltipClasses}>
        <div className={tooltipTextClasses}>{customRenderer ? customRenderer() : title}</div>
        {children}
      </div>
    </div>
  );
};
