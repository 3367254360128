import { toast } from "react-hot-toast";
import httpClient from "../../services/http/client";
import ROUTES, { PaginationParams } from "../../services/http/routes";
import { notifyError } from "../../services/requests";
import { Paginated, UUID } from "../common";

export interface Contract extends NewContract {
  id: UUID;
  bills_count: number;
  projects_count: number;
  amount_billed: number;
  created_at: string;
  updated_at: string;
}

export interface ContractResponse extends Contract {
  customer_name: string;
  owner_first_name: string;
  owner_last_name: string;
  owner_email: string;
}

export interface NewContract {
  customer_id: UUID;
  owner_id: UUID;
  name: string;
  description?: string;
  bills_due_date_offset?: number;
  legal_contact_id: UUID;
  billing_contact_id: UUID;
  vat_percent: number;
}

export interface ContractData {
  owner_id?: UUID;
  name?: UUID;
  description?: string;
  bills_due_date_offset?: number;
  legal_contact_id?: UUID;
  billing_contact_id?: UUID;
  vat_percent?: number;
}

export type ContractsFilters = {
  customer_id?: UUID;
} & PaginationParams;

export async function getContract(id: UUID): Promise<ContractResponse> {
  return httpClient.req<ContractResponse>(ROUTES.FETCH_CONTRACT(id), (err) =>
    notifyError(err || "Erreur lors de la récupération du contrat")
  );
}

export async function getContracts(params: ContractsFilters): Promise<Paginated<ContractResponse>> {
  return httpClient.req<Paginated<ContractResponse>>(ROUTES.FETCH_CONTRACTS(params), () =>
    notifyError("Erreur lors de la récupération des contrats")
  );
}

export async function createContract(new_contract: NewContract): Promise<Contract> {
  return httpClient
    .req<Contract>(ROUTES.CREATE_CONTRACT(new_contract), (err) =>
      notifyError(err || "Erreur lors de la création du contrat")
    )
    .then((e) => {
      toast.success("Contrat créé avec succès");
      return e;
    });
}

export async function updateContract(data: ContractData & { id: UUID }): Promise<Contract> {
  return httpClient
    .req<Contract>(ROUTES.UPDATE_CONTRACT(data), (err) => notifyError(err || "Échec de la mise à jour du contrat"))
    .then((e) => {
      toast.success("Contrat mis à jour avec succès");
      return e;
    });
}
