import React, { FunctionComponent } from "react";

// TODO ANIMATE-PULSE
export const JobOfferRowSkeleton: FunctionComponent = () => {
  return (
    <div className="bg-background-bright p-4 rounded-3xl">
      <div className="flex gap-4">
        <div className="min-w-[64px] min-h-[64px] w-16 h-16 flex items-center justify-center bg-slate-200 rounded-full" />
        <div className="flex-1 flex justify-between max-w-[calc(100%-128px)]">
          <div className="flex-1 max-w-[calc(100%-400px)]">
            <div className="w-2/3 h-6 mb-2 bg-slate-200 rounded-lg"></div>
            <div className="w-2/3 h-8 bg-slate-200 rounded-lg"></div>
            <div className="w-2/3 mt-4 h-6 bg-slate-200 rounded-lg"></div>
          </div>
          <div className="max-w-[400px] w-full py-1.5" onClick={(e) => e.stopPropagation()}>
            <div className="w-full h-6 bg-slate-200 mb-2 rounded-lg"></div>
            <div className="w-full h-14 bg-slate-200 rounded-lg"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobOfferRowSkeleton;
