import React, { useState } from "react";
import { FunctionComponent } from "react";
import { GlobalFilters, DashboardModule, getStatsTerminated } from "../../../../models/dashboards";
import { Status } from "../../../../models/jobOffers";
import { getComparePeriod } from "../../../../utils/dates";
import ModuleTitle from "../../elements/title";
import { useDeepCompareEffect } from "react-use";
import { Divider, Checkbox } from "@getprorecrutement/getpro-design";
import DashboardTimeline, { TimelineData } from "../../elements/timeline";
import { PALETTE } from "../..";
import DashboardStats from "../../elements/stats";

export const TERMINATED_SOURCES: { [terminatedSource: string]: string } = {
  getpro: "Getpro",
  candidate: "Candidat",
  client: "Client",
};

interface Props {
  filters: GlobalFilters;
  locked: boolean;
  dashboardSlug: string;
  mod: DashboardModule<{
    termination_sources?: string[];
    granularity?: "day" | "week" | "month";
    compare?: boolean;
  }>;
  statuses: Status[];
  updateModule: (data: {
    dashboard_slug: string;
    module_key: string;
    filters: {
      termination_sources?: string[];
      granularity?: "day" | "week" | "month";
      compare?: boolean;
    };
    title?: string;
  }) => Promise<void>;
}

export const TerminatedChart: FunctionComponent<Props> = (props) => {
  const { filters, locked, dashboardSlug, mod, statuses, updateModule } = props;

  const [terminatedData, setTerminatedData] = useState<{
    summary: { [name: string]: number };
    timeline: TimelineData[];
    comparison?: { [name: string]: number };
  }>();

  const setShowComparison = async (show: boolean) => {
    await updateModule({
      dashboard_slug: dashboardSlug,
      module_key: mod.key,
      filters: {
        compare: show,
      },
    });
  };

  const setGranularity = async (value: "day" | "week" | "month") => {
    await updateModule({
      dashboard_slug: dashboardSlug,
      module_key: mod.key,
      filters: {
        granularity: value,
      },
    });
  };

  const getData = (
    timeline: {
      created_at: string;
      count: number;
      termination_source: string;
    }[]
  ): TimelineData[] => {
    const res = timeline.reduce(
      (
        res: {
          [date: string]: { [name: string]: number; total: number };
        },
        data
      ) => {
        res[data.created_at] ||= { total: 0 };
        res[data.created_at][TERMINATED_SOURCES[data.termination_source]] = data.count;
        res[data.created_at].total += data.count;
        return res;
      },
      {}
    );

    return Object.keys(res).map((d) => ({ date: d, ...res[d] } as TimelineData));
  };

  const fetchStatsPipe = async () => {
    const dateRangeCompare = mod.filters.compare ? filters.period && getComparePeriod(filters.period) : undefined;

    if (statuses.length > 0) {
      const response = await getStatsTerminated({
        ...filters.dateRange,
        ...dateRangeCompare,
        granularity: mod.filters.granularity,
        users: filters.users,
        business_units: filters.businessUnits,
        job_offers: filters.jobOffers,
        customers: filters.customers,
        termination_sources: mod.filters.termination_sources || [],
      });
      const res = {
        ...response,
        summary: response.summary.reduce((o, data) => ({ ...o, [data[0]]: data[1] }), {}),
        comparison: response.comparison
          ? response.comparison.reduce((o, data) => ({ ...o, [data[0]]: data[1] }), {})
          : undefined,
      };
      setTerminatedData({
        ...res,
        timeline: getData(response.timeline),
      });
    }
  };

  const setSelectedTerminationSources = async (filter: string) => {
    const termination_sources = !(mod.filters.termination_sources || []).includes(filter)
      ? [...(mod.filters.termination_sources || []), filter] // Add
      : (mod.filters.termination_sources || []).filter((f) => f !== filter); // Remove

    await updateModule({
      dashboard_slug: dashboardSlug,
      module_key: mod.key,
      filters: {
        termination_sources,
      },
    });
  };

  useDeepCompareEffect(() => {
    fetchStatsPipe();
  }, [filters, statuses, mod.filters]);

  const terminatedSources = ["getpro", "candidate", "client"].map((v) => ({ id: v, name: TERMINATED_SOURCES[v] }));

  return (
    <div className="bg-white p-6 shadow-md mb-6">
      {!locked && (
        <div>
          <div className="flex items-center justify-between">
            <div className="flex-1 flex gap-6 items-center flex-wrap">
              {["getpro", "candidate", "client"].map((source) => (
                <Checkbox
                  key={source}
                  light
                  value={(mod.filters.termination_sources || []).includes(source)}
                  onChange={() => setSelectedTerminationSources(source)}
                  label={TERMINATED_SOURCES[source]}
                />
              ))}
            </div>
            <Divider type="vertical" style={{ height: 20 }} light />
            <div className="w-[35%]">
              <Checkbox
                label="Comparer avec la période précédente"
                light
                value={mod.filters.compare || false}
                onChange={() => setShowComparison(!mod.filters.compare)}
              />
            </div>
          </div>
          <Divider light />
        </div>
      )}
      <ModuleTitle
        title={mod.title}
        slug={dashboardSlug}
        modKey={mod.key}
        updateModule={updateModule}
        locked={locked}
      />
      <div className="flex items-cdenter justify-between gap-2">
        <div className="w-[65%]">
          <DashboardTimeline
            timeline={terminatedData?.timeline}
            setGranularity={setGranularity}
            granularity={mod.filters.granularity || "week"}
            selectedIds={mod.filters.termination_sources || []}
            statuses={terminatedSources}
            colors={PALETTE.ORANGE}
          />
        </div>
        <Divider type="vertical" style={{ height: 200 }} light />
        <div className="w-[35%]">
          <DashboardStats
            summary={terminatedData?.summary || {}}
            comparison={mod.filters.compare ? terminatedData?.comparison : undefined}
            selectedIds={mod.filters.termination_sources || []}
            statuses={terminatedSources}
            colors={PALETTE.ORANGE}
          />
        </div>
      </div>
    </div>
  );
};

export default TerminatedChart;
