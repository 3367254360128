import httpClient from "../services/http/client";
import ROUTES from "../services/http/routes";
import store from "../services/store";
import { UUID } from "./common";

export interface JobTitle {
  id: UUID;
  title: string;
  hierarchy_id: UUID;
  category_id: UUID;
}

export interface JobTitleQuery {
  search?: string;
  cateogry_id?: UUID;
  ids?: UUID[];
}

export interface AnyJobTitleFilters {
  ids?: UUID[];
}

export interface NewUnvalidatedJobTitle {
  title: string;
  category_id: UUID;
  hierarchy_id: UUID;
}

export const fetchJobTitles = (filters: JobTitleQuery): Promise<JobTitle[]> =>
  httpClient.req(ROUTES.FETCH_JOB_TITLES(filters));

export const createUnvalidatedJobTItle = (data: NewUnvalidatedJobTitle): Promise<JobTitle> =>
  httpClient.req(ROUTES.CREATE_UNVALIDATED_JOBTITLE(data));

export const deleteUnvalidatedJobTitle = async (id: UUID) => httpClient.req(ROUTES.DELETE_UNVALIDATED_JOB_TITLE(id));

/// Fetch jobTitles Or UnvalidatedJobTitles
export const fetchAnyJobTitle = (filters: AnyJobTitleFilters): Promise<JobTitle[]> =>
  httpClient.req(ROUTES.FETCH_ANY_JOB_TILTES(filters));

export async function fetchJobTitle(id: UUID): Promise<JobTitle> {
  return store.cached(id, 24 * 60 * 60, () => httpClient.req(ROUTES.FETCH_JOB_TITLE(id)));
}
