import React, { FunctionComponent, useState } from "react";
import { JobApplicationBase, JobApplicationData, JobApplicationResponse } from "../../models/jobApplications";
import { UUID } from "../../models/common";
import { CandidateInformations } from "./jobApplications/candidateInformations";
import InlineLabelValue from "../../components/inlineLabelValue";
import { euroDisplay } from "../../utils/formatters";
import dayjs from "dayjs";
import StatusSelect from "../../components/forms/inputs/statusSelect";
import { Status } from "../../models/jobOffers";
import { JobAppActions } from "./jobApplications/jobAppActions";
import { Candidate } from "../../models/candidate";
import { DatePicker } from "@getprorecrutement/getpro-design";
import { PencilSquareIcon } from "@heroicons/react/24/outline";
import qs from "qs";
import { useNavigate } from "react-router-dom";

interface Props {
  jobApp: JobApplicationResponse;
  statusIds?: UUID[];
  statuses: Status[];
  callStatuses: Status[];
  sources: { id: UUID; name: string }[];
  fetchJo: () => Promise<void>;
  updateStatusId: (value: UUID, jobApp: JobApplicationBase, candidate: Candidate) => Promise<void>;
  updateJa: (data: JobApplicationData) => Promise<void>;
}

export const RecruitedJobApp: FunctionComponent<Props> = ({
  jobApp,
  statusIds,
  statuses,
  callStatuses,
  sources,
  fetchJo,
  updateStatusId,
  updateJa,
}) => {
  const [editStartJobAt, setEditStartJobAt] = useState<boolean>(false);
  const navigate = useNavigate();

  return (
    <div className="mt-8 w-full bg-background-medium px-4 py-1 rounded-3xl text-content-bright flex items-center gap-2">
      <div className="flex-1 py-3">
        <CandidateInformations
          candidate={jobApp.candidate}
          onClick={() =>
            navigate({
              pathname: `/jobs/${jobApp.job_offer_id}`,
              search: qs.stringify({ statusIds: statusIds, candidateId: jobApp.candidate_id }),
            })
          }
          terminatedJa={jobApp.terminated}
        />
      </div>
      <div className="flex items-center gap-16 pr-6">
        <InlineLabelValue
          direction="vertical"
          label="Salaire"
          value={jobApp.candidate.salary !== undefined ? euroDisplay(jobApp.candidate.salary) : undefined}
          dark
        />
        <InlineLabelValue
          labelWidth={150}
          direction="vertical"
          label="Entrée en poste"
          value={
            jobApp.starts_job_at && (
              <div className="flex items-center gap-2 cursor-pointer group" onClick={() => setEditStartJobAt(true)}>
                {editStartJobAt ? (
                  <DatePicker
                    dark
                    defaultOpen
                    inputClassName="p-0 font-bold text-base"
                    bordered={false}
                    value={jobApp.starts_job_at}
                    onChange={async (v) => {
                      if (v) {
                        await updateJa({ starts_job_at: v });
                        setEditStartJobAt(false);
                      }
                    }}
                  />
                ) : (
                  <div>{dayjs(jobApp.starts_job_at).format("ll")}</div>
                )}
                <div className={`invisible ${editStartJobAt ? "" : "group-hover:visible"}`}>
                  <PencilSquareIcon className="w-4 h-4" />
                </div>
              </div>
            )
          }
          dark
        />
      </div>
      <div className="w-[165px]">
        <StatusSelect
          dark
          size="small"
          onSelect={async (s) => await updateStatusId(s, jobApp, jobApp.candidate)}
          value={jobApp.status_id}
          statuses={statuses}
        />
      </div>
      <div className="w-[110px]">
        <JobAppActions
          jobApp={jobApp}
          candidate={jobApp.candidate}
          onUpdate={async () => await fetchJo()}
          updateStatusId={updateStatusId}
          statuses={statuses}
          callStatuses={callStatuses}
          sources={sources}
          dark
          withCandidateActions
        />
      </div>
    </div>
  );
};

export default RecruitedJobApp;
