import classNames from "classnames";
import React, { FunctionComponent, useEffect, useRef, useState } from "react";
import { twMerge } from "tailwind-merge";
import { dropDownClasses } from "../../utils/common";

export interface Props {
  position?: "top" | "bottom" | "left" | "right" | "bottom-left" | "bottom-right" | "top-left" | "top-right";
  dark?: boolean;
  light?: boolean;
  children: JSX.Element;
  content: JSX.Element;
  tooltipProps?: React.HTMLAttributes<HTMLDivElement>;
}

export const PopOver: FunctionComponent<Props & React.HTMLAttributes<HTMLDivElement>> = ({
  position = "top",
  children,
  content,
  dark,
  light,
  tooltipProps,
  ...props
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const domRef = useRef<HTMLDivElement>(null);
  const dropDownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (open && dropDownRef.current) {
      dropDownRef.current.focus({ preventScroll: true });
    }
  }, [open]);

  const tooltipClasses = twMerge(
    classNames(`${dropDownClasses({ position, light })}`, {
      [`${tooltipProps?.className}`]: !!tooltipProps?.className,
    })
  );

  const wrapperClasses = classNames("w-fit relative", {
    dark: dark,
  });

  const classes = twMerge(
    classNames("text-content-medium cursor-pointer", {
      "dark:text-content-bright": !light,
      [`${props.className}`]: !!props.className,
    })
  );

  return (
    <div className={wrapperClasses}>
      <div {...props} tabIndex={0} onClick={() => setOpen(!open)} ref={domRef} className={classes}>
        {children}
      </div>
      {open && (
        <div
          tabIndex={0}
          ref={dropDownRef}
          onBlur={(ev) => {
            if (ev.relatedTarget?.contains(domRef.current)) {
              return;
            }

            if (
              !(ev.relatedTarget && dropDownRef.current && (dropDownRef.current as Node).contains(ev.relatedTarget))
            ) {
              setOpen(false);
            }
          }}
          {...tooltipProps}
          className={tooltipClasses}
        >
          {content}
        </div>
      )}
    </div>
  );
};
