import { CandidateSearchQuery } from "../candidate";
import { UUID } from "../common";
import { UserDashboard } from "../dashboards";
import ROUTES from "../../services/http/routes";
import httpClient from "../../services/http/client";
import { notifyError } from "../../services/requests";
import { UserResponse } from "./users";
import store from "../../services/store";

export interface SavedCandidateQuery {
  id: UUID;
  name: string;
  query: CandidateSearchQuery;
}

export interface UserSettings {
  saved_candidate_queries?: SavedCandidateQuery[];
  dashboards: UserDashboard[];
}

export async function saveQuery(data: Omit<SavedCandidateQuery, "id">): Promise<UserSettings> {
  store.invalidateCache(["settings/me"]);
  return httpClient.req(ROUTES.SAVE_QUERY(data), (err) =>
    notifyError(err || "Erreur lors de l'enregistrement de la recherche")
  );
}

export async function deleteSavedQuery(id: UUID): Promise<UserSettings> {
  store.invalidateCache(["settings/me"]);
  return httpClient.req(ROUTES.DELETE_SAVED_QUERY(id), (err) =>
    notifyError(err || "Erreur lors de la suppression de la recherche")
  );
}

export async function getMySettings(): Promise<UserSettings> {
  return store.cached("settings/me", 60 * 30, () =>
    httpClient.req(ROUTES.FETCH_MY_SETTINGS(), (err) =>
      notifyError(err || "Erreur lors de la récupération des paramètres")
    )
  );
}

export async function updateMySettings(data: UserSettings): Promise<UserResponse> {
  store.invalidateCache(["settings/me"]);
  return httpClient.req(ROUTES.UPDATE_MY_SETTINGS(data), (err) =>
    notifyError(err || "Erreur lors de la mise à jour de vos paramètres")
  );
}

export async function updateModuleInSettings(data: {
  dashboard_slug: string;
  module_key: string;
  filters: unknown;
  title?: string;
}): Promise<void> {
  store.invalidateCache(["settings/me"]);
  return httpClient.req(ROUTES.UPDATE_MODULE_IN_SETTINGS(data), (err) =>
    notifyError(err || "Erreur lors de la mise à jour du module")
  );
}
