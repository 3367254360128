import httpClient from "../services/http/client";
import ROUTES from "../services/http/routes";
import { notifyError } from "../services/requests";
import store from "../services/store";
import { UUID } from "./common";

export enum CTKind {
  Location = "location",
  Salary = "salary",
  Experience = "experience",
  JobType = "job_type",
  Meta = "meta",
}

export interface Category extends NewCategory {
  id: string;
}

export interface NewCategory {
  category_type_id?: UUID;
  index: number;
  name: string;
}

export interface CategoryType extends NewCategoryType {
  id: string;
  kind: CTKind;
  index: number;
}

export interface NewCategoryType {
  name: string;
  parent_id?: UUID;
}

export interface GroupedCategories {
  name: string;
  kind: CTKind;
  categories: Category[];
}

export function groupCategories(categories: Category[], cTypes: CategoryType[]): GroupedCategories[] {
  const res = [] as GroupedCategories[];

  cTypes.forEach((ct) => {
    const group = {
      name: ct.name,
      kind: ct.kind,
      categories: [],
    } as GroupedCategories;

    group.categories = group.categories.concat(categories.filter((c: Category) => c.category_type_id === ct.id));
    res.push(group);
  });

  return res;
}

export async function getCategories(): Promise<Category[]> {
  return store.cached("categories", 60 * 30, () =>
    httpClient.req(ROUTES.FETCH_CATEGORIES(), (err) =>
      notifyError(err || "Erreur lors de la récupération des catégories")
    )
  );
}

export async function createCategory(new_category: NewCategory): Promise<Category> {
  store.invalidateCache(["categories"]);
  return httpClient.req(ROUTES.CREATE_CATEGORY(new_category), (err) =>
    notifyError(err || "Erreur lors de la création de la catégorie")
  );
}

export async function updateCategory(data: Category): Promise<Category> {
  store.invalidateCache(["categories"]);
  return httpClient.req(ROUTES.UPDATE_CATEGORY(data), (err) =>
    notifyError(err || "Erreur lors de la modification de la catégorie")
  );
}

export async function deleteCategory(id: UUID): Promise<void> {
  store.invalidateCache(["categories"]);
  return httpClient.req(ROUTES.DELETE_CATEGORY(id), (err) =>
    notifyError(
      err ||
        "Erreur lors de la suppression de la catégorie, merci de vérifier qu'elle n'est pas référencée par une offre"
    )
  );
}

export async function getCategoryTypes(): Promise<CategoryType[]> {
  return store.cached("categoryTypes", 60 * 30, () => httpClient.req(ROUTES.FETCH_CATEGORY_TYPES()));
}
