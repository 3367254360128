import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/outline";
import classNames from "classnames";
import React, { FunctionComponent, useEffect, useRef, useState } from "react";

export interface Props {
  values: JSX.Element[];
  autoPlay?: boolean;
  interval?: number;
  showIndicators?: boolean;
  manualIndex?: number;
}

enum TransitionDirection {
  LEFT,
  RIGHT,
}

export const ElementCarousel: FunctionComponent<Props> = ({
  values,
  autoPlay = true,
  interval = 3000,
  showIndicators = true,
  manualIndex,
}) => {
  const [currentIndex, setCurrentIndex] = useState<number>(manualIndex ? manualIndex : 0);
  const [transitionDirection, setTransitionDirection] = useState<TransitionDirection>();
  const [intervalId, setIntervalId] = useState<number>();
  const [clickedIndex, setClickedIndex] = useState<number>();

  useEffect(() => {
    if (manualIndex != undefined && manualIndex != currentIndex) {
      if (autoPlay) {
        resetInterval();
      }
      setTransitionDirection(manualIndex < currentIndex ? TransitionDirection.RIGHT : TransitionDirection.LEFT);
      setClickedIndex(manualIndex);
      window.setTimeout(() => {
        setTransitionDirection(undefined);
        setClickedIndex(undefined);
        setCurrentIndex(manualIndex);
      }, 300);
    }
  }, [manualIndex]);

  useEffect(() => {
    if (autoPlay) {
      setIntervalId(launchAutoPlay());
      return () => {
        setIntervalId((interal) => {
          if (interal) {
            window.clearInterval(interal);
          }
          return undefined;
        });
      };
    }
  }, []);

  const launchAutoPlay = () => {
    return window.setInterval(() => {
      setTransitionDirection(TransitionDirection.LEFT);
      window.setTimeout(() => {
        setTransitionDirection(undefined);
        setCurrentIndex(nextIndex);
      }, 300);
    }, interval);
  };

  const resetInterval = () => {
    if (intervalId != undefined) {
      window.clearInterval(intervalId);
    }
    setIntervalId(launchAutoPlay());
  };

  const nextIndex = (index: number) => {
    return (index + 1) % values.length;
  };

  const prevIndex = (index: number) => {
    let new_index = index - 1;
    return new_index >= 0 ? new_index : values.length - 1;
  };

  const wrapperClasses = classNames(
    "w-full h-full relative w-full h-full flex justify-center items-center duration-300",
    {
      "transition-all -translate-x-full": transitionDirection == TransitionDirection.LEFT,
      "transition-all translate-x-full": transitionDirection == TransitionDirection.RIGHT,
      "transition-none": transitionDirection == undefined,
    }
  );

  const prev = () => {
    setTransitionDirection(TransitionDirection.RIGHT);
    if (autoPlay) {
      resetInterval();
    }
    window.setTimeout(() => {
      setTransitionDirection(undefined);
      setCurrentIndex(prevIndex);
    }, 300);
  };

  const next = () => {
    if (autoPlay) {
      resetInterval();
    }
    setTransitionDirection(TransitionDirection.LEFT);
    window.setTimeout(() => {
      setTransitionDirection(undefined);
      setCurrentIndex(nextIndex);
    }, 300);
  };

  return (
    <div className="relative w-full h-full">
      <div className="overflow-hidden w-full h-full">
        <div className={wrapperClasses}>
          <div className="absolute right-full w-full h-full top-0 flex justify-center items-center">
            {
              values[
                clickedIndex != undefined && transitionDirection == TransitionDirection.RIGHT
                  ? clickedIndex
                  : prevIndex(currentIndex)
              ]
            }
          </div>
          {values[currentIndex]}
          <div className="absolute left-full w-full h-full top-0 flex justify-center items-center">
            {
              values[
                clickedIndex != undefined && transitionDirection == TransitionDirection.LEFT
                  ? clickedIndex
                  : nextIndex(currentIndex)
              ]
            }
          </div>
        </div>
      </div>

      {showIndicators && [
        <ArrowLeftIcon
          key="left-arrow-controller"
          height={24}
          width={24}
          onClick={prev}
          className="cursor-pointer absolute left-3 top-1/2"
        />,
        <ArrowRightIcon
          key="right-arrow-controller"
          height={24}
          width={24}
          onClick={next}
          className="cursor-pointer absolute right-3 top-1/2"
        />,
      ]}

      <div className="absolute bottom-5 w-full flex justify-center gap-2">
        {showIndicators &&
          [...Array(values.length).keys()].map((index) => {
            return (
              <div
                key={index}
                className={`h-2 w-2 cursor-pointer rounded-full border border-primary-medium ${
                  index == currentIndex ? "bg-primary-dark" : ""
                }`}
                onClick={() => {
                  if (autoPlay) {
                    resetInterval();
                  }
                  setTransitionDirection(index < currentIndex ? TransitionDirection.RIGHT : TransitionDirection.LEFT);
                  setClickedIndex(index);
                  window.setTimeout(() => {
                    setTransitionDirection(undefined);
                    setClickedIndex(undefined);
                    setCurrentIndex(index);
                  }, 300);
                }}
              ></div>
            );
          })}
      </div>
    </div>
  );
};
