import React, { FunctionComponent } from "react";
import { Contact } from "../../../models/contacts";
import { UUID } from "../../../models/common";
import { Button, PopConfirm } from "@getprorecrutement/getpro-design";
import { PencilIcon, TrashIcon } from "@heroicons/react/24/outline";
import { EnvelopeIcon, PhoneIcon } from "@heroicons/react/24/solid";

interface Props {
  contact: Contact;
  onDelete?: (id: UUID) => void;
  onEdit?: (contact: Contact) => void;
}

export const ContactCard: FunctionComponent<Props> = (props) => {
  const { contact, onDelete, onEdit } = props;

  return (
    <div key={contact.id} className="bg-white p-6 shadow-md">
      <div className="flex justify-between items-center gap-6">
        <div className="font-bold">
          {contact.first_name} {contact.last_name}
        </div>
        <div className="flex gap-2">
          {onEdit && (
            <Button
              light
              className="p-0"
              kind="light"
              size="small"
              onClick={() => onEdit(contact)}
              icon={<PencilIcon />}
            />
          )}
          {onDelete && (
            <div className="hover:text-red-400">
              <PopConfirm
                title={`Supprimer le contact ${contact.first_name} ${contact.last_name} ?`}
                onValidate={() => onDelete(contact.id)}
                position="top"
                light
              >
                <Button size="small" light className="p-0" kind="light" icon={<TrashIcon />} />
              </PopConfirm>
            </div>
          )}
        </div>
      </div>
      <div className="mt-4">
        <div className="flex items-center gap-2">
          <EnvelopeIcon className="w-4 h-4" />
          <div>{contact.email}</div>
        </div>
        <div className="flex items-center gap-2">
          <PhoneIcon className="w-4 h-4" />
          <div>{contact.phone}</div>
        </div>
      </div>
    </div>
  );
};

export default ContactCard;
