import React, { useEffect, useState } from "react";
import {
  createRole,
  getPermissions,
  getRoles,
  PermissionsCat,
  Role,
  updateRole,
  deleteRole as removeRole,
} from "../../../models/roles";
import { UUID } from "../../../models/common";
import { Modal, PopOver, Select, Spinner, Tab, TextInput, Button } from "@getprorecrutement/getpro-design";
import { ArrowRightIcon, ChevronRightIcon, DocumentDuplicateIcon, XMarkIcon } from "@heroicons/react/24/outline";
import RolesTree from "./tree";
import toast from "react-hot-toast";

export const SettingsRoles = () => {
  const [selectedRole, setSelectedRole] = useState<Role>();
  const [roles, setRoles] = useState<Role[]>([]);
  const [selectedPermissions, setSelectedPermissions] = useState<string[]>([]);
  const [permissionCategories, setPermissionCategories] = useState<PermissionsCat[]>([]);
  const [selectedPermissionCategories, setSelectedPermissionCategories] = useState<PermissionsCat[]>([]);
  const [creation, setCreation] = useState<boolean>(false);
  const allPermissions = permissionCategories.map((e) => e.permissions).flat();
  const [newRole, setNewRole] = useState<string>();
  const [roleToDelete, setRoleToDelete] = useState<Role | undefined>();
  const [newRoleId, setNewRoleId] = useState<UUID>();
  const isModified =
    !selectedPermissions.every((role) => selectedRole?.permissions.includes(role)) ||
    selectedRole?.permissions.length !== selectedPermissions.length;

  const fetchRoles = async () => {
    const roles = await getRoles();
    setRoles(roles);
    setSelectedRole(roles.find((r) => r.id === selectedRole?.id) || roles[0]);
  };

  const fetchPermissions = async () => {
    const permissions = await getPermissions();
    setPermissionCategories(permissions);
  };

  const updatePermissions = async (permissions: string[]) => {
    if (selectedRole) {
      await updateRole({ ...selectedRole, permissions });
      fetchRoles();
      toast.success("Permissions du rôle définies avec succès");
    }
  };

  const _createRole = async () => {
    if (newRole)
      await createRole({ name: newRole, permissions: [], manages: [] }).then(() =>
        toast.success("Rôle créé avec succès")
      );
    setNewRole(undefined);
    fetchRoles();
    setCreation(false);
  };

  const updateManages = async (manages: UUID[]) => {
    if (selectedRole) {
      await updateRole({ ...selectedRole, manages });
      fetchRoles();
    }
  };

  const importPermissions = async (id?: UUID) => {
    if (id) {
      const role = roles.find((role) => role.id === id);

      if (role) await updatePermissions(role.permissions);
    }
  };

  useEffect(() => {
    if (selectedRole) {
      setSelectedPermissions(selectedRole.permissions);
      setSelectedPermissionCategories(
        permissionCategories
          .filter((pCat) => !!pCat.permissions.find((perm) => selectedRole.permissions.includes(perm.key)))
          .map((pCat) => ({
            ...pCat,
            permissions: pCat.permissions.filter((p) => selectedRole.permissions.includes(p.key)),
          }))
      );
    }
  }, [selectedRole]);

  useEffect(() => {
    fetchPermissions();
    fetchRoles();
  }, []);

  const deleteRole = async (data: { target_id: UUID }) => {
    if (roleToDelete) {
      await removeRole(roleToDelete.id, data.target_id);
      await fetchRoles();
      setRoleToDelete(undefined);
    }
  };

  const onSelect = (key: string) => {
    if (allPermissions.map((e) => e.key).includes(key)) {
      if (selectedPermissions.includes(key)) setSelectedPermissions((ps) => ps.filter((p) => p !== key));
      else setSelectedPermissions((ps) => [...ps, key]);
    } else {
      const permissionsOfCat =
        permissionCategories.find((pCat) => pCat.name === key)?.permissions?.map((p) => p.key) || [];
      if (permissionsOfCat.every((p) => selectedPermissions.includes(p)))
        setSelectedPermissions((ps) => ps.filter((p) => !permissionsOfCat.includes(p)));
      else setSelectedPermissions((ps) => [...ps, ...permissionsOfCat]);
    }
  };

  return (
    <div className="h-full min-h-screen flex flex-col">
      <div className="bg-white p-6 flex items-center border-b border-solid border-border-lightest">
        <div className="text-3xl font-bold">Rôles & Permissions</div>
      </div>
      {selectedRole ? (
        <div className="flex flex-1">
          <div className="w-fit pr-4 border-r border-solid border-border-bright ">
            <Tab
              values={roles}
              getKey={(v) => v.id}
              customRenderer={(v, onClick, setRef) => (
                <div
                  key={v.id}
                  className="px-4 py-2 cursor-pointer flex items-center gap-2 group"
                  ref={setRef}
                  onClick={onClick}
                >
                  <div
                    className={
                      selectedRole.name === v.name ? "text-content-dark" : "hover:text-content-dark text-content-light"
                    }
                  >
                    {v.name}
                  </div>
                  <XMarkIcon
                    onClick={(e) => {
                      e.preventDefault();
                      setRoleToDelete(v);
                    }}
                    className="w-4 h-4 stroke-2 min-w-fit text-red-400 invisible group-hover:visible"
                  />
                </div>
              )}
              onChange={(v) => setSelectedRole(v)}
              selected={selectedRole}
              direction="vertical"
              light
            />
            {creation ? (
              <div className="pl-4">
                <TextInput
                  type="text"
                  inputClassName="px-1 py-2 border-t-0 border-x-0 rounded-none w-40"
                  suffix={
                    <div className="flex items-center" onClick={_createRole}>
                      <ArrowRightIcon className="w-4 h-4 min-w-fit cursor-pointer text-content-light hover:text-content-dark" />
                    </div>
                  }
                  placeholder="Nom du rôle"
                  value={newRole}
                  onChange={(e) => setNewRole(e.currentTarget.value)}
                />
              </div>
            ) : (
              <div onClick={() => setCreation(true)} className="px-4 py-2 cursor-pointer">
                Ajouter un rôle
              </div>
            )}
          </div>
          <div className="flex-1 h-full p-6">
            <div className="flex items-center gap-4 bg-white">
              <Select
                type="multiple"
                className="w-96"
                placeholder="Rôles gérés"
                label="Rôles gérés"
                bordered
                rounded
                options={roles}
                value={roles.filter((r) => selectedRole.manages.includes(r.id))}
                getKey={(v) => v.id}
                optionRender={(v) => <div className="whitespace-nowrap">{v.name}</div>}
                onChange={(v) =>
                  v &&
                  updateManages(
                    selectedRole.manages.includes(v.id)
                      ? selectedRole.manages.filter((m) => m != v.id)
                      : [...selectedRole.manages, v.id]
                  )
                }
              />
              <PopOver
                position="bottom"
                content={
                  <div>
                    {roles
                      .filter((role) => selectedRole.id !== role.id)
                      .map((role) => (
                        <div
                          className="text-content-medium hover:text-content-dark cursor-pointer whitespace-nowrap"
                          key={role.id}
                          onClick={() => importPermissions(role.id)}
                        >
                          {role.name}
                        </div>
                      ))}
                  </div>
                }
              >
                <Button title="Copier des permissions" icon={<DocumentDuplicateIcon />} light />
              </PopOver>
            </div>
            <div className="flex gap-4 mt-4">
              <RolesTree permissionsCat={permissionCategories} onSelect={onSelect} values={selectedPermissions} />
              <div className="flex items-center">
                <Button
                  icon={<ChevronRightIcon />}
                  disabled={!isModified}
                  onClick={() => isModified && updatePermissions(selectedPermissions)}
                />
              </div>
              <RolesTree permissionsCat={selectedPermissionCategories} />
            </div>
          </div>
        </div>
      ) : (
        <div className="flex justify-center">
          <Spinner light />
        </div>
      )}
      <Modal
        className="w-modal-md"
        show={!!roleToDelete}
        onClose={() => {
          setNewRoleId(undefined);
          setRoleToDelete(undefined);
        }}
      >
        <div className="text-2xl">Suppression du rôle {roleToDelete?.name}</div>
        <div className="flex flex-col gap-6 mt-6 bg-inherit">
          <Select
            bordered
            rounded
            type="single"
            label="Remplacer par"
            onChange={(v) => v && setNewRoleId(v.id)}
            options={roles}
            getKey={(v) => v.id}
            optionRender={(v) => v.name}
          />
          <div className="flex justify-end">
            <Button
              title="Enregistrer"
              disabled={!newRoleId}
              onClick={() => newRoleId && deleteRole({ target_id: newRoleId })}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default SettingsRoles;
