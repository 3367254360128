import httpClient from "../services/http/client";
import ROUTES from "../services/http/routes";
import { notifyError } from "../services/requests";
import store from "../services/store";
import { UUID } from "./common";

export interface Role extends NewRole {
  id: UUID;
}

export interface NewRole {
  name: string;
  permissions: string[];
  manages: UUID[];
}

export interface RoleData {
  name?: string;
  permissions?: string[];
  manages?: UUID[];
}

export interface PermissionsCat {
  name: string;
  permissions: Permission[];
}

export interface Permission {
  key: string;
  description: string;
}

export async function getPermissions(): Promise<PermissionsCat[]> {
  return store.cached("permissions", 60 * 30, () => httpClient.req(ROUTES.FETCH_PERMISSIONS(), notifyError));
}

export async function getRoles(): Promise<Role[]> {
  return store.cached("roles", 60 * 30, () => httpClient.req(ROUTES.FETCH_ROLES(), notifyError));
}

export async function createRole(newRole: NewRole): Promise<Role> {
  store.invalidateCache(["roles"]);
  return httpClient.req(ROUTES.CREATE_ROLE(newRole), notifyError);
}

export async function updateRole(role: RoleData & { id: UUID }): Promise<Role> {
  store.invalidateCache(["roles"]);
  return httpClient.req(ROUTES.UPDATE_ROLE(role), notifyError);
}

export async function deleteRole(id: UUID, target_id: UUID): Promise<void> {
  store.invalidateCache(["roles"]);
  return httpClient.req(ROUTES.DELETE_ROLE(id, target_id), notifyError);
}
