import React, { useState } from "react";
import {
  JA_TERMINATION_SOURCES,
  JobApplicationBase,
  JobApplicationTerminationSource,
  deleteJa,
  getEmailTemplate,
  restartJa,
  terminateJa,
} from "../../../models/jobApplications";
import closingIcon from "../../../assets/closing.svg";
import {
  ArrowPathIcon,
  CheckIcon,
  EnvelopeIcon,
  InformationCircleIcon,
  PlusCircleIcon,
  ShieldCheckIcon,
  TrashIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { Button, Modal, MoreActions, PopOver, Tooltip } from "@getprorecrutement/getpro-design";
import { UUID } from "../../../models/common";
import { Candidate, getCandidateConsentToken } from "../../../models/candidate";
import { copyToClipboard } from "../../../utils";
import toast from "react-hot-toast";
import store, { Message } from "../../../services/store";
import JobAppForm from "../../../components/forms/jobAppForm";
import TerminateForm from "./terminateForm";
import { Status } from "../../../models/jobOffers";
import JobAppInfos from "../../../components/jobAppInfos";

interface Props<T extends JobApplicationBase> {
  jobApp: T;
  candidate: Candidate;
  onUpdate: () => void;
  updateStatusId: (id: UUID, jobApp: T, candidate: Candidate) => Promise<void>;
  statuses: Status[];
  callStatuses: Status[];
  sources: { id: UUID; name: string }[];
  dark?: boolean;
  withCandidateActions?: boolean;
}

enum MessengerTrigger {
  Contact,
  Accept,
  Reject,
}

export const JobAppActions = <T extends JobApplicationBase>({
  jobApp,
  candidate,
  onUpdate,
  updateStatusId,
  statuses,
  callStatuses,
  sources,
  dark,
  withCandidateActions,
}: Props<T>) => {
  const [infosModalOpen, setInfosModalOpen] = useState<T>();
  const [addToJoModalOpen, setAddToJoModalOpen] = useState<Candidate>();
  const [terminateModalOpen, setTerminateModalOpen] = useState<T>();

  async function triggerMessenger(trigger: MessengerTrigger = MessengerTrigger.Contact) {
    let template;
    let afterSend: () => void;
    switch (trigger) {
      case MessengerTrigger.Accept:
        afterSend = async () => await updateStatusId("c596e2bc-d302-4b5e-bc25-ff6afe66ec4b", jobApp, candidate);
        template = await getEmailTemplate(jobApp.id, "accept");
        break;
      case MessengerTrigger.Reject:
        afterSend = async () => {
          const terminationData = {
            termination_source: JobApplicationTerminationSource.GetPro,
          };
          await terminateJa(jobApp.id, terminationData);
          onUpdate();
        };
        template = await getEmailTemplate(jobApp.id, "reject");
        break;
      case MessengerTrigger.Contact:
      default:
        afterSend = () => undefined;
        template = { body: "", subject: "" };
    }

    store.notify(Message.Messenger, {
      label: candidate.full_name || "Candidat anonyme",
      to: candidate.email,
      body: template.body,
      subject: template.subject,
      closeAfterSend: true,
      jaId: jobApp.id,
      afterSend,
    });
  }

  async function deleteJobApplication(id: UUID) {
    await deleteJa(id);
    onUpdate();
    toast.success("Candidature supprimée");
  }

  async function restartJobApplication(id: UUID) {
    await restartJa(id);
    onUpdate();
  }

  async function getConsentToken(id: UUID) {
    const url = process.env.STAGING === "true" ? process.env.STAGING_RGPD_URL : process.env.RGPD_URL;
    const { token } = await getCandidateConsentToken(id);
    copyToClipboard(`${url}/consent/${token}`, "Le lien RGPD a été copié dans votre presse-papier");
    onUpdate();
  }

  const actions = () => [
    { icon: <InformationCircleIcon />, title: "Voir l'historique", action: () => setInfosModalOpen(jobApp) },
    ...(withCandidateActions
      ? [
          {
            icon: <PlusCircleIcon />,
            title: "Ajouter à une offre",
            action: () => setAddToJoModalOpen(candidate),
          },
          { icon: <ShieldCheckIcon />, title: "Lien RGPD", action: () => getConsentToken(jobApp.candidate_id) },
        ]
      : []),
    {
      icon: <EnvelopeIcon />,
      disabled: !candidate.email,
      title: "Contacter",
      action: () => triggerMessenger(MessengerTrigger.Contact),
    },
    {
      icon: <TrashIcon />,
      title: "Supprimer",
      action: () => deleteJobApplication(jobApp.id),
      popConfirm: { show: true, title: "Supprimer la candidature ?" },
    },
  ];

  return (
    <div>
      <div className="flex gap-2 justify-end items-center">
        {!jobApp.terminated && jobApp.status_id === "97df2814-b7b7-41ce-82a4-a6583ad64303" ? (
          <div className="flex gap-2 items-center">
            <div
              className="w-7 h-7 cursor-pointer border border-solid border-green-200 hover:border-green-400 flex justify-center items-center rounded-full"
              onClick={() => triggerMessenger(MessengerTrigger.Accept)}
            >
              <CheckIcon className="text-green-400" width={20} height={20} />
            </div>
            <div
              className="w-7 h-7 cursor-pointer border border-solid border-red-200 hover:border-red-400 flex justify-center items-center rounded-full"
              onClick={() => triggerMessenger(MessengerTrigger.Reject)}
            >
              <XMarkIcon className="text-red-400" width={20} height={20} />
            </div>
          </div>
        ) : jobApp.terminated ? (
          <PopOver
            dark
            content={
              <div>
                {jobApp.termination_source && (
                  <div className="text-center font-bold whitespace-nowrap px-4">
                    Clôturée par {jobApp.termination_source === "getpro" ? "" : "le "}
                    {JA_TERMINATION_SOURCES[jobApp.termination_source].toLowerCase()}
                  </div>
                )}
                {jobApp.termination_comment && (
                  <div className="min-w-[250px] text-center mt-2">{jobApp.termination_comment}</div>
                )}
                <div className="flex justify-center mt-4">
                  <Button
                    light
                    size="small"
                    icon={<ArrowPathIcon />}
                    title="Réouvrir"
                    onClick={() => restartJobApplication(jobApp.id)}
                  />
                </div>
              </div>
            }
            position="top-left"
          >
            <InformationCircleIcon
              className="cursor-pointer hover:text-content-darker text-content-light"
              width={20}
              height={20}
            />
          </PopOver>
        ) : (
          <Tooltip title="Clôturer" light>
            <img
              src={closingIcon}
              onClick={() => setTerminateModalOpen(jobApp)}
              style={{ width: 20 }}
              className="cursor-pointer"
            />
          </Tooltip>
        )}
        <MoreActions dark={dark} light={dark ? false : true} actions={actions()} />
      </div>
      <Modal light show={!!infosModalOpen} onClose={() => setInfosModalOpen(undefined)} className="w-modal-lg">
        {infosModalOpen && (
          <JobAppInfos jobApp={infosModalOpen} sources={sources} statuses={statuses} callStatuses={callStatuses} />
        )}
      </Modal>
      <Modal className="w-modal-md" light show={!!addToJoModalOpen} onClose={() => setAddToJoModalOpen(undefined)}>
        {addToJoModalOpen && (
          <JobAppForm candidate={addToJoModalOpen} onFinish={() => setAddToJoModalOpen(undefined)} />
        )}
      </Modal>
      <Modal className="w-modal-md" light show={!!terminateModalOpen} onClose={() => setTerminateModalOpen(undefined)}>
        {terminateModalOpen && (
          <TerminateForm
            jobApplication={terminateModalOpen}
            onFinish={() => {
              setTerminateModalOpen(undefined);
              onUpdate();
            }}
          />
        )}
      </Modal>
    </div>
  );
};
