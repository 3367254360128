import { Tab } from "@getprorecrutement/getpro-design";
import React, { useEffect, useState } from "react";
import BillAndBookingList from "../../../components/billing/billsAndBookingList";
import BillingCharts from "../../../components/billing/charts";
import ProjectList from "../../../components/billing/projects/projectList";
import { BillResponse, BillsFilters, BillStatus, getBills } from "../../../models/billing/bill";
import { getProjects, ProjectResponse, ProjectsFilters, ProjectType } from "../../../models/billing/project";
import { Paginated } from "../../../models/common";

const MENU_LIST = [
  { label: "Factures", value: "bills" },
  { label: "Projets", value: "projects" },
];

export const BillingDashboardPage = () => {
  const [projects, setProjects] = useState<Paginated<ProjectResponse>>();
  const [bills, setBills] = useState<Paginated<BillResponse>>();
  const [selectedList, setSelectedList] = useState<{ label: string; value: string }>(MENU_LIST[0]);

  const fetchProjects = async (filters: ProjectsFilters) => {
    const p = await getProjects(filters);
    setProjects(p);
  };

  const fetchBills = async (filters: BillsFilters, project_types: ProjectType[]) => {
    const b = await getBills({
      ...filters,
      project_types,
    });
    setBills(b);
  };

  useEffect(() => {
    fetchProjects({ page: 1, per_page: 10 });
  }, []);

  return (
    <div className="billingDashboard">
      <div className="bg-white p-6 shadow-md mb-6">
        <div className="text-3xl font-bold">Dashboard</div>
      </div>
      <BillingCharts />
      <Tab
        values={MENU_LIST}
        getKey={(v) => v.value}
        getLabel={(v) => v.label}
        onChange={(v) => setSelectedList(v)}
        selected={selectedList}
        light
      />
      {selectedList.value === "bills" ? (
        <BillAndBookingList bills={bills} fetchBills={fetchBills} edition defaultStatuses={[BillStatus.Billed]} />
      ) : (
        <ProjectList edition fetchProjects={fetchProjects} projects={projects} />
      )}
    </div>
  );
};

export default BillingDashboardPage;
