import React, { FunctionComponent, useState, useEffect } from "react";
import { GeocodedLocation, geocode } from "../../../../models/common";
import { Select } from "@getprorecrutement/getpro-design";
import { useDebounce } from "../../../../utils";

interface Props {
  onSelect: (v: GeocodedLocation) => void;
  value?: GeocodedLocation;
  error?: string;
  placeholder?: string;
  style?: React.CSSProperties;
  label?: string;
  size?: "small" | "medium" | "large";
}

export const GeocodingSelect: FunctionComponent<Props> = (props) => {
  const [geocodingResults, setGeocodingResults] = useState<GeocodedLocation[]>([]);
  const [geocodingLoading, setGeocodingLoading] = useState<boolean>(false);

  const [search, setSearch] = useState<string>("");
  const debouncedGeocoding = useDebounce(search, 1000);

  const triggerGeocoding = async (query: string) => {
    setGeocodingLoading(true);

    geocode(query)
      .then(setGeocodingResults)
      .finally(() => setGeocodingLoading(false));
  };

  useEffect(() => {
    if (debouncedGeocoding) triggerGeocoding(debouncedGeocoding);
  }, [debouncedGeocoding]);

  return (
    <span style={props.style} className="userSelect bg-inherit">
      <Select
        light
        error={props.error}
        bordered
        rounded
        label={props.label}
        size={props.size}
        type="single"
        options={geocodingResults}
        optionRender={(c) => c.formatted_address}
        getKey={(c) => c.formatted_address}
        onSearch={setSearch}
        loading={geocodingLoading}
        onChange={(v) => v && props.onSelect(v)}
        placeholder={props.placeholder || "Sélectionner une adresse"}
        notFoundPlaceholder="Aucunes adresses disponibles"
        value={props.value}
      />
    </span>
  );
};

export default GeocodingSelect;
