import React, { FunctionComponent, useState, useEffect, useCallback, useMemo } from "react";
import { NominatimLocationResponse, UUID, geocodeCity } from "../../../../models/common";
import { Select } from "@getprorecrutement/getpro-design";
import { useDebounce } from "../../../../utils";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import flags from "../../../../assets/icons/flags/*.svg";
import { NewCandidateLocation, getCandidateLocations } from "../../../../models/candidateLocation";
import { PlusIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { toast } from "react-hot-toast";

type PendingCandidateLocation = NewCandidateLocation & NominatimLocationResponse;

interface Props {
  onSelect: (v: NewCandidateLocation[]) => void;
  candidateId: UUID;
  error?: string;
  placeholder?: string;
  style?: React.CSSProperties;
  label?: string;
  size?: "small" | "medium" | "large";
}

const RadiusOptions = [5_000, 10_000, 20_000, 50_000, 100_000];

interface CitySelectProps {
  value?: NominatimLocationResponse & { max_radius?: number };
  onChange: (value: NominatimLocationResponse & { max_radius?: number }) => void;
  hideRadius?: boolean;
  size?: "small" | "medium" | "large";
  customValueRender?: (value: JSX.Element) => JSX.Element;
}

enum DisplayMode {
  City,
  PostCode,
  Full,
}

const useCancelablePromise = <A, T>(callback: (args: A) => Promise<T>): ((args: A) => Promise<T>) => {
  let promiseRejector: (() => void) | undefined = undefined;
  return (args: A) => {
    if (promiseRejector) {
      promiseRejector();
    }
    const promise = new Promise((_, reject) => (promiseRejector = reject));
    return Promise.race([callback(args), promise]) as Promise<T>;
  };
};

export const CitySelect: FunctionComponent<CitySelectProps> = ({
  value,
  onChange,
  hideRadius,
  size = "medium",
  customValueRender,
}) => {
  const [search, setSearch] = useState<string>("");
  const debouncedGeocoding = useDebounce(search, 150);

  const [geocodingResults, setGeocodingResults] = useState<NominatimLocationResponse[]>([]);
  const [geocodingLoading, setGeocodingLoading] = useState<boolean>(false);

  const triggerGeocoding = useMemo(
    () =>
      useCancelablePromise(async (query: string) => {
        setGeocodingLoading(true);

        return await geocodeCity(query);
      }),
    []
  );

  const onSelect = (value?: NominatimLocationResponse) => {
    if (value) onChange({ ...value, max_radius: !hideRadius ? 10_000 : undefined });
  };

  useEffect(() => {
    if (debouncedGeocoding)
      triggerGeocoding(debouncedGeocoding)
        .then(setGeocodingResults)
        .finally(() => setGeocodingLoading(false));
  }, [debouncedGeocoding]);

  const displayMode = useMemo(() => {
    let canDisplayPostalCode = true;
    const cityCountByCoutry = geocodingResults.reduce((acc, next) => {
      if (!next.postal_code) canDisplayPostalCode = false;
      const name = `${next.name.toLowerCase()}-${next.country_code}`;
      acc[name] = (acc[name] || 0) + 1;

      return acc;
    }, {} as { [i: string]: number });
    if (Math.max(...Object.values(cityCountByCoutry)) > 1) {
      return canDisplayPostalCode ? DisplayMode.PostCode : DisplayMode.Full;
    }
    return DisplayMode.City;
  }, [geocodingResults]);

  const render = (value: NominatimLocationResponse) => {
    if (value.country_code) {
      return (
        <div className="flex gap-2 items-center justify-between">
          {value.country_code && <img className="h-4 w-4" src={flags[value.country_code]} />}
          <div>
            {displayMode === DisplayMode.Full && value.display_name}
            {displayMode === DisplayMode.City && value.name}
            {displayMode === DisplayMode.PostCode && `${value.name}, ${value.postal_code}`}
          </div>
        </div>
      );
    }
    return <div>{value.name}</div>;
  };

  const renderValue = () => {
    if (!value) return <></>;
    return (
      <div className="flex justify-between gap-5 w-full items-center">
        <div>{render(value)}</div>
        {!hideRadius && (
          <div onClick={(ev) => ev.stopPropagation()}>
            <Select
              showArrowIcon={true}
              getKey={(v) => v.toString()}
              options={RadiusOptions}
              value={value.max_radius}
              optionRender={(v) => <div className="px-2"> {`${v / 1000}km`} </div>}
              onChange={(max_radius) => max_radius && onChange({ ...value, max_radius })}
              valueClassName="p-0"
              bordered={false}
              rounded={false}
              size="small"
              type="single"
              style={{ padding: 0 }}
            />
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="w-full">
      <Select
        light
        bordered
        customValueRender={(v) => (customValueRender ? customValueRender(render(v)) : renderValue())}
        rounded
        size={size}
        type="single"
        dropdownClassName="min-w-[250px]"
        options={geocodingResults}
        optionRender={render}
        getKey={(c) => c.location_id.toString()}
        onSearch={setSearch}
        loading={geocodingLoading}
        onChange={onSelect}
        placeholder={"Sélectionner une ville"}
        notFoundPlaceholder="Aucunes ville disponibles"
        value={value}
      />
    </div>
  );
};

export const CityGeocodingSelect: FunctionComponent<Props> = ({ candidateId, style, label, ...props }) => {
  const [selectedLocations, setSelectedLocations] = useState<(PendingCandidateLocation | undefined)[]>();

  const fetchSelected = useCallback(async () => {
    const location = await getCandidateLocations(candidateId);
    setSelectedLocations(location);
  }, [candidateId]);

  useEffect(() => {
    fetchSelected();
  }, [fetchSelected]);

  useEffect(() => {
    if (selectedLocations)
      props.onSelect(
        selectedLocations
          .filter((location) => location !== undefined)
          .map((location) => ({
            max_radius: location?.max_radius,
            // lourd language
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            location_id: location!.location_id,
            candidate_id: candidateId,
          }))
      );
  }, [selectedLocations]);

  const onChange = (idx: number, value: PendingCandidateLocation) => {
    if (selectedLocations?.find((l, i) => i !== idx && l && l.location_id === value.location_id)) {
      toast.error("Vous ne pouvez pas sélectionner deux fois la même ville");
      return;
    }

    const newSelectedLocations = [...(selectedLocations || [])];

    newSelectedLocations.splice(idx, 1, value);

    setSelectedLocations(newSelectedLocations);
  };

  const onRemove = (idx: number) => {
    const newSelectedLocations = [...(selectedLocations || [])];
    newSelectedLocations.splice(idx, 1);

    setSelectedLocations(newSelectedLocations);
  };

  return (
    <span style={style} className="bg-inherit">
      <div className="text-content-light mb-1">{label}</div>
      <div className="bg-inherit flex gap-2 flex-col justify-start">
        {selectedLocations?.map((location, idx) => (
          <div key={location?.location_id} className="w-full flex gap-2 justify-between items-center">
            <CitySelect
              value={location as NominatimLocationResponse & { max_radius: number }}
              onChange={(v) => onChange(idx, { ...v, candidate_id: candidateId })}
            />
            <XMarkIcon
              className="text-content-lighter cursor-pointer h-6 w-6 hover:text-content-light"
              onClick={() => onRemove(idx)}
            />
          </div>
        ))}
        <PlusIcon
          className="h-8 w-8 text-white p-1 rounded-full bg-content-darker cursor-pointer self-center"
          onClick={() => setSelectedLocations([...(selectedLocations || []), undefined])}
        />
      </div>
    </span>
  );
};

export default CityGeocodingSelect;
