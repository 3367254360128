import React, { useEffect, useRef, useState } from "react";

interface Props {
  request: () => Promise<Blob>;
  setLoading: (loading: boolean) => void;
  children: JSX.Element | JSX.Element[];
  fileName: string;
  disabled: boolean;
}

export const Download = ({ request, setLoading, children, fileName, disabled }: Props) => {
  const [fileUrl, setFileUrl] = useState<string>();
  const ref = useRef<HTMLAnchorElement>(null);

  useEffect(() => {
    if (fileUrl) {
      ref.current?.click();
      URL.revokeObjectURL(fileUrl);
      setFileUrl(undefined);
    }
  }, [fileUrl]);

  const download = async () => {
    try {
      setLoading(false);

      const file = await request();
      const url = URL.createObjectURL(new Blob([file], { type: "application/pdf" }));
      setFileUrl(url);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {fileUrl && <a ref={ref} download={fileName} style={{ display: "none" }} href={fileUrl}></a>}
      <div onClick={() => !disabled && download()}>{children}</div>
    </>
  );
};
