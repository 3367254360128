import React, { FunctionComponent, useEffect, useState } from "react";
import { NewSignal } from "../../models/signals";
import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/24/outline";
import store from "../../services/store";
import httpClient from "../../services/http/client";
import ROUTES from "../../services/http/routes";
import qs from "qs";

interface Props {
  signal: NewSignal;
}

export const SignalPreview: FunctionComponent<Props> = ({ signal }) => {
  const [preview, setPreview] = useState<string>();
  const [signalNth, setSignalNth] = useState<number>(0);

  const showPreview = async () => {
    if (store.state.RawJWT) {
      const signalParams = {
        target_id: signal.target_id,
        send_on: signal.send_on,
        filters: signal.filters,
      };

      const params = qs.stringify({ jwt: store.state.RawJWT, ...signalParams }, { encodeValuesOnly: true });
      const preview = `${httpClient.genUrl(ROUTES.GET_SIGNAL_PREVIEW(signalNth))}?${params}`;

      setPreview(preview);
    }
  };

  useEffect(() => {
    signal && showPreview();
  }, [signalNth]);

  return (
    <div>
      <div className="flex justify-center">
        <iframe className="mt-8 w-[630px] h-[900px] overflow-y-auto overflow-hidden" src={preview} />
      </div>
      <div className="flex justify-center mt-4">
        <div className="p-4 w-1/2 flex justify-between items-center bg-background-medium rounded-full">
          <div
            className="flex items-center gap-4 text-content-medium hover:text-content-bright cursor-pointer"
            onClick={() => setSignalNth(signalNth + 1)}
          >
            <ArrowLeftIcon className="w-4 h-4 " />
            <div>Précédent</div>
          </div>
          <div
            className={`flex items-center gap-4 ${
              signalNth === 0 ? "text-content-medium" : "text-content-medium hover:text-content-bright cursor-pointer"
            }`}
            onClick={() => signalNth > 0 && setSignalNth(signalNth - 1)}
          >
            <div>Suivant</div>
            <ArrowRightIcon className="w-4 h-4 " />
          </div>
        </div>
      </div>
    </div>
  );
};
