import React, { useState, useEffect, FunctionComponent } from "react";
import store, { Stored } from "../../services/store";
import qs from "qs";
import { useNavigate } from "react-router";
import googleLogo from "../../assets/googleLogo.svg";
import { googleAuthCb, googleAuth, login } from "../../models/users/users";
import { TextInput, Checkbox, Button } from "@getprorecrutement/getpro-design";
import { LockClosedIcon, UserCircleIcon } from "@heroicons/react/24/outline";

export const LoginPage: FunctionComponent = () => {
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState<{ email?: string; password?: string; remember: boolean }>({ remember: true });
  const redirectUrl =
    process.env.STAGING === "true" ? process.env.STAGING_GOOGLE_REDIRECT_URL : process.env.GOOGLE_REDIRECT_URL;
  const navigate = useNavigate();

  useEffect(() => {
    async function tryOauthLogin(): Promise<void> {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const oauthParams = qs.parse(window.location.search.slice(1)) as any;

      if (!(oauthParams.code && oauthParams.scope && oauthParams.state)) return;

      setLoading(true);
      store.setPersistable(Stored.RefreshToken, true);

      const resp = await googleAuthCb({
        ...oauthParams,
        scopes: oauthParams.scope,
        redirect_url: redirectUrl,
      }).finally(() => setLoading(false));

      store.setCredentials(resp);
      navigate("/");
    }
    tryOauthLogin();
  }, []);

  const onFinish = async (data: { email: string; password: string; remember: boolean }): Promise<void> => {
    setLoading(true);
    store.setPersistable(Stored.RefreshToken, true);
    login(data)
      .then((resp) => store.setCredentials(resp))
      .finally(() => setLoading(false));
  };

  const triggerGoogleOauth = async (): Promise<void> => {
    if (redirectUrl) {
      const { url } = await googleAuth(redirectUrl);
      window.location.href = url;
    }
  };

  return (
    <div className="login">
      <div className="bg-white w-[400px] p-8 shadow-lg rounded">
        <div className="text-2xl text-center font-bold mb-6">Connexion</div>
        <div className="flex flex-col gap-6 w-full">
          <TextInput
            light
            type="email"
            value={values.email}
            placeholder="Adresse Email"
            onChange={(e) => setValues({ ...values, email: e.currentTarget.value })}
            icon={<UserCircleIcon />}
          />
          <TextInput
            light
            type="password"
            value={values.password}
            placeholder="Mot de passe"
            onChange={(e) => setValues({ ...values, password: e.currentTarget.value })}
            icon={<LockClosedIcon />}
          />
          <Checkbox
            light
            label={"Se souvenir de moi"}
            value={values.remember}
            onChange={(v) => setValues({ ...values, remember: v })}
          />
          <div className="flex justify-between items-center">
            <Button
              title="Se connecter"
              kind="solid"
              disabled={!values.email || !values.password}
              onClick={() => onFinish(values as { email: string; password: string; remember: boolean })}
              loading={loading}
            />
            <img src={googleLogo} onClick={triggerGoogleOauth} style={{ width: 32 }} className="cursor-pointer" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
