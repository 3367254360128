import React, { FunctionComponent, useState } from "react";
import { CheckIcon } from "@heroicons/react/outline";
import classNames from "classnames";
import { twMerge } from "tailwind-merge";
import { Light } from "../../../../stories/Tab.stories";

export interface Props {
  value: boolean;
  onChange: (value: boolean) => void;
  label?: string;
  dark?: boolean;
  light?: boolean;
}

export const Switch: FunctionComponent<Props & Omit<React.HTMLAttributes<HTMLDivElement>, "onChange">> = ({
  value,
  onChange,
  label,
  dark,
  light,
  ...props
}) => {
  const wrapperClasses = twMerge(
    classNames("flex w-full gap-2", {
      [`${props.className}`]: !!props.className,
      dark: dark,
    })
  );

  const switchClasses = classNames(
    "w-14 h-7 flex items-center rounded-full p-1 cursor-pointer relative transition-colors border",
    {
      "bg-background-lighter border-border-bright": !value,
      "bg-primary-medium border-primary-medium": value,
      "dark:bg-background-medium dark:border-border-dark": !light && !value,
      "dark:bg-primary-medium dark:border-primary-medium": !light && value,
    }
  );

  const dotClasses = classNames("bg-white h-5 w-5 rounded-full absolute transition-all ease-in-out duration-300 ", {
    "dark:bg-background-darker": !light,
  });

  const labelClasses = classNames("w-max text-content-light transition-colors", {
    "text-content-dark": value,
    "dark:text-content-medium": !light && !value,
    "dark:text-content-bright": !light && value,
  });

  return (
    <div {...props} className={wrapperClasses}>
      <div className={switchClasses} onClick={() => onChange(!value)}>
        <div className={dotClasses} style={{ left: value ? "calc(100% - 24px)" : 4 }} />
      </div>
      {label && <div className={labelClasses}>{label}</div>}
    </div>
  );
};
