import React, { FunctionComponent, useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { QuestionMarkCircleIcon, MinusIcon, PlusIcon } from "@heroicons/react/solid";
import { Tooltip } from "../../../Tooltip";
import { twMerge } from "tailwind-merge";
import { labelClasses, textInputClasses, wrapperInputClasses } from "../../../../utils/common";

const textSize = (elem: HTMLElement, value?: string): number => {
  if (elem && value) {
    const font = window.getComputedStyle(elem).font;
    const span = document.createElement("span");
    document.body.appendChild(span);
    span.style.font = font;
    span.style.position = "absolute";
    span.style.whiteSpace = "no-wrap";
    span.innerHTML = value;
    span.style.height = "auto";
    span.style.width = "auto";
    span.style.padding = "0";
    const width = span.clientWidth;
    document.body.removeChild(span);
    return width;
  }
  return 0;
};

const controlsPxToRemove = (size: "small" | "medium" | "large") =>
  size === "small" ? 36 : size === "medium" ? 52 : 60;

const valueIsNull = (value?: number) => (value === undefined || value === null ? true : false);

export interface Props {
  placeholder: string;
  label?: string;
  size?: "small" | "medium" | "large";
  disabled?: boolean;
  error?: string;
  hint?: string;
  value?: number;
  onChange?: (e?: number) => void;
  handleKeyDown?: (event: any) => void;
  autoFocus?: boolean;
  bordered?: boolean;
  dark?: boolean;
  light?: boolean;
  step?: number;
  min?: number;
  max?: number;
  display?: {
    formatter: (value?: string) => string | undefined;
    parser: (value?: string) => string;
  };
  suffix?: string;
  prefix?: string;
  controls?: boolean;
  precision?: number;
  inputClassName?: string;
}

export const InputNumber: FunctionComponent<Props &
  Omit<React.HTMLAttributes<HTMLDivElement>, "onChange" | "prefix">> = ({
  placeholder,
  label,
  disabled,
  error,
  size = "medium",
  hint,
  value,
  onChange,
  handleKeyDown,
  bordered = true,
  autoFocus,
  dark,
  light,
  defaultValue,
  step = 1,
  min,
  max,
  display,
  suffix,
  prefix,
  controls = true,
  precision = 0,
  inputClassName,
  ...props
}) => {
  const [focused, _setFocused] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const [decimalSave, setDecimalSave] = useState<string>();
  const [inputWidth, setInputWidth] = useState<number>();
  const [pxToRemove, setPxToRemove] = useState<number>(0);
  const [formattedValue, setFormattedValue] = useState<string>();
  const [decimalValue, setDecimalValue] = useState<string>("");

  const setFocused = (val: boolean) => {
    _setFocused(val);
    if (val && inputRef.current) inputRef.current.focus();
  };

  const wrapperClasses = classNames("bg-inherit", {
    dark: dark,
  });

  const groupClasses = twMerge(
    classNames("group relative bg-inherit text-primary-medium flex w-full", {
      [`${props.className}`]: !!props.className,
    })
  );

  const inputWrapperClasses = twMerge(
    classNames(
      `flex relative items-center gap-1.5 ${wrapperInputClasses({
        size,
        disabled,
        light,
        bordered,
        focused,
        error,
      })}`,
      {
        "bg-background-lightest text-content-light placeholder:text-content-light": disabled,
        "dark:bg-background-dark dark:text-content-dark dark:placeholder:text-content-dark": disabled && !light,
        [`${inputClassName}`]: !!inputClassName,
      }
    )
  );

  const baseInputClasses = classNames(textInputClasses({ size, disabled, light, error }), {});

  const inputClasses = classNames(`bg-transparent max-w-full ${baseInputClasses}`, {});

  const suffixPrefixClasses = (type: "suffix" | "prefix") =>
    classNames(`${baseInputClasses} min-w-fit`, {
      "pl-1": type === "suffix",
      "pr-1": type === "prefix",
    });

  // const labelClasses = twMerge(
  //   classNames("absolute z-10 -top-2 text-content-light font-normal bg-inherit transition-colors flex items-center", {
  //     "text-xs left-4 px-1": size === "small",
  //     "text-sm left-5 px-2": size === "medium",
  //     "text-sm left-9 px-2": size === "large",
  //     "dark:text-content-regular": !light,
  //     "text-content-lighter": disabled,
  //     "group-hover:text-content-darker": !disabled && !error,
  //     "dark:group-hover:text-content-bright": !disabled && !error && !light,
  //     "text-error-normal": !disabled && error,
  //     "text-content-darker": focused,
  //     "dark:text-content-bright": focused && !light,
  //   })
  // );

  const hintClasses = classNames("", {
    "w-3": size === "small",
    "w-3.5": size === "medium",
    "w-4": size === "large",
  });

  const errorClasses = classNames("mt-1 italic text-error-normal", {
    "pl-3 text-xs": size === "small",
    "pl-5 text-sm": size === "medium",
    "pl-8": size === "large",
  });

  const controlClasses = twMerge(
    classNames("stroke-2", {
      "dark:text-content-medium": !disabled && !light,
      "cursor-pointer text-content-dark": !disabled,
      "text-content-light": disabled,
      "dark:text-content-dark": disabled && !light,
      "w-3 h-3 min-w-[12px]": size === "small",
      "w-5 h-5 min-w-[20px]": size === "medium",
      "w-6 h-6 min-w-[24px]": size === "large",
    })
  );

  const onInput = (e: React.FormEvent<HTMLInputElement>) => {
    if (onChange) {
      const parsed = display?.parser ? display?.parser(e.currentTarget.value) : e.currentTarget.value;
      const defaultValue = parsed.length > 0 ? 0 : undefined;
      precision > 0 && setDecimalSave(parsed[parsed.length - 1] === "." ? "." : undefined);
      const newValue =
        typeof max !== "undefined" && parseFloat(parsed) > max
          ? max
          : typeof min !== "undefined" && parseFloat(parsed) < min
          ? min
          : precision
          ? parseFloat(parseFloat(parsed).toFixed(precision))
          : parseFloat(parsed);
      onChange(newValue || defaultValue);
    }
  };

  const onControlChange = (nb: number) => {
    if (onChange && !disabled) {
      const newValue = typeof value !== "undefined" ? value + nb : 0;
      const checked =
        typeof max !== "undefined" && newValue > max
          ? max
          : typeof min !== "undefined" && newValue < min
          ? min
          : parseFloat(newValue.toFixed(precision));
      onChange(checked);
    }
  };

  useEffect(() => {
    const formatted = valueIsNull(value)
      ? ""
      : display
      ? display.formatter(Math.trunc(value!).toString())
      : Math.trunc(value!).toString();

    const decimal =
      !valueIsNull(value) && value!.toString().includes(".")
        ? "." + value!.toString().split(".")[1]
        : decimalSave || "";

    setFormattedValue(formatted);
    setDecimalValue(decimal);

    if (inputRef.current && !valueIsNull(value)) setInputWidth(textSize(inputRef.current, formatted + decimal));
    else setInputWidth(undefined);
  }, [inputRef.current, value, decimalSave]);

  useEffect(() => {
    if (inputRef.current)
      setPxToRemove(
        (suffix ? textSize(inputRef.current, suffix) + 4 : 0) + (prefix ? textSize(inputRef.current, prefix) + 4 : 0)
      );
  }, [inputRef.current, suffix, prefix]);

  return (
    <div className={wrapperClasses}>
      <div {...props} className={groupClasses}>
        <div className="flex relative bg-inherit w-full">
          {label && (
            <label className={labelClasses({ size, disabled, light, focused, error })}>
              {label}{" "}
              {hint && (
                <div className="ml-1">
                  <Tooltip title={hint}>
                    <QuestionMarkCircleIcon className={hintClasses} />
                  </Tooltip>
                </div>
              )}
            </label>
          )}
          <div className={inputWrapperClasses} onClick={() => setFocused(true)}>
            {controls && <MinusIcon onClick={() => onControlChange(-step)} className={controlClasses} />}
            <div
              className="flex-1 flex items-center relative"
              style={{
                maxWidth: !valueIsNull(value) && controls ? `calc(100% - ${controlsPxToRemove(size)}px)` : "100%",
              }}
            >
              {prefix && !valueIsNull(value) && <div className={suffixPrefixClasses("prefix")}>{prefix}</div>}
              <input
                ref={inputRef}
                autoFocus={autoFocus}
                onFocus={() => setFocused(true)}
                onBlur={() => setFocused(false)}
                onInput={onInput}
                value={formattedValue + decimalValue}
                placeholder={placeholder}
                className={inputClasses}
                type="text"
                disabled={disabled}
                onKeyDown={handleKeyDown}
                style={{
                  // pxToRemove for suffix & prefix width
                  maxWidth: !valueIsNull(value) ? `calc(100% - ${pxToRemove}px)` : undefined,
                  width: inputWidth || "100%",
                }}
              />
              {suffix && !valueIsNull(value) && <div className={suffixPrefixClasses("suffix")}>{suffix}</div>}
            </div>
            {controls && <PlusIcon onClick={() => onControlChange(step)} className={controlClasses} />}
          </div>
        </div>
      </div>
      {error && <div className={errorClasses}>{error}</div>}
    </div>
  );
};
