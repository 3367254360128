import React, { FunctionComponent } from "react";
import { CandidatePicture } from "../../../components/candidateProfile/picture";
import { EnvelopeIcon, PhoneIcon } from "@heroicons/react/24/outline";
import { Tooltip } from "@getprorecrutement/getpro-design";
import { copyToClipboard } from "../../../utils";
import LinkedinIcon from "../../../utils/icons";
import { Candidate, SearchCandidateResponse } from "../../../models/candidate";

interface Props {
  candidate: Candidate | SearchCandidateResponse;
  onClick?: () => void;
  terminatedJa?: boolean;
}

const contactIcon = (children: JSX.Element, notification: string, contact: string) => {
  return (
    <Tooltip light title={contact} className={"cursor-pointer"} onClick={() => copyToClipboard(contact, notification)}>
      <div className="p-1 rounded bg-primary-medium/10 text-primary-medium font-bold">{children}</div>
    </Tooltip>
  );
};

export const CandidateInformations: FunctionComponent<Props> = ({ candidate, onClick, terminatedJa }) => {
  return (
    <div className="flex gap-3 items-center cursor-pointer" onClick={onClick}>
      <CandidatePicture candidate={candidate} imageClasses="w-12 h-12 min-w-[48px] min-h-[48px]" />
      <div className="flex-1 max-w-[calc(100%-56px)]">
        <div className={`font-bold truncate max-w-[95%] w-fit ${terminatedJa ? "text-content-light" : ""}`}>
          {candidate.full_name || "Candidat anonyme"}
        </div>
        <div className="flex gap-2 items-center mt-1 w-fit" onClick={(e) => e.stopPropagation()}>
          {candidate.phone &&
            contactIcon(
              <PhoneIcon width={12} height={12} className={"stroke-2"} />,
              "Le numéro de téléphone a été copié dans votre presse-papier",
              candidate.phone
            )}
          {candidate.email &&
            contactIcon(
              <EnvelopeIcon width={12} height={12} className={"stroke-2"} />,
              "L'adresse e-mail a été copié dans votre presse-papier",
              candidate.email
            )}
          {candidate.linkedin_url && (
            <a
              onClick={(e) => e.stopPropagation()}
              href={candidate.linkedin_url}
              target="_blank"
              rel="noreferrer"
              className="p-1 rounded bg-primary-medium/10 text-primary-medium font-bold"
            >
              <LinkedinIcon className="w-3 h-3" fill={"#7942ed"} />
            </a>
          )}
        </div>
      </div>
    </div>
  );
};
