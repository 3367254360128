import React, { useState } from "react";
import { FunctionComponent } from "react";
import { GlobalFilters, DashboardModule, getStatsRetainer } from "../../../../models/dashboards";
import RetainersTimeline, { TimelineData } from "./timeline";
import ModuleTitle from "../../elements/title";
import RetainersPerUser from "./retainersPerUser";
import { useDeepCompareEffect } from "react-use";
import dayjs from "dayjs";
import DashboardRetainersList from "./retainers";
import { UUID } from "../../../../models/common";
import { BillStatus } from "../../../../models/billing/bill";
import { Divider, Checkbox, Radio } from "@getprorecrutement/getpro-design";
import { ArrowLongRightIcon } from "@heroicons/react/24/outline";

export interface RetainerData {
  timeline?: TimelineData[];
  per_user?: PerUserData[];
  retainers: FullRetainerData[];
}

export interface RetainerResponse {
  timeline?: RetainersTimelineData[];
  per_user?: RetainersPerUserData[];
  retainers: FullRetainerData[];
}

export interface FullRetainerData {
  id: UUID;
  amount: number;
  owner_name: string;
  job_offer_id?: UUID;
  job_offer_name?: string;
  customer_id: UUID;
  customer_name: string;
  paid_at: string;
  status: BillStatus;
}

export interface RetainersTimelineData {
  paid_at: string;
  status?: BillStatus;
  amount?: number;
}

export interface RetainersPerUserData {
  name: string;
  status: BillStatus;
  amount?: number;
}

export type PerUserData = {
  name: string;
  total: number;
};

type ModuleFilters = {
  granularity?: "month" | "quarter" | "year";
  compare?: boolean;
  timeline?: "timeline" | "users";
  retainers?: boolean;
};

interface Props {
  filters: GlobalFilters;
  locked: boolean;
  dashboardSlug: string;
  mod: DashboardModule<ModuleFilters>;
  updateModule: (data: {
    dashboard_slug: string;
    module_key: string;
    filters: ModuleFilters;
    title?: string;
  }) => Promise<void>;
}

const getTimelineData = (timeline: RetainersTimelineData[]): TimelineData[] => {
  const res = timeline.reduce(
    (
      res: {
        [date: string]: { total: number };
      },
      data
    ) => {
      res[data.paid_at] ||= { total: 0 };
      res[data.paid_at].total += data.amount || 0;
      return res;
    },
    {}
  );

  return Object.keys(res).map((d) => ({ paid_at: d, ...res[d] } as TimelineData));
};

const getUsersData = (data: RetainersPerUserData[]): PerUserData[] => {
  const res = data.reduce(
    (
      res: {
        [date: string]: { total: number };
      },
      data
    ) => {
      res[data.name] ||= { total: 0 };
      res[data.name].total += data.amount || 0;
      return res;
    },
    {}
  );

  return Object.keys(res).map((d) => ({ name: d, ...res[d] } as PerUserData));
};

export const getCustomTooltipDate = (date: string, granularity: "month" | "quarter" | "year") => {
  return (
    <div className="DashboardCustomTooltipDate">
      {granularity === "month" ? (
        dayjs(date).format("MMMM YYYY")
      ) : granularity === "quarter" ? (
        <div className="flex items-center justify-between gap-2">
          <div>{dayjs(date).format("MMM YY")}</div>
          <ArrowLongRightIcon className="w-4 h-4" />
          <div>{dayjs(date).endOf("quarter").format("MMM YY")}</div>
        </div>
      ) : (
        dayjs(date).format("YYYY")
      )}
    </div>
  );
};

export const RetainerChart: FunctionComponent<Props> = (props) => {
  const { filters, locked, dashboardSlug, mod, updateModule } = props;
  const [retainersData, setRetainerData] = useState<RetainerData>();
  const [chartTypeSelected, _setTimelineSelected] = useState<"timeline" | "users">(mod.filters.timeline || "timeline");

  const setShowRetainers = async (show: boolean) => {
    await updateModule({
      dashboard_slug: dashboardSlug,
      module_key: mod.key,
      filters: {
        retainers: show,
      },
    });
  };

  const setGranularity = async (value: "month" | "quarter" | "year") => {
    await updateModule({
      dashboard_slug: dashboardSlug,
      module_key: mod.key,
      filters: {
        granularity: value,
      },
    });
  };

  const setTimelineSelect = async (value: "timeline" | "users") => {
    await updateModule({
      dashboard_slug: dashboardSlug,
      module_key: mod.key,
      filters: {
        timeline: value,
      },
    });
  };

  const setTimelineSelected = (value: "timeline" | "users") => {
    _setTimelineSelected(value);
    setTimelineSelect(value);
  };

  const fetchRetainerData = async () => {
    const response = await getStatsRetainer({
      ...filters.dateRange,
      granularity: mod.filters.granularity,
      users: filters.users,
      business_units: filters.businessUnits,
      job_offers: filters.jobOffers,
      customers: filters.customers,
      chart_type: chartTypeSelected,
      retainers: mod.filters.retainers,
    });

    setRetainerData({
      ...response,
      per_user: response.per_user && getUsersData(response.per_user),
      timeline: response.timeline && getTimelineData(response.timeline),
    });
  };

  useDeepCompareEffect(() => {
    fetchRetainerData();
  }, [filters, mod.filters]);

  return (
    <div className="bg-white p-6 shadow-md mb-6">
      {!locked && (
        <div>
          <div className="flex items-center gap-6">
            <Radio
              light
              optionType="button"
              options={[
                { label: "Timeline", value: "timeline" },
                { label: "Par utilisateurs", value: "users" },
              ]}
              value={chartTypeSelected}
              onChange={(val) => setTimelineSelected(val as "timeline" | "users")}
            />
          </div>
          <div className="mt-2">
            <Checkbox
              label="Afficher les acomptes"
              light
              value={mod.filters.retainers || false}
              onChange={(val) => setShowRetainers(val)}
            />
          </div>
          <Divider light />
        </div>
      )}
      <ModuleTitle
        title={mod.title}
        slug={dashboardSlug}
        modKey={mod.key}
        updateModule={updateModule}
        locked={locked}
      />
      <div className="w-full">
        {mod.filters.retainers ? (
          <DashboardRetainersList retainers={retainersData?.retainers || []} />
        ) : chartTypeSelected === "timeline" ? (
          retainersData?.timeline && (
            <RetainersTimeline
              timeline={retainersData?.timeline}
              setGranularity={setGranularity}
              granularity={mod.filters.granularity || "month"}
            />
          )
        ) : (
          chartTypeSelected === "users" &&
          retainersData?.per_user && <RetainersPerUser retainers={retainersData?.per_user} />
        )}
      </div>
    </div>
  );
};

export default RetainerChart;
