import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import { SignalCategoryEdit } from "./companySignals";
import { PlusIcon, TrashIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { Select } from "@getprorecrutement/getpro-design";
import { CategorySelect } from "../../components/forms/inputs/jobTitleCategorySelect/categorySelect";
import { Category } from "../../models/jobTitleCategory";
import { Skill, fetchSkills } from "../../models/skill";
import { useDebounce } from "../../utils";

export interface SignalFiltersSelectProps {
  onChange: (categories: SignalCategoryEdit[]) => void;
  selectedCategories: SignalCategoryEdit[];
  categories: Category[];
}

export interface SignalFilterRowProps {
  filters: Partial<SignalCategoryEdit>;
  skills: Skill[];
  search: string;
  setSearch: (search: string) => void;
  removeLine: () => void;
  categories: Category[];
  onChange: (filers: Partial<SignalCategoryEdit>) => void;
}

export const SignalFilterRow: FunctionComponent<SignalFilterRowProps> = ({
  filters,
  onChange,
  setSearch,
  removeLine,
  search,
  categories,
  skills,
}) => {
  const onSkillChange = (skill?: Skill) => {
    if (!skill) return;
    if (filters.skills?.map((s) => s.id).includes(skill.id)) {
      onChange({ ...filters, skills: filters.skills.filter((s) => s.id !== skill.id) });
    } else {
      onChange({ ...filters, skills: [...(filters.skills || []), skill] });
    }
  };

  const options = useMemo(() => {
    const selectedIds = filters.skills?.map((s) => s.id) || [];
    return (filters.skills?.filter((f) => f.name.toLowerCase().includes(search.toLowerCase())) || []).concat(
      skills.filter((s) => !selectedIds.includes(s.id))
    );
  }, [filters, skills]);

  const customValueRender = (skills: Skill[]) => (
    <div className="flex items-center gap-2 text-xs">
      {skills.length > 0 && (
        <div className="bg-primary-bright text-primary-regular px-3 py-1 items-center rounded-full flex gap-2 font-bold">
          <div>{skills[0].name}</div>

          <XMarkIcon height={12} width={12} className="cursor-pointer" onClick={() => onSkillChange(skills[0])} />
        </div>
      )}
      {skills.length > 1 && (
        <div className="bg-primary-bright text-primary-regular px-3 py-1 items-center rounded-full flex gap-2 font-bold">
          <div>+ {skills.length - 1}</div>
        </div>
      )}
    </div>
  );

  return (
    <div className="w-full">
      <div className="flex justify-between items-center gap-2 group relative overflow-visible z-50">
        <div
          className="absolute left-0 -translate-x-1/2 rounded-full bg-red-400 hidden cursor-pointer group-hover:block p-1"
          onClick={removeLine}
        >
          <TrashIcon className="text-white" height={16} width={16} />
        </div>
        <div className="w-1/2">
          <CategorySelect
            showXIcon={false}
            bordered={false}
            categories={categories}
            value={filters.category}
            placeholder="Catégorie"
            onSelect={(category) => onChange({ ...filters, category })}
          />
        </div>
        <div className="w-1/2">
          <Select
            placeholder="Compétences"
            showArrowIcon={true}
            customValueRender={customValueRender}
            value={filters.skills}
            options={options}
            onChange={onSkillChange}
            getKey={(s) => s.id}
            onSearch={setSearch}
            optionRender={(s) => s.name}
            type="multiple"
          />
        </div>
      </div>
      <div className="w-full px-7">
        <div className="w-full border-b border-border-bright"></div>
      </div>
    </div>
  );
};

export const SignalFiltersSelect: FunctionComponent<SignalFiltersSelectProps> = ({
  selectedCategories,
  onChange,
  categories,
}) => {
  const [lines, setLines] = useState<Partial<SignalCategoryEdit>[]>(
    selectedCategories && selectedCategories.length > 0 ? selectedCategories : [{}]
  );
  const [skills, setSkills] = useState<Skill[]>([]);

  const [input, setInput] = useState<string>("");
  const search = useDebounce(input, 300);

  useEffect(() => {
    const categories = lines.reduce((acc, next) => {
      if (next.category) {
        acc.push({
          category: next.category,
          skills: next.skills || [],
        });
      }
      return acc;
    }, [] as SignalCategoryEdit[]);
    onChange(categories);
  }, [lines]);

  useEffect(() => {
    if (search && search.length > 0) {
      fetchSkills({ name: search }).then(setSkills);
    } else {
      setSkills([]);
    }
  }, [search]);

  const updateLine = (value: Partial<SignalCategoryEdit>, idx: number) => {
    const l = [...lines];
    l[idx] = value;
    setLines(l);
  };

  const removeLine = (idx: number) => {
    const l = [...lines];
    console.log(l);
    console.log(l.splice(idx, 1));
    setLines(l);
  };

  return (
    <div className="rounded-3xl border border-border-bright py-3">
      <div className="flex items-center italic gap-2">
        <div className="w-1/2 px-7">Catégories</div>
        <div className="w-1/2 px-7">Compétences</div>
      </div>
      <div className="w-full flex flex-col gap-2">
        {lines.map((line, idx) => (
          <SignalFilterRow
            removeLine={() => removeLine(idx)}
            search={search || ""}
            setSearch={setInput}
            skills={skills}
            key={`${line.category?.id}-${line.skills?.map((s) => s.id)}-${idx}`}
            filters={line}
            categories={categories}
            onChange={(v) => updateLine(v, idx)}
          />
        ))}
      </div>

      <div className="flex justify-center items-end mt-3">
        <div className="p-1.5 rounded-full bg-primary-regular">
          <PlusIcon
            className="text-white cursor-pointer"
            height={20}
            onClick={() => setLines([...lines, {}])}
            width={20}
          />
        </div>
      </div>
    </div>
  );
};
