import classNames from "classnames";
import { FunctionComponent } from "react";
import { twMerge } from "tailwind-merge";
import React from "react";

export const nextDirection = (
  currentSort: "asc" | "desc" | undefined,
  sortable: true | "asc" | "desc"
): "asc" | "desc" | undefined => {
  switch (currentSort) {
    case "desc":
      return sortable == "desc" ? undefined : "asc";
    case "asc":
      return undefined;
    case undefined:
      return sortable === true ? "desc" : sortable;
  }
};

export const SortableBtn: FunctionComponent<{
  sortable: true | "asc" | "desc";
  onDirectionChange: (direction?: "asc" | "desc") => void;
  currentSort: "asc" | "desc" | undefined;
  light?: boolean;
}> = ({ sortable, onDirectionChange, currentSort, light }) => {
  const topIconClasses = twMerge(
    classNames("stroke-content-light stroke-2", {
      "stroke-content-medium": currentSort === "asc",
      "dark:stroke-content-medium": !light,
      "dark:stroke-content-lighter": currentSort === "asc" && !light,
    })
  );

  const bottomIconClasses = twMerge(
    classNames("stroke-content-light stroke-2", {
      "stroke-content-medium": currentSort === "desc",
      "dark:stroke-content-medium": !light,
      "dark:stroke-content-lighter": currentSort === "desc" && !light,
    })
  );

  const updateDirection = (ev: React.MouseEvent) => {
    ev.stopPropagation();
    onDirectionChange(nextDirection(currentSort, sortable));
  };

  return (
    <div
      className="flex flex-col justify-center items-center cursor-pointer gap-1 -mx-1 px-2"
      onClick={updateDirection}
    >
      {/* <svg className={topIconClasses} width="6" height="6" viewBox="0 0 5 5" xmlns="http://www.w3.org/2000/svg">
        <path d="M3.36602 4.24512C2.98112 4.91178 2.01887 4.91178 1.63397 4.24512L0.334936 1.99512C-0.0499639 1.32845 0.431162 0.495117 1.20096 0.495117L3.79904 0.495117C4.56884 0.495118 5.04996 1.32845 4.66506 1.99512L3.36602 4.24512Z" />
      </svg> */}

      <svg
        className={topIconClasses}
        width="8"
        height="4"
        viewBox="0 0 7 4"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M1 3.33333L3.5 1L6 3.33333" strokeLinecap="round" strokeLinejoin="round" />
      </svg>

      {/* <svg className={bottomIconClasses} width="6" height="6" viewBox="0 0 5 5" xmlns="http://www.w3.org/2000/svg">
        <path d="M3.36602 4.24512C2.98112 4.91178 2.01887 4.91178 1.63397 4.24512L0.334936 1.99512C-0.0499639 1.32845 0.431162 0.495117 1.20096 0.495117L3.79904 0.495117C4.56884 0.495118 5.04996 1.32845 4.66506 1.99512L3.36602 4.24512Z" />
      </svg> */}

      <svg
        className={bottomIconClasses}
        width="8"
        height="4"
        viewBox="0 0 7 4"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M6 0.833252L3.5 3.16658L1 0.833252" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    </div>
  );
};
