import React, { forwardRef, useImperativeHandle } from "react";
import { JobOffer, JobOfferData, NewJobOffer } from "../../../models/jobOffers";
import { UUID } from "../../../models/common";
import { useState, useEffect } from "react";
import { Category, CategoryType, getCategories, getCategoryTypes } from "../../../models/categories";
import { Project, ProjectData } from "../../../models/billing/project";
import { Checkbox, InputNumber, Radio, Select, TextInput } from "@getprorecrutement/getpro-design";
import GeocodingSelect from "../inputs/geocodingSelect";
import UserSelect from "../inputs/userSelect";
import CustomerSelect from "../inputs/customerSelect";
import { useDebounce } from "../../../utils";
import { Skill, fetchSkills, getSuggestedSkills } from "../../../models/skill";
import { CategoriesSelect } from "../inputs/categoriesSelect";
import { useFormManager } from "../../../services/form";
import { StepHandle } from ".";
import { JobTitleSelect } from "../inputs/JobTitleSelect";

interface Props {
  jobOffer: Partial<JobOffer>;
  isValid?: () => boolean;
  project: Partial<Project>;
  setDisabled: (value: boolean) => void;
  data:
    | NewJobOffer
    | {
        project?: ProjectData;
        job_offer?: JobOfferData;
      };
}

// TODO USE VALIDATE CATEGORIES
const validateCategories = (value: string[], cTypes: CategoryType[], categories: Category[]) => {
  const selectedCategories = categories.filter((c) => value.includes(c.name));
  const selectedCTypes = cTypes.filter((ct) => selectedCategories.map((ct) => ct.category_type_id).includes(ct.id));
  const mandatoryCTypes = cTypes.filter(
    (ct) => (!ct.parent_id || selectedCategories.map((ct) => ct.id).includes(ct.parent_id)) && ct.kind !== "salary"
  );

  return mandatoryCTypes.filter((ct) => !selectedCTypes.map((mct) => mct.id).includes(ct.id)).length === 0;
};

const optionsValidationPeriod = [
  { label: "Aucune", value: "0" },
  { label: "3 mois", value: "3" },
  { label: "4 mois", value: "4" },
  { label: "6 mois", value: "6" },
];

export const BasicStepForm = forwardRef<StepHandle, Props>(function BasicStepForm(props, ref) {
  const [categories, setCategories] = useState<Category[]>([]);
  const [categoryTypes, setCategoryTypes] = useState<CategoryType[]>([]);
  const [categoryId, setCategoryId] = useState<UUID>();
  const [selectedSkills, setSelectedSkills] = useState<Skill[]>();
  const [suggestedSkills, setSuggestedSkills] = useState<Skill[]>();
  const [skillInput, setSkillInput] = useState<string>("");
  const skillSearch = useDebounce(skillInput, 500);
  const [skills, setSkills] = useState<Skill[]>();
  const form = useFormManager<JobOffer>({
    defaultState: props.data.job_offer || {},
    validations: {
      name: {
        required: true,
        message: "Merci de préciser le nom de l'offre",
      },
      owner_id: {
        required: true,
        message: "Merci de préciser le propriétaire de l'offre",
      },
      company_id: {
        required: true,
        message: "Merci de préciser le client de l'offre",
      },
      location: {
        required: true,
        message: "Merci de préciser une localisation",
      },
      job_title_id: {
        required: true,
        message: "Merci de préciser le titre de poste de l'offre",
      },
      categories: {
        required: (selectedCategories) =>
          !!selectedCategories && validateCategories(selectedCategories, categoryTypes, categories),
        message: "Merci de sélectionner au minimum une catégorie de chaque type",
      },
      validation_period_months: {
        required: true,
        message: "Merci de préciser une période de validation",
      },
    },
  });

  useImperativeHandle(
    ref,
    () => ({
      isValid() {
        return form.validate();
      },

      getData() {
        return {
          ...props.data,
          job_offer: { ...props.data.job_offer, ...form.state },
        };
      },
    }),
    [form.state]
  );

  useEffect(() => {
    props.setDisabled(form.disabled);
  }, [form.disabled]);

  useEffect(() => {
    if (props.jobOffer.full_skills) setSelectedSkills(props.jobOffer.full_skills);
    if (props.jobOffer.job_title) setCategoryId(props.jobOffer.job_title.category_id);
  }, [props.jobOffer]);

  useEffect(() => {
    if (categoryId) {
      getSuggestedSkills(categoryId, selectedSkills?.map((s) => s.id) || []).then(setSuggestedSkills);
    }
  }, [categoryId, selectedSkills]);

  useEffect(() => {
    if (skillSearch && skillSearch.length > 0) {
      fetchSkills({ name: skillSearch }).then(setSkills);
    } else {
      setSkills(undefined);
    }
  }, [skillSearch]);

  useEffect(() => {
    if (selectedSkills) {
      form.onChange({ skills: selectedSkills.map((s) => s.id) });
    }
  }, [selectedSkills]);

  const getDatas = async () => {
    const categories = await getCategories();
    const categoryTypes = await getCategoryTypes();
    setCategories(categories);
    setCategoryTypes(categoryTypes);
  };

  useEffect(() => {
    getDatas();
  }, []);

  return (
    <div className="flex flex-col gap-6 bg-inherit">
      <TextInput
        type="text"
        value={form.state.name}
        error={form.errors.name}
        onChange={(e) => form.onChange({ name: e.target.value })}
        light
        placeholder="Nom de l'offre"
        label="Nom de l'offre"
      />
      <GeocodingSelect
        error={form.errors.location}
        label="Localisation"
        value={form.state.location}
        onSelect={(v) => form.onChange({ location: v })}
      />
      <UserSelect
        label={"Propriétaire"}
        placeholder="Sélectionner le propriétaire de l'offre"
        value={form.state.owner_id}
        error={form.errors.owner_id}
        showSearch
        onSelect={(id: UUID) => form.onChange({ owner_id: id })}
      />
      <CustomerSelect
        label={"Entreprise"}
        value={form.state.company_id}
        error={form.errors.company_id}
        showSearch
        onSelect={(id: UUID) => form.onChange({ company_id: id })}
      />
      <div className="flex items-center gap-2">
        <div className="flex-1">
          <Checkbox
            value={!!form.state.anonymous_company}
            onChange={(e) => form.onChange({ anonymous_company: e })}
            light
            label="Entreprise anonyme"
          />
        </div>
        <div className="flex-1">
          <Checkbox
            light
            value={!!form.state.new_customer}
            onChange={(e) => form.onChange({ new_customer: e })}
            label="Nouveau client"
          />
        </div>
      </div>
      <div className="bg-inherit">
        <JobTitleSelect
          label="Titre de poste"
          error={form.errors.job_title_id}
          currentJobTitle={form.state.job_title}
          onChange={(jobTitle, categoryId) => {
            setCategoryId(categoryId);
            form.onChange({ job_title_id: jobTitle?.id, job_title: jobTitle });
          }}
        />
      </div>
      <div className="bg-inherit w-full">
        <Select
          rounded
          bordered
          onSearch={setSkillInput}
          label="Compétences"
          placeholder="Sélectionnez des compétences"
          disabled={suggestedSkills?.length === 0 && !selectedSkills}
          options={(selectedSkills || []).concat(
            skills?.filter((skill) => !selectedSkills?.find((selected) => selected.id === skill.id)) || []
          )}
          value={selectedSkills || []}
          customValueRender={(values: Skill[]) => (
            <div className="flex flex-wrap gap-2 items-center">
              {values.map((s) => (
                <div
                  className="border border-solid border-border-lighter text-content-darker rounded-full px-2 py-[1px] text-xs"
                  key={s.id}
                >
                  {s.name}
                </div>
              ))}
            </div>
          )}
          onChange={(skill) => {
            setSkillInput("");
            if (skill) {
              if (selectedSkills?.find((s) => s.id === skill.id)) {
                setSelectedSkills(selectedSkills?.filter((s) => s.id !== skill.id));
              } else {
                setSelectedSkills([...(selectedSkills || []), skill]);
              }
            }
          }}
          getKey={(s) => s.id}
          optionRender={(s) => s.name}
          type="multiple"
        />
        {suggestedSkills && suggestedSkills.length > 0 && (
          <div className="flex flex-wrap items-center gap-2 mt-2">
            <div className="text-sm text-content-light font-medium">Suggestions :</div>
            {suggestedSkills.map((s) => {
              return (
                <div
                  key={s.id}
                  className="cursor-pointer rounded-md border px-2 py-1 items-center bg-primary-bright text-primary-medium text-xs flex gap-1"
                  onClick={() => setSelectedSkills([...(selectedSkills || []), s])}
                >
                  {s.name}
                </div>
              );
            })}
          </div>
        )}
      </div>

      <CategoriesSelect
        onSelect={(id) => {
          const cat = categories.find((c) => c.id === id);
          if (cat) {
            const selectedCategories = form.state.categories || [];
            const new_categories = selectedCategories.includes(cat.name)
              ? selectedCategories.filter((name) => name !== cat.name)
              : [...selectedCategories, cat.name];
            form.onChange({ categories: new_categories });
          }
        }}
        value={categories.filter((c) => (form.state.categories || []).includes(c.name)).map((c) => c.id)}
      />
      {form.errors.categories && <div className="text-error-normal italic">{form.errors.categories}</div>}
      <InputNumber
        value={form.state.expected_salary}
        onChange={(e) => form.onChange({ expected_salary: e })}
        display={{
          formatter: (value) => value && value.replace(/\B(?=(\d{3})+(?!\d))/g, " "),
          parser: (value) => (value ? value.replace(/[\s€]*/g, "") : ""),
        }}
        min={0}
        max={10000000}
        light
        placeholder="Salaire prévu"
        label="Salaire prévu"
        suffix="€"
        precision={2}
        controls={false}
      />
      <div className="flex gap-4 items-center">
        <div>Période de garantie :</div>
        <div>
          <Radio
            light
            optionType="button"
            options={optionsValidationPeriod}
            value={form.state.validation_period_months?.toString()}
            onChange={(v) => form.onChange({ validation_period_months: v ? parseInt(v) : undefined })}
          />
          {form.errors.validation_period_months && (
            <div className="text-error-normal italic">{form.errors.validation_period_months}</div>
          )}
        </div>
      </div>
    </div>
  );
});

export default BasicStepForm;
