import React, { useEffect, useState } from "react";
import { FunctionComponent } from "react";
import { UUID } from "../../../../models/common";
import { GlobalFilters, DashboardModule, getStatsSourcing } from "../../../../models/dashboards";
import { getSources } from "../../../../services/requests";
import { getComparePeriod } from "../../../../utils/dates";
import ModuleTitle from "../../elements/title";
import { useDeepCompareEffect } from "react-use";
import { Divider, Checkbox } from "@getprorecrutement/getpro-design";
import DashboardTimeline, { TimelineData } from "../../elements/timeline";
import { PALETTE } from "../..";
import DashboardStats from "../../elements/stats";

interface Props {
  filters: GlobalFilters;
  locked: boolean;
  dashboardSlug: string;
  mod: DashboardModule<{
    sources_ids?: UUID[];
    granularity?: "day" | "week" | "month";
    compare?: boolean;
  }>;
  updateModule: (data: {
    dashboard_slug: string;
    module_key: string;
    filters: {
      sources_ids?: UUID[];
      granularity?: "day" | "week" | "month";
      compare?: boolean;
    };
    title?: string;
  }) => Promise<void>;
}

export const SourcingChart: FunctionComponent<Props> = (props) => {
  const { filters, locked, dashboardSlug, mod, updateModule } = props;
  const [sourcingData, setSourcingData] = useState<{
    summary: { [name: string]: number };
    timeline: TimelineData[];
    comparison?: { [name: string]: number };
  }>();
  const [sources, setSources] = useState<{ id: UUID; name: string }[]>([]);

  const setShowComparison = async (show: boolean) => {
    await updateModule({
      dashboard_slug: dashboardSlug,
      module_key: mod.key,
      filters: {
        compare: show,
      },
    });
  };

  const setGranularity = async (value: "day" | "week" | "month") => {
    await updateModule({
      dashboard_slug: dashboardSlug,
      module_key: mod.key,
      filters: {
        granularity: value,
      },
    });
  };

  const getData = (
    timeline: { created_at: string; count: number; source_id: UUID }[],
    sources: { id: UUID; name: string }[]
  ): TimelineData[] => {
    const keyedSources: { [id: string]: string } = sources.reduce((res, s) => ({ [s.id]: s.name, ...res }), {});

    const res = timeline.reduce(
      (
        res: {
          [date: string]: { [name: string]: number; total: number };
        },
        data
      ) => {
        res[data.created_at] ||= { total: 0 };
        res[data.created_at][keyedSources[data.source_id]] = data.count;
        res[data.created_at].total += data.count;
        return res;
      },
      {}
    );

    return Object.keys(res).map((d) => ({ date: d, ...res[d] } as TimelineData));
  };

  const fetchSourcingData = async () => {
    const dateRangeCompare = mod.filters.compare ? filters.period && getComparePeriod(filters.period) : undefined;

    if (sources.length > 0) {
      const response = await getStatsSourcing({
        ...filters.dateRange,
        ...dateRangeCompare,
        granularity: mod.filters.granularity,
        users: filters.users,
        business_units: filters.businessUnits,
        job_offers: filters.jobOffers,
        customers: filters.customers,
        sources_ids: mod.filters.sources_ids || [],
      });
      const res = {
        ...response,
        summary: response.summary.reduce((o, data) => ({ ...o, [data[0]]: data[1] }), {}),
        comparison: response.comparison
          ? response.comparison.reduce((o, data) => ({ ...o, [data[0]]: data[1] }), {})
          : undefined,
      };
      setSourcingData({
        ...res,
        timeline: getData(response.timeline, sources),
      });
    }
  };

  const setSelectedSourceIds = async (filter: string) => {
    const sources_ids = !(mod.filters.sources_ids || []).includes(filter)
      ? [...(mod.filters.sources_ids || []), filter]
      : (mod.filters.sources_ids || []).filter((f) => f !== filter);

    await updateModule({
      dashboard_slug: dashboardSlug,
      module_key: mod.key,
      filters: {
        sources_ids,
      },
    });
  };

  const fetchSources = async () => {
    const sources = await getSources();
    setSources(sources);
  };

  useEffect(() => {
    fetchSources();
  }, []);

  useDeepCompareEffect(() => {
    fetchSourcingData();
  }, [filters, mod.filters, sources]);

  return (
    <div className="bg-white p-6 shadow-md mb-6">
      {!locked && (
        <div>
          <div className="flex items-center gap-2">
            <div className="flex-1 flex gap-2 items-center flex-wrap">
              {sources.map((source) => (
                <Checkbox
                  key={source.id}
                  light
                  label={source.name}
                  value={(mod.filters.sources_ids || []).includes(source.id)}
                  onChange={() => setSelectedSourceIds(source.id)}
                />
              ))}
            </div>
            <Divider type="vertical" style={{ height: 20 }} light />
            <div className="w-[35%]">
              <Checkbox
                label="Comparer avec la période précédente"
                light
                value={mod.filters.compare || false}
                onChange={setShowComparison}
              />
            </div>
          </div>
          <Divider light />
        </div>
      )}
      <ModuleTitle
        title={mod.title}
        slug={dashboardSlug}
        modKey={mod.key}
        updateModule={updateModule}
        locked={locked}
      />
      <div className="flex items-center justify-between gap-2">
        <div className="w-[65%]">
          <DashboardTimeline
            timeline={sourcingData?.timeline}
            setGranularity={setGranularity}
            granularity={mod.filters.granularity || "week"}
            statuses={sources}
            selectedIds={mod.filters.sources_ids || []}
            colors={PALETTE.RED}
          />
        </div>
        <Divider type="vertical" style={{ height: 200 }} light />
        <div className="w-[35%]">
          <DashboardStats
            summary={sourcingData?.summary || {}}
            comparison={mod.filters.compare ? sourcingData?.comparison : undefined}
            statuses={sources}
            selectedIds={mod.filters.sources_ids || []}
            colors={PALETTE.RED}
          />
        </div>
      </div>
    </div>
  );
};

export default SourcingChart;
