import { CheckIcon } from "@heroicons/react/outline";
import classNames from "classnames";
import React, { FunctionComponent, useEffect, useRef, useState } from "react";
import { twMerge } from "tailwind-merge";

interface Step {
  title: string;
  description?: string;
  icon?: JSX.Element;
  subTitle?: string;
}

export interface Props {
  steps: Step[];
  currentStep: number;
  onClick?: (step: number) => void;
  dark?: boolean;
  light?: boolean;
}

// TODO ADD SIZE AND REWORK COLORS FOR DARK
export const Steps: FunctionComponent<Props & Omit<React.HTMLAttributes<HTMLDivElement>, "onClick">> = ({
  steps,
  currentStep,
  onClick,
  dark,
  light,
  ...props
}) => {
  const wrapperClasses = classNames("w-full flex justify-between gap-4 items-center h-fit relative bg-inherit", {
    dark: dark,
  });

  const classes = twMerge(
    classNames("text-content-medium flex gap-2 items-center bg-inherit", {
      "dark:text-content-bright": !light,
      "cursor-pointer": onClick,
      [`${props.className}`]: !!props.className,
    })
  );

  const stepLineClasses = (index: number) =>
    classNames("flex-1 h-1 transition-all", {
      "h-1 bg-primary-regular": currentStep >= index,
      "h-0.5 bg-primary-lightest": currentStep < index,
    });

  const iconClasses = (index: number) =>
    classNames("h-8 w-8 p-1 rounded-full flex items-center justify-center transition-all", {
      "bg-primary-darker text-content-bright": currentStep === index,
      "bg-primary-lightest text-content-bright": currentStep < index,
      "bg-primary-regular text-content-bright": currentStep > index,
      // "h-4 w-4": size === "small",
      // "h-5 w-5": size === "medium",
      // "h-6 w-6": size === "large",
    });

  return (
    <div className={wrapperClasses}>
      {steps.map((step, i) => (
        <div key={`step-${i}`} className={`flex items-center gap-4 ${i === 0 ? "" : "flex-1"}`}>
          {i > 0 && <div className={stepLineClasses(i)}></div>}
          <div className={classes} onClick={onClick ? () => onClick(i) : undefined}>
            <div className={iconClasses(i)}>{i < currentStep ? <CheckIcon /> : step.icon ? step.icon : i + 1}</div>
            <div>
              <div className="flex items-center gap-2">
                <div>{step.title}</div>
                {step.subTitle && "-"}
                {step.subTitle && <div className="text-content-light">{step.subTitle}</div>}
              </div>
              {step.description && <div>{step.description}</div>}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
