import React, { useEffect, useState } from "react";
import { FunctionComponent } from "react";
import { Tooltip } from "@getprorecrutement/getpro-design";

interface Props {
  title: string;
  value: number;
  compare_value?: number;
  color: string;
}

const CountValue: FunctionComponent<Props> = (props) => {
  const { title, value, color, compare_value } = props;
  const [percentage, setPercentage] = useState<number>();

  useEffect(() => {
    if (compare_value) setPercentage(Math.round((value * 100) / compare_value - 100));
  }, [compare_value]);

  const getCompareColor = (percentage: number) => {
    if (percentage < 0) return "#eb994d";
    else if (percentage === 0) return "#c1c1c1";
    else if (percentage > 0) return "#72ba59";
  };

  return (
    <div className="w-1/3 min-w-fit my-2 relative">
      <div className="flex justify-center items-center">
        <div
          style={{
            backgroundColor: color,
            fontSize: 16,
            borderRadius: 32,
            height: 64,
            minWidth: 64,
            color: "white",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "8px 12px",
            marginBottom: 8,
          }}
        >
          <div>{value}</div>
        </div>
        {compare_value && (
          <div
            style={{
              position: "absolute",
              backgroundColor: percentage ? getCompareColor(percentage) : "#c1c1c1",
              fontSize: 14,
              borderRadius: 32,
              height: 24,
              minWidth: 60,
              color: "white",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "0px 4px",
              bottom: "90%",
              left: "50%",
            }}
          >
            <Tooltip title={`${compare_value}`} light>{`${percentage} %`}</Tooltip>
          </div>
        )}
      </div>
      <div
        className="flex justify-center items-center"
        style={{
          color: color,
          fontSize: 14,
        }}
      >
        <b>{title}</b>
      </div>
    </div>
  );
};

export default CountValue;
