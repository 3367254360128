import React, { FunctionComponent, useEffect, useLayoutEffect, useRef, useState } from "react";
import classNames from "classnames";
import {
  AtSymbolIcon,
  EyeOffIcon,
  SearchIcon,
  PhoneIcon,
  GlobeAltIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/react/outline";
import { Tooltip } from "../../../Tooltip";
import { twMerge } from "tailwind-merge";
import { labelClasses, textInputClasses, wrapperInputClasses } from "../../../../utils/common";

export type InputTypes =
  | "button"
  | "checkbox"
  | "color"
  | "date"
  | "datetime-local"
  | "email"
  | "file"
  | "hidden"
  | "image"
  | "month"
  | "number"
  | "password"
  | "radio"
  | "range"
  | "reset"
  | "search"
  | "submit"
  | "tel"
  | "text"
  | "time"
  | "url"
  | "week";

const ICONS: { [key in InputTypes]?: JSX.Element } = {
  email: <AtSymbolIcon />,
  password: <EyeOffIcon />,
  search: <SearchIcon />,
  tel: <PhoneIcon />,
  url: <GlobeAltIcon />,
};

export interface Props {
  placeholder: string;
  label?: string;
  size?: "small" | "medium" | "large";
  type: InputTypes;
  icon?: JSX.Element;
  disabled?: boolean;
  error?: string;
  hint?: string;
  prefix?: JSX.Element;
  suffix?: JSX.Element;
  value?: string;
  onChange: (ev: React.ChangeEvent<HTMLInputElement>) => void;
  handleKeyDown?: (event: any) => void;
  autoFocus?: boolean;
  bordered?: boolean;
  dark?: boolean;
  light?: boolean;
  inputClassName?: string;
}

export const TextInput: FunctionComponent<Props &
  Omit<React.HTMLAttributes<HTMLDivElement>, "onChange" | "prefix">> = ({
  placeholder,
  label,
  icon,
  type,
  disabled,
  error,
  size = "medium",
  hint,
  prefix,
  suffix,
  value,
  onChange,
  handleKeyDown,
  bordered = true,
  autoFocus,
  dark,
  light,
  defaultValue,
  inputClassName,
  ...props
}) => {
  const [focused, setFocused] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const wrapperClasses = classNames("bg-inherit", {
    dark: dark,
  });

  const groupClasses = twMerge(
    classNames("group relative bg-inherit flex w-full", {
      [`${props.className}`]: !!props.className,
    })
  );

  useEffect(() => {
    if (inputRef.current && autoFocus) {
      inputRef.current.focus({ preventScroll: true });
    }
  }, [inputRef.current]);

  const inputClasses = twMerge(
    classNames(
      `flex relative items-center bg-transparent \
      ${wrapperInputClasses({
        size,
        disabled,
        light,
        bordered,
        focused,
        error,
      })} ${textInputClasses({
        size,
        disabled,
        light,
        error,
      })}`,
      {
        "pr-7": size === "small" && icon,
        "pr-9": (size === "medium" || size === "large") && icon,
        "rounded-r-full rounded-l-none pl-2": !suffix && prefix,
        "rounded-l-full rounded-r-none pr-2": !prefix && suffix,
        "px-2": prefix && suffix,
        "bg-background-lightest": disabled,
        "dark:bg-background-dark dark:text-content-dark dark:placeholder:text-content-dark": disabled && !light,
        [`${inputClassName}`]: !!inputClassName,
      }
    )
  );

  const iconClasses = classNames("absolute z-10 right-0 transition-colors ", {
    "mr-2.5 top-1.5": size === "small",
    "mr-3 top-1.5": size === "medium",
    "mr-3.5 top-2.5": size === "large",
    "text-content-light group-hover:text-content-darker": !focused && !error && !disabled,
    "dark:text-content-medium dark:group-hover:text-content-bright": !focused && !error && !disabled && !light,
    "text-content-darker": focused,
    "dark:text-content-bright": focused && !light,
  });

  const hintClasses = classNames("", {
    "w-3": size === "small",
    "w-3.5": size === "medium",
    "w-4": size === "large",
  });

  const errorClasses = classNames("mt-1 italic text-error-normal", {
    "pl-3 text-xs": size === "small",
    "pl-5 text-sm": size === "medium",
    "pl-8": size === "large",
  });

  const iconCloned =
    icon &&
    React.cloneElement(icon, {
      className: twMerge(
        classNames(icon.props.className, {
          "w-5 h-5 stroke-2": size === "small",
          "w-7 h-7 stroke-1": size === "medium",
          "w-8 h-8 stroke-1": size === "large",
        })
      ),
    });

  return (
    <div className={wrapperClasses}>
      <div {...props} className={groupClasses}>
        {prefix && prefix}
        <div className="flex relative bg-inherit w-full">
          {label && (
            <label className={labelClasses({ size, disabled, light, focused, error })}>
              {label}{" "}
              {hint && (
                <div className="ml-1">
                  <Tooltip title={hint}>
                    <QuestionMarkCircleIcon className={hintClasses} />
                  </Tooltip>
                </div>
              )}
            </label>
          )}
          <input
            ref={inputRef}
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
            onChange={onChange}
            value={value || ""}
            defaultValue={defaultValue}
            placeholder={placeholder}
            className={inputClasses}
            type={type}
            disabled={disabled}
            onKeyDown={handleKeyDown}
          />
        </div>
        {suffix
          ? suffix
          : (icon || (ICONS as any)[type]) && <div className={iconClasses}>{iconCloned || (ICONS as any)[type]}</div>}
      </div>
      {error && <div className={errorClasses}>{error}</div>}
    </div>
  );
};
