import React, { FunctionComponent } from "react";
import { Tooltip, Progress } from "@getprorecrutement/getpro-design";

interface Props {
  title: string;
  icon: JSX.Element;
  value: number;
  size: number;
  color: string;
}

const displayBigNumber = (value: number) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, " ");

const ProgressValue: FunctionComponent<Props> = (props) => {
  const { title, icon, value, size, color } = props;

  return (
    <div>
      <Tooltip light title={title}>
        <div style={{ width: 120, height: 132 }}>
          <Progress className="absolute" value={value} size={size} type="circle" labelMode="none" light />
          <div className="flex flex-col items-center h-full justify-center relative" style={{ color }}>
            <div className="mt-2 mb-1">{icon}</div>
            <div>{displayBigNumber(value)}</div>
          </div>
        </div>
      </Tooltip>
    </div>
  );
};

export default ProgressValue;
