import classNames from "classnames";
import React, { FunctionComponent, useState } from "react";
import { twMerge } from "tailwind-merge";
import { ElementCarousel } from "../ElementCarousel";

export interface StepperItem {
  onFinish?: () => void;
  onCancel?: () => void;
}

export interface Step<T extends StepperItem> {
  Component: FunctionComponent<T>;
  title?: string;
  key: string;
  sub_title?: string;
  is_valid: boolean;
  props?: Partial<Omit<T, "onFinish" | "onCancel">>;
}

export interface Props<T extends StepperItem> {
  values: Step<T>[];
  position?: "top" | "bottom";
  onFinish?: () => void;
  headerClasses?: string;
  customRender?: (value: Step<T>, focused: boolean, focus: () => void) => JSX.Element;
}

export const Stepper = <T extends StepperItem>({
  values,
  onFinish,
  position = "top",
  customRender,
  headerClasses,
  ...props
}: Props<T> & React.HTMLAttributes<HTMLDivElement>) => {
  const [index, setIndex] = useState<number>(0);

  const _headerClasses = twMerge(
    classNames("w-full flex justify-around items-center my-5", {
      [`${headerClasses}`]: !!headerClasses,
    })
  );

  const goNext = () => {
    if (!values[index].is_valid) return;
    if (index == values.length - 1) return onFinish?.();
    setIndex((index) => index + 1);
  };

  const classes = classNames("flex", {
    "flex-col-reverse": position == "top",
    "flex-col": position == "bottom",
    [`${props.className}`]: !!props.className,
  });

  const goPrev = () => {
    if (index == 0) return;
    setIndex((index) => index - 1);
  };

  const renderStepper = () => {
    const maxIndex = values.map((elem) => elem.is_valid).findIndex((elem) => elem === false);
    return values.map((value, i) => {
      return customRender ? (
        customRender(value, i === index, () => (maxIndex == -1 || i <= maxIndex) && setIndex(i))
      ) : (
        <div className="relative w-full flex-col flex justify-center items-center text-center">
          {i < values.length - 1 && (
            <div
              className={`absolute w-[calc(100%-56px)] h-1 top-3 left-[calc(50%+28px)] transition-colors  border-t-2 border-dashed ${
                index > i ? "border-primary-darker" : "border-primary-light"
              }`}
            />
          )}

          <div
            onClick={() => {
              if (maxIndex == -1 || i <= maxIndex) setIndex(i);
            }}
            className={`w-7 h-7 ${
              maxIndex == -1 || i <= maxIndex ? "cursor-pointer" : "cursor-not-allowed"
            } flex justify-center flex-col items-center transition-colors  rounded-full border ${
              index >= i ? "bg-primary-dark text-white" : "bg-primary-light text-primary-normal"
            }`}
          >
            <div>{i + 1}</div>
          </div>
          {value.title && <div>{value.title}</div>}
          {value.sub_title && <div className="text-xs italic">{value.sub_title}</div>}
        </div>
      );
    });
  };

  return (
    <div {...props} className={classes}>
      <ElementCarousel
        manualIndex={index}
        values={values.map(({ Component, props, key }: Step<T>) => {
          let componentProps = {
            onFinish: goNext,
            onCancel: goPrev,
            ...props,
          } as T;
          return <Component {...componentProps} key={key} />;
        })}
        autoPlay={false}
        showIndicators={false}
      />
      <div className={_headerClasses}>{renderStepper()}</div>
    </div>
  );
};
