import React from "react";
import { FunctionComponent } from "react";
import ProgressValue from "../../elements/progressValue";
import { PALETTE } from "../..";
import { Tooltip } from "@getprorecrutement/getpro-design";
import { EnvelopeIcon, PhoneIcon, UserIcon } from "@heroicons/react/24/solid";
import LinkedinIcon from "../../../../utils/icons";

interface Props {
  countByType: { [key: string]: number };
  totalCount: number;
  showJos: boolean;
  locked: boolean;
}

const displayBigNumber = (value: number) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, " ");

export const ContactedStats: FunctionComponent<Props> = (props) => {
  const { countByType, totalCount, showJos, locked } = props;

  const displayCandidateCount =
    [countByType["email"], countByType["phone"], countByType["inmail"]].filter((e) => e).length > 1;

  return (
    <div
      style={{
        height: 284,
        fontSize: 16,
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        marginBottom: showJos || !locked ? 8 : 24,
      }}
    >
      {displayCandidateCount && (
        <div className="flex justify-center items-center">
          <Tooltip light title={"Candidats contactés"}>
            <div
              style={{
                border: "2px dashed #a1a1a1",
                borderRadius: 60,
                height: 110,
                minWidth: 110,
              }}
            >
              <div className="h-full flex flex-col justify-center items-center gap-1">
                <UserIcon className="text-[#a1a1a1] w-6 h-6" />
                <div className="text-[#a1a1a1]">{displayBigNumber(totalCount || 0)}</div>
              </div>
            </div>
          </Tooltip>
        </div>
      )}
      <div className="flex justify-around flex-wrap">
        {countByType["email"] && (
          <ProgressValue
            title="E-mail"
            icon={<EnvelopeIcon width={20} height={20} />}
            value={countByType["email"]}
            size={totalCount}
            color={PALETTE.AQUA[0]}
          />
        )}
        {countByType["phone"] && (
          <ProgressValue
            title={"Téléphone"}
            icon={<PhoneIcon width={20} height={20} />}
            value={countByType["phone"]}
            size={totalCount}
            color={PALETTE.AQUA[2]}
          />
        )}
        {countByType["inmail"] && (
          <ProgressValue
            title={"InMail"}
            icon={<LinkedinIcon fill={"#177470"} />}
            value={countByType["inmail"]}
            size={totalCount}
            color={PALETTE.AQUA[4]}
          />
        )}
      </div>
    </div>
  );
};

export default ContactedStats;
