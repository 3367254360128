import classNames from "classnames";

import { modalHandler } from ".";
import { Portal } from "../Portal";
import React, { FunctionComponent, useState } from "react";

export interface ModelHandlerProps {
  onChange?: (show: boolean) => void;
}

export const ModalPortal: FunctionComponent<ModelHandlerProps> = ({ onChange }) => {
  const [open, setOpen] = useState<boolean>();

  const classes = classNames("z-50 h-max min-h-screen w-screen fixed top-0 left-0", {
    "pointer-events-auto": open,
    "pointer-events-none": !open,
  });

  return (
    <Portal
      id="modal-portal"
      onChange={(v) => {
        setOpen(v);
        onChange?.(v);
      }}
      className={classes}
      handler={modalHandler}
    />
  );
};
