import React, { FunctionComponent, useState } from "react";
import { NewJobApplication, createJa } from "../../../models/jobApplications";
import JobOfferSelect from "../inputs/jobOfferSelect";
import StatusSelect from "../inputs/statusSelect";
import { Button } from "@getprorecrutement/getpro-design";
import { Candidate } from "../../../models/candidate";
import { useFormManager } from "../../../services/form";
import { useLoading } from "../../../utils/loading";

interface Props {
  candidate: Candidate;
  onFinish: () => void;
}

const JobAppForm: FunctionComponent<Props> = ({ candidate, onFinish }) => {
  const [loading, setLoading] = useState(false);
  const load = useLoading(setLoading);
  const form = useFormManager<NewJobApplication>({
    // source_id is defined with Sweeft source id
    defaultState: { candidate_id: candidate.id, source_id: "6803df04-733e-412c-a39e-6bb3af2aec0b" },
    validations: {
      job_offer_id: {
        required: true,
        message: "Merci de préciser l'offre",
      },
      status_id: {
        required: true,
        message: "Merci de préciser le statut",
      },
    },
  });

  const onSubmit = async (data: NewJobApplication) => {
    load(createJa(data).then(() => onFinish()));
  };

  return (
    <>
      <div className="text-2xl mb-2">{`Nouvelle candidature pour ${candidate.full_name || "Candidat anonyme"}`}</div>
      <div className="createJobApp bg-inherit py-2 pr-4 overflow-auto">
        <div className="mt-6 bg-inherit">
          <JobOfferSelect
            label="Offre"
            onSelect={(id) => form.onChange({ job_offer_id: id })}
            value={form.state.job_offer_id}
            error={form.errors.job_offer_id}
          />
        </div>
        <div className="mt-6 bg-inherit">
          <StatusSelect
            label="Statut"
            onSelect={(id) => form.onChange({ status_id: id })}
            value={form.state.status_id}
            error={form.errors.status_id}
          />
        </div>
        <div className="flex justify-end mt-8">
          <Button
            light
            loading={loading}
            title="Enregistrer"
            onClick={() => form.validate() && onSubmit(form.state as NewJobApplication)}
            disabled={form.disabled}
          />
        </div>
      </div>
    </>
  );
};

export default JobAppForm;
