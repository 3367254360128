import React, { FunctionComponent, useEffect, useState } from "react";
import { CustomerResponse, getCustomers } from "../../../models/customers";
import CustomerForm from "../../../components/forms/customerForm";
import CustomerCard from "./customer";
import { Button, ColumnProps, Tooltip, Table, Modal } from "@getprorecrutement/getpro-design";
import { CheckIcon } from "@heroicons/react/24/outline";

const PER_PAGE = 10;

export const SettingsCustomers: FunctionComponent = () => {
  const [search, setSearch] = useState<string>("");
  const [customers, setCustomers] = useState<CustomerResponse[]>([]);
  const [customer, setCustomer] = useState<CustomerResponse | undefined>();
  const [edit, setEdit] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);

  const columns: ColumnProps<CustomerResponse>[] = [
    {
      title: "Nom",
      dataIndex: "name",
      key: "name",
      filterBy: "text",
    },
    {
      title: "Propriétaire",
      dataIndex: "owner_id",
      key: "owner_id",
      width: 120,
      render: (_, customer) => (
        <Tooltip light title={`${customer.owner_first_name} ${customer.owner_last_name}`}>
          <div className="truncate max-w-[110px]">
            {customer.owner_first_name} {customer.owner_last_name[0]}.
          </div>
        </Tooltip>
      ),
    },
    {
      title: "Adresse de facturation",
      dataIndex: "location",
      key: "location",
      width: 200,
      render: (_, customer) => (
        <Tooltip light title={customer.location.formatted_address}>
          <div className="truncate w-[190px]">{customer.location.formatted_address}</div>
        </Tooltip>
      ),
    },
    {
      title: "Description",
      width: 90,
      dataIndex: "description",
      key: "description",
      render: (_, customer) => (
        <div className="flex justify-center">
          {customer.description ? <CheckIcon width={20} height={20} /> : undefined}
        </div>
      ),
    },
  ];

  useEffect(() => {
    fetchCustomers();
  }, []);

  useEffect(() => {
    if (page !== 1) setPage(1);
  }, [search]);

  const fetchCustomers = async () => {
    const res = await getCustomers();
    setCustomers(res);
    if (customer) setCustomer(res.find((e) => e.id === customer.id));
  };

  const onFinish = async (): Promise<void> => {
    setEdit(false);
    fetchCustomers();
  };

  const displayedCustomers = (filterPage: boolean) => {
    const save = search ? customers.filter((c) => c.name.toLowerCase().includes(search.toLowerCase())) : customers;

    return filterPage ? save.filter((_, i) => i >= (page - 1) * 10 && i < page * 10) : save;
  };

  return (
    <div className="settingsCustomers">
      <div className="bg-white p-6 shadow-md flex justify-between items-center bg-inherit">
        <div className="text-3xl font-bold">Entreprises</div>
        <Button
          onClick={() => {
            setCustomer(undefined);
            setEdit(true);
          }}
          light
          size="small"
          title="Nouvelle entreprise"
        />
      </div>
      <div className="flex gap-6 p-6">
        <div className="flex-1">
          <Table
            dataSource={{
              data: displayedCustomers(true).map((e) => ({ key: e.id, ...e })),
              total: displayedCustomers(false).length,
            }}
            columns={columns}
            light
            bordered
            onClick={(record) => {
              setCustomer(record);
              window.scrollTo(0, 0);
            }}
            pagination={{ per_page: PER_PAGE, page, onChange: setPage }}
            filter={{
              filters: { name: search },
              onFilterChange: (key, value) => {
                if (key === "name") {
                  setSearch(value);
                }
              },
            }}
          />
        </div>
        {customer && (
          <CustomerCard
            customer={customer}
            fetchCustomers={fetchCustomers}
            setCustomer={setCustomer}
            setEdit={setEdit}
          />
        )}
      </div>
      <Modal onClose={() => setEdit(false)} show={edit} className="w-modal-xl" light>
        <CustomerForm key={customer?.id || "new_company"} onFinish={onFinish} customer={customer} />
      </Modal>
    </div>
  );
};

export default SettingsCustomers;
