import React, { FunctionComponent, useState, useEffect } from "react";
import { Status } from "../../../../models/jobOffers";
import { UUID } from "../../../../models/common";
import { Summary } from "../../../../models/jobOffers";
import { getStatuses } from "../../../../services/requests";
import { Select } from "@getprorecrutement/getpro-design";
import { MultipleValues, SingleValue } from "@getprorecrutement/getpro-design/dist/components/Form/Inputs/Select";
import classNames from "classnames";

interface Props {
  statuses?: Status[];
  onSelect: (s: UUID) => void;
  placeholder?: string;
  style?: React.CSSProperties;
  summary?: Summary;
  bordered?: boolean;
  size?: "small" | "medium" | "large";
  disabled?: boolean;
  label?: string;
  dark?: boolean;
  error?: string;
  dropdownClassName?: string;
}

interface MultipleProps {
  multiple: true;
  value?: UUID[];
  customValueRender?: (value: Status[]) => JSX.Element;
}

interface SingleProps {
  multiple?: false;
  value?: UUID;
  customValueRender?: (value: Status) => JSX.Element;
}

export const StatusSelect: FunctionComponent<Props & (SingleProps | MultipleProps)> = (props) => {
  const [statuses, setStatuses] = useState<Status[]>(props.statuses || []);

  const fetchStatuses = async () => {
    getStatuses().then(setStatuses);
  };

  useEffect(() => {
    if (!props.statuses) fetchStatuses();
  }, []);

  useEffect(() => {
    if (props.statuses) setStatuses(props.statuses);
  }, [props.statuses]);

  const selectProps: SingleValue<Status> | MultipleValues<Status> = props.multiple
    ? {
        type: "multiple",
        value: statuses.filter((s) => props.value?.includes(s.id)),
        customValueRender: props.customValueRender,
      }
    : {
        type: "single",
        value: statuses.find((cs) => cs.id === props.value),
        customValueRender: props.customValueRender,
      };

  const dropdownClasses = classNames("border border-solid rounded-3xl", {
    "bg-white": !props.dark,
    "bg-background-dark border-border-medium": props.dark,
    [`${props.dropdownClassName}`]: props.dropdownClassName,
  });

  return (
    <span style={props.style} className="statusSelect bg-inherit">
      <Select
        dark={props.dark}
        light={props.dark ? false : true}
        showArrowIcon
        width={90}
        bordered
        rounded
        placeholder={props.placeholder}
        size={props.size}
        label={props.label}
        error={props.error}
        onSearch={undefined}
        disabled={props.disabled}
        options={statuses.sort((a, b) => a.index - b.index)}
        optionRender={(item) => (
          <div className="flex gap-3 items-center">
            <div className="w-2 h-2 rounded-full" style={{ backgroundColor: `#${item.color}` }}></div>
            <div>{item.name}</div>
          </div>
        )}
        dropdownClassName={dropdownClasses}
        getKey={(item) => item.id}
        onChange={(v) => v && props.onSelect(v.id)}
        {...selectProps}
      />
    </span>
  );
};

export default StatusSelect;
