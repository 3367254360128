import React, { Fragment, useEffect, forwardRef, useImperativeHandle } from "react";
import { JobOffer, JobOfferData, OpportunityTypes } from "../../../models/jobOffers";
import { useState } from "react";
import { Project, ProjectData, ProjectType, ProjectTypes } from "../../../models/billing/project";
import { ContractResponse, getContracts } from "../../../models/billing/contract";
import { UUID } from "../../../models/common";
import { getUser } from "../../../models/users/users";
import { Select, Slider, TextInput, Radio, Checkbox, InputNumber } from "@getprorecrutement/getpro-design";
import { BusinessUnitSelect } from "../inputs/businessUnitSelect";
import HelpTooltip from "../../helpTooltip";
import { StepHandle } from ".";
import { FormManager, useFormManager } from "../../../services/form";

interface Props {
  setDisabled: (value: boolean) => void;
  data: {
    project?: ProjectData;
    job_offer?: JobOfferData;
  };
  jobOffer: Partial<JobOffer>;
  project: Partial<Project>;
}

function getProjectTypeOptions(
  offerType?: JobOffer["opportunity_type"]
): { label: string; value: Project["project_type"] }[] {
  if (offerType === "cabinet") return ProjectTypes.recruitment.filter((e) => e.value !== "recruitment_commission_none");
  if (offerType === "rpo") return ProjectTypes.recruitment.filter((e) => e.value !== "recruitment_commission_third");
  return [];
}

function showPercentage(project?: ProjectData): JSX.Element {
  return (
    <div className="flex justify-center">
      <div>Au recrutement : {project?.details.percent_billed_at_recruitment || 0} %</div>
      <div style={{ marginLeft: 8 }}>
        Prise de poste : {100 - (project?.details.percent_billed_at_recruitment || 0)} %
      </div>
    </div>
  );
}

function getBillingInputs(
  recruitmentType: (typeof ProjectTypes.recruitment)[number]["value"],
  opportunityType: JobOffer["opportunity_type"],
  projectForm: FormManager<Project>
): JSX.Element | null {
  if (recruitmentType === "recruitment_commission")
    return (
      <>
        <InputNumber
          light
          label="Montant forfaitaire"
          placeholder="18 000 €"
          value={projectForm.state.details?.amount}
          onChange={(e) => projectForm.onChange({ details: { ...(projectForm.state.details || {}), amount: e } })}
          display={{
            formatter: (value) => value && value.replace(/\B(?=(\d{3})+(?!\d))/g, " "),
            parser: (value) => (value ? value.replace(/[\s€]*/g, "") : ""),
          }}
          precision={2}
          suffix="€"
          min={0}
          controls={false}
        />
        {opportunityType !== "rpo" && (
          <InputNumber
            light
            label="Acompte"
            placeholder="1 000 €"
            value={projectForm.state.details?.retainer}
            onChange={(e) => projectForm.onChange({ details: { ...(projectForm.state.details || {}), retainer: e } })}
            display={{
              formatter: (value) => value && value.replace(/\B(?=(\d{3})+(?!\d))/g, " "),
              parser: (value) => (value ? value.replace(/[\s€]*/g, "") : ""),
            }}
            precision={2}
            suffix="€"
            min={0}
            controls={false}
          />
        )}
        <div className="w-full h-16">
          <Slider
            light
            size={101}
            defaultValue={projectForm.state.details?.percent_billed_at_recruitment}
            onChange={(e: number) => {
              projectForm.onChange({
                details: { ...(projectForm.state.details || {}), percent_billed_at_recruitment: e },
              });
            }}
          />
        </div>
        {showPercentage(projectForm.state)}
      </>
    );
  if (recruitmentType === "recruitment_commission_rate")
    return (
      <>
        <InputNumber
          light
          label="Taux"
          placeholder="25 %"
          value={projectForm.state.details.rate}
          onChange={(e) => projectForm.onChange({ details: { ...(projectForm.state.details || {}), rate: e } })}
          precision={2}
          suffix="%"
          min={0}
          max={100}
        />
        <InputNumber
          light
          label="Montant minimum"
          placeholder="5 000 €"
          value={projectForm.state.details?.minimum_billed}
          onChange={(e) =>
            projectForm.onChange({ details: { ...(projectForm.state.details || {}), minimum_billed: e } })
          }
          display={{
            formatter: (value) => value && value.replace(/\B(?=(\d{3})+(?!\d))/g, " "),
            parser: (value) => (value ? value.replace(/[\s€]*/g, "") : ""),
          }}
          precision={2}
          suffix="€"
          min={0}
          controls={false}
        />
        {opportunityType !== "rpo" && (
          <InputNumber
            light
            label="Acompte"
            placeholder="1 000 €"
            value={projectForm.state.details?.retainer}
            onChange={(e) => projectForm.onChange({ details: { ...(projectForm.state.details || {}), retainer: e } })}
            precision={2}
            display={{
              formatter: (value) => value && value.replace(/\B(?=(\d{3})+(?!\d))/g, " "),
              parser: (value) => (value ? value.replace(/[\s€]*/g, "") : ""),
            }}
            suffix="€"
            min={0}
            controls={false}
          />
        )}
        <div className="w-full h-16">
          <Slider
            light
            size={101}
            defaultValue={projectForm.state.details?.percent_billed_at_recruitment}
            onChange={(e: number) => {
              projectForm.onChange({
                details: { ...(projectForm.state.details || {}), percent_billed_at_recruitment: e },
              });
            }}
          />
        </div>
        {showPercentage(projectForm.state)}
      </>
    );
  if (recruitmentType === "recruitment_commission_third")
    return (
      <>
        <InputNumber
          light
          label="Commissions"
          placeholder="8 000 €"
          value={projectForm.state.details?.amount}
          onChange={(e) => projectForm.onChange({ details: { ...(projectForm.state.details || {}), amount: e } })}
          display={{
            formatter: (value) => value && value.replace(/\B(?=(\d{3})+(?!\d))/g, " "),
            parser: (value) => (value ? value.replace(/[\s€]*/g, "") : ""),
          }}
          precision={2}
          suffix="€"
          min={0}
          controls={false}
        />
        <InputNumber
          light
          label="Nombre de présentés"
          placeholder="3"
          value={projectForm.state.details?.candidates_sent_requirement}
          onChange={(e) =>
            projectForm.onChange({ details: { ...(projectForm.state.details || {}), candidates_sent_requirement: e } })
          }
          min={0}
        />
      </>
    );
  return null;
}

export const BillingStepForm = forwardRef<StepHandle, Props>(function BillingStepForm(props, ref) {
  const { jobOffer, project } = props;

  const [contracts, setContracts] = useState<ContractResponse[]>();

  const jobOfferForm = useFormManager<JobOffer>({
    defaultState: { ...jobOffer, ...props.data.job_offer },
    validations: {
      opportunity_type: {
        required: true,
        message: "Merci de préciser le type d'opportunité",
      },
    },
  });

  const projectForm = useFormManager<Project>({
    defaultState: { ...project, ...props.data.project } as Partial<Project>,
    validations: {
      business_unit_id: {
        required: true,
        message: "Merci de préciser le département",
      },
      contract_id: {
        required: true,
        message: "Merci de préciser un contrat",
      },
      project_type: {
        required: true,
        message: "Merci de préciser le type de projet",
      },
      details: {
        required: (details) => {
          if (projectForm.state.project_type === "recruitment_commission_none") return true;
          if (!details) return false;

          if (projectForm.state.project_type === "recruitment_commission_rate") {
            return (
              details.rate !== undefined &&
              details.minimum_billed !== undefined &&
              (jobOfferForm.state.opportunity_type === "rpo" || details.retainer !== undefined) &&
              details.percent_billed_at_recruitment !== undefined
            );
          }
          if (projectForm.state.project_type === "recruitment_commission") {
            return (
              details.amount !== undefined &&
              (jobOfferForm.state.opportunity_type === "rpo" || details.retainer !== undefined) &&
              details.percent_billed_at_recruitment !== undefined
            );
          }
          if (projectForm.state.project_type === "recruitment_commission_third") {
            return details.amount !== undefined && details.candidates_sent_requirement !== undefined;
          }

          return true;
        },
        message: "Merci de remplir les informations récessaires à la facturation",
      },
    },
  });

  useImperativeHandle(
    ref,
    () => ({
      isValid() {
        const projectFormValid = projectForm.validate();
        const jobOfferFormValid = jobOfferForm.validate();
        return projectFormValid && jobOfferFormValid;
      },
      getData() {
        return {
          project: { ...props.data.project, ...projectForm.state },
          job_offer: { ...props.data.job_offer, ...jobOfferForm.state },
        };
      },
    }),
    [projectForm.state, jobOfferForm.state]
  );

  useEffect(() => {
    props.setDisabled(projectForm.disabled || jobOfferForm.disabled);
  }, [projectForm.disabled, jobOfferForm.disabled]);

  const fetchContracts = async () => {
    const c = await getContracts({
      customer_id: jobOffer.company_id,
      page: 1,
      per_page: 100,
    });
    setContracts(c.data);
  };

  const fetchUser = async (id: UUID) => {
    const u = await getUser(id);
    projectForm.onChange({ business_unit_id: u.business_unit_id });
  };

  useEffect(() => {
    fetchContracts();
    if (jobOffer.owner_id) fetchUser(jobOffer.owner_id);
  }, []);

  const projectTypeOptions = getProjectTypeOptions(jobOfferForm.state.opportunity_type);

  const setOpportunityType = (type: JobOffer["opportunity_type"] | undefined) => {
    projectForm.onChange({ project_type: undefined, details: undefined });
    jobOfferForm.onChange({ opportunity_type: type });
  };

  const setProjectType = (mode?: ProjectType) => {
    projectForm.onChange({
      project_type: mode,
      details:
        mode === project.project_type
          ? project.details
          : mode === ProjectType.Commission || mode === ProjectType.CommissionRate
          ? { percent_billed_at_recruitment: 100 }
          : undefined,
    });
  };

  return (
    <div className="billingStep bg-inherit flex flex-col gap-4">
      <BusinessUnitSelect
        error={projectForm.errors.business_unit_id}
        label="Département"
        placeholder="Département"
        value={projectForm.state.business_unit_id}
        onSelect={(id) => projectForm.onChange({ business_unit_id: id })}
      />
      <div className="flex justify-between items-center gap-4">
        <div>
          <Radio
            light
            options={OpportunityTypes.filter((e) => e.value !== "interne")}
            optionType="button"
            value={jobOfferForm.state.opportunity_type}
            onChange={(e) => {
              setOpportunityType(e as "cabinet" | "rpo");
            }}
          />
          {jobOfferForm.errors.opportunity_type && (
            <div className="text-error-normal italic">{jobOfferForm.errors.opportunity_type}</div>
          )}
        </div>
        {jobOfferForm.state.opportunity_type && (
          <div>
            <Radio
              light
              options={projectTypeOptions}
              optionType="button"
              value={projectForm.state.project_type}
              onChange={(e) => {
                setProjectType(e as Project["project_type"]);
              }}
            />
            {projectForm.errors.project_type && (
              <div className="text-error-normal italic">{projectForm.errors.project_type}</div>
            )}
          </div>
        )}
      </div>
      {jobOfferForm.state.opportunity_type && (
        <Fragment>
          {projectForm.state.project_type && (
            <>
              {getBillingInputs(projectForm.state.project_type, jobOfferForm.state.opportunity_type, projectForm)}
              {projectForm.errors.details && (
                <div className="text-error-normal italic">{projectForm.errors.details}</div>
              )}
              <Select
                light
                bordered
                rounded
                error={projectForm.errors.contract_id}
                label="Contrat"
                options={contracts || []}
                optionRender={(c) => c.name}
                getKey={(c) => c.id}
                type="single"
                onSearch={undefined}
                value={contracts?.find((c) => c.id === projectForm.state.contract_id)}
                disabled={!!jobOfferForm.state.project_id}
                onChange={(c) => {
                  if (c) {
                    projectForm.onChange({ contract_id: c.id });
                  }
                }}
              />
              <div className="flex items-center gap-2 bg-inherit">
                <div className="flex-1 bg-inherit">
                  <TextInput
                    light
                    type="text"
                    label="Description"
                    placeholder="Description"
                    value={projectForm.state.description}
                    onChange={({ target }) => projectForm.onChange({ description: target.value })}
                  />
                </div>
                <HelpTooltip
                  position="left"
                  title="Modalités spécifiques au projet - utile lorsque la facturation est manuelle ou avec des montants inconnus"
                />
              </div>
              <div className="flex items-center gap-2">
                <Checkbox
                  label="Facturation manuelle"
                  light
                  value={!!projectForm.state.manual_billing}
                  onChange={(v) => projectForm.onChange({ manual_billing: v })}
                />
                <HelpTooltip
                  position="right"
                  title="À utiliser si l'évènement déclencheur n'est pas standard (exemple : l'ensemble des honoraires sont à payer à l'entrée en poste du candidat)"
                />
              </div>
            </>
          )}
        </Fragment>
      )}
    </div>
  );
});

export default BillingStepForm;
