import {
  CommissionDetails,
  CommissionRateDetails,
  CommissionThirdDetails,
  getProjectBillsDetails,
  ProductDetails,
  ProductLine,
  ProjectResponse,
  ProjectStatuses,
  ProjectType,
  RpoCostLine,
  RpoDetails,
  SubscriptionDetails,
} from "../../../models/billing/project";
import React, { useEffect, useState } from "react";
import InlineLabelValue from "../../inlineLabelValue";
import { BillResponse, BillsFilters, getBills } from "../../../models/billing/bill";
import { Paginated } from "../../../models/common";
import { euroDisplay } from "../../../utils/formatters";
import ProjectTypeTag from "./projectTypeTag";
import ContactsList from "../contacts/contactsList";
import { Contact, getProjectContacts } from "../../../models/contacts";
import store, { Message } from "../../../services/store";
import { TemplateData } from "../../../models/messenger";
import BillAndBookingList from "../billsAndBookingList";
import { Booking, BookingsFilters, getBookings } from "../../../models/billing/booking";
import { Link } from "react-router-dom";
import { Tag, Divider, Table, ColumnProps, TagColor } from "@getprorecrutement/getpro-design";

const PER_PAGE = 12;

interface Props {
  project: ProjectResponse;
}

export const ProjectCard = (props: Props) => {
  const { project } = props;
  const [bills, setBills] = useState<Paginated<BillResponse>>();
  const [contacts, setContacts] = useState<Contact[]>([]);
  const [bookings, setBookings] = useState<Paginated<Booking>>();
  const [projectBillsDetails, setProjectBillsDetails] = useState<{ desc: string; quantity: number }[]>([]);

  const fetchBookings = async (filters: BookingsFilters) => {
    const b = await getBookings({
      ...filters,
      per_page: PER_PAGE,
      project_id: project.id,
    });
    setBookings(b);
  };

  const fetchBills = async (filters: BillsFilters, project_types: ProjectType[]) => {
    const b = await getBills({
      ...filters,
      per_page: PER_PAGE,
      project_id: project.id,
      project_types,
    });
    if (project.project_type === "rpo") {
      const project_bills_details = await getProjectBillsDetails({ project_id: project.id });
      setProjectBillsDetails(project_bills_details);
    }
    setBills(b);
  };

  const fetchContacts = async () => {
    const c = await getProjectContacts(project.id);
    setContacts(c);
  };

  useEffect(() => {
    fetchContacts();
  }, [project]);

  const openMessenger = (contact: Contact) => {
    const templateData: TemplateData = {
      ...contact,
      last_bill_amount: euroDisplay(bills?.data[0]?.amount),
    };
    store.notify(Message.Messenger, {
      label: `${contact.first_name} ${contact.last_name}`,
      to: contact.email,
      from: store.state.User?.email,
      templateData,
      closeAfterSend: true,
    });
  };

  const commissionThird = () => {
    return (
      <div className="flex-1">
        <InlineLabelValue
          label="Montant par tiers"
          value={euroDisplay((project.details as CommissionThirdDetails).amount)}
          bold={true}
          labelWidth={200}
        />
        <InlineLabelValue
          label="Montant total"
          value={euroDisplay((project.details as CommissionThirdDetails).amount * 3)}
          bold={true}
          labelWidth={200}
        />
        <InlineLabelValue
          label={"Présentés avant 2e facture"}
          value={`${(project.details as CommissionThirdDetails).candidates_sent_requirement}`}
          bold={true}
          labelWidth={200}
        />
      </div>
    );
  };

  const commission = () => {
    const retainer = (project.details as CommissionDetails).retainer;
    return (
      <div className="flex-1">
        <InlineLabelValue
          label="Montant"
          value={euroDisplay((project.details as CommissionDetails).amount)}
          bold={true}
          labelWidth={200}
        />
        {typeof retainer === "number" && retainer > 0 && (
          <InlineLabelValue label="Acompte" value={euroDisplay(retainer)} bold={true} labelWidth={200} />
        )}
      </div>
    );
  };

  const commissionRate = () => {
    const details = project.details as CommissionRateDetails;
    const minimum_billed = details.minimum_billed;
    const retainer = details.retainer;
    const amount = details.amount;
    const rate = details.rate;

    return (
      <div className="flex-1">
        <InlineLabelValue label={"Salaire prévu"} value={euroDisplay(amount)} bold={true} labelWidth={200} />
        {rate !== 100 && <InlineLabelValue label="Taux" value={`${rate} %`} bold={true} labelWidth={200} />}
        {rate !== 100 && (
          <InlineLabelValue label={"Montant"} value={euroDisplay((amount * rate) / 100)} bold={true} labelWidth={200} />
        )}
        {minimum_billed && minimum_billed > 0 && (
          <InlineLabelValue
            label="Minimum de facturation"
            value={euroDisplay(minimum_billed)}
            bold={true}
            labelWidth={200}
          />
        )}
        {typeof retainer === "number" && retainer > 0 && (
          <InlineLabelValue label="Acompte" value={euroDisplay(retainer)} bold={true} labelWidth={200} />
        )}
      </div>
    );
  };

  const productColumns: ColumnProps<ProductLine>[] = [
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Prix",
      dataIndex: "amount",
      key: "amount",
      width: 80,
      render: (val) => <div>{euroDisplay(val)}</div>,
    },
    { title: "Quantité", dataIndex: "quantity", key: "quantity", width: 80 },
  ];

  const product = () => {
    const products = (project.details as ProductDetails).products;
    const [page, setPage] = useState<number>(1);

    return (
      <div className="flex-1">
        <Table
          size="small"
          light
          bordered
          dataSource={{ data: products.filter((_, i) => i >= (page - 1) * 3 && i < page * 3), total: products.length }}
          columns={productColumns}
          pagination={{ per_page: 3, page, onChange: setPage }}
        />
      </div>
    );
  };

  const subscription = () => {
    const details = project.details as SubscriptionDetails;
    const amount = details.amount;
    const quantity = details.quantity;

    return (
      <div className="flex-1">
        <InlineLabelValue label="Prix annuel" value={euroDisplay(amount)} bold={true} labelWidth={200} />
        <InlineLabelValue label={"Nombre de crédits"} value={`${quantity} crédits`} bold={true} labelWidth={200} />
      </div>
    );
  };

  const rpoColumns: ColumnProps<RpoCostLine & { billed: number }>[] = [
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "TJM",
      dataIndex: "amount",
      key: "amount",
      render: (val) => <div>{euroDisplay(val)}</div>,
    },
    { title: "Facturé", dataIndex: "billed", key: "billed" },
    { title: "Restant", dataIndex: "days", key: "days", render: (value, record) => <div>{value - record.billed}</div> },
  ];

  const rpo = () => {
    const cost_lines = (project.details as RpoDetails).cost_lines.map((c) => ({
      ...c,
      billed: projectBillsDetails.find((b) => b.desc === c.description)?.quantity || 0,
    }));
    const [page, setPage] = useState<number>(1);

    return (
      <div className="flex-1">
        <Table
          size="small"
          columns={rpoColumns}
          dataSource={{
            data: cost_lines.filter((_, i) => i >= (page - 1) * 3 && i < page * 3),
            total: cost_lines.length,
          }}
          light
          bordered
          pagination={{ per_page: 3, page, onChange: setPage }}
        />
      </div>
    );
  };

  const projectBasicInfos = () => {
    const status = ProjectStatuses.find((e) => e.value === project.status)?.label;

    return (
      <div className="flex-1">
        {project.description && (
          <InlineLabelValue label="Description" value={project.description} bold={true} labelWidth={200} />
        )}
        <InlineLabelValue
          label="Entreprise"
          value={<Link to={`/billing/customers/${project.customer_id}`}>{project.customer_name}</Link>}
          bold={true}
          labelWidth={200}
        />
        <InlineLabelValue
          label="Type de projet"
          value={<ProjectTypeTag type={project.project_type} />}
          bold={true}
          labelWidth={200}
        />
        {status && <InlineLabelValue label="Statut" value={status} bold={true} labelWidth={200} />}
        <InlineLabelValue
          label="Facturation manuelle"
          value={
            <Tag
              type={project.manual_billing ? TagColor.Success : TagColor.Error}
              value={project.manual_billing ? "Oui" : "Non"}
              light
              bordered
            />
          }
          bold={true}
          labelWidth={200}
        />
        <InlineLabelValue
          label={"Montant facturé"}
          value={euroDisplay(project.amount_billed)}
          bold={true}
          labelWidth={200}
        />
        <InlineLabelValue label={"Département"} value={project.business_unit_name} bold={true} labelWidth={200} />
      </div>
    );
  };

  return (
    <div className="projectCard">
      <div className="bg-white p-6 shadow-md mb-6">
        <div className="text-3xl font-bold mb-4">Détails</div>
        <div className="flex gap-4">
          {projectBasicInfos()}
          {project.project_type === "recruitment_commission" && commission()}
          {project.project_type === "recruitment_commission_rate" && commissionRate()}
          {project.project_type === "recruitment_commission_third" && commissionThird()}
          {project.project_type === "product" && product()}
          {project.project_type === "subscription" && subscription()}
          {project.project_type === "rpo" && rpo()}
        </div>
        <Divider light />
        <ContactsList contacts={contacts} openMessenger={openMessenger} />
      </div>
      <BillAndBookingList
        bills={bills}
        fetchBills={fetchBills}
        bookings={bookings}
        fetchBookings={fetchBookings}
        edition
        customerId={project.customer_id}
        contractId={project.contract_id}
        projectId={project.id}
      />
    </div>
  );
};

export default ProjectCard;
