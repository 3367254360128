import dayjs from "dayjs";
import { DatePeriod } from "../models/dashboards";

const getThisWeek = () => ({
  from: dayjs().weekday(0).format("YYYY-MM-DD"),
  to: dayjs().weekday(6).format("YYYY-MM-DD"),
});

const getThisWeekCompare = () => {
  const data = getLastWeek();

  return {
    compare_from: data.from,
    compare_to: data.to,
  };
};

const getLastWeek = () => getLastNthWeek(1);

function getLastNthWeek(nth: number): { from: string; to: string } {
  return {
    from: dayjs().subtract(nth, "week").weekday(0).format("YYYY-MM-DD"),
    to: dayjs().subtract(nth, "week").weekday(6).format("YYYY-MM-DD"),
  };
}

const getLastWeekCompare = () => {
  const data = getLastNthWeek(2);

  return {
    compare_from: data.from,
    compare_to: data.to,
  };
};

const getThisMonth = () => ({
  from: dayjs().startOf("month").format("YYYY-MM-DD"),
  to: dayjs().endOf("month").format("YYYY-MM-DD"),
});

const getThisMonthCompare = () => {
  const data = getLastMonth();

  return {
    compare_from: data.from,
    compare_to: data.to,
  };
};

const getLastMonth = () => getLastNthMonth(1);

const getLastMonthCompare = () => {
  const data = getLastNthMonth(2);

  return {
    compare_from: data.from,
    compare_to: data.to,
  };
};

const getLastNthMonth = (nth: number) => ({
  from: dayjs().subtract(nth, "month").date(1).format("YYYY-MM-DD"),
  to: dayjs().subtract(nth, "month").endOf("month").format("YYYY-MM-DD"),
});

const getToday = () => ({
  from: dayjs().format("YYYY-MM-DD"),
  to: dayjs().format("YYYY-MM-DD"),
});

const getTodayCompare = () => ({
  compare_from: dayjs().subtract(1, "week").format("YYYY-MM-DD"),
  compare_to: dayjs().subtract(1, "week").format("YYYY-MM-DD"),
});

const getYesterday = () => ({
  from: dayjs().subtract(1, "day").format("YYYY-MM-DD"),
  to: dayjs().subtract(1, "day").format("YYYY-MM-DD"),
});

const getYesterdayCompare = () => ({
  compare_from: dayjs().subtract(1, "day").subtract(1, "week").format("YYYY-MM-DD"),
  compare_to: dayjs().subtract(1, "day").subtract(1, "week").format("YYYY-MM-DD"),
});

const getThisQuarter = () => ({
  from: dayjs().startOf("quarter").format("YYYY-MM-DD"),
  to: dayjs().endOf("quarter").format("YYYY-MM-DD"),
});

const getThisQuarterCompare = () => {
  const data = getLastQuarter();

  return {
    compare_from: data.from,
    compare_to: data.to,
  };
};

const getLastQuarter = () => getLastNthQuarter(1);

const getLastQuarterCompare = () => {
  const data = getLastNthQuarter(2);

  return {
    compare_from: data.from,
    compare_to: data.to,
  };
};

const getLastNthQuarter = (nth: number) => ({
  from: dayjs().subtract(nth, "quarter").startOf("quarter").format("YYYY-MM-DD"),
  to: dayjs().subtract(nth, "quarter").endOf("quarter").format("YYYY-MM-DD"),
});

const getThisYear = () => ({
  from: dayjs().startOf("year").format("YYYY-MM-DD"),
  to: dayjs().endOf("year").format("YYYY-MM-DD"),
});

const getThisYearCompare = () => {
  const data = getLastYear();

  return {
    compare_from: data.from,
    compare_to: data.to,
  };
};

const getLastYear = () => getLastNthYear(1);

const getLastNthYear = (nth: number) => ({
  from: dayjs().subtract(nth, "year").startOf("year").format("YYYY-MM-DD"),
  to: dayjs().subtract(nth, "year").endOf("year").format("YYYY-MM-DD"),
});

const getLastYearCompare = () => {
  const data = getLastNthYear(2);

  return {
    compare_from: data.from,
    compare_to: data.to,
  };
};

const getLast12Months = () => ({
  from: dayjs().subtract(1, "year").format("YYYY-MM-DD"),
  to: dayjs().format("YYYY-MM-DD"),
});

const getLast12MonthsCompare = () => ({
  compare_from: dayjs().subtract(2, "year").format("YYYY-MM-DD"),
  compare_to: dayjs().subtract(1, "year").format("YYYY-MM-DD"),
});

export function getPeriod(period?: DatePeriod): { from: string; to: string } {
  if (period === DatePeriod.Yesterday) return getYesterday();
  else if (period === DatePeriod.Today) return getToday();
  else if (period === DatePeriod.Week) return getThisWeek();
  else if (period === DatePeriod.LastWeek) return getLastWeek();
  else if (period === DatePeriod.Month) return getThisMonth();
  else if (period === DatePeriod.LastMonth) return getLastMonth();
  else if (period === DatePeriod.Quarter) return getThisQuarter();
  else if (period === DatePeriod.LastQuarter) return getLastQuarter();
  else if (period === DatePeriod.Year) return getThisYear();
  else if (period === DatePeriod.LastYear) return getLastYear();
  else if (period === DatePeriod.RollingYear) return getLast12Months();
  return getThisWeek();
}

export function getComparePeriod(period?: DatePeriod): {
  compare_from: string;
  compare_to: string;
} {
  if (period === DatePeriod.Yesterday) return getYesterdayCompare();
  else if (period === DatePeriod.Today) return getTodayCompare();
  else if (period === DatePeriod.Week) return getThisWeekCompare();
  else if (period === DatePeriod.LastWeek) return getLastWeekCompare();
  else if (period === DatePeriod.Month) return getThisMonthCompare();
  else if (period === DatePeriod.LastMonth) return getLastMonthCompare();
  else if (period === DatePeriod.Quarter) return getThisQuarterCompare();
  else if (period === DatePeriod.LastQuarter) return getLastQuarterCompare();
  else if (period === DatePeriod.Year) return getThisYearCompare();
  else if (period === DatePeriod.LastYear) return getLastYearCompare();
  else if (period === DatePeriod.RollingYear) return getLast12MonthsCompare();
  return getThisWeekCompare();
}
