import React, { FunctionComponent, useState, useEffect } from "react";
import { UserResponse } from "../../../../models/users/users";
import { UUID } from "../../../../models/common";
import { getUsers } from "../../../../models/users/users";
import { Select } from "@getprorecrutement/getpro-design";
import { Avatar } from "../../../userStatus";
import { UserIcon } from "@heroicons/react/24/outline";
import { useLoading } from "../../../../utils/loading";
import { MultipleValues, SingleValue } from "@getprorecrutement/getpro-design/dist/components/Form/Inputs/Select";

interface Props {
  users?: UserResponse[];
  onSelect: (s: UUID) => void;
  placeholder?: string;
  style?: React.CSSProperties;
  label?: string;
  showSearch?: boolean;
  size?: "small" | "medium" | "large";
  error?: string;
  showArrowIcon?: boolean;
  showIcon?: boolean;
  dropdownClassName?: string;
}

interface MultipleProps {
  multiple: true;
  value?: UUID[];
  customValueRender?: (value: UserResponse[]) => JSX.Element;
}

interface SingleProps {
  multiple?: false;
  value?: UUID;
  customValueRender?: (value: UserResponse) => JSX.Element;
}

export const UserSelect: FunctionComponent<Props & (MultipleProps | SingleProps)> = ({
  onSelect,
  placeholder,
  style,
  label,
  showSearch,
  size,
  value,
  error,
  showArrowIcon,
  showIcon = true,
  ...props
}) => {
  const [users, setUsers] = useState<UserResponse[]>(props.users || []);
  const [search, setSearch] = useState<string>("");
  const [displayOptions, setDisplayOptions] = useState<UserResponse[]>([]);
  const [loading, setLoading] = useState(false);
  const load = useLoading(setLoading);

  const fetchUsers = async () => {
    load(getUsers().then(setUsers));
  };

  useEffect(() => {
    if (props.users) setUsers(users);
    else fetchUsers();
  }, [props.users]);

  useEffect(() => {
    const [selected, nonSelected] = users
      .filter((c) => search.length === 0 || c.full_name?.toLowerCase()?.includes(search.toLowerCase()))
      .reduce(
        (acc, next) => {
          if (props.multiple) {
            acc[value?.indexOf(next.id) !== -1 ? 0 : 1].push(next);
          } else {
            acc[next.id === value ? 0 : 1].push(next);
          }
          return acc;
        },
        [[], []] as UserResponse[][]
      );

    setDisplayOptions(selected.concat(nonSelected));
  }, [users, search]);

  const selectProps: SingleValue<UserResponse> | MultipleValues<UserResponse> = props.multiple
    ? {
        type: "multiple",
        value: users.filter((c) => value?.includes(c.id)),
        customValueRender: props.customValueRender,
      }
    : { type: "single", value: users.find((c) => c.id === value), customValueRender: props.customValueRender };

  return (
    <span style={style} className="userSelect bg-inherit">
      <Select
        light
        bordered
        rounded
        icon={showIcon ? <UserIcon /> : undefined}
        loading={loading}
        // allowClear={multiple ? false : true}
        showArrowIcon={showArrowIcon}
        size={size}
        label={label}
        error={error}
        options={displayOptions}
        optionRender={(c) => (
          <div className="flex items-center gap-3">
            <Avatar size={size} user={c} />
            <div>
              {c.first_name[0]}. {c.last_name}
            </div>
          </div>
        )}
        getKey={(c) => c.id}
        onSearch={showSearch ? setSearch : undefined}
        // loading={loading}
        onChange={(v) => v && onSelect(v.id)}
        placeholder={placeholder || "Sélectionner un utilisateur"}
        dropdownClassName={props.dropdownClassName}
        {...selectProps}
      />
    </span>
  );
};

export default UserSelect;
