import classNames from "classnames";
import React, { FunctionComponent } from "react";
import { twMerge } from "tailwind-merge";

export interface Props {
  title?: string;
  position?: "start" | "center" | "end";
  type?: "vertical" | "horizontal";
  dark?: boolean;
  light?: boolean;
}

export const Divider: FunctionComponent<Props & React.HTMLAttributes<HTMLDivElement>> = ({
  title,
  position = "start",
  type = "horizontal",
  dark,
  light,
  ...props
}) => {
  const wrapperClasses = classNames("relative bg-inherit", {
    "w-full h-fit py-2": type === "horizontal",
    "h-full w-fit px-2": type === "vertical",
    dark: dark,
  });

  const classes = twMerge(
    classNames("bg-background-lighter", {
      "w-full h-0.5 my-2": type === "horizontal",
      "h-full mx-2 w-0.5": type === "vertical",
      "dark:bg-background-medium": !light,
      [`${props.className}`]: !!props.className,
    })
  );

  const titleClasses = classNames("absolute top-4 -translate-y-1/2 px-3 bg-inherit text-content-medium", {
    "left-6": position === "start",
    "left-1/2 -translate-x-1/2": position === "center",
    "right-6": position === "end",
  });

  return (
    <div className={wrapperClasses}>
      <div {...props} className={classes}></div>
      {title && type === "horizontal" && <div className={titleClasses}>{title}</div>}
    </div>
  );
};
