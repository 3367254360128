import React, { FunctionComponent, useEffect, useState } from "react";
import { UUID } from "../../../../models/common";
import {
  Category,
  CategoryType,
  getCategories,
  getCategoryTypes,
  groupCategories,
} from "../../../../models/categories";
import { Checkbox, TextInput } from "@getprorecrutement/getpro-design";
import { ChevronRightIcon, XMarkIcon } from "@heroicons/react/24/outline";

interface CategoriesSelect {
  onSelect: (s: UUID) => void;
  value?: UUID[];
}

export const CategoriesSelect: FunctionComponent<CategoriesSelect> = ({ onSelect, value }) => {
  const [categories, setCategories] = useState<Category[]>([]);
  const [categoryTypes, setCategoryTypes] = useState<CategoryType[]>([]);
  const [search, setSearch] = useState<string>("");
  const [opened, setOpened] = useState<boolean>(false);

  useEffect(() => {
    getCategories().then(setCategories);
    getCategoryTypes().then(setCategoryTypes);
  }, []);

  const groupedCategories = groupCategories(categories, categoryTypes);

  return (
    <div
      className="w-full relative"
      onClick={() => setOpened(true)}
      tabIndex={0}
      onBlur={(ev) => {
        if (!ev.currentTarget.contains(ev.relatedTarget)) setOpened(false);
      }}
    >
      <div className="px-7 py-[9px] border border-solid border-border-lighter hover:border-border-dark rounded-4xl w-full relative group">
        <label
          className={
            "absolute z-10 px-2 text-sm left-5 -top-2.5 font-normal bg-white group-hover:text-content-darker text-content-light"
          }
        >
          Catégories
        </label>
        {value && value.length > 0 ? (
          <div className="flex items-center gap-2 flex-wrap">
            {value.map((cId) => {
              const category = categories.find((c) => c.id === cId);

              return (
                <div
                  key={cId}
                  className="flex gap-2 items-center px-2 py-1 border border-solid border-border-lighter text-xs rounded-full"
                >
                  <div>{category?.name}</div>
                  <XMarkIcon
                    className="w-3 h-3 min-w-fit min-h-fit cursor-pointer stroke-2"
                    onClick={() => onSelect(cId)}
                  />
                </div>
              );
            })}
            <TextInput
              inputClassName="px-0 py-0"
              placeholder="Sélectionner des catégories"
              type="text"
              bordered={false}
              value={search}
              onChange={(e) => setSearch(e.currentTarget.value)}
            />
          </div>
        ) : (
          <TextInput
            inputClassName="px-0 py-0"
            placeholder="Sélectionnez des catégories"
            type="text"
            bordered={false}
            value={search}
            onChange={(e) => setSearch(e.currentTarget.value)}
          />
        )}
      </div>
      {opened && (
        <div className="w-full bg-background-bright mt-2 rounded-lg p-2 absolute top-full left-0 z-30 max-h-48 overflow-auto">
          {groupedCategories
            .map((v) => ({
              ...v,
              categories: v.categories.filter((c) => c.name.toLowerCase().includes(search.toLowerCase())),
            }))
            .filter((v) => v.categories.length > 0)
            .map((gc) => (
              <CategoryTypeElem
                key={`${gc.name}-${gc.kind}`}
                name={gc.name}
                categories={gc.categories}
                selected={value || []}
                setSelected={(id) => {
                  setSearch("");
                  onSelect(id);
                }}
              />
            ))}
        </div>
      )}
    </div>
  );
};

interface CategoryTypeElemProps {
  name: string;
  categories: Category[];
  selected: UUID[];
  setSelected: (value: UUID) => void;
}

export const CategoryTypeElem: FunctionComponent<CategoryTypeElemProps> = ({
  name,
  categories,
  selected,
  setSelected,
}) => {
  const [opened, setOpened] = useState<boolean>(false);

  return (
    <div>
      <div onClick={() => setOpened(!opened)} className="flex items-center gap-2 cursor-pointer">
        <ChevronRightIcon className={`w-3 h-3 min-w-fit stroke-2 transition-all ${opened ? "rotate-90" : ""}`} />
        <div>{name}</div>
      </div>
      {opened && (
        <div className="ml-4 my-1">
          {categories.map((c) => (
            <div key={c.id}>
              <Checkbox
                light
                label={<div className="whitespace-nowrap">{c.name}</div>}
                value={selected.includes(c.id)}
                onChange={() => setSelected(c.id)}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
