import React, { FunctionComponent, useState } from "react";
import { Category, CategoryType } from "../../../models/categories";
import { Button, TextInput, Radio, Select } from "@getprorecrutement/getpro-design";
import { useFormManager } from "../../../services/form";

interface Props {
  category?: Category;
  categories: Category[];
  categoryTypes: CategoryType[];
  onSubmit: (data: Category) => Promise<void>;
}

export const CategoryForm: FunctionComponent<Props> = (props) => {
  const [loading, setLoading] = useState(false);
  // const [values, setValues] = useState<Partial<Category>>(props.category || {});
  const form = useFormManager<Category>({
    defaultState: props.category || {},
    validations: {
      name: {
        required: true,
        message: "Merci de préciser le nom de la catégorie",
      },
      category_type_id: { required: true, message: "Merci de préciser le type de la catégorie" },
      index: { required: true, message: "Merci de préciser l'index de la catégorie" },
    },
  });

  const onSubmit = (data: Category) => {
    setLoading(true);
    props.onSubmit(data).then(() => setLoading(false));
  };

  const { categoryTypes } = props;

  return (
    <>
      <div className="text-2xl mb-2">{props.category ? `Éditer ${props.category.name}` : "Nouvelle catégorie"}</div>
      <div className="flex flex-col gap-4 bg-inherit py-2 pr-4 overflow-auto">
        <TextInput
          type="text"
          value={form.state.name}
          error={form.errors.name}
          onChange={(e) => form.onChange({ name: e.target.value })}
          placeholder="Nom de la catégorie"
          label="Nom"
          light
        />
        <Select
          light
          bordered
          rounded
          label="Type"
          type="single"
          value={categoryTypes.find((c) => c.id === form.state.category_type_id)}
          options={categoryTypes}
          optionRender={(item) => item.name}
          getKey={(item) => item.id}
          onChange={(val) => val && form.onChange({ category_type_id: val.id })}
        />
        <div className="flex gap-2 items-center">
          <div>Afficher dans les filters de la job board : </div>
          <Radio
            light
            options={[
              { label: "Oui", value: "1" },
              { label: "Non", value: "-1" },
            ]}
            optionType="button"
            value={form.state.index?.toString()}
            onChange={(val) => val && form.onChange({ index: parseInt(val) })}
          />
        </div>
        <div className="flex justify-end">
          <Button
            title="Enregister"
            light
            size="small"
            loading={loading}
            disabled={form.disabled}
            onClick={() => form.validate() && onSubmit(form.state as Category)}
          />
        </div>
      </div>
    </>
  );
};

export default CategoryForm;
