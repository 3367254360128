import React, { FunctionComponent, useState } from "react";
import {
  getProjects,
  ProjectResponse,
  ProjectsFilters,
} from "../../../models/billing/project";
import { Paginated, UUID } from "../../../models/common";
import ProjectList from "../../../components/billing/projects/projectList";
import { Customer } from "../../../models/customers";

interface Props {
  customer: Customer;
  id?: UUID;
}

export const CustomerProjectList: FunctionComponent<Props> = (props) => {
  const { customer, id } = props;
  const [projects, setProjects] = useState<Paginated<ProjectResponse>>();

  const fetchProjects = async (filters: ProjectsFilters) => {
    const projects = await getProjects({
      customer_id: customer.id,
      ...filters,
    });
    setProjects(projects);
  };

  return (
    <ProjectList
      projects={projects}
      customerId={customer.id}
      fetchProjects={fetchProjects}
      returnPath={`/billing/customers/${customer.id}`}
      id={id}
      edition
    />
  );
};

export default CustomerProjectList;
