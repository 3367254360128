import React, { useState } from "react";
import { FunctionComponent } from "react";
import dayjs from "dayjs";
import { Table, ColumnProps } from "@getprorecrutement/getpro-design";
import { ReportFullJobOffer } from "../../../models/customers";

const PER_PAGE = 10;

interface SummaryTableProps {
  job_offers: ReportFullJobOffer[];
}

export const SummaryTable: FunctionComponent<SummaryTableProps> = ({ job_offers }) => {
  const [page, setPage] = useState<number>(1);

  const columns: ColumnProps<ReportFullJobOffer>[] = [
    {
      title: "Nom du poste",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Jours",
      dataIndex: "id",
      key: "days",
      width: 70,
      render: (_val, record) => {
        const compare = record.archived_at ? dayjs(record.archived_at) : dayjs();
        const created_at = dayjs(record.created_at);
        return <div className="text-center h-10 flex items-center">{compare.diff(created_at, "day")}</div>;
      },
    },
    {
      title: "Contactés",
      dataIndex: "id",
      key: "contacted",
      width: 100,
      render: (_val, record) => {
        const status_summary = record.summary.statuses.find((val) => val.name === "Contacté");

        return <div style={{ textAlign: "center" }}>{status_summary?.candidates_count || 0}</div>;
      },
    },
    {
      title: "Postulé",
      dataIndex: "id",
      key: "applied",
      width: 100,
      render: (_val, record) => {
        const status_summary = record.summary.statuses.find((val) => val.name === "Postulé");

        return <div style={{ textAlign: "center" }}>{status_summary?.candidates_count || 0}</div>;
      },
    },
    {
      title: "Reçu par le client",
      dataIndex: "id",
      key: "received",
      width: 145,
      render: (_val, record) => {
        const status_summary = record.summary.statuses.find((val) => val.name === "Reçu par le client");

        return <div style={{ textAlign: "center" }}>{status_summary?.candidates_count || 0}</div>;
      },
    },
    {
      title: "Closing",
      dataIndex: "id",
      key: "closing",
      width: 100,
      render: (_val, record) => {
        const status_summary = record.summary.statuses.find((val) => val.name === "Closing");

        return <div style={{ textAlign: "center" }}>{status_summary?.candidates_count || 0}</div>;
      },
    },
    {
      title: "Embauché",
      dataIndex: "id",
      key: "hired",
      width: 110,
      render: (_val, record) => {
        const hired_summary = record.summary.statuses.find((val) => val.name === "Embauché");
        const validated_summary = record.summary.statuses.find((val) => val.name === "Validé");

        const count =
          (hired_summary ? hired_summary.candidates_count : 0) +
          (validated_summary ? validated_summary.candidates_count : 0);

        return <div style={{ textAlign: "center" }}>{count}</div>;
      },
    },
  ];

  const displayedJos = job_offers.filter((_, i) => i >= (page - 1) * PER_PAGE && i < page * PER_PAGE);

  return (
    <Table
      size="small"
      bordered
      pagination={{ per_page: PER_PAGE, page, onChange: setPage }}
      columns={columns}
      light
      dataSource={{ data: displayedJos, total: job_offers.length }}
    />
  );
};
