import httpClient from "../services/http/client";
import ROUTES from "../services/http/routes";
import { UUID } from "./common";

export interface Skill {
  id: UUID;
  name: string;
  validated: boolean;
}

export interface NewSkill {
  name: string;
  validated?: boolean;
}

export const getSuggestedSkills = (categoryId?: UUID, skills?: UUID[]): Promise<Skill[]> =>
  httpClient.req(ROUTES.SUGGEST_SKILLS(categoryId, skills));

export const fetchSkills = (params: { name?: string; ids?: UUID[] }): Promise<Skill[]> =>
  httpClient.req(ROUTES.FETCH_SKILLS(params));

export const createSkill = (payload: NewSkill): Promise<Skill> => httpClient.req(ROUTES.CREATE_SKILL(payload));

export const deleteUnvalidatedSkill = (id: UUID) => httpClient.req(ROUTES.DELETE_UNVALIDATED_SKILL(id));
