import React from "react";
import { FunctionComponent } from "react";
import dayjs from "dayjs";
import { Bar, BarChart, ResponsiveContainer, XAxis, YAxis, Tooltip, TooltipProps, LabelList } from "recharts";
import { BookingTimelineData } from ".";
import { PALETTE } from "../..";
import { getCustomTooltipDate } from ".";
import { euroDisplay } from "../../../../utils/formatters";
import { Radio, Spinner } from "@getprorecrutement/getpro-design";

export const formattedAmountLabel = (nb: number) => {
  return Math.abs(nb) < 1000
    ? nb
    : Math.abs(nb) > 100000
    ? `${(nb / 1000).toFixed(0)}K`
    : `${+(nb / 1000).toFixed(1)}K`;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const renderCustomizedLabel = (props: any) => {
  const { x, y, width, value } = props;
  const radius = 10;

  return (
    value && (
      <text
        className="text-xs"
        x={x + width / 2}
        y={y - radius}
        fill="#000"
        textAnchor="middle"
        dominantBaseline="middle"
      >
        {formattedAmountLabel(value)}
      </text>
    )
  );
};

interface Props {
  timeline?: BookingTimelineData[];
  setGranularity: (value: "month" | "quarter" | "year") => void;
  granularity: "month" | "quarter" | "year";
}

export const BookingsTimeline: FunctionComponent<Props> = (props) => {
  const { timeline, setGranularity, granularity } = props;

  const tl = timeline || [];
  const barSize = tl.length <= 2 ? 100 : undefined;
  const padding = tl.length > 0 && tl.length < 3 ? tl.length * 100 : undefined;
  const paddingProp = padding ? { left: padding, right: padding } : "gap";

  const CustomTooltip = ({ active, payload, label }: TooltipProps<number, string>) => {
    if (active && payload && payload.length) {
      return (
        <div className="DashboardCustomTooltip">
          {getCustomTooltipDate(label, granularity)}

          <div
            key={payload[0].dataKey}
            className="DashboardCustomTooltipData"
            style={{
              backgroundColor: PALETTE.RED[0],
            }}
          >
            <span className="DashboardCustomTooltipValue">{`${euroDisplay(payload[0].value)}`}</span>
          </div>
        </div>
      );
    }

    return null;
  };

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: 284,
      }}
    >
      {timeline ? (
        <div style={{ width: "100%" }}>
          <div className="mb-6 flex justify-between items-center">
            <Radio
              light
              optionType="button"
              options={[
                { label: "Mois", value: "month" },
                { label: "Trimestre", value: "quarter" },
                { label: "Année", value: "year" },
              ]}
              value={granularity}
              onChange={(v) => setGranularity(v as "month" | "quarter" | "year")}
            />
          </div>
          <ResponsiveContainer width="100%" height={200}>
            <BarChart
              margin={{ left: 0, right: 0 }}
              data={timeline.map((t: BookingTimelineData) => ({
                ...t,
                created_at: dayjs(t.created_at).valueOf(),
              }))}
            >
              <XAxis
                dataKey="created_at"
                scale="time"
                type="number"
                axisLine={false}
                tickLine={false}
                padding={paddingProp}
                domain={["auto", "auto"]}
                tickFormatter={(tick) => dayjs(tick).format("DD/MM/YY")}
              />
              <YAxis padding={{ top: 20, bottom: 0 }} axisLine={false} tickLine={false} />
              <Tooltip cursor={false} content={CustomTooltip} />
              <Bar barSize={barSize} dataKey="amount" stackId="a" fill={PALETTE.RED[0]}>
                <LabelList position="top" dataKey="amount" content={renderCustomizedLabel} />
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </div>
      ) : (
        <Spinner light />
      )}
    </div>
  );
};

export default BookingsTimeline;
