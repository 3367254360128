import { UUID } from "./common";
import httpClient from "../services/http/client";
import { notifyError } from "../services/requests";
import ROUTES from "../services/http/routes";

export enum GFileKind {
  Kickoff = "kickoff",
  ScoreCard = "score_card",
  Report = "report",
  Folder = "folder",
  Teaser = "teaser",
}

export enum GFileType {
  Document = "document",
  Presentation = "presentation",
  Spreadsheets = "spreadsheets",
}

const GFILE_KINDS = {
  [GFileKind.Kickoff]: "support de kickoff",
  [GFileKind.ScoreCard]: "grille de recherche",
  [GFileKind.Report]: "bilan de fin de mandat",
  [GFileKind.Folder]: "dossier",
  [GFileKind.Teaser]: "teaser",
};

export interface GFile {
  id: UUID;
  kind: GFileKind;
  gdrive_id: string;
  target_id: UUID;
  file_type?: GFileType;
}

export async function getJOGfiles(joId: UUID): Promise<GFile[]> {
  return httpClient.req(ROUTES.FETCH_JOB_OFFER_GFILES(joId), (err) =>
    notifyError(err || "Erreur lors de la récupération des documents liés a l'offre")
  );
}

export async function createJOGFile(joId: UUID, kind: GFileKind): Promise<GFile> {
  return httpClient.req(ROUTES.CREATE_JOB_OFFER_GFILES(joId, kind), (err) =>
    notifyError(err || `Erreur lors de la creation du/de la ${GFILE_KINDS[kind]}`)
  );
}
