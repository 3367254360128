import React, { useState } from "react";
import { FunctionComponent } from "react";
import { DashboardModule, getStatsContacted } from "../../../../models/dashboards";
import { ContactedData, GlobalFilters } from "../../../../models/dashboards";
import ContactedJobOffers from "./jobOffers";
import ContactedStats from "./stats";
import ContactedTimeline from "./timeline";
import ModuleTitle from "../../elements/title";
import { useDeepCompareEffect } from "react-use";
import { Divider, Checkbox } from "@getprorecrutement/getpro-design";
import { EnvelopeIcon, PhoneIcon } from "@heroicons/react/24/outline";
import LinkedinIcon from "../../../../utils/icons";

interface Props {
  filters: GlobalFilters;
  locked: boolean;
  dashboardSlug: string;
  mod: DashboardModule<{
    contact_types?: string[];
    show_offers?: boolean;
    granularity?: "day" | "week" | "month";
  }>;
  updateModule: (data: {
    dashboard_slug: string;
    module_key: string;
    filters: {
      contact_types?: string[];
      show_offers?: boolean;
      granularity?: "day" | "week" | "month";
    };
    title?: string;
  }) => Promise<void>;
}

export const ContactedModule: FunctionComponent<Props> = (props) => {
  const { filters, locked, dashboardSlug, mod, updateModule } = props;
  const [contactedData, setContactedData] = useState<{
    contacted_count: number;
    contacted_count_by_type: { [key: string]: number };
    contacted_count_by_job_offer: [string, number][];
    timeline: ContactedData[];
  }>();

  const setGranularity = async (value: "day" | "week" | "month") => {
    await updateModule({
      dashboard_slug: dashboardSlug,
      module_key: mod.key,
      filters: {
        granularity: value,
      },
    });
  };

  const fetchStatsContacted = async () => {
    const response = await getStatsContacted({
      ...filters.dateRange,
      granularity: mod.filters.granularity,
      users: filters.users,
      business_units: filters.businessUnits,
      job_offers: filters.jobOffers,
      customers: filters.customers,
      contact_types: mod.filters.contact_types || [],
    });

    const res = {
      ...response,
      contacted_count_by_type: response.contacted_count_by_type.reduce((o, data) => ({ ...o, [data[0]]: data[1] }), {}),
      timeline: response.timeline.map((v) => {
        return { ...v, total: v.email + v.inmail + v.phone };
      }),
    };
    setContactedData(res);
  };

  const setContactFilters = async (filter: string) => {
    const contact_types = !(mod.filters.contact_types || []).includes(filter)
      ? [...(mod.filters.contact_types || []), filter]
      : (mod.filters.contact_types || []).filter((f) => f !== filter);

    await updateModule({
      dashboard_slug: dashboardSlug,
      module_key: mod.key,
      filters: {
        contact_types,
      },
    });
  };

  const setShowJos = async () => {
    await updateModule({
      dashboard_slug: dashboardSlug,
      module_key: mod.key,
      filters: {
        show_offers: !mod.filters.show_offers,
      },
    });
  };

  useDeepCompareEffect(() => {
    fetchStatsContacted();
  }, [filters, mod.filters]);

  return (
    <div className="bg-white p-6 shadow-md mb-6">
      {!locked && (
        <div>
          <div className="flex items-center gap-6 flex-wrap">
            <Checkbox
              light
              value={(mod.filters.contact_types || []).includes("email")}
              onChange={() => setContactFilters("email")}
              label={
                <div className="flex gap-1 items-center w-full">
                  <EnvelopeIcon className="w-5 h-5" />
                  <div>E-mail</div>
                </div>
              }
            />
            <Checkbox
              light
              value={(mod.filters.contact_types || []).includes("phone")}
              onChange={() => setContactFilters("phone")}
              label={
                <div className="flex gap-1 items-center w-full">
                  <PhoneIcon className="w-5 h-5" />
                  <div>Téléphone</div>
                </div>
              }
            />
            <Checkbox
              light
              value={(mod.filters.contact_types || []).includes("inmail")}
              onChange={() => setContactFilters("inmail")}
              label={
                <div className="flex gap-1 items-center w-full">
                  <LinkedinIcon />
                  <div>InMail</div>
                </div>
              }
            />
          </div>
          <div className="mt-2">
            <Checkbox
              label="Afficher les offres"
              light
              value={mod.filters.show_offers || false}
              onChange={setShowJos}
            />
          </div>
          <Divider light />
        </div>
      )}
      <ModuleTitle
        title={mod.title}
        slug={dashboardSlug}
        modKey={mod.key}
        updateModule={updateModule}
        locked={locked}
      />
      <div className="flex items-center gap-2">
        <div className="w-[65%]">
          <ContactedTimeline
            timeline={contactedData?.timeline}
            setGranularity={setGranularity}
            granularity={mod.filters.granularity || "week"}
            showJos={mod.filters.show_offers || false}
            locked={locked}
          />
        </div>
        <div className="h-[200px]">
          <Divider type="vertical" light />
        </div>
        <div className="w-[35%]">
          <ContactedStats
            countByType={contactedData?.contacted_count_by_type || {}}
            totalCount={contactedData?.contacted_count || 0}
            showJos={mod.filters.show_offers || false}
            locked={locked}
          />
        </div>
      </div>
      {mod.filters.show_offers && (
        <div className="w-full">
          <Divider light />
          <ContactedJobOffers countByJobOffer={contactedData?.contacted_count_by_job_offer} />
        </div>
      )}
    </div>
  );
};

export default ContactedModule;
