import React, { FunctionComponent, useState } from "react";
import { UserResponse, UserData } from "../../models/users/users";
import HtmlEditor from "../../components/forms/inputs/htmlEditor";
import { getAccessToken } from "../../models/auth";
import { getGmailEmailSignature } from "../../services/requests";
import { Divider, Button } from "@getprorecrutement/getpro-design";
import { CloudArrowDownIcon } from "@heroicons/react/24/outline";

interface Props {
  user?: UserResponse;
  onSubmit: (data: UserData) => Promise<void>;
}

export const SignatureForm: FunctionComponent<Props> = (props) => {
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState<Partial<UserResponse>>({ email_signature: props.user?.email_signature });

  const onSubmit = (data: UserData) => {
    setLoading(true);
    props.onSubmit(data).then(() => setLoading(false));
  };

  const [currentSignature, setCurrentSignature] = useState<string | undefined>(props.user?.email_signature);

  const syncSignature = async () => {
    const data = await getAccessToken();
    const { signature } = await getGmailEmailSignature(props.user?.email as string, data.access_token);
    setCurrentSignature(signature);
    setValues({ ...values, email_signature: signature });
  };

  return (
    <div className="userForm flex flex-col gap-4 bg-white">
      <Divider title="Signature d'e-mail" light />
      <HtmlEditor
        onChange={(val) => setValues({ ...values, email_signature: val })}
        cleanInput
        content={currentSignature}
      />
      <div className="flex gap-2 justify-end">
        <Button light size="small" title="Synchroniser" onClick={syncSignature} icon={<CloudArrowDownIcon />} />
        <Button light size="small" title="Enregistrer" loading={loading} onClick={() => onSubmit(values as UserData)} />
      </div>
    </div>
  );
};

export default SignatureForm;
