import {
  ChevronDownIcon,
  ChevronUpIcon,
  CogIcon,
  DotsVerticalIcon,
  EmojiSadIcon,
  MinusCircleIcon,
  PlusCircleIcon,
} from "@heroicons/react/outline";
import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { twMerge } from "tailwind-merge";
import { PopOver } from "../PopOver";
import { ColorType } from "../../utils/common";
import { Checkbox, Select } from "../Form/Inputs";
import { Pagination } from "../Pagination";
import { Skeleton } from "../Skeleton";
import { Tooltip } from "../Tooltip";
import { SelectFilter } from "./SelectFilter";
import { SortableBtn, nextDirection } from "./SortableBtn";
import { TableRows } from "./TableRow";
import { TextInputDropDown } from "./TextInputDropDown";

export interface ColumnProps<T> {
  title: JSX.Element | string;
  dataIndex: keyof T;
  key: string;
  width?: number;
  sortable?: true | "asc" | "desc";
  filterBy?: "select" | "text";
  optionFilters?: {
    value: any;
    key: string;
    label: string | JSX.Element;
  }[];
  filterRenderer?: (value: any) => JSX.Element;
  render?: (value: any, record: T) => JSX.Element;
}

export interface Props<T> {
  dataSource: { data: T[]; total: number };
  columns: ColumnProps<T>[];
  loading?: boolean;
  bordered?: boolean;
  rowBordered?: boolean;
  filter?: {
    filters: { [key in keyof T]?: any[] | string };
    onFilterChange: (key: keyof T, value: any) => void;
  };
  sortBy?: { key: keyof T; sortDirection: "asc" | "desc" | undefined };
  onSortDirectionChange?: (key: keyof T, direction?: "asc" | "desc") => void;
  pagination?: { per_page?: number; page: number; onChange: (page: number) => void };
  selection?: {
    selected: any[];
    onCheck: (val: T) => void;
    onCheckAll: (select: boolean) => void;
    selectKey: keyof T;
  };
  extend?: { render?: (data: T) => JSX.Element | undefined; extendKey: keyof T };
  onClick?: (val: T) => void;
  colorType?: ColorType;
  dark?: boolean;
  light?: boolean;
  size?: "small" | "medium" | "large";
  rowClassName?: (data: T) => string;
  displayedColumns?: string[];
  displayedColsCallback?: (cols: string[]) => void;
}

export const Table = <T extends unknown>({
  dataSource,
  columns,
  loading = false,
  bordered = true,
  rowBordered = true,
  pagination,
  selection,
  extend,
  filter,
  sortBy,
  onSortDirectionChange,
  onClick,
  colorType = ColorType.Primary,
  dark,
  light,
  size = "medium",
  rowClassName,
  displayedColumns,
  displayedColsCallback,
}: Props<T>) => {
  const divRef = useRef<HTMLDivElement>(null);
  const [maxSize, setMaxSize] = useState<number>(0);
  const [loadingData, setLoadingData] = useState<T[]>([]);
  const [extended, setExtended] = useState<T[]>([]);
  const [displayedCols, setDisplayedCols] = useState<string[] | undefined>(displayedColumns);

  useEffect(() => {
    const loadingValue: any = {};

    columns.forEach((c) => {
      loadingValue[c.dataIndex] = "";
    });

    setLoadingData([...Array.from(Array(pagination?.per_page || 10)).map(() => loadingValue)]);
  }, []);

  /* CLASSES */
  const tableClasses = classNames("w-full relative", {
    dark: dark,
  });

  const rowClasses = (val: T) =>
    twMerge(
      classNames("flex justify-start items-stretch bg-white hover:bg-slate-50 last:rounded-b-2xl first:rounded-t-2xl", {
        "border-x first:border-t last:border-b border-solid px-2": bordered,
        "border-b border-solid": rowBordered,
        "dark:hover:bg-background-medium dark:border-border-medium dark:bg-background-dark": !light,
        "h-12": size === "small",
        "h-16": size === "medium",
        "h-20": size === "large",
        [`${rowClassName && rowClassName(val)}`]: rowClassName,
      })
    );

  const rowLabelClasses = classNames("flex justify-start items-center bg-inherit", {
    "px-2": bordered,
    // "dark:border-border-dark": bordered && !light,
  });

  const wrapperColLabelClasses = classNames("basis-full h-auto", {});

  const colLabelClasses = classNames(
    "py-3 px-2 w-full text-2xs font-bold text-content-darker flex h-full justify-left gap-2 items-center",
    {
      "dark:text-content-medium": !light,
    }
  );
  const colClasses = classNames("px-2 h-full flex items-center text-sm font-normal text-content-darker bg-inherit", {
    "dark:text-content-bright": !light,
    "justify-center": !loading,
    "justify-start": loading,
  });

  const extendIconsClasses = classNames("cursor-pointer w-full h-full text-primary-medium", {
    "dark:text-primary-dark": !light,
  });
  const paginationClasses = "py-4 flex justify-end";
  const colElemClasses = "w-full truncate";

  /* USEFULL FOR TOOLTIP WHEN ELLIPSIS ACTIF */
  useEffect(() => {
    getListSize();
    window.addEventListener("resize", getListSize);
  }, []);

  const getListSize = () => {
    const newWidth = divRef.current?.clientWidth;
    if (newWidth) {
      setMaxSize((newWidth - totalDefinedWidth) / columns.filter((e) => !e.width).length - 48);
    }
  };

  const cols = [
    /* SELECTION */
    ...(selection
      ? [
          {
            title: (
              <Checkbox
                light={light}
                colorType={colorType}
                value={selection.selected.length > 0}
                onChange={(val) => selection.onCheckAll(val)}
              />
            ),
            dataIndex: "",
            key: "select",
            width: 34,
            render: (val: any, record: T) => {
              const res = record[selection.selectKey];
              return (
                <div onClick={(e) => e.stopPropagation()}>
                  <Checkbox
                    light={light}
                    colorType={colorType}
                    value={selection.selected.includes(res)}
                    onChange={(val) => selection.onCheck(record)}
                  />
                </div>
              );
            },
          } as ColumnProps<T>,
        ]
      : []),
    /* EXTEND */
    ...(extend
      ? [
          {
            title: "",
            dataIndex: extend.extendKey,
            key: "extend",
            width: 32,
            render: (val: any, record: T) => {
              return extend?.render && extend?.render(record) ? (
                <div
                  className={extendIconsClasses}
                  onClick={(e) => {
                    e.stopPropagation();
                    setExtended(extended.includes(val) ? [...extended.filter((e) => e !== val)] : [...extended, val]);
                  }}
                >
                  {extended.includes(val) ? <ChevronUpIcon strokeWidth={1} /> : <ChevronDownIcon strokeWidth={1} />}
                </div>
              ) : (
                undefined
              );
            },
          } as ColumnProps<T>,
        ]
      : []),
    // FILTER COLUMNS
    ...columns.filter((c) => (displayedCols ? (displayedCols.includes(c.key) ? true : false) : true)),
  ];

  const totalDefinedWidth = cols.reduce((prev, next) => prev + (next.width || 0), 0);

  return (
    <div>
      <div ref={divRef} className={tableClasses}>
        {displayedCols && (
          <div className="absolute top-3 right-4 z-20">
            <PopOver
              light={light}
              position="bottom-left"
              content={
                <div>
                  {columns.map((val) => (
                    <Checkbox
                      className="whitespace-nowrap w-full cursor-pointer"
                      label={val.title}
                      value={displayedCols.includes(val.key)}
                      onChange={(e) => {
                        const newCols = e ? [...displayedCols, val.key] : displayedCols.filter((v) => v !== val.key);
                        setDisplayedCols(newCols);
                        displayedColsCallback && displayedColsCallback(newCols);
                      }}
                    />
                  ))}
                </div>
              }
            >
              <CogIcon width={20} height={20} className="text-content-darker" />
            </PopOver>
          </div>
        )}
        <div className={rowLabelClasses}>
          {cols.map((col) => (
            <div
              key={col.key}
              className={wrapperColLabelClasses}
              style={{
                maxWidth: col.width
                  ? col.width
                  : `calc(calc(100% - ${totalDefinedWidth}px) / ${columns.filter((e) => !e.width).length})`,
              }}
            >
              {col.optionFilters && col.filterBy === "select" && (
                <SelectFilter
                  colorType={colorType}
                  sortBy={sortBy}
                  onSortDirectionChange={onSortDirectionChange}
                  colLabelClasses={colLabelClasses}
                  col={col}
                  filter={filter}
                  light={light}
                />
              )}
              {col.filterBy === "text" && (
                <TextInputDropDown
                  onSortDirectionChange={onSortDirectionChange}
                  col={col}
                  filter={filter}
                  sortBy={sortBy}
                  currentValue={filter?.filters[col.dataIndex] as string}
                  light={light}
                />
              )}
              {!col.optionFilters && col.filterBy === undefined && (
                <div className={colLabelClasses}>
                  <div
                    className={`flex items-center gap-2 ${col.sortable ? "cursor-pointer" : ""}`}
                    onClick={() => {
                      col.sortable &&
                        onSortDirectionChange?.(
                          col.dataIndex,
                          nextDirection(sortBy?.key === col.dataIndex ? sortBy.sortDirection : undefined, col.sortable)
                        );
                    }}
                  >
                    {col.title}
                    {col.sortable && (
                      <SortableBtn
                        currentSort={sortBy?.key === col.dataIndex ? sortBy.sortDirection : undefined}
                        sortable={col.sortable}
                        onDirectionChange={(newDirection) => {
                          onSortDirectionChange?.(col.dataIndex, newDirection);
                        }}
                        light={light}
                      />
                    )}
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
        {/* extended.includes(val[extend.extendKey]) */}
        <TableRows
          cols={cols}
          columns={columns}
          colClasses={colClasses}
          columnClasses={"basis-full h-auto"}
          rowClasses={rowClasses}
          colElemClasses={colElemClasses}
          totalDefinedWidth={totalDefinedWidth}
          extend={extend}
          extended={extended}
          maxSize={maxSize}
          onClick={onClick}
          data={loading ? loadingData : dataSource.data}
          loading={loading}
          bordered={bordered}
          rowBordered={rowBordered}
          light={light}
        />
      </div>
      {pagination && (
        <div className={paginationClasses}>
          <Pagination
            page={pagination.page}
            pageSize={pagination.per_page}
            total={loading ? loadingData.length : dataSource.total}
            onChange={pagination.onChange}
            dark={dark}
            light={light}
          />
        </div>
      )}
    </div>
  );
};
