import httpClient from "../services/http/client";
import ROUTES, { PaginationParams } from "../services/http/routes";
import { notifyError } from "../services/requests";
import { CommentResponse } from "./comments";
import { UUID, Paginated, GeocodedLocation } from "./common";
import { CondensedJobApplication } from "./jobApplications";
import { File as FileModel } from "../models/file";
import { NewCandidateLocation, SimpleCandidateLocation } from "./candidateLocation";
import { CandidateFilters } from "../pages/candidates/filters";

export enum CandidateRGPDState {
  Accepted = "accepted",
  Denied = "denied",
  Pending = "pending",
}
export const RGPG_STATES = {
  [CandidateRGPDState.Accepted]: "Accepté",
  [CandidateRGPDState.Denied]: "Refusée",
  [CandidateRGPDState.Pending]: "Envoyée",
};

export interface SocialNetwork {
  type: string;
  value: string;
}

export interface Experience {
  company?: string;
  current?: boolean;
  end?: string;
  location?: string;
  start?: string;
  summary?: string;
  title?: string;
}

export interface Education {
  degree?: string[];
  domain?: string;
  end?: string;
  school?: string;
  start?: string;
}

interface CandidateDetails {
  educations: Education[];
  experiences: Experience[];
  locations?: string[];
  skills?: string[];
  summary?: string;
  title?: string;
}

export interface Blacklist {
  user_id: UUID;
  comment: string;
  blacklisted_at: string;
}

export interface RemotePolicy {
  full_remote: boolean;
  parcial_remote: boolean;
  no_remote: boolean;
}

export type SelectionInput = { kind: "selection"; id: UUID } | { kind: "raw_input"; input: string };

export interface JobTitleInputs {
  suggested_title_id?: UUID;
  candidate_selection?: SelectionInput;
  skills: SelectionInput[];
}

export interface Candidate extends Partial<NewCandidate> {
  id: UUID;
  created_at: string;
  updated_at: string;
  consentment_state?: CandidateRGPDState;
  consentment_updated_at?: string;
  blacklisted?: Blacklist;
  details: CandidateDetails;
  job_title_id?: UUID;
  skills: UUID[];
  job_title_inputs?: JobTitleInputs;
}

export interface NewCandidate {
  full_name?: string;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  picture_url?: string;
  details?: CandidateDetails;
  linkedin_url?: string;
  salary?: number;
  title?: string;
  first_job_at?: string;
  available_at?: string;
  location?: GeocodedLocation;
  file_ids?: UUID[];
}

export interface CandidateData extends Partial<NewCandidate> {
  blacklisted?: Blacklist;
  job_title_id?: UUID;
  skills?: UUID[];
  locations?: NewCandidateLocation[];
}

export interface QueriedCandidate {
  id: UUID;
  full_name?: string;
  email?: string;
  phone?: string;
}

export interface CandidateSearchQuery {
  id?: UUID;
  phone?: string;
  email?: string;
  name?: string;
  job_offers?: UUID[];
  include_archived?: boolean;
  include_terminated?: boolean;
  with_email?: boolean;
  with_phone?: boolean;
  with_consentment?: boolean;
  statuses?: UUID[];
  call_statuses?: UUID[];
  categories?: string[];
  users?: UUID[];
  customers?: UUID[];
  skills?: UUID[];
  page?: number;
  per_page?: number;
  selected_filters?: CandidateFilters[];
  job_titles?: UUID[];
  experience?: { min: number; max: number };
  location?: { location_id: number; lat: string; lon: string };
}

export interface SearchCandidateResponse {
  id: UUID;
  full_name?: string;
  first_name?: string;
  last_name?: string;
  blacklisted?: Blacklist;
  picture_url?: string;
  email?: string;
  phone?: string;
  linkedin_url?: string;
  job_title?: string;
  first_job_at?: string;
  consentment_state?: CandidateRGPDState;
  consentment_updated_at?: string;
  skills: string[];
  locations: SimpleCandidateLocation[];
  remote_policy?: RemotePolicy;
  updated_at: string;
}

export interface CandidateSearchSelector {
  all: boolean;
  selected?: UUID[];
  deselected?: UUID[];
}

export type CandidateAddToJoAction = {
  add_to_job_offer: {
    job_offer_id: UUID;
    status_id: UUID;
  };
};

export type CandidateAction = CandidateAddToJoAction;

export interface CandidateQueryAction {
  query: CandidateSearchQuery;
  selector: CandidateSearchSelector;
  action: CandidateAction;
}

export interface ClearCandidateData {
  salary?: boolean;
  location?: boolean;
  first_job_at?: boolean;
  email?: boolean;
  phone?: boolean;
}

export async function getCandidate(id: UUID): Promise<Candidate> {
  return httpClient.req(ROUTES.FETCH_CANDIDATE(id), (err) =>
    notifyError(err || "Erreur lors de la récupération du candidat")
  );
}

export async function getCandidateFiles(id: UUID): Promise<FileModel[]> {
  return httpClient.req(ROUTES.FETCH_CANDIDATE_FILES(id), (err) =>
    notifyError(err || "Erreur lors de la récupération des fichiers du candidat")
  );
}

export async function getCandidateJas(id: UUID): Promise<CondensedJobApplication[]> {
  return httpClient.req(ROUTES.FETCH_CANDIDATE_JOB_APPLICATIONS(id), (err) =>
    notifyError(err || "Erreur lors de la récupération des candidatures du candidat")
  );
}

export async function getCandidateComments(id: UUID): Promise<CommentResponse[]> {
  return httpClient.req(ROUTES.FETCH_COMMENTS(id), (err) =>
    notifyError(err || "Erreur lors de la récupération des commentaires du candidat")
  );
}

export async function addContactCandidate(data: { email?: string; phone?: string; id: UUID }): Promise<Candidate> {
  return httpClient.req(ROUTES.ADD_CONTACT(data), (err) =>
    notifyError(err || "Erreur lors de la modification du candidat")
  );
}

export async function delContactCandidate(data: { email?: string; phone?: string; id: UUID }): Promise<Candidate> {
  return httpClient.req(ROUTES.DELETE_CONTACT_CANDIDATE(data), (err) =>
    notifyError(err || "Erreur lors de la modification du candidat")
  );
}

export async function clearCandidate(id: UUID, data: ClearCandidateData): Promise<Candidate> {
  return httpClient.req(ROUTES.CLEAR_CANDIDATE(id, data), (err) => {
    notifyError(err || "Erreur lors de la modification du candidat");
  });
}

export async function actionCandidate(data: CandidateQueryAction): Promise<void> {
  return httpClient.req(ROUTES.CANDIDATE_ACTION(data), (err) =>
    notifyError(err || "Erreur lors de l'ajout à une offre")
  );
}

export async function searchCandidates(
  params: PaginationParams,
  query: CandidateSearchQuery
): Promise<Paginated<SearchCandidateResponse>> {
  return httpClient.req(ROUTES.SEARCH_CANDIDATES(params, query), (err) =>
    notifyError(err || "Erreur lors de la recherche des candidats")
  );
}

export async function updateCandidate(data: CandidateData & { id: UUID }): Promise<Candidate> {
  return httpClient.req(ROUTES.UPDATE_CANDIDATE(data), (err) =>
    notifyError(err || "Erreur lors de la mise à jour du candidat")
  );
}

export async function createCandidate(new_candidate: NewCandidate): Promise<Candidate> {
  return httpClient.req(ROUTES.CREATE_CANDIDATE(new_candidate), (err) =>
    notifyError(err || "Erreur lors de la création du candidat")
  );
}

export async function candidateUnblacklist(candidate_id: UUID): Promise<Candidate> {
  return httpClient.req(ROUTES.CANDIDATE_UNBLACKLIST(candidate_id), (err) =>
    notifyError(err || "Erreur lors de modification de la blacklist")
  );
}

export async function getCandidateConsentToken(candidate_id: UUID): Promise<{ token: string }> {
  return httpClient.req(ROUTES.FETCH_CANDIDATE_CONSENT_TOKEN(candidate_id), (err) =>
    notifyError(err || "Erreur lors de la récupération du lien RGPD")
  );
}
