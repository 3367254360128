import React, { FunctionComponent, useCallback, useEffect, useState } from "react";
import {
  convertTemporalityToUtc,
  getGranularity,
  getWeekday,
  Granularity,
  Signal,
  SignalTarget,
  SignalTargetResponse,
  Temporality,
} from "../../models/signals";
import { MoreActions, Tag, TagColor, Tooltip } from "@getprorecrutement/getpro-design";
import { PencilIcon, TrashIcon, ChevronRightIcon, EyeIcon, PlusIcon } from "@heroicons/react/24/outline";
import { UUID } from "../../models/common";
import { Customer } from "../../models/customers";
import dayjs from "dayjs";
import { Skill, fetchSkills } from "../../models/skill";
import { Category, fetchJobTitleCategories } from "../../models/jobTitleCategory";

export const SIGNAL_GRANULARITY: {
  [key: string]: string;
} = {
  day: "Signal Journalier",
  week: "Signal Hebdo",
  month: "Signal Mensuel",
};

export const WEEKDAY: {
  [key: string]: string;
} = {
  Mon: "lundi",
  Tue: "mardi",
  Wed: "mercredi",
  Thu: "jeudi",
  Fri: "vendredi",
  Sat: "samedi",
  Sun: "dimanche",
};

const formatTime = (at: [number, number]) => `${at[0]}h${at[1] < 10 ? "0" : ""}${at[1]}`;

const formatTemporality = (t: Temporality) => {
  const weekDay = getWeekday(t);
  const time = formatTime(t.at);

  if (!weekDay) return `Tous les jours à ${time}`;

  const granularity = getGranularity(t);

  return granularity === Granularity.Week
    ? `Tous les ${WEEKDAY[weekDay]}s à ${time}`
    : `Le premier ${WEEKDAY[weekDay]} du mois à ${time}`;
};

export interface SignalCategoryEdit {
  category: Category;
  skills: Skill[];
}

export interface SignalEdit {
  signal: Signal;
  categories: SignalCategoryEdit[];
}

interface Props {
  companySignals: { company: Customer; targets: SignalTargetResponse[] };
  setEditClient: (st: SignalTarget) => void;
  onDeleteSignalTarget: (id: UUID) => Promise<void>;
  setEditSignal: (signal: SignalEdit) => void;
  createNewSignal: (signalTargetId: UUID) => void;
  onDeleteSignal: (signalId: UUID) => Promise<void>;
  setPreviewSignal: (signal: Signal) => void;
}

export const CompanySignals: FunctionComponent<Props> = ({
  companySignals,
  setEditClient,
  onDeleteSignalTarget,
  setEditSignal,
  createNewSignal,
  onDeleteSignal,
  setPreviewSignal,
}) => {
  const [openedTargets, setOpenedTargets] = useState<UUID[]>([]);
  const [skills, setSkills] = useState<{ [key: UUID]: Skill }>();
  const [categories, setCategories] = useState<{ [key: UUID]: Category }>();

  const getSkills = useCallback(async () => {
    const skills_ids: UUID[] = companySignals.targets
      .map((companySignal) => companySignal.signals.map((s) => s.filters.categories.map((s) => s.skills).flat()).flat())
      .flat();
    const skills = await fetchSkills({ ids: skills_ids });
    setSkills(
      skills.reduce((acc, next) => {
        acc[next.id] = next;
        return acc;
      }, {} as { [key: UUID]: Skill })
    );
  }, [companySignals.targets]);

  const getCategories = useCallback(async () => {
    const categories_ids: UUID[] = companySignals.targets
      .map((companySignal) => companySignal.signals.map((s) => s.filters.categories.map((s) => s.category)).flat())
      .flat();
    const categories = await fetchJobTitleCategories({ ids: categories_ids });
    setCategories(
      categories.reduce((acc, next) => {
        acc[next.id] = next;
        return acc;
      }, {} as { [key: UUID]: Category })
    );
  }, [companySignals.targets]);

  useEffect(() => {
    setOpenedTargets([]);
    getSkills();
    getCategories();
  }, [companySignals.targets]);

  return (
    <div className="px-6 py-8 flex-1 flex flex-col items-center gap-4 overflow-hidden">
      <div className="flex flex-col items-center gap-1">
        <div className="min-w-fit min-h-fit w-20 h-20 rounded-full">
          <img
            className="max-w-full max-h-full rounded-full"
            src={`https://storage.googleapis.com/getpro-public/${
              companySignals.company.file_id || "e1ca4dfa-7173-40a1-8f9a-917dc3f3556f"
            }`}
          />
        </div>
        <div className="text-2xl font-bold">{companySignals.company.name}</div>
        <div className="text-content-light">{companySignals.targets.length} collaborateurs</div>
      </div>
      <div className="flex-1 flex flex-col gap-4 overflow-auto w-full">
        {companySignals.targets.map((st) => (
          <div key={st.id} className="w-full border border-solid border-border-bright rounded-3xl">
            <div
              className={`flex items-center gap-4 px-4 py-2 cursor-pointer ${
                openedTargets.includes(st.id) ? "border-b border-solid border-border-bright" : ""
              }`}
              onClick={() =>
                st.signals.length > 0 &&
                setOpenedTargets((v) => (v.includes(st.id) ? v.filter((t) => t !== st.id) : [...v, st.id]))
              }
            >
              <div className="flex items-center gap-1 text-content-darker rounded-3xl border border-solid border-border-bright px-2 py-0.5">
                <div className="font-bold pl-1">{st.signals.length}</div>
                <ChevronRightIcon
                  className={`w-3 h-3 min-w-fit stroke-2 transition-all ${
                    openedTargets.includes(st.id) ? "rotate-90" : ""
                  }`}
                />
              </div>
              <div className="flex-1">
                <div className="text-content-darker font-bold">
                  {st.first_name} {st.last_name}
                </div>
                <div className="text-sm text-content-light">{st.email}</div>
              </div>
              <div className="w-fit text-xs text-content-light">Depuis le {dayjs(st.created_at).format("ll")}</div>
              <MoreActions
                light
                actions={[
                  {
                    icon: <PlusIcon />,
                    title: "Ajouter un signal",
                    action: () => createNewSignal(st.id),
                  },
                  {
                    icon: <PencilIcon />,
                    title: "Editer",
                    action: () => setEditClient(st),
                  },
                  {
                    icon: <TrashIcon />,
                    title: "Supprimer",
                    action: async () => await onDeleteSignalTarget(st.id),
                    popConfirm: { show: true, title: "Voulez-vous supprimer le contact ?" },
                  },
                ]}
              />
            </div>
            {openedTargets.includes(st.id) &&
              st.signals.map((signal) => (
                <div key={signal.id} className="flex items-center gap-4 px-4 py-2">
                  <div
                    className="px-3.5 py-2 cursor-pointer"
                    onClick={() => setPreviewSignal({ ...signal, send_on: convertTemporalityToUtc(signal.send_on) })}
                  >
                    <EyeIcon className="w-5 h-5 min-w-fit text-content-light" />
                  </div>
                  <div className="w-60 min-w-fit">
                    <div className="text-content-darker font-bold flex gap-2 items-center">
                      <div>{SIGNAL_GRANULARITY[getGranularity(signal.send_on)]}</div>
                    </div>
                    <div className="text-content-light text-sm">{formatTemporality(signal.send_on)}</div>
                  </div>
                  <div className="flex-1 flex items-center gap-2">
                    {categories &&
                      signal.filters.categories.slice(0, 2).map((c) =>
                        categories[c.category] ? (
                          <div key={`category-${c.category}`}>
                            <Tag
                              title={categories[c.category].title}
                              value={categories[c.category].title}
                              light
                              type={TagColor.Primary}
                            />
                          </div>
                        ) : (
                          <></>
                        )
                      )}
                    {categories && signal.filters.categories.length > 2 && (
                      <Tooltip
                        light
                        position="bottom"
                        customRenderer={() => (
                          <div className="flex items-center gap-2">
                            {signal.filters.categories.slice(2).map((c) =>
                              categories[c.category] ? (
                                <div key={`category-${c.category}`}>
                                  <Tag
                                    title={categories[c.category].title}
                                    value={categories[c.category].title}
                                    light
                                    type={TagColor.Primary}
                                  />
                                </div>
                              ) : (
                                <></>
                              )
                            )}
                          </div>
                        )}
                      >
                        <div className="font-bold text-primary-regular">+{signal.filters.categories.length - 2}</div>
                      </Tooltip>
                    )}
                  </div>
                  <div className="w-fit text-xs text-content-light">
                    Prochain envoi le {dayjs(signal.next_run).format("ll")} à {formatTime(signal.send_on.at)}
                  </div>
                  <MoreActions
                    actions={[
                      {
                        icon: <PencilIcon />,
                        title: "Editer",
                        action: () =>
                          categories &&
                          skills &&
                          setEditSignal({
                            signal: signal,
                            categories: signal.filters.categories.map((c) => ({
                              category: categories[c.category],
                              skills: c.skills.map((id) => skills[id]),
                            })),
                          }),
                      },
                      {
                        icon: <TrashIcon />,
                        title: "Supprimer",
                        action: async () => await onDeleteSignal(signal.id),
                        popConfirm: { show: true, title: "Voulez-vous supprimer le signal ?" },
                      },
                    ]}
                    light
                  />
                </div>
              ))}
          </div>
        ))}
      </div>
    </div>
  );
};
