import React, { FunctionComponent } from "react";
import { useState } from "react";
import StatusSelect from "../../components/forms/inputs/statusSelect";
import { CandidateSearchQuery, CandidateAction, actionCandidate } from "../../models/candidate";
import JobOfferSelect from "../../components/forms/inputs/jobOfferSelect";
import { UUID } from "../../models/common";
import { toast } from "react-hot-toast";
import { Button } from "@getprorecrutement/getpro-design";

interface Props {
  selected: UUID[];
  query: CandidateSearchQuery;
}

interface AddToJoData {
  joId?: UUID;
  statusId?: UUID;
}

interface CurrentAction {
  action?: "addToJo";
  data?: AddToJoData;
}

export const CandidatesActions: FunctionComponent<Props> = (props) => {
  const [currentAction, setCurrentAction] = useState<CurrentAction>({});
  const [loading, setLoading] = useState(false);

  const submit = async (action: CandidateAction) => {
    setLoading(true);
    await actionCandidate({
      action,
      query: props.query,
      selector: { all: false, deselected: [], selected: props.selected },
    }).finally(() => {
      setLoading(false);
      setCurrentAction({});
    });

    toast.success("Vous recevrez un mail une fois l'import terminé");
  };

  const { joId, statusId } = currentAction?.data || {};
  const addToJo = currentAction.action === "addToJo";

  return (
    <div className="flex items-center gap-2 bg-white">
      {!currentAction.action && (
        <Button
          onClick={() => setCurrentAction({ action: "addToJo" })}
          title="Ajouter à une offre"
          light
          size="small"
        />
      )}
      {addToJo && (
        <JobOfferSelect
          size="small"
          showArrowIcon
          onSelect={(joId) =>
            setCurrentAction((ca) => ({
              ...ca,
              data: { ...(ca.data || {}), joId },
            }))
          }
          value={currentAction.data?.joId}
          placeholder="Offre"
          style={{ width: 250 }}
        />
      )}
      {addToJo && (
        <StatusSelect
          size="small"
          onSelect={(statusId) =>
            setCurrentAction((ca) => ({
              ...ca,
              data: { ...(ca.data || {}), statusId },
            }))
          }
          value={statusId}
          placeholder="Status"
          style={{ width: 200 }}
        />
      )}
      {addToJo && joId && statusId && (
        <Button
          size="small"
          loading={loading}
          onClick={() =>
            submit({
              add_to_job_offer: { status_id: statusId, job_offer_id: joId },
            })
          }
          title="Confirmer"
          light
        />
      )}
    </div>
  );
};

export default CandidatesActions;
