import React, { useEffect, useState } from "react";
import {
  createGlobalDashboard,
  deleteGlobalDashboard,
  getGlobalDashboards,
  GlobalDashboard,
  updateGlobalDashboard,
  UserDashboard,
} from "../../../models/dashboards";
import Dashboard from "../../../components/dashboard/dashboard";
import { UUID } from "../../../models/common";
import BusinessUnitSelect from "../../../components/forms/inputs/businessUnitSelect";
import { slugify } from "../../../utils/formatters";
import { toast } from "react-hot-toast";
import { Modal, TextInput, Button } from "@getprorecrutement/getpro-design";
import { PencilIcon, PlusIcon, ArrowLeftIcon } from "@heroicons/react/24/outline";

export const SettingsDashboards = () => {
  const [globalDashboards, setGlobalDashboards] = useState<GlobalDashboard[]>([]);
  const [currentDashboard, setCurrentDashboard] = useState<GlobalDashboard | undefined>(undefined);
  const [dashboardEdit, setDashboardEdit] = useState<Partial<GlobalDashboard> | undefined>();

  const fetchGlobalDashboards = async () => {
    const globalDashboards = await getGlobalDashboards();
    setGlobalDashboards(globalDashboards);
  };

  const removeDashboard = async (id: UUID) => {
    await deleteGlobalDashboard(id);
    setCurrentDashboard(undefined);
  };

  const createDashboard = async () => {
    const payload = {
      name: dashboardEdit?.name || "Nouveau Dashboard",
      business_units_ids: dashboardEdit?.business_units_ids || [],
      modules: [],
      filters: {},
      slug: slugify(`global-${dashboardEdit?.name}`),
    };
    const res = await createGlobalDashboard(payload);
    setDashboardEdit(undefined);
    setCurrentDashboard(res);
  };

  const updateDashboard = async (
    data: UserDashboard | GlobalDashboard | Partial<GlobalDashboard>,
    navigateTo?: boolean
  ) => {
    const res = await updateGlobalDashboard(data);
    navigateTo && setCurrentDashboard(res);
    setDashboardEdit(undefined);
    fetchGlobalDashboards();
    toast.success("Paramètres du dashboard global mis à jour");
  };

  useEffect(() => {
    fetchGlobalDashboards();
  }, []);

  return (
    <div>
      <div className="bg-white p-6 flex items-center gap-4 shadow-md mb-6">
        {currentDashboard && (
          <ArrowLeftIcon className="cursor-pointer w-5" onClick={() => setCurrentDashboard(undefined)} />
        )}
        <div className="text-3xl font-bold">Dashboards globaux</div>
      </div>
      {currentDashboard ? (
        <Dashboard
          dashboard={currentDashboard}
          backLink="/settings/dashboards"
          remove={removeDashboard}
          update={(data) => updateDashboard(data, true)}
        />
      ) : (
        <div className="flex gap-4 flex-wrap px-6">
          <div
            className="cursor-pointer p-6 bg-slate-200 shadow-md min-w-[200px]"
            onClick={() => {
              setDashboardEdit({ name: "", business_units_ids: [] });
            }}
          >
            <div className="flex items-center gap-6">
              <PlusIcon className="w-5" />
              <div>Nouveau Dashboard</div>
            </div>
          </div>
          {globalDashboards.map((d) => (
            <div
              className="cursor-pointer p-6 bg-slate-200 shadow-md min-w-[200px]"
              onClick={() => setCurrentDashboard(d)}
              key={d.id}
            >
              <div className="flex items-center gap-6 justify-between">
                <div>{d.name}</div>
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    setDashboardEdit(d);
                  }}
                >
                  <PencilIcon className="w-5" />
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
      <Modal light className="w-modal-md" show={!!dashboardEdit} onClose={() => setDashboardEdit(undefined)}>
        <div className="text-2xl mb-2">{dashboardEdit?.id ? "Éditer le dashboard" : "Nouveau dashboard"}</div>
        <div key={dashboardEdit?.id || "newDashboard"} className="bg-inherit py-2 pr-4 overflow-auto">
          <TextInput
            type="text"
            value={dashboardEdit?.name}
            onChange={(e) =>
              setDashboardEdit((d) => ({
                ...d,
                name: e.target.value,
                business_units_ids: d?.business_units_ids || [],
              }))
            }
            light
            placeholder="Nom du dashboard"
            label="Nom"
          />
          <div className="bg-white my-4">
            <BusinessUnitSelect
              value={dashboardEdit?.business_units_ids}
              onSelect={(buId) => {
                dashboardEdit?.business_units_ids?.includes(buId)
                  ? setDashboardEdit((d) => ({
                      ...d,
                      name: d?.name || "",
                      business_units_ids: (d?.business_units_ids || []).filter((id) => id !== buId),
                    }))
                  : setDashboardEdit((d) => ({
                      ...d,
                      name: d?.name || "",
                      business_units_ids: [...(d?.business_units_ids || []), buId],
                    }));
              }}
              multiple
              label="Département(s)"
            />
          </div>
          <div className="flex justify-end">
            <Button
              size="small"
              title="Enregistrer"
              light
              disabled={
                !(dashboardEdit?.business_units_ids?.length && dashboardEdit?.business_units_ids?.length > 0) ||
                !dashboardEdit?.name
              }
              onClick={() => (dashboardEdit?.id ? updateDashboard(dashboardEdit) : createDashboard())}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default SettingsDashboards;
