import { Spinner } from "@getprorecrutement/getpro-design";
import React from "react";
import { FunctionComponent } from "react";
import { Bar, BarChart, ResponsiveContainer, XAxis, YAxis, Tooltip, TooltipProps, LabelList } from "recharts";
import { BookingPerUserData } from ".";
import { PALETTE } from "../..";
import { euroDisplay } from "../../../../utils/formatters";
import { renderCustomizedLabel } from "./timeline";

interface Props {
  bookings?: BookingPerUserData[];
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const CustomizedAxisTick = (props: any) => {
  const { x, y, payload } = props;

  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={4} textAnchor="end" fill="#666" transform="rotate(-90)">
        {payload.value.split(" ")[0]}
      </text>
    </g>
  );
};

export const BookingPerUser: FunctionComponent<Props> = (props) => {
  const { bookings } = props;

  const tl = bookings || [];
  const barSize = tl.length <= 2 ? 100 : undefined;

  const CustomTooltip = ({ active, payload, label }: TooltipProps<number, string>) => {
    if (active && payload && payload.length) {
      return (
        <div className="DashboardCustomTooltip">
          <div style={{ marginBottom: 12, fontWeight: "bold", textAlign: "center" }}>{label}</div>

          <div
            key={payload[0].dataKey}
            className="DashboardCustomTooltipData"
            style={{
              backgroundColor: PALETTE.RED[0],
            }}
          >
            <span className="DashboardCustomTooltipValue">{`${euroDisplay(payload[0].value)}`}</span>
          </div>
        </div>
      );
    }

    return null;
  };

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: 284,
      }}
    >
      {bookings ? (
        <div className="w-full">
          <ResponsiveContainer width="100%" height={300}>
            <BarChart margin={{ left: 0, right: 0 }} data={bookings}>
              <XAxis
                dataKey="name"
                tick={<CustomizedAxisTick />}
                axisLine={false}
                tickLine={false}
                interval={0}
                height={100}
                domain={["auto", "auto"]}
              />
              <YAxis padding={{ top: 20, bottom: 0 }} axisLine={false} tickLine={false} />
              <Tooltip cursor={false} content={CustomTooltip} />
              <Bar barSize={barSize} dataKey="amount" stackId="a" fill={PALETTE.RED[0]}>
                <LabelList position="top" dataKey="amount" content={renderCustomizedLabel} />
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </div>
      ) : (
        <Spinner light />
      )}
    </div>
  );
};

export default BookingPerUser;
