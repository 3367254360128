import React, { useEffect, useState } from "react";
import { UUID } from "../../../models/common";
import { Contact } from "../../../models/contacts";
import ContactForm from "./contactForm";
import { Button, Select } from "@getprorecrutement/getpro-design";
import { PlusIcon } from "@heroicons/react/24/outline";
import { MultipleValues, SingleValue } from "@getprorecrutement/getpro-design/dist/components/Form/Inputs/Select";

interface Props {
  customerId: UUID;
  value?: UUID | UUID[];
  onSelect: (id: UUID) => void;
  onDeselect?: (id: UUID) => void;
  multiple?: boolean;
  creation?: boolean;
  size?: "small" | "medium" | "large";
  contacts: Contact[];
  fetchContacts: (id: UUID) => void;
  label?: string;
}

export const ContactSelect = (props: Props) => {
  const { customerId, onSelect, onDeselect, multiple, creation, contacts, fetchContacts } = props;
  const [value, setValue] = useState<UUID | UUID[] | undefined>(props.value);
  const [create, setCreate] = useState<boolean>(false);

  const createContact = () => {
    setCreate(true);
  };

  const onFinish = (contact: Contact) => {
    setCreate(false);
    fetchContacts(customerId);
    toggleValue(contact.id);
  };

  const toggleValue = (id: UUID | "create") => {
    if (multiple) {
      let save = value ? [...value] : [];
      if (save.length > 0 && save.includes(id)) {
        save = save.filter((e) => e !== id);
        onDeselect && onDeselect(id);
      } else {
        save = [...save, id];
        onSelect(id);
      }
      setValue(save);
    } else {
      setValue(id);
      onSelect(id);
    }
  };

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  const selectProps: SingleValue<Contact> | MultipleValues<Contact> = multiple
    ? { type: "multiple", value: contacts.filter((c) => value?.includes(c.id)) }
    : { type: "single", value: contacts.find((c) => value === c.id) };

  return (
    <div className="contactSelect bg-inherit">
      <div className="flex gap-2 items-center bg-inherit">
        <div className="flex-1 bg-inherit">
          <Select
            light
            rounded
            bordered
            size={props.size}
            label={props.label || `Contact${multiple ? "s" : ""}`}
            options={contacts}
            optionRender={(item) => `${item.first_name} ${item.last_name}`}
            getKey={(item) => item.id}
            onChange={(e) => e && toggleValue(e.id)}
            dropdownRender={(menu) =>
              creation ? (
                <div>
                  {menu}
                  <div
                    className={`flex justify-center items-center p-2 ${
                      create ? "border-t border-solid border-border-lighter" : ""
                    }`}
                  >
                    {create ? (
                      <ContactForm customerId={customerId} onFinish={onFinish} onCancel={() => setCreate(false)} />
                    ) : (
                      <Button icon={<PlusIcon />} title="Créer un contact" light size="small" onClick={createContact} />
                    )}
                  </div>
                </div>
              ) : (
                <div>{menu}</div>
              )
            }
            {...selectProps}
            // onSearch={undefined}
          />
        </div>
      </div>
    </div>
  );
};

export default ContactSelect;
