import httpClient from "../services/http/client";
import ROUTES from "../services/http/routes";
import { notifyError } from "../services/requests";
import store from "../services/store";
import { UUID } from "./common";

export interface BusinessUnit extends NewBusinessUnit {
  id: UUID;
  created_at: string;
  updated_at: string;
}

export interface NewBusinessUnit {
  name: string;
}

export interface BusinessUnitData extends NewBusinessUnit {
  id: UUID;
}

export async function getBusinessUnits(): Promise<BusinessUnit[]> {
  return store.cached("businessUnits", 60 * 30, () =>
    httpClient.req(ROUTES.FETCH_BUSINESS_UNITS(), () => notifyError("Erreur lors de la récupération des départements"))
  );
}

export async function createBusinessUnit(new_bu: NewBusinessUnit): Promise<BusinessUnit> {
  store.invalidateCache(["businessUnits"]);
  return httpClient.req(ROUTES.CREATE_BUSINESS_UNIT(new_bu), () =>
    notifyError("Erreur lors de la création du département")
  );
}

export async function updateBusinessUnit(data: BusinessUnitData): Promise<BusinessUnit> {
  store.invalidateCache(["businessUnits"]);
  return httpClient.req(ROUTES.UPDATE_BUSINESS_UNIT(data), () =>
    notifyError("Erreur lors de la modification du département")
  );
}

export async function deleteBusinessUnit(id: UUID): Promise<void> {
  store.invalidateCache(["businessUnits"]);
  return httpClient.req(ROUTES.DELETE_BUSINESS_UNIT(id), () =>
    notifyError("Erreur lors de la suppression du département")
  ); // "Le département n'a pas été supprimé, merci de vérifier qu'il n'est pas référencé par un utilisateur"
}
