import React, { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import { Button } from "@getprorecrutement/getpro-design";

const Notfound: FunctionComponent = () => {
  return (
    <div className="flex flex-col justify-center items-center gap-4 my-20">
      <ExclamationCircleIcon className="w-48 h-48" />
      <div className="text-2xl text-center">Désolé mais la page recherchée n'existe pas</div>
      <Link className="mt-4" to="/">
        <Button title="Retour" />
      </Link>
    </div>
  );
};

export default Notfound;
