import React, { FunctionComponent, useEffect, useState } from "react";
import {
  convertTemporalityToLocal,
  createSignal,
  createSignalTarget,
  deleteSignal,
  deleteSignalTarget,
  getSignalTargets,
  NewSignal,
  NewSignalTarget,
  Signal,
  SignalTarget,
  SignalTargetResponse,
  UpdateSignal,
  updateSignal,
  updateSignalTarget,
  UpdateSignalTarget,
} from "../../models/signals";
import { Modal, TextInput } from "@getprorecrutement/getpro-design";
import { PlusIcon } from "@heroicons/react/24/outline";
import { SignalClientForm } from "./signalClientForm";
import { SignalForm } from "./signalForm";
import { UUID } from "../../models/common";
import { Customer, getCustomers } from "../../models/customers";
import { toast } from "react-hot-toast";
import { SignalPreview } from "./signalPreview";
import { CompanySignals, SignalEdit } from "./companySignals";
import { CompaniesList } from "./companiesList";

export const SignalsPage: FunctionComponent = () => {
  const [newClient, setNewClient] = useState<boolean>(false);
  const [editClient, setEditClient] = useState<SignalTarget>();
  const [newSignal, setNewSignal] = useState<UUID>();
  const [editSignal, setEditSignal] = useState<SignalEdit>();
  const [companies, setCompanies] = useState<Customer[]>([]);
  const [search, setSearch] = useState<string>();
  const [groupedSignalTargets, setGroupedSignalTargets] = useState<
    {
      company: Customer;
      targets: SignalTargetResponse[];
    }[]
  >();
  const [selectedCompany, _setSelectedCompany] = useState<{ company: Customer; targets: SignalTargetResponse[] }>();
  const [previewSignal, setPreviewSignal] = useState<Signal>();

  const setSelectedCompany = (val: { company: Customer; targets: SignalTargetResponse[] }) => {
    _setSelectedCompany(val);
    if (newClient) setNewClient(false);
    if (editClient) setEditClient(undefined);
  };

  const fetchData = async () => {
    const signalTargets = await getSignalTargets();
    const companies = await getCustomers();
    // convert send_on to locale
    signalTargets
      .map((signalTarget) => signalTarget.signals)
      .flat()
      .forEach((s) => {
        s.send_on = convertTemporalityToLocal(s.send_on);
      });

    const res = signalTargets
      .reduce(
        (
          res: {
            company: Customer;
            targets: SignalTargetResponse[];
          }[],
          data
        ) => {
          const current = res.find((r) => r.company.id === data.customer_id);
          const company = current ? current.company : companies.find((c) => c.id === data.customer_id);
          res = current
            ? [
                ...res.filter((r) => r.company.id !== data.customer_id),
                { company: current.company, targets: [...current.targets, data] },
              ]
            : company
            ? [...res, { company, targets: [data] }]
            : res;

          return res;
        },
        []
      )
      .sort((a, b) => b.targets.length - a.targets.length);

    setGroupedSignalTargets(res);
    if (selectedCompany) {
      const newValue = res.find((v) => v.company.id === selectedCompany.company.id);
      if (newValue) _setSelectedCompany(newValue);
      else _setSelectedCompany(res[0]);
    } else {
      _setSelectedCompany(res[0]);
    }
    setCompanies(companies);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const onRefresh = () => {
    fetchData();
    setNewClient(false);
    setEditClient(undefined);
    setEditSignal(undefined);
    setNewSignal(undefined);
  };

  const finish = (msg: string) => {
    toast.success(msg);
    onRefresh();
  };

  const onRemoveSignalTarget = async (id: UUID) => {
    await deleteSignalTarget(id);
    finish("Contact signal supprimé avec succès");
  };

  const onRemoveSignal = async (id: UUID) => {
    await deleteSignal(id);
    finish("Signal supprimé avec succès");
  };

  const onSubmitSignal = async (data: Partial<Signal>) => {
    if (data.send_on && data.filters && (data.id || data.target_id)) {
      if (data.id)
        updateSignal(data as UpdateSignal & { id: UUID }).then(() => finish("Signal mis à jour avec succès"));
      else if (data.target_id) createSignal(data as NewSignal).then(() => finish("Signal créé avec succès"));
    }
  };

  const onSubmitSignalTarget = async (targetData: Partial<SignalTarget>, signalData?: Partial<Signal>) => {
    try {
      if (targetData.first_name && targetData.last_name && targetData.email && targetData.customer_id) {
        if (targetData.id) await updateSignalTarget({ ...(targetData as UpdateSignalTarget), id: targetData.id });
        else {
          const newTarget = await createSignalTarget(targetData as NewSignalTarget);
          if (signalData && signalData.send_on && signalData.filters) {
            await createSignal({ ...signalData, target_id: newTarget.id } as NewSignal);
          }
        }
      }
    } finally {
      finish(targetData.id ? "Contact du signal mis à jour avec succès" : "Contact du signal créé avec succès");
    }
  };

  return (
    <div className="h-screen">
      <div className="flex h-full">
        <div className="w-80 py-8 h-full border-r border-solid border-border-bright flex flex-col gap-8 overflow-hidden">
          <div className="flex items-center justify-between px-6">
            <div className="text-2xl font-bold">Signal</div>
            <div
              className="w-8 h-8 rounded-full bg-primary-medium text-content-bright cursor-pointer flex items-center justify-center"
              onClick={() => setNewClient(true)}
            >
              <PlusIcon width={20} height={20} />
            </div>
          </div>
          <div className="px-6">
            <TextInput
              light
              value={search}
              onChange={(e) => setSearch(e.currentTarget.value)}
              type="text"
              placeholder="Recherche par entreprise"
            />
          </div>
          <CompaniesList
            companies={groupedSignalTargets}
            setSelectedCompany={setSelectedCompany}
            selectedCompanyId={selectedCompany?.company.id}
            search={search}
          />
        </div>
        {newClient || !!editClient ? (
          <SignalClientForm onSubmit={onSubmitSignalTarget} companies={companies} signalTarget={editClient} />
        ) : (
          selectedCompany && (
            <CompanySignals
              companySignals={selectedCompany}
              setEditClient={setEditClient}
              onDeleteSignalTarget={onRemoveSignalTarget}
              setEditSignal={setEditSignal}
              createNewSignal={setNewSignal}
              onDeleteSignal={onRemoveSignal}
              setPreviewSignal={setPreviewSignal}
            />
          )
        )}
      </div>
      <Modal
        light
        show={!!newSignal || !!editSignal}
        onClose={() => {
          setNewSignal(undefined);
          setEditSignal(undefined);
        }}
        className="w-modal-lg"
      >
        <SignalForm onSubmit={onSubmitSignal} newSignalTargetId={newSignal} signal={editSignal} />
      </Modal>
      <Modal className="w-modal-lg" light show={!!previewSignal} onClose={() => setPreviewSignal(undefined)}>
        {previewSignal && (
          <div className="pr-4 overflow-auto">
            <SignalPreview signal={previewSignal} />
          </div>
        )}
      </Modal>
    </div>
  );
};
