import httpClient from "../services/http/client";
import ROUTES from "../services/http/routes";
import { UUID } from "./common";

export enum NotificationKind {
  Assignation = "job_offer_assignation",
  Text = "text",
  IncomingCandidates = "incoming_candidates",
  ConfirmedCandidate = "confirmed_candidate",
  CandidateMilestoneClosed = "candidate_milestone_closed",
  Mentioned = "mentioned",
  CandidateMilestoneFirstDay = "candidate_milestone_first_day",
  CandidateMilestoneTentDays = "candidate_milestone_ten_days",
  CandidateMilestoneFortyDays = "candidate_milestone_forty_days",
  CandidateMilestoneThreeMonths = "candidate_milestone_three_months",
  CandidateMilestoneSixMonths = "candidate_milestone_six_months",
  CandidateMilestoneValidated = "candidate_milestone_validated",
  CandidateMilestoneLost = "candidate_milestone_lost",
}

export interface ConfirmedCandidatePayload {
  owner: string;
  owner_email_address: string;
  candidate: string;
  jo_name: string;
  jo_id: UUID;
}

export interface ClosedCandidatePayload {
  owner: string;
  candidate: string;
  jo_name: string;
  jo_id: UUID;
}

export interface MentionedPayload {
  mentioner: string;
  candidate: string;
  candidate_id: UUID;
  jo_name?: string;
  jo_id?: UUID;
}

export interface Notification {
  id: UUID;
  target_id?: UUID;
  from_id?: UUID;
  to_id?: UUID;
  notification: boolean;
  read_at?: string;
  user_id: UUID;
  value?: string;
  kind: NotificationKind;
  created_at: string;
}

export function getNotificationWs(token: string): WebSocket {
  const ws = new WebSocket(httpClient.genUrl(ROUTES.GET_NOTIFICATION_WS(token), true));

  return ws;
}

export async function readNotifications(): Promise<void> {
  return httpClient.req(ROUTES.READ_NOTIFICATIONS());
}
