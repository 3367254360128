import { XIcon } from "@heroicons/react/outline";
import classNames from "classnames";
import React, { FunctionComponent, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { twMerge } from "tailwind-merge";
import { randomString } from "../../utils/common";
import { PortalHandler } from "../Portal";

export interface Props {
  children: React.ReactNode;
  show: boolean;
  onClose: () => void;
  maskClosable?: boolean;
  style?: React.CSSProperties;
  className?: string;
  dark?: boolean;
  light?: boolean;
}

export const modalHandler: PortalHandler = new PortalHandler();

export const Modal: FunctionComponent<Props & React.HTMLAttributes<HTMLDivElement>> = ({
  children,
  show,
  onClose,
  style,
  className,
  maskClosable = "false",
  dark,
  light,
  ...props
}) => {
  const [portal, setPortal] = useState<HTMLElement>();
  const [id] = useState<string>(randomString(12));

  useEffect(() => {
    if (show && !modalHandler.items.includes(id)) {
      modalHandler.addModal(id);
    } else if (!show) {
      modalHandler.removeModal(id);
    }
  }, [show]);

  useEffect(() => {
    let elem = document.getElementById("modal-portal");
    if (elem) {
      setPortal(elem);
    } else {
      throw new Error("Modal Portal not defined");
    }
    return () => {
      modalHandler.removeModal(id);
    };
  }, []);

  const wrapperClasses = classNames("w-screen h-screen flex justify-center items-center ", {
    dark: dark,
  });

  const modalClasses = twMerge(
    classNames("max-h-[80vh] p-8 md:p-12 bg-white rounded-3xl relative flex flex-col", {
      [`${className}`]: !!className,
      "dark:bg-background-dark": !light,
    })
  );

  let content = (
    <div className={wrapperClasses} onClick={onClose} style={{ background: "#3b3b3b80" }}>
      <div {...props} onClick={(e) => e.stopPropagation()} className={modalClasses}>
        {!maskClosable && (
          <div className="absolute right-4 top-4 cursor-pointer" onClick={onClose}>
            <XIcon width={20} />
          </div>
        )}
        <div className={"overflow-hidden flex-1 flex flex-col bg-inherit"}>{children}</div>
      </div>
    </div>
  );

  return portal && show ? createPortal(content, portal) : <div />;
};
