import { EmojiSadIcon } from "@heroicons/react/outline";
import { ColumnProps } from ".";
import { Skeleton } from "../Skeleton";
import { Tooltip } from "../Tooltip";
import React from "react";
import classNames from "classnames";
import { ColorType } from "../../utils/common";
import { twMerge } from "tailwind-merge";

interface RenderRowsProps<T> {
  data: T[];
  loading: boolean;
  cols: ColumnProps<T>[];
  columns: ColumnProps<T>[];
  columnClasses: string;
  rowClasses: (val: T) => string;
  colClasses: string;
  colElemClasses: string;
  totalDefinedWidth: number;
  maxSize: number;
  extended: T[];
  extend?: { render?: (data: T) => JSX.Element | undefined; extendKey: keyof T };
  onClick?: (val: T) => void;
  bordered?: boolean;
  rowBordered?: boolean;
  colorType?: ColorType;
  light?: boolean;
}

export const TableRows = <T extends unknown>({
  data,
  loading,
  cols,
  columns,
  columnClasses,
  colElemClasses,
  colClasses,
  rowClasses,
  totalDefinedWidth,
  maxSize,
  extend,
  extended,
  onClick,
  bordered,
  rowBordered,
  light,
}: RenderRowsProps<T>) => {
  const textSize = (text: string): number => {
    const font = "500 16px Montserrat";
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    if (context) {
      context.font = font;
      const { width } = context.measureText(text);
      return width;
    }
    return 0;
  };

  const extendClasses = twMerge(
    classNames("w-full h-max bg-background-bright text-content-darker", {
      "border-x border-solid last:rounded-b-2xl": bordered,
      "border-b border-solid": rowBordered,
      "last:border-b": bordered && !rowBordered,
      "dark:bg-background-dark dark:border-border-dark dark:text-content-bright": !light,
    })
  );

  const emptyClasses = classNames("w-full h-48 text-content-medium flex justify-center items-center", {
    "border border-solid rounded-2xl": bordered,
    "dark:border-border-dark": bordered && !light,
  });

  return (
    <div>
      {data.length > 0 ? (
        data.map((val: any, i) => (
          <React.Fragment key={i}>
            <div onClick={() => onClick && onClick(val as T)} className={rowClasses(val)}>
              {cols.map((col) => (
                <div
                  key={`${col.key}-${i}`}
                  className={columnClasses}
                  style={{
                    maxWidth: col.width
                      ? col.width
                      : `calc(calc(100% - ${totalDefinedWidth}px) / ${columns.filter((e) => !e.width).length})`,
                  }}
                >
                  <div className={colClasses}>
                    {loading ? (
                      <div className="w-1/2">
                        <Skeleton elem={<div className="w-full bg-slate-300 h-4 rounded" />} />
                      </div>
                    ) : (
                      <div className="w-full">
                        {col.render ? (
                          col.render(val[col.dataIndex], val)
                        ) : textSize(val[col.dataIndex]) > maxSize ? (
                          <Tooltip title={val[col.dataIndex]} className="w-full" light={light}>
                            <div className={colElemClasses}>{val[col.dataIndex]}</div>
                          </Tooltip>
                        ) : (
                          <div className={colElemClasses}>{val[col.dataIndex]}</div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
            {extend && extend.render && extended.includes(val[extend.extendKey]) && (
              <div className={extendClasses}>{extend.render(val)}</div>
            )}
          </React.Fragment>
        ))
      ) : (
        <div className={emptyClasses}>
          <div className="flex flex-col items-center">
            <svg width="64" height="41" viewBox="0 0 64 41" xmlns="http://www.w3.org/2000/svg">
              <g transform="translate(0 1)" fill="none" fillRule="evenodd">
                <ellipse fill="#f5f5f5" cx="32" cy="33" rx="32" ry="7"></ellipse>
                <g stroke="#d9d9d9" fillRule="nonzero">
                  <path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"></path>
                  <path
                    d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z"
                    fill="#fafafa"
                  ></path>
                </g>
              </g>
            </svg>
            <div className="mt-4">Rien à afficher</div>
          </div>
        </div>
      )}
    </div>
  );
};
