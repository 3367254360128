import React, { FunctionComponent } from "react";
import { Experience, Education } from "../../models/candidate";
import dayjs from "dayjs";
import { duration } from "../../utils/formatters";
import { Paragraph } from "@getprorecrutement/getpro-design";

interface Props {
  isEdu?: boolean;
  elem: Education | Experience;
}

interface Elem {
  title?: string;
  subtitle?: string;
  content?: string;
  period: string;
}

const mapElem = (elem: Experience | Education, isEdu: boolean): Elem => {
  if (isEdu) {
    const e = elem as Education;
    const start = e.start ? dayjs(e.start).format("LL") : "Inconnu";
    const end = e.end ? dayjs(e.end).format("LL") : "Présent";
    const dur = e.start ? ` • ${duration(e.start, e.end)}` : "";
    return {
      title: e.school,
      subtitle: e.degree?.join(", "),
      content: e.domain,
      period: `${start} - ${end}${dur}`,
    };
  }

  const e = elem as Experience;
  const start = e.start ? dayjs(e.start).format("LL") : "Inconnu";
  const end = e.end ? dayjs(e.end).format("LL") : "Présent";
  const dur = e.start ? ` • ${duration(e.start, e.end)}` : "";
  return {
    title: e.title,
    subtitle: [e.company, e.location].filter((x) => x).join(" - "),
    content: e.summary,
    period: `${start} - ${end}${dur}`,
  };
};

const ExpEdu: FunctionComponent<Props> = (props) => {
  const e = mapElem(props.elem, !!props.isEdu);

  return (
    <div className="my-4 border-l-2 border-solid border-border-bright pl-4 bg-inherit">
      <div className="font-bold">{e.title}</div>
      <div className="text-content-medium">{e.subtitle}</div>
      <div className="text-content-medium">{e.period}</div>
      {e.content && <Paragraph light size="small" rows={3} description={e.content} />}
    </div>
  );
};

export default ExpEdu;
