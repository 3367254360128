import React from "react";
import { FunctionComponent } from "react";
import dayjs from "dayjs";
import { Bar, BarChart, ResponsiveContainer, XAxis, YAxis, Tooltip, TooltipProps, LabelList } from "recharts";
import { OpportunityTypes } from "../../../../models/jobOffers";
import { BookingTimelinePerTypeData, getCustomTooltipDate } from ".";
import { PALETTE } from "../..";
import { euroDisplay } from "../../../../utils/formatters";
import { formattedAmountLabel } from "./timeline";
import { Radio, Spinner } from "@getprorecrutement/getpro-design";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const renderCustomizedLabel = (props: any) => {
  const { x, y, width, value } = props;

  return (
    <text
      className="text-xs"
      x={x + width / 2}
      y={value < 0 ? y + 10 : y - 10}
      fill="#000"
      textAnchor="middle"
      dominantBaseline="middle"
    >
      {value ? formattedAmountLabel(value) : undefined}
    </text>
  );
};

interface Props {
  bookings?: BookingTimelinePerTypeData[];
  setGranularity: (value: "month" | "quarter" | "year") => void;
  granularity: "month" | "quarter" | "year";
}

export const BookingsTimelinePerType: FunctionComponent<Props> = (props) => {
  const { bookings, setGranularity, granularity } = props;

  const tl = bookings || [];
  const barSize = tl.length <= 2 ? 100 : undefined;
  const padding = tl.length > 0 && tl.length < 3 ? tl.length * 100 : undefined;
  const paddingProp = padding ? { left: padding, right: padding } : "gap";

  const opt_types = OpportunityTypes.filter((o) => o.value !== "interne");

  const CustomTooltip = ({ active, payload, label }: TooltipProps<number, string>) => {
    if (active && payload && payload.length) {
      return (
        <div className="DashboardCustomTooltip">
          {getCustomTooltipDate(label, granularity)}
          {opt_types.map((s, i) => {
            const save = payload.find((p) => p.name === s.value);
            if (save) {
              return (
                <div
                  key={save.dataKey}
                  className="DashboardCustomTooltipData"
                  style={{ backgroundColor: PALETTE.RED[i * 2] }}
                >
                  <span className="DashboardCustomTooltipLabel">{s.label}</span>
                  <span className="DashboardCustomTooltipValue">{`${euroDisplay(save.value)}`}</span>
                </div>
              );
            }
          })}
        </div>
      );
    }

    return null;
  };

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: 284,
      }}
    >
      {bookings ? (
        <div style={{ width: "100%" }}>
          <div className="mb-6 flex justify-between items-center">
            <Radio
              light
              optionType="button"
              options={[
                { label: "Mois", value: "month" },
                { label: "Trimestre", value: "quarter" },
                { label: "Année", value: "year" },
              ]}
              value={granularity}
              onChange={(v) => setGranularity(v as "month" | "quarter" | "year")}
            />
          </div>
          <ResponsiveContainer width="100%" height={200}>
            <BarChart
              margin={{ left: 0, right: 0 }}
              data={bookings.map((t: BookingTimelinePerTypeData) => ({
                ...t,
                created_at: dayjs(t.created_at).valueOf(),
              }))}
            >
              <XAxis
                dataKey="created_at"
                scale="time"
                type="number"
                axisLine={false}
                tickLine={false}
                padding={paddingProp}
                domain={["auto", "auto"]}
                tickFormatter={(tick) => dayjs(tick).format("DD/MM/YY")}
              />
              <YAxis padding={{ top: 20, bottom: 0 }} axisLine={false} tickLine={false} />
              <Tooltip cursor={false} content={CustomTooltip} />
              {opt_types.sort().map((s, i) => {
                return (
                  <Bar barSize={barSize} key={s.label} dataKey={s.value} stackId={i} fill={PALETTE.RED[i * 2]}>
                    <LabelList dataKey={s.value} content={renderCustomizedLabel} />
                  </Bar>
                );
              })}
            </BarChart>
          </ResponsiveContainer>
        </div>
      ) : (
        <Spinner light />
      )}
    </div>
  );
};

export default BookingsTimelinePerType;
