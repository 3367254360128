import React, { useState } from "react";
import { FunctionComponent } from "react";
import { UUID } from "../../../../models/common";
import { Table, ColumnProps } from "@getprorecrutement/getpro-design";
import { useNavigate } from "react-router";

const PER_PAGE = 5;

interface Props {
  jos: {
    id: UUID;
    name: string;
    summary: { name: string; count: number }[];
  }[];
  statuses_ids: UUID[];
}

export const PipeJos: FunctionComponent<Props> = (props) => {
  const { jos, statuses_ids } = props;
  const navigate = useNavigate();
  const [page, setPage] = useState<number>(1);

  const getColumns = (): ColumnProps<{
    id: UUID;
    name: string;
    summary: { name: string; count: number }[];
  }>[] => {
    const columns: ColumnProps<{
      id: UUID;
      name: string;
      summary: { name: string; count: number }[];
    }>[] = [
      {
        title: "Offres",
        dataIndex: "name",
        key: "name",
      },
    ];

    (statuses_ids.includes("c596e2bc-d302-4b5e-bc25-ff6afe66ec4b") || statuses_ids.length === 0) &&
      columns.push({
        title: "Postulé",
        width: 60,
        dataIndex: "summary",
        key: "summary-applied",
        render: (_: string, record) => {
          const status = record.summary.find((e) => e.name === "Postulé")?.count;
          return <div style={{ textAlign: "center" }}>{status || 0}</div>;
        },
      });

    (statuses_ids.includes("188508f1-9000-49fe-80a2-93162a002d52") || statuses_ids.length === 0) &&
      columns.push({
        title: "Présenté",
        dataIndex: "summary",
        key: "summary-sent",
        width: 70,
        render: (_: string, record) => {
          const status = record.summary.find((e) => e.name === "Présenté au client")?.count;
          return <div style={{ textAlign: "center" }}>{status || 0}</div>;
        },
      });

    (statuses_ids.includes("5eaf0c5a-1cbf-44ae-9f7e-35bc31741a14") || statuses_ids.length === 0) &&
      columns.push({
        title: "Reçu",
        dataIndex: "summary",
        key: "summary-met",
        width: 50,
        render: (_: string, record) => {
          const status = record.summary.find((e) => e.name === "Reçu par le client")?.count;
          return <div style={{ textAlign: "center" }}>{status || 0}</div>;
        },
      });

    (statuses_ids.includes("967e60fd-7063-49cb-a50a-9058883354b8") || statuses_ids.length === 0) &&
      columns.push({
        title: "Closing",
        width: 60,
        dataIndex: "summary",
        key: "summary-closing",
        render: (_: string, record) => {
          const status = record.summary.find((e) => e.name === "Closing")?.count;
          return <div style={{ textAlign: "center" }}>{status || 0}</div>;
        },
      });

    (statuses_ids.includes("ce7d87f3-f446-499d-bc49-12112e78f131") || statuses_ids.length === 0) &&
      columns.push({
        title: "Embauché",
        width: 75,
        dataIndex: "summary",
        key: "summary-recruited",
        render: (_: string, record) => {
          const status = record.summary.find((e) => e.name === "Embauché")?.count;
          return <div style={{ textAlign: "center" }}>{status || 0}</div>;
        },
      });

    return columns;
  };

  const displayedJos = (jos || []).filter((_, i) => i >= (page - 1) * PER_PAGE && i < page * PER_PAGE);

  return (
    <Table
      size="small"
      dataSource={{ data: displayedJos, total: jos.length || 0 }}
      columns={getColumns()}
      rowClassName={() => "cursor-pointer"}
      onClick={(v) => navigate(`/jobs/${v.id}`)}
      bordered
      light
      pagination={{ per_page: PER_PAGE, page, onChange: setPage }}
    />
  );
};

export default PipeJos;
