import { Button, PopOver, Table } from "@getprorecrutement/getpro-design";
import { ColumnProps } from "@getprorecrutement/getpro-design/dist/components/Table";
import dayjs from "dayjs";
import React from "react";
import { FunctionComponent, useEffect, useState } from "react";
import { Candidate } from "../../../models/candidate";
import { UUID } from "../../../models/common";
import { JobApplicationBase, JobApplicationData, JobApplicationResponse } from "../../../models/jobApplications";
import { getJoJas, FullJobOffer, Status, Summary } from "../../../models/jobOffers";
import { CheckIcon, XMarkIcon, ArrowSmallLeftIcon, ChevronDownIcon, FireIcon } from "@heroicons/react/24/outline";
import { FireIcon as FireIconSolid } from "@heroicons/react/24/solid";
import StatusSelect from "../../../components/forms/inputs/statusSelect";
import CallStatusSelect from "../../../components/forms/inputs/callStatusSelect";
import CandidateProfile from "../../../components/candidateProfile";
import { useNavigate } from "react-router-dom";
import qs from "qs";
import { CandidateInformations } from "./candidateInformations";
import { JobAppActions } from "./jobAppActions";

interface Props {
  jobOffer: FullJobOffer;
  statusIds?: UUID[];
  candidateId?: UUID;
  onUpdate: () => void;
  pinned: boolean;
  statuses: Status[];
  callStatuses: Status[];
  sources: { id: UUID; name: string }[];
  updateStatusId: (id: UUID, jobApp: JobApplicationBase, candidate: Candidate) => Promise<void>;
  updateJaReq: (data: JobApplicationData & { id: UUID }) => Promise<void>;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Filters = Omit<{ [key in keyof JobApplicationResponse]?: any[] }, "search"> & { search?: string };

export const JobApplications: FunctionComponent<Props> = (props) => {
  const jobOffer = props.jobOffer;
  const [jobApplications, setJobApplications] = useState<JobApplicationResponse[]>([]);
  const [page, setPage] = useState<number>(1);
  const [total, setTotal] = useState<number>(10);
  const [terminated, setTerminated] = useState<boolean | undefined>(props.candidateId ? undefined : false);
  const [sortBy, setSortBy] = useState<{
    key: keyof JobApplicationResponse;
    sortDirection: undefined | "asc" | "desc";
  }>();
  const navigate = useNavigate();
  const [skipScroll, setSkipScroll] = useState<boolean>(true);
  // const [selectedJas, setSelectedJas] = useState<UUID[]>([]);
  const [filters, setFilters] = useState<Filters>({ status_id: props.statusIds || [], call_status_id: [] });

  useEffect(() => {
    setFilters({ ...filters, status_id: props.statusIds || [] });
  }, [props.statusIds]);

  const fetchJas = async () => {
    const resp = await getJoJas(jobOffer.id, {
      page: page,
      per_page: 10,
      search: filters.search,
      status_ids: filters.status_id ? filters.status_id : undefined,
      call_status_ids: filters.call_status_id ? filters.call_status_id : undefined,
      terminated: terminated,
      pinned: props.pinned ? props.pinned : undefined,
      sort_by: sortBy?.key,
      sort_direction: sortBy?.sortDirection,
    });

    setJobApplications(resp.data);
    setTotal(resp.total);
  };

  useEffect(() => {
    fetchJas();
  }, [page, filters, sortBy, terminated, props.pinned, jobOffer]);

  // Scroll up to candidate list when changing page
  useEffect(() => {
    if (skipScroll) setSkipScroll(false);
    else document.getElementById("jobApplications")?.scrollIntoView();
  }, [page]);

  async function updateCallStatusId(value: UUID, id: UUID) {
    await props.updateJaReq({ call_status_id: value, id });
  }

  async function updatePinnedJobApplication(id: UUID, pinned: boolean) {
    await props.updateJaReq({ id, pinned });
  }

  const selectStatusId = (id: UUID) => {
    const newStatuses: UUID[] =
      ((filters.status_id || []).includes(id)
        ? filters.status_id?.filter((s) => s !== id)
        : [...(filters.status_id || []), id]) || [];

    navigate({
      pathname: `/jobs/${props.jobOffer.id}`,
      search: qs.stringify({ statusIds: newStatuses }),
    });
  };

  const jaColumns: ColumnProps<JobApplicationResponse>[] = [
    {
      title: "",
      dataIndex: "pinned",
      key: "top_candidate",
      width: 40,
      render: (val: boolean, record: JobApplicationResponse) => {
        return val ? (
          <FireIconSolid
            width={20}
            height={20}
            className="text-primary-regular cursor-pointer"
            onClick={() => updatePinnedJobApplication(record.id, !val)}
          />
        ) : (
          <FireIcon
            width={20}
            height={20}
            className={`cursor-pointer text-content-light hover:text-content-darker`}
            onClick={() => updatePinnedJobApplication(record.id, !val)}
          />
        );
      },
    },
    {
      title: "Candidat",
      dataIndex: "candidate",
      key: "candidate",
      filterBy: "text",
      render: (candidate: Candidate, record: JobApplicationResponse) => (
        <CandidateInformations
          candidate={candidate}
          onClick={() =>
            navigate({
              pathname: `/jobs/${record.job_offer_id}`,
              search: qs.stringify({ statusIds: filters.status_id, candidateId: record.candidate_id }),
            })
          }
          terminatedJa={record.terminated}
        />
      ),
    },
    {
      title: "Mise à jour",
      dataIndex: "status_updated_at",
      key: "status_updated_at",
      sortable: true,
      width: 110,
      render: (val: string, record: JobApplicationResponse) => (
        <div className={`text-xs ${record.terminated ? "text-content-light" : ""}`}>
          {dayjs(val).format("DD MMM YYYY")}
        </div>
      ),
    },
    {
      title: "Mail",
      dataIndex: "contacted_by_email",
      key: "contacted_by_email",
      width: 50,
      render: (val: boolean, record: JobApplicationResponse) => (
        <div className="flex items-center">
          {val ? (
            <CheckIcon width={24} height={24} className={record.terminated ? "text-green-200" : "text-green-400"} />
          ) : (
            <XMarkIcon width={24} height={24} className={record.terminated ? "text-red-200" : "text-red-400"} />
          )}
        </div>
      ),
    },
    {
      title: "Inmail",
      dataIndex: "contacted_by_inmail",
      key: "contacted_by_inmail",
      width: 50,
      render: (val: boolean, record: JobApplicationResponse) => (
        <div className="flex items-center">
          {val ? (
            <CheckIcon width={24} height={24} className={record.terminated ? "text-green-200" : "text-green-400"} />
          ) : (
            <XMarkIcon width={24} height={24} className={record.terminated ? "text-red-200" : "text-red-400"} />
          )}
        </div>
      ),
    },
    {
      title: "Cold Call",
      dataIndex: "call_status_id",
      key: "call_status_id",
      filterBy: "select",
      width: 210,
      optionFilters: props.callStatuses.map((s) => {
        return { value: s.id, label: s.name, key: s.id };
      }),
      render: (val: string, record: JobApplicationResponse) => (
        <CallStatusSelect
          size="small"
          disabled={record.terminated}
          onSelect={(s) => updateCallStatusId(s, record.id)}
          value={val}
          callStatuses={props.callStatuses}
        />
      ),
    },
    {
      title: "Statut",
      dataIndex: "status_id",
      key: "status_id",
      filterBy: "select",
      width: 180,
      optionFilters: props.statuses.map((s) => {
        return {
          value: s.id,
          label: (
            <div className="flex gap-3 items-center">
              <div className="ml-1 w-2 h-2 rounded-full" style={{ backgroundColor: `#${s.color}` }}></div>
              <div>{s.name}</div>
            </div>
          ),
          key: s.id,
        };
      }),
      render: (val: string, record: JobApplicationResponse) => (
        <StatusSelect
          size="small"
          disabled={record.terminated}
          onSelect={async (s) => await props.updateStatusId(s, record, record.candidate)}
          value={val}
          statuses={props.statuses}
        />
      ),
    },
    {
      title: "",
      dataIndex: "id",
      key: "actions",
      width: 120,
      render: (_: string, record: JobApplicationResponse) => (
        <JobAppActions
          jobApp={record}
          candidate={record.candidate}
          onUpdate={() => {
            fetchJas();
            props.onUpdate();
          }}
          updateStatusId={props.updateStatusId}
          statuses={props.statuses}
          callStatuses={props.callStatuses}
          sources={props.sources}
          withCandidateActions
        />
      ),
    },
  ];

  // const onUpdateSelect = (val: JobApplicationResponse) => {
  //   const select = val["id"];
  //   setSelectedJas(selectedJas.includes(select) ? selectedJas.filter((e) => e !== select) : [...selectedJas, select]);
  // };

  return (
    <section id="jobApplications">
      <JobOfferSummary
        selectedIds={filters.status_id || []}
        selectStatusId={selectStatusId}
        statuses={props.statuses // Enlever Embauché & Validé de la liste
          .filter(
            (v) => v.id !== "ce7d87f3-f446-499d-bc49-12112e78f131" && v.id !== "cedcfdaf-1ee2-458e-b89c-8e19939af2d8"
          )}
        summary={jobOffer.summary}
        terminated={terminated}
        setTerminated={setTerminated}
      />
      <section className="px-9">
        {props.candidateId ? (
          <div>
            <Button
              size="small"
              kind="light"
              light
              className="mb-2"
              icon={<ArrowSmallLeftIcon />}
              title="Retour aux candidatures"
              onClick={() =>
                navigate({ pathname: `/jobs/${jobOffer.id}`, search: qs.stringify({ statusIds: filters.status_id }) })
              }
            />
            <CandidateProfile id={props.candidateId} />
          </div>
        ) : (
          <Table
            rowBordered={false}
            light
            size="large"
            columns={jaColumns}
            dataSource={{ data: jobApplications, total } || { data: [], total: 0 }}
            pagination={{ per_page: 10, page, onChange: setPage }}
            // extend={{
            //   render: (data) =>
            //     data.terminated ? (
            //       <div className="px-4 py-2">
            //         <div>
            //           Candidate cloturées par {data.termination_source}
            //           {data.termination_comment ? " avec la raison :" : ""}{" "}
            //         </div>
            //         <div>{data.termination_comment}</div>
            //       </div>
            //     ) : undefined,
            //   extendKey: "id",
            // }}
            // extend={{
            //   render: (data) => (data.description ? <div>{data.description}</div> : undefined),
            //   extendKey: "id",
            // }}
            rowClassName={(data) => (data.terminated ? "bg-background-bright hover:bg-background-bright" : "")}
            sortBy={sortBy}
            onSortDirectionChange={(key, direction) => {
              setSortBy({ key: key, sortDirection: direction });
            }}
            filter={{
              filters: { ...filters, candidate: filters.search },
              onFilterChange: (key, value) => {
                if (key == "candidate") {
                  setFilters({
                    ...filters,
                    search: value,
                  });
                } else if (key in filters) {
                  if (key === "status_id") selectStatusId(value);
                  else
                    setFilters({
                      ...filters,
                      [key]: filters[key]?.includes(value)
                        ? filters[key]?.filter((elem) => elem != value)
                        : [...(filters[key] || []), value],
                    });
                }
              },
            }}
            // ADD GLOBAL ACTIONS
            // selection={{
            //   selected: selectedJas,
            //   onCheck: onUpdateSelect,
            //   // TODO SELECT ALL
            //   onCheckAll: (select) => console.log("SELECT ALL TODO", select),
            //   selectKey: "id",
            // }}
          />
        )}
      </section>
    </section>
  );
};

interface SummaryProps {
  statuses: Status[];
  summary: Summary;
  selectStatusId: (id: UUID) => void;
  selectedIds: UUID[];
  terminated?: boolean;
  setTerminated: (val?: boolean) => void;
}

const JobOfferSummary: FunctionComponent<SummaryProps> = ({
  statuses,
  summary,
  selectStatusId,
  selectedIds,
  terminated,
  setTerminated,
}) => {
  const options = [
    { key: "all", label: "Toutes", terminated: undefined },
    { key: "not_terminated", label: "Actives", terminated: false },
    { key: "terminated", label: "Cloturées", terminated: true },
  ];

  return (
    <div className="mb-4 mt-9 mx-9 flex bg-background-bright rounded-3xl">
      <div className="w-32 z-10 h-auto items-center justify-center bg-background-dark rounded-3xl text-white flex flex-col">
        <PopOver
          dark
          tooltipProps={{ className: "p-2 w-full text-center dark:bg-background-medium" }}
          position="bottom-right"
          className="w-full h-full"
          content={
            <div className="flex flex-col gap-1">
              {options.map((o) => (
                <div
                  onClick={() => setTerminated(o.terminated)}
                  key={`option-${o.key}`}
                  className={`w-full px-2 py-1 cursor-pointer rounded-xl ${
                    o.terminated === terminated
                      ? "bg-content-dark text-content-bright"
                      : "text-content-regular hover:bg-content-dark hover:text-content-bright"
                  }`}
                >
                  {o.label}
                </div>
              ))}
            </div>
          }
        >
          <div className="py-4">
            <div className="text-center font-bold text-lg">
              {typeof terminated === "undefined"
                ? summary.active_count + summary.terminated_count
                : terminated
                ? summary.terminated_count
                : summary.active_count}
            </div>
            <div className="w-32 flex items-center justify-center gap-2 text-content-regular">
              <div>{options.find((o) => o.terminated === terminated)?.label}</div>
              <ChevronDownIcon width={14} />
            </div>
          </div>
        </PopOver>
      </div>
      <div className="px-6 py-4 flex items-center flex-wrap w-full gap-2">
        {statuses
          .sort((a, b) => a.index - b.index)
          .map((status) => {
            const status_summary = summary.statuses.find((s) => s.id === status.id);
            const count = status_summary
              ? typeof terminated === "undefined"
                ? status_summary.active_count + status_summary.terminated_count
                : terminated
                ? status_summary.terminated_count
                : status_summary.active_count
              : 0;

            return (
              <div
                onClick={() => selectStatusId(status.id)}
                key={status.id}
                className={`min-w-[100px] cursor-pointer text-content-dark ${
                  selectedIds.includes(status.id) || selectedIds.length === 0 ? "" : "opacity-30"
                }`}
              >
                <div className="text-lg font-bold text-center">{count}</div>
                <div className="flex justify-center items-center gap-2 h-[22px]">
                  <div
                    className="w-2 h-2 rounded-full"
                    style={{
                      backgroundColor: `#${status.color}`,
                    }}
                  />
                  <div className="text-xs">{status.name}</div>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default JobApplications;
