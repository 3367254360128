import React, { FunctionComponent, useState } from "react";
import { Permission, PermissionsCat } from "../../../models/roles";
import { CheckIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import { Checkbox } from "@getprorecrutement/getpro-design";

interface Props {
  permissionsCat: PermissionsCat[];
  values?: string[];
  onSelect?: (key: string) => void;
}

const allPermissionsSelected = (selected: string[], permissions: Permission[]) => {
  let res = true;
  permissions.forEach((permission) => {
    if (!selected.includes(permission.key)) res = false;
  });
  return res;
};

const onePermissionSelected = (selected: string[], permissions: Permission[]) => {
  let res = false;
  permissions.forEach((permission) => {
    if (selected.includes(permission.key)) res = true;
  });
  return res;
};

const RolesTree: FunctionComponent<Props> = ({ permissionsCat, values, onSelect }) => {
  const [openedCat, setOpenedCat] = useState<string[]>([]);

  return (
    <div className="p-4 border border-solid w-full">
      {permissionsCat.map((cat, i) => (
        <div key={`${cat.name}-${i}`}>
          <div className="flex items-center gap-2 cursor-pointer">
            <ChevronRightIcon
              className={`w-4 h-4 min-w-fit transition-all ${openedCat.includes(cat.name) ? "rotate-90" : ""}`}
              onClick={() =>
                setOpenedCat(
                  openedCat.includes(cat.name) ? openedCat.filter((v) => v !== cat.name) : [...openedCat, cat.name]
                )
              }
            />
            {onSelect && values && (
              <div className={"w-4 h-4 min-w-fit border border-solid"} onClick={() => onSelect(cat.name)}>
                {allPermissionsSelected(values, cat.permissions) ? (
                  <div className="w-full h-full bg-primary-regular flex items-center justify-center">
                    <CheckIcon className="w-3 h-3 text-white stroke-2" />
                  </div>
                ) : (
                  onePermissionSelected(values, cat.permissions) && (
                    <div className="w-full h-full flex items-center justify-center p-[3px]">
                      <div className="bg-primary-regular w-full h-full" />
                    </div>
                  )
                )}
              </div>
            )}
            <div onClick={() => onSelect && onSelect(cat.name)}>{cat.name}</div>
          </div>
          <div className="pl-12">
            {openedCat.includes(cat.name) &&
              cat.permissions.map((permission) =>
                onSelect && values ? (
                  <Checkbox
                    value={values.includes(permission.key)}
                    onChange={() => onSelect(permission.key)}
                    label={permission.description}
                    key={permission.key}
                  />
                ) : (
                  <div key={permission.key}>{permission.description}</div>
                )
              )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default RolesTree;
