import React, { FunctionComponent, useEffect, useRef } from "react";

export interface Props {
  src: string;
  fallback?: string;
  alt?: string;
  size?: "small" | "medium" | "large";
  dark?: boolean;
  light?: boolean;
}

export const Image: FunctionComponent<Props & React.HTMLAttributes<HTMLImageElement>> = ({
  src,
  fallback,
  alt,
  size = "medium",
  dark,
  light,
  ...props
}) => {
  const ref = useRef<HTMLImageElement>(null);

  useEffect(() => {
    if (ref.current) {
      ref.current.addEventListener("error", () => {
        if (fallback && ref.current) ref.current.src = fallback;
      });
    }
  }, []);

  return <img {...props} ref={ref} src={src} alt={alt || "image"} />;
};
