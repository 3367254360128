import React, { FunctionComponent, useEffect, useState } from "react";
import {
  NewProject,
  Project,
  ProjectTypes,
  createProject,
  updateProject,
  ProjectType,
  setProjectContacts,
  RpoCostLine,
  ProductLine,
} from "../../../../models/billing/project";
import ButtonCard from "../../../buttonCard";
import { ContractResponse, getContracts } from "../../../../models/billing/contract";
import { getUsers, UserResponse } from "../../../../models/users/users";
import { Paginated, UUID } from "../../../../models/common";
import ContactSelect from "../../contacts/contactSelect";
import { Contact, getCustomerContacts, getProjectContacts } from "../../../../models/contacts";
import HelpTooltip from "../../../helpTooltip";
import CustomerSelect from "../../../forms/inputs/customerSelect";
import { Button, Checkbox, InputNumber, Select, TextInput } from "@getprorecrutement/getpro-design";
import UserSelect from "../../../forms/inputs/userSelect";
import BusinessUnitSelect from "../../../forms/inputs/businessUnitSelect";
import { toast } from "react-hot-toast";
import { ArrowLeftIcon, MinusCircleIcon, PlusIcon } from "@heroicons/react/24/outline";

interface FormProps {
  project?: Project;
  contractId?: UUID;
  customerId?: UUID;
  projectType: ProjectType;
  onFinish: () => void;
}

const RpoLines = [
  {
    name: "MacBook Air",
    price: 5,
  },
  {
    name: "Linkedin Recruiter Professional Services",
    price: 15,
  },
  {
    name: "Sweeft (ATS)",
    price: 5,
  },
  {
    name: "Figures (Données salaires marché)",
    price: 5,
  },
  { name: "Multiposting des annonces", price: 5 },
  { name: "Sponsoring des annonces", price: 15 },
  { name: "TJM Recruteur", price: 550 },
  { name: "TJM Manager", price: 850 },
];

export const ProjectFormDetails: FunctionComponent<FormProps> = ({
  customerId,
  contractId,
  project,
  projectType,
  onFinish,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [details, setDetails] = useState<any>(project?.details || { project_type: projectType });
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState<UserResponse[]>([]);
  const [contracts, setContracts] = useState<Paginated<ContractResponse>>();
  const [selectedContacts, setSelectedContacts] = useState<UUID[]>([]);
  const [contacts, setContacts] = useState<Contact[]>([]);
  const [rpoLineSelected, setRpoLineSelected] = useState<{ name: string; price: number }>();
  const [rpoLines, setRpoLines] = useState<{ name: string; price: number }[]>(RpoLines);
  const [newLine, setNewLine] = useState<string>();
  const [contractSearch, setContractSearch] = useState<string>("");
  const [values, setValues] = useState<Partial<Project>>(
    project || { customer_id: customerId, contract_id: contractId }
  );

  const fetchContacts = async (id: UUID) => {
    const c = await getCustomerContacts(id);
    setContacts(c);
  };

  const fetchContactsIds = async (id: UUID) => {
    const c = await getProjectContacts(id);
    setSelectedContacts(c.map((e) => e.id));
  };

  useEffect(() => {
    if (project) fetchContactsIds(project.id);
  }, [project]);

  const fetchUsers = async () => {
    const users = await getUsers();
    setUsers(users);
  };

  const fetchContracts = async (id: UUID) => {
    const c = await getContracts({
      customer_id: id,
      page: 1,
      per_page: 100,
    });
    setContracts(c);
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const selectUser = (id: UUID) => {
    const user = users.find((e) => e.id === id);
    setValues({ ...values, business_unit_id: user?.business_unit_id, owner_id: id });
  };

  useEffect(() => {
    if (values.customer_id) {
      fetchContracts(values.customer_id);
      fetchContacts(values.customer_id);
    }
  }, [values.customer_id]);

  const submit = async (data: Partial<Project>) => {
    setLoading(true);
    const filteredDetails =
      projectType === ProjectType.Rpo && details.cost_lines
        ? { ...details, cost_lines: details.cost_lines.filter((c: RpoCostLine) => c.days > 0) }
        : projectType === ProjectType.Product && details.products
        ? { ...details, products: details.products.filter((p: ProductLine) => p.quantity > 0) }
        : details;
    const projectData = {
      ...data,
      project_type: projectType,
      details: filteredDetails,
    };
    try {
      const resp = project
        ? await updateProject({ ...projectData, id: project.id })
        : await createProject(projectData as NewProject);
      await setProjectContacts(resp.id, selectedContacts);
      toast.success(project ? "Projet mis à jour avec succès" : "Projet créé avec succès");
      onFinish();
    } finally {
      setLoading(false);
    }
  };

  const changeAmount = (amount: number) => {
    setDetails({
      ...details,
      amount,
    });
  };

  const changeQuantity = (quantity?: number) => {
    setDetails({
      ...details,
      quantity,
    });
  };

  const subscriptionForm = () => {
    return (
      <div className="flex justify-between items-center gap-4 bg-inherit">
        <div className="flex-1 bg-inherit">
          <InputNumber
            light
            label="Prix annuel"
            placeholder="500"
            value={details.amount}
            onChange={(e) => e && changeAmount(e)}
            min={0}
            display={{
              formatter: (value) => value && value.replace(/\B(?=(\d{3})+(?!\d))/g, " "),
              parser: (value) => (value ? value.replace(/[\s€]*/g, "") : ""),
            }}
            precision={2}
            suffix="€"
            controls={false}
          />
        </div>
        <div className="flex-1 bg-inherit">
          <InputNumber
            light
            min={0}
            label="Nombre de crédits"
            placeholder="10"
            value={details.quantity}
            onChange={(e) => changeQuantity(e)}
          />
        </div>
      </div>
    );
  };

  const changeCostLines = (cost_lines: RpoCostLine[]) => {
    setDetails({
      ...details,
      cost_lines,
    });
  };

  const removeCostLine = (index: number) => {
    let new_cost_lines: RpoCostLine[] = [];
    details.cost_lines.map((e: RpoCostLine, i: number) => {
      if (i !== index) {
        new_cost_lines = [...new_cost_lines, e];
      }
    });
    changeCostLines(new_cost_lines);
  };

  const addItem = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    if (!rpoLines.find((line) => line.name === (newLine || "Nouvelle ligne"))) {
      setRpoLines([...rpoLines, { name: newLine || `Nouvelle ligne`, price: 0 }]);
      setNewLine("");
    } else {
      console.log("Option already exist");
      // SHOW ERROR NOTIFICATION ?
    }
  };

  const rpoForm = () => {
    return (
      <div className="bg-inherit">
        {details.cost_lines &&
          details.cost_lines.map((line: RpoCostLine, i: number) => (
            <div key={`${i}-${line.description}`} className="flex justify-between items-center mb-3 bg-inherit gap-2">
              <TextInput
                onChange={() => console.log("Disabled")}
                light
                placeholder=""
                label="Description"
                type="text"
                value={line.description}
                style={{ width: 400 }}
                disabled
              />
              <InputNumber
                style={{ width: 150 }}
                light
                placeholder="TJM"
                label="TJM"
                value={line.amount}
                onChange={(e) =>
                  changeCostLines(
                    details.cost_lines.map((line: RpoCostLine, index: number) =>
                      i === index ? { ...line, amount: e } : line
                    )
                  )
                }
                display={{
                  formatter: (value) => value && value.replace(/\B(?=(\d{3})+(?!\d))/g, " "),
                  parser: (value) => (value ? value.replace(/[\s€]*/g, "") : ""),
                }}
                precision={2}
                suffix="€"
                min={0}
                controls={false}
              />
              <InputNumber
                style={{ width: 150 }}
                controls={false}
                light
                placeholder="Jours"
                label="Jours"
                value={line.days}
                onChange={(e) =>
                  changeCostLines(
                    details.cost_lines.map((line: RpoCostLine, index: number) =>
                      i === index ? { ...line, days: e } : line
                    )
                  )
                }
                precision={2}
                min={0}
              />
              <Button className="p-0" light kind="light" icon={<MinusCircleIcon />} onClick={() => removeCostLine(i)} />
            </div>
          ))}
        <div className="flex justify-between items-center mt-6 bg-inherit gap-2">
          <div className="flex-1 bg-inherit">
            <Select
              // style={{ width: 300 }}

              light
              bordered
              rounded
              label="Nouvelle ligne"
              // allowClear
              value={rpoLines.find((line) => line.name === rpoLineSelected?.name)}
              onChange={(e) => setRpoLineSelected(e)}
              options={rpoLines.filter((line) => {
                let ret = true;
                details.cost_lines?.map((cost: RpoCostLine) => {
                  if (cost.description === line.name) {
                    ret = false;
                  }
                });
                return ret;
              })}
              type="single"
              optionRender={(item) => item.name}
              getKey={(item) => item.name}
              dropdownRender={(menu) => (
                <div>
                  {menu}
                  <div className="flex justify-center items-center gap-2 px-2 pb-2">
                    <TextInput
                      size="small"
                      type="text"
                      light
                      bordered={false}
                      className="border-b border-b-primary-lighter"
                      placeholder="Option"
                      value={newLine}
                      onChange={(e) => setNewLine(e.target.value)}
                    />
                    <div onClick={(e) => newLine && addItem(e)}>
                      <Button light kind="light" className="p-0" icon={<PlusIcon />} />
                    </div>
                  </div>
                </div>
              )}
            />
          </div>
          <Button
            size="small"
            light
            title="Ajouter une ligne"
            disabled={!rpoLineSelected}
            onClick={() => {
              rpoLineSelected &&
                changeCostLines(
                  details.cost_lines
                    ? [
                        ...details.cost_lines,
                        { amount: rpoLineSelected.price, description: rpoLineSelected.name, days: 0 },
                      ]
                    : [{ amount: rpoLineSelected.price, description: rpoLineSelected.name, days: 0 }]
                );
              setRpoLineSelected(undefined);
            }}
          />
        </div>
      </div>
    );
  };

  const changeProducts = (products: ProductLine[]) => {
    setDetails({
      ...details,
      products,
    });
  };

  const removeProductLine = (index: number) => {
    let new_product_lines: ProductLine[] = [];
    details.products.map((e: ProductLine, i: number) => {
      if (i !== index) {
        new_product_lines = [...new_product_lines, e];
      }
    });
    changeProducts(new_product_lines);
  };

  const productForm = () => {
    return (
      <div className="bg-inherit">
        {details.products &&
          details.products.map((line: ProductLine, i: number) => (
            <div key={i} className="flex justify-between items-center gap-2 mb-3 bg-inherit">
              <TextInput
                type="text"
                light
                placeholder="Nouvelle ligne"
                label="Description"
                value={line.description}
                onChange={(e) =>
                  changeProducts(
                    details.products.map((line: ProductLine, index: number) =>
                      i === index ? { ...line, description: e.target.value } : line
                    )
                  )
                }
              />
              <InputNumber
                style={{ width: 150 }}
                light
                placeholder="Prix"
                label="Prix"
                value={line.amount}
                onChange={(e) =>
                  changeProducts(
                    details.products.map((line: ProductLine, index: number) =>
                      i === index ? { ...line, amount: e } : line
                    )
                  )
                }
                display={{
                  formatter: (value) => value && value.replace(/\B(?=(\d{3})+(?!\d))/g, " "),
                  parser: (value) => (value ? value.replace(/[\s€]*/g, "") : ""),
                }}
                precision={2}
                suffix="€"
                min={0}
                controls={false}
              />
              <InputNumber
                style={{ width: 150 }}
                controls={false}
                light
                placeholder="Quantité"
                label="Quantité"
                value={line.quantity}
                onChange={(e) =>
                  changeProducts(
                    details.products.map((line: ProductLine, index: number) =>
                      i === index ? { ...line, quantity: e } : line
                    )
                  )
                }
                precision={2}
                min={0}
              />
              <Button
                className="p-0"
                light
                kind="light"
                icon={<MinusCircleIcon />}
                onClick={() => removeProductLine(i)}
              />
            </div>
          ))}
        <Button
          className="mt-3"
          light
          size="small"
          title="Ajouter une ligne"
          onClick={() => {
            changeProducts(
              details.products
                ? [
                    ...details.products,
                    { amount: 0, description: `Nouvelle ligne ${details.products.length}`, quantity: 0 },
                  ]
                : [{ amount: 0, description: "Nouvelle ligne", quantity: 0 }]
            );
          }}
        />
      </div>
    );
  };

  const selectedForm = (type: ProjectType) => {
    switch (type) {
      case "subscription":
        return subscriptionForm();
      case "rpo":
        return rpoForm();
      case "product":
        return productForm();
    }
  };

  return (
    <div className="flex flex-col gap-4 bg-inherit py-2 pr-4 overflow-auto">
      {!customerId && !project && (
        <CustomerSelect
          label="Entreprise"
          value={values.customer_id}
          onSelect={(e) => setValues({ ...values, customer_id: e })}
          showSearch
        />
      )}
      {!contractId && !project && (
        <Select
          label="Contrat"
          value={contracts?.data.find((c) => c.id === values.contract_id)}
          onChange={(e) => e && setValues({ ...values, contract_id: e.id })}
          options={
            contracts?.data.filter(
              (c) => contractSearch === "" || c.name.toLowerCase().includes(contractSearch.toLowerCase())
            ) || []
          }
          disabled={!values.customer_id}
          optionRender={(item) => item.name}
          getKey={(item) => item.id}
          type="single"
          light
          bordered
          rounded
          onSearch={(v) => {
            setContractSearch(v);
            if (values.contract_id) setValues({ ...values, contract_id: undefined });
          }}
        />
      )}
      <TextInput
        light
        placeholder="Nom"
        label="Nom"
        type="text"
        value={values.name}
        onChange={(e) => setValues({ ...values, name: e.target.value })}
      />
      <TextInput
        light
        placeholder="Description"
        label="Description"
        type="text"
        value={values.description}
        onChange={(e) => setValues({ ...values, description: e.target.value })}
      />
      <UserSelect
        label="Propriétaire"
        placeholder="Sélectionner le propriétaire de l'offre"
        value={values.owner_id}
        showSearch
        onSelect={(id: UUID) => selectUser(id)}
      />
      <BusinessUnitSelect
        showSearch
        label="Département"
        value={values.business_unit_id}
        onSelect={(v) => setValues({ ...values, business_unit_id: v })}
      />
      <div className="flex items-center gap-2">
        <Checkbox
          label="Facturation manuelle"
          light
          value={values.manual_billing || false}
          onChange={(e) => setValues({ ...values, manual_billing: e })}
        />
        <HelpTooltip
          position="right"
          title={"Aucune facture ne sera générée automatiquement, elles devront être créées manuellement"}
        />
      </div>
      {values.customer_id && (
        <ContactSelect
          multiple
          creation
          contacts={contacts}
          fetchContacts={fetchContacts}
          customerId={values.customer_id}
          value={selectedContacts}
          onSelect={(id) => setSelectedContacts((e) => [...e, id])}
          onDeselect={(id) => setSelectedContacts(selectedContacts.filter((e) => e !== id))}
        />
      )}
      {selectedForm(projectType)}
      <div className="flex justify-end">
        <Button
          loading={loading}
          title="Enregistrer"
          light
          size="small"
          disabled={
            !values.customer_id || !values.contract_id || !values.name || !values.owner_id || !values.business_unit_id
          }
          onClick={() => submit(values)}
        />
      </div>
    </div>
  );
};

interface SelectProps {
  onSelect: (type: ProjectType) => void;
}

export const ProjectTypeSelect = (props: SelectProps) => {
  const { onSelect } = props;

  return (
    <div>
      <div className="flex gap-4">
        {ProjectTypes.other.map((p) => (
          <div className="flex-1" key={p.value}>
            <ButtonCard size="small" onClick={() => onSelect(p.value)} title={p.label} />
          </div>
        ))}
      </div>
    </div>
  );
};

interface Props {
  project?: Project;
  contractId?: UUID;
  customerId?: UUID;
  onFinish: () => void;
}

export const ProjectForm = ({ project, contractId, customerId, onFinish }: Props) => {
  const [projectType, setProjectType] = useState<ProjectType | undefined>(project?.project_type);

  return (
    <>
      {projectType ? (
        <div className="flex items-center gap-2 mb-2">
          {!project && <ArrowLeftIcon className="w-5 h-5 cursor-pointer" onClick={() => setProjectType(undefined)} />}
          <div className="text-lg font-bold">Informations du projet</div>
        </div>
      ) : (
        <div className="text-center text-2xl font-bold mb-2">Type de projet</div>
      )}
      {/* <div className="bg-inherit"> */}
      {projectType ? (
        <ProjectFormDetails
          project={project}
          contractId={contractId}
          customerId={customerId}
          onFinish={onFinish}
          projectType={projectType}
        />
      ) : (
        <ProjectTypeSelect onSelect={setProjectType} />
      )}
      {/* </div> */}
    </>
  );
};

export default ProjectForm;
