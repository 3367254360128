import React, { FunctionComponent, useCallback, useEffect, useState } from "react";
import { Candidate, CandidateData } from "../../models/candidate";
import dayjs from "dayjs";
import { Button, DatePicker, InputNumber, Slider, TextInput } from "@getprorecrutement/getpro-design";
import { JobTitle, fetchJobTitle } from "../../models/jobTitle";
import { useFormManager } from "../../services/form";
import { UUID } from "../../models/common";
import { JobTitleSelect } from "../forms/inputs/JobTitleSelect";
import {
  DisplayedConfirmedSkill,
  DisplayedSkills,
  SkillsSelection,
  displayedSkillsEq,
  getDisplayedSkillsFromCandidate,
  onCreateSkill,
} from "../forms/inputs/SkillsSelect/displayedSkills";
import CityGeocodingSelect from "../forms/inputs/cityGeocodingSelect";

interface Props {
  candidate: Candidate;
  onSubmit: (data: CandidateData) => Promise<void>;
}

export const CandidateForm: FunctionComponent<Props> = ({ candidate, ...props }) => {
  const [loading, setLoading] = useState(false);
  const [currentJobTitle, setCurrentJobTitle] = useState<JobTitle>();
  const [selectedSkills, setSelectedSkills] = useState<DisplayedSkills[]>([]);
  const form = useFormManager<CandidateData>({
    defaultState: {
      ...candidate,
      first_job_at: candidate.first_job_at && dayjs(candidate.first_job_at).date(1).toISOString().split("T")[0],
      available_at: candidate.available_at && candidate.available_at.split("T")[0],
    },
    validations: {
      skills: {
        required: (skills?: UUID[]) => skills === undefined || skills.length === selectedSkills.length,
        message: "Merci de valider ou supprimer les compétences non validées",
      },
    },
  });

  useEffect(() => {
    form.onChange({
      skills: selectedSkills.reduce((acc, next) => {
        if (next.kind === "selection") acc.push(next.skill.id);
        return acc;
      }, [] as UUID[]),
    });
  }, [selectedSkills]);

  const initSkills = useCallback(async () => {
    const candidateSkills = await getDisplayedSkillsFromCandidate(candidate);
    setSelectedSkills(candidateSkills);
  }, [candidate]);

  useEffect(() => {
    if (candidate.job_title_id) fetchJobTitle(candidate.job_title_id).then(setCurrentJobTitle);
    initSkills();
  }, [candidate]);

  const onSubmit = async (data: CandidateData) => {
    const payload: CandidateData = {
      ...data,
      skills: selectedSkills.map((s) => (s as DisplayedConfirmedSkill).skill.id),
      full_name: data.first_name && data.last_name ? `${data.first_name} ${data.last_name}` : undefined,
    };
    try {
      setLoading(true);
      await props.onSubmit(payload);
    } catch {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="text-2xl mb-2">{`Éditer ${candidate.full_name || "Candidat anonyme"}`}</div>
      <div className="bg-inherit flex flex-col gap-4 py-2 pr-4 overflow-auto">
        <TextInput
          light
          label="Prénom"
          type="text"
          placeholder="Prénom"
          value={form.state.first_name}
          onChange={(e) => form.onChange({ first_name: e.currentTarget.value })}
        />
        <TextInput
          light
          label="Nom"
          type="text"
          placeholder="Nom"
          value={form.state.last_name}
          onChange={(e) => form.onChange({ last_name: e.currentTarget.value })}
        />
        <InputNumber
          light
          label="Salaire"
          placeholder="Salaire"
          value={form.state.salary}
          onChange={(e) => form.onChange({ salary: e })}
          min={0}
          controls={false}
          suffix="€"
          display={{
            formatter: (value) => value && value.replace(/\B(?=(\d{3})+(?!\d))/g, " "),
            parser: (value) => (value ? value.replace(/[\s]*/g, "") : ""),
          }}
          precision={2}
        />
        <CityGeocodingSelect
          label="Localisation"
          candidateId={candidate.id}
          onSelect={(locations) => form.onChange({ locations })}
        />
        <JobTitleSelect
          label="Titre de poste"
          currentJobTitle={currentJobTitle}
          onChange={(jobTitle) => {
            if (jobTitle) setCurrentJobTitle(jobTitle);
            form.onChange({ job_title_id: jobTitle?.id });
          }}
        />
        <SkillsSelection
          error={form.errors.skills}
          label="Compétences"
          selectedTitleCategory={currentJobTitle?.category_id}
          onChange={async (value) => {
            if (value) {
              if (selectedSkills.find((s) => displayedSkillsEq(s, value))) {
                setSelectedSkills(selectedSkills.filter((s) => !displayedSkillsEq(s, value)));
              } else {
                setSelectedSkills([...(selectedSkills || []), value]);
              }
            }
          }}
          onCreate={(skill) => setSelectedSkills((s) => onCreateSkill(skill, s))}
          selectedSkills={selectedSkills || []}
        />
        <div className="bg-inherit mt-2">
          <DatePicker
            label="Disponibilité"
            light
            value={form.state.available_at}
            onChange={(val) => form.onChange({ available_at: val })}
          />
        </div>
        <div className="w-full h-16 px-4">
          <div>Expérience</div>
          <Slider
            light
            size={51}
            defaultValue={dayjs().diff(dayjs(form.state.first_job_at), "year")}
            onChange={(e: number) => {
              const first_job_at = dayjs().subtract(e, "year").date(1).toISOString().split("T")[0];
              form.onChange({ first_job_at });
            }}
          />
        </div>
        <div className="flex justify-end mt-4">
          <Button
            light
            size="small"
            loading={loading}
            title="Enregistrer"
            disabled={form.disabled}
            onClick={async () => form.validate() && (await onSubmit(form.state))}
          />
        </div>
      </div>
    </>
  );
};

export default CandidateForm;
