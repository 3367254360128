import React, { FunctionComponent, useEffect, useState } from "react";
import { Candidate } from "../../models/candidate";
import dayjs from "dayjs";
import { UUID } from "../../models/common";
import {
  CondensedJobApplication,
  JobApplicationBase,
  JobApplicationData,
  updateJa,
} from "../../models/jobApplications";
import StatusSelect from "../forms/inputs/statusSelect";
import store, { Message } from "../../services/store";
import { Status } from "../../models/jobOffers";
import { getCallStatuses, getSources, getStatuses } from "../../services/requests";
import { Link } from "react-router-dom";
import CallStatusSelect from "../forms/inputs/callStatusSelect";
import { Modal, Tooltip, Table, ColumnProps } from "@getprorecrutement/getpro-design";
import toast from "react-hot-toast";
import { EnvelopeIcon, PhoneIcon } from "@heroicons/react/24/solid";
import LinkedinIcon from "../../utils/icons";
import { JobAppActions } from "../../pages/jobOffer/jobApplications/jobAppActions";
import MetaForm, { StepComponent, getSteps } from "../forms/metaForm";

const PER_PAGE = 5;

interface Props {
  candidate: Candidate;
  jobApps: CondensedJobApplication[];
  fetchJas: () => Promise<void>;
}

const JobApplications: FunctionComponent<Props> = (props) => {
  const [metaFormData, setMetaFormData] = useState<
    | undefined
    | {
        steps: StepComponent[];
        jobApp: JobApplicationBase;
        candidate: Candidate;
        onFinish: () => void;
      }
  >(undefined);
  const [statuses, setStatuses] = useState<Status[]>([]);
  const [callStatuses, setCallStatuses] = useState<Status[]>([]);
  const [sources, setSources] = useState<{ id: UUID; name: string }[]>([]);
  const [page, setPage] = useState<number>(1);

  const fetchMeta = async () => {
    getStatuses().then((s) => setStatuses(s.map((v) => ({ ...v, name: v.name.split(" ")[0] }))));
    getCallStatuses().then(setCallStatuses);
    getSources().then(setSources);
  };

  useEffect(() => {
    fetchMeta();
  }, []);

  const updateJaReq = async (data: JobApplicationData & { id: string }) => {
    const ja = await updateJa(data);

    // Embauché
    if (ja.status_id === data.status_id && data.status_id === "ce7d87f3-f446-499d-bc49-12112e78f131")
      store.notify(Message.Confetti);

    toast.success("Candidature mise à jour avec succès");
    props.fetchJas();
  };

  const updateStatusId = async (newStatusId: UUID, jobApp: JobApplicationBase, candidate: Candidate) => {
    const steps = getSteps(jobApp.status_id, newStatusId, statuses);
    if (steps.length) {
      setMetaFormData({
        steps,
        candidate,
        jobApp,
        onFinish: async () => {
          await updateJaReq({
            id: jobApp.id,
            status_id: newStatusId,
          });
        },
      });
    } else {
      await updateJaReq({ status_id: newStatusId, id: jobApp.id });
    }
  };

  const columns: ColumnProps<CondensedJobApplication>[] = [
    {
      title: "Offre",
      dataIndex: "job_offer_name",
      key: "job_offer_name",
      width: 200,
      render: (_, record: CondensedJobApplication) => {
        return (
          <Tooltip light title={record.job_offer_name}>
            <Link to={`/jobs/${record.job_offer_id}`}>
              <div className="truncate cursor-pointer">{record.job_offer_name}</div>
            </Link>
          </Tooltip>
        );
      },
    },
    {
      title: "Entreprise",
      dataIndex: "job_offer_company_name",
      key: "job_offer_company_name",
    },
    {
      width: 92,
      title: "Canaux",
      dataIndex: "contacted",
      key: "contacted",
      render: (_, record) => (
        <div className="flex items-center gap-2">
          <Tooltip position="top" light title={"Contacté par e-mail"}>
            <EnvelopeIcon
              width={16}
              height={16}
              className={record.contacted_by_email ? "text-primary-medium" : "text-primary-lightest"}
            />
          </Tooltip>
          <Tooltip position="top" light title={"Contacté par inMail"}>
            <LinkedinIcon className="w-4 h-4" fill={record.contacted_by_inmail ? "#7942ed" : "#cec2fc"} />
          </Tooltip>
          <Tooltip position="top" light title={"Contacté par téléphone"}>
            <PhoneIcon
              width={16}
              height={16}
              className={record.contacted_by_phone ? "text-primary-medium" : "text-primary-lightest"}
            />
          </Tooltip>
        </div>
      ),
    },
    {
      title: "Statut cold call",
      dataIndex: "call_status_id",
      key: "call_status_id",
      render: (csId: UUID, record) => (
        <CallStatusSelect
          size="small"
          disabled={record.terminated || record.job_offer_archived}
          callStatuses={callStatuses}
          value={csId}
          onSelect={(call_status_id) => updateJaReq({ id: record.id, call_status_id })}
        />
      ),
    },
    {
      title: "Statut",
      dataIndex: "status_id",
      key: "status_id",
      render: (sId: UUID, record) => (
        <StatusSelect
          size="small"
          disabled={record.terminated || record.job_offer_archived}
          statuses={statuses}
          value={sId}
          onSelect={(status_id) => updateStatusId(status_id, record, props.candidate)}
        />
      ),
    },
    {
      title: "Création",
      width: 150,
      dataIndex: "created_at",
      key: "created_at",
      render: (createdAt: string) => <div>{dayjs(createdAt).format("LL")}</div>,
      // defaultSortOrder: "descend",
      // sorter: (a: CondensedJobApplication, b: CondensedJobApplication) =>
      //   (dayjs(a.created_at).isAfter(dayjs(b.created_at)) ? 1 : -1) as number,
    },
    {
      title: "",
      dataIndex: "id",
      key: "actions",
      width: 120,
      render: (_: string, record: CondensedJobApplication) => (
        <JobAppActions
          jobApp={record}
          candidate={props.candidate}
          onUpdate={() => props.fetchJas()}
          updateStatusId={updateStatusId}
          statuses={statuses}
          callStatuses={callStatuses}
          sources={sources}
        />
      ),
    },
  ];

  const displayedJas = props.jobApps.filter((_, i) => i >= (page - 1) * PER_PAGE && i < page * PER_PAGE);

  return (
    <div>
      <Table
        rowClassName={(r) =>
          r.job_offer_archived || r.terminated ? "bg-background-lighter hover:bg-background-lighter" : ""
        }
        size="small"
        light
        bordered
        dataSource={{ data: displayedJas, total: props.jobApps.length }}
        columns={columns}
        pagination={{ per_page: PER_PAGE, page, onChange: setPage }}
      />
      <Modal light className="w-modal-lg" show={!!metaFormData} onClose={() => setMetaFormData(undefined)} maskClosable>
        {metaFormData && (
          <MetaForm
            key={`metaForm${metaFormData.jobApp.id}`}
            jobApp={metaFormData.jobApp as CondensedJobApplication}
            candidate={props.candidate}
            steps={metaFormData.steps}
            onFinish={() => {
              metaFormData.onFinish();
              setMetaFormData(undefined);
            }}
            onCancel={() => setMetaFormData(undefined)}
          />
        )}
      </Modal>
    </div>
  );
};

export default JobApplications;
