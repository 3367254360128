import React, { FunctionComponent, useState } from "react";
import { ContractResponse } from "../../../models/billing/contract";
import {
  getProjects,
  ProjectResponse,
  ProjectsFilters,
} from "../../../models/billing/project";
import { Paginated, UUID } from "../../../models/common";
import ProjectList from "../../../components/billing/projects/projectList";

interface Props {
  contract: ContractResponse;
  id?: UUID;
}

export const ContractProjectList: FunctionComponent<Props> = (props) => {
  const { contract, id } = props;
  const [projects, setProjects] = useState<Paginated<ProjectResponse>>();

  const fetchProjects = async (filters: ProjectsFilters) => {
    const projects = await getProjects({
      customer_id: contract.customer_id,
      ...filters,
      contract_id: contract.id,
    });
    setProjects(projects);
  };

  return (
    <ProjectList
      projects={projects}
      customerId={contract.customer_id}
      contractId={contract.id}
      fetchProjects={fetchProjects}
      returnPath={`/billing/contracts/${contract.id}`}
      id={id}
      edition
    />
  );
};

export default ContractProjectList;
