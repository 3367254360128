import React, { useEffect, useState } from "react";
import { FunctionComponent } from "react";
import dayjs from "dayjs";
import { FullBookingData } from ".";
import { euroDisplay } from "../../../../utils/formatters";
import { Link } from "react-router-dom";
import { Tooltip, Table, ColumnProps, Tag, TagColor } from "@getprorecrutement/getpro-design";

interface Props {
  bookings?: FullBookingData[];
}

export const DashboardBookingsList: FunctionComponent<Props> = (props) => {
  const { bookings } = props;
  const [sortBy, setSortBy] = useState<{
    key: keyof FullBookingData;
    sortDirection: undefined | "asc" | "desc";
  }>();
  const [page, setPage] = useState<number>(1);

  useEffect(() => {
    if (bookings) setPage(1);
  }, [bookings, sortBy]);

  const columns: ColumnProps<FullBookingData>[] = [
    {
      title: "Montant",
      dataIndex: "amount",
      key: "amount",
      width: 100,
      render: (value) => (
        <Tag
          value={value ? euroDisplay(value) : "À définir"}
          light
          bordered
          type={value ? (parseInt(value) > 0 ? TagColor.Success : TagColor.Error) : TagColor.Warning}
        />
      ),
    },
    {
      title: "Offre",
      dataIndex: "job_offer_name",
      key: "job_offer_name",
      width: 300,
      render: (value, record) =>
        value && (
          <Tooltip light title={value}>
            <Link to={`/jobs/${record.job_offer_id}`}>
              <div className="truncate">{value}</div>
            </Link>
          </Tooltip>
        ),
    },
    { title: "Propriétaire", dataIndex: "owner_name", key: "owner_name" },
    { title: "Entreprise", dataIndex: "customer_name", key: "customer_name" },
    {
      title: "Date",
      dataIndex: "created_at",
      key: "created_at",
      width: 150,
      sortable: true,
      render: (value) => <div>{dayjs(value).format("D MMM YYYY")}</div>,
    },
  ];

  const displayedBookings = (
    (sortBy && sortBy.key === "created_at"
      ? bookings?.sort((a, b) =>
          sortBy.sortDirection === "asc"
            ? dayjs(a.created_at).diff(dayjs(b.created_at))
            : dayjs(b.created_at).diff(dayjs(a.created_at))
        )
      : bookings) || []
  ).filter((_, i) => i >= (page - 1) * 5 && i < page * 5);

  return (
    <Table
      size="small"
      dataSource={{ data: displayedBookings, total: bookings?.length || 0 }}
      columns={columns}
      bordered
      light
      sortBy={sortBy}
      onSortDirectionChange={(key, direction) => {
        setSortBy({ key: key, sortDirection: direction });
      }}
      pagination={{
        per_page: 5,
        page: page,
        onChange: setPage,
      }}
    />
  );
};

export default DashboardBookingsList;
