import React, { FunctionComponent, Fragment, useCallback } from "react";
import { JobOffer, FullJobOffer, getJos } from "../../models/jobOffers";
import { Paginated } from "../../models/common";
import { useState, useEffect } from "react";
import JobOfferRowSkeleton from "../../components/jobOfferRow/skeleton";
import JobOfferRow from "../../components/jobOfferRow";
import store, { Stored } from "../../services/store";
import JobOfferForm from "../../components/forms/jobOfferForm";
import { useNavigate } from "react-router";
import CustomerReportForm from "../../components/forms/customerReportForm";
import { Button, Empty, Pagination, Modal, Tooltip, TextInput } from "@getprorecrutement/getpro-design";
import { ArchiveBoxIcon, DocumentIcon, HeartIcon, HomeIcon } from "@heroicons/react/24/outline";
import qs from "qs";
import { debounce } from "../../utils";

const PER_PAGE = 10;

interface Props {
  archived?: boolean;
}

// TODO REWORK
export const JobOffersPage: FunctionComponent<Props> = (props) => {
  const [jobOffers, setJobOffers] = useState<Paginated<FullJobOffer> | undefined>(undefined);
  const [loading, setLoading] = useState(true);
  const [ownedByMe, _setOwnedByMe] = useState<boolean>(!!store.state.JobOffersFilters?.owned_by_me);
  const [ownedByBu, _setOwnedByBu] = useState<boolean>(!!store.state.JobOffersFilters?.owned_by_bu);
  const [creation, setCreation] = useState<boolean>(false);
  const [editedJo, setEditedJo] = useState<JobOffer | undefined>();
  const [generateReport, setGenerateReport] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<string>();

  const { q, page } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  }) as {
    q: string | undefined;
    page: string | undefined;
  };

  useEffect(() => setSearchQuery(q), []);

  const navigate = useNavigate();
  const navigateDebounced = useCallback(debounce(navigate), []);

  const { archived } = props;

  const setOwnedByBu = (value: boolean) => {
    _setOwnedByBu(value);
    _setOwnedByMe(false);
  };

  const setOwnedByMe = (value: boolean) => {
    _setOwnedByMe(value);
    _setOwnedByBu(false);
  };

  const fetchJobOffers = async (): Promise<void> => {
    setLoading(true);
    getJos({
      page: page ? parseInt(page) : 1,
      per_page: PER_PAGE,
      archived: !!archived,
      owner_ids: store.state.JWT?.sub && ownedByMe ? [store.state.JWT?.sub] : undefined,
      search: q,
      bu_id: ownedByBu ? store.state.User?.business_unit_id : undefined,
    })
      .then(setJobOffers)
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    store.update(Stored.JobOffersFilters, {
      owned_by_me: ownedByMe,
      owned_by_bu: ownedByBu,
    });
  }, [ownedByMe, ownedByBu]);

  useEffect(() => {
    fetchJobOffers();
  }, [page, q, archived, ownedByMe, ownedByBu]);

  // Scroll up to job offers list list when changing page
  useEffect(() => {
    document.getElementById("jobOffers")?.scrollIntoView();
  }, [page]);

  return (
    <div id="jobOffers" className="rounded-3xl overflow-hidden flex-grow">
      <div className="bg-white p-6 mb-6">
        <div className="flex items-center justify-between bg-inherit mb-4">
          <div className="text-3xl font-bold">Offres d'emploi</div>
          <div className="flex items-center gap-3">
            <Tooltip position="bottom" light title="Générer un rapport">
              <Button
                size="small"
                onClick={() => setGenerateReport(true)}
                icon={<DocumentIcon />}
                light
                kind="outline"
              />
            </Tooltip>
            <Button
              size="small"
              onClick={() => setCreation(true)}
              style={{ float: "right" }}
              title="Créer une offre"
              light
            />
          </div>
        </div>
        <div className="flex items-center gap-6 bg-inherit flex-wrap">
          <TextInput
            type="search"
            value={searchQuery}
            onChange={(e) => {
              setSearchQuery(e.target.value);
              navigateDebounced({
                pathname: props.archived ? "/jobs/archived" : "/jobs",
                search: e.target.value.length > 0 ? qs.stringify({ q: e.target.value }) : undefined,
              });
            }}
            light
            className="min-w-[320px]"
            // label="Recherche"
            placeholder="Rechercher par nom, mail, entreprise"
          />
          <div className="flex items-center gap-4">
            <Button
              className={
                ownedByMe
                  ? "text-content-darker hover:text-content-darker px-0 py-1"
                  : "hover:text-content-darker text-content-light px-0 py-1"
              }
              onClick={() => setOwnedByMe(!ownedByMe)}
              icon={<HeartIcon />}
              title="Les miennes"
              kind="light"
              light
            />
            <Button
              className={
                props.archived
                  ? "text-content-darker hover:text-content-darker px-0 py-1"
                  : "hover:text-content-darker text-content-light px-0 py-1"
              }
              icon={<ArchiveBoxIcon />}
              light
              kind="light"
              title="Archivées"
              onClick={() => {
                navigate({
                  pathname: props.archived ? "/jobs" : "/jobs/archived",
                  search: q ? qs.stringify({ q }) : undefined,
                });
              }}
            />
            <Button
              className={
                ownedByBu
                  ? "text-content-darker hover:text-content-darker px-0 py-1"
                  : "hover:text-content-darker text-content-light px-0 py-1"
              }
              onClick={() => setOwnedByBu(!ownedByBu)}
              icon={<HomeIcon />}
              title="Ma BU"
              light
              kind="light"
            />
          </div>
        </div>
      </div>
      <div className="w-full px-7">
        {loading ? (
          <>
            {[...Array(PER_PAGE)].map((_, i) => (
              <div className="mb-2" key={i}>
                <JobOfferRowSkeleton />
              </div>
            ))}
          </>
        ) : (
          <Fragment>
            {jobOffers?.data && jobOffers?.data.length > 0 ? (
              jobOffers.data.map((jo) => (
                <div className="mb-2" key={jo.id}>
                  <JobOfferRow onEdit={setEditedJo} onUpdate={() => fetchJobOffers()} jobOffer={jo} />
                </div>
              ))
            ) : (
              <div style={{ marginTop: 100, marginBottom: 100 }}>
                <Empty light title={"Aucun résultat"} />
              </div>
            )}
            <div className="flex justify-end px-2 pb-2">
              <Pagination
                light
                pageSize={PER_PAGE}
                page={page ? parseInt(page) : 1}
                total={jobOffers?.total || 0}
                onChange={(page) => {
                  navigate({
                    pathname: props.archived ? "/jobs/archived" : "/jobs",
                    search: qs.stringify({ q, page: page > 1 ? page : undefined }),
                  });
                }}
              />
            </div>
          </Fragment>
        )}
      </div>
      <Modal
        light
        show={creation || !!editedJo}
        onClose={() => {
          setCreation(false);
          setEditedJo(undefined);
        }}
        className="w-modal-xl"
      >
        <JobOfferForm
          key={editedJo?.id || "new"}
          jobOffer={editedJo}
          onFinish={(jobOffer) => {
            if (editedJo) {
              setEditedJo(undefined);
              fetchJobOffers();
            } else navigate(`/jobs/${jobOffer.id}`);
          }}
        />
      </Modal>
      <Modal className="w-modal-xl" light show={generateReport} onClose={() => setGenerateReport(false)}>
        <CustomerReportForm />
      </Modal>
    </div>
  );
};

export default JobOffersPage;
