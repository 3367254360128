import React, { useState } from "react";
import BillAndBookingList from "../../../components/billing/billsAndBookingList";
import { BillResponse, BillsFilters, getBills } from "../../../models/billing/bill";
import { Booking, BookingsFilters, getBookings } from "../../../models/billing/booking";
import { ProjectType } from "../../../models/billing/project";
import { Paginated } from "../../../models/common";

export const BillingBillsPage = () => {
  const [bills, setBills] = useState<Paginated<BillResponse>>();
  const [bookings, setBookings] = useState<Paginated<Booking>>();

  const fetchBookings = async (filters: BookingsFilters) => {
    const b = await getBookings(filters);
    setBookings(b);
  };

  const fetchBills = async (filters: BillsFilters, project_types: ProjectType[]) => {
    const b = await getBills({
      ...filters,
      project_types,
    });
    setBills(b);
  };

  return (
    <div className="billingDashboard">
      <BillAndBookingList
        bills={bills}
        fetchBills={fetchBills}
        bookings={bookings}
        fetchBookings={fetchBookings}
        edition
      />
    </div>
  );
};

export default BillingBillsPage;
