import React, { FunctionComponent, useState } from "react";
import { useEffect } from "react";
import { NewContract, updateContract } from "../../../models/billing/contract";
import { UUID } from "../../../models/common";
import { Contract, createContract } from "../../../models/billing/contract";
import { Contact, getCustomerContacts } from "../../../models/contacts";
import ContactSelect from "../contacts/contactSelect";
import CustomerSelect from "../../forms/inputs/customerSelect";
import { TextInput, Button, InputNumber, Select } from "@getprorecrutement/getpro-design";
import UserSelect from "../../forms/inputs/userSelect";

interface Props {
  customerId?: UUID;
  contract?: Contract;
  onFinish: () => void;
}

const TVA_OPTIONS = [
  { label: "20 %", value: 20 },
  { label: "8.5 %", value: 8.5 },
  { label: "0 %", value: 0 },
];

const ContractForm: FunctionComponent<Props> = (props) => {
  const { contract, onFinish } = props;
  const [loading, setLoading] = useState(false);
  const [contacts, setContacts] = useState<Contact[]>([]);
  const [values, setValues] = useState<Partial<Contract>>(contract || { customer_id: props.customerId });

  const fetchContacts = async (id: UUID) => {
    const c = await getCustomerContacts(id);
    setContacts(c);
  };

  useEffect(() => {
    if (values.customer_id) fetchContacts(values.customer_id);
  }, [values.customer_id]);

  const submit = async (data: Partial<Contract>) => {
    setLoading(true);
    try {
      if (contract)
        await updateContract({
          ...data,
          id: contract.id,
        });
      else if (data.customer_id) await createContract(data as NewContract);
    } finally {
      setLoading(false);
      onFinish();
    }
  };

  return (
    <>
      <div className="mb-2 text-2xl">Contrat</div>
      <div className="flex flex-col gap-4 bg-inherit py-2 pr-4 overflow-auto">
        {!props.customerId && !contract && (
          <CustomerSelect
            onSelect={(e) => setValues({ ...values, customer_id: e })}
            value={values.customer_id}
            showSearch
            label="Entreprise"
          />
        )}
        <TextInput
          label="Nom"
          placeholder="Nom"
          type="text"
          value={values.name}
          onChange={(e) => setValues({ ...values, name: e.target.value })}
          light
        />
        <TextInput
          label="Description"
          placeholder="Description"
          type="text"
          value={values.description}
          onChange={(e) => setValues({ ...values, description: e.target.value })}
          light
        />
        <UserSelect
          onSelect={(id) => setValues({ ...values, owner_id: id })}
          value={values.owner_id}
          showSearch
          label="Propriétaire"
        />
        <InputNumber
          label="Délai de paiement"
          placeholder="Délai de paiement"
          value={values.bills_due_date_offset}
          onChange={(e) =>
            setValues({
              ...values,
              bills_due_date_offset: e,
            })
          }
          min={0}
          light
        />
        <Select
          light
          options={TVA_OPTIONS}
          value={TVA_OPTIONS.find((e) => e.value === values.vat_percent)}
          optionRender={(item) => item.label}
          getKey={(item) => item.value.toString()}
          onChange={(e) => e && setValues({ ...values, vat_percent: e.value })}
          type="single"
          bordered
          rounded
          label="TVA"
        />
        {values.customer_id && (
          <ContactSelect
            customerId={values.customer_id}
            value={values.legal_contact_id}
            onSelect={(id) => setValues({ ...values, legal_contact_id: id })}
            contacts={contacts}
            fetchContacts={fetchContacts}
            creation
            label="Contact légal"
          />
        )}
        {values.customer_id && (
          <ContactSelect
            customerId={values.customer_id}
            value={values.billing_contact_id}
            onSelect={(id) => setValues({ ...values, billing_contact_id: id })}
            contacts={contacts}
            fetchContacts={fetchContacts}
            creation
            label="Contact de facturation"
          />
        )}
        <div className="flex justify-end mt-2">
          <Button
            size="small"
            loading={loading}
            title="Enregister"
            light
            onClick={() => submit(values)}
            disabled={
              typeof values.bills_due_date_offset === "undefined" ||
              typeof values.vat_percent === "undefined" ||
              !values.billing_contact_id ||
              !values.legal_contact_id ||
              !values.owner_id ||
              !values.name ||
              !values.customer_id
            }
          />
        </div>
      </div>
    </>
  );
};

export default ContractForm;
