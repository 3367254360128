import React, { useState } from "react";
import { BillStatus } from "../../../models/billing/bill";
import { Checkbox } from "@getprorecrutement/getpro-design";
import { CheckCircleIcon, ClockIcon, InformationCircleIcon } from "@heroicons/react/24/outline";

interface Props {
  onSelect: (status: BillStatus[]) => void;
  defaultValue?: BillStatus[];
}

export const BillStatusMultiSelect = (props: Props) => {
  const { onSelect } = props;
  const [selected, setSelected] = useState<BillStatus[]>(props.defaultValue || []);

  const select = (status: BillStatus) => {
    let statuses = [];

    if (selected.includes(status)) statuses = selected.filter((s) => s !== status);
    else statuses = [...selected, status];

    setSelected(statuses);
    onSelect(statuses);
  };

  return (
    <div className="flex items-center gap-4">
      <Checkbox
        value={selected.includes(BillStatus.Pending)}
        onChange={() => select(BillStatus.Pending)}
        label={
          <div className="flex gap-1 items-center w-full">
            <ClockIcon className="text-orange-600 w-5 h-5" />
            <div>En attente</div>
          </div>
        }
        light
      />
      <Checkbox
        value={selected.includes(BillStatus.Billed)}
        onChange={() => select(BillStatus.Billed)}
        label={
          <div className="flex gap-1 items-center w-full">
            <InformationCircleIcon className="text-blue-600 w-5 h-5" />
            <div>Facturé</div>
          </div>
        }
        light
      />
      <Checkbox
        value={selected.includes(BillStatus.Paid)}
        onChange={() => select(BillStatus.Paid)}
        label={
          <div className="flex gap-1 items-center w-full">
            <CheckCircleIcon className="text-green-600 w-5 h-5" />
            <div>Payé</div>
          </div>
        }
        light
      />
    </div>
  );
};

export default BillStatusMultiSelect;
