import React from "react";
import { SearchedJobOffer } from ".";
import { Image } from "@getprorecrutement/getpro-design";

export const JobOfferResult = (props: SearchedJobOffer) => (
  <div className="flex p-2 items-center gap-4 bg-white rounded-full hover:bg-background-lightest transition">
    <div className="min-w-[32px] min-h-[32px] w-8 h-8 flex items-center justify-center bg-white rounded-full">
      <Image
        src={
          props.company_logo_id || "https://storage.googleapis.com/getpro-public/e1ca4dfa-7173-40a1-8f9a-917dc3f3556f"
        }
        fallback={"https://storage.googleapis.com/getpro-public/e1ca4dfa-7173-40a1-8f9a-917dc3f3556f"}
        className="max-w-full max-h-full rounded-full"
      />
    </div>
    <div>{props.name}</div>
  </div>
);
