import React, { useEffect, useState, FunctionComponent } from "react";
import { useParams } from "react-router";
import InlineLabelValue from "../../../components/inlineLabelValue";
import { Customer, CustomerResponse, getCustomer } from "../../../models/customers";
import { Contact, getCustomerContacts } from "../../../models/contacts";
import { Paginated, UUID } from "../../../models/common";
import CustomerForm from "../../../components/forms/customerForm";
import store, { Message } from "../../../services/store";
import ButtonCard from "../../../components/buttonCard";
import ContractList from "../../../components/billing/contracts/contractList";
import CustomerProjectList from "./projectList";
import { BillResponse, BillsFilters, getBills } from "../../../models/billing/bill";
import ContactsList from "../../../components/billing/contacts/contactsList";
import { TemplateData } from "../../../models/messenger";
import BillAndBookingList from "../../../components/billing/billsAndBookingList";
import { Booking, BookingsFilters, getBookings } from "../../../models/billing/booking";
import { ProjectType } from "../../../models/billing/project";
import { Divider, Button, Modal } from "@getprorecrutement/getpro-design";
import { MapPinIcon, PencilSquareIcon, UserIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";

export const BillingCustomerPage: FunctionComponent = () => {
  const { id, objectType, objectId } = useParams<{ id: UUID; objectType?: string; objectId?: UUID }>() as {
    id: UUID;
    objectType?: string;
    objectId?: UUID;
  };
  const [customer, setCustomer] = useState<CustomerResponse>();
  const [contacts, setContacts] = useState<Contact[]>([]);
  const [editing, setEditing] = useState<Customer>();
  const [bills, setBills] = useState<Paginated<BillResponse>>();
  const [bookings, setBookings] = useState<Paginated<Booking>>();

  const fetchBookings = async (filters: BookingsFilters) => {
    const b = await getBookings({ ...filters, per_page: 10, customer_id: id });
    setBookings(b);
  };

  const openMessenger = (contact: Contact) => {
    const templateData: TemplateData = { ...contact };
    store.notify(Message.Messenger, {
      label: `${contact.first_name} ${contact.last_name}`,
      to: contact.email,
      from: store.state.User?.email,
      templateData,
      closeAfterSend: true,
    });
  };

  const fetchBills = async (filters: BillsFilters, project_types: ProjectType[]) => {
    const b = await getBills({
      ...filters,
      per_page: 10,
      customer_id: id,
      project_types,
    });
    setBills(b);
  };

  useEffect(() => {
    fetchCustomer();
  }, [id]);

  const fetchCustomer = async () => {
    getContacts(id);
    const c = await getCustomer(id);
    setCustomer(c);
  };

  const onFinish = async (): Promise<void> => {
    setEditing(undefined);
    fetchCustomer();
  };

  const getContacts = async (id: UUID) => {
    const c = await getCustomerContacts(id);
    setContacts(c);
  };

  const customerProfile = (customer: CustomerResponse) => {
    return (
      <div className="bg-white p-6 shadow-md mb-6">
        <div className="flex justify-between items-center mb-4">
          <div className="flex items-center gap-4">
            {customer.file_id && (
              <img
                className="companyLogo"
                src={`https://storage.googleapis.com/getpro-public/${
                  customer.file_id || "e1ca4dfa-7173-40a1-8f9a-917dc3f3556f"
                }`}
              />
            )}
            <div className="text-3xl font-bold">{customer.name}</div>
          </div>
          <Button kind="light" onClick={() => setEditing(customer)} light icon={<PencilSquareIcon />} className="p-0" />
        </div>
        <div className="flex gap-4">
          <div className="flex-1">
            <div className="text-lg font-bold mb-2">Informations</div>
            {customer.description && (
              <InlineLabelValue label="Description" value={customer.description} bold={true} labelWidth={170} />
            )}
            <InlineLabelValue
              label="Adresse de facturation"
              value={customer.location.formatted_address}
              bold={true}
              icon={<MapPinIcon width={20} height={20} />}
              labelWidth={170}
            />
            <InlineLabelValue
              label={"Propriétaire"}
              value={`${customer.owner_first_name} ${customer.owner_last_name}`}
              bold={true}
              icon={<UserIcon width={20} height={20} />}
              labelWidth={170}
            />
          </div>
          <div className="flex-1">
            <div className="text-lg font-bold mb-2">Facturation</div>
            <InlineLabelValue label={"N° TVA"} value={customer.tax_number} bold={true} labelWidth={170} />
            <InlineLabelValue label={"N° SIRET"} value={customer.siret} bold={true} labelWidth={170} />
          </div>
        </div>
        <Divider light />
        <ContactsList contacts={contacts} openMessenger={openMessenger} />
      </div>
    );
  };

  const customerObjects = (customer: CustomerResponse) => {
    return (
      <div className="grid grid-cols-3 w-full gap-4 px-6">
        <Link to={`/billing/customers/${id}/contracts`}>
          <ButtonCard count={customer.contracts_count} title={"Contrats"} />
        </Link>
        <Link to={`/billing/customers/${id}/projects`}>
          <ButtonCard title={"Projets"} count={customer.projects_count} />
        </Link>
        <Link to={`/billing/customers/${id}/bills`}>
          <ButtonCard title={"Factures"} count={customer.bills_count} />
        </Link>
      </div>
    );
  };

  const customerObjectsType = (customer: Customer, type: string) => {
    switch (type) {
      case "contracts":
        return <ContractList customer={customer} onUpdate={fetchCustomer} />;
      case "projects":
        return <CustomerProjectList customer={customer} id={objectId} />;
      case "bills":
        return (
          <BillAndBookingList
            bills={bills}
            fetchBills={fetchBills}
            bookings={bookings}
            fetchBookings={fetchBookings}
            customerId={customer.id}
            edition
            returnPath={`/billing/customers/${id}`}
          />
        );
    }
  };

  return (
    <div>
      {customer && customerProfile(customer)}
      {customer && !objectType && customerObjects(customer)}
      {customer && objectType && customerObjectsType(customer, objectType)}
      <Modal onClose={onFinish} show={!!editing} className="w-modal-xl" light>
        <CustomerForm key={id} onFinish={onFinish} customer={editing} />
      </Modal>
    </div>
  );
};

export default BillingCustomerPage;
