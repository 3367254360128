import React, { FunctionComponent, useCallback, useEffect, useState } from "react";
import { MetaFormStepProps } from "..";
import { UUID } from "../../../../models/common";
import { useFormManager } from "../../../../services/form";
import {
  DisplayedSkills,
  SkillsSelection,
  displayedSkillsEq,
  getDisplayedSkillsFromCandidate,
  onCreateSkill,
} from "../../inputs/SkillsSelect/displayedSkills";
import { updateCandidate } from "../../../../models/candidate";
import { tracker } from "../../../..";
import { JobTitleRgpdForm } from "../../JobTitleRgpdForm";
import { Button } from "@getprorecrutement/getpro-design";
import { Skill } from "../../../../models/skill";

type FormType = {
  job_title_id?: UUID;
  skills: UUID[];
};

export const JobTitleStep: FunctionComponent<MetaFormStepProps> = ({ onSubmit, candidate, jobApp, onCancel }) => {
  const [selectedSkills, setSelectedSkills] = useState<DisplayedSkills[]>([]);
  const [loading, setLoading] = useState<boolean>();
  const [selectedCategoryId, setSelectedCategoryId] = useState<UUID>();
  const form = useFormManager<FormType>({
    defaultState: {},
    validations: {
      job_title_id: {
        required: true,
        message: "Merci de choisir un titre de poste",
      },

      skills: {
        required: (skills) => skills !== undefined,
        message: "Merci de valider ou supprimer les compétences non validées",
      },
    },
  });

  useEffect(() => {
    if (!selectedSkills.find((s) => s.kind === "raw_input")) {
      form.onChange({ skills: selectedSkills.map((s) => (s.skill as Skill).id) });
    } else {
      form.onChange({ skills: undefined });
    }
  }, [selectedSkills]);

  const initSkills = useCallback(async () => {
    setLoading(true);
    const candidateSkills = await getDisplayedSkillsFromCandidate(candidate);
    setSelectedSkills(candidateSkills);
    setLoading(false);
  }, [candidate]);

  useEffect(() => {
    initSkills();
  }, [candidate]);

  useEffect(() => tracker.event("jobTitleForm", { candidateId: candidate.id }), []);

  const submit = async () => {
    if (form.validate()) {
      setLoading(true);
      try {
        const candidateUpdated = await updateCandidate({ id: candidate.id, ...form.state });
        setLoading(false);
        onSubmit(candidateUpdated, jobApp);
      } catch {
        setLoading(false);
      }
    }
  };

  const onSkillChange = (skill: DisplayedSkills) => {
    if (skill) {
      let newSelectedSkills: DisplayedSkills[] = [];
      if (selectedSkills.find((s) => displayedSkillsEq(s, skill))) {
        newSelectedSkills = selectedSkills.filter((s) => !displayedSkillsEq(s, skill));
      } else {
        newSelectedSkills = [...(selectedSkills || []), skill];
      }
      setSelectedSkills(newSelectedSkills);
    }
  };

  return (
    <>
      <JobTitleRgpdForm
        candidate={candidate}
        error={form.errors.job_title_id}
        onChange={(title) => {
          setSelectedCategoryId(title?.category_id);
          form.onChange({ job_title_id: title?.id });
        }}
      />
      <div className="font-bold my-3">Choisir ou confirmer les compétences du candidat</div>
      <SkillsSelection
        error={form.errors.skills}
        selectedTitleCategory={selectedCategoryId}
        onChange={onSkillChange}
        onCreate={(skill) => setSelectedSkills((s) => onCreateSkill(skill, s))}
        selectedSkills={selectedSkills}
      />
      <div className="flex justify-between mt-8">
        <Button size="small" kind="outline" light title="Retour" onClick={onCancel} />
        <Button size="small" light loading={loading} disabled={form.disabled} title="Enregistrer" onClick={submit} />
      </div>
    </>
  );
};
