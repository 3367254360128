import classNames from "classnames";
import React, { FunctionComponent, useEffect, useRef, useState } from "react";

export interface Props {
  description: string;
  rows?: number;
  symbol?: string;
  closeSymbol?: string;
  size?: "small" | "medium" | "large";
  dark?: boolean;
  light?: boolean;
}

export const Paragraph: FunctionComponent<Props & React.HTMLAttributes<HTMLDivElement>> = ({
  description,
  rows = 2,
  symbol = "Voir plus",
  closeSymbol = "Voir moins",
  size = "medium",
  dark,
  light,
  ...props
}) => {
  const [showMore, setShowMore] = useState<boolean>(false);
  const [isClamped, setIsClamped] = useState<boolean>(false);
  const textRef = useRef<HTMLDivElement>(null);

  const wrapperClasses = classNames("w-full relative bg-inherit", {
    dark: dark,
  });

  const sizeClasses = classNames("", {
    "text-base": size === "small",
    "text-lg": size === "medium",
    "text-2xl": size === "large",
  });

  const descriptionClasses = classNames("text-content-darker bg-inherit", {
    "dark:text-content-bright": !light,
    "line-clamp": !showMore,
  });

  useEffect(() => {
    if (textRef.current) {
      const isClamped = textRef.current.scrollHeight > textRef.current.clientHeight;
      if (isClamped) setIsClamped(isClamped);
    }
  }, [textRef.current]);

  return (
    <div {...props} className={wrapperClasses}>
      <div ref={textRef} style={{ WebkitLineClamp: rows }} className={`${descriptionClasses} ${sizeClasses}`}>
        {description}
      </div>
      {isClamped && (
        <div onClick={() => setShowMore(!showMore)} className="absolute right-0 -bottom-6 cursor-pointer text-blue-500">
          {showMore ? closeSymbol : symbol}
        </div>
      )}
    </div>
  );
};
