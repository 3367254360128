import classNames from "classnames";
import React, { FunctionComponent, useEffect, useState } from "react";
import { twMerge } from "tailwind-merge";
import DatePickerComponent from "react-date-picker/dist/entry.nostyle";
import { CalendarIcon, XIcon } from "@heroicons/react/outline";
import dayjs from "dayjs";
import { labelClasses, textInputClasses, wrapperInputClasses } from "../../../../utils/common";

export interface Props {
  // value format is YYYY-MM-DD
  value?: string;
  onChange: (val?: string) => void;
  size?: "small" | "medium" | "large";
  disabled?: boolean;
  width?: number;
  label?: string;
  dark?: boolean;
  light?: boolean;
  error?: string;
  bordered?: boolean;
  defaultOpen?: boolean;
  allowClear?: boolean;
  inputClassName?: string;
  showIcon?: boolean;
}

export const DatePicker: FunctionComponent<Props & Omit<React.HTMLAttributes<HTMLDivElement>, "onChange">> = ({
  value,
  onChange,
  size = "medium",
  disabled,
  width,
  label,
  dark,
  light,
  error,
  bordered = true,
  defaultOpen = false,
  allowClear = false,
  inputClassName,
  showIcon = false,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(defaultOpen);

  const wrapperClasses = twMerge(
    classNames("bg-inherit", {
      dark: dark,
    })
  );

  const groupClasses = twMerge(
    classNames("datePicker w-full relative bg-inherit group", {
      "cursor-pointer": !disabled,
      [`w-[${width}px]`]: !!width,
      "w-full": !width,
    })
  );

  const valueClasses = twMerge(
    classNames(
      [
        wrapperInputClasses({ size, disabled, light, bordered, focused: isOpen, error }),
        textInputClasses({ size, disabled, light, error }),
      ].join(" "),
      {
        [`${inputClassName}`]: !!inputClassName,
      }
    )
  );

  const iconClasses = classNames("stroke-2", {
    "w-4 h-4": size === "small",
    "w-4.5 h-4.5": size === "medium",
    "w-5 h-5": size === "large",
  });

  const calendarClasses = classNames("overflow-hidden my-2 rounded-3xl", {
    "dark:bg-background-dark": !light,
  });

  const errorClasses = classNames("mt-1 italic text-error-normal", {
    "pl-3 text-xs": size === "small",
    "pl-5 text-sm": size === "medium",
    "pl-8": size === "large",
  });

  return (
    <div className={wrapperClasses}>
      <div
        {...props}
        onClick={() => !isOpen && !disabled && setIsOpen(true)}
        onFocus={() => !isOpen && !disabled && setIsOpen(true)}
        className={groupClasses}
      >
        {label && <label className={labelClasses({ size, disabled, light, focused: isOpen, error })}>{label}</label>}
        <DatePickerComponent
          dayPlaceholder={"dd"}
          monthPlaceholder={"mm"}
          yearPlaceholder={"yyyy"}
          format={"dd-MM-y"}
          locale="fr-FR"
          calendarClassName={calendarClasses}
          className={valueClasses}
          onChange={(e?: Date) => {
            onChange(e ? dayjs(e.toString()).format("YYYY-MM-DD") : undefined);
          }}
          value={value ? new Date(value) : undefined}
          isOpen={isOpen}
          disabled={disabled}
          onCalendarClose={() => setIsOpen(false)}
          calendarIcon={showIcon ? <CalendarIcon className={iconClasses} /> : null}
          clearIcon={allowClear ? <XIcon className={iconClasses} /> : null}
          required={false}
        />
        {error && <div className={errorClasses}>{error}</div>}
      </div>
    </div>
  );
};
