{
  "private": true,
  "name": "sweet",
  "version": "3.2.0",
  "license": "MIT",
  "scripts": {
    "clean": "rm -rf ../static/sweet/* .parcel-cache/",
    "start": "parcel serve ./src/index.html",
    "build": "yarn clean && parcel build ./src/index.html",
    "lint": "yarn eslint ."
  },
  "eslintIgnore": [
    "build/*"
  ],
  "dependencies": {
    "@getprorecrutement/getpro-design": "0.1.224",
    "@heroicons/react": "^2.0.16",
    "@openreplay/tracker": "8.0.0",
    "@openreplay/tracker-assist": "6.0.0",
    "@sentry/react": "^7.43.0",
    "@sentry/tracing": "^7.43.0",
    "@superset-ui/embedded-sdk": "^0.1.0-alpha.9",
    "@tiptap/core": "^2.0.0-beta.200",
    "@tiptap/extension-link": "^2.0.0-beta.220",
    "@tiptap/pm": "^2.0.0-beta.220",
    "@tiptap/react": "^2.0.0-beta.220",
    "@tiptap/starter-kit": "^2.0.0-beta.220",
    "@types/react-beautiful-dnd": "^13.1.3",
    "array-move": "^4.0.0",
    "axios": "^1.3.4",
    "classnames": "^2.3.2",
    "dayjs": "^1.11.7",
    "frappe-charts": "^1.6.2",
    "jwt-decode": "^3.1.2",
    "md5": "^2.3.0",
    "meilisearch": "^0.32.3",
    "qs": "^6.11.1",
    "rc-picker": "^3.3.3",
    "react": "^17.0.1",
    "react-beautiful-dnd": "^13.1.1",
    "react-confetti": "^6.1.0",
    "react-dom": "^17.0.1",
    "react-frappe-charts": "^4.1.0",
    "react-hot-toast": "^2.4.0",
    "react-router": "^6.9.0",
    "react-router-dom": "^6.9.0",
    "react-use": "^17.4.0",
    "recharts": "^2.4.3"
  },
  "devDependencies": {
    "@parcel/resolver-glob": "2.8.3",
    "@parcel/transformer-less": "2.8.3",
    "@types/md5": "^2.3.2",
    "@types/node": "^18.15.5",
    "@types/qs": "^6.9.7",
    "@types/react": "^17.0.0",
    "@types/react-dom": "^17.0.0",
    "@types/react-router-dom": "^5.3.3",
    "@typescript-eslint/eslint-plugin": "^5.55.0",
    "@typescript-eslint/parser": "^5.55.0",
    "buffer": "^5.5.0",
    "crypto-browserify": "^3.12.0",
    "eslint": "^8.36.0",
    "eslint-config-airbnb": "^19.0.4",
    "eslint-plugin-import": "^2.27.5",
    "eslint-plugin-jsx-a11y": "^6.7.1",
    "eslint-plugin-react": "^7.32.2",
    "eslint-plugin-react-hooks": "^4.6.0",
    "events": "^3.1.0",
    "less": "^4.1.3",
    "parcel": "2.8.3",
    "postcss": "^8.4.21",
    "process": "^0.11.10",
    "stream-browserify": "^3.0.0",
    "tailwindcss": "^3.2.7",
    "typescript": "^4.9.5"
  },
  "browserslist": [
    "> 1%",
    "not dead"
  ]
}
