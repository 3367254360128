import httpClient from "../services/http/client";
import ROUTES from "../services/http/routes";
import { UUID } from "./common";

export interface Category {
  id: string;
  title: string;
  parent_id?: string;
}

export interface CategoryTreeElem {
  category: Category;
  childs: CategoryTreeElem[];
}

export const fetchJobTitleCategories = (params?: { ids?: UUID[] }): Promise<Category[]> =>
  httpClient.req(ROUTES.FETCH_JOB_TITLE_CATEGORIES(params));

export const fetchCategoryPath = (id: UUID): Promise<Category[]> => httpClient.req(ROUTES.FETCH_CATEGORY_PATH(id));
