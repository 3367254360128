import React, { FunctionComponent } from "react";
import ContractsList from "../../../components/billing/contracts/contractList";

export const BillingContractsPage: FunctionComponent = () => {
  return (
    <div>
      <ContractsList />
    </div>
  );
};

export default BillingContractsPage;
