import React, { FunctionComponent, useEffect, useState } from "react";
import { Project, getProjects, getProject } from "../../../models/billing/project";
import { ContractResponse, getContracts } from "../../../models/billing/contract";
import { Paginated, UUID } from "../../../models/common";
import dayjs from "dayjs";
// import { euroDisplay } from "../../../utils/formatters";
import { Booking, BookingData, createBooking, NewBooking, updateBooking } from "../../../models/billing/booking";
import CustomerSelect from "../../forms/inputs/customerSelect";
import { Select, Button, InputNumber, DatePicker } from "@getprorecrutement/getpro-design";

interface Props {
  booking?: Booking;
  contractId?: UUID;
  customerId?: UUID;
  projectId?: UUID;
  onFinish: () => void;
}

export const BookingForm: FunctionComponent<Props> = (props) => {
  const { booking } = props;
  const [loading, setLoading] = useState(false);
  const [project, setProject] = useState<Project>();
  const [contracts, setContracts] = useState<Paginated<ContractResponse>>();
  const [projects, setProjects] = useState<Paginated<Project>>();
  const [contractSearch, setContractSearch] = useState<string>("");
  const [projectSearch, setProjectSearch] = useState<string>("");
  const [values, setValues] = useState<Partial<Booking>>(
    booking
      ? { ...booking, created_at: dayjs(booking.created_at).format("YYYY-MM-DD") }
      : { customer_id: props.customerId, contract_id: props.contractId, project_id: props.projectId }
  );

  if (props.projectId && (!props.customerId || !props.contractId))
    throw "projectId without customerId or contractId (props of BillForm)";

  const fetchContracts = async () => {
    if (props.projectId) return;
    const c = await getContracts({
      customer_id: values.customer_id,
      page: 1,
      per_page: 100,
    });
    setContracts(c);
  };

  const fetchProjects = async () => {
    if (props.projectId) return;
    const p = await getProjects({
      contract_id: values.contract_id,
      page: 1,
      per_page: 100,
    });
    setProjects(p);
  };

  const fetchProject = async (id: UUID) => {
    const p = await getProject(id);
    setProject(p);
  };

  useEffect(() => {
    if (values.customer_id) fetchContracts();
  }, [values.customer_id]);

  useEffect(() => {
    if (values.contract_id) fetchProjects();
  }, [values.contract_id]);

  useEffect(() => {
    if (values.project_id) fetchProject(values.project_id);
  }, [values.project_id]);

  const submit = async (data: Partial<Booking>) => {
    setLoading(true);
    try {
      if (booking) {
        const save = {
          id: booking.id,
          ...data,
        } as BookingData & { id: string };
        await updateBooking(save);
      } else {
        const bill = {
          project_id: props.projectId,
          ...data,
        } as NewBooking;
        await createBooking(bill);
      }
      props.onFinish();
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="mb-2 text-2xl">Détails du booking</div>
      <div className="flex flex-col gap-4 bg-inherit py-2 pr-4 overflow-auto">
        {!props.customerId && !booking && (
          <CustomerSelect
            onSelect={(id) => setValues({ ...values, customer_id: id })}
            value={values.customer_id}
            showSearch
            label="Entreprise"
          />
        )}
        {!props.contractId && !booking && contracts && (
          <Select
            options={contracts.data.filter(
              (c) => contractSearch === "" || c.name.toLowerCase().includes(contractSearch.toLowerCase())
            )}
            disabled={!values.customer_id}
            value={contracts.data.find((c) => c.id === values.contract_id)}
            type="single"
            optionRender={(item) => item.name}
            getKey={(item) => item.id}
            label="Contrat"
            onChange={(e) => e && setValues({ ...values, contract_id: e.id })}
            light
            bordered
            rounded
            onSearch={(v) => {
              setContractSearch(v);
              if (values.contract_id) setValues({ ...values, contract_id: undefined });
            }}
          />
        )}
        {!props.projectId && !booking && projects && (
          <Select
            options={projects.data.filter(
              (p) => projectSearch === "" || p.name.toLowerCase().includes(projectSearch.toLowerCase())
            )}
            disabled={!values.contract_id}
            type="single"
            label="Projet"
            optionRender={(item) => item.name}
            getKey={(item) => item.id}
            value={projects.data.find((p) => p.id === values.project_id)}
            onChange={(e) => e && setValues({ ...values, project_id: e.id })}
            light
            bordered
            rounded
            onSearch={(v) => {
              setProjectSearch(v);
              if (values.project_id) setValues({ ...values, project_id: undefined });
            }}
          />
        )}
        {(booking || project) && (
          <>
            <InputNumber
              value={values.amount}
              onChange={(e) => setValues({ ...values, amount: e })}
              suffix="€"
              placeholder="5 000 €"
              label="Montant"
              display={{
                formatter: (value) => value && value.replace(/\B(?=(\d{3})+(?!\d))/g, " "),
                parser: (value) => (value ? value.replace(/[\s€]*/g, "") : ""),
              }}
              precision={2}
              light
            />
            <DatePicker
              label="Date de création"
              light
              value={values.created_at}
              onChange={(val) => setValues({ ...values, created_at: val })}
            />
          </>
        )}

        <div className="flex justify-end mt-2">
          <Button
            loading={loading}
            size="small"
            disabled={
              !values.customer_id || !values.contract_id || !values.project_id || !values.created_at || !values.amount
            }
            onClick={() => submit(values)}
            title="Enregistrer"
            light
          />
        </div>
      </div>
    </>
  );
};

export default BookingForm;
