import React, { FunctionComponent } from "react";

export interface Props {
  onDrop: () => void;
}

export const DroppableZone: FunctionComponent<React.HTMLAttributes<HTMLDivElement> & Props> = ({
  onDrop,
  children,
  ...props
}) => {
  const allowDrop = (ev: React.DragEvent) => {
    ev.preventDefault();
  };

  return (
    <div
      onDrop={(event) => {
        event.preventDefault();
        onDrop();
      }}
      onDragOver={allowDrop}
      {...props}
    >
      {children}
    </div>
  );
};
