import { toast } from "react-hot-toast";
import httpClient from "../../services/http/client";
import ROUTES, { PaginationParams } from "../../services/http/routes";
import { notifyError } from "../../services/requests";
import { Paginated, UUID } from "../common";
import { ProjectType } from "./project";

export enum BillStatus {
  Pending = "pending",
  Billed = "billed",
  Paid = "paid",
}

export interface BillResponse extends Bill {
  customer_name: string;
  project_name: string;
  contract_name: string;
  contract_vat_percent: number;
}

export interface BillLine {
  amount?: number;
  note?: string;
  description: string;
  quantity?: number;
}

export interface Bill extends NewBill {
  id: UUID;
  status: BillStatus;
  created_at: string;
  updated_at: string;
  file_id?: UUID;
  bill_ref?: UUID;
}

export interface NewBill {
  customer_id: UUID;
  contract_id: UUID;
  status?: BillStatus;
  due_date: string;
  amount?: number;
  manual?: boolean;
  project_id: UUID;
  billed_at: string;
  paid_at?: string;
  due_date_offset: number;
  lines: BillLine[];
  retainer: boolean;
}

export interface BillData {
  status?: BillStatus;
  due_date?: string;
  amount?: number;
  billed_at?: string;
  paid_at?: string;
  due_date_offset?: number;
  lines?: BillLine[];
  retainer?: boolean;
}

export type BillsFilters = {
  customer_id?: UUID;
  contract_id?: UUID;
  project_id?: UUID;
  status?: BillStatus[];
  field?: string;
  order?: string;
} & PaginationParams;

export async function getBills(
  params: BillsFilters & {
    project_types?: ProjectType[];
  }
): Promise<Paginated<BillResponse>> {
  return httpClient.req(ROUTES.FETCH_BILLS(params), (err) =>
    notifyError(err || "Erreur lors de la récupération des factures")
  );
}

export async function getBill(id: UUID): Promise<Bill> {
  return httpClient.req(ROUTES.FETCH_BILL(id), (err) =>
    notifyError(err || "Erreur lors de la récupération de la facture")
  );
}

export async function createBill(new_bill: NewBill): Promise<Bill> {
  return httpClient
    .req<Bill>(ROUTES.CREATE_BILL(new_bill), (err) => notifyError(err || "Erreur lors de la création de la facture"))
    .then((e) => {
      toast.success("Facture créée avec succès");
      return e;
    });
}

export async function generateZip(period: { from: string; to: string }): Promise<void> {
  return httpClient
    .req(ROUTES.GENERATE_BILL_ZIP(period), (err) => notifyError(err || "Erreur lors la génération des factures"))
    .then(() => {
      toast.success("Archive générée avec succès");
    });
}

export async function updateBill(data: BillData & { id: UUID }): Promise<Bill> {
  return httpClient
    .req<Bill>(ROUTES.UPDATE_BILL(data), (err) => notifyError(err || "Erreur lors de la mis à jour de la facture"))
    .then((e) => {
      toast.success("Facture mise à jour avec succès");
      return e;
    });
}

export async function deleteBill(id: UUID): Promise<void> {
  return httpClient
    .req(ROUTES.DELETE_BILL(id), (err) => notifyError(err || "Erreur lors de la suppression de la facture"))
    .then(() => {
      toast.success("Facture supprimé avec succès");
    });
}

export async function generateBill(id: UUID): Promise<void> {
  return httpClient.req(ROUTES.GENERATE_BILL(id), (err) =>
    notifyError(err || "Erreur lors de la communication avec le service de génération de pdf")
  );
}

export async function getCsvResults(id: UUID, period: { from: string; to: string }): Promise<{ url: string }> {
  const signature = await httpClient.req<string>(ROUTES.SIGN_FILE(id), (err) =>
    notifyError(err || "Erreur lors la signature du fichier")
  );
  return {
    url: httpClient.genUrlWithParams(ROUTES.CSV_RESULTS(id, signature, period)),
  };
}
