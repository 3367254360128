import React from "react";
import { FunctionComponent } from "react";
import { ContactedData } from "../../../../models/dashboards";
import dayjs from "dayjs";
import { Bar, BarChart, ResponsiveContainer, XAxis, YAxis, Tooltip, TooltipProps, LabelList } from "recharts";
import { PALETTE } from "../..";
import { Radio, Spinner } from "@getprorecrutement/getpro-design";
import { EnvelopeIcon, PhoneIcon } from "@heroicons/react/24/solid";
import LinkedinIcon from "../../../../utils/icons";
import { getCustomTooltipDate, renderCustomizedLabel } from "../../elements/timeline";

const IconsTooltip: { [key: string]: JSX.Element } = {
  ["email"]: <EnvelopeIcon className="w-5 h-5" />,
  ["phone"]: <PhoneIcon className="w-5 h-5" />,
  ["inmail"]: <LinkedinIcon fill={"#FFFFFF"} />,
};

const ColorsTooltip: { [key: string]: string } = {
  ["email"]: PALETTE.AQUA[0],
  ["phone"]: PALETTE.AQUA[2],
  ["inmail"]: PALETTE.AQUA[4],
};

interface Props {
  timeline?: ContactedData[];
  setGranularity: (value: "day" | "week" | "month") => void;
  granularity: "day" | "week" | "month";
  showJos: boolean;
  locked: boolean;
}

export const ContactedTimeline: FunctionComponent<Props> = (props) => {
  const { timeline, setGranularity, granularity, showJos, locked } = props;

  const tl = timeline || [];
  const barSize = tl.length <= 2 ? 100 : undefined;
  const padding = tl.length > 0 && tl.length < 3 ? tl.length * 100 : undefined;
  const paddingProp = padding ? { left: padding, right: padding } : "gap";

  const CustomTooltip = ({ active, payload, label }: TooltipProps<number, string>) => {
    if (active && payload && payload.filter((p) => p.value && p.value > 0).length) {
      return (
        <div className="DashboardCustomTooltip">
          {getCustomTooltipDate(label, granularity)}
          {payload
            .filter((p) => p.value && p.value > 0)
            .map((p) => (
              <div
                key={p.dataKey}
                className="DashboardCustomTooltipData"
                style={{
                  backgroundColor: p.name && ColorsTooltip[p.name],
                }}
              >
                <span className="DashboardCustomTooltipLabel">{p.name && IconsTooltip[p.name]}</span>
                <span className="DashboardCustomTooltipValue">{`${p.value}`}</span>
              </div>
            ))}
        </div>
      );
    }

    return null;
  };

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: 284,
        marginBottom: showJos || !locked ? 8 : 24,
      }}
    >
      {timeline ? (
        <div style={{ width: "100%" }}>
          <div className="mb-6 flex justify-between items-center">
            <Radio
              light
              optionType="button"
              options={[
                { label: "Jours", value: "day" },
                { label: "Semaine", value: "week" },
                { label: "Mois", value: "month" },
              ]}
              value={granularity}
              onChange={(v) => setGranularity(v as "day" | "week" | "month")}
            />
          </div>
          <ResponsiveContainer width="100%" height={200}>
            <BarChart
              margin={{ left: 0, right: 0 }}
              data={timeline.map((t) => ({
                ...t,
                contacted_at: dayjs(t.contacted_at).valueOf(),
              }))}
            >
              <XAxis
                dataKey="contacted_at"
                scale="time"
                type="number"
                axisLine={false}
                tickLine={false}
                padding={paddingProp}
                domain={["auto", "auto"]}
                tickFormatter={(tick) => dayjs(tick).format("DD/MM/YY")}
              />
              <YAxis padding={{ top: 20, bottom: 0 }} axisLine={false} tickLine={false} />
              <Tooltip cursor={false} content={CustomTooltip} />
              <Bar barSize={barSize} dataKey="email" stackId="a" fill={PALETTE.AQUA[0]} />
              <Bar barSize={barSize} dataKey="phone" stackId="a" fill={PALETTE.AQUA[2]} />
              <Bar barSize={barSize} dataKey="inmail" stackId="a" fill={PALETTE.AQUA[4]}>
                <LabelList position="top" dataKey="total" content={renderCustomizedLabel} />
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </div>
      ) : (
        <Spinner light />
      )}
    </div>
  );
};

export default ContactedTimeline;
