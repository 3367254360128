import classNames from "classnames";

import { selectHandler } from "./base";
import React, { FunctionComponent } from "react";
import { Portal } from "../../../Portal";

export interface SelectPortalProps {
  onChange?: (show: boolean) => void;
}

export const SelectPortal: FunctionComponent<SelectPortalProps> = ({ onChange }) => {
  return <Portal id="select-portal" onChange={onChange} handler={selectHandler} />;
};
