import React, { FunctionComponent, useEffect, useState } from "react";
import {
  convertTemporalityToUtc,
  createTemporality,
  getGranularity,
  getWeekday,
  Granularity,
  NewSignal,
  Signal,
  SignalFilters,
  Weekday,
} from "../../models/signals";
import { Button, Radio, Select, TextInput } from "@getprorecrutement/getpro-design";
import { SIGNAL_GRANULARITY, SignalCategoryEdit, SignalEdit, WEEKDAY } from "./companySignals";
import { NominatimLocationResponse, UUID } from "../../models/common";
import { SignalPreview } from "./signalPreview";
import { Category, fetchJobTitleCategories } from "../../models/jobTitleCategory";
import { SignalFiltersSelect } from "./signalFiltersSelect";
import { CitySelect } from "../../components/forms/inputs/cityGeocodingSelect";

interface Props {
  newSignalTargetId?: UUID;
  signal?: SignalEdit;
  onSubmit: (data: Partial<Signal>) => Promise<void>;
}

export const SignalForm: FunctionComponent<Props> = ({ newSignalTargetId, signal, onSubmit }) => {
  const [showPreview, setShowPreview] = useState<NewSignal>();
  const [targetId] = useState<UUID | undefined>(newSignalTargetId || signal?.signal.target_id);
  const [values, setValues] = useState<Partial<Signal>>(signal?.signal || {});

  const onPreview = () => {
    if (values.send_on && values.filters && values.filters.categories && targetId) {
      setShowPreview({
        target_id: targetId,
        send_on: values.send_on,
        filters: values.filters,
      });
    }
  };

  return (
    <>
      <div className="text-2xl mb-2 text-center">{`${signal ? "Editer" : "Créer"} un signal`}</div>
      <div className="bg-inherit py-2 p-4 overflow-auto">
        {showPreview ? (
          <div>
            <SignalPreview signal={showPreview} />
          </div>
        ) : (
          <SignalFormInputs values={values} setValues={setValues} selectedCategories={signal?.categories} />
        )}
        <div className={`flex mt-4 ${showPreview ? "justify-between" : "justify-end"}`}>
          {showPreview && (
            <Button title="Retour" size="small" kind="outline" light onClick={() => setShowPreview(undefined)} />
          )}
          <Button
            light
            size="small"
            title={showPreview ? "Enregister" : "Pré-visualiser"}
            onClick={() => {
              showPreview
                ? onSubmit({
                    id: signal?.signal.id,
                    send_on: values.send_on,
                    filters: values.filters,
                    target_id: newSignalTargetId,
                  })
                : onPreview();
            }}
            disabled={!values.send_on || !values.filters || !values.filters.categories.length}
          />
        </div>
      </div>
    </>
  );
};

interface SignalInputsProps {
  values: Partial<Signal>;
  selectedCategories?: SignalCategoryEdit[];
  setValues: (val: Partial<Signal>) => void;
}

export const SignalFormInputs: FunctionComponent<SignalInputsProps> = ({ values, setValues, ...props }) => {
  const [granularity, setGranularity] = useState<Granularity | undefined>(
    values.send_on && getGranularity(values.send_on)
  );
  const [weekDay, setWeekday] = useState<Weekday | undefined>(values.send_on && getWeekday(values.send_on));
  const [at, setAt] = useState<[number, number]>(values?.send_on?.at || [9, 0]);
  const [timeInputError, setTimeInputError] = useState<string>();
  const [selectedCategories, setSelectedCategories] = useState<SignalCategoryEdit[]>(props.selectedCategories || []);
  const [categories, setCategories] = useState<Category[]>([]);
  const [selectedLocations, setSelectedLocations] = useState<NominatimLocationResponse & { max_radius?: number }>();

  useEffect(() => {
    fetchJobTitleCategories().then(setCategories);
  }, []);

  useEffect(() => {
    if (props.selectedCategories) setSelectedCategories(props.selectedCategories);
  }, [props.selectedCategories]);

  useEffect(() => {
    const categories = selectedCategories.map((c) => ({
      category: c.category.id,
      skills: c.skills.map((s) => s.id),
    }));

    const filters: SignalFilters = {
      metadata: {
        location: selectedLocations && {
          location_id: selectedLocations.location_id,
          max_radius: selectedLocations.max_radius,
        },
      },
      categories,
    };
    const updates = {
      ...values,
      send_on:
        granularity && at ? convertTemporalityToUtc(createTemporality(granularity, weekDay, at)) : values.send_on,
      filters: { ...values.filters, ...filters },
    };
    setValues(updates);
  }, [selectedLocations, granularity, at, weekDay, selectedCategories]);

  return (
    <div className="bg-inherit flex flex-col gap-4">
      <Radio
        light
        optionType="button"
        options={[
          { label: SIGNAL_GRANULARITY[Granularity.Day], value: Granularity.Day },
          { label: SIGNAL_GRANULARITY[Granularity.Week], value: Granularity.Week },
          { label: SIGNAL_GRANULARITY[Granularity.Month], value: Granularity.Month },
        ]}
        value={granularity}
        onChange={(e) => setGranularity(e as Granularity)}
      />
      <div className="flex">
        {granularity && granularity !== Granularity.Day && (
          <div className="flex-1">
            <Select
              type="single"
              placeholder="Jour"
              valueClassName="rounded-r-none"
              bordered
              rounded
              light
              value={weekDay}
              optionRender={(item) => WEEKDAY[item]}
              getKey={(item) => item}
              onChange={(e) => setWeekday(e as Weekday)}
              options={[
                Weekday.Monday,
                Weekday.Tuesday,
                Weekday.Wednesday,
                Weekday.Thursday,
                Weekday.Friday,
                Weekday.Saturday,
                Weekday.Sunday,
              ]}
            />
          </div>
        )}
        <div className="flex-1">
          <TextInput
            type="time"
            light
            placeholder=""
            inputClassName={`h-10 ${granularity && granularity !== Granularity.Day ? "rounded-l-none" : ""}`}
            value={at ? `${at[0] < 10 ? "0" : ""}${at[0]}:${at[1] < 10 ? "0" : ""}${at[1]}` : ""}
            onChange={(e) => {
              const [h, m] = e.currentTarget.value.split(":").map((e) => parseInt(e));
              if (h < 6 || h > 20) setTimeInputError("Veuillez saisir une heure entre 6h et 20h");
              else setTimeInputError(undefined);
              setAt([h, m]);
            }}
          />
        </div>
      </div>
      {timeInputError && <div className="text-red-500">Heure invalide: {timeInputError}</div>}
      <SignalFiltersSelect
        onChange={setSelectedCategories}
        categories={categories}
        selectedCategories={selectedCategories}
      />
      <CitySelect value={selectedLocations} onChange={setSelectedLocations} hideRadius={true} />
    </div>
  );
};
