import React, { FunctionComponent, useState } from "react";
import { MetaFormStepProps } from "..";
import { useFormManager } from "../../../../services/form";
import { Button, DatePicker, InputNumber } from "@getprorecrutement/getpro-design";
import HelpTooltip from "../../../helpTooltip";
import { updateCandidate } from "../../../../models/candidate";
import { updateJa } from "../../../../models/jobApplications";

export const HiredBasicStep: FunctionComponent<MetaFormStepProps> = ({ candidate, jobApp, onSubmit, onCancel }) => {
  const [loading, setLoading] = useState<boolean>();

  const form = useFormManager({
    defaultState: {
      starts_job_at: jobApp.starts_job_at && jobApp.starts_job_at.split("T")[0],
      salary: candidate.salary,
    },
    validations: {
      starts_job_at: {
        required: true,
        message: "Merci de préciser la date d'entrée en poste du candidat",
      },
      salary: {
        required: (salary) => !!salary && salary > 1000,
        message: "Merci de préciser le salaire du candidat",
      },
    },
  });

  const submit = async () => {
    if (form.validate()) {
      const jobAppData = { starts_job_at: form.state.starts_job_at, id: jobApp.id };
      const candidateData = { salary: form.state.salary, id: candidate.id };

      try {
        setLoading(true);
        const updatedCandidate = await updateCandidate(candidateData);
        const updatedJobApp = await updateJa(jobAppData);
        setLoading(false);
        onSubmit(updatedCandidate, updatedJobApp);
      } catch {
        setLoading(false);
      }
    }
  };

  return (
    <>
      <DatePicker
        light
        value={form.state.starts_job_at}
        label="Date d'entrée en poste"
        error={form.errors.starts_job_at}
        onChange={(val) =>
          form.onChange({
            starts_job_at: val,
          })
        }
      />
      <div className="flex items-center gap-1 mt-4 bg-inherit">
        <div className="flex-1 bg-inherit">
          <InputNumber
            light
            label="Salaire annuel actuel"
            placeholder="50 000 €"
            value={form.state.salary}
            error={form.errors.salary}
            onChange={(e) => form.onChange({ salary: e })}
            display={{
              formatter: (value) => value && value.replace(/\B(?=(\d{3})+(?!\d))/g, " "),
              parser: (value) => (value ? value.replace(/[\s€]*/g, "") : ""),
            }}
            precision={2}
            suffix="€"
            min={0}
            controls={false}
          />
        </div>
        <HelpTooltip position="left" title={"Salaire fixe + variable sans les avantages ni BSPCE"} />
      </div>
      <div className="flex justify-between mt-8">
        <Button size="small" kind="outline" light title="Annuler" onClick={onCancel} />
        <Button size="small" loading={loading} light disabled={form.disabled} title="Enregistrer" onClick={submit} />
      </div>
    </>
  );
};
