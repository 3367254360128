import React, { FunctionComponent, useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router";
import { ContactElem } from "../../../components/billing/contacts/contactsList";
import ButtonCard from "../../../components/buttonCard";
import InlineLabelValue from "../../../components/inlineLabelValue";
import { ContractResponse, getContract } from "../../../models/billing/contract";
import { UUID } from "../../../models/common";
import { Contact, ContractContacts, getContractContacts } from "../../../models/contacts";
import store, { Message } from "../../../services/store";
import ContractBillList from "./billList";
import ContractProjectList from "./projectList";
import ContractForm from "../../../components/billing/contracts/contractForm";
import { TemplateData } from "../../../models/messenger";
import { Modal, Button } from "@getprorecrutement/getpro-design";
import { PencilSquareIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";

export const BillingContractPage: FunctionComponent = () => {
  const { id, objectType, objectId } = useParams<{ id?: UUID; objectType?: string; objectId?: UUID }>();
  const [contract, setContract] = useState<ContractResponse>();
  const [contacts, setContacts] = useState<ContractContacts>();
  const [edit, setEdit] = useState<boolean>(false);

  const openMessenger = (contact: Contact) => {
    const templateData: TemplateData = { ...contact };
    store.notify(Message.Messenger, {
      label: `${contact.first_name} ${contact.last_name}`,
      to: contact.email,
      from: store.state.User?.email,
      templateData,
      closeAfterSend: true,
    });
  };

  const fetchContrat = async (id: UUID) => {
    const contract = await getContract(id);
    setContract(contract);
    fetchContacts(contract.id);
  };

  const fetchContacts = async (id: UUID) => {
    const c = await getContractContacts(id);
    setContacts(c);
  };

  const onFinish = () => {
    setEdit(false);
    if (id) fetchContrat(id);
  };

  useEffect(() => {
    if (id) fetchContrat(id);
  }, [id]);

  const contractObjects = (contract: ContractResponse) => {
    return (
      <div className="grid grid-cols-2 w-full gap-4 px-6">
        <Link to={`/billing/contracts/${id}/bills`}>
          <ButtonCard title="Factures" count={contract.bills_count} />
        </Link>
        <Link to={`/billing/contracts/${id}/projects`}>
          <ButtonCard title="Projets" count={contract.projects_count} />
        </Link>
      </div>
    );
  };

  const contractObjectsType = (contract: ContractResponse, type: string) => {
    switch (type) {
      case "bills":
        return <ContractBillList contract={contract} />;
      case "projects":
        return <ContractProjectList contract={contract} id={objectId} />;
    }
  };

  const contractInfos = (contract: ContractResponse) => {
    return (
      <div className="bg-white p-6 shadow-md mb-6">
        <div className="flex justify-between mb-4">
          <div className="text-3xl font-bold">{contract.name}</div>
          <Button icon={<PencilSquareIcon />} onClick={() => setEdit(true)} light className="p-0" kind="light" />
        </div>
        <div className="flex gap-4">
          <div className="flex-1">
            {contract.description && (
              <InlineLabelValue label="Description" value={contract.description} bold={true} labelWidth={200} />
            )}
            <InlineLabelValue label="Client" value={contract.customer_name} bold={true} labelWidth={200} />
            <InlineLabelValue
              label={"Proriétaire"}
              value={`${contract.owner_first_name} ${contract.owner_last_name} (${contract.owner_email})`}
              bold={true}
              labelWidth={200}
            />
            <InlineLabelValue
              label={"Total facturé"}
              value={`${contract.amount_billed} €`}
              bold={true}
              labelWidth={200}
            />
            {contacts?.legal && (
              <InlineLabelValue
                label={"Contact legal"}
                value={<ContactElem contact={contacts.legal} openMessenger={openMessenger} />}
                bold={true}
                labelWidth={200}
              />
            )}
          </div>
          <div className="flex-1">
            <InlineLabelValue
              label={"Total facturé"}
              value={`${contract.amount_billed} €`}
              bold={true}
              labelWidth={200}
            />
            <InlineLabelValue label={"TVA"} value={`${contract.vat_percent} %`} bold={true} labelWidth={200} />
            {contacts?.billing && (
              <InlineLabelValue
                label={"Contact de facturation"}
                value={<ContactElem contact={contacts.billing} openMessenger={openMessenger} />}
                bold={true}
                labelWidth={200}
              />
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      {contract && (
        <div>
          <div className="contract">{contractInfos(contract)}</div>
          {contract && !objectType && contractObjects(contract)}
          {contract && objectType && contractObjectsType(contract, objectType)}
          <Modal light onClose={onFinish} show={edit} className="w-modal-md">
            <ContractForm customerId={contract.customer_id} contract={contract} onFinish={onFinish} />
          </Modal>
        </div>
      )}
    </div>
  );
};

export default BillingContractPage;
