import React, { FunctionComponent, useState, useEffect } from "react";
import { Status } from "../../../../models/jobOffers";
import { UUID } from "../../../../models/common";
import { Summary } from "../../../../models/jobOffers";
import { getCallStatuses } from "../../../../services/requests";
import { Select } from "@getprorecrutement/getpro-design";
import { MultipleValues, SingleValue } from "@getprorecrutement/getpro-design/dist/components/Form/Inputs/Select";
import classNames from "classnames";

interface Props {
  callStatuses?: Status[];
  onSelect: (s: UUID) => void;
  value?: UUID | UUID[];
  multiple?: boolean;
  placeholder?: string;
  style?: React.CSSProperties;
  summary?: Summary;
  bordered?: boolean;
  size?: "small" | "medium" | "large";
  disabled?: boolean;
  label?: string;
  dropdownClassName?: string;
  showArrowIcon?: boolean;
}

interface MultipleProps {
  multiple: true;
  value?: UUID[];
  customValueRender?: (value: Status[]) => JSX.Element;
}

interface SingleProps {
  multiple?: false;
  value?: UUID;
  customValueRender?: (value: Status) => JSX.Element;
}

export const CallStatusSelect: FunctionComponent<Props & (SingleProps | MultipleProps)> = (props) => {
  const [callStatuses, setCallStatuses] = useState<Status[]>(props.callStatuses || []);

  const fetchStatuses = async () => {
    getCallStatuses().then(setCallStatuses);
  };

  useEffect(() => {
    if (!props.callStatuses) fetchStatuses();
  }, []);

  useEffect(() => {
    if (props.callStatuses) setCallStatuses(props.callStatuses);
  }, [props.callStatuses]);

  const selectProps: SingleValue<Status> | MultipleValues<Status> = props.multiple
    ? {
        type: "multiple",
        value: callStatuses.filter((c) => props.value?.includes(c.id)),
        customValueRender: props.customValueRender,
      }
    : {
        type: "single",
        value: callStatuses.find((c) => c.id === props.value),
        customValueRender: props.customValueRender,
      };

  const dropdownClasses = classNames("border border-solid rounded-3xl bg-white", {
    [`${props.dropdownClassName}`]: props.dropdownClassName,
  });

  return (
    <span style={props.style} className="statusSelect bg-inherit">
      <Select
        light
        bordered
        rounded
        showArrowIcon={props.showArrowIcon}
        placeholder={props.placeholder}
        label={props.label}
        size={props.size}
        options={callStatuses}
        optionRender={(item) => item.name}
        getKey={(item) => item.id}
        dropdownClassName={dropdownClasses}
        onChange={(v) => v && props.onSelect(v.id)}
        onSearch={undefined}
        disabled={props.disabled}
        {...selectProps}
      />
    </span>
  );
};

export default CallStatusSelect;
