import React from "react";

export const LoaderPage = () => (
  <div className="loaderPage">
    <div className="loader">
      <span className="loader-block"></span>
      <span className="loader-block"></span>
      <span className="loader-block"></span>
      <span className="loader-block"></span>
      <span className="loader-block"></span>
      <span className="loader-block"></span>
      <span className="loader-block"></span>
      <span className="loader-block"></span>
      <span className="loader-block"></span>
    </div>
  </div>
);

export default LoaderPage;
