import { UUID } from "./common";
import httpClient from "../services/http/client";
import ROUTES from "../services/http/routes";
import { notifyError } from "../services/requests";
import { BookingResponse } from "../components/dashboard/modules/booking";
import { RetainerResponse } from "../components/dashboard/modules/retainers";

export enum DatePeriod {
  Yesterday = "yesterday",
  Today = "today",
  Week = "week",
  LastWeek = "last-week",
  Month = "month",
  LastMonth = "last-month",
  Quarter = "quarter",
  LastQuarter = "last-quarter",
  Year = "year",
  LastYear = "last-year",
  RollingYear = "rolling-year",
}

export interface GlobalFilters {
  users?: UUID[];
  businessUnits?: UUID[];
  jobOffers?: UUID[];
  customers?: UUID[];
  dateRange?: { from?: string; to?: string; granularity?: string };
  period?: DatePeriod;
}

export interface ContactedData {
  contacted_at: string;
  email: number;
  phone: number;
  inmail: number;
  total: number;
}

export interface DashboardModule<T> {
  index: number;
  title?: string;
  kind: string;
  key: string;
  filters: T;
}

export interface UserDashboard {
  name: string;
  slug: string;
  filters: GlobalFilters;
  modules: DashboardModule<object>[];
}

export interface GlobalDashboard {
  id: UUID;
  name: string;
  slug: string;
  filters: GlobalFilters;
  modules: DashboardModule<object>[];
  business_units_ids: UUID[];
  created_at: string;
}

export interface PipeCandidate {
  candidate_id: UUID;
  candidate_name: string;
  job_offer_id: UUID;
  job_offer_name: string;
  phone?: string;
  linkedin_url?: string;
  email?: string;
  owner_first_name: string;
  owner_last_name: string;
  status_id: UUID;
}

export async function getStatsContacted(params: {
  from?: string;
  to?: string;
  granularity?: string;
  users?: UUID[];
  business_units?: UUID[];
  job_offers?: UUID[];
  customers?: UUID[];
  contact_types: string[];
}): Promise<{
  contacted_count: number;
  contacted_count_by_type: [string, number][];
  contacted_count_by_job_offer: [string, number][];
  timeline: ContactedData[];
}> {
  return httpClient.req(ROUTES.GET_STATS_CONTACTED(params), (err) =>
    notifyError(err || "Erreur lors de la récupération des statistiques")
  );
}

export async function getStatsCallStatuses(params: {
  from?: string;
  to?: string;
  compare_from?: string;
  compare_to?: string;
  granularity?: string;
  users?: UUID[];
  business_units?: UUID[];
  job_offers?: UUID[];
  customers?: UUID[];
  candidates?: boolean;
  call_status_ids: string[];
  field?: string;
  order?: string;
}): Promise<{
  timeline: { created_at: string; count: number; call_status_id: UUID }[];
  summary: [string, number][];
  comparison?: [string, number][];
  candidates?: {
    id: UUID;
    full_name: string;
    job_offer_name: string;
    job_offer_id: UUID;
    linkedin_url?: string;
    call_status_id: UUID;
    created_at: string;
  }[];
}> {
  return httpClient.req(ROUTES.GET_STATS_CALL_STATUSES(params), (err) =>
    notifyError(err || "Erreur lors de la récupération des statistiques")
  );
}

export async function getStatsStatuses(params: {
  from?: string;
  to?: string;
  compare_from?: string;
  compare_to?: string;
  granularity?: string;
  users?: UUID[];
  business_units?: UUID[];
  job_offers?: UUID[];
  customers?: UUID[];
  candidates?: boolean;
  jos?: boolean;
  statuses_ids: string[];
  opportunity_type?: "cabinet" | "rpo" | "interne";
  field?: string;
  order?: string;
  chart_type: string;
}): Promise<{
  timeline_per_user?: {
    name: string;
    status_id: UUID;
    count?: number;
  }[];
  timeline_per_bu?: {
    name: string;
    status_id: UUID;
    count?: number;
  }[];
  timeline?: { updated_at: string; count: number; status_id: UUID }[];
  summary: [string, number][];
  comparison?: [string, number][];
  candidates?: {
    id: UUID;
    full_name: string;
    job_offer_name: string;
    job_offer_id: UUID;
    linkedin_url?: string;
    status_id: UUID;
    created_at: string;
  }[];
  jos?: {
    id: UUID;
    name: string;
    summary: { name: string; count: number }[];
  }[];
}> {
  return httpClient.req(ROUTES.GET_STATS_STATUSES(params), (err) =>
    notifyError(err || "Erreur lors de la récupération des statistiques")
  );
}

export async function getStatsTerminated(params: {
  from?: string;
  to?: string;
  compare_from?: string;
  compare_to?: string;
  granularity?: string;
  users?: UUID[];
  business_units?: UUID[];
  job_offers?: UUID[];
  customers?: UUID[];
  candidates?: boolean;
  termination_sources?: string[];
}): Promise<{
  timeline: { created_at: string; count: number; termination_source: string }[];
  summary: [string, number][];
  comparison?: [string, number][];
}> {
  return httpClient.req(ROUTES.GET_STATS_TERMINATED(params), (err) =>
    notifyError(err || "Erreur lors de la récupération des statistiques")
  );
}

export async function getCandidatesPipe(params: {
  users?: UUID[];
  business_units?: UUID[];
  job_offers?: UUID[];
  customers?: UUID[];
  statuses_ids?: string[];
  archived?: boolean;
  field?: string;
  order?: string;
  chart_type: string;
  opportunity_type?: "cabinet" | "rpo" | "interne";
}): Promise<{
  candidates: PipeCandidate[];
  summary: [string, number][];
  per_user: {
    name: string;
    status: UUID;
    count?: number;
  }[];
  per_bu: {
    name: string;
    status: UUID;
    count?: number;
  }[];
  jos?: {
    id: UUID;
    name: string;
    summary: { name: string; count: number }[];
  }[];
}> {
  return httpClient.req(ROUTES.GET_CANDIDATES_PIPE(params), (err) =>
    notifyError(err || "Erreur lors de la récupération des statistiques")
  );
}

export async function getStatsSourcing(params: {
  from?: string;
  to?: string;
  compare_from?: string;
  compare_to?: string;
  granularity?: string;
  users?: UUID[];
  business_units?: UUID[];
  job_offers?: UUID[];
  customers?: UUID[];
  sources_ids: string[];
}): Promise<{
  timeline: { created_at: string; count: number; source_id: UUID }[];
  summary: [string, number][];
  comparison?: [string, number][];
}> {
  return httpClient.req(ROUTES.GET_STATS_SOURCING(params), (err) =>
    notifyError(err || "Erreur lors de la récupération des statistiques")
  );
}

export async function getStatsBooking(params: {
  from?: string;
  to?: string;
  // compare_from?: string;
  // compare_to?: string;
  granularity?: string;
  users?: UUID[];
  business_units?: UUID[];
  job_offers?: UUID[];
  customers?: UUID[];
  chart_type: string;
  bookings?: boolean;
}): Promise<BookingResponse> {
  return httpClient.req(ROUTES.GET_STATS_BOOKING(params), (err) =>
    notifyError(err || "Erreur lors de la récupération des statistiques")
  );
}

export async function getStatsRetainer(params: {
  from?: string;
  to?: string;
  granularity?: string;
  users?: UUID[];
  business_units?: UUID[];
  job_offers?: UUID[];
  customers?: UUID[];
  chart_type: string;
  retainers?: boolean;
}): Promise<RetainerResponse> {
  return httpClient.req(ROUTES.GET_STATS_RETAINERS(params), (err) =>
    notifyError(err || "Erreur lors de la récupération des statistiques")
  );
}

export async function getGlobalDashboards(): Promise<GlobalDashboard[]> {
  return httpClient.req(ROUTES.GET_GLOBAL_DASHBOARDS(), (err) =>
    notifyError(err || "Erreur lors de la récupération des dashboards globaux")
  );
}

export async function viewGlobalDashboards(): Promise<GlobalDashboard[]> {
  return httpClient.req(ROUTES.VIEW_GLOBAL_DASHBOARDS(), (err) =>
    notifyError(err || "Erreur lors de la récupération des dashboards globaux")
  );
}

export async function createGlobalDashboard(data: Partial<GlobalDashboard>): Promise<GlobalDashboard> {
  return httpClient.req(ROUTES.CREATE_GLOBAL_DASHBOARD(data), (err) =>
    notifyError(err || "Erreur lors de la création du dashboard")
  );
}

export async function updateGlobalDashboard(data: Partial<GlobalDashboard>): Promise<GlobalDashboard> {
  return httpClient.req(ROUTES.UPDATE_GLOBAL_DASHBOARD(data), (err) =>
    notifyError(err || "Erreur lors de la modification du dashboard")
  );
}

export async function deleteGlobalDashboard(id: UUID): Promise<void> {
  return httpClient.req(ROUTES.DELETE_GLOBAL_DASHBOARD(id), (err) =>
    notifyError(err || "Erreur lors de la suppression du dashboard")
  );
}
