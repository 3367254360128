import React, { useEffect, useState } from "react";
import { FunctionComponent } from "react";
import { UUID } from "../../../models/common";
import {
  CustomerResponse,
  generateReport,
  getCustomer,
  ReportFullJobOffer,
  reportSummary,
} from "../../../models/customers";
import { getUsers, UserResponse } from "../../../models/users/users";
import JobOfferSelect from "../inputs/jobOfferSelect";
import dayjs from "dayjs";
import { Download } from "../../download";
import { SummaryTable } from "./table";
import CustomerSelect from "../inputs/customerSelect";
import UserSelect from "../inputs/userSelect";
import { Button, Radio, Checkbox, Textarea, Tree } from "@getprorecrutement/getpro-design";
import { InformationCircleIcon, MinusCircleIcon, PlusIcon } from "@heroicons/react/24/outline";

interface Props {
  customer?: CustomerResponse;
}

export const CustomerReportForm: FunctionComponent<Props> = (props: Props) => {
  const [users, setUsers] = useState<UserResponse[]>([]);
  const [opportunityType, setOpportunityType] = useState<"rpo" | "cabinet">("rpo");
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [selectedJos, setSelectedJos] = useState<UUID[]>([]);
  const [step, setStep] = useState<number>(0);
  const [accomplishments, setAccomplishments] = useState<string[]>([]);
  const [observations, setObservations] = useState<string[]>([]);
  const [goals, setGoals] = useState<string[]>([]);
  const [fullJos, setFullJos] = useState<{ full_jos: ReportFullJobOffer[]; full_jos_from: ReportFullJobOffer[] }>();
  const [loading, setLoading] = useState(false);
  const [customer, setCustomer] = useState<CustomerResponse | undefined>(props.customer);
  const [customerId, setCustomerId] = useState<UUID | undefined>(props.customer?.id);
  const [syntheseOnly, setSyntheseOnly] = useState<boolean>(false);
  const fileName = "Rapport " + customer?.name + " " + dayjs().format("DD-MM-YYYY") + ".pdf";
  const [tabSelected, setTabSelected] = useState<"description" | "summary">("description");

  useEffect(() => {
    if (customerId) getCustomer(customerId).then(setCustomer);
    else setCustomer(undefined);
  }, [customerId]);

  useEffect(() => {
    getUsers().then(setUsers);
  }, []);

  const onSubmit = async () => {
    if (opportunityType) {
      if (!syntheseOnly && step === 0) {
        setStep(step + 1);
      } else {
        const payload: {
          job_offers: UUID[];
          from: string;
        } = {
          job_offers: selectedJos,
          from: dayjs().weekday(0).format("YYYY-MM-DD"),
        };

        reportSummary(payload).then((res: { full_jos: ReportFullJobOffer[]; full_jos_from: ReportFullJobOffer[] }) => {
          setFullJos(res);
          setStep(() => step + (syntheseOnly ? 2 : 1));
        });
      }
    }
  };

  const downloadReport = async (customerId: UUID): Promise<Blob> => {
    const payload = {
      opportunity_type: opportunityType,
      users: users ? users.filter((u) => selectedUsers.includes(u.id)) : [],
      full_jos: fullJos ? fullJos.full_jos : [],
      full_jos_from: fullJos ? fullJos.full_jos_from : [],
      accomplishments,
      observations,
      goals,
      synthese_only: syntheseOnly,
    };
    setLoading(true);
    const report = await generateReport(customerId, payload);
    setLoading(false);
    return report;
  };

  const generateDisabled = !customer || selectedUsers.length === 0 || !fullJos;

  return (
    <>
      <div className="text-2xl font-bold text-center mb-4">{step < 2 ? "Rapport" : "Résumé du rapport"}</div>
      <div className="bg-inherit py-2 pr-4 overflow-auto">
        {step < 2 ? (
          <>
            {step === 0 && (
              <div className="flex flex-col gap-4 bg-white">
                <Radio
                  optionType="button"
                  options={[
                    { label: "RPO", value: "rpo" },
                    { label: "Cabinet", value: "cabinet" },
                  ]}
                  onChange={(e) => setOpportunityType(e as "rpo" | "cabinet")}
                  light
                  value={opportunityType}
                />
                <CustomerSelect
                  label="Entreprise"
                  value={customerId}
                  onSelect={(id) => {
                    setSelectedJos([]);
                    setCustomerId(id);
                  }}
                  showSearch
                />
                <UserSelect
                  label="Consultant(s)"
                  multiple
                  showSearch
                  value={selectedUsers}
                  onSelect={(id) =>
                    selectedUsers.includes(id)
                      ? setSelectedUsers(selectedUsers.filter((userId) => userId !== id))
                      : setSelectedUsers([...selectedUsers, id])
                  }
                />
                <JobOfferSelect
                  key={"jobOfferSelect"}
                  onSelect={(e) =>
                    selectedJos.includes(e)
                      ? setSelectedJos(selectedJos.filter((jo) => jo !== e))
                      : setSelectedJos([...selectedJos, e])
                  }
                  label="Offres"
                  onFetchJos={(jos) => customerId && setSelectedJos(jos)}
                  multiple={true}
                  value={selectedJos}
                  ownerIds={selectedUsers}
                  customerIds={customer && [customer.id]}
                  includeArchived
                />
                <div className="flex items-center gap-4">
                  <Checkbox
                    label="Synthèse uniquement"
                    value={syntheseOnly}
                    onChange={(e) => setSyntheseOnly(e)}
                    light
                  />
                  <div className="flex items-center gap-2">
                    <InformationCircleIcon width={20} height={20} />
                    <div>Dans le cas d'un reporting hebdomadaire, merci de ne pas cocher cette case</div>
                  </div>
                </div>
              </div>
            )}
            {step === 1 && (
              <div className="flex flex-col gap-4 bg-white">
                <div className="text-lg font-bold">Accomplissements</div>
                {accomplishments.map((val, i) => (
                  <div key={`accomplisment-${i}`} className="flex gap-4">
                    <div className="flex-1">
                      <Textarea
                        light
                        value={val}
                        onChange={(e) => setAccomplishments(accomplishments.map((a, index) => (index === i ? e : a)))}
                        rows={3}
                        placeholder="Accomplissement"
                      />
                    </div>
                    <MinusCircleIcon
                      className="cursor-pointer"
                      width={20}
                      onClick={() => setAccomplishments(accomplishments.filter((_, index) => index !== i))}
                    />
                  </div>
                ))}
                <div className="flex justify-end">
                  <Button
                    size="small"
                    onClick={() => setAccomplishments([...accomplishments, ""])}
                    title="Ajouter un accomplissement"
                    light
                    icon={<PlusIcon />}
                  />
                </div>
                <div className="text-lg font-bold">Observations</div>
                {observations.map((val, i) => (
                  <div key={`observation-${i}`} className="flex gap-4">
                    <div className="flex-1">
                      <Textarea
                        light
                        value={val}
                        onChange={(e) => setObservations(observations.map((a, index) => (index === i ? e : a)))}
                        rows={3}
                        placeholder="Observation"
                      />
                    </div>
                    <MinusCircleIcon
                      className="cursor-pointer"
                      width={20}
                      onClick={() => setObservations(observations.filter((_, index) => index !== i))}
                    />
                  </div>
                ))}
                <div className="flex justify-end">
                  <Button
                    size="small"
                    onClick={() => setObservations([...observations, ""])}
                    title="Ajouter une observation"
                    light
                    icon={<PlusIcon />}
                  />
                </div>
                <div className="text-lg font-bold">Objectifs</div>
                {goals.map((val, i) => (
                  <div key={`goal-${i}`} className="flex gap-4">
                    <div className="flex-1">
                      <Textarea
                        light
                        value={val}
                        onChange={(e) => setGoals(goals.map((a, index) => (index === i ? e : a)))}
                        rows={3}
                        placeholder="Objectif"
                      />
                    </div>
                    <MinusCircleIcon
                      className="cursor-pointer"
                      width={20}
                      onClick={() => setGoals(goals.filter((_, index) => index !== i))}
                    />
                  </div>
                ))}
                <div className="flex justify-end">
                  <Button
                    size="small"
                    onClick={() => setGoals([...goals, ""])}
                    title="Ajouter un objectif"
                    light
                    icon={<PlusIcon />}
                  />
                </div>
              </div>
            )}
            <div className={`flex mt-8 ${step === 0 ? "justify-end" : "justify-between"}`}>
              {step !== 0 && <Button onClick={() => setStep(0)} title="Retour" light size="small" />}
              <Button
                title={step === 0 && !syntheseOnly ? "Suivant" : "Visualiser le rapport"}
                disabled={!(opportunityType && selectedUsers.length > 0 && selectedJos.length > 0)}
                light
                size="small"
                onClick={onSubmit}
              />
            </div>
          </>
        ) : (
          <>
            {!syntheseOnly && (
              <Radio
                onChange={(e) => setTabSelected(e as "description" | "summary")}
                value={tabSelected}
                optionType="button"
                light
                options={[
                  { label: "Description", value: "description" },
                  { label: "Résumé", value: "summary" },
                ]}
              />
            )}
            <div className="mt-4">
              {tabSelected === "description" && !syntheseOnly ? (
                <div>
                  <div className="text-xl font-bold text-center mb-4">
                    Rapport d'activité {opportunityType === "rpo" ? "RPO" : "Cabinet"}
                  </div>
                  <div className="text-xl font-bold text-center mb-6 uppercase">{customer?.name}</div>
                  <div className="mb-6 font-bold">
                    Consultant{selectedUsers.length > 1 ? "s" : ""} :{" "}
                    {selectedUsers.map((u, i) =>
                      i < selectedUsers.length - 1
                        ? users.find((user) => user.id === u)?.full_name + ", "
                        : users.find((user) => user.id === u)?.full_name
                    )}
                  </div>
                  {accomplishments.length > 0 && <div className="text-lg font-bold mb-4">Accomplissements</div>}
                  <Tree light values={accomplishments} getKey={(v) => v} getLabel={(v) => v} />
                  {observations.length > 0 && <div className="text-lg font-bold mb-4">Observations</div>}
                  <Tree light values={observations} getKey={(v) => v} getLabel={(v) => v} />
                  {goals.length > 0 && <div className="text-lg font-bold mb-4">Objectifs de la semaine prochaine</div>}
                  <Tree light values={goals} getKey={(v) => v} getLabel={(v) => v} />
                </div>
              ) : (
                <div>
                  {!syntheseOnly && <div className="text-xl font-bold mb-4">Activités de la semaine</div>}
                  {fullJos && !syntheseOnly && <SummaryTable job_offers={fullJos.full_jos_from} />}
                  <div className="text-xl font-bold mb-4">Synthèse de la recherche</div>
                  {fullJos && <SummaryTable job_offers={fullJos.full_jos} />}
                </div>
              )}
            </div>
            <div className="flex justify-between mt-8">
              <Button title="Retour" light size="small" onClick={() => setStep(step - (syntheseOnly ? 2 : 1))} />
              {customer && (
                <Download
                  disabled={generateDisabled}
                  setLoading={setLoading}
                  fileName={fileName}
                  request={() => downloadReport(customer.id)}
                >
                  <Button disabled={generateDisabled} loading={loading} title="Générer le rapport" light size="small" />
                </Download>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default CustomerReportForm;
