import React, { FunctionComponent, useCallback, useEffect, useState } from "react";
import { useEditor, Editor, EditorContent } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Link from "@tiptap/extension-link";
import {
  ArrowUturnLeftIcon,
  ArrowUturnRightIcon,
  LinkIcon,
  ListBulletIcon,
  MinusIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { Tooltip } from "@getprorecrutement/getpro-design";

export const VARIABLES: { [key: string]: { key: string; value: string }[] } = {
  "Offres & Candidatures": [
    { key: "Prénom", value: "[:first_name:]" },
    { key: "Nom", value: "[:last_name:]" },
    { key: "Titre", value: "[:title:]" },
    { key: "Nom de l'offre", value: "[:job_offer_name:]" },
    { key: "Dernière entreprise", value: "[:last_company:]" },
    { key: "Dernières entreprises (2)", value: "[:last_companies_2:]" },
    { key: "Dernières entreprises (3)", value: "[:last_companies_3:]" },
  ],
  "Facturation & Contrats": [
    { key: "Prénom", value: "[:first_name:]" },
    { key: "Nom", value: "[:last_name:]" },
    { key: "Montant derniére facture", value: "[:last_bill_amount:]" },
  ],
};

interface Props {
  onChange: (val: string) => void;
  content?: string;
  cleanInput?: boolean;
  withVars?: boolean;
}

const MenuBar = ({ editor }: { editor: Editor }) => {
  const [varsOpen, setVarsOpen] = useState(false);

  if (!editor) {
    return null;
  }

  const setLink = useCallback(() => {
    const previousUrl = editor.getAttributes("link").href;
    const url = window.prompt("URL", previousUrl);

    // cancelled
    if (url === null) {
      return;
    }

    // empty
    if (url === "") {
      editor.chain().focus().extendMarkRange("link").unsetLink().run();

      return;
    }

    // update link
    editor.chain().focus().extendMarkRange("link").setLink({ href: url }).run();
  }, [editor]);

  return (
    <div className="HTMLEditorToolbar flex ml-6" style={{ display: "flex" }}>
      <div
        onClick={() => editor.chain().focus().toggleBold().run()}
        className={editor.isActive("bold") ? "is-active" : ""}
      >
        <Tooltip light title="Gras">
          <div className="font-bold">B</div>
        </Tooltip>
      </div>
      <div
        onClick={() => editor.chain().focus().toggleItalic().run()}
        className={editor.isActive("italic") ? "is-active" : ""}
      >
        <Tooltip light title="Italic">
          <div className="italic font-sans mb-0.5">I</div>
        </Tooltip>
      </div>
      <div
        onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
        className={editor.isActive("heading", { level: 1 }) ? "is-active" : ""}
      >
        <Tooltip light title="Titre 1">
          h1
        </Tooltip>
      </div>
      <div
        onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
        className={editor.isActive("heading", { level: 2 }) ? "is-active" : ""}
      >
        <Tooltip light title="Titre 2">
          h2
        </Tooltip>
      </div>
      <div
        onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
        className={editor.isActive("heading", { level: 3 }) ? "is-active" : ""}
      >
        <Tooltip light title="Titre 3">
          h3
        </Tooltip>
      </div>
      <div
        onClick={() => editor.chain().focus().toggleBulletList().run()}
        className={editor.isActive("bulletList") ? "is-active" : ""}
      >
        <Tooltip light title="Liste non ordonnée">
          <ListBulletIcon className="w-5 h-5" />
        </Tooltip>
      </div>
      <div
        onClick={() => editor.chain().focus().toggleOrderedList().run()}
        className={editor.isActive("orderedList") ? "is-active" : ""}
      >
        <Tooltip light title="Liste ordonnée">
          <ListBulletIcon className="w-5 h-5" />
        </Tooltip>
      </div>
      <div className={editor.isActive("link") ? "is-active" : ""} onClick={setLink}>
        <Tooltip light title="Ajouter un lien">
          <LinkIcon className="w-5 h-5" />
        </Tooltip>
      </div>
      <div onClick={() => editor.chain().focus().setHorizontalRule().run()}>
        <Tooltip light title="Ajouter un séparateur">
          <MinusIcon className="w-5 h-5" />
        </Tooltip>
      </div>
      <div onClick={() => editor.chain().focus().clearNodes().unsetAllMarks().run()}>
        <Tooltip light title="Supprimer le formattage">
          <TrashIcon className="w-4 h-4" />
        </Tooltip>
      </div>
      <div onClick={() => editor.chain().focus().undo().run()}>
        <Tooltip light title="Annuler">
          <ArrowUturnLeftIcon className="w-4 h-4" />
        </Tooltip>
      </div>
      <div onClick={() => editor.chain().focus().redo().run()}>
        <Tooltip light title="Rétablir">
          <ArrowUturnRightIcon className="w-4 h-4" />
        </Tooltip>
      </div>
      <div className="HTMLEditorVars" onClick={() => setVarsOpen(!varsOpen)}>
        <div>Variables</div>
        {varsOpen && (
          <div className="HTMLEditorVarMenu">
            {Object.keys(VARIABLES).map((key: string) => (
              <div key={key} className="varMenu">
                <div className="varMenuTitle">{key}</div>
                <div>
                  {VARIABLES[key].map((v: { key: string; value: string }) => (
                    <div
                      className="varMenuElem"
                      key={v.key}
                      onClick={() => editor.chain().focus().insertContent(v.value).run()}
                    >
                      {v.key}
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

function cleanHtml(input: string): string {
  return input.replaceAll(/\s{2,}/g, " ").replaceAll(/_{5,}/g, "<hr/>");
}

const HtmlEditor: FunctionComponent<Props> = (props) => {
  const editor = useEditor({
    extensions: [
      StarterKit,
      Link.configure({
        autolink: true,
        linkOnPaste: true,
        validate: (href) => /^https?:\/\//.test(href),
      }),
    ],
    content: cleanHtml(props.content || ""),
    onUpdate: (e) => props.onChange(cleanHtml(e.editor.getHTML())),
  });

  useEffect(() => {
    if (editor)
      editor
        .chain()
        .clearContent()
        .insertContent(props.content || "")
        .run();
  }, [props.content]);

  if (!editor) {
    return null;
  }

  return (
    <div className="HTMLEditor">
      <MenuBar editor={editor} />
      <EditorContent className="HTMLEditorContent" style={{ minHeight: 200, display: "flex" }} editor={editor} />
    </div>
  );
};

export default HtmlEditor;
