import React, { useEffect, useState } from "react";
import { FunctionComponent } from "react";
import { CustomerResponse, deleteCustomer as deleteCustomerRoute } from "../../../models/customers";
import InlineLabelValue from "../../../components/inlineLabelValue";
import { Contact, getCustomerContacts } from "../../../models/contacts";
import { UUID } from "../../../models/common";
import store, { Message } from "../../../services/store";
import ContactsList from "../../../components/billing/contacts/contactsList";
import { TemplateData } from "../../../models/messenger";
import CustomerReportForm from "../../../components/forms/customerReportForm";
import { toast } from "react-hot-toast";
import { Divider, Modal, MoreActions } from "@getprorecrutement/getpro-design";
import { DocumentIcon, MapPinIcon, PencilIcon, TrashIcon, UserIcon } from "@heroicons/react/24/outline";

interface Props {
  customer: CustomerResponse;
  fetchCustomers: () => Promise<void>;
  setCustomer: (customer?: CustomerResponse) => void;
  setEdit: (val: boolean) => void;
}

export const CustomerCard: FunctionComponent<Props> = ({ customer, fetchCustomers, setCustomer, setEdit }) => {
  const [contacts, setContacts] = useState<Contact[]>();
  const [generateReport, setGenerateReport] = useState<boolean>(false);

  const getContacts = async (id: UUID) => {
    const c = await getCustomerContacts(id);
    setContacts(c);
  };

  const deleteCustomer = async () => {
    await deleteCustomerRoute(customer?.id as UUID);

    toast.success(`Entreprise supprimée avec succès`);
    setCustomer(undefined);
    fetchCustomers();
  };

  const openMessenger = (contact: Contact) => {
    const templateData: TemplateData = { ...contact };
    store.notify(Message.Messenger, {
      label: `${contact.first_name} ${contact.last_name}`,
      to: contact.email,
      from: store.state.User?.email,
      templateData,
      closeAfterSend: true,
    });
  };

  useEffect(() => {
    getContacts(customer.id);
  }, [customer]);

  return (
    <div className="w-1/3">
      <div className="bg-white p-6 shadow-md">
        <div className="flex justify-between mb-4 items-center">
          <div className="flex items-center gap-4 flex-wrap">
            {customer.file_id && (
              <img
                className="companyLogo"
                src={`https://storage.googleapis.com/getpro-public/${
                  customer.file_id || "e1ca4dfa-7173-40a1-8f9a-917dc3f3556f"
                }`}
              />
            )}
            <div className="text-3xl">{customer.name}</div>
          </div>
          <MoreActions
            light
            actions={[
              { title: "Générer un rapport", action: () => setGenerateReport(true), icon: <DocumentIcon /> },
              { title: "Editer", action: () => setEdit(true), icon: <PencilIcon /> },
              {
                title: "Supprimer",
                action: deleteCustomer,
                popConfirm: { show: true, title: "Supprimer l'entreprise ?" },
                icon: <TrashIcon />,
              },
            ]}
          />
        </div>
        {customer.description && (
          <InlineLabelValue
            label="Description"
            direction="vertical"
            value={customer.description}
            bold={true}
            labelWidth={170}
          />
        )}
        <InlineLabelValue
          label="Adresse de facturation"
          value={customer.location.formatted_address}
          bold={true}
          icon={<MapPinIcon width={20} height={20} />}
          labelWidth={170}
          direction="vertical"
        />
        <InlineLabelValue
          label={"Propriétaire"}
          value={`${customer.owner_first_name} ${customer.owner_last_name}`}
          bold={true}
          icon={<UserIcon width={20} height={20} />}
          labelWidth={170}
          direction="vertical"
        />
        <Divider light />
        <div className="text-lg font-bold mb-2">Facturation</div>
        <InlineLabelValue label={"N° TVA"} value={customer.tax_number} bold={true} labelWidth={170} />
        <InlineLabelValue label={"N° SIRET"} value={customer.siret} bold={true} labelWidth={170} />
        {contacts && contacts.length > 0 && (
          <>
            <Divider light />
            <ContactsList contacts={contacts} openMessenger={openMessenger} direction="vertical" />
          </>
        )}
      </div>
      <Modal light className="w-modal-xl" show={generateReport} onClose={() => setGenerateReport(false)}>
        <CustomerReportForm customer={customer} />
      </Modal>
    </div>
  );
};

export default CustomerCard;
