import React, { FunctionComponent, useEffect, useState } from "react";
import {
  Project,
  getProjects,
  getProject,
  ProjectType,
  RpoDetails,
  ProductDetails,
  RpoCostLine,
} from "../../../models/billing/project";
import { ContractResponse, getContracts } from "../../../models/billing/contract";
import { Paginated, UUID } from "../../../models/common";
import { Bill, BillLine, BillStatus, createBill, NewBill, updateBill } from "../../../models/billing/bill";
import dayjs from "dayjs";
import CustomerSelect from "../../forms/inputs/customerSelect";
import {
  Textarea,
  Button,
  Checkbox,
  TextInput,
  InputNumber,
  DatePicker,
  Select,
} from "@getprorecrutement/getpro-design";
import { MinusCircleIcon, PlusIcon } from "@heroicons/react/24/outline";

interface Props {
  bill?: Bill;
  contractId?: UUID;
  customerId?: UUID;
  projectId?: UUID;
  onFinish: () => void;
}

export const BillForm: FunctionComponent<Props> = (props) => {
  const { bill } = props;
  const [loading, setLoading] = useState(false);
  const [project, setProject] = useState<Project>();
  const [contracts, setContracts] = useState<Paginated<ContractResponse>>();
  const [projects, setProjects] = useState<Paginated<Project>>();
  const [lines, setLines] = useState<BillLine[]>([]);
  const [rpoLineSelected, setRpoLineSelected] = useState<BillLine>();
  const [newLine, setNewLine] = useState<string>();
  const [contractSearch, setContractSearch] = useState<string>("");
  const [projectSearch, setProjectSearch] = useState<string>("");
  const [rpoOptions, setRpoOptions] = useState<RpoCostLine[]>([]);
  const [values, setValues] = useState<Partial<Bill> & { refund: boolean }>({
    customer_id: props.customerId,
    contract_id: props.contractId,
    project_id: props.projectId,
    ...bill,
    refund: bill?.amount && bill?.amount < 0 ? true : false,
  });

  if (props.projectId && (!props.customerId || !props.contractId))
    throw "projectId without customerId or contractId (props of BillForm)";

  // Set bills due date offset when selecting a contract
  const setContractId = (id?: UUID) => {
    const offset = contracts?.data.find((c) => c.id === id)?.bills_due_date_offset || 0;
    setValues({ ...values, contract_id: id, due_date_offset: offset });
  };

  const fetchContracts = async (id: UUID) => {
    if (props.projectId) return;
    const c = await getContracts({
      customer_id: id,
      page: 1,
      per_page: 100,
    });
    setContracts(c);
  };

  const fetchProjects = async (id: UUID) => {
    if (props.projectId) return;
    const p = await getProjects({
      contract_id: id,
      page: 1,
      per_page: 100,
    });
    setProjects(p);
  };

  const fetchProject = async (id: UUID) => {
    const p = await getProject(id);
    // if (bill?.amount && p.project_type === ProjectType.Rpo)
    //   setNbDays(Math.abs(bill.amount) / (p.details.amount || 1));
    setProject(p);
    if (p.project_type === ProjectType.Rpo) setRpoOptions((p.details as RpoDetails).cost_lines);
    if (!bill) {
      setLines(
        p.project_type === ProjectType.Rpo
          ? (p.details as RpoDetails).cost_lines.map((e) => {
              return {
                amount: e.amount,
                description: e.description,
              };
            })
          : p.project_type === ProjectType.Product
          ? (p.details as ProductDetails).products.map((e) => {
              return {
                amount: e.amount,
                description: e.description,
              };
            })
          : []
      );
    }
  };

  useEffect(() => {
    if (values.customer_id) fetchContracts(values.customer_id);
  }, [values.customer_id]);

  useEffect(() => {
    if (values.contract_id) fetchProjects(values.contract_id);
  }, [values.contract_id]);

  useEffect(() => {
    if (values.project_id) fetchProject(values.project_id);
  }, [values.project_id]);

  useEffect(() => {
    if (bill) {
      setLines(
        bill.amount && bill.amount < 0
          ? bill.lines.map((line) => ({ ...line, amount: line.amount ? Math.abs(line.amount) : undefined }))
          : bill.lines
      );
      setValues({
        ...bill,
        refund: bill?.amount && bill?.amount < 0 ? true : false,
      });
    }
  }, [bill]);

  const submit = async (data: Partial<Bill> & { refund: boolean }) => {
    setLoading(true);
    try {
      const new_lines = (
        project?.project_type === ProjectType.Rpo || project?.project_type === ProjectType.Product
          ? lines.filter((line) => typeof line.quantity === "number")
          : lines
      ).map((line) => (data.refund ? { ...line, amount: line.amount ? line.amount * -1 : undefined } : line));

      if (bill && data.due_date_offset !== undefined && data.billed_at) {
        const save = {
          id: bill.id,
          ...data,
          lines: new_lines,
          due_date: data.due_date_offset
            ? dayjs(data.billed_at).add(data.due_date_offset, "day").format("YYYY-MM-DD")
            : dayjs(data.billed_at).format("YYYY-MM-DD"),
          billed_at: dayjs(data.billed_at).format("YYYY-MM-DD"),
          paid_at: data.paid_at && dayjs(data.paid_at).format("YYYY-MM-DD"),
        };
        await updateBill(save);
      } else if (data.due_date_offset !== undefined && data.billed_at) {
        const bill = {
          ...data,
          lines: new_lines,
          due_date: data.due_date_offset
            ? dayjs(data.billed_at).add(data.due_date_offset, "day").format("YYYY-MM-DD")
            : dayjs(data.billed_at).format("YYYY-MM-DD"),
          billed_at: dayjs(data.billed_at).format("YYYY-MM-DD"),
          paid_at: data.paid_at && dayjs(data.paid_at).format("YYYY-MM-DD"),
        } as NewBill;
        await createBill(bill);
      }
      props.onFinish();
    } finally {
      setLoading(false);
    }
  };

  const removeLine = (index: number) => {
    let new_lines: BillLine[] = [];
    lines.map((e, i) => {
      if (i !== index) {
        new_lines = [...new_lines, e];
      }
    });
    setLines(new_lines);
  };

  const addItem = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    if (!rpoOptions.find((option) => option.description === (newLine || "Nouvelle ligne")))
      setRpoOptions([...rpoOptions, { description: newLine || "Nouvelle ligne", amount: 0, days: 0 }]);
    else {
      console.log("Option already exist");
      // SHOW ERROR NOTIFICATION ?
    }
    setNewLine(undefined);
  };

  const getBillInputs = () => {
    if (project?.project_type === ProjectType.Rpo)
      return (
        <div className="bg-inherit">
          {lines.length > 0 &&
            lines.map((line, i) => (
              <div key={i} className="mb-2 bg-inherit">
                <div
                  // key={`${i}-${line.description}`}
                  className="bg-inherit flex justify-between items-center gap-4 mb-4"
                >
                  <TextInput
                    onChange={() => null}
                    light
                    placeholder=""
                    label="Description"
                    type="text"
                    value={line.description}
                    style={{ width: 400 }}
                    disabled
                  />
                  <InputNumber
                    style={{ width: 150 }}
                    light
                    placeholder="TJM"
                    label="TJM"
                    disabled={
                      !!(project.details as RpoDetails).cost_lines.find((cost) => cost.description === line.description)
                    }
                    value={line.amount}
                    onChange={(e) =>
                      setLines(
                        lines.map((line: BillLine, index: number) => (i === index ? { ...line, amount: e } : line))
                      )
                    }
                    display={{
                      formatter: (value) => value && value.replace(/\B(?=(\d{3})+(?!\d))/g, " "),
                      parser: (value) => (value ? value.replace(/[\s€]*/g, "") : ""),
                    }}
                    precision={2}
                    suffix="€"
                    min={0}
                    controls={false}
                  />
                  <InputNumber
                    style={{ width: 150 }}
                    controls={false}
                    light
                    placeholder="Jours"
                    label="Jours"
                    value={line.quantity}
                    onChange={(e) =>
                      setLines(
                        lines.map((line: BillLine, index: number) => (i === index ? { ...line, quantity: e } : line))
                      )
                    }
                    precision={2}
                    min={0}
                  />
                  <Button className="p-0" light kind="light" icon={<MinusCircleIcon />} onClick={() => removeLine(i)} />
                </div>
                <Textarea
                  light
                  label="Notes"
                  rows={2}
                  value={line.note}
                  placeholder="Notes"
                  onChange={(e) =>
                    setLines(lines.map((line: BillLine, index: number) => (i === index ? { ...line, note: e } : line)))
                  }
                />
              </div>
            ))}
          <div className="mt-6 flex justify-between items-center gap-4 bg-inherit">
            <Select
              style={{ width: 500 }}
              light
              bordered
              rounded
              label="Ligne de facturation"
              // allowClear
              options={rpoOptions.filter((cost: RpoCostLine) => {
                let ret = true;
                lines.map((line) => {
                  if (cost.description === line.description) {
                    ret = false;
                  }
                });
                return ret;
              })}
              type="single"
              optionRender={(item) => item.description}
              getKey={(item) => item.description}
              value={rpoOptions.find((line) => line.description === rpoLineSelected?.description)}
              onChange={(e) => setRpoLineSelected(e)}
              dropdownRender={(menu) => (
                <div>
                  {menu}
                  <div className="flex justify-center items-center gap-2 pb-2 px-2">
                    <TextInput
                      size="small"
                      type="text"
                      light
                      bordered={false}
                      className="border-b border-b-primary-lighter"
                      placeholder="Option"
                      value={newLine}
                      onChange={(e) => setNewLine(e.target.value)}
                    />
                    <div onClick={(e) => newLine && addItem(e)}>
                      <Button light kind="light" className="p-0" icon={<PlusIcon />} />
                    </div>
                  </div>
                </div>
              )}
            />
            <Button
              title="Ajouter une ligne"
              light
              disabled={!rpoLineSelected}
              onClick={() => {
                rpoLineSelected && setLines([...lines, rpoLineSelected]);
                setRpoLineSelected(undefined);
              }}
            />
          </div>
        </div>
      );
    else if (project?.project_type === ProjectType.Product)
      return (
        <div className="bg-inherit">
          {lines.length > 0 &&
            lines.map((line, i) => (
              <div key={`${i}-${line.description}`} className="flex justify-between items-center mb-2 gap-4 bg-inherit">
                <TextInput
                  onChange={() => null}
                  light
                  placeholder=""
                  label="Description"
                  type="text"
                  value={line.description}
                  style={{ width: 500 }}
                  disabled
                />
                <InputNumber
                  controls={false}
                  style={{ width: 150 }}
                  light
                  placeholder=""
                  label="Prix"
                  disabled
                  suffix="€"
                  value={line.amount}
                  precision={2}
                />
                <InputNumber
                  style={{ width: 150 }}
                  controls={false}
                  light
                  placeholder="Quantité"
                  label="Quantité"
                  value={line.quantity}
                  precision={2}
                  onChange={(e) =>
                    setLines(
                      lines.map((line: BillLine, index: number) => (i === index ? { ...line, quantity: e } : line))
                    )
                  }
                  min={0}
                />
                <Button className="p-0" light kind="light" icon={<MinusCircleIcon />} onClick={() => removeLine(i)} />
              </div>
            ))}
        </div>
      );
    else {
      return (
        <div className="bg-inherit">
          {lines.length > 0 &&
            lines.map((line, i) => (
              <div key={i} className="mb-2 bg-inherit">
                <div className="flex justify-between items-center mb-2 gap-4 bg-inherit">
                  <TextInput
                    light
                    placeholder="Nouvelle ligne"
                    label="Description"
                    type="text"
                    value={line.description}
                    style={{ width: 500 }}
                    onChange={(e) =>
                      setLines(
                        lines.map((line: BillLine, index: number) =>
                          i === index ? { ...line, description: e.currentTarget.value } : line
                        )
                      )
                    }
                  />
                  <InputNumber
                    style={{ width: 150 }}
                    controls={false}
                    light
                    placeholder="Prix"
                    label="Prix"
                    value={line.amount}
                    onChange={(e) =>
                      setLines(
                        lines.map((line: BillLine, index: number) => {
                          return i === index ? { ...line, amount: e } : line;
                        })
                      )
                    }
                    display={{
                      formatter: (value) => value && value.replace(/\B(?=(\d{3})+(?!\d))/g, " "),
                      parser: (value) => (value ? value.replace(/[\s€]*/g, "") : ""),
                    }}
                    precision={2}
                    suffix="€"
                  />
                  <InputNumber
                    style={{ width: 150 }}
                    controls={false}
                    light
                    placeholder="Quantité"
                    label="Quantité"
                    value={line.quantity}
                    precision={2}
                    onChange={(e) =>
                      setLines(
                        lines.map((line: BillLine, index: number) =>
                          i === index
                            ? {
                                ...line,
                                quantity: e,
                              }
                            : line
                        )
                      )
                    }
                  />
                  <Button className="p-0" light kind="light" icon={<MinusCircleIcon />} onClick={() => removeLine(i)} />
                </div>
                <Textarea
                  light
                  label="Notes"
                  rows={2}
                  value={line.note}
                  placeholder="Notes"
                  onChange={(e) =>
                    setLines(lines.map((line: BillLine, index: number) => (i === index ? { ...line, note: e } : line)))
                  }
                />
              </div>
            ))}
          <div className="flex justify-end">
            <Button
              size="small"
              title="Ajouter une ligne"
              light
              onClick={() => {
                setLines((e) => [...e, { amount: 0, description: "" }]);
              }}
            />
          </div>
        </div>
      );
    }
  };

  return (
    <>
      <div className="mb-2 text-2xl">Détails de la facture</div>
      <div className="bg-inherit flex flex-col gap-4 py-2 pr-4 overflow-auto">
        {!props.customerId && !bill && (
          <CustomerSelect
            label="Entreprise"
            value={values.customer_id}
            onSelect={(e) => setValues({ ...values, customer_id: e })}
            showSearch
          />
        )}
        {!props.contractId && !bill && (
          <Select
            label="Contrat"
            light
            bordered
            rounded
            options={
              contracts?.data.filter(
                (c) => contractSearch === "" || c.name.toLowerCase().includes(contractSearch.toLowerCase())
              ) || []
            }
            disabled={!values.customer_id}
            type="single"
            value={contracts?.data.find((c) => c.id === values.contract_id)}
            optionRender={(item) => item.name}
            getKey={(item) => item.id}
            onChange={(e) => e && setContractId(e.id)}
            onSearch={(v) => {
              setContractSearch(v);
              if (values.contract_id) setValues({ ...values, contract_id: undefined });
            }}
          />
        )}
        {!props.projectId && !bill && (
          <Select
            label="Projet"
            light
            bordered
            rounded
            value={projects?.data.find((p) => p.id === values.project_id)}
            optionRender={(item) => item.name}
            getKey={(item) => item.id}
            options={
              projects?.data.filter(
                (c) => projectSearch === "" || c.name.toLowerCase().includes(projectSearch.toLowerCase())
              ) || []
            }
            disabled={!values.contract_id}
            type="single"
            onChange={(e) => e && setValues({ ...values, project_id: e.id })}
            onSearch={(v) => {
              setProjectSearch(v);
              if (values.project_id) setValues({ ...values, project_id: undefined });
            }}
          />
        )}
        {getBillInputs()}
        <Checkbox
          light
          value={values.retainer || false}
          onChange={(e) => setValues({ ...values, retainer: e })}
          label="Acompte"
        />
        <Checkbox light value={values.refund} onChange={(e) => setValues({ ...values, refund: e })} label="Avoir" />
        <DatePicker
          label="Date de facturation"
          light
          value={values.billed_at}
          onChange={(val) => setValues({ ...values, billed_at: val })}
        />
        <div className="bg-inherit">
          <InputNumber
            light
            label="Délai de paiement"
            placeholder="15"
            value={values.due_date_offset}
            onChange={(e) => setValues({ ...values, due_date_offset: e })}
          />
          {values.billed_at && values.due_date_offset !== undefined && (
            <div className="mt-2">
              Échéance : {dayjs(values.billed_at).add(values.due_date_offset, "day").format("LL")}
            </div>
          )}
        </div>
        {bill?.status === BillStatus.Paid && (
          <DatePicker
            label="Date de paiement"
            light
            value={values.paid_at}
            onChange={(val) => setValues({ ...values, paid_at: val })}
          />
        )}
        <div className="flex justify-end mt-4">
          <Button
            title="Enregister"
            light
            size="small"
            loading={loading}
            onClick={() => submit(values)}
            disabled={
              !values.customer_id ||
              !values.contract_id ||
              !values.project_id ||
              !values.billed_at ||
              values.due_date_offset === undefined ||
              lines.length === 0
            }
          />
        </div>
      </div>
    </>
  );
};

export default BillForm;
