import React, { ComponentType } from "react";
import { render } from "react-dom";
import { BrowserRouter } from "react-router-dom";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import advancedFormat from "dayjs/plugin/advancedFormat";
import weekday from "dayjs/plugin/weekday";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
import utc from "dayjs/plugin/utc";
import "dayjs/locale/fr";
import App from "./components/app";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import Tracker from "@openreplay/tracker";
import trackerAssist from "@openreplay/tracker-assist";

if (process.env.NODE_ENV === "production")
  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    environment: process.env.STAGING ? "staging" : process.env.NODE_ENV,
    integrations: [
      new BrowserTracing({
        tracingOrigins: ["api-beta.getpro.co", "api.getpro.co"],
      }),
    ],
    release: process.env.npm_package_version,
    tracesSampleRate: 1,
    attachStacktrace: true,
  });

export const tracker = new Tracker({
  defaultInputMode: 0,
  obscureInputDates: false,
  obscureInputEmails: false,
  obscureInputNumbers: false,
  obscureTextEmails: false,
  obscureTextNumbers: false,
  projectKey: "x3kquZuZtFUMi8oDruMv",
  ingestPoint: "https://or.insynia.com/ingest",
});
tracker.use(trackerAssist({}));
process.env.NODE_ENV === "production" && !process.env.STAGING && tracker.start();

async function renderApp(AppElem: ComponentType) {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const mountNode = document.getElementById("app")!;

  render(
    <BrowserRouter>
      <AppElem />
    </BrowserRouter>,
    mountNode
  );
}

dayjs.extend(localizedFormat);
dayjs.extend(advancedFormat);
dayjs.extend(utc);
dayjs.extend(weekday);
dayjs.extend(quarterOfYear);
dayjs.locale("fr");

renderApp(App);
