import React, { FunctionComponent, useEffect } from "react";
import { Checkbox, Button, Radio } from "@getprorecrutement/getpro-design";
import { useState } from "react";
import { JobOffer, Status } from "../models/jobOffers";
import StatusSelect from "./forms/inputs/statusSelect";
import { UUID } from "../models/common";
import { JobApplicationResponse } from "../models/jobApplications";
import dayjs from "dayjs";
import { expFromFirstJobAt, euroDisplay } from "../utils/formatters";
import download from "../utils/download";
import { getStatuses } from "../services/requests";
import { getJoJas } from "../models/jobOffers";

interface Props {
  jobOffer: JobOffer;
  onExport?: () => void;
  onFinish?: () => void;
}

interface Column {
  name: string;
  default: boolean;
  get: (ja: JobApplicationResponse, statuses: Status[]) => string | undefined;
}

const COLUMNS: Column[] = [
  {
    name: "Nom",
    default: true,
    get: (ja: JobApplicationResponse) => ja.candidate.full_name || "Candidat anonyme",
  },
  {
    name: "Titre", // LinkedIn
    default: true,
    get: (ja: JobApplicationResponse) => ja.candidate.details.title,
  },
  {
    name: "Adresse e-mail",
    default: true,
    get: (ja: JobApplicationResponse) => ja.candidate.email,
  },
  {
    name: "Téléphone",
    default: true,
    get: (ja: JobApplicationResponse) => ja.candidate.phone,
  },
  {
    name: "LinkedIn",
    default: true,
    get: (ja: JobApplicationResponse) => ja.candidate.linkedin_url,
  },
  {
    name: "Localisation",
    default: false,
    get: (ja: JobApplicationResponse) => ja.candidate.location?.formatted_address,
  },
  {
    name: "Disponibilité",
    default: true,
    get: (ja: JobApplicationResponse) =>
      ja.candidate.available_at ? dayjs(ja.candidate.available_at).format("LL") : undefined,
  },
  {
    name: "Salaire souhaité",
    default: true,
    get: (ja: JobApplicationResponse) => (ja.candidate.salary ? euroDisplay(ja.candidate.salary) : undefined),
  },
  {
    name: "Expérience",
    default: false,
    get: (ja: JobApplicationResponse) =>
      (ja.candidate.first_job_at && expFromFirstJobAt(ja.candidate.first_job_at)) || undefined,
  },
  {
    name: "Date d'ajout",
    default: true,
    get: (ja: JobApplicationResponse) => dayjs.utc(ja.created_at).format("LL"),
  },
  {
    name: "Statut",
    default: false,
    get: (ja: JobApplicationResponse, statuses?: Status[]) =>
      statuses ? statuses.find((s) => s.id === ja.status_id)?.name : "test",
  },
  {
    name: "Dernière entreprise",
    default: false,
    get: (ja: JobApplicationResponse) => (ja.candidate.details.experiences[0] || {}).company,
  },
  {
    name: "Dernière école",
    default: false,
    get: (ja: JobApplicationResponse) => (ja.candidate.details.educations[0] || {}).school,
  },
  {
    name: "Date de màj",
    default: false,
    get: (ja: JobApplicationResponse) => dayjs.utc(ja.updated_at).format("LL"),
  },
  {
    name: "Clôturée",
    default: true,
    get: (ja: JobApplicationResponse) => (ja.terminated ? "Oui" : "Non"),
  },
  {
    name: "Commentaire clôture",
    default: false,
    get: (ja: JobApplicationResponse) => ja.termination_comment,
  },
];

export const ExportJas: FunctionComponent<Props> = (props) => {
  const [statuses, setStatuses] = useState<Status[]>([]);
  const [selectedStatusesIds, setSelectedStatusesIds] = useState<UUID[]>([]);
  const [selectedColumns, setSelectedColumns] = useState<Column[]>([...COLUMNS.filter((c) => c.default)]);
  const [jobApps, setJobApps] = useState<JobApplicationResponse[]>([]);
  const [total, setTotal] = useState<number | undefined>(undefined);
  const [terminated, setTerminated] = useState<boolean>();

  const fetchStatuses = async () => {
    getStatuses().then(setStatuses);
  };

  useEffect(() => {
    fetchStatuses();
  }, []);

  useEffect(() => {
    fetchJas();
  }, [selectedStatusesIds, terminated]);

  const fetchJas = async () => {
    const resp = await getJoJas(props.jobOffer.id, {
      page: 1,
      per_page: 1000,
      status_ids: selectedStatusesIds,
      terminated,
    });

    setJobApps(resp.data);
    setTotal(resp.total);
  };

  const exportData = () => {
    const csv = [];
    csv.push(selectedColumns.map((c) => c.name));
    const lines = jobApps.map((ja) =>
      selectedColumns
        .map((c) => c.get(ja, statuses) || "")
        .map((str) => `${str}`.replace(/"/g, ""))
        .map((e) => `"${e}"`)
    );
    download("export.csv", csv.concat(lines).join("\n"));
    props.onFinish && props.onFinish();
  };

  return (
    <>
      <div className="mb-2">
        <div className="text-3xl font-bold mb-2">Export de candidatures</div>
        <div className="text-2xl font-bold">{props.jobOffer.name}</div>
      </div>
      <div className="exportJas bg-inherit py-2 pr-4 overflow-auto">
        <StatusSelect
          placeholder={"Sélectionner les statuts à exporter"}
          multiple
          label="Statuts"
          statuses={statuses}
          value={selectedStatusesIds}
          onSelect={(v) => {
            selectedStatusesIds?.includes(v)
              ? setSelectedStatusesIds(selectedStatusesIds.filter((e) => e !== v))
              : setSelectedStatusesIds([...(selectedStatusesIds || []), v]);
          }}
        />
        <div className="my-2 flex justify-between items-center">
          <Radio
            light
            optionType="button"
            options={[
              { label: "Toutes", value: "undefined" },
              { label: "Actives", value: "false" },
              { label: "Cloturées", value: "true" },
            ]}
            value={typeof terminated !== "undefined" ? (terminated ? "true" : "false") : "undefined"}
            onChange={(e) => setTerminated(e === "undefined" ? undefined : e === "true")}
          />
          <div className="m-3 text-content-medium">
            {!(typeof total === "undefined") ? `${total} candidatures à exporter` : ""}
          </div>
        </div>
        <div className="flex items-center flex-wrap gap-y-1">
          {COLUMNS.map((c) => (
            <div key={c.name} className="w-1/4">
              <Checkbox
                value={!!selectedColumns.find((sc) => sc.name === c.name)}
                onChange={(e) =>
                  e
                    ? setSelectedColumns((sc) => [...sc, c])
                    : setSelectedColumns((sc) => sc.filter((sc) => sc.name !== c.name))
                }
                light
                label={c.name}
              />
            </div>
          ))}
        </div>
        <div className="flex justify-end mt-2">
          <Button onClick={exportData} title="Exporter" light size="small" />
        </div>
      </div>
    </>
  );
};

export default ExportJas;
