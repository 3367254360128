import { toast } from "react-hot-toast";
import httpClient from "../../services/http/client";
import ROUTES, { PaginationParams } from "../../services/http/routes";
import { notifyError } from "../../services/requests";
import { Paginated, UUID } from "../common";
import { Contract } from "./contract";

export enum ProjectStatus {
  Pending = "pending",
  Started = "started",
  Finished = "finished",
}

export const ProjectStatuses = [
  { label: "En attente", value: ProjectStatus.Pending },
  { label: "En cours", value: ProjectStatus.Started },
  { label: "Terminé", value: ProjectStatus.Finished },
];

export enum ProjectType {
  Commission = "recruitment_commission",
  CommissionRate = "recruitment_commission_rate",
  CommissionThird = "recruitment_commission_third",
  CommissionNone = "recruitment_commission_none",
  Subscription = "subscription",
  Rpo = "rpo",
  Product = "product",
}

export const ProjectTypes = {
  recruitment: [
    { label: "Commission fixe", value: ProjectType.Commission },
    { label: "Commission taux", value: ProjectType.CommissionRate },
    { label: "Trois tiers", value: ProjectType.CommissionThird },
    { label: "Pas de commission", value: ProjectType.CommissionNone },
  ],
  other: [
    { label: "Abonnement", value: ProjectType.Subscription },
    { label: "RPO", value: ProjectType.Rpo },
    { label: "Produit", value: ProjectType.Product },
  ],
};

export interface RpoCostLine {
  amount: number;
  description: string;
  days: number;
}

export interface RpoDetails {
  cost_lines: RpoCostLine[];
}

export interface ProductLine {
  amount: number;
  description: string;
  quantity: number;
}

export interface ProductDetails {
  products: ProductLine[];
}

export interface SubscriptionDetails {
  amount: number;
  quantity: number;
}

export interface CommissionDetails {
  amount: number;
  retainer?: number;
  percent_billed_at_recruitment: number;
}

export interface CommissionRateDetails {
  rate: number;
  amount: number;
  minimum_billed?: number;
  retainer?: number;
  percent_billed_at_recruitment: number;
}

export interface CommissionThirdDetails {
  amount: number;
  candidates_sent_requirement: number;
}

export type ProjectDetails = (
  | CommissionThirdDetails
  | CommissionRateDetails
  | CommissionDetails
  | SubscriptionDetails
  | ProductDetails
  | RpoDetails
  | any
) & { project_type: string };

export interface ProjectResponse extends Project {
  customer_name: string;
  business_unit_name: UUID;
  contract: Contract;
}

export interface Project extends NewProject {
  id: UUID;
  bills_count: number;
  amount_billed: number;
  created_at: string;
  updated_at: string;
}

export interface NewProject {
  customer_id: UUID;
  contract_id: UUID;
  owner_id: UUID;
  project_type: ProjectType;
  name: string;
  description?: string;
  status?: ProjectStatus;
  manual_billing?: boolean;
  business_unit_id: UUID;
  details: ProjectDetails;
}

export interface ProjectData {
  owner_id?: UUID;
  contract_id?: UUID;
  project_type?: string;
  name?: string;
  description?: string;
  status?: ProjectStatus;
  manual_billing?: boolean;
  business_unit_id?: UUID;
  details?: ProjectDetails;
}

export type ProjectsFilters = {
  contract_id?: UUID;
  name?: string;
  status?: ProjectStatus;
} & PaginationParams;

export async function getProject(id: UUID): Promise<ProjectResponse> {
  return httpClient.req<ProjectResponse>(ROUTES.FETCH_PROJECT(id), (err) =>
    notifyError(err || "Erreur lors de la récupération du projet")
  );
}

export async function getProjects(
  filters: ProjectsFilters & { customer_id?: UUID }
): Promise<Paginated<ProjectResponse>> {
  return httpClient.req<Paginated<ProjectResponse>>(ROUTES.FETCH_PROJECTS(filters), () =>
    notifyError("Erreur lors de la récupération des projets")
  );
}

export async function createProject(new_project: NewProject): Promise<Project> {
  return httpClient.req(ROUTES.CREATE_PROJECT(new_project), (err) =>
    notifyError(err || "Erreur lors de la création du projet")
  );
}

export async function updateProject(data: ProjectData & { id: UUID }): Promise<Project> {
  return httpClient.req(ROUTES.UPDATE_PROJECT(data), (err) => notifyError(err || "Échec de la mise à jour du projet"));
}

export async function setProjectContacts(id: UUID, contacts: UUID[]): Promise<void> {
  return httpClient
    .req(ROUTES.SET_PROJECT_CONTACTS(id, contacts), (err) =>
      notifyError(err || "Échec de la mise à jour des contacts du projet")
    )
    .then(() => {
      toast.success("Contacts du projet mis à jour avec succès");
    });
}

export async function getAmountPaid(id: UUID): Promise<{ value: number }> {
  return httpClient.req(ROUTES.FETCH_AMOUNT_PAID(id), (err) =>
    notifyError(err || "Échec de la récupération du total de facturation")
  );
}

export async function getProjectBillsDetails(params: {
  project_id: UUID;
}): Promise<{ desc: string; quantity: number }[]> {
  return httpClient.req(ROUTES.FETCH_PROJECT_BILLS_DETAILS(params), (err) =>
    notifyError(err || "Erreur lors de la récupération des détails de facturation")
  );
}
