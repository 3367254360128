import React, { FunctionComponent, useState } from "react";
import { MetaFormStepProps } from "..";
import { useFormManager } from "../../../../services/form";
import { isEmailValid } from "../../../../utils";
import { Button, InputNumber, Slider, TextInput } from "@getprorecrutement/getpro-design";
import dayjs from "dayjs";
import { updateCandidate } from "../../../../models/candidate";
import HelpTooltip from "../../../helpTooltip";
import CityGeocodingSelect from "../../inputs/cityGeocodingSelect";
import { NewCandidateLocation } from "../../../../models/candidateLocation";

type FormType = {
  email?: string;
  phone?: string;
  salary?: number;
  locations?: NewCandidateLocation[];
  first_job_at?: string;
};

export const PresentedBasicStep: FunctionComponent<MetaFormStepProps> = ({ onSubmit, candidate, jobApp, onCancel }) => {
  const [loading, setLoading] = useState<boolean>();

  const form = useFormManager<FormType>({
    defaultState: {
      email: candidate?.email,
      phone: candidate?.phone,
      salary: candidate?.salary,
      locations: undefined,
      first_job_at: candidate?.first_job_at,
    },
    validations: {
      first_job_at: {
        required: true,
        message: "Merci de préciser le nombre d'années d'expérience du candidat",
      },
      email: {
        required: (v) => !!v && isEmailValid(v),
        message: "Merci de préciser une adresse email valide",
      },
      phone: {
        required: true,
        message: "Merci de préciser un numéro de téléphone valide",
      },
      salary: {
        required: (salary) => !!salary && salary > 1000,
        message: "Merci de préciser le salaire du candidat",
      },
    },
  });

  const submit = async () => {
    if (form.validate()) {
      setLoading(true);
      try {
        const newCandidate = await updateCandidate({ id: candidate.id, ...form.state });
        setLoading(false);
        onSubmit(newCandidate, jobApp);
      } catch {
        setLoading(false);
      }
    }
  };

  return (
    <>
      <TextInput
        light
        className="mt-4"
        type="text"
        label="Adresse e-mail"
        placeholder="Adresse e-mail"
        value={form.state.email}
        error={form.errors.email}
        onChange={(e) => form.onChange({ email: e.currentTarget.value })}
      />
      <TextInput
        light
        label="Numéro de téléphone"
        className="mt-4"
        type="text"
        placeholder="Numéro de téléphone"
        value={form.state.phone}
        error={form.errors.phone}
        onChange={(e) => form.onChange({ phone: e.currentTarget.value })}
      />
      <div className="flex items-center gap-1 mt-4 bg-inherit">
        <div className="flex-1 bg-inherit">
          <InputNumber
            light
            label="Salaire annuel actuel"
            placeholder="50 000 €"
            value={form.state.salary}
            error={form.errors.salary}
            onChange={(e) => form.onChange({ salary: e })}
            display={{
              formatter: (value) => value && value.replace(/\B(?=(\d{3})+(?!\d))/g, " "),
              parser: (value) => (value ? value.replace(/[\s€]*/g, "") : ""),
            }}
            precision={2}
            suffix="€"
            min={0}
            controls={false}
          />
        </div>
        <HelpTooltip position="left" title={"Salaire fixe + variable sans les avantages ni BSPCE"} />
      </div>
      <div className="mt-4 bg-inherit">
        <CityGeocodingSelect
          candidateId={candidate.id}
          label="Localisation"
          onSelect={(locations) => form.onChange({ locations })}
        />
      </div>
      <div className="w-full h-16 mt-4 px-4">
        <div>Expérience</div>

        <Slider
          light
          size={51}
          defaultValue={dayjs().diff(dayjs(form.state.first_job_at), "year")}
          onChange={(e: number) => {
            const first_job_at = dayjs().subtract(e, "year").date(1).toISOString().split("T")[0];
            form.onChange({ first_job_at });
          }}
        />
        {form.errors.first_job_at && <div className="text-error-normal italic">{form.errors.first_job_at}</div>}
      </div>
      <div className="flex justify-between mt-8">
        <Button size="small" light kind="outline" title="Annuler" onClick={onCancel} />
        <Button size="small" light loading={loading} disabled={form.disabled} title="Enregistrer" onClick={submit} />
      </div>
    </>
  );
};
