import React, { useState } from "react";
import { FunctionComponent } from "react";
import { useDeepCompareEffect } from "react-use";
import { UUID } from "../../../../models/common";
import { GlobalFilters, PipeCandidate, getCandidatesPipe, DashboardModule } from "../../../../models/dashboards";
import { Status } from "../../../../models/jobOffers";
import ModuleTitle from "../../elements/title";
import CandidatePipeList from "./list";
import { Divider, Checkbox, Radio } from "@getprorecrutement/getpro-design";
import DashboardStats from "../../elements/stats";
import { PALETTE } from "../..";
import DashboardTimeline, { PerUserOrBuData } from "../../elements/timeline";
import PipeJos from "./jos";

export interface PipeData {
  candidates: PipeCandidate[];
  per_user: PerUserOrBuData[];
  per_bu: PerUserOrBuData[];
  summary: { [name: string]: number };
  jos?: {
    id: UUID;
    name: string;
    summary: { name: string; count: number }[];
  }[];
}

interface Props {
  filters: GlobalFilters;
  locked: boolean;
  dashboardSlug: string;
  mod: DashboardModule<{
    statuses_ids?: UUID[];
    archived?: boolean;
    chart_type?: "users" | "bus" | "candidates" | "jos";
    opportunity_type?: "cabinet" | "rpo" | "interne";
  }>;
  statuses: Status[];
  updateModule: (data: {
    dashboard_slug: string;
    module_key: string;
    filters: {
      statuses_ids?: UUID[];
      archived?: boolean;
      chart_type?: "users" | "bus" | "candidates" | "jos";
      opportunity_type?: "cabinet" | "rpo" | "interne";
    };
    title?: string;
  }) => Promise<void>;
}

export const CandidatePipeModule: FunctionComponent<Props> = (props) => {
  const { filters, locked, dashboardSlug, mod, statuses, updateModule } = props;
  const [sortFilter, setSortFilter] = useState<{ field: string; order?: "ascend" | "descend" }>();
  const [candidatePipeData, setCandidatePipeData] = useState<PipeData>();
  const [chartTypeSelected, _setTimelineSelected] = useState<"users" | "bus" | "candidates" | "jos">(
    mod.filters.chart_type || "users"
  );
  const [opportunityType, _setOpportunityType] = useState<"cabinet" | "rpo" | "interne" | undefined>(
    mod.filters.opportunity_type
  );

  const setTimelineSelect = async (value: "users" | "bus" | "candidates" | "jos") => {
    await updateModule({
      dashboard_slug: dashboardSlug,
      module_key: mod.key,
      filters: {
        chart_type: value,
      },
    });
  };

  const setTimelineSelected = (value: "users" | "bus" | "candidates" | "jos") => {
    setTimelineSelect(value);
    _setTimelineSelected(value);
  };

  const setOpportunityType = async (value: "all" | "cabinet" | "rpo" | "interne") => {
    const save = value === "all" ? undefined : value;
    await updateModule({
      dashboard_slug: dashboardSlug,
      module_key: mod.key,
      filters: {
        opportunity_type: save,
      },
    });
    _setOpportunityType(save);
  };

  const getUsersData = (
    data: {
      name: string;
      status: UUID;
      count?: number;
    }[]
  ): PerUserOrBuData[] => {
    const res = data.reduce(
      (
        res: {
          [date: string]: { [name: string]: number; total: number };
        },
        data
      ) => {
        const status = statuses.find((s) => s.id === data.status);
        if (status) {
          res[data.name] ||= { total: 0 };
          res[data.name][status.name] = data.count || 0;
          res[data.name].total += data.count || 0;
        }
        return res;
      },
      {}
    );

    return Object.keys(res).map((d) => ({ name: d, ...res[d] } as PerUserOrBuData));
  };

  const fetchCandidatesPipe = async () => {
    if (statuses.length > 0) {
      const response = await getCandidatesPipe({
        users: filters.users,
        business_units: filters.businessUnits,
        job_offers: filters.jobOffers,
        customers: filters.customers,
        statuses_ids:
          (mod.filters.statuses_ids || []).length === 0 ? statuses.map((s) => s.id) : mod.filters.statuses_ids,
        archived: mod.filters.archived,
        chart_type: chartTypeSelected,
        opportunity_type: opportunityType,
        ...sortFilter,
      });

      const res = {
        ...response,
        summary: response.summary.reduce((o, data) => ({ ...o, [data[0]]: data[1] }), {}),
        per_user: response.per_user && getUsersData(response.per_user).sort((a, b) => b.total - a.total),
        per_bu: response.per_bu && getUsersData(response.per_bu).sort((a, b) => b.total - a.total),
      };

      setCandidatePipeData(res);
    }
  };

  const setSelectedStatusesIds = async (filter: string) => {
    const statuses_ids = !(mod.filters.statuses_ids || []).includes(filter)
      ? [...(mod.filters.statuses_ids || []), filter]
      : (mod.filters.statuses_ids || []).filter((f) => f !== filter);

    await updateModule({
      dashboard_slug: dashboardSlug,
      module_key: mod.key,
      filters: {
        statuses_ids,
      },
    });
  };

  const setArchived = async (archived: boolean) => {
    await updateModule({
      dashboard_slug: dashboardSlug,
      module_key: mod.key,
      filters: {
        archived,
      },
    });
  };

  useDeepCompareEffect(() => {
    fetchCandidatesPipe();
  }, [filters, mod.filters, statuses, sortFilter]);

  return (
    <div className="bg-white p-6 shadow-md mb-6">
      {!locked && (
        <div>
          <div className="flex items-center gap-2">
            {statuses.map((status) => (
              <Checkbox
                key={status.id}
                label={status.name}
                light
                value={(mod.filters.statuses_ids || []).includes(status.id)}
                onChange={() => setSelectedStatusesIds(status.id)}
              />
            ))}
          </div>
          <div className="flex gap-4 items-center mt-2">
            <Radio
              light
              optionType="button"
              value={chartTypeSelected}
              onChange={(e) => setTimelineSelected(e as "users" | "bus" | "candidates" | "jos")}
              options={[
                { label: "Par utilisateurs", value: "users" },
                { label: "Par départements", value: "bus" },
                { label: "Candidats", value: "candidates" },
                { label: "Offres", value: "jos" },
              ]}
            />
            <Radio
              light
              optionType="button"
              value={opportunityType || "all"}
              onChange={(e) => setOpportunityType(e as "all" | "cabinet" | "rpo" | "interne")}
              options={[
                { label: "Toutes", value: "all" },
                { label: "Cabinet", value: "cabinet" },
                { label: "RPO", value: "rpo" },
                { label: "Interne", value: "interne" },
              ]}
            />
          </div>
          <div className="mt-2 flex items-center gap-2">
            <Checkbox
              label="Archivé"
              light
              value={mod.filters.archived || false}
              onChange={(val) => setArchived(val)}
            />
          </div>
          <Divider light />
        </div>
      )}
      <ModuleTitle
        title={mod.title}
        slug={dashboardSlug}
        modKey={mod.key}
        updateModule={updateModule}
        locked={locked}
      />
      <div className="flex items-center justify-between gap-2">
        <div className="w-[65%]">
          {chartTypeSelected === "candidates" ? (
            <CandidatePipeList
              candidates={candidatePipeData?.candidates}
              statuses={statuses}
              setSortFilter={setSortFilter}
            />
          ) : chartTypeSelected === "jos" ? (
            <PipeJos jos={candidatePipeData?.jos || []} statuses_ids={mod.filters.statuses_ids || []} />
          ) : chartTypeSelected === "bus" ? (
            <DashboardTimeline
              statuses={statuses}
              timeline={candidatePipeData?.per_bu}
              selectedIds={mod.filters.statuses_ids || []}
              colors={PALETTE.BLUE}
              perUserOrBu
            />
          ) : (
            <DashboardTimeline
              statuses={statuses}
              timeline={candidatePipeData?.per_user}
              selectedIds={mod.filters.statuses_ids || []}
              colors={PALETTE.BLUE}
              perUserOrBu
            />
          )}
        </div>
        <div className="h-[200px]">
          <Divider type="vertical" light />
        </div>
        <div className="w-[35%]">
          <DashboardStats
            summary={candidatePipeData?.summary || {}}
            statuses={statuses}
            selectedIds={mod.filters.statuses_ids || []}
            colors={PALETTE.BLUE}
          />
        </div>
      </div>
    </div>
  );
};

export default CandidatePipeModule;
